import React, {useEffect, useState} from 'react';
import {
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useFetchData} from '../../../../controllers/useFetchData';
import {LibraryVersionCardStyles} from '../../../../components/card/theme';
import {useStyles} from '@unthinkable/react-theme';
import {IntegratedProjectComponentScreen} from './ComponentScreen';
import {IntegratedProjectDataServiceLibraryScreen} from './DataServiceLibraryScreen';
import {IntegratedProjectStyledComponentScreen} from './StyledComponentScreen';
import {ThemeLibraryVersionTab} from '../ThemeLibraryVersionTab';
import {LibraryTypes} from '../../constants/LibraryConstants';

const LIBRARY_WISE_VIEWS = {
  [LibraryTypes.COMPONENT]: IntegratedProjectComponentScreen,
  [LibraryTypes.STYLED_COMPONENT]: IntegratedProjectStyledComponentScreen,
  [LibraryTypes.THEME]: ThemeLibraryVersionTab,
  [LibraryTypes.DATA_SERVICE]: IntegratedProjectDataServiceLibraryScreen,
};

const LibraryVersionCard = ({setVersion, row, isSelected}) => {
  const {container, activeContainer, boldTextStyle, textStyle} = useStyles(
    LibraryVersionCardStyles,
  );
  return (
    <TouchableOpacity
      onPress={() => setVersion(row)}
      style={isSelected ? {...container, ...activeContainer} : container}>
      <Text style={boldTextStyle}>{`Version: ${row.version}`}</Text>
      <Text style={textStyle}>{`Branch: ${row.branch}`}</Text>
    </TouchableOpacity>
  );
};

export const ProjectLibraryVersionTable = props => {
  const {
    route: {params},
  } = props;

  let [version, setVersion] = useState();

  const {row} = params || {};

  const {data, loading} = useFetchData({
    api: `/projectlibraryversions`,
    filter: {
      library_id: params?.row?._id,
    },
    sort: {
      createdAt: -1,
    },
  });

  useEffect(() => {
    if (data?.length) {
      setVersion(data[0]);
    }
  }, [data]);

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ActivityIndicator />
      </View>
    );
  }

  const Screen = LIBRARY_WISE_VIEWS[row?.type];

  return (
    <Row gap={8} style={{flex: 1, overflow: 'hidden'}}>
      <View style={{overflow: 'hidden'}}>
        <ScrollView style={{gap: 10}}>
          {data?.map(row => {
            return (
              <LibraryVersionCard
                isSelected={row?._id === version?._id}
                setVersion={setVersion}
                row={row}
              />
            );
          })}
        </ScrollView>
      </View>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <Screen {...props} version={version} />
      </View>
    </Row>
  );
};
