import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const ClientProjectForm = props => {
  let {
    route: {params = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'ClientProject',
    ...props,
  });
  let {project} = params || {};
  const {user} = useAppStateContext();
  const {employee = {}, org} = user;
  return (
    <Form
      api={`/projects/${project?._id}`}
      fields={{
        project: 1,
        code: 1,
        desc: 1,
        owner_id: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        user_organization: org,
        project_status: 'a',
        owner_id: employee?._id && employee,
        created_on: getZeroTimeDate(new Date()),
        tool: 'Locomo',
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'project',
          label: 'Project Name',
          required: true,
        },
        {
          type: 'aiTextArea',
          field: 'desc',
          required: true,
          label: 'Description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'PM',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          filter: {
            user_organization: org,
          },
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ClientAddProjectForm = props => {
  return (
    <ClientProjectForm
      header={{
        title: 'Add Project',
      }}
      {...props}
    />
  );
};

export const ClientEditProjectForm = props => {
  return (
    <ClientProjectForm
      mode="edit"
      header={{
        title: 'Edit Project',
      }}
      {...props}
    />
  );
};

export const ClientDetailProjectForm = props => {
  return (
    <ClientProjectForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail Project',
      }}
      {...props}
    />
  );
};
