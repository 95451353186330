import React, {useState} from 'react';

import {Table} from '../../../../components/table/Table';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ScreenDetailForm} from './ScreenForm';
import {BaseForm, InlineForm} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_VIEW_TYPE_ID} from '../../../common/constants/SourceConstants';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  ToggleGraphView,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {useFetchData} from '../../../../controllers/useFetchData';
import {ScreenTable, screenExportParams} from './ScreensTable';
import {TabView} from '../../../../components/tab';
import {SearchFilter} from '../../../../components/filter/Filters';
import {TableHeader} from '../../../../components/table/Headers';
import {useFilter} from '../../../../controllers/useFilter';
import {ScreenGraphView} from '../../../../components/graphflow/ScreenGraphView';
import {AISuggestionsButton} from '../../cellrenders/AISuggestionRenders';
import {GraphHeader} from '../../headers/Header';
import {
  GraphExportActions,
  GraphMoreActions,
} from '../../../../app-components/renders/GraphActions';
import {View} from '@unthinkable/react-core-components';
import {GroupBy} from '../../../../components/table/GroupBy';

const EVENT_SOURCE_ID = [
  'Feature',
  'Task',
  'View',
  'Design',
  'ProjectAI',
  'MapUserstory',
  'MapModelView',
];

export const ScreenInlineForm = props => {
  const {
    navigation,
    route: {params = {}},
    sourceIds,
    Component = InlineForm,
    ...rest
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'View',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params;

  const defaultValues = {
    source: PROJECT_VIEW_TYPE_ID,
    feature_id: feature?._id,
    project_id: project?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <Component
      {...rest}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Screen',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_VIEW_TYPE_ID}/suggestions`,
          suggestionField: 'view',
          valueField: 'view',
          searchFields: ['view'],
          required: true,
          filter: {_id: {$nin: sourceIds}},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-view`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

const FeatureActiveDataEntityGraphHeader = ({params, ...props}) => {
  return (
    <GraphHeader
      leftActions={[
        <View style={{flex: 1, overflow: 'hidden'}}>
          <ScreenInlineForm
            {...props}
            Component={BaseForm}
            inline
            hideInputError
          />
        </View>,
      ]}
      actions={[
        <GraphExportActions fileName={'Screens'} {...props} />,
        <GraphMoreActions {...props} />,
      ]}
    />
  );
};

const FeatureAllScreenGraphHeader = ({
  searchValue,
  onChangeFilter,
  params,
  ...props
}) => {
  return (
    <GraphHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GraphExportActions fileName={'Screens'} {...props} />,
        <GraphMoreActions {...props} />,
        <AssetAddButton title={'Screen'} view={'add-view'} params={params} />,
      ]}
    />
  );
};

const FeatureScreenTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    fetchParams,
    selectedId,
  } = props;

  const {feature} = params;

  const {
    assetDetail,
    tasks,
    removeFromFeature,
    addDesign,
    viewDesign,
    versions,
    changeModule,
    changeDataEntity,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_VIEW_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/projectViews',
    source: PROJECT_VIEW_TYPE_ID,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_VIEW_TYPE_ID}
          assetInlineForm={<ScreenInlineForm {...props} />}
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={screenExportParams({...fetchParams, fromFeature: true})}
          {...headerProps}
        />
      )}
      selection={{
        actions: [updateModuleMultipleAssets, updateDataEntityMultipleAssets],
      }}
      columns={[
        {type: 'text', field: 'view', header: 'Screen'},
        !selectedId && assetColumns.modelView,
        !selectedId && assetColumns.api,
        !selectedId && assetColumns.userstory_count,
        assetColumns.linked_to,
        assetColumns.designs({
          onUpload: addDesign,
          onView: viewDesign,
        }),
        !selectedId && assetColumns.tasks({onPress: tasks}),
        !selectedId && assetColumns.est_hrs,
        !selectedId && assetColumns.assignees,
        !selectedId && assetColumns.code_editor,
      ]}
      onRowPress={assetDetail}
      moreActions={[
        versions,
        changeModule,
        changeDataEntity,
        {
          title: 'Generate Code',
          visible: ({row}) => row?.folder_path_id && row?.file_name,
          onPress: ({row}) => {
            navigation.navigate('screen-code-editor', {
              ...params,
              row,
              readOnly: feature.status === 'released',
            });
          },
        },
        removeFromFeature,
      ]}
      {...fetchParams}
    />
  );
};

const FeatureScreenGraph = props => {
  const {
    navigation,
    route: {params},
    fetchParams,
  } = props;

  const {feature} = params;

  const {data = [], loading} = useFetchData({
    api: `/features/${feature?._id}/asset-screen-graph-view`,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_VIEW_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const renderHeader = props => {
    return (
      <FeatureActiveDataEntityGraphHeader
        {...props}
        excelParams={screenExportParams({
          ...fetchParams,
          fromFeature: true,
        })}
        params={params}
      />
    );
  };

  return (
    <>
      {/* <FeatureAssetHeaderRow
        data={actualData}
        feature={feature}
        assetId={PROJECT_VIEW_TYPE_ID}
        assetInlineForm={<ScreenInlineForm {...props} />}
        eventSourceId={EVENT_SOURCE_ID}
        exportParams={screenExportParams({...fetchParams, fromFeature: true})}
      /> */}
      <ScreenGraphView
        renderHeader={renderHeader?.(props)}
        onNodePress={({item, readOnly}) => {
          assetDetail({row: item, readOnly});
        }}
        data={data}
        loading={loading}
        {...props}
      />
    </>
  );
};

const AllScreenGraph = ({renderHeader, ...props}) => {
  const {navigation, route: {params} = {}} = props;
  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_VIEW_TYPE_ID,
  });

  const {groupBy, ...restProps} = props;

  const {data = [], loading} = useFetchData({
    ...restProps,
  });

  return (
    <ScreenGraphView
      renderHeader={renderHeader?.(props)}
      fromAll
      onNodePress={({item, readOnly}) => {
        assetDetail({row: item, readOnly});
      }}
      data={data}
      loading={loading}
      {...restProps}
    />
  );
};

const FeatureActiveScreens = props => {
  const {
    route: {params},
    isGraphView,
  } = props;

  const {feature} = params;

  const fetchParams = {
    api: `/features/${feature?._id}/assets/${PROJECT_VIEW_TYPE_ID}/asset-data`,
    eventSourceId: EVENT_SOURCE_ID,
  };

  const ScreenView = isGraphView ? FeatureScreenGraph : FeatureScreenTable;

  return (
    <SplitScreen splitScreen={<ScreenDetailForm {...props} />}>
      <ScreenView fetchParams={fetchParams} {...props} />
    </SplitScreen>
  );
};

export const AllScreen = props => {
  const {
    route: {params},
    isGraphView,
    api,
    groupBy,
  } = props;

  const {project, feature, module} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_VIEW_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_VIEW_TYPE_ID}/all-data`,
    addOnFilter: {
      ...filterValues.filter,
    },
    filter: {
      module_id: module?._id,
    },
    search: searchValue,
    searchFields: sourceField,
    eventSourceId: EVENT_SOURCE_ID,
    secondaryApiProps: {
      api: `/projectviews/ai`,
      params: {
        parent_entity: 'Module',
        parent_entity_id: module?._id,
      },
      eventSourceId: 'ProjectAI',
    },
    params: {
      show_in_development: !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };

  if (isGraphView) {
    tableProps.limit = 10000;
    tableProps.params = {show_in_development: false};
  }

  const renderHeader = ({selectedIds}) => {
    if (isGraphView) {
      return (
        <FeatureAllScreenGraphHeader
          {...filterProps}
          {...props}
          excelParams={screenExportParams({
            ...tableProps,
            fromFeature: true,
            ids: props?.selectedIds,
          })}
          params={params}
        />
      );
    }
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={screenExportParams({...tableProps, fromFeature: true})}
          />,
          <AssetAddButton title={'Screen'} view={'add-view'} params={params} />,
        ]}
      />
    );
  };

  const ScreenView = isGraphView ? AllScreenGraph : ScreenTable;

  return (
    <SplitScreen splitScreen={<ScreenDetailForm {...props} />}>
      <ScreenView
        {...props}
        renderHeader={renderHeader}
        groupBy={groupBy}
        {...tableProps}
        fromFeature
      />
    </SplitScreen>
  );
};

export const FeatureScreenTab = props => {
  const [isGraphView, setGraphView] = useState();
  const {route} = props;
  const {params} = route || {};
  const {project, module, feature} = params;
  let tabs = {
    active: {
      label: 'In Development',
      view: <FeatureActiveScreens {...props} isGraphView={isGraphView} />,
    },
    all: {
      label: 'All',
      view: <AllScreen {...props} isGraphView={isGraphView} />,
      actions: [
        project?.ai_enabled && module && feature ? (
          <AISuggestionsButton
            {...props}
            entityName="projectview"
            filter={{
              module_id: module._id,
            }}
            eventSourceId="ProjectAI"
            params={{
              parent_entity: 'Module',
              parent_entity_id: module._id,
              project_id: project._id,
              module_id: module._id,
              label: 'Screens',
              // entity_category_prompts: [
              //   {
              //     category: 'Description',
              //     prompt_name: 'NewDetailSuggestion',
              //     label: 'Screens Description',
              //   },
              //   {
              //     category: 'Detail',
              //     prompt_name: 'NewDetailSuggestion',
              //     label: 'Screens Detail',
              //   },
              // ],
            }}
            initialValues={{
              status: 'active',
              project_id: project._id,
              module_id: module._id,
              source: PROJECT_VIEW_TYPE_ID,
              feature_id: feature?._id,
            }}
          />
        ) : (
          void 0
        ),
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      actions={[
        <ToggleGraphView
          isGraphView={isGraphView}
          setGraphView={setGraphView}
        />,
      ]}
    />
  );
};
