import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {getPreviousMonth} from '@unthinkable/react-filters';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {IconButton} from '../../../components/button/IconButton';

const projectOnpress = ({navigation, row}) => {
  return navigation.navigate(`project-home`, {
    _id: row?.project,
    project: row?.project,
  });
};

const customerOnpress = ({navigation, row, params}) => {
  return navigation.navigate(`customer-details`, {
    ...params,
    customerId: row?.customer?._id,
    row: {
      _id: row?.customer?._id,
      name: row?.customer?.name,
    },
  });
};

export const ProjectGrossMarginTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
    isCustomerGrossMargin = false,
  } = props;
  filterParams = {...params, ...filterParams};
  return (
    <Table
      {...props}
      params={{...filterParams, isCustomerGrossMargin}}
      addOnFilter={{...filter, ...params?.customerFilter}}
      api={'/projects/teamcostreport'}
      limit={5000}
      renderHeader={() =>
        params?.fromCustomer && (
          <TableHeader title={'T&M Project Gross Margin'} />
        )
      }
      columns={[
        {
          header: isCustomerGrossMargin ? 'Customer' : 'Project',
          minWidth: 280,
          render: ({row}) => {
            let type = '';
            if (row.projecttype === 'tm') {
              type = 'T&M';
            } else if (row.projecttype === 'i') {
              type = 'Internal';
            } else if (row.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <PrimaryColumnCard
                primaryTitle={
                  isCustomerGrossMargin
                    ? row?.customer?.name
                    : row?.project?.project
                }
                items={[
                  {
                    value: row?.code,
                  },
                  {
                    value: row?.customer?.name,
                  },
                ]}
              />
            );
          },
          onPress: ({row}) =>
            isCustomerGrossMargin
              ? customerOnpress({navigation, row, params})
              : projectOnpress({navigation, row}),
        },
        {
          header: 'AM',
          type: 'userAvatar',
          field: 'customer.owner',
          width: 60,
        },
        {
          header: 'CSM',
          type: 'userAvatar',
          field: 'customer.csm',
          width: 60,
        },
        isCustomerGrossMargin && {
          render: CountCellRender,
          header: 'Projects',
          align: 'right',
          count_field: 'projectCounts',
          minWidth: 200,
          onPress: ({row}) => {
            navigation.navigate('project-gross-margin-report', {
              ...params,
              fromCustomer: true,
              customerFilter: {customer: row?.customer?._id},
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Team',
          align: 'right',
          count_field: 'project_team',
          onPress: ({row}) => {
            navigation.navigate('eng_gross_margin_report', {
              ...row,
              employeeId: row.employeeId,
              project_id: !isCustomerGrossMargin && row?.project?._id,
              customer: isCustomerGrossMargin && row?.customer?._id,
              api: '/employee/engineeringReport',
              period: filterParams.period,
              formProjectGrossMargin: true,
            });
          },
        },
        {
          header: 'Avg Exp',
          width: 80,
          field: 'avg_experience',
          align: 'right',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#737373'}}>
                {row.avg_experience} y
              </Text>
            );
          },
        },
        {
          aggregate: true,
          count_field: 'revenue',
          header: 'Revenue',
          type: 'currency',
          field: 'revenue',
          align: 'right',
          width: 120,
        },
        {
          aggregate: true,
          count_field: 'cost',
          header: 'Cost',
          type: 'currency',
          field: 'cost',
          align: 'right',
          width: 120,
        },
        {
          aggregate: true,
          count_field: 'gross_margin',
          header: 'Gross Margin',
          type: 'currency',
          field: 'gross_margin',
          align: 'right',
          width: 120,
        },
        {
          count_field: 'avg_profit_per_person',
          header: 'Gross Margin %',
          field: 'avg_profit_per_person',
          align: 'right',
          width: 140,
          render: ({row, styles}) => {
            return (
              <Row
                style={{
                  backgroundColor: row.avgColor,
                  width: 120,
                  flex: 1,
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  borderRadius: 8,
                }}>
                <Text style={{...styles.rowText}}>
                  {row.avg_gross_margin_percentage
                    ? `${Number(row?.avg_gross_margin_percentage).toFixed(1)}%`
                    : '0'}
                </Text>
              </Row>
            );
          },
        },
      ]}
    />
  );
};

export const FixedProjectGrossMarginTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      {...props}
      params={filterParams}
      addOnFilter={filter}
      api={'/projects/fixedcostreport'}
      limit={5000}
      columns={[
        {
          header: 'Project',
          minWidth: 250,
          render: ({row}) => {
            let icon = '';
            let type = '';
            if (row.project_id.tool === 'Locomo') {
              icon = icons?.LocomoTool;
            } else if (row.project_id.tool === 'Jira') {
              icon = icons?.JiraIcon;
            } else {
              icon = icons?.OtherTool;
            }
            if (row?.project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (row?.project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (row?.project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <Row
                style={{
                  flex: 1,
                  rowGap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                gap={8}>
                <Row
                  style={{
                    borderRadius: 2,
                    padding: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={icon} />
                </Row>
                <PrimaryColumnCard
                  primaryTitle={row?.project_id?.project}
                  items={[
                    {
                      value: row?.project_id?.code,
                    },
                    {
                      value: row?.customer.name,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'AM',
          type: 'userAvatar',
          field: 'customer.owner',
          width: 60,
        },
        {
          header: 'CSM',
          type: 'userAvatar',
          field: 'customer.csm',
          width: 60,
        },
        {
          render: CountCellRender,
          header: 'Team',
          field: 'allocatedHoursInMM',
          count_field: 'allocatedHoursInMM',
          align: 'right',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('eng_gross_margin_report', {
              employeeId: row.employeeIds,
              project_id: row?.project_id?._id,
              api: '/employee/fixedEngineeringReport',
              period: filterParams.period,
              formProjectGrossMargin: true,
            });
          },
        },
        {
          header: 'Avg Exp',
          align: 'right',
          render: ({row, styles}) => {
            return <Text style={{...styles.rowText}}>{row.avg_exp} y</Text>;
          },
          width: 100,
        },
        {
          aggregate: true,
          count_field: 'revenue',
          header: 'Revenue',
          field: 'revenue',
          type: 'currency',
          width: 120,
        },
        {
          aggregate: true,
          count_field: 'cost',
          header: 'Cost',
          field: 'cost',
          type: 'currency',
          width: 120,
        },
        {
          aggregate: true,
          count_field: 'gross_margin',
          header: 'Gross Margin',
          field: 'gross_margin',
          type: 'currency',
          width: 120,
        },
        {
          header: 'Gross Margin %',
          field: 'gross_margin_percentage',
          align: 'rigth',
          width: 140,
          render: ({row, styles}) => {
            return (
              <Row
                style={{
                  backgroundColor: row.avgColor,
                  width: 120,
                  flex: 1,
                  alignItems: 'center',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  borderRadius: 8,
                }}>
                <Text style={{...styles.rowText}}>
                  {row.gross_margin_percentage
                    ? `${Number(row?.gross_margin_percentage).toFixed(1)}%`
                    : '0'}
                </Text>
              </Row>
            );
          },
        },
      ]}
    />
  );
};

export const ProjectGrossMarginTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {download} = useAppStateContext();
  const tabs = {
    project_gross_margin_report: {
      apiKey: 'active',
      label: 'T&M',
      view: (
        <ProjectGrossMarginTable
          key="ProjectGrossMargin"
          filterParams={filterParams}
          filter={{...filter, period}}
        />
      ),
      // api: '/projects/teamcostreport',
      // filter: {...filter, period},
      // addOnFilter: {...filter, period, ...params?.customerFilter},
      // params: {...filterParams, ...params, isCustomerGrossMargin: false},
      // limit: 5000,
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'project_id',
              api: '/projects',
              suggestionField: 'project',
              valueField: 'project',
              label: 'Project',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportTandMProjectGrossMarginReport',
              props: {
                uri: '/projects/teamcostreport',
                parameters: {
                  params: {...filterParams, isCustomerGrossMargin: false},
                  addOnFilter: {...filter, ...params?.customerFilter},
                },
              },
            });
          }}
        />,
      ],
    },

    // cutomer_gross_margin_report: {
    //   label: 'T&M Customer',
    //   view: (
    //     <ProjectGrossMarginTable
    //       key="CustomerGrossMargin"
    //       filterParams={filterParams}
    //       filter={{...filter, period}}
    //       isCustomerGrossMargin={true}
    //     />
    //   ),
    //   actions: [
    //     <PeriodRangeFilter
    //       onChangeFilter={onChangeFilter}
    //       filterValues={filterValues}
    //     />,
    //     <GroupFilter
    //       filterValues={filterValues}
    //       applyFilter={applyFilter}
    //       filters={[
    //         {
    //           type: 'autoComplete',
    //           label: 'Customer',
    //           api: '/customers',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'customer',
    //         },
    //         {
    //           type: 'autoComplete',
    //           field: 'project_id',
    //           api: '/projects',
    //           suggestionField: 'project',
    //           valueField: 'project',
    //           label: 'Project',
    //         },
    //       ]}
    //     />,
    //     <IconButton
    //       icon={icons.Download}
    //       onPress={() => {
    //         download({
    //           uri: '/exportTandMProjectGrossMarginReport',
    //           props: {
    //             uri: '/projects/teamcostreport',
    //             parameters: {
    //               params: {...filterParams, isCustomerGrossMargin: true},
    //               addOnFilter: {...filter, ...params?.customerFilter},
    //             },
    //           },
    //         });
    //       }}
    //     />,
    //   ],
    // },
    Fixed_gross_margin_report: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <FixedProjectGrossMarginTable
          key="FixedGrossMargin"
          filterParams={filterParams}
          filter={{...filter, period}}
          isCustomerGrossMargin={true}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'project_id',
              api: '/projects',
              suggestionField: 'project',
              valueField: 'project',
              label: 'Project',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportfixedProjectGrossMarginReport',
              props: {
                uri: '/projects/fixedcostreport',
                parameters: {
                  params: {filter},
                  addOnFilter: {...filterParams},
                },
              },
            });
          }}
        />,
      ],
      // api: '/projects/fixedcostreport',
      // addOnFilter: {...filter, period},
      // params: {...filterParams},
      // limit: 5000,
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
