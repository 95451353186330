import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const ManualVariable = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/variablesalaries',
    eventSourceId: 'manualVariable',
  });
  return (
    <Form
      api={`/manualVariable/setup/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          type: 'autoComplete',
          field: 'employee_id',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
          size: 6,
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Level 1',
          field: 'level1_achievement',
          type: 'number',
          // required: true,
          size: 6,
        },
        {
          label: 'Level 2',
          field: 'level2_achievement',
          type: 'number',
          size: 6,
        },
        {
          label: 'Level 3',
          field: 'level3_achievement',
          type: 'number',
          size: 6,
        },
        {
          label: 'Self',
          field: 'self_achievement',
          type: 'number',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddManualVariable = props => {
  return <ManualVariable header="Add Manual Variable" {...props} />;
};

export const EditManualVariable = props => {
  return (
    <ManualVariable mode="edit" header={'Update Manual Variable'} {...props} />
  );
};
