import {Text, TouchableOpacity} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useFormContext} from '@unthinkable/react-form/src/FormContext';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {Form, MultiStepForm} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {AssignEmailMessageSequence} from './AssignEmailMessageSequence';
import {LoadEmailThemes} from './LoadEmailTheme';
import {LoadProspectsTable} from './LoadProspectList';

export const EmailCampaignForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/emailCampaigns',
    eventSourceId: 'emailCampaignAdded',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    mode,
  } = props;
  const {product, row, afterSubmit, searchValue} = params;
  return (
    <MultiStepForm
      computations={{
        employee_team: [
          value => value?.assigned_to?.employee_team,
          ['assigned_to'],
        ],
      }}
      api={`/emailCampaigns/${row?._id}`}
      fields={{
        name: 1,
        date: 1,
        email: {
          email_subject: 1,
        },
        assigned_to: {
          name: 1,
          employee_team: 1,
        },
        communication_type: 1,
        target_audience: {
          name: 1,
          industry: 1,
        },
        campaign_id: 1,
        campaign_account: 1,
        employee_team: {name: 1},
        organization: {name: 1},
        channel: {name: 1},
      }}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      header={'Add Email Campaign'}
      submitAction={'Save Setup'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        experiment_on: getZeroTimeDate(new Date()),
        name: searchValue,
        communication_type: 'Automatic',
        status: 'In Experiment',
        is_nurtuable: false,
      }}
      steps={[
        {
          title: 'About',
          fields: [
            {
              label: 'Target Audience',
              field: 'target_audience',
              type: 'autoComplete',
              api: '/buyerPersonas',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              fields: {
                name: 1,
                industry: 1,
              },
              required: true,
            },
            {
              label: 'Name',
              field: 'name',
              type: 'text',
              size: 6,
              required: true,
            },
            {
              label: 'Ownership',
              fields: [
                {
                  label: 'Owner',
                  field: 'assigned_to',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  fields: {
                    name: 1,
                    official_email_id: 1,
                    employee_team: 1,
                  },
                  size: 1,
                  required: true,
                },
              ],
            },
            {
              label: 'Woodpecker',
              fields: [
                {
                  label: 'Campaign Account',
                  field: 'campaign_account',
                  type: 'radioGroup',
                  suggestionField: 'label',
                  keyField: 'value',
                  options: [
                    {label: 'Unthinkable', value: 'A1'},
                    {label: 'Daffodil', value: 'L1'},
                  ],
                },
                {
                  label: 'Campaign Ids',
                  field: 'campaign_id',
                  type: 'text',
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Type',
                  type: 'radioGroup',
                  field: 'communication_type',
                  options: ['Automatic', 'Manual'],
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Channel',
                  field: 'channel',
                  type: 'autoComplete',
                  api: `/channels`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  filter: {
                    _id: {
                      $nin: ['66d2ec16f897151a53daf08b'],
                    },
                  },
                  required: true,
                },
                {
                  label: 'Employee Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  filter: {
                    _id: {
                      $in: [
                        '654c6ebd64126a8c199e9537',
                        '655b1c352722272f13e98953',
                      ],
                    },
                  },
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_nurtuable',
                  label: 'Is Nurtuable',
                  size: 6,
                },
              ],
            },
          ],
          saveOnNext: true,
          // onNext: async ({values}) => {
          //   if (values.communication_type == 'Automatic') {
          //     navigation.goBack();
          //   }
          // },
        },
        {
          title: 'Load Prospects',
          render: formProps => (
            <LoadProspectsTable
              {...props}
              formProps={formProps}
              source={'prospects'}
            />
          ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-prospects-on-email-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Select Message',
          render: formProps => (
            <LoadEmailThemes {...props} formProps={formProps} />
          ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-messages-on-email-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Assign Sequence',
          fields: [
            {
              render: (_, formProps) => (
                <AssignEmailMessageSequence {...props} formProps={formProps} />
              ),
            },
          ],
          onNext: _ => {
            navigation.goBack();
          },
        },
      ]}
      {...props}
    />
  );
};

export const EmailCampaignForm1 = props => {
  const {
    route: {params},
    navigation,
    mode,
  } = props;

  let {onSubmit} = useFormSubmit({
    uri: '/add-email-campaign',
    eventSourceId: 'emailCampaignAdded',
    ...props,
  });
  const {departmentId, product, row} = params;

  return (
    <Form
      api={`/emailCampaigns/${row?._id}`}
      fields={{
        name: 1,
        date: 1,
        assigned_to: {
          name: 1,
        },
        campaign_id: 1,
        campaign_account: 1,
        marketing_theme: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      header={'Add Email Outreach Campaign'}
      submitAction={'Save Setup'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
      }}
      layoutFields={[
        [
          {
            label: 'Target Audience',
            field: 'target_audience',
            type: 'autoComplete',
            api: '/buyerPersonas',
            suggestionField: 'name',
            valueField: 'name',
            size: 1,
            fields: {
              name: 1,
              industry: 1,
            },
          },
          {
            label: 'Name',
            field: 'name',
            type: 'text',
            size: 1,
          },
        ],
        {
          label: 'Ownership',
          fields: [
            {
              label: 'Owner',
              field: 'assigned_to',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 1,
            },
          ],
        },
        {
          label: 'Woodpecker Campaign Ids',
          field: 'campaign_id',
          type: 'text',
        },
        {
          label: 'Woodpecker Campaign Account',
          field: 'campaign_account',
          type: 'radioGroup',
          suggestionField: 'label',
          keyField: 'value',
          options: [
            {label: 'Unthinkable', value: 'A1'},
            {label: 'Daffodil', value: 'L1'},
          ],
        },
        {
          fields: [
            {
              label: 'Type',
              type: 'radioGroup',
              field: 'communication_type',
              options: ['Automatic', 'Manual'],
            },
          ],
        },
        {
          visible: ({values}) => values?.communication_type == 'Manual',
          field: 'loaded_prospects',
          render: (props, {values}) => {
            const {value} = props;
            const {setFieldValue} = useFormContext();
            const colors = useTheme('colors');
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('load-prospects', {
                    setFieldValue,
                    selectedIds: value,
                    filter: {
                      industry: values?.target_audience?.industry,
                    },
                  });
                }}>
                <Text
                  style={{
                    color: colors['ACCENT1_HIGH'],
                    textDecoration: 'underline',
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}>
                  {`Loaded Prospects(${value?.length || 0})`}
                </Text>
              </TouchableOpacity>
            );
          },
        },
        {
          visible: ({values}) => values?.communication_type == 'Manual',
          field: 'loaded_messages',
          render: (props, {values}) => {
            const {value} = props;
            const {setFieldValue} = useFormContext();
            const colors = useTheme('colors');
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('load-email-messages', {
                    setFieldValue,
                    selectedIds: value,
                    marketingThemeFilter: {
                      target_audience: values?.target_audience,
                    },
                  });
                }}>
                <Text
                  style={{
                    color: colors['ACCENT1_HIGH'],
                    textDecoration: 'underline',
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}>
                  {`Loaded Messages(${value?.length || 0})`}
                </Text>
              </TouchableOpacity>
            );
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditEmailCampaignForm = props => {
  return (
    <EmailCampaignForm header={'Edit Email Campaign'} mode="edit" {...props} />
  );
};
