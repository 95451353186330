import React from 'react';
import {Table} from '../../../components/table/Table';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';

export const ExpenseVarianceReport = props => {
  const filterProps = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {filterValues} = filterProps;
  return (
    <Table
      params={filterValues.params}
      filter={filterValues.filter}
      renderHeader={() => (
        <TableHeader
          title="Expense Variance Report"
          actions={[
            <PeriodRangeFilter {...filterProps} options={[]} skipQuarter />,
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  type: 'multiAutoComplete',
                  field: 'organization',
                  label: 'Organization',
                  api: '/organizations',
                  valueField: 'name',
                  suggestionField: 'name',
                },
                {
                  type: 'multiAutoComplete',
                  field: 'account',
                  label: 'Account',
                  api: '/accounts',
                  valueField: 'name',
                  suggestionField: 'name',
                  filter: {
                    is_summary_required: true,
                  },
                  asParam: true,
                },
              ]}
            />,
          ]}
        />
      )}
      api="/expenseVariationReport"
      columns={[
        {header: 'Account', field: 'account.name', type: 'text'},
        {
          header: 'Current Closing',
          field: 'current_closing',
          type: 'number',
          width: 250,
        },
        {
          header: 'Previous Closing',
          field: 'previous_closing',
          type: 'number',
          width: 250,
        },
        {
          header: 'Variance',
          field: 'variance',
          type: 'number',
          width: 250,
        },
      ]}
      {...props}
    />
  );
};
