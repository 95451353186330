import React from 'react';
import {Table} from '../../../components/table/Table';

export const DepartmentWiseTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      api="/departments"
      fields={{
        name: 1,
      }}
      eventSourceId={['DepartmentRole']}
      onRowPress={({row}) => {
        navigation.navigate('department-wise-employee-menus', {
          ...params,
          department: row._id,
          title: row.name,
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
