import React from 'react';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {randomColor} from '../../../utils/util';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {AddButton} from '../../../components/button/AddButton';
import {Form} from '../../../components/form/Form';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

export const UpdateDepartment = props => {
  const {route: {params} = {}} = props;
  const {row, key, ...rest} = params;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'voucherledger',
  });
  return (
    <Form
      onSubmit={async data => {
        await invoke({
          uri: '/invoke',
          props: {data, ...rest},
        });
      }}
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'department',
          label: 'Department',
          api: '/departments',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateDepartmentOnVoucherForm = props => {
  const {route = {}} = props;
  const {params = {}} = route;
  return <UpdateDepartment header="Update Department" {...props} />;
};

const VoucherLedgerTable = props => {
  const {filterParams, params, navigation} = props;
  const invoke = useInvoke({
    eventSourceId: 'voucherledger',
    method: 'post',
  });
  const fetchProps = {
    params,
    addOnFilter: {...filterParams},
    api: '/getledger',
  };
  return (
    <Table
      {...props}
      api={'/getledger'}
      addOnFilter={filterParams}
      params={params}
      eventSourceId="voucherledger"
      limit={10000}
      selection={{
        actions: [
          {
            title: 'Update Department',
            onPress: ({selectedIds}) => {
              navigation.navigate('update-department', {
                ...params,
                selectedIds,
                fetchProps,
                api: '/updatedepartmentonvoucheritem',
              });
            },
          },
        ],
      }}
      columns={[
        {
          type: 'date',
          header: 'Date',
          field: 'voucher_id.voucher_date',
          width: 100,
        },
        {
          type: 'text',
          field: 'voucher_id.voucher_no',
          header: 'Number',
          width: 200,
        },
        {
          type: 'text',
          field: 'voucher_id.narration',
          header: 'Naration',
          width: 300,
        },
        {
          field: 'account.name',
          type: 'text',
          header: 'Account',
          width: 200,
        },
        {
          field: 'entity.name',
          type: 'text',
          header: 'Entity',
          width: 200,
        },
        {
          field: 'department.name',
          type: 'text',
          header: 'Department',
          width: 200,
        },
        {
          type: 'currency',
          field: 'cr_amount',
          header: 'Credit',
          width: 200,
        },
        {
          type: 'currency',
          field: 'dr_amount',
          header: 'Debit',
          width: 200,
        },
        {
          type: 'currency',
          header: 'Closing',
          field: 'openingDrAmount',
          width: 200,
        },
      ]}
      moreActions={() => [
        {
          title: 'Voucher',
          onPress: ({row}) => {
            const {breadcrumb, ...rest} = params;
            if (row?.voucher_id?._id) {
              navigation.navigate(`voucherslist`, {
                ...rest,
                voucherfilter: {
                  _id: row?.voucher_id?._id,
                },
              });
            }
          },
        },
      ]}
    />
  );
};

export const VoucherLedgerTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    all: {
      label: 'Ledger',
      view: (
        <VoucherLedgerTable
          filterParams={filter}
          searchValue={searchValue}
          params={{
            ...params,
            ...filterValues.params,
          }}
          {...props}
        />
      ),
      eventSourceId: ['TDS', 'voucherledger'],
      addOnFilter: filter,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportledger',
              props: {
                addOnFilter: {...filter},
                params: {...params, ...filterValues.params},
                globalParams: globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={{...params, ...filterValues.params}}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            // {
            //   type: 'date',
            //   label: 'Date',
            //   field: 'period',
            //   asParam: true,
            // },
            {
              type: 'multiAutoComplete',
              label: 'Entity',
              api: '/getentities',
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              field: 'entity',
            },
            {
              type: 'multiAutoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              titleField: 'name',
              field: 'account',
            },
            // {
            //   type: 'multiAutoComplete',
            //   label: 'Organization',
            //   api: '/organizations',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'organization',
            // },
            // {
            //   type: 'autoComplete',
            //   label: 'Product',
            //   api: '/products',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'product',
            // },
            {
              type: 'autoComplete',
              label: 'Currency',
              placeholder: 'Select',
              options: [
                {label: 'INR', value: 'INR'},
                {label: 'Other', value: 'Other'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'currency',
            },
          ]}
        />,
        <AddButton title="Download Ledger" view="download-ledger" />,
      ]}
    />
  );
};
