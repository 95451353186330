import React, {useEffect, useRef, useState} from 'react';
import {Row} from '@unthinkable/react-core-components';
import {DurationInputCell, DurationInputContainer} from './Styles';
import {InputWrapper} from '@unthinkable/react-input';

const DurationInput = props => {
  let {
    styles,
    hrPlaceholder = 'Hr',
    minPlaceholder = 'Min',
    value,
    inputStyle,
    error,
    ...restProps
  } = props;

  const [isHrActive, setIsHrActive] = useState();
  const [isMinActive, setIsMinActive] = useState();
  const hrRef = useRef(Math.floor(value));
  const minRef = useRef(Math.round((value - Math.floor(value)) * 60));

  const getValue = value => {
    let hrValue, minValue;
    if (value) {
      hrValue = Math.floor(value);
      minValue = Math.round((value - hrValue) * 60);
    }
    hrRef.current.value = hrValue;
    minRef.current.value = minValue;
  };
  const onChangeValue = e => {
    let {onChangeValue} = props;
    let newValue =
      (hrRef.current.value || 0) + (minRef.current.value || 0) / 60;
    if (newValue % 1 !== 0) {
      newValue = parseFloat(newValue.toFixed(2));
    }
    onChangeValue && onChangeValue(newValue, e);
  };

  const onChangeHr = (value, e) => {
    if (value === '') {
      hrRef.current.value = '';
      onChangeValue(e);
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      hrRef.current.value =
        hrRef.current.value === undefined ? '' : hrRef.current.value;
      return;
    }
    let {maxHr} = props;
    if (!maxHr || value < maxHr) {
      hrRef.current.value = value;
      onChangeValue(e);
    }
  };

  const onChangeMin = (value, e) => {
    if (value === '') {
      minRef.current.value = '';
      onChangeValue(e);
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      minRef.current.value =
        hrRef.current.value === undefined ? '' : minRef.current.value;
      return;
    }
    if (value < 60) {
      minRef.current.value = value;
      onChangeValue(e);
    }
  };

  const onFocus = e => {
    let {onFocus} = props;
    onFocus && onFocus(e);
  };

  const onFocusHr = e => {
    setIsHrActive(true);
    onFocus(e);
  };

  const onFocusMin = e => {
    setIsMinActive(true);
    onFocus(e);
  };

  const onBlur = e => {
    let {onBlur} = props;
    onBlur && onBlur(e);
  };

  const onBlurHr = e => {
    setIsHrActive(false);
    onBlur(e);
  };

  const onBlurMin = e => {
    setIsMinActive(false);
    onBlur(e);
  };

  useEffect(() => {
    if (!isHrActive && !isMinActive) {
      getValue(value);
    }
  }, [value]);

  let hrDisplayValue = hrRef.current.value;
  let minDisplayValue = minRef.current.value;
  if (!isHrActive && hrDisplayValue !== undefined && hrDisplayValue !== '') {
    hrDisplayValue = hrDisplayValue + ' Hr';
  }
  if (!isMinActive && minDisplayValue !== undefined && minDisplayValue !== '') {
    minDisplayValue = minDisplayValue + ' Min';
  }

  return (
    <InputWrapper {...props}>
      <Row gap={12}>
        <DurationInputContainer styles={styles} error={error}>
          <DurationInputCell
            {...restProps}
            styles={styles}
            placeholder={hrPlaceholder}
            isActive={isHrActive}
            value={hrDisplayValue}
            onChangeValue={onChangeHr}
            onFocus={onFocusHr}
            onBlur={onBlurHr}
            ref={hrRef}
          />
        </DurationInputContainer>
        <DurationInputContainer styles={styles} error={error}>
          <DurationInputCell
            {...restProps}
            styles={styles}
            placeholder={minPlaceholder}
            isActive={isMinActive}
            value={minDisplayValue}
            onChangeValue={onChangeMin}
            onFocus={onFocusMin}
            onBlur={onBlurMin}
            ref={minRef}
          />
        </DurationInputContainer>
      </Row>
    </InputWrapper>
  );
};

export default DurationInput;
