import React from 'react';
import {Text, Row} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {QAStages} from '../constants/FeatureStages';

const convertStatusText = (statusText, re_qa_count) => {
  if (statusText === 'Failed' && re_qa_count) {
    return `Re-QA Failed`;
  } else {
    let status = QAStages.find(stage => stage.value === statusText);
    return status ? status.label : statusText;
  }
};

const StatusRender = ({row, status_field}) => {
  let {open_bugs_count, re_qa_count} = row;
  let statusText = row[status_field] || '';

  let {
    columnTextStyle,
    textStyle,
    activeTextStyle,
    activeBackgroundStyle,
    rejectedTextStyle,
    rejectedBackgroundStyle,
    qaTextStyle,
    qaBackgroundStyle,
    uatTextStyle,
    uatBackgroundStyle,
    designTextStyle,
    designBackgroundStyle,
    codeTextStyle,
    codeBackgroundStyle,
  } = useComponentTheme('StatusRenderStyles');

  if (statusText === 'Design') {
    textStyle = {...textStyle, ...designTextStyle};
    columnTextStyle = {...columnTextStyle, ...designBackgroundStyle};
  } else if (statusText === 'Code') {
    textStyle = {...textStyle, ...codeTextStyle};
    columnTextStyle = {...columnTextStyle, ...codeBackgroundStyle};
  } else if (statusText === 'active' || statusText === 'Open') {
    textStyle = {...textStyle, ...activeTextStyle};
    columnTextStyle = {...columnTextStyle, ...activeBackgroundStyle};
  } else if (
    statusText === 'QA' ||
    statusText === 'Re-QA' ||
    statusText === 'Resolved'
  ) {
    textStyle = {...textStyle, ...qaTextStyle};
    columnTextStyle = {...columnTextStyle, ...qaBackgroundStyle};
  } else if (statusText === 'Failed') {
    textStyle = {...textStyle, ...rejectedTextStyle};
    columnTextStyle = {...columnTextStyle, ...rejectedBackgroundStyle};
  } else if (statusText === 'UAT') {
    textStyle = {...textStyle, ...uatTextStyle};
    columnTextStyle = {...columnTextStyle, ...uatBackgroundStyle};
  }

  if (!statusText) {
    return null;
  }

  return (
    <Row style={columnTextStyle} gap={4}>
      <Text style={textStyle}>
        {convertStatusText(statusText, re_qa_count)}
      </Text>
      {open_bugs_count &&
      (statusText === 'QA' ||
        statusText === 'Re-QA' ||
        statusText === 'Failed') ? (
        <Text style={textStyle}>{`(${open_bugs_count})`}</Text>
      ) : (
        void 0
      )}
    </Row>
  );
};

export default StatusRender;
