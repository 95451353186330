import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';

export const ManualVariableTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter = {}} = filterValues || {};
  return (
    <Table
      eventSourceId={['manualVariable']}
      api={`/manualVariable/setup`}
      addOnFilter={filter}
      renderHeader={() => {
        return (
          <TableHeader
            title="Manual Variable"
            actions={[
              <AddButton
                title="Manual Variable"
                view="add-manual-variable"
                params={params}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'employee_id',
                    secondarySuggestionField: 'official_email_id',
                    searchFields: ['name', 'official_email_id', 'employee_code'],
                  },
                  {
                    type: 'date',
                    field: 'from_date',
                    label: 'From Date',
                  },
                  {
                    type: 'date',
                    field: 'to_date',
                    label: 'To Date',
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-manual-variable`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee_id?.name}
                color={row?.employee_id?.color}
                official_email_id={row?.employee_id?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
          width: 150,
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {format: 'DD MMM, YY'},
          width: 150,
        },
        {
          header: 'Level 1',
          field: 'level1_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Level 2',
          field: 'level2_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Level 3',
          field: 'level3_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Self',
          field: 'self_achievement',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};
