import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { getCurrentMonth } from '@unthinkable/react-filters/src/DateUtility';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { useTheme } from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';

import { CommentMoreAction } from '../../../app-components/renders/CommentRender';
import { IconButton } from '../../../components/button/IconButton';
import { GroupContentItem } from '../../../components/card/GroupContentItem';
import {
    GroupFilter,
    PeriodRangeFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useFetchData } from '../../../controllers/useFetchData';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { CUSTOMER_TYPE_ID } from '../../common/constants/SourceConstants';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'autoComplete',
      label: 'AM',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'account_owner_id',
    },
  ];
};

const commonFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'T&M',
  params,
  period,
}) => {
  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    <IconButton
      icon={icons.Download}
      onPress={() => {
        download({
          uri: `/growthAndBillingTAndM/export`,
          props: {
            ...params,
            period,
          },
        });
      }}
    />,
  ];
};

const CurrentBusinessTAndMTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  let previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.to).subtract(1, 'M').toDate();
  }
  return (
    <Table
      api={`/currentbusiness/tandmprojection`}
      search={searchValue}
      addOnFilter={addOnFilter}
      params={{...params, period}}
      limit={1000}
      eventSourceId="GrowthBillingT&M"
      variant={'bordered'}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
        },
        {
          header: 'PM',
          field: 'customer.owner',
          type: 'userAvatar',
          align: 'center',
          width: 60,
        },
        {
          header: 'CSM',
          field: 'customer.csm',
          type: 'userAvatar',
          align: 'center',
          width: 60,
        },
        {
          header: 'Billed resources',
          children: [
            {
              header: 'Previous month',
              field: 'variable_potential_previous_month',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
              onPress: ({row}) => {
                navigation.navigate('current-business-customer-list', {
                  filter: {
                    _id: {
                      $in: row?.previousMonthCustomerDelivery?.map(
                        ({_id}) => _id,
                      ),
                    },
                    due_date: {
                      $gte: previousPeriod.from,
                      $lte: previousPeriod.to,
                    },
                    order_type: 'r',
                    status: {$ne: 'Cancelled'},
                  },
                });
              },
            },
            {
              header: 'Current month',
              field: 'variable_potential_current_month',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
              onPress: ({row}) => {
                navigation.navigate('current-business-customer-list', {
                  filter: {
                    _id: {$in: row?._children?.map(({_id}) => _id)},
                    due_date: {
                      $gte: period.from,
                      $lte: period.to,
                    },
                    order_type: 'r',
                    status: {$ne: 'Cancelled'},
                  },
                });
              },
            },
            {
              header: 'Change',
              field: 'change',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
            },
          ],
        },
        {
          header: 'Resources deployed',
          field: 'resource_deployable',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('current-business-resource-deployable-list', {
              filter: {
                customer: row._id,
                is_actual_allocation: true,
                is_wrong_input: {$in: [false, null]},
                is_shadow: {$in: [null, false]},
                project_type: 'tm',
                from_date: {$lte: period.to},
                $or: [
                  {to_date: {$gte: period.from}},
                  {to_date: {$exists: false}},
                ],
              },
            });
          },
        },
        {
          header: 'Projected billing (in MM)',
          field: 'projectedBilling',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
          width: 180,
        },
      ]}
    />
  );
};

export const CurrentBusinessTAndMTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    current_business_tandm: {
      label: 'T & M (Current business)',
      view: (
        <CurrentBusinessTAndMTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          period={period}
        />
      ),
      eventSourceId: 'CurrentBusinessT&M',
      addOnFilter: {...filter},
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'T&M',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

const comments = props => {
  return CommentMoreAction({
    ...props,
    params: ({row}) => ({
      comment_source: {_id: CUSTOMER_TYPE_ID, label: 'Customer'},
      comment_source_id: {_id: row?.customer?._id, name: row?.customer?.name},
    }),
  });
};

const GrowthAndBillingFixedTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  let previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.to).subtract(1, 'M').toDate();
  }
  return (
    <Table
      api={`/growthAndBillingFixed`}
      search={searchValue}
      addOnFilter={addOnFilter}
      params={{...params, period}}
      variant={'bordered'}
      limit={2000}
      eventSourceId="CurrentBusinessFixed"
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
        },
        {
          header: 'AM',
          field: 'customer.owner',
          type: 'userAvatar',
          align: 'center',
          width: 60,
        },
        {
          header: 'CSM',
          field: 'customer.csm',
          type: 'userAvatar',
          align: 'center',
          width: 60,
        },
        {
          header: 'Opening',
          field: 'variable_potential_current_month',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
          width: 80,
        },
        {
          header: 'Deployed',
          field: 'resource_deployable',
          type: 'number',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress: ({row}) => {
            navigation.navigate('current-business-resource-deployable-list', {
              filter: {
                customer: row?.customer?._id,
                is_actual_allocation: true,
                is_wrong_input: {$in: [false, null]},
                is_shadow: {$in: [null, false]},
                project_type: 'f',
                from_date: {$lte: period.to},
                to_date: {$gte: period.from},
              },
            });
          },
          width: 80,
        },
        {
          header: 'Projected Billing',
          children: [
            {
              header: 'IH',
              field: 'current_month_projection',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
              width: 60,
            },
            {
              header: 'Pipeline',
              field: 'pipeline',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
              width: 80,
            },
            {
              header: 'Total',
              field: 'total_projected',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              width: 60,
              aggregate: true,
            },
          ],
        },
        {
          header: 'Actual',
          children: [
            {
              header: 'New Orders',
              field: 'growth',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              width: 100,
              aggregate: true,
            },
            {
              header: 'Billing',
              field: 'actual',
              type: 'number',
              formatOptions: {
                maximumFractionDigits: 2,
              },
              aggregate: true,
              width: 80,
            },
          ],
        },
        {
          header: 'Variance',
          field: 'variance',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          aggregate: true,
        },
      ]}
      moreActions={[
        comments({
          navigation,
          params,
          tabName: 'fixed',
          view: {
            view: 'growth-and-billing',
            label: 'Growth & Billing',
            view_metadata: {
              params: {period},
              selectedTab: 'growth_billing_fixed',
            },
          },
        }),
        {
          title: 'Fixed Output',
          onPress: ({row}) => {
            navigation.navigate('fixed-output-from-growth-and-billing', {
              period,
              fixedOutputFilterFormGrowthAndBilling: {
                customer: row?.customer?._id,
              },
            });
          },
        },
      ]}
    />
  );
};

const GrowthAndBillingTAndMTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    filterParam,
  } = props;
  let previousPeriod = {};
  if (period.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = moment(period.to).subtract(1, 'M').toDate();
  }
  const working_day = 2;
  const {
    data: growthBillingTAndM,
    aggregates,
    ...restFetchProps
  } = useFetchData({
    api: `/growthAndBillingtAndM`,
    params: {
      sort_on: filterParam.sort_on || 'variance_new',
      ...params,
      period,
    },
    addOnFilter: {...addOnFilter},

    limit: 2000,
  });

  return (
    <>
      <RowWithSeparator
        style={{
          gap: 12,
          alignItems: 'center',
          backgroundColor: '#FAFAFA',
          marginBottom: 8,
          borderRadius: 6,
          padding: (8, 12, 8, 12),
        }}>
        <GroupContentItem
          value={`Working days : ${aggregates?.working_days || 0} (${
            aggregates?.calculateWorkingDaysPercentage || 0
          }%) `}
        />
        <GroupContentItem
          value={`Avg. Leave : ${aggregates?.avg_leave || 0}%`}
        />
        <GroupContentItem
          value={`Billing factor(BF) : ${
            aggregates?.billing_factor?.toFixed(2) || 0
          }`}
        />
      </RowWithSeparator>
      <Table
        data={growthBillingTAndM}
        aggregates={aggregates}
        {...restFetchProps}
        limit={1000}
        eventSourceId="GrowthBillingT&M"
        variant={'bordered'}
        moreActions={[
          comments({
            navigation,
            params,
            tabName: 'tandm',
            view: {
              view: 'growth-and-billing',
              label: 'Growth & Billing',
              view_metadata: {
                params: {
                  period,
                },
                selectedTab: 'growth_billing_tandm',
              },
            },
          }),
          {
            title: 'T&M Output',
            onPress: ({row}) => {
              navigation.navigate('t-and-m-growth-and-billing', {
                period,
                tandMOutputFilterFormGrowthAndBilling: {
                  customer: row?.customer?._id,
                },
              });
            },
          },
        ]}
        columns={[
          {
            header: 'Customer',
            field: 'customer.name',
          },
          {
            header: 'AM',
            field: 'customer.owner',
            type: 'userAvatar',
            align: 'center',
            width: 40,
          },
          {
            header: 'CSM',
            field: 'customer.csm',
            type: 'userAvatar',
            align: 'center',
            width: 40,
          },
          {
            header: 'Resource order',
            children: [
              {
                header: 'Previous',
                field: 'variable_potential_previous_month',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                width: 80,
                aggregate: true,
                // onPress: ({row}) => {
                //   navigation.navigate('current-business-customer-list', {
                //     filter: {
                //       _id: {
                //         $in: row?.previousMonthCustomerDelivery?.map(
                //           ({_id}) => _id,
                //         ),
                //       },
                //       due_date: {
                //         $gte: previousPeriod.from,
                //         $lte: previousPeriod.to,
                //       },
                //       order_type: 'r',
                //       status: {$ne: 'Cancelled'},
                //     },
                //   });
                // },
              },
              {
                header: 'Current',
                field: 'variable_potential_current_month',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                width: 80,
                aggregate: true,
                // onPress: ({row}) => {
                //   navigation.navigate('current-business-customer-list', {
                //     filter: {
                //       _id: {$in: row?._children?.map(({_id}) => _id)},
                //       due_date: {
                //         $gte: period.from,
                //         $lte: period.to,
                //       },
                //       order_type: 'r',
                //       status: {$ne: 'Cancelled'},
                //     },
                //   });
                // },
              },
              {
                header: 'Change',
                field: 'change',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                width: 80,
                aggregate: true,
              },
            ],
          },
          {
            header: 'Deployed',
            field: 'resource_deployable',
            type: 'number',
            formatOptions: {
              maximumFractionDigits: 2,
            },
            aggregate: true,
            width: 90,
            onPress: ({row}) => {
              navigation.navigate('current-business-resource-deployable-list', {
                filter: {
                  customer: row._id,
                  is_actual_allocation: true,
                  is_wrong_input: {$in: [false, null]},
                  is_shadow: {$in: [null, false]},
                  project_type: 'tm',
                  from_date: {$lte: period.to},
                  $or: [
                    {to_date: {$gte: period.from}},
                    {to_date: {$exists: false}},
                  ],
                },
              });
            },
          },
          {
            header: 'Projected Billing',
            children: [
              {
                header: 'IH',
                field: 'projectedBilling',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                aggregate: true,
                width: 70,
              },
              {
                header: 'Pipeline',
                field: 'pipeline',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                aggregate: true,
                width: 80,
              },
              {
                header: 'Total',
                field: 'total_projected',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                aggregate: true,
                width: 80,
              },
            ],
          },
          {
            header: 'Actual',
            children: [
              {
                header: 'New Orders',
                field: 'variable_potential_current_month_new',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                width: 100,
                aggregate: true,
              },
              {
                header: 'Billing',
                field: 'actual',
                type: 'number',
                formatOptions: {
                  maximumFractionDigits: 2,
                },
                aggregate: true,
                width: 60,
              },
            ],
          },
          {
            header: 'Variance',
            field: 'variance',
            type: 'number',
            formatOptions: {
              maximumFractionDigits: 2,
            },
            width: 80,
            aggregate: true,
          },
        ]}
      />
    </>
  );
};

export const GrowthAndBillingTab = props => {
  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  const {route: {params} = {}} = props;
  const {selectedTab} = params;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: filterParam = {}} = filterValues || {};
  const {period} = filterParam;
  const tabs = {
    growth_billing_fixed: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <GrowthAndBillingFixedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: {$ne: 'Support'},
          }}
          period={period}
        />
      ),
      eventSourceId: 'GrowthBillingFixed',
      addOnFilter: {
        ...filter,
        order_exists_type: {$ne: 'Support'},
      },
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={filters({tab: 'Fixed'})}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: `/growthAndBillingFixed/export`,
              props: {
                ...params,
                period,
              },
            });
          }}
        />,
      ],
    },

    growth_billing_tandm: {
      apiKey: 'active',
      label: 'T&M',
      view: (
        <GrowthAndBillingTAndMTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{...filter}}
          period={period}
          filterParam={filterParam}
        />
      ),
      eventSourceId: 'GrowthBillingT&M',
      addOnFilter: {...filter},
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'Variance', value: 'variance_new'},
                {label: 'Change', value: 'change'},
              ],
              field: 'sort_on',
              label: 'Sort On',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'AM',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'account_owner_id',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/growthAndBillingTAndM/export',
              props: {
                ...params,
                period,
              },
            });
          }}
        />,
      ],
    },

    retainer: {
      apiKey: 'active',
      label: 'Retainer',
      view: (
        <GrowthAndBillingFixedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: 'Support',
          }}
          period={period}
        />
      ),
      eventSourceId: 'Retainer',
      addOnFilter: {
        ...filter,
        order_exists_type: 'Support',
      },
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          skipQuarter
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={filters({tab: 'Retainer'})}
        />,
        // <IconButton
        //   icon={icons.Download}
        //   onPress={() => {
        //     download({
        //       uri: `/growthAndBillingFixed/export`,
        //       props: {
        //         ...params,
        //         period,
        //       },
        //     });
        //   }}
        // />,
      ],
    },
  };
  return (
    <TabView selectedTab={selectedTab} tabs={tabs} params={params} {...props} />
  );
};
