import React from 'react';
import {PrimaryColumnCard} from '../../components/card/PrimaryColumnCard';
import {Image, Row} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

export const ProjectDetailedName = ({row}) => {
  const projectTypeKeys = {
    i: 'Internal',
    f: 'Fixed',
    tm: 'T & M',
    tr: 'Training',
  };
  const {icons} = useTheme();
  let icon;
  if (row.tool === 'Locomo') {
    icon = icons?.LocomoTool;
  } else if (row.tool === 'Jira') {
    icon = icons?.JiraIcon;
  } else {
    icon = icons?.OtherTool;
  }

  return (
    <Row style={{alignItems: 'center', gap: 8}}>
      <Image source={icon} />
      <PrimaryColumnCard
        primaryTitle={row?.project}
        items={[
          {value: row?.project_sub_category},
          {value: row?.code},
        ]}
      />
    </Row>
  );
};
