import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  DepartmentWiseEmployeeTabs,
  EmployeeList,
  RoleWiseEmployeeTabs,
  SkillWiseEmployeeTabs,
} from '../../employee/views/EmployeeList';
import {OrderTableWithDueAllocationsTab} from '../../order/views/OrderTable';
import {BackgroundVerificationTabs} from '../../employee/views/BackgroundVerificationTabs';
import {AllResginationTabs} from '../../employee/views/ResignationTabs';
import {ResignationActiveTable} from '../../employee/views/ResignationTables';
import {HRPendingAppraisalTab} from './HRAppraisalTabs';
import {
  ProbationConfirmationHrTabs,
  ProbationConfirmationManagerTabs,
} from '../../employee/views/ProbationConfirmationTabs';
import {
  InternshipConfirmationHrTabs,
  InternshipConfirmationManagerTabs,
} from '../../employee/views/InternshipConfirmationTabs';
import {PipResourceTabs} from '../../employee/views/PipResourceTabs';
import {getCurrentMonth} from '@unthinkable/react-filters';
import {ProjectWiseAllocationTab} from '../../order/views/ProjectWiseAllocationsTab';
import {
  BenchTab,
  DeliveryOwnersBenchTab,
  InternalTab,
  SocialTab,
  TainingTab,
  UnbilledTagTab,
} from '../../employee/views/EnggNonBilledTab';
import RMFeedbackTab from './ResourceManagementFeedbackTab';
import {EmployeeRequestTable} from '../../order/views/EmployeeRequestTable';
import {AllocationOnOrderTypeBasisTab} from '../../order/views/AllocationTab';
import {AllocatedResourceTab} from '../../order/views/AllocatedResourceTab';
import {BillingStatusReportTab} from '../../employee/views/BillingStatusReportTab';
import {EnggAnalyticsTabs} from '../../employee/views/EngineeringEmployeeAnalyticsTabs';
import {DepartmentTagsWithNavigationTable} from './DepartmentTagTable';
import {EmployeeToBeFreeTabs} from '../../employee/views/EmployeeToBeFree';
import {BenchComparisionReport} from '../../employee/views/BenchComparisionReport';
import {OrderWiseAllocationsTab} from '../../order/views/FixedOrderWiseAllocations';
import {TrainingResourceAllocatedList} from '../../order/views/DetailResourceAllocatedList';
import {EnggBilledTab} from '../../employee/views/EnggBilledTab';
import {
  ResourceAllocationTab,
  ResourceAllocationTabDO,
} from '../../order/views/ResourceAllocationTab';
import {ProjectWiseAllocationDueTab} from '../../order/views/ProjectDueAllocationsTable';
import {ReplacementTabs} from '../../employee/views/ReplacementTab';
import {EngGrossMarginTab} from '../../employee/views/EnggGrossMarginTab';
import {NonEnggTeamReport} from '../../employee/views/AllNonEnggReport';
import {BenchCostReportTab} from '../../employee/views/BenchCostReport';
import {ManagementCostTable} from '../../employee/views/ManagementCostReportTable';

export const DepartmentWiseEmployeeMenu = props => {
  const {
    route: {params},
  } = props;

  const currentMonth = getCurrentMonth();
  const menus = [
    {
      label: 'Active',
      section: true,
      menus: [
        {
          label: 'All',
          view: <DepartmentWiseEmployeeTabs />,
        },
        {
          label: 'New',
          view: (
            <EmployeeList
              addOnFilter={{
                department: params?.department,
                joining_date: {
                  $gte: currentMonth.from,
                  $lte: currentMonth.to,
                },
              }}
            />
          ),
        },
        {
          label: 'Skillwise',
          view: <SkillWiseEmployeeTabs />,
        },
        {
          label: 'Rolewise',
          view: <RoleWiseEmployeeTabs />,
        },
        {
          label: 'Tagwise',
          view: <DepartmentTagsWithNavigationTable />,
        },
      ],
    },
    {
      label: 'Resignations',
      view: <AllResginationTabs />,
    },
    {
      label: 'Pending Exits',
      view: (
        <ResignationActiveTable
          addOnFilter={{
            department: params?.department,
          }}
        />
      ),
    },
    {
      label: 'BGV',
      view: <BackgroundVerificationTabs />,
    },
    {
      label: 'Performance',
      section: true,
      menus: [
        {
          label: 'PIP',
          view: <PipResourceTabs />,
        },
        {
          label: 'Appraisal',
          view: <HRPendingAppraisalTab />,
        },
        {
          label: 'Feedback',
          view: <RMFeedbackTab />,
        },
        {
          label: 'Confirmation',
          section: true,
          menus: [
            {
              label: 'Probation',
              view: <ProbationConfirmationHrTabs />,
            },
            {
              label: 'Internship',
              view: <InternshipConfirmationHrTabs />,
            },
          ],
        },
      ],
    },
    {
      label: 'Allocations',
      section: true,
      menus: [
        {
          label: 'Requests',
          view: <OrderTableWithDueAllocationsTab />,
        },
        {
          label: 'Plan',
          view: <ReplacementTabs />,
        },
      ],
    },
    // {
    //   label: 'Employee Request',
    //   section: true,
    //   menus: [
    //     {
    //       label: 'New',
    //       view: <ProjectWiseAllocationDueTab />,
    //     },
    //   ],
    // },
    {
      label: 'Allocation Reports',
      section: true,
      menus: [
        {
          label: 'Order Wise',
          view: <OrderWiseAllocationsTab />,
        },
        {
          label: 'Employee Wise',
          view: <AllocatedResourceTab />,
        },
        // {
        //   label: 'Training',
        //   view: <TainingTab />,
        // },
        {
          label: 'Bench (DO)',
          view: <DeliveryOwnersBenchTab />,
        },
        // {
        //   label: 'Customer',
        //   view: <EnggBilledTab />,
        // },
        {
          label: 'DO Allocations',
          view: <ResourceAllocationTabDO />,
        },
        {
          label: 'Project',
          section: true,
          menus: [
            {
              label: 'Billed',
              view: <ProjectWiseAllocationTab />,
            },
            {
              label: 'Internal',
              view: <InternalTab />,
            },
            {
              label: 'Social',
              view: <SocialTab />,
            },
          ],
        },
      ],
    },
    {
      label: 'Bench',
      view: <BenchTab />,
    },
    {
      label: 'Reports',
      section: true,
      menus: [
        {
          label: 'Project Wise Allocation',
          view: <ProjectWiseAllocationTab />,
        },
        {
          label: 'Engg Gross Margin',
          view: props => {
            return <EngGrossMarginTab {...props} />;
          },
        },
        {
          label: 'Non Engg Team Report',
          view: props => {
            return <NonEnggTeamReport {...props} />;
          },
        },
        {
          label: 'Allocation Report',
          view: ({user}) => {
            return <ResourceAllocationTab user={user} />;
          },
        },
        {
          label: 'Bench Cost',
          view: ({user}, props) => {
            return <BenchCostReportTab user={user} {...props} />;
          },
        },
        {
          label: 'Management cost',
          view: ({user}) => {
            return <ManagementCostTable user={user} />;
          },
        },
      ],
    },
    {
      label: 'Employee Billing',
      section: true,
      menus: [
        {
          label: 'DO & TL Billing Status',
          view: <BillingStatusReportTab />,
        },
        {
          label: 'Analytics',
          view: <EnggAnalyticsTabs />,
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
