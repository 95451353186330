import React from 'react';
import moment from 'moment';
import {useTheme} from '@unthinkable/react-theme';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {Col, Image, Row, View} from '@unthinkable/react-core-components';
import {resolveExp} from '@unthinkable/react-utils';

import Images from '../../../images';
import AddCountCellRender from '../../../app-components/renders/AddCountCellRender';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {InlineForm} from '../../../components/form/Form';
import {Table} from '../../../components/table/Table';
import {DateRenderer} from '../../../components/form-editors/Editors';
import {MilestoneColumnCard} from '../../../components/card/MilestoneColumnCard';
import {PROJECT_MILESTONE_TYPE_ID} from '../../common/constants/SourceConstants';
import {EstHrsColumnRender} from '../cellrenders/EstHrsChipRender';
import {RenderMarkActive} from '../../task/views/TasksTable';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {Labels} from '../constants/Label';
import {Tag} from '../../../components/chip/Chip';
import {Tooltip} from '../../../components/modal/Tooltip';

const {Owner} = Images;

const DateRender = props => {
  let {row, columnField, color} = props;
  const {colors, icons, fonts} = useTheme();
  let {due_date_reason} = row || {};
  const value = resolveExp(row, columnField);
  if (!value) {
    return;
  }

  return (
    <Row gap={4} style={{alignItems: 'center'}}>
      <DateRenderer
        value={value}
        color={color?.({row})}
        styles={{
          text: {
            ...fonts.BODY2,
            color: colors.NEUTRAL_LOW,
          },
        }}
      />
      {due_date_reason ? (
        <Tooltip content={due_date_reason} width={void 0} maxWidth={200}>
          <Image style={{height: 12, width: 12}} source={icons.InfoGrey} />
        </Tooltip>
      ) : null}
    </Row>
  );
};

const StatusTargetRender = props => {
  const {row, columnField} = props;

  const value = resolveExp(row, columnField);

  if (!value) {
    return;
  }

  let color = void 0;

  let label = '';
  const currentDate = moment.utc();
  const dueDate = moment.utc(row.due_date);
  const diff = currentDate.diff(dueDate, 'days');
  if (diff > 30) {
    color = 'ERROR';
    label = `${diff} days delay`;
  } else if (diff > 0) {
    color = 'WARNING';
    label = `${diff} days delay`;
  } else {
    color = 'SUCCESS';
    label = 'On Time';
  }

  return (
    <Col gap={3} style={{alignItems: 'flex-end'}}>
      <Tag value={label} color={color} displayTextColor />
      <DateRender {...props} />
    </Col>
  );
};

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to Delete?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/milestones/${row?._id}/delete`,
      props: {},
    });
  },
});

const features = ({skipAdd} = {}) => {
  return {
    render: AddCountCellRender,
    header: Labels.Features,
    align: 'right',
    width: 110,
    skipAdd,
    totalCountField: 'objective_count',
    completedCountField: 'completed_objective_count',
    skipAdd: true,
  };
};

const milestone = {
  header: 'Milestone',
  responsive: 'sm',
  render: ({row}) => {
    const {colors} = useTheme();
    const {milestone, start_date} = row;
    const items = start_date
      ? [{value: `Start date: ${moment.utc(start_date).format('DD MMM YY')}`}]
      : [];

    return (
      <MilestoneColumnCard
        row={row}
        primaryTitle={milestone}
        items={items}
        primaryTitleColor={colors['NEUTRAL_HIGH']}
      />
    );
  },
};

const markComplete = ({invoke}) => ({
  title: 'Mark Complete',
  confirm: {
    title: 'Complete Mark',
    message: 'Are you sure you want to Mark Complete?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectmilestones/${row?._id}`,
      props: {
        status: 'completed',
        completed_on: getZeroTimeDate(new Date()),
      },
    });
  },
});

// Set the status of milestone tables to 'active' in the projectmilestones. - Rajnish Trikaldarshi 11-03-2024
const markActive = ({invoke}) => ({
  title: 'Mark Active',
  confirm: {
    title: 'Active Mark',
    message: 'Are you sure you want to Mark Active?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectmilestones/${row?._id}`,
      props: {status: 'active'},
    });
  },
});

const moveToBacklog = ({invoke}) => ({
  title: 'Move to Backlog',
  confirm: {
    title: 'Move to Backlog',
    message: 'Are you sure you want to Move to Backlog?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectmilestones/${row?._id}`,
      props: {status: 'backlog'},
    });
  },
});

const edit = ({navigation, params}) => ({
  title: 'Edit',
  onPress: ({row}) => {
    navigation.navigate('update-milestone-detail', {
      ...params,
      row,
    });
  },
});

const activityLog = ({navigation}) => ({
  title: 'Activity Logs',
  onPress: ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row?._id,
      api: `/projectmilestones/${row?._id}/activities`,
      title: row?.milestone ? `Milestone : ${row?.milestone}` : '',
      displayFields: {
        due_date: {
          label: 'Target Date',
        },
        due_date_reason: {
          label: 'Target Date Reason',
        },
      },
    });
  },
});

const hours = {
  header: 'Est.',
  render: EstHrsColumnRender,
  align: 'right',
  width: 130,
  estHrsField: 'totalEstHours',
  completedHrsField: 'totalCompletedEstHours',
};

const status_target = {
  render: StatusTargetRender,
  columnField: 'due_date',
  header: 'Status & target',
  readOnly: true,
  width: 120,
  align: 'right',
};

const targetDate = ({colors}) => ({
  render: DateRender,
  columnField: 'due_date',
  header: 'Target',
  readOnly: true,
  width: 120,
  align: 'right',
  color: ({row}) => {
    const currentDate = moment.utc();
    const dueDate = moment.utc(row.due_date);
    const diff = currentDate.diff(dueDate, 'days');
    if (diff > 30) {
      return colors.ERROR_HIGH;
    } else if (diff > 0) {
      return colors.WARNING_HIGH;
    } else {
      return colors.SUCCESS_HIGH;
    }
  },
});

const completedDate = ({colors}) => {
  return {
    type: 'date',
    field: 'completed_on',
    formatOptions: {
      format: 'DD MMM YY',
    },
    header: 'Completed on',
    width: 130,
    color: ({row}) => {
      return moment
        .utc(row.completed_on || void 0)
        .startOf('day')
        .toDate() > moment.utc(row.due_date).startOf('day').toDate()
        ? colors.ERROR_HIGH
        : colors.SUCCESS_HIGH;
    },
    align: 'right',
  };
};

const owner = {
  width: 50,
  header: {icon: Owner},
  field: 'owner_id',
  type: 'userAvatar',
};

const milestoneCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_MILESTONE_TYPE_ID, label: 'Milestone'},
      comment_source_id: {_id: row._id, name: row.milestone},
    }),
  });
};

const updateDueDate = ({navigation}) => ({
  title: 'Update Target Date',
  onPress: props => {
    const {row} = props;
    navigation.navigate('milestone-target-date-form', {
      row,
      api: '/projectmilestones',
      secondaryTitle: row?.milestone,
    });
  },
});

const markActiveColumn = ({invoke}) => ({
  width: 120,
  type: 'text',
  render: RenderMarkActive,
  align: 'right',
  onPress: ({row}) => {
    invoke({
      uri: `/projectmilestones/${row?._id}`,
      props: {
        status: 'active',
      },
    });
  },
});

const MilestoneInlineForm = ({params, defaultValues}) => {
  const {project} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Milestone',
    uri: '/projectmilestones',
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{...defaultValues, project_id: project?._id}}
      layoutFields={[
        {
          label: 'Milestone',
          field: 'milestone',
          type: 'text',
          required: true,
        },
        {
          label: 'Start Date',
          type: 'date',
          field: 'start_date',
          width: 200,
        },
        {
          label: 'Target Date',
          type: 'date',
          field: 'due_date',
          width: 200,
        },
      ]}
    />
  );
};

export const BacklogMilestoneTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {project} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Milestone',
  });

  const invoke = useInvoke({method: 'put', eventSourceId: 'Milestone'});
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      search={searchValue}
      searchFields={'milestone'}
      eventSourceId={['Milestone', 'Feature', 'Comment']}
      api={`/project/${project?._id}/milestones`}
      filter={{status: 'backlog'}}
      renderHeader={() => {
        return (
          <MilestoneInlineForm
            params={params}
            defaultValues={{status: 'backlog'}}
          />
        );
      }}
      onRowPress={({row}) => {
        if (navigateTo) {
          navigateTo(`milestone-menu-new`, {
            ...params,
            milestone: row,
            milestone_id: row._id,
            breadcrumb: {
              dropdown: {
                field: 'milestone',
              },
            },
          });
        } else {
          navigation.navigate('milestone-menu', {
            ...params,
            milestone: row,
          });
        }
      }}
      columns={[
        milestone,
        features({params, navigation}),
        hours,
        owner,
        status_target,
        milestoneCommentColumn({params, navigation}),
        markActiveColumn({invoke}),
      ]}
      moreActions={[
        edit({navigation, params}),
        updateDueDate({navigation, params}),
        activityLog({navigation}),
        deleted({deleteInvoke}),
      ]}
    />
  );
};

export const ActiveMilestoneTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {project} = params;

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Milestone',
  });

  const invoke = useInvoke({method: 'put', eventSourceId: 'Milestone'});
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      search={searchValue}
      searchFields={'milestone'}
      eventSourceId={['Milestone', 'Feature', 'Comment']}
      api={`/project/${project._id}/milestones`}
      filter={{status: 'active'}}
      renderHeader={() => {
        return (
          <MilestoneInlineForm
            params={params}
            defaultValues={{status: 'active'}}
          />
        );
      }}
      onRowPress={({row}) => {
        if (navigateTo) {
          navigateTo(`milestone-menu-new`, {
            ...params,
            milestone: row,
            milestone_id: row._id,
            breadcrumb: {
              dropdown: {
                field: 'milestone',
              },
            },
          });
        } else {
          navigation.navigate('milestone-menu', {
            ...params,
            milestone: row,
          });
        }
      }}
      columns={[
        milestone,
        features({params, navigation}),
        hours,
        owner,
        status_target,
        milestoneCommentColumn({params, navigation}),
      ]}
      moreActions={[
        edit({navigation, params}),
        updateDueDate({navigation, params}),
        markComplete({invoke}),
        moveToBacklog({invoke}),
        activityLog({navigation}),
        deleted({deleteInvoke}),
      ]}
    />
  );
};

export const CompletedMilestoneTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {project} = params;
  const invoke = useInvoke({method: 'put', eventSourceId: 'Milestone'});
  const colors = useTheme('colors');
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      search={searchValue}
      sort={{completed_on: -1}}
      searchFields={'milestone'}
      eventSourceId={['Milestone', 'Feature', 'Comment']}
      api={`/project/${project?._id}/milestones`}
      filter={{status: 'completed'}}
      onRowPress={({row}) => {
        if (navigateTo) {
          navigateTo(`milestone-menu-new`, {
            ...params,
            milestone: row,
            milestone_id: row._id,
            breadcrumb: {
              dropdown: {
                field: 'milestone',
              },
            },
          });
        } else {
          navigation.navigate('milestone-menu', {
            ...params,
            milestone: row,
          });
        }
      }}
      columns={[
        milestone,
        features({params, navigation, skipAdd: true}),
        hours,
        owner,
        targetDate({colors}),
        completedDate({colors}),
        milestoneCommentColumn({params, navigation}),
      ]}
      moreActions={() => [markActive({invoke}), activityLog({navigation})]}
    />
  );
};
