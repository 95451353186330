import {TextAreaInput} from '../form-editors/Editors';

export const JSONTextAreaInput = props => {
  const {
    field,
    form: {setFieldError},
    value,
  } = props;
  return <TextAreaInput {...props} />;
};
