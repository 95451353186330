import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const ClientUserForm = props => {
  let {
    route: {params = {}},
  } = props || {};
  let {onSubmit} = useFormSubmit({
    uri: '/users',
    eventSourceId: 'ClientUser',
    ...props,
  });
  let {row} = params || {};
  const {user} = useAppStateContext();
  const {org} = user;
  return (
    <Form
      api={`/users/${row?._id}`}
      fields={{
        name: 1,
        email: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        org,
        userType: 'client',
        status: 'a',
        password:
          'ef797c8118f02dfb649607dd5d3f8c7623048c9c063d532cc95c5ed7a898a64f',
      }}
      layoutFields={[
        {
          type: 'text',
          field: 'name',
          label: 'Name',
          required: true,
        },
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddUserAllFormHOC = props => {
  const {
    route: {params = {}},
  } = props;

  const {onSubmit} = useFormSubmit({
    uri: '/users',
    eventSourceId: 'usersetup',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        password:
          'ef797c8118f02dfb649607dd5d3f8c7623048c9c063d532cc95c5ed7a898a64f',
        status: 'a',
      }}
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          // editable: false,
        },
        {
          label: 'Email',
          type: 'text',
          field: 'email',
          // editable: false,
        },
        {
          label: 'Phone number',
          type: 'text',
          field: 'mobile',
        },
        {
          field: 'userType',
          type: 'autoComplete',
          options: ['employee', 'client'],
          label: 'User type',
        },
        // {
        //   label: 'Password',
        //   type: 'text',
        //   field: 'password',
        // },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          visible: ({values}) => values && values.userType == 'employee',
        },
        {
          field: 'externalUser',
          type: 'checkbox',
          label: 'External user',
        },
      ]}
      {...props}
    />
  );
};

export const AllUserSetupForm = props => {
  const {
    route: {params = {}},
  } = props;

  let {
    row: {_id},
  } = params;
  const {onSubmit} = useFormSubmit({
    uri: '/users',
    eventSourceId: 'usersetup',
    ...props,
  });

  return (
    <Form
      api={`/users/${_id}`}
      fields={{
        name: 1,
        password: 1,
        email: 1,
        mobile: 1,
        userType: 1,
        employee: {name: 1},
        externalUser: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          editable: false,
        },
        {
          label: 'Email',
          type: 'text',
          field: 'email',
          // editable: false,
        },
        {
          label: 'Phone number',
          type: 'text',
          field: 'mobile',
        },
        {
          label: 'Password',
          type: 'text',
          field: 'password',
        },
        {
          field: 'userType',
          type: 'autoComplete',
          options: ['employee', 'client'],
          label: 'User type',
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          visible: ({values}) => values && values.userType == 'employee',
          // required: true,
          // size: 6,
        },
        {
          field: 'externalUser',
          type: 'checkbox',
          label: 'External user',
        },
      ]}
      {...props}
    />
  );
};

export const ClientAddUserForm = props => {
  return (
    <ClientUserForm
      header={{
        title: 'Add User',
      }}
      {...props}
    />
  );
};

export const ClientEditUserForm = props => {
  return (
    <ClientUserForm
      mode="edit"
      header={{
        title: 'Edit User',
      }}
      {...props}
    />
  );
};

export const ClientDetailUserForm = props => {
  return (
    <ClientUserForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail User',
      }}
      {...props}
    />
  );
};

export const AddAllUserForm = props => {
  return (
    <AddUserAllFormHOC
      header={{
        title: 'Add User',
      }}
      {...props}
    />
  );
};

export const UpdateAllUsers = props => {
  return <AllUserSetupForm mode="edit" header="Update User" {...props} />;
};
