import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const UpdateCampaignForm = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Update Campaign'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Campaign',
          placeholder: 'Campaign',
          field: 'campaign_type',
          type: 'cascader',
          cascades: [
            {
              field: 'campaign_type',
              label: 'Campaign',
              api: '/types',
              fields: {name: 1, display_field: 1},
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              params: ({values}) => {
                const {campaign_type} = values || {};
                return {
                  source: campaign_type,
                };
              },
              label: 'Campaign',
              placeholder: 'Campaign',
              field: 'campaign_id',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                navigation,
                params,
              }),
            },
          ],
        },
      ]}
    />
  );
};
