import React, {useState} from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useInvoke} from '../../../controllers/useInvoke';

const checkCurlyBraces = sentence => {
  const regex = /\{{3}[^{}]*\}{3}/g;

  let processedSentence = sentence.replace(regex, '');

  if (processedSentence.includes('{{{') || processedSentence.includes('}}}')) {
    return false;
  }

  return true;
};

export const AddCampaignMessageForm = props => {
  const {
    route: {params},
    header = 'Add Message',
    mode,
    isDualMode = false,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/campaignmessages',
    eventSourceId: ['campaignMessageAdded'],
  });
  const {defaultValues, campaign_id} = params;

  const {parent_id} = defaultValues || {};

  const {communication_type} = campaign_id || {};
  const invoke = useInvoke({method: 'put', eventSourceId: 'versionUpdated'});

  return (
    <Form
      fields={{
        action: 1,
        send_after: 1,
        send_after_span: 1,
        status: 1,
        enable_thread: 1,
        enable_link_tracking: 1,
        // campaign_usp_id: {usp_id: {name: 1}},
        body: 1,
        subject: 1,
        personalize_with_ai: 1,
        personalize_subject_with_ai: 1,
        usp_id: {name: 1},
        template: 1,
      }}
      onSubmit={onSubmit}
      afterSubmit={props => {
        if (parent_id) {
          invoke({
            uri: `/campaignMessages/${parent_id}`,
            props: {
              status: 'inactive',
            },
          });
        }
      }}
      beforeSubmit={({data}) => {
        if (data?.template === 'Manual' && data?.personalize_with_ai) {
          data = {...data, manual_personalization: true};
        }

        return {data};
      }}
      header={header}
      defaultValues={{
        created_on: getZeroTimeDate(new Date()),
        ...defaultValues,
      }}
      layoutFields={[
        {
          fields: [
            {
              field: 'template',
              label: 'Template',
              type: 'radioGroup',
              options: ['AI', 'Manual'],
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                value === 'AI'
                  ? setFieldValue('personalize_with_ai', true)
                  : setFieldValue('personalize_with_ai', null);
                value === 'AI'
                  ? setFieldValue('personalize_subject_with_ai', true)
                  : setFieldValue('personalize_subject_with_ai', null);
              },
            },
          ],
        },
        [
          {
            field: 'action',
            type: 'autoComplete',
            label: 'Action',
            size: 'medium',
            options: [
              'connection_request',
              'direct_message',
              'inmail_message',
              'follow_request',
              'email',
              'degree_of_connection',
              'like_post',
            ],
          },
          {
            field: 'usp_id',
            type: 'autoComplete',
            label: 'USP',
            api: '/uniquesellingpoints',
            suggestionField: 'name',
            valueField: 'name',
            size: 'medium',
            onChangeValue: (value, e, {setFieldValue}) => {
              setFieldValue('name', value?.name);
            },
          },
        ],

        {
          field: 'send_after',
          type: 'number',
          label: 'Delay',
          size: 3,
          visible: !isDualMode,
        },
        {
          field: 'send_after_span',
          type: 'autoComplete',
          label: 'Span',
          options: ['Minutes', 'Hours', 'Days'],
          size: 3,
          visible: !isDualMode,
        },

        {
          field: 'status',
          type: 'autoComplete',
          label: 'Status',
          options: ['active', 'archived'],
          visible: () => mode === 'edit' && !isDualMode,
        },
        {
          label: 'Enable Thread',
          field: 'enable_thread',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          size: 3,
          visible: props => {
            const {values} = props || {};
            const {action} = values || {};
            return action === 'email' && !isDualMode;
          },
        },
        {
          label: 'Enable Link Tracking',
          field: 'enable_link_tracking',
          type: 'autoComplete',
          size: 3,
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
          visible: props => {
            const {values} = props || {};
            const {action} = values || {};
            return (
              !isDualMode &&
              (action === 'email' ||
                action === 'inmail_message' ||
                action === 'direct_message')
            );
          },
        },
        {
          label: 'Subject',
          placeholder: 'Subject',
          field: 'subject',
          type: 'textArea',
          visible: ({values}) =>
            values?.action === 'email' && values?.template === 'Manual',
          validate: value => {
            if (value) {
              const isValid = checkCurlyBraces(value);
              if (!isValid) {
                return 'Check curly braces';
              }
            }
          },
        },

        {
          label: 'Body',
          placeholder: 'Body',
          field: 'body',
          type: 'textArea',
          visible: ({values}) => values?.template === 'Manual',
          validate: value => {
            if (value) {
              const isValid = checkCurlyBraces(value);
              if (!isValid) {
                return 'Check curly braces';
              }
            }
          },
        },

        {
          field: 'personalize_with_ai',
          type: 'checkbox',
          label: 'Personalize With AI',
          visible: ({values}) => values?.template === 'Manual',
          onChangeValue: (value, e, {setFieldValue}) => {
            value
              ? setFieldValue('personalize_subject_with_ai', true)
              : setFieldValue('personalize_subject_with_ai', null);
          },
        },
        // {
        //   field: 'personalize_subject_with_ai',
        //   type: 'checkbox',
        //   label: 'Personalize Subject With AI',
        //   visible: ({values}) => values?.action === 'email',
        // },
      ]}
      {...props}
    />
  );
};

export const AddCampaignMessageFormEdit = props => {
  const {
    route: {
      params: {
        row: {_id},
        dualMode,
      },
    },
  } = props;
  return (
    <AddCampaignMessageForm
      mode="edit"
      api={`/campaignmessages/${_id}`}
      header={'Edit Campaign Message'}
      isDualMode={dualMode}
      {...props}
    />
  );
};
