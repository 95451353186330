import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const OutReachBatchForm = props => {
  const {
    route: {params},
    defaultValue,
    header = 'Add Outreach Batch',
    navigation,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/outreachbatches',
    ...props,
    eventSourceId: 'outreachBatch',
  });
  const {department, product, campaign_type, row} = params;
  return (
    <Form
      api={`/outreachbatches/${row?._id}`}
      fields={{
        name: 1,
        message_send: 1,
        sales_person_id: {name: 1},
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction={'Save'}
      defaultValues={{
        ...defaultValue,
        department: department?._id,
        product: product?._id,
        campaign_type: campaign_type,
      }}
      layoutFields={[
        {
          placeholder: 'Batch',
          field: 'name',
          type: 'text',
        },
        {
          type: 'autoComplete',
          label: 'Campaign',
          placeholder: 'Campaign',
          field: 'campaign_id',
          api: '/emailcampaigns',
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) =>
            values?.campaign_type?._id == '64d0fd55ab78dfead48c144a',
        },
        {
          type: 'autoComplete',
          label: 'Campaign',
          placeholder: 'Campaign',
          field: 'campaign_id',
          api: '/inmailcampaigns',
          suggestionField: 'name',
          valueField: 'name',
          visible: ({values}) =>
            values?.campaign_type?._id == '64d0fd55ab78dfead48c144b',
        },
        {
          placeholder: 'Sales Person',
          field: 'sales_person_id',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
        },
        {
          placeholder: 'Message Send',
          field: 'message_send',
          type: 'autoComplete',
          options: ['Auto', 'Manual'],
        },
        {
          placeholder: 'Woodpecker CampaignIDs(comma separated)',
          field: 'woodpecker_campaign_id',
          type: 'text',
          visible: ({values}) =>
            values?.campaign_type?._id == '64d0fd55ab78dfead48c144a',
        },
      ]}
      {...props}
    />
  );
};

export const EditOutReachBatchForm = props => {
  return (
    <AdCreativeForm mode="edit" header={'Edit Outreach Batch'} {...props} />
  );
};
