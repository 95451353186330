import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';

export const PotentialCustomerForm = props => {
  const {
    navigation,
    route: {params},
    add,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  const {post, user} = useAppStateContext();

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'type',
          options: ['Individual', 'Organization'],
          dependentFields: ['email', 'website'],
        },
        {
          fields: [
            {
              type: 'text',
              field: 'name',
              placeholder: 'Person name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'phone',
              placeholder: 'Phone',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'email',
              placeholder: 'Email',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'designation',
              placeholder: 'Designation',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'name',
              placeholder: 'Organization name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'text',
              field: 'website',
              placeholder: 'Website',
              required: true,
              validate: async fieldValue => {
                if (!add) {
                  return;
                }
                const {data} = await post({
                  uri: `/potentialcustomers/websiteValidation`,
                  method: 'post',
                  props: {
                    website: fieldValue.toLowerCase(),
                  },
                });
                if (data) {
                  return data;
                }
              },
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'text',
              field: 'linkedin_url',
              placeholder: 'LinkedIn URL',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: `/industries`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Industry',
              label: 'Industry',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'country',
              placeholder: 'Country',
              label: 'Country',
              type: 'autoComplete',
              api: `/countries`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'geography_location',
              placeholder: 'Location',
              label: 'Geography Location',
              type: 'autoComplete',
              api: `/geographylocations`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'organization_category',
              placeholder: 'Organization Category',
              label: 'Organization Category',
              type: 'autoComplete',
              api: `/organizationcategories`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'employee_count',
              placeholder: 'Employee Count',
              label: 'Employee Count',
              type: 'autoComplete',
              api: `/employeeSize/active`,
              suggestionField: 'number',
              valueField: 'number',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'exact_employee_count',
              placeholder: 'Exact Employee Count',
              label: 'Exact Employee Count',
              type: 'number',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              label: 'Target Audience',
              placeholder: 'Target Audience',
              field: 'buyer_persona',
              type: 'autoComplete',
              api: `/buyerpersonas`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Inside Sales',
              placeholder: 'Inside Sales',
              field: 'inside_sales',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
            },
            {
              placeholder: 'Product',
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Channel',
              field: 'channel',
              type: 'autoComplete',
              api: `/channels`,
              suggestionField: 'name',
              valueField: 'name',
              filter: {
                _id: {
                  $nin: ['66d2ec16f897151a53daf08b'],
                },
              },
              size: 6,
            },
            {
              label: 'Team',
              field: 'employee_team',
              type: 'autoComplete',
              api: `/employeeteams`,
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
        {
          label: 'Campaign Details',
          fields: [
            {
              placeholder: 'Channel & Campaign',
              label: 'Channel & Campaign',
              field: 'campaign_type',
              type: 'cascader',
              required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'campaign_type',
                  api: '/types/suggestionsForLead',
                  suggestionField: 'label',
                  valueField: 'label',
                  required: true,
                },
                {
                  label: 'Campaign Value',
                  placeholder: 'Campaign Id',
                  field: 'campaign_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  params: ({values: {campaign_type} = {}}) => {
                    return {
                      source: campaign_type,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'textArea',
              field: 'description',
              placeholder: 'Description',
              label: 'Description',
            },
          ],
        },
        {
          groups: [
            // {
            //   label: 'Campaign Details',
            //   fields: [
            //     {
            //       placeholder: 'Channel & Campaign',
            //       label: 'Channel & Campaign',
            //       field: 'campaign_type',
            //       type: 'cascader',
            //       cascades: [
            //         {
            //           label: 'Select Source',
            //           field: 'campaign_type',
            //           api: '/types/suggestionsForLead',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           required: true,
            //         },
            //         {
            //           label: 'Campaign Value',
            //           placeholder: 'Campaign Id',
            //           field: 'campaign_id',
            //           type: 'autoComplete',
            //           api: '/sourceSuggestions',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           required: true,
            //           params: ({values: {campaign_type} = {}}) => {
            //             return {
            //               source: campaign_type,
            //             };
            //           },
            //           onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
            //             navigation,
            //             params,
            //           }),
            //         },
            //       ],
            //     },
            //     {
            //       type: 'textArea',
            //       field: 'description',
            //       placeholder: 'Description',
            //       label: 'Description',
            //     },
            //   ],
            // },
            {
              label: 'Point of Contact',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              fields: [
                {
                  field: 'contact_detail',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          header: 'First Name',
                          type: 'text',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          header: 'Last Name',
                          type: 'text',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          header: 'Designation',
                          type: 'text',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          header: 'Email',
                          type: 'text',
                        },
                        {
                          field: 'linkedin_url',
                          placeholder: 'Linkedin',
                          header: 'Linkedin',
                          type: 'text',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Phone',
                          header: 'Phone',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const PotentialCustomerFormForLead = props => {
  const {
    navigation,
    route: {params},
    readOnly,
    add,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/potentialcustomers',
    eventSourceId: 'Lead',
    ...props,
  });
  const {post, user} = useAppStateContext();

  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      submitAction={'Save'}
      readOnly={readOnly}
      // computations={{
      //   uql_stage_date: [
      //     value => {
      //       const {stage} = value;
      //       if (stage?.name == 'UQL') {
      //         return new Date();
      //       }
      //     },
      //     ['stage'],
      //   ],
      // }}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'type',
          options: ['Individual', 'Organization'],
          dependentFields: ['email', 'website'],
        },
        {
          fields: [
            {
              type: 'text',
              field: 'name',
              placeholder: 'Name',
              label: 'Name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'phone',
              placeholder: 'Phone',
              label: 'Phone',
              // required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'email',
              placeholder: 'Email',
              label: 'Email',
              // required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'designation',
              placeholder: 'Designation',
              label: 'Designation',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              size: 3,
            },
            {
              type: 'text',
              field: 'name',
              placeholder: 'Organization name',
              label: 'Organization name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'text',
              field: 'website',
              placeholder: 'Website',
              label: 'Website',
              required: true,
              validate: async fieldValue => {
                if (!add) {
                  return;
                }
                const {data} = await post({
                  uri: `/potentialcustomers/websiteValidation`,
                  method: 'post',
                  props: {
                    website: fieldValue.toLowerCase(),
                  },
                });
                if (data) {
                  return data;
                }
              },
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: `/industries`,
              suggestionField: 'name',
              valueField: 'name',
              placeholder: 'Industry',
              label: 'Industry',
              label: 'Industry',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 4,
            },
            {
              field: 'country',
              placeholder: 'Country',
              label: 'Country',
              type: 'autoComplete',
              api: `/countries`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'geography_location',
              placeholder: 'Geography Location',
              label: 'Geography Location',
              type: 'autoComplete',
              api: `/geographylocations`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'organization_category',
              placeholder: 'Organization Category',
              label: 'Organization Category',
              type: 'autoComplete',
              api: `/organizationcategories`,
              suggestionField: 'name',
              required: true,
              valueField: 'name',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            {
              field: 'employee_count',
              placeholder: 'Employee Count',
              label: 'Employee Count',
              type: 'autoComplete',
              api: `/employeeSize/active`,
              suggestionField: 'number',
              valueField: 'number',
              required: true,
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              size: 6,
            },
            // {
            //   field: 'exact_employee_count',
            //   placeholder: 'Exact Employee Count',
            //   label: 'Exact Employee Count',
            //   type: 'number',
            //   visible: ({values}) => {
            //     return values?.type == 'Organization';
            //   },
            //   size: 6,
            // },
            // {
            //   label: 'Target Audience',
            //   placeholder: 'Target Audience',
            //   field: 'buyer_persona',
            //   type: 'autoComplete',
            //   api: `/buyerpersonas`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   size: 6,
            // },
            {
              label: 'Inside Sales',
              placeholder: 'Inside Sales',
              field: 'inside_sales',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Owner',
              placeholder: 'Owner',
              field: 'owner',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Channel',
              field: 'channel',
              type: 'autoComplete',
              api: `/channels`,
              suggestionField: 'name',
              valueField: 'name',
              filter: {
                _id: {
                  $nin: ['66d2ec16f897151a53daf08b'],
                },
              },
              size: 6,
            },
            {
              label: 'Team',
              field: 'employee_team',
              type: 'autoComplete',
              api: `/employeeteams`,
              filter: {
                _id: {
                  $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
                },
              },
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              placeholder: 'Product',
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Stage',
              field: 'stage',
              type: 'autoComplete',
              api: `/stages/lead-creation`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value?.name == 'UQL') {
                  setFieldValue('uql_stage_date', new Date());
                }
              },
            },
            {
              type: 'date',
              field: 'uql_stage_date',
              label: 'Uql Date',
              placeholder: 'Uql Date',
              size: 6,
              visible: ({values}) => {
                return values?.stage?.name == 'UQL';
              },
            },
          ],
        },
        {
          label: 'Campaign Details',
          fields: [
            {
              placeholder: 'Channel & Campaign',
              label: 'Channel & Campaign',
              field: 'campaign_type',
              type: 'cascader',
              required: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'campaign_type',
                  api: '/types/suggestionsForLead',
                  suggestionField: 'label',
                  valueField: 'label',
                },
                {
                  label: 'Campaign Value',
                  placeholder: 'Campaign Id',
                  field: 'campaign_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  params: ({values: {campaign_type} = {}}) => {
                    return {
                      source: campaign_type,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
            {
              type: 'textArea',
              field: 'description',
              placeholder: 'Description',
              label: 'Description',
            },
          ],
        },
        {
          groups: [
            {
              label: 'Organization Details',
              visible: ({values}) => {
                return values?.type == 'Individual';
              },
              fields: [
                {
                  type: 'text',
                  field: 'company_name',
                  placeholder: 'Name',
                  label: 'Name',
                  required: true,
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'website',
                  placeholder: 'Website',
                  // required: true,
                  size: 4,
                },
                {
                  type: 'autoComplete',
                  field: 'industry',
                  api: `/industries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  placeholder: 'Industry',
                  label: 'Industry',
                  required: true,
                  size: 4,
                },
                {
                  field: 'country',
                  placeholder: 'Country',
                  label: 'Country',
                  type: 'autoComplete',
                  api: `/countries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 6,
                },
                {
                  field: 'geography_location',
                  placeholder: 'Location',
                  label: 'Geography Location',
                  type: 'autoComplete',
                  api: `/geographylocations`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 6,
                },
                {
                  field: 'employee_count',
                  placeholder: 'Employee Count',
                  label: 'Employee Count',
                  type: 'autoComplete',
                  api: `/employeeSize/active`,
                  suggestionField: 'number',
                  valueField: 'number',
                  required: true,
                  size: 6,
                },
                {
                  field: 'exact_employee_count',
                  placeholder: 'Exact Employee Count',
                  label: 'Exact Employee Count',
                  type: 'number',
                  size: 6,
                },
                {
                  field: 'organization_category',
                  placeholder: 'Organization Category',
                  label: 'Organization Category',
                  type: 'autoComplete',
                  api: `/organizationcategories`,
                  suggestionField: 'name',
                  required: true,
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            // {
            //   label: 'Campaign Details',
            //   fields: [
            //     {
            //       placeholder: 'Channel & Campaign',
            //       label: 'Channel & Campaign',
            //       field: 'campaign_type',
            //       type: 'cascader',
            //       required: true,
            //       cascades: [
            //         {
            //           label: 'Select Source',
            //           field: 'campaign_type',
            //           api: '/types/suggestionsForLead',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //         },
            //         {
            //           label: 'Campaign Value',
            //           placeholder: 'Campaign Id',
            //           field: 'campaign_id',
            //           type: 'autoComplete',
            //           api: '/sourceSuggestions',
            //           suggestionField: 'name',
            //           valueField: 'name',
            //           params: ({values: {campaign_type} = {}}) => {
            //             return {
            //               source: campaign_type,
            //             };
            //           },
            //           onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
            //             navigation,
            //             params,
            //           }),
            //         },
            //       ],
            //     },
            //     {
            //       type: 'textArea',
            //       field: 'description',
            //       placeholder: 'Description',
            //       label: 'Description',
            //     },
            //   ],
            // },
            {
              label: 'Point of Contact',
              visible: ({values}) => {
                return values?.type == 'Organization';
              },
              fields: [
                {
                  field: 'contact_detail',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          field: 'first_name',
                          placeholder: 'First Name',
                          header: 'First Name',
                          type: 'text',
                        },
                        {
                          field: 'last_name',
                          placeholder: 'Last Name',
                          header: 'Last Name',
                          type: 'text',
                        },
                        {
                          field: 'designation',
                          placeholder: 'Designation',
                          header: 'Designation',
                          type: 'text',
                        },
                        {
                          field: 'email',
                          placeholder: 'Email',
                          header: 'Email',
                          type: 'text',
                        },
                        {
                          field: 'linkedin_url',
                          placeholder: 'Linkedin',
                          header: 'Linkedin',
                          type: 'text',
                        },
                        {
                          field: 'phone_no',
                          placeholder: 'Phone',
                          header: 'Phone',
                          type: 'text',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Consultants',
              fields: [
                {
                  label: 'Technical Consultant',
                  field: 'tech_consultant',
                  type: 'multiAutoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Solution Consultant',
                  field: 'solution_consultant',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Business Analyst',
                  field: 'business_analyst',
                  type: 'multiAutoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Business Consultant',
                  field: 'business_consultant',
                  type: 'multiAutoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
