import React, {memo} from 'react';
import {Table as TableComponent} from '@unthinkable/react-table';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {
  BorderedGroupTableStyles,
  CardTableStyles,
  DashboardGroupTableStyles,
  GroupTableStyles,
  RecursiveTableStyles,
  RecursiveTableV2Styles,
  TableStyles,
} from './theme';
import ColumnRenders from './ColumnRenders';
import ColumnFormats from './ColumnFormats';
import ColumnStyles from './ColumnStyles';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useAppStateContext} from '../../providers/AppState';
import {useModuleGlobalParams} from '../../controllers/useModuleGlobalParams';
import Lottie from 'react-lottie';
import animationData from './lottieImages/NoData.json';
import {removeRouteParams} from '../menu/Utility';
import {EmptyState} from '../helpguide/EmptyState';
import {useNavigation} from '@react-navigation/native';

export const NoDataFound = props => {
  let defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const navigation = useNavigation();

  const {user} = useAppStateContext();

  const {helpId} = props || {};

  const {help_contents = {}} = user || {};

  const {title, video_title, help_items = []} = help_contents[helpId] || {};

  const videoContent = help_items && help_items[0];

  const navigateUri =
    (videoContent?.videoUrl || videoContent?.image) &&
    (videoContent?.how_title || videoContent?.why_title)
      ? 'help-guide-video-player'
      : 'help-guide-video-player-no-data';

  return helpId && help_contents[helpId] ? (
    <EmptyState
      title={title}
      subText={video_title}
      onPlay={() => {
        navigation.navigate(navigateUri, {videoContent});
      }}
    />
  ) : (
    <Lottie options={defaultOptions} height={300} width={300} />
  );
};

export const BaseTable = memo(props => {
  const {fetch} = useAppStateContext();
  const globalParams = useModuleGlobalParams(props);
  const {colors} = useTheme();
  let {params, ...restProps} = props;
  params = removeRouteParams(props);

  return (
    <TableComponent
      columnRenders={ColumnRenders}
      columnFormats={ColumnFormats}
      columnStyles={ColumnStyles}
      renderLoader={() => <ActivityIndicator />}
      fetch={fetch}
      variant="outline"
      borderColor={colors.OUTLINE}
      renderNoData={NoDataFound}
      globalParams={globalParams}
      params={params}
      {...restProps}
    />
  );
});

export const Table = props => {
  let styles = useStyles(TableStyles);
  let groupStyles = useStyles(GroupTableStyles);
  let extraProps = {};
  if (props?.groupRow) {
    extraProps.variant = 'none';
    extraProps.styles = groupStyles;
  }
  return <BaseTable {...props} styles={styles} {...extraProps} />;
};

export const DashboardGroupTable = props => {
  let styles = useStyles(DashboardGroupTableStyles);
  return <BaseTable {...props} styles={styles} variant="none" />;
};

export const RecursiveTable = props => {
  let styles = useStyles(RecursiveTableStyles);
  return (
    <BaseTable
      showLine
      recursiveRowIndent
      {...props}
      variant="none"
      skipRowBorder
      styles={styles}
    />
  );
};

export const RecursiveTableV2 = props => {
  let styles = useStyles(RecursiveTableV2Styles);
  return (
    <BaseTable
      showLine={false}
      recursiveRowIndent
      connectorIndentation={0}
      {...props}
      variant="none"
      skipRowBorder
      styles={styles}
    />
  );
};

export const CardTable = props => {
  let styles = useStyles(CardTableStyles);
  return <BaseTable {...props} styles={styles} variant="none" />;
};

export const GroupTable = props => {
  let styles = useStyles(GroupTableStyles);
  return <BaseTable {...props} styles={styles} variant="none" />;
};

export const BorderedGroupTable = props => {
  let styles = useStyles(BorderedGroupTableStyles);
  return <BaseTable {...props} styles={styles} />;
};
