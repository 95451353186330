import React from 'react';
import {Col} from '@unthinkable/react-core-components';
import {InlineForm} from '../../../components/form/Form';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const DepartmentTagTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department} = params;

  const {onSubmit} = useFormSubmit({
    uri: `/departmentTags`,
    eventSourceId: ['DepartmentTag'],
  });

  return (
    <Table
      eventSourceId="DepartmentTag"
      api={`/departmenttags`}
      renderHeader={() => (
        <Col>
          <TableHeader
            title="Department Tags"
            secondaryTitle={department?.name}
          />
          <InlineForm
            layoutFields={[
              {
                field: 'name',
                type: 'text',
                label: 'Name',
              },
              {
                field: 'path',
                type: 'text',
                label: 'Path',
              },
            ]}
            defaultValues={{
              department: department,
            }}
            onSubmit={onSubmit}
          />
        </Col>
      )}
      fields={{
        name: 1,
        path: 1,
      }}
      filter={{
        department: department,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'path',
          header: 'Path',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-department-tag', {
              departmentTag: row,
            });
          },
        },
      ]}
    />
  );
};

export const DepartmentTagsWithNavigationTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department, title} = params;

  return (
    <Table
      eventSourceId="DepartmentTag"
      api={`/departmenttags`}
      renderHeader={() => <TableHeader title="Tags" />}
      fields={{
        name: 1,
        path: 1,
      }}
      filter={{
        department: department,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(row.path, {
          tag: row,
          navTitle: row.name,
          title: title,
          department: department,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};
