import React, {useContext, useMemo} from 'react';
import {useAppModuleContext} from '../providers/AppModuleProvider';
import {
  globalScreenMapping,
  moduleMenus,
} from '../modules/AppModulesNavigation';
import {useAppStateContext} from '../providers/AppState';
import {isAccessible} from '../modules/common/constants/SourceConstants';

export const RoleContext = React.createContext();

export const useRoleContext = () => {
  return useContext(RoleContext);
};

function filterMenuItemsByRoles({menuData = [], roles}) {
  return menuData.reduce((filteredItems, item) => {
    const hasValidRole =
      item.role && item.role.some(role => roles.includes(role));

    if (hasValidRole && item?.menuItems?.length) {
      item.menuItems = filterMenuItemsByRoles({
        menuData: item.menuItems,
        roles,
      });
    }
    if (hasValidRole || !item.role) {
      filteredItems.push(item);
    }

    return filteredItems;
  }, []);
}

// to be updated for group and section work
function collectIds(menuItems = []) {
  let ids = [];

  menuItems.forEach(item => {
    ids.push(item.id);

    if (item.menuItems && item.menuItems.length > 0) {
      ids = ids.concat(collectIds(item.menuItems));
    }
  });

  return ids;
}

function getScreens(screenData, menuItems, roles) {
  const screenList = collectIds(menuItems);

  screenData = screenData.filter(screen =>
    screenList.includes(screen.menuItemId),
  );

  const filterViews = views => {
    return views
      .map(view => {
        if (Array.isArray(view)) {
          // for group support
          return filterViews(view);
        }
        if (!view.role || view.role.some(role => roles.includes(role))) {
          return view;
        }
        return null;
      })
      .filter(view => !(Array.isArray(view) && !view.length) && view !== null);
  };

  return screenData
    .map(item => {
      const filteredViews = filterViews(item.views);

      return {
        ...item,
        views: filteredViews,
      };
    })
    .filter(item => item.views.length > 0);
}

const backendModules = {
  crm: 1,
  user: 1,
  organization: 1,
  attendancemanagement: 1,
  recruitment: 1,
  hrm: 1,
  finance: 1,
  skill: 1,
  asset: 1,
  payrollmanagement: 1,
  revenuemanagement: 1,
  expensemanagement: 1,
  task: 1,
  pmt: 1,
  helpmanagement: 1,
};

const filterBackendModules = ({user, moduleId}) => {
  const {module_navigation = {}} = user || {};

  let module = module_navigation[moduleId];

  let menuItem = {};
  let {id, menus = [], screens = []} = module || {};

  for (let screen of screens) {
    let {views = []} = screen || {};

    views = views.map(item => {
      let {menus} = item || {};
      if (Array.isArray(menus)) {
        menus = menus.map(menu => {
          return {...menu, ...globalScreenMapping[menu.id]};
        });
        item.menus = menus;
      }

      return {...item, ...globalScreenMapping[item.id]};
    });

    screen.views = views;
  }

  menus = menus.map(item => {
    let {menus = []} = item || {};

    if (Array.isArray(menus)) {
      menus = menus.map(menu => {
        let {addOnMenus = {}} = moduleMenus[id] || {};
        return {...menu, ...addOnMenus[menu.id]};
      });
    }
    item.menus = menus;
    let {addOnMenus = {}} = moduleMenus[id] || {};

    return {...item, ...addOnMenus[item.id]};
  });
  screens = [...screens, ...(moduleMenus[id]?.addOnScreen || [])];
  menuItem = {menus: menus, screens: screens};

  if(moduleId === 'task' && user?.email === 'yogesh@daffodilsw.com'){
    let {menus} = menuItem;
    menus = menus.filter(item => item.id !== 'todaysfollowupmenu');
    menuItem = {menus, screens };
  }
  
  return menuItem;
};
const filterMenus = ({user, menu, module, menuSection}) => {
  const accessible = isAccessible({
    user,
    menu,
    module,
    menuSection,
    fromMenu: true,
  });
  if (!accessible) {
    return null;
  }
  if (!menu.menus || !Array.isArray(menu.menus) || menu.menus.length === 0) {
    return menu;
  }
  const childMenus = [];
  for (let child of menu.menus) {
    let childVisible = filterMenus({
      user,
      menu: child,
      module,
      menuSection: menu,
    });

    if (childVisible) {
      childMenus.push(child);
    }
  }
  if (childMenus.length === 0) {
    return null;
  }

  menu = {...menu};
  menu.menus = childMenus;
  return menu;
};
const filterScreens = ({
  user,
  menu,
  module,
  menuSection,
  screen,
  screenSection,
}) => {
  const accessible = isAccessible({
    user,
    menu,
    module,
    screen,
    menuSection,
    screenSection,
    fromScreen: true,
  });
  if (!accessible) {
    return null;
  }
  if (
    !screen.menus ||
    !Array.isArray(screen.menus) ||
    screen.menus.length === 0
  ) {
    return screen;
  }
  const childMenus = [];
  for (let child of screen.menus) {
    let childVisible = filterScreens({
      user,
      screen: child,
      screenSection: screen,
      module,
      menuSection,
      menu,
    });

    if (childVisible) {
      childMenus.push(child);
    }
  }
  if (childMenus.length === 0) {
    return null;
  }

  screen = {...screen};
  screen.menus = childMenus;
  return screen;
};
const filterModuleResources = ({user, allowed, modules}) => {
  let newModules = {};
  var allowedModules = allowed.reduce((acc, module) => {
    acc[module.id] = 1;
    return acc;
  }, {});
  for (let moduleId in modules) {
    if (backendModules[moduleId]) {
      let moduleItem = filterBackendModules({user, moduleId});
      newModules[moduleId] = moduleItem;
      continue;
    }
    if (!allowedModules[moduleId]) {
      continue;
    }
    let module = {id: moduleId};
    let {menus, screens} = modules[moduleId];
    if (!Array.isArray(menus) || menus.length === 0) {
      continue;
    }
    if (!Array.isArray(screens) || screens.length === 0) {
      continue;
    }
    let sectionMenuIds = {};
    for (let menu of menus) {
      if (menu.section && menu.menus) {
        for (let subMenu of menu.menus) {
          sectionMenuIds[subMenu.id] = menu.id;
        }
      } else {
        sectionMenuIds[menu.id] = '';
      }
    }

    for (let menu of menus) {
      const modifiedMenu = filterMenus({user, menu, module, fromMenu: true});

      if (modifiedMenu) {
        newModules[moduleId] = newModules[moduleId] || {menus: []};
        newModules[moduleId].menus.push(modifiedMenu);
      }
    }
    if (!newModules[moduleId]) {
      continue;
    }

    newModules[moduleId] = newModules[moduleId] || {};
    newModules[moduleId].screens = [];
    for (let screen of screens) {
      let {menuItemId, views, skipFiltering} = screen;
      if (skipFiltering) {
        newModules[moduleId].screens.push(screen);
        continue;
      }
      let menuId = menuItemId;
      let menu = {id: menuId};
      let menuSection;
      if (sectionMenuIds[menuId]) {
        menuSection = {id: sectionMenuIds[menuId]};
      }

      let newViews = [];

      if (typeof views === 'function') {
        views = views({user});
      }

      for (let view of views) {
        const modifiedScreen = filterScreens({
          user,
          menu,
          menuSection,
          module,
          screen: view,
        });
        if (modifiedScreen) {
          newViews.push(modifiedScreen);
        }
      }
      screen.views = newViews;
      newModules[moduleId].screens.push(screen);
    }
  }
  return newModules;
};
export const RoleProvider = ({children}) => {
  let {moduleData} = useAppModuleContext();
  let {user} = useAppStateContext();

  // const roles = ['User'];

  // const menuItems = {};
  // const screens = {};

  // moduleData.forEach(data => {
  //   menuItems[data.id] = filterMenuItemsByRoles({
  //     menuData: moduleMenus[data.id].menus,
  //     roles,
  //   });

  //   screens[data.id] = getScreens(
  //     moduleMenus[data.id].screens,
  //     menuItems[data.id],
  //     roles,
  //   );
  // });

  // format should remain the same

  // menuItems.pmt = moduleMenus['pmt'].menus;
  // screens.pmt = moduleMenus['pmt'].screens;

  const roleContextValue = useMemo(() => {
    if (!user) {
      return {moduleNavigation: {}};
    }
    let moduleNavigation = filterModuleResources({
      user,
      allowed: moduleData,
      modules: moduleMenus,
    });
    return {
      moduleNavigation,
    };
  }, [user]);

  return (
    <RoleContext.Provider value={roleContextValue}>
      {children}
    </RoleContext.Provider>
  );
};
