import {
    View
} from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Form } from '../../../components/form/Form';

import { Avatar } from '../../../components/avatar/Avatar';
import NestedTable from '../../../components/form/NestedTable';
import { useAppStateContext } from '../../../providers/AppState';

export const AboutMeProfile = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {colors, icons} = useTheme();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  // if (mode?.length) return;
  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          render: () => {
            return (
              <View style={{alignSelf: 'flex-start'}}>
                <Avatar value={user?.name} color={user.color} size={80} />
                {/* <TouchableOpacity
                  // onPress = {() =>{

                  // }}
                  style={{
                    height: 28,
                    width: 28,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    bottom: 0,
                    right: -10,
                    backgroundColor: colors.SURFACE2,
                    borderRadius: 14,
                  }}>
                  <Image style={{height: 20, width: 20}} source={icons.Edit} />
                </TouchableOpacity> */}
              </View>
            );
          },
        },
        {
          label: 'Name',
          defaultValue: '-',
          type: 'text',
          field: 'name',
          size: 6,
        },
        {
          label: 'Employee code',
          defaultValue: '-',
          type: 'text',
          field: 'employee_code',
          size: 6,
        },
        {
          label: 'Email',
          defaultValue: '-',
          type: 'text',
          field: 'official_email_id',
          size: 6,
        },
        {
          label: 'Mobile number',
          defaultValue: '-',
          type: 'text',
          field: 'mobile_number',
          size: 6,
        },
        {
          type: 'multiAutoComplete',
          field: 'reporting_to',
          label: 'Reporting to',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },

        {
          type: 'autoComplete',
          field: 'functional_manager',
          label: 'People manager',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'skill_leader',
          label: 'Skill leader',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'autoComplete',
          field: 'delivery_owner',
          label: 'Delivery owner',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
      ]}
    />
  );
};

export const AboutMePersonal = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  // if (mode?.length) return;
  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'name',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',

                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',

                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',

                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                {
                  label: 'Spouse name',
                  field: 'spouse_name',
                  type: 'textArea',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state',
                  type: 'text',

                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',

                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  size: 4,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',

                  field: 'emergency_contact_persion_number',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const AboutMeEducation = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  // if (mode?.length) return;
  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  type: 'text',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  type: 'text',

                  size: 3,
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  type: 'text',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  type: 'text',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  type: 'text',

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  type: 'text',

                  size: 3,
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',

                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',

                  type: 'text',
                  size: 4,
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export const AboutMePreviousExperience = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  // if (mode?.length) return;
  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          title: 'Employement History',
          fields: [
            {
              label: 'Has past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};

export const AboutMeAccount = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      // validate: async fieldValue => {
                      //   const accnoReg = /^[0-9]{9,18}$/;
                      //   if (fieldValue && !accnoReg.test(fieldValue)) {
                      //     return 'Account Number Not Valid';
                      //   }
                      // },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      // validate: async fieldValue => {
                      //   if (fieldValue && fieldValue.length !== 11) {
                      //     return 'ONLY 11 CHARACTERS ALLOWED';
                      //   }
                      // },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};

export const AboutMeStatutory = props => {
  const {
    route: {params},
    navigation,
    userData = {},
    parichayId,
  } = props;
  const {row = {}} = params || {};
  const {post, user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;

  let defaultValues = {};

  return (
    <Form
      {...props}
      closable={false}
      mode="edit"
      readOnly={true}
      api={`/employee-details/${employeeId}`}
      afterSubmit={() => {
        location.reload();
      }}
      layoutFields={[
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  size: 6,
                  required: true,
                  validate: async fieldValue => {
                    const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                    if (fieldValue && !regexp.test(fieldValue)) {
                      return 'INVALID ADHAR CARD NUMBER';
                    }
                  },
                },
                {
                  placeholder: 'Adhaar card copy',
                  label: 'Adhaar card copy',
                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  field: 'applied_for_pan_card',
                  type: 'checkbox',
                  label: 'Applied for pan card',
                },
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  validate: async fieldValue => {
                    let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                    if (fieldValue && !regpan.test(fieldValue)) {
                      return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                    }
                  },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  label: 'PAN card copy',
                  visible: ({values}) => values && !values.applied_for_pan_card,

                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  label: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  label: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  label: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  label: 'Employee Consent',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  label: 'Offer letter copy',
                  required: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  type: 'file',
                  label: 'Passport size photo',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
                {
                  label: 'LWF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'LWF number',
                      type: 'text',
                      field: 'lwf_number',
                    },
                  ],
                },
                {
                  label: 'Pran number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'Pran number',
                      type: 'text',
                      field: 'pran_id',
                    },
                  ],
                },
                {
                  label: 'UAN number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'UAN number',
                      type: 'text',
                      field: 'uan_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
