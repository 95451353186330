import moment from 'moment';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

export const leadControllers = props => {
  const {params, navigation} = props;
  const {post, user} = useAppStateContext();
  const invoke = useInvoke({method: 'put'});
  const invokePost = useInvoke({
    method: 'post',
    eventSourceId: [
      'popuateMessage',
      'populateBusiness',
      'sendAction',
      'updateBrowser',
      'UnlockRequest',
      'RemoveLocks',
      'removeError',
      'updateUQL',
      'syncStatus',
      'addJob',
    ],
  });
  const invokeDelete = useInvoke({
    method: 'delete',
    eventSourceId: ['communicationAdded', 'indexUpdated', 'removeDocument'],
  });

  const period = params?.period;
  let previousPeriod = {};
  if (period?.from) {
    previousPeriod.from = getZeroTimeDate(
      moment(period?.from).subtract(1, 'M').startOf('M').toDate(),
    );
    previousPeriod.to = getZeroTimeDate(
      moment(period?.from).subtract(1, 'M').endOf('M').toDate(),
    );
  }
  const periodFilter = period
    ? {
        $lte: period?.to,
        $gte: period?.from,
      }
    : void 0;

  const preViousPeriodFilter = previousPeriod
    ? {
        $lte: previousPeriod?.to,
        $gte: previousPeriod?.from,
      }
    : void 0;

  const markLost = ({row}) => {
    navigation.navigate(`mark-lead-lost`, {
      ...params,
      row,
    });
  };

  const markLeadLost = ({row}) => {
    navigation.navigate(`mark-lead-lost-cumulative`, {
      ...params,
      row,
    });
  };

  const updateLeadStage = ({row}) => {
    navigation.navigate(`update-lead-stage`, {
      ...params,
      row,
    });
  };

  const addLeadConsultants = ({row}) => {
    navigation.navigate(`add-lead-consultants`, {
      ...params,
      row,
    });
  };

  const markFree = ({selectedIds}) => {
    invoke({
      uri: `/potentialcustomers/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          lead_status: 'Free',
        },
      },
      success: 'Web Page Marked Archive Successfully',
    });
  };
  const populateBusinessSummary = ({row}) => {
    invokePost({
      uri: `/potentialcustomer-business-summary`,
      props: {
        potential_customer: row.potential_customer,
        campaign_id: row?.campaign._id,
      },
      success: 'Business summary updated',
    });
  };

  const generateHubSpotContactUQL = ({row}) => {
    invokePost({
      uri: `/generate-hubspot-contact-uql`,
      props: {
        data: row,
      },
      success: 'Moved to UQL',
    });
  };
  const populateWebSiteBusinessSummary = ({row}) => {
    navigation.navigate('website-summary-test-form', {...row});
  };

  const editFinetuneUsecase = ({row}) => {
    navigation.navigate('edit-fine-tune-use-case', {row: {_id: row?._id}});
  };

  const editFinetuneDocument = ({row}) => {
    navigation.navigate('edit-fine-tune-document', {row: {_id: row?._id}});
  };

  const updateBusinessSummary = ({row}) => {
    navigation.navigate(`update-business-summary`, {
      potential_customer: row.potential_customer,
    });
  };
  const useAsSampleData = ({row}) => {
    navigation.navigate(`use-as-sample-data`, {
      potential_customer: row?._id,
    });
  };

  const useTestFineTuneModel = ({row}) => {
    navigation.navigate(`test-fine-tune-model`, {
      potential_customer: row?._id,
    });
  };

  const useAsSampleDocument = ({campaign_action_id}) => {
    navigation.navigate(`use-sample-document`, {
      campaign_action_id,
    });
  };
  const useFinetuneMessage = ({row}) => {
    navigation.navigate(`fine-tune-message`, {
      potential_customer: row?._id,
    });
  };
  const useFinetuneSummary = ({row, campaign_id}) => {
    navigation.navigate(`fine-tune-summary`, {
      potential_customer: row?._id,
      campaign_id,
    });
  };

  const useAddFinetuneFollowupMessage = ({row}) => {
    navigation.navigate(`fine-tune-follow-up-message`, {
      row,
    });
  };

  const useEditJob = ({row}) => {
    navigation.navigate(`edit-fine-tune-job`, {row: {_id: row?._id}});
  };

  const useJobCreate = ({row}) => {
    navigation.navigate(`select-job-model`, {usecase_id: row?._id});
  };

  const useDuplicateUseCase = ({row}) => {
    navigation.navigate(`select-duplicate-usecase`, {usecase_id: row?._id});
  };

  const useAddJob = ({row}) => {
    invokePost({
      uri: `/finetunejobs/create`,
      props: {
        usecase_id: row?._id,
      },
    });
  };

  const useSyncStatus = ({row}) => {
    invokePost({
      uri: `/finetunejobs/sync`,
      props: {
        _id: row?._id,
      },
    });
  };
  const verifyBusinessSummary = props => {
    const {selectedIds} = props;
    invoke({
      uri: `/campaigncontacts/batchUpdate`,
      eventSourceId: ['summaryVerified'],
      props: {
        ids: selectedIds,
        updates: {
          website_summary_status: 'verified',
          verified_on: new Date(),
        },
      },
      success: 'Web Page Marked Archive Successfully',
    });
  };

  const setStatusToBeGenerated = props => {
    const {selectedIds} = props;
    invoke({
      uri: `/campaigncontacts/batchUpdate`,
      eventSourceId: ['summaryVerified'],
      props: {
        ids: selectedIds,
        updates: {
          website_summary_status: 'to_be_generated',
        },
      },
    });
  };

  const sendActions = ({selectedIds}) => {
    invokePost({
      uri: `/sendActions`,
      props: {
        campaign_action_ids: selectedIds,
      },
      success: 'Actions send',
    });
  };
  const verifyActions = ({selectedIds}) => {
    invoke({
      uri: `/campaignactions/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          verified: true,
        },
      },
      eventSourceId: ['actionVerified'],
    });
  };
  const addAsDocument = ({selectedIds}) => {
    invokePost({
      uri: `/finetuneAddDocumentApproveMessage`,
      props: {
        campaign_action_ids: selectedIds,
        fromRowAction: true,
      },
    });
  };

  const updatePersonalizeMessage = ({row}) => {
    invokePost({
      uri: `/createContactcustomizedmessage`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'Message personalized',
    });
  };
  const removeError = ({row}) => {
    invokePost({
      uri: `/campaignactions/${row._id}/removeError`,
      props: {},
      success: 'Error Removed',
    });
  };

  const sendContactConnectionRequest = ({row}) => {
    invokePost({
      uri: `/sendContactConnectionRequest`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'Connection request sent',
    });
  };
  const campaignActionSendEmail = ({row}) => {
    invokePost({
      uri: `/campaignaction/${row?._id}/send`,
      props: {},
      success: 'Email sent',
    });
  };
  const campaignActionTestEmail = ({row}) => {
    navigation.navigate(`send-email-test-form`, {
      ...row,
    });
  };
  const sendContactFollowed = ({row}) => {
    invokePost({
      uri: `/sendContactFollowed`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'Followed',
    });
  };
  const acceptUnlockRequest = ({row}) => {
    invokePost({
      uri: `/potentialcustomerlocks/${row._id}/free`,
      props: {},
      success: 'Accepted',
    });
  };
  const acceptUnlockRequestBatch = ({selectedIds}) => {
    invokePost({
      uri: `/acceptUnlockRequestBatch`,
      props: {selectedIds},
      success: 'Accepted',
    });
  };

  const populateRandomContacts = ({row}) => {
    invokePost({
      uri: `/campaigns/${row._id}/populate-random-contacts-messages`,
      props: {},
      success: 'Contacts populated',
    });
  };
  const loadRandomDataFromList = ({row}) => {
    invokePost({
      uri: `/import-potential-as-contact`,
      props: {campaign_id: row?._id, campaign_type: row?.campaign_type},
      eventSourceId: 'importData',
      success: 'Contacts load successfully',
    });
  };

  // Akshat Garg - 30/07/24 - load data of active leads
  const loadDataForActive = ({row}) => {
    invokePost({
      uri: `/load-batch-contacts`,
      props: {name: row?.name, batch_id: row?._id, api: row?.api},
      eventSourceId: 'loadData',
      success: 'Contacts load successfully',
    });
  };
  const importDataForTraining = ({row}) => {
    invokePost({
      uri: `/import-potential-as-contact`,
      props: {
        campaign_id: row?._id,
        campaign_type: row?.campaign_type,
        for_finetune: true,
      },
      eventSourceId: 'importData',
      success: 'Contacts load successfully',
    });
  };

  const populateNextMessage = ({row}) => {
    invokePost({
      uri: `/oureachcampaigns/${row._id}/populatenextaction`,
      props: {},
      success: 'New Messages Populated',
    });
  };

  const verifyPastMessages = ({row}) => {
    invokePost({
      uri: `/outreachcampaigns/verifyPastMessages`,
      props: {campaign_id: row?._id},
      success: 'Verified Past Messages',
    });
  };

  // Mark as unsubscribed - Parveen 02-02-2024
  const markAsUnsubscribe = ({row}) => {
    invokePost({
      uri: `/mark-as-unsubscribed/${row?._id}`,
    });
  };
  const readCampaignConnectionRequest = ({row}) => {
    invokePost({
      uri: `/readCampaignConnectionRequest`,
      props: {campaign_id: row._id},
      success: 'connection request status checked',
    });
  };
  const readCampaignMessages = ({row}) => {
    invokePost({
      uri: `/readCampaignMessages`,
      props: {campaign_id: row._id},
      success: 'Campaign message read',
    });
  };
  const checkLinkedinProfile = ({row}) => {
    invokePost({
      uri: `/checkLinkedinProfile`,
      props: {
        linkedin_profile: row.linkedin_profile?._id || row.linkedin_profile,
      },
      success: 'Check profile',
    });
  };
  const viewLinkedinProfile = ({row}) => {
    invokePost({
      uri: `/checkLinkedinProfile`,
      props: {
        linkedin_profile: row.linkedin_profile?._id || row.linkedin_profile,
        profileUrl: row?.contact_id?.linkedin_url,
      },
      success: 'Visit profile',
    });
  };
  const sendRandomActions = ({row}) => {
    invokePost({
      uri: `/sendRandomActions`,
      props: {
        campaign_id: row._id,
      },
      success: 'Send',
    });
  };
  const sendParticularAction = ({row}) => {
    invokePost({
      uri: `/sendParticularAction`,
      props: {campaign_action_id: row?._id, action: row?.action},
    });
  };
  const readContactConnectionRequest = ({row}) => {
    invokePost({
      uri: `/readContactConnectionRequest`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'connection request status read',
    });
  };
  const sendContactMessageThroughUrl = ({row}) => {
    invokePost({
      uri: `/sendContactMessageThroughUrl`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'connection request status read',
    });
  };
  const readContactReceivedMessageByUrl = ({row}) => {
    invokePost({
      uri: `/readContactReceivedMessageByUrl`,
      props: {
        campaign_action_id: row._id,
      },
      success: 'read message status',
    });
  };

  const populateContactMessages = ({row}) => {
    invokePost({
      uri: `/campaigncontacts/${row._id}/populatenextaction`,
      success: 'Message populated',
    });
  };
  const viewCommunications = ({row}) => {
    navigation.navigate(`show-communications`, {
      ...row,
      addOnFilter: {
        potential_customer: row?.potential_customer?._id,
      },
    });
  };

  const assignToMe = ({selectedIds}) => {
    invoke({
      uri: `/potentialcustomers/batchUpdate`,
      props: {
        ids: selectedIds,
        updates: {
          owner: user.employee,
        },
      },
    });
  };

  const scheduledActions = ({row}) => {
    navigation.navigate(`schedule-action`, {
      ...params,
      row,
    });
  };

  const markAsPositiveResponse = ({row}) => {
    navigation.navigate(`mark-as-positive-response`, {
      ...params,
      row,
    });
  };

  const makeLead = ({row}) => {
    navigation.navigate(`make-lead`, {
      ...params,
      row,
    });
  };

  const makeLeadFromCampaignContact = ({row}) => {
    navigation.navigate(`make-lead`, {
      ...params,
      row: row?.potential_customer,
    });
  };

  const makeUql = ({row}) => {
    navigation.navigate(`make-uql`, {
      ...params,
      row,
    });
  };

  const makeUqlFromCampaignContact = ({row}) => {
    navigation.navigate(`make-uql`, {
      ...params,
      row: row?.potential_customer,
    });
  };

  const makeUqlFromHubSpotContact = ({row}) => {
    navigation.navigate(`make-uql`, {
      ...params,
      row: {_id: row?.potential_customer},
    });
  };
  const pageVisits = ({row}) => {
    navigation.navigate(`page-vists-hubspots`, {
      ...params,
      hubspot_contact_id: row?.hubspot_contact_id,
      name: row?.name,
    });
  };
  const editHubSpotContact = ({row}) => {
    navigation.navigate(`edit-hubspot-contact`, {
      ...params,
      _id: row?._id,
    });
  };

  const updateLeadStatus = ({selectedIds}) => {
    navigation.navigate(`update-lead-status`, {
      ...params,
      selectedIds,
    });
  };

  const updateProspectStatus = ({selectedIds}) => {
    navigation.navigate(`update-lead-status`, {
      ...params,
      selectedIds,
      fromProspect: true,
    });
  };

  const markNurturable = ({row}) => {
    post({
      uri: `/potentialcustomers/${row?._id}`,
      method: 'put',
      props: {
        lead_status: 'Nurturable',
        nurturable_date: getZeroTimeDate(new Date()),
      },
    });
  };

  const updateOwner = ({selectedIds}) => {
    navigation.navigate(`update-owners`, {
      ...params,
      selectedIds,
    });
  };

  const updateOwnerForFree = ({selectedIds}) => {
    navigation.navigate(`update-owners-for-free`, {
      ...params,
      selectedIds,
    });
  };
  const updateOwnerRowAction = ({row}) => {
    navigation.navigate(`update-owners-row-action`, {
      ...params,
      row,
    });
  };

  const updateCampaign = ({selectedIds}) => {
    navigation.navigate(`update-campaign`, {
      ...params,
      selectedIds,
    });
  };

  const createDuplicateLead = ({row}) => {
    navigation.navigate(`create-duplicate-lead`, {
      ...params,
      row,
    });
  };
  const showContacts = ({row}) => {
    navigation.navigate(`show-contacts`, {
      ...params,
      row,
    });
  };

  const submitProposal = ({row}) => {
    navigation.navigate(`submit-proposal`, {
      ...params,
      opportunity: row,
    });
  };

  const positiveProspects = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        response: 'positive',
        responseDate: periodFilter,
      },
    });
  };

  const CampaignProspectOnClickPostive = ({row}) => {
    navigation.navigate(`show-prospect-list-for-positive-click`, {
      ...params,
      row,
      filter: {
        response: 'positive',
        responseDate: periodFilter,
      },
    });
  };

  const CampaignProspectOnClickLifecycleCompleted = ({row}) => {
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        campaign_id: row?._id,
        lifecycle: periodFilter,
      },
    });
  };

  const onPressLeadsInCycle = ({row}) => {
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        campaign_id: row?._id,
        // lifecycle: periodFilter,
        uql_stage_date: {
          $lte: period?.to,
          $gte: period?.from,
        },
        $expr: {
          $lte: ['$uql_stage_date', '$lifecycle'],
        },
      },
    });
  };

  const CampaignProspectOnClickQLInCycle = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        // campaign_id: row?._id,
        sql_stage_date: {
          $lte: period?.to,
          $gte: period?.from,
        },
        expected_ql_date: {
          $gte: period.from,
          $lte: period.to,
        },
      },
    });
  };

  const CampaignProspectOnClickLeadLifecycle = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        // campaign_id: row?._id,
        expected_ql_date: {
          $gte: period.from,
          $lte: period.to,
        },
      },
    });
  };

  const positiveProspectsForMessageSend = ({row}) => {
    navigation.navigate(`show-prospect-list-for-message-send-positive-tab`, {
      ...params,
      row,
      filter: {
        response: 'positive',
        responseDate: periodFilter,
      },
    });
  };

  const negativeProspectsForMessage = ({row}) => {
    navigation.navigate(`show-prospect-list-for-message`, {
      ...params,
      row,
      filter: {
        response: 'negative',
        responseDate: periodFilter,
      },
    });
  };

  const assignTechPerson = ({row}) => {
    navigation.navigate(`assign-tech-person`, {
      ...params,
      opportunity: row,
    });
  };

  const communications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row,
    });
  };

  const communicationsForContact = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row: row?.potential_customer,
    });
  };

  const customerCommunications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row: {
        ...row,
        fromCustomer: true,
      },
    });
  };

  const addCommunication = ({row}) => {
    navigation.navigate(`add-communication`, {
      ...params,
      row,
    });
  };

  const updateStage = ({row}) => {
    navigation.navigate(`update-lead-stage`, {
      ...params,
      row,
    });
  };
  const moveToRql = ({row}) => {
    navigation.navigate(`update-lead-stage`, {
      ...params,
      row,
      stage: {
        _id: '53bbd4b279df44bac9b13038',
        name: 'RQL',
      },
    });
  };
  const updateUqlDate = ({row}) => {
    navigation.navigate(`update-uql-date`, {
      ...params,
      row,
    });
  };
  const moveToProposalSubmitted = ({row}) => {
    navigation.navigate(`update-lead-stage`, {
      ...params,
      row,
      stage: {
        _id: '53bbd36a79df44bac9b12f39',
        name: 'Proposal',
      },
    });
  };
  const emailMessages = ({row}) => {
    navigation.navigate(`email-message-list`, {
      ...params,
      row,
    });
  };

  const prospects = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        message_send_date: periodFilter,
      },
    });
  };

  const CampaignProspectOnClickMessageSend = ({row}) => {
    navigation.navigate(`show-prospect-list-for-messagesend-click`, {
      ...params,
      row,
      filter: {
        lifecycle_end_date: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const CampaignProspectOnClickMessageSendFromOld = ({row}) => {
    navigation.navigate(`show-prospect-list-for-messagesend-click`, {
      ...params,
      row,
      filter: {
        message_send_date: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const prospectsListForEmailMessage = ({row}) => {
    navigation.navigate(`show-prospect-list-for-message-send`, {
      ...params,
      row: {
        _id: row?._id,
        name: row?.subject || row?.name,
      },
      filter: {
        message_send_date: periodFilter,
      },
    });
  };

  const acceptedProspects = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        expected_connection_date: {
          $gte: period.from,
          $lte: period.to,
        },
        connection_accept_date: {
          $gte: period.from,
          $lte: period.to,
        },
        $expr: {
          $lte: ['$connection_accept_date', '$expected_connection_date'],
        },
      },
    });
  };

  const acceptedProspectsOld = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        connection_accept_date: {
          $gte: period.from,
          $lte: period.to,
        },
      },
    });
  };

  const requestSentProspects = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        connection_request_date: periodFilter,
      },
    });
  };

  const connectionCycleCompleted = ({row}) => {
    navigation.navigate(`show-prospect-list`, {
      ...params,
      row,
      filter: {
        expected_connection_date: periodFilter,
      },
    });
  };

  const prospectsCountForCampaign = props => {
    const {row} = props;
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        added_on: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const positiveProspectsCountForCampaign = props => {
    const {row} = props;
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        uql_stage_date: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const prospectsCountForCampaignForOutreachBatch = props => {
    const {row} = props;
    navigation.navigate(`show-prospect-count-list-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        added_on: periodFilter,
      },
    });
  };

  const inmailMessages = ({row}) => {
    navigation.navigate(`inmail-message-list`, {
      ...params,
      row,
    });
  };

  const uqls = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: periodFilter,
      },
    });
  };

  const preuqls = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: preViousPeriodFilter,
      },
    });
  };

  const qualifiedLeads = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: periodFilter,
      },
    });
  };

  const prequalifiedLeads = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: preViousPeriodFilter,
      },
    });
  };

  const assignPreSales = ({selectedIds}) => {
    navigation.navigate(`assign-pre-sales`, {
      ...params,
      selectedIds,
    });
  };

  const showInterviewResources = ({row}) => {
    navigation.navigate(`show-interview-resources`, {
      ...params,
      row,
    });
  };

  const assignInterviewResource = ({row}) => {
    navigation.navigate(`assign-interview-resource`, {
      ...params,
      opportunity: row,
    });
  };

  const markOpportunityClose = ({selectedIds}) => {
    navigation.navigate(`mark-opportunity-close`, {
      ...params,
      selectedIds,
    });
  };

  const markEnginneringOpportunityClose = ({row}) => {
    navigation.navigate(`mark-engineering-opp-close`, {
      ...params,
      row,
    });
  };

  const markInterviewResourceOutcome = ({row}) => {
    navigation.navigate(`mark-interview-outcome`, {
      ...params,
      row,
    });
  };

  const scheduleResourceInterview = ({row}) => {
    navigation.navigate(`schedule-resource-interview`, {
      ...params,
      row,
    });
  };

  const updateResume = ({row}) => {
    navigation.navigate(`update-interview-resource-resume`, {
      ...params,
      row,
    });
  };

  const outcomeDateCorrection = ({row}) => {
    navigation.navigate(`outcome-date-correction`, {
      ...params,
      row,
    });
  };

  const markRPSClose = ({selectedIds}) => {
    navigation.navigate(`mark-rps-close`, {
      ...params,
      selectedIds,
    });
  };

  const createRFQ = ({row}) => {
    navigation.navigate(`create-rfq`, {
      ...params,
      row: row,
    });
  };

  const createRFR = ({row}) => {
    navigation.navigate(`create-rfr`, {
      ...params,
      row: row,
    });
  };

  const markContract = ({row}) => {
    navigation.navigate(`mark-contract-negotiations`, {
      ...params,
      row: row,
    });
  };

  const markOpportunityPositionLost = ({row}) => {
    navigation.navigate(`mark-opportunity-position-lost`, {
      ...params,
      row: row,
    });
  };

  const markOpportunityPositionDrop = ({row}) => {
    navigation.navigate(`mark-opportunity-position-drop`, {
      ...params,
      row: row,
    });
  };

  const makeCustomer = ({row}) => {
    navigation.navigate(`make-customer`, {
      ...params,
      leadId: row?._id,
    });
  };

  const addCustomerFromCustomPlatformOpportunity = ({row}) => {
    // navigation.navigate(`add-customer`, {
    navigation.navigate(`add-customer-for-opportunity`, {
      ...params,
      row: row,
    });
  };

  const opportunityMarkActive = ({row}) => {
    const {_id} = row;
    invoke({
      uri: `/opportunities/${_id}`,
      props: {
        status: 'Active',
        outcome_date: null,
        outcome_description: null,
        outcome_description_comment: null,
      },
      eventSourceId: ['CustomPlatformOpportunity'],
    });
  };
  const markAsIrrelevant = ({row}) => {
    const {_id} = row;
    invoke({
      uri: `/potentialcustomers/${_id}`,
      props: {
        is_irrelevant: true,
      },
    });
  };
  const markAsNurturable = ({row}) => {
    const {_id} = row;
    invoke({
      uri: `/potentialcustomers/${_id}`,
      props: {
        is_nurturable: true,
      },
    });
  };
  const updateProposalHours = ({row}) => {
    navigation.navigate(`update-proposal-hrs`, {
      ...params,
      row: row,
    });
  };

  const makeCustomeOpportunity = ({row}) => {
    navigation.navigate(`make-custom-platform-opportunity`, {
      ...params,
      leadId: row?._id,
    });
  };
  const makeStaffAugmentationOpportunity = ({row}) => {
    navigation.navigate(`make-staff-augmentation-opportunity`, {
      ...params,
      leadId: row?._id,
    });
  };

  const makeRps = ({row}) => {
    navigation.navigate(`make-rps`, {
      ...params,
      leadId: row?._id,
    });
  };

  const editInmailCampaign = ({row}) => {
    navigation.navigate(`edit-inmailcampaign`, {
      ...params,
      row: row,
    });
  };

  const editOutreachCampaign = ({row}) => {
    navigation.navigate(`edit-outreach-campaign`, {
      ...params,
      row: row,
    });
  };

  const editCampaign = ({row}) => {
    navigation.navigate(`edit-campaign-form`, {
      ...params,
      row: row,
    });
  };

  const moveToNewCampaign = ({row}) => {
    navigation.navigate(`inmail-campaign-select-new-campaign`, {
      ...params,
      row: row,
      api: '/outreachcampaigns/moveProspectToNewCampaign',
    });
  };
  const moveAllProspectsToNewCampaign = ({row}) => {
    navigation.navigate(`inmail-campaign-select-new-campaign`, {
      ...params,
      row: row,
      moveAllProspects: true,
      api: '/outreachcampaigns/moveProspectToNewCampaign',
    });
  };

  const settingsOutreachCampaign = ({row}) => {
    navigation.navigate(`settings-outreach-campaign`, {
      ...params,
      row: row,
    });
  };

  const setAsAutomaticOutreachCampaign = ({row}) => {
    navigation.navigate(`update-communication-type`, {
      ...params,
      row: row,
    });
  };

  const outreachBatchUqls = ({row}) => {
    navigation.navigate(`show-lead-list-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: periodFilter,
      },
    });
  };

  const preoutreachBatchUqls = ({row}) => {
    navigation.navigate(`show-lead-list-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: preViousPeriodFilter,
      },
    });
  };

  const qualifiedLeadsForOutreach = ({row}) => {
    navigation.navigate(`show-lead-list-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: periodFilter,
      },
    });
  };

  const prequalifiedLeadsForOutreach = ({row}) => {
    navigation.navigate(`show-lead-list-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: preViousPeriodFilter,
      },
    });
  };

  const requestSentForOutreach = ({row}) => {
    navigation.navigate(`request-send-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        connection_request_date: periodFilter,
      },
    });
  };

  const acceptedProspectsForOutreachBatch = ({row}) => {
    navigation.navigate(`request-send-for-outreach-batch`, {
      ...params,
      row,
      filter: {
        connection_request: true,
        connection_request_date: periodFilter,
      },
    });
  };

  const messageSendForOutreach = ({row}) => {
    navigation.navigate(`messagesend-for-outreach`, {
      ...params,
      row,
      filter: {
        message_send_date: periodFilter,
      },
    });
  };

  const postiveForOutreachBatch = ({row}) => {
    navigation.navigate(`positive-for-outreach`, {
      ...params,
      row,
      filter: {
        response: 'positive',
        responseDate: periodFilter,
      },
    });
  };

  const editEmailCampaign = ({row}) => {
    navigation.navigate(`edit-emailcampaign`, {
      ...params,
      row: row,
    });
  };

  const editOutreachContent = ({row}) => {
    const {row: campaign} = params;
    const marketingThemeFilter = {
      target_audience: campaign?.target_audience,
    };

    navigation.navigate(`edit-outreach-content`, {
      ...params,
      row: row,
      marketingThemeFilter,
    });
  };

  const showThemeMessages = ({row}) => {
    navigation.navigate(`theme-messages`, {
      ...params,
      row: row,
    });
  };

  const addThemeMessage = ({row}) => {
    navigation.navigate(`add-message`, {
      ...params,
      row: row,
    });
  };

  const showDripEmails = ({row}) => {
    navigation.navigate(`drip-email-list-for-message-theme`, {
      ...params,
      marketingThemeFilter: {
        marketing_theme: row?._id,
      },
      marketing_theme: row,
    });
  };

  const showDripInmails = ({row}) => {
    navigation.navigate(`drip-inmail-list-for-message-theme`, {
      ...params,
      marketingThemeFilter: {
        marketing_theme: row?._id,
      },
      marketing_theme: row,
    });
  };

  const showValuePropositions = ({row}) => {
    navigation.navigate(`show-value-propositions`, {
      ...params,
      row: row,
      filter: {
        target_audience: row?._id,
      },
    });
  };
  const newValuePropositionsList = ({row}) => {
    navigation.navigate(`new-value-propositions-list`, {
      ...params,
      row: row,
      filter: {
        target_audience: row?._id,
      },
    });
  };

  const addnewValuePropositions = ({row}) => {
    navigation.navigate(`add-new-value-proposition`, {
      ...params,
      row: row,
      filter: {
        target_audience: row?._id,
      },
    });
  };
  const showTargetAudienceContacts = ({row}) => {
    navigation.navigate(`outreach-targetaudience-contacts`, {
      target_audience: {_id: row._id, name: row.name},
    });
  };

  const showValueNuturing = ({row}) => {
    navigation.navigate(`show-nuturing`, {
      ...params,
      row: row,
      filter: {
        target_audience: row?._id,
      },
    });
  };

  const moveToOpportunity = ({row}) => {
    navigation.navigate(`move-to-opportunity`, {
      ...params,
      leads: row,
    });
  };

  const moveToRPS = ({row}) => {
    navigation.navigate(`add-rps-proposal`, {
      ...params,
      leadId: row?._id,
    });
  };

  const updateInsideSales = ({selectedIds}) => {
    navigation.navigate(`update-inside-sales`, {
      ...params,
      selectedIds,
    });
  };

  const interviewResourceCommunication = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row,
      props: {
        fromInterview: true,
      },
    });
  };

  const viewProspect = ({row}) => {
    navigation.navigate(`prospect-detail`, {
      ...params,
      prospectId: row?.potential_customer?._id,
    });
  };
  const viewUpdatePotentialCustomer = ({row}) => {
    navigation.navigate(`update-potential-customer`, {
      ...params,
      row,
    });
  };
  const editCampaignContactProspect = ({row}) => {
    navigation.navigate(`prospect-detail`, {
      row: row.potential_customer,
      prospectId: row?.potential_customer?._id,
    });
  };
  // Move selected prospect to new campaign - Parveen 22-01-2024
  const moveSelectedProspectToNewCampaign = ({selectedIds}) => {
    navigation.navigate(`inmail-campaign-select-new-campaign`, {
      ...params,
      selectedIds,
      api: '/outreachcampaigns/moveSelectedProspectToNewCampaign',
    });
  };

  const updatePotentialCustomerBusinessSummaryBatch = ({selectedIds}) => {
    post({
      uri: `/potentialcustomer-business-summary-batch`,
      method: 'post',
      eventSourceId: 'communicationAdded',
      props: {
        selectedIds,
      },
    });
  };

  const markUnsubscribe = ({row}) => {
    post({
      uri: `/potentialcustomers/${row?._id}`,
      method: 'put',
      eventSourceId: 'Lead',
      props: {
        lead_status: 'Unsubscribe',
        unsubscribe_date: getZeroTimeDate(new Date()),
      },
    });
  };

  const channelLead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: periodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  const channelNurturableLead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      fromNurturable: true,
      filter: {
        pipeline_type: 'Prospect',
        nurturable_date: periodFilter,
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  const channelPreNurturableLead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      fromNurturable: true,
      filter: {
        pipeline_type: 'Prospect',
        nurturable_date: preViousPeriodFilter,
        is_irrelevant: {$in: [null, false]},
      },
    });
  };
  const opportunity_lead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      fromOpportunityLead: true,
      filter: {
        pipeline_type: 'Prospect',
        opportunity_date: periodFilter,
        stage: {
          $in: ['53bbd36a79df44bac9b12f39', '53a44d07faba6a0200b67fb5'],
        },
        is_irrelevant: {$in: [null, false]},
      },
    });
  };
  const previous_opportunity_lead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      fromOpportunityLead: true,
      filter: {
        pipeline_type: 'Prospect',
        opportunity_date: preViousPeriodFilter,
        stage: {
          $in: ['53bbd36a79df44bac9b12f39', '53a44d07faba6a0200b67fb5'],
        },
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  const channelPreLead = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        uql_stage_date: preViousPeriodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  // Akshat Garg - 14/08/24 - On press for potential customers in lead to customer table
  const potential_customers = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      potential: true,
    });
  };

  // Akshat Garg - 14/08/24 - On press for potential customers in lead to customer table
  const previous_potential_customers = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      potential: true,
      previous_potential: true,
    });
  };

  const channelQl = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: periodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  const channelComparativeQl = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: periodFilter,
        uql_stage_date: periodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
        is_irrelevant: {$in: [null, false]},
      },
    });
  };

  const channelPreQl = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: preViousPeriodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
      },
    });
  };

  const channelPreComparativeQl = ({row}) => {
    navigation.navigate(`show-lead-list`, {
      ...params,
      row,
      fromChannel: true,
      filter: {
        pipeline_type: 'Prospect',
        sql_stage_date: preViousPeriodFilter,
        uql_stage_date: preViousPeriodFilter,
        $or: [
          {lead_sub_stage: {$exists: false}},
          {lead_sub_stage: {$ne: 'Rejected'}},
        ],
      },
    });
  };

  const messageSentDetails = ({row}) => {
    navigation.navigate(`show-prospect-list-for-messagesend-click`, {
      ...params,
      row,
      filter: {
        campaign_contact_id: row?._id,
      },
    });
  };

  const deleteOutreachCampaignContacts = ({row}) => {
    invokeDelete({
      uri: `/campaigncontacts/${row._id}`,
      props: {},
    });
  };

  const dropOutreachCampaignContacts = ({row}) => {
    navigation.navigate(`drop-outreachcampaign-contacts`, {
      ...params,
      row,
    });
  };

  const deleteOutreachTargetAudienceContacts = ({row}) => {
    invokeDelete({
      uri: `/targetaudiencecontacts/${row._id}`,
      props: {},
    });
  };

  const potentialCustomerContactLockUnlockRequest = ({selectedIds}) => {
    post({
      uri: `/potentialcustomercontactlock/unlock-request-batch`,
      method: 'post',
      eventSourceId: 'communicationAdded',
      props: {
        selectedIds,
      },
    });
  };
  const updateBrowser = ({row, windowHeight, windowWidth}) => {
    let linkedin_profile_id = row?._id;
    invokePost({
      uri: `/updateBrowser`,
      props: {
        linkedin_profile_id,
        height: windowHeight,
        width: windowWidth,
      },
      success: 'Update Browser',
    });
  };

  const deleteCampaignActionRecord = ({row}) => {
    invokeDelete({
      uri: `/campaignactions/${row._id}`,
      props: {},
    });
  };

  const deleteandRegenerateAction = ({row, selectedIds}) => {
    invokePost({
      uri: `/oureachcampaigns/deleteandRegenerateActions`,
      props: {
        selectedIds,
        row: {_id: row?._id, campaign_contact_id: row?.campaign_contact_id},
      },
      eventSourceId: ['deleteandRegenerate'],
    });
  };

  const deleteFineTuneDocument = ({row}) => {
    invokeDelete({
      uri: `/finetunedocuments/${row._id}`,
      props: {},
    });
  };

  const potentialCustomerLocksBatchRemove = ({selectedIds}) => {
    invokePost({
      uri: `/potentialcustomerlocks/batchremove`,
      props: {selectedIds},
    });
  };

  const actionsBatchRemove = ({selectedIds}) => {
    invokePost({
      uri: `/actions/batchRemove`,
      props: {selectedIds},
      eventSourceId: 'indexUpdated',
    });
  };

  const verifyActionsBatchUpdate = ({selectedIds}) => {
    invokePost({
      uri: `/campaignActions/batchVerified`,
      props: {selectedIds},
      eventSourceId: ['actionVerified'],
    });
  };

  const populateHubspotContact = ({row}) => {
    invokePost({
      uri: `/import-hubspot-campaign-contacts`,
      props: {data: row},
    });
  };

  const activityLogDetailforInmailCampaign = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'linkedin_profile', select: {name: 1}},
        {path: 'profile_owner', select: {name: 1}},
        {path: 'assigned_to', select: {name: 1}},
        {path: 'email_profile', select: {name: 1}},
      ],
      api: `/inmailcampaigns/${row?._id}/activityLogs`,
      displayFields: {
        profile_owner: {
          label: 'Profile Owner',
          field: 'profile_owner.name',
        },
        linkedin_profile: {
          label: 'Linkedin Profile',
          field: 'linkedin_profile.name',
        },
        assigned_to: {
          label: 'Assign to',
          field: 'assigned_to.name',
        },
        email_profile: {
          label: 'Email Profile',
          field: 'email_profile.name',
        },
      },
    });
  };
  const campaignProspectMarkDropped = props => {
    navigation.navigate(`drop-campaign-prospect`, {...props});
  };

  const campaignProspectMarkVerified = props => {
    let {potential_customer, _id} = props || {};
    invokePost({
      uri: `/campaigns/${_id}/verifyProspect`,
      props: {potential_customer},
      eventSourceId: ['markVerified'],
      success: 'campaign contact verified',
    });
  };

  const populateSummary = ({row, campaign_id}) => {
    invokePost({
      uri: `/potentialcustomer-business-summary`,
      props: {
        potential_customer: row._id,
        campaign_id,
      },
      success: 'Business summary updated',
    });
  };

  const updatePotentialCustomerSummaryBatch = ({selectedIds, campaign_id}) => {
    invokePost({
      uri: `/potentialcustomer-summary-batch`,
      method: 'post',
      eventSourceId: 'communicationAdded',
      props: {
        selectedIds,
        campaign_id,
      },
    });
  };

  const moveToUnverifiedBatch = ({selectedIds, campaign_id}) => {
    invoke({
      uri: `/campaigncontacts/batchUpdate`,
      eventSourceId: ['moveToUnverified'],
      props: {
        filter: {
          potential_customer: selectedIds,
          campaign_id,
          status: 'dropped',
        },
        updates: {
          status: 'new',
          website_summary_status: 'unverified',
          drop_on: null,
          drop_by_system: null,
          reason: null,
        },
      },
    });
  };
  // Write controller to fetch contact's linkedIn connection list - Rajnish Trikaldarshi 12-01-2024
  const fetchContactConnectionList = ({row}) => {
    invokePost({
      uri: `/outreachCampaignContactConnection`,
      props: {_id: row?._id},
      success: 'Connection List Fetched',
    });
  };

  // Write Controller to navigate to View of contact's linkedin connection list - Rajnish Trikaldarshi 12-01-2024
  const viewOutreachCampaignContactConnection = ({row}) => {
    navigation.navigate(`show-campaign-connection-list`, {
      campaign_id: row?.campaign_id,
      campaign_contact_id: row?._id,
    });
  };

  // Write controller to sync locomo with jira - Rajnish Trikaldarshi 09-02-2024
  const syncLocomoWithJira = ({row}) => {
    let {project_id, _id} = row || {};
    invokePost({
      uri: `/jira/syncLocomoWithJira`,
      eventSourceId: 'syncOnJira',
      props: {_id, project_id},
      success: 'Sync locomo with jira',
    });
  };

  const deleteUseCase = ({row}) => {
    invokeDelete({
      uri: `/finetuneusecases/${row?._id}`,
      props: {},
      eventSourceId: 'removeUseCase',
    });
  };
  return {
    assignToMe,
    markFree,
    makeLead,
    makeUql,
    markLost,
    markLeadLost,
    addLeadConsultants,
    updateLeadStatus,
    updateProspectStatus,
    markNurturable,
    updateOwner,
    updateCampaign,
    createDuplicateLead,
    showContacts,
    submitProposal,
    positiveProspects,
    negativeProspectsForMessage,
    assignTechPerson,
    communications,
    addCommunication,
    updateStage,
    emailMessages,
    prospects,
    acceptedProspects,
    requestSentProspects,
    prospectsCountForCampaign,
    inmailMessages,
    uqls,
    qualifiedLeads,
    assignInterviewResource,
    markOpportunityClose,
    assignPreSales,
    showInterviewResources,
    markInterviewResourceOutcome,
    scheduleResourceInterview,
    outcomeDateCorrection,
    markRPSClose,
    createRFQ,
    createRFR,
    markContract,
    makeCustomer,
    prospectsListForEmailMessage,
    makeCustomeOpportunity,
    makeStaffAugmentationOpportunity,
    makeRps,
    moveToRql,
    moveToProposalSubmitted,
    positiveProspectsForMessageSend,
    editInmailCampaign,
    preuqls,
    prequalifiedLeads,
    CampaignProspectOnClickMessageSend,
    CampaignProspectOnClickPostive,
    requestSentForOutreach,
    acceptedProspectsForOutreachBatch,
    messageSendForOutreach,
    postiveForOutreachBatch,
    outreachBatchUqls,
    preoutreachBatchUqls,
    qualifiedLeadsForOutreach,
    prequalifiedLeadsForOutreach,
    editEmailCampaign,
    editOutreachContent,
    showThemeMessages,
    prospectsCountForCampaignForOutreachBatch,
    addThemeMessage,
    showValuePropositions,
    showDripEmails,
    showDripInmails,
    showValueNuturing,
    moveToOpportunity,
    customerCommunications,
    communicationsForContact,
    makeLeadFromCampaignContact,
    makeUqlFromCampaignContact,
    interviewResourceCommunication,
    updateUqlDate,
    updateOwnerRowAction,
    addCustomerFromCustomPlatformOpportunity,
    viewProspect,
    markUnsubscribe,
    updateOwnerForFree,
    CampaignProspectOnClickLifecycleCompleted,
    channelLead,
    channelPreLead,
    channelQl,
    channelComparativeQl,
    channelPreQl,
    channelPreComparativeQl,
    moveToRPS,
    CampaignProspectOnClickQLInCycle,
    CampaignProspectOnClickLeadLifecycle,
    messageSentDetails,
    connectionCycleCompleted,
    onPressLeadsInCycle,
    newValuePropositionsList,
    addnewValuePropositions,
    showTargetAudienceContacts,
    populateBusinessSummary,
    updateBusinessSummary,
    verifyBusinessSummary,
    setStatusToBeGenerated,
    updatePersonalizeMessage,
    sendContactConnectionRequest,
    readContactConnectionRequest,
    sendContactMessageThroughUrl,
    readContactReceivedMessageByUrl,
    editOutreachCampaign,
    moveToNewCampaign,
    editCampaignContactProspect,
    populateRandomContacts,
    readCampaignConnectionRequest,
    readCampaignMessages,
    checkLinkedinProfile,
    updateLeadStage,
    updatePotentialCustomerBusinessSummaryBatch,
    deleteOutreachCampaignContacts,
    deleteOutreachTargetAudienceContacts,
    scheduledActions,
    setAsAutomaticOutreachCampaign,
    potentialCustomerContactLockUnlockRequest,
    dropOutreachCampaignContacts,
    sendRandomActions,
    sendParticularAction,
    sendActions,
    verifyActions,
    sendContactFollowed,
    updateInsideSales,
    acceptUnlockRequest,
    updateBrowser,
    campaignActionSendEmail,
    settingsOutreachCampaign,
    positiveProspectsCountForCampaign,
    acceptUnlockRequestBatch,
    populateContactMessages,
    campaignActionTestEmail,
    populateWebSiteBusinessSummary,
    viewUpdatePotentialCustomer,
    deleteCampaignActionRecord,
    CampaignProspectOnClickMessageSendFromOld,
    acceptedProspectsOld,
    potentialCustomerLocksBatchRemove,
    populateNextMessage,
    CampaignProspectOnClickMessageSendFromOld,
    activityLogDetailforInmailCampaign,
    viewLinkedinProfile,
    removeError,
    campaignProspectMarkDropped,
    campaignProspectMarkVerified,
    populateSummary,
    updatePotentialCustomerSummaryBatch,
    viewCommunications,
    fetchContactConnectionList,
    populateHubspotContact,
    makeUqlFromHubSpotContact,
    viewOutreachCampaignContactConnection,
    moveToUnverifiedBatch,
    updateResume,
    generateHubSpotContactUQL,
    editHubSpotContact,
    pageVisits,
    moveSelectedProspectToNewCampaign,
    markAsPositiveResponse,
    markAsUnsubscribe,
    editFinetuneUsecase,
    useAsSampleData,
    useAddJob,
    useSyncStatus,
    useEditJob,
    editFinetuneDocument,
    deleteFineTuneDocument,
    useTestFineTuneModel,
    useFinetuneMessage,
    useAddFinetuneFollowupMessage,
    useJobCreate,
    useDuplicateUseCase,
    actionsBatchRemove,
    verifyActionsBatchUpdate,
    syncLocomoWithJira,
    updateProposalHours,
    markOpportunityPositionLost,
    markOpportunityPositionDrop,
    markEnginneringOpportunityClose,
    useAsSampleDocument,
    deleteandRegenerateAction,
    useFinetuneSummary,
    deleteUseCase,
    addAsDocument,
    moveAllProspectsToNewCampaign,
    verifyPastMessages,
    loadRandomDataFromList,
    editCampaign,
    importDataForTraining,
    loadDataForActive,
    potential_customers,
    previous_potential_customers,
    opportunityMarkActive,
    markAsIrrelevant,
    markAsNurturable,
    channelNurturableLead,
    channelPreNurturableLead,
    opportunity_lead,
    previous_opportunity_lead,
  };
};
