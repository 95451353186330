import {useState} from 'react';
import {useInvoke} from '../../../controllers/useInvoke';
import pluralize from 'pluralize';

export const useAIActions = ({
  entityName,
  pluralEntityName,
  filter,
  uri,
  initialValues,
  params,
  suggestionUri,
  acceptUri,
  rejectUri,
  eventSourceId,
}) => {
  const [loadingAction, setLoadingAction] = useState();
  if (!pluralEntityName && entityName) {
    pluralEntityName = pluralize.plural(entityName);
  }

  const invoke = useInvoke({
    method: 'post',
    eventSourceId,
    setLoading: setLoadingAction,
  });

  suggestionUri = suggestionUri || `/${pluralEntityName}/ai`;
  acceptUri = acceptUri || `/${pluralEntityName}/ai/accept`;
  rejectUri = rejectUri || `/${pluralEntityName}/ai/reject`;

  const onNewSuggestions = props => () => {
    invoke({
      uri: suggestionUri,
      props: {...props, ...params, entity: entityName, filter, uri},
      loadingAction: 'NewSuggestions',
    });
  };

  const onMoreSuggestions =
    props =>
    ({improvement_text}) => {
      invoke({
        uri: suggestionUri,
        props: {
          ...props,
          ...params,
          entity: entityName,
          filter,
          uri,
          more_suggestion_text: improvement_text,
        },
        loadingAction: 'MoreSuggestions',
      });
    };

  const onImproveSuggestions =
    props =>
    ({improvement_text, improvement_props}) => {
      invoke({
        uri: suggestionUri,
        props: {
          ...props,
          ...params,
          entity: entityName,
          filter,
          uri,
          improvement_text,
          ...improvement_props,
        },
        loadingAction: 'ImproveSuggestions',
      });
    };

  const onAcceptAll = () => {
    invoke({
      uri: acceptUri,
      props: {
        ...params,
        entity: entityName,
        filter,
        uri,
        initialValues,
      },
      loadingAction: 'AcceptAllSuggestions',
    });
  };

  const onRejectAll = () => {
    invoke({
      uri: rejectUri,
      props: {
        ...params,
        entity: entityName,
        filter,
        uri,
        initialValues,
      },
      loadingAction: 'RejectAllSuggestions',
    });
  };

  const onAccept = ({acceptBody}) => {
    invoke({uri: acceptUri, props: acceptBody});
  };

  const onReject = ({row}) => {
    invoke({
      uri: rejectUri,
      props: {
        suggestionId: row._id,
      },
    });
  };

  return {
    onNewSuggestions,
    onMoreSuggestions,
    onImproveSuggestions,
    onAccept,
    onAcceptAll,
    onReject,
    onRejectAll,
    loadingAction,
  };
};
