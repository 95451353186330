import {useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {
  TransactionCOOTable,
  TransactionResultCOOTable,
} from './TransactionCOOTables';

export const TransactionCOOTabs = props => {
  let {route: {params} = {}} = props;
  const {filterValues, applyFilter} = useFilter({});
  const tabs = {
    pending: {
      label: 'Pending',
      view: (
        <TransactionCOOTable
          tab="pending"
          addOnFilter={{
            transaction_status: 'Pending authoriser Appoval',
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
    },
    successful: {
      label: 'Successful',
      view: (
        <TransactionResultCOOTable
          tab="successful"
          addOnFilter={{transaction_status: 'Success'}}
          {...props}
        />
      ),
    },
    unsuccessful: {
      label: 'Unsuccessful',
      view: (
        <TransactionResultCOOTable
          tab="Unsuccessful"
          addOnFilter={{transaction_status: 'Fail'}}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              field: 'fund_id',
              type: 'autoComplete',
              label: 'Fund',
              api: '/banks',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
            {
              field: 'payment_id.category',
              type: 'autoComplete',
              label: 'Category',
              api: '/categories',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
            },
          ]}
        />,
      ]}
    />
  );
};
