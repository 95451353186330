import React from 'react';
import {Text} from '@unthinkable/react-core-components';

export const FontNameColumn = ({row}) => {
  const {
    fontName,
    fontFamily,
    fontSize,
    fontWeight,
    italic,
    letterSpacing,
    lineHeight,
  } = row;
  return (
    <Text
      style={{
        // fontFamily: `{${fontFamily}`,
        fontSize: `${fontSize}px`,
        fontWeight: `${fontWeight}px`,
        letterSpacing: `${letterSpacing}px`,
        lineHeight: `${lineHeight}px`,
      }}>
      {fontName}
    </Text>
  );
};
