import React from 'react';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {useInvoke} from '../../../controllers/useInvoke';
import {STATUS} from './TargetAudienceTable';
import ColorDiffCellRender from '../../../app-components/renders/ColorDiffCellRender';

export const EmailCampaignHeader = props => {
  const {
    route: {params},
  } = props;
  return (
    <NavHeader
      title="Email Campaign"
      {...props}
      actions={[
        <AddButton
          title="Email Campaign"
          view="add-emailcampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage?.toFixed(2)}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

export const EmailCampaignTable = props => {
  let {
    route: {params},
    filterParams,
    searchValue,
    filter,
    navigation,
    tabFilter = {},
  } = props;
  params = {...params, ...filterParams};
  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144a',
      label: row?.name,
    });
  };
  const invoke = useInvoke({
    close: false,
    method: 'put',
    eventSourceId: ['emailCampaignAdded'],
  });
  const prospectsCountForCampaignWithoutPeriod = props => {
    const {row} = props;
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        // added_on: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const {
    emailMessages,
    prospectsCountForCampaign,
    uqls,
    preuqls,
    qualifiedLeads,
    prequalifiedLeads,
    CampaignProspectOnClickPostive,
    CampaignProspectOnClickMessageSend,
    editEmailCampaign,
    positiveProspectsCountForCampaign,
    CampaignProspectOnClickMessageSendFromOld,
  } = leadControllers({...props, params: {...params, fromEmailCampaign: true}});
  return (
    <Table
      eventSourceId={['outreachAdded', 'emailCampaignAdded', 'Task']}
      onRowPress={emailMessages}
      api={`/emails-campaign`}
      params={params}
      addOnFilter={{...filter, ...tabFilter}}
      search={searchValue}
      limit={1000}
      searchFields={'name'}
      columns={[
        {
          header: 'Name',
          render: ColorDiffCellRender,
        },

        {
          width: 80,
          field: 'assigned_to',
          header: 'Owner',
          type: 'userAvatar',
        },
        // {
        //   render: CountCellRender,
        //   width: 120,
        //   header: 'Outreach Contents',
        //   align: 'right',
        //   count_field: 'campaign_message_count',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`add-outreach-content`, {
        //       ...params,
        //       row,
        //       campaign_type: '64d0fd55ab78dfead48c144a',
        //     });
        //   },
        //   onPress: emailMessages,
        // },
        // taskRender({params, navigation, showTasks}),
        {
          type: 'number',
          field: 'prospect_count',
          header: 'Prospect',
          aggregate: true,
          onPress: prospectsCountForCampaign,
        },
        {
          type: 'number',
          field: 'message_send_count_all',
          header: 'Message Sent',
          aggregate: true,
          onPress: CampaignProspectOnClickMessageSendFromOld,
        },
        {
          type: 'number',
          field: 'positive_result_percentage',
          // render: RendersPositivePercentage,
          header: 'Positive %',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          onPress: positiveProspectsCountForCampaign,
          width: 110,
        },

        {
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: uqls,
          onComparePress: preuqls,
          render: ComparisonRender,
          width: 110,
        },
        {
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'QL',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: qualifiedLeads,
          onComparePress: prequalifiedLeads,
          render: ComparisonRender,
          width: 110,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editEmailCampaign,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Show Tasks',
          onPress: showTasks,
          navigation,
          params,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/emailCampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'emailCampaignAdded', 'Task'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/emailCampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'emailCampaignAdded', 'Task'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/emailCampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'emailCampaignAdded', 'Task'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/emailcampaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/emailCampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'emailCampaignAdded', 'Task'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Prospect',
          onPress: prospectsCountForCampaignWithoutPeriod,
        },
      ]}
    />
  );
};
