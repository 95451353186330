import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ProjectsTable, projectFilters} from './ProjectsTable';
import {AddButton} from '../../../components/button/AddButton';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useAppStateContext} from '../../../providers/AppState';

export const ProjectTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Active',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="active"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
            user?.userType === 'employee' ? (
              <AddButton title={'Project'} view={'add-project'} />
            ) : (
              void 0
            ),
          ],
        },
        completed: {
          label: 'Completed',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="released"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
          ],
        },
      }}
    />
  );
};

export const ProjectActiveTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Active',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="active"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
            user?.userType === 'employee' ? (
              <AddButton title={'Project'} view={'add-project'} />
            ) : (
              void 0
            ),
          ],
        },
      }}
    />
  );
};

export const ProjectClosedTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        completed: {
          label: 'Closed',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="released"
              tab = "closed"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
          ],
        },
      }}
    />
  );
};
