import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AddFeedbackCriteria = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/feedbackCriteria',
    ...props,
  });
  
  const {
    route: {params},
  } = props;

  const {training_program} = params;

  return (
    <Form
      header="Add Feedback Criteria"
      onSubmit={onSubmit}
      defaultValues={{
        type: training_program.course_type,
      }}
      layoutFields={[
        {
          label: 'Criteria',
          field: 'name',
          type: 'text',
          required: true,
        },
        {
          label: 'Type',
          type: 'radioGroup',
          field: 'type',
          options: ['Tech', 'Non-Tech'],
        },
      ]}
    />
  );
};
