import React from 'react';
import {useGlobalFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';

export const DepartmentPerformanceReportTable = props => {
  const {navigation, invoice_type, filterProps} = props;

  const {filterValues = {}} = filterProps;

  const {colors} = useTheme();

  return (
    <Table
      params={{...filterValues.params, invoice_type}}
      addOnFilter={filterValues.filter}
      api="/departmentWisePerformance"
      limit = {5000}
      columns={[
        {
          header: 'Department',
          field: 'department.name',
          type: 'text',
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          width: 150,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          width: 150,
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Difference',
          type: 'number',
          align: 'left',
          width: 200,
          field: 'expense_diff',
          aggregate: true,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                Number(Math.abs(row.expense_diff)).toFixed(0) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          onPress: ({row}) => {
            if (invoice_type == 'Expense') {
              navigation.navigate('department-performance-details', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                invoice_type,
              });
            } else {
              navigation.navigate('department-performance-details-salary', {
                ...filterValues.params,
                departmentId: row?.department?._id,
                addOnFilter: filterValues.filter,
                invoice_type,
              });
            }
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
      ]}
      {...props}
    />
  );
};

export const DepartmentPerformanceReportTabs = props => {
  const filterProps = useGlobalFilter();
  return (
    <TabView
      headerComponent={<TableHeader title="Department Expense Analysis" />}
      tabs={{
        salary: {
          label: 'Salary',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Salary"
              filterProps={filterProps}
              key="salary"
            />
          ),
        },
        expense: {
          label: 'Expense',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Expense"
              filterProps={filterProps}
              key="expense"
            />
          ),
        },
        direct: {
          label: 'Direct',
          view: (
            <DepartmentPerformanceReportTable
              {...props}
              invoice_type="Direct"
              filterProps={filterProps}
              key="Direct"
            />
          ),
        },
      }}
      actions={[
        <PeriodRangeFilter
          skipQuarter
          monthWiseBreakup={false}
          options={[]}
          {...filterProps}
        />,
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
          ]}
        />,
      ]}
    />
  );
};
