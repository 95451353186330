import React, {useEffect, useMemo, useState} from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {LeftMenuViewStyles} from './theme';
import {useNavigation} from '@react-navigation/native';
import {View} from '@unthinkable/react-core-components';
import {LeftMenuContext} from './LeftMenuContext';
import {getRenderComponent, isMobile} from '@unthinkable/react-utils';
import {useAppModuleContext} from '../../providers/AppModuleProvider';
import {useRoleContext} from '../../routes/RoleContext';
import {singular} from 'pluralize';
import {useAppStateContext} from '../../providers/AppState';
import {screenPaths} from '../../routes';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Container, ViewContainer} from './Styles';
import {
  fetchRequiredFieldData,
  filterMenus,
  filterScreens,
  findMenuId,
  findMenuItem,
  findScreen,
  getEntities,
  getScreenId,
  getSelectedSectionMenu,
} from './Utility';
import {Menus} from './Menus';
import FloatingHelpButton from './FloatingHelpButton';

export const LeftMenuNewViewComponent = ({header, ...props}) => {
  const {route: currentRoute, styles} = props;

  const [state, setState] = useState(true);
  let [selectedScreenSection, setSelectedScreenSection] = useState(null);
  let [selectedMenuSection, setSelectedMenuSection] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isMenuVisible, setMenuVisible] = useState(true);

  const navigation = useNavigation();
  const {moduleData} = useAppModuleContext();
  const {user} = useAppStateContext();
  const {moduleNavigation} = useRoleContext();

  let routeParams = currentRoute?.params || {};

  let appModule = moduleData[0];

  if (routeParams?.appModule_id) {
    appModule = moduleData.find(
      module => module.id === routeParams?.appModule_id,
    );
  }
  let menus = moduleNavigation[appModule.id]?.menus;
  let screens = moduleNavigation[appModule.id]?.screens;

  if (
    Array.isArray(routeParams.parentItems) &&
    routeParams.parentItems.length
  ) {
    menus = getEntities(menus, routeParams.parentItems);
  }

  // visibility work on groups and normal object menus
  menus = filterMenus(menus, routeParams, user);
  screens = filterScreens(screens, routeParams, user);

  let selectedItem;
  let itemScreens;
  let selectedScreen;

  if (Array.isArray(menus) && menus.length && routeParams?.itemId) {
    selectedItem = findMenuItem(menus, routeParams.itemId);
  }

  if (Array.isArray(screens) && screens.length && selectedItem) {
    screens?.forEach(screen => {
      if (screen?.menuItemId === selectedItem?.id) {
        itemScreens = screen?.views;
      }
    });
  }

  if (
    Array.isArray(itemScreens) &&
    itemScreens.length &&
    routeParams?.screenId
  ) {
    selectedScreen = findScreen(itemScreens, routeParams.screenId);
  }

  useEffect(() => {
    let sectionMenu;
    for (let menu of menus) {
      if (Array.isArray(menu)) {
        for (let _menu of menu) {
          sectionMenu = getSelectedSectionMenu({
            menu: _menu,
            routeParams,
          });
          if (sectionMenu) break;
        }
      } else {
        sectionMenu = getSelectedSectionMenu({menu, routeParams});
      }
      if (sectionMenu) {
        break;
      }
    }

    if (selectedMenuSection !== sectionMenu) {
      setSelectedMenuSection(sectionMenu);
    }

    screens.forEach(screen => {
      let views = screen?.views;
      if (typeof views === 'function') {
        views = views({user});
      }
      if (screen?.menuItemId === routeParams.itemId) {
        if (routeParams?.screenId) {
          const findAndSetSection = screens => {
            for (const screen of screens) {
              if (screen.id === routeParams?.screenId) {
                setSelectedScreenSection(void 0);
                return true;
              } else if (Array.isArray(screen)) {
                if (findAndSetSection(screen)) return true;
              } else if (screen.section) {
                if (findAndSetSection(screen.menus)) return true;
              }
            }
            return false;
          };
          itemScreens?.forEach(itemScreen => {
            if (itemScreen?.section && findAndSetSection([itemScreen])) {
              setSelectedScreenSection(
                itemScreen?.section ? itemScreen : void 0,
              );
            } else if (itemScreen.id === routeParams?.screenId) {
              setSelectedScreenSection(void 0);
            }
          });
        } else if (views?.[0]?.section || views?.[0]?.[0]?.section) {
          setSelectedScreenSection(views?.[0]?.[0] || views?.[0]);
        }
      }
    });
  }, [routeParams.itemId, routeParams.screenId]);

  useEffect(() => {
    let appModule = routeParams?.appModule;

    if (!appModule && routeParams?.appModule_id) {
      appModule = moduleData.find(
        module => module.id === routeParams?.appModule_id,
      );
    }

    if (!appModule) {
      appModule = moduleData[0];
    }

    let itemId = routeParams.itemId;
    let entityKey = routeParams.entityKey;

    // to get default selected entity
    const findDefaultSelectedId = menus => {
      for (const menu of menus) {
        let {defaultSelected = false} = menu;
        if (typeof defaultSelected === 'function') {
          defaultSelected = defaultSelected({
            params: routeParams,
            ...props,
            user,
          });
        }

        if (Array.isArray(menu)) {
          const result = findDefaultSelectedId(menu);
          if (result) return result;
        } else if (menu.section) {
          const result = findDefaultSelectedId(menu.menus);
          if (result) return result;
        } else if (defaultSelected) {
          return {itemId: menu.id, entityKey: menu.entityKey};
        }
      }
      return null;
    };

    if (!itemId) {
      let {itemId: menuItemId} = findDefaultSelectedId(menus) || {};
      itemId = menuItemId;
    }
    if (!entityKey) {
      let {entityKey: menuEntityKey} = findDefaultSelectedId(menus) || {};
      entityKey = menuEntityKey;
    }

    if (!itemId) {
      let {itemId: menuItemId} = findMenuId(menus) || {};
      itemId = menuItemId;
    }
    if (!entityKey) {
      let {entityKey: menuEntityKey} = findMenuId(menus) || {};
      entityKey = menuEntityKey;
    }

    let itemScreenId = getScreenId({screens, itemId});

    const newParams = {
      itemId: routeParams?.itemId || itemId,
      entityKey: routeParams?.entityKey || entityKey,
      screenId: routeParams?.screenId || itemScreenId,
      breadcrumb: routeParams.breadcrumb || {
        dropdown: {
          field: 'appModule',
        },
      },
      appModule_id: routeParams?.appModule_id || moduleData[0].id,
      appModule: appModule,
    };

    if (routeParams?.parentItemId && !routeParams?.parentItems) {
      newParams.parentItems = [routeParams?.parentItemId];
    }
    navigation.setParams(newParams);
    setState(false);
  }, [state, routeParams?.appModule_id]);

  let view = selectedScreen?.view;
  if (typeof view === 'function') {
    view = view({...props, user});
  }

  const viewComponent = view
    ? React.cloneElement(view, {
        ...props,
        isLinkTab: true,
      })
    : void 0;

  const navigateTo = (view, params = {}) => {
    const {parentItems: currentParentItems = [], itemId: currentItemId} =
      currentRoute.params || {};

    let newParentItems = [...currentParentItems];

    if (currentItemId) {
      newParentItems.push(currentItemId);
    }

    let filteredItems = filterMenus(
      selectedItem.menuItems,
      {...routeParams, ...params},
      user,
    );

    const {itemId, entityKey} =
      findMenuId(filteredItems, {...routeParams, ...params}, user) || {};

    let screenId = getScreenId({screens, itemId});

    let newParams = {
      ...params,
      ...routeParams,
      itemId,
      entityKey,
      screenId,
      helpId: selectedItem?.helpId,
      helpItemId: selectedItem?.helpItemId,
      helpScreenId: selectedItem?.helpScreenId,
      parentItemId: routeParams?.itemId,
      parentScreenId: routeParams?.screenId,
      breadcrumb: params?.breadcrumb || {
        dropdown: {
          field: singular(routeParams?.entityKey || routeParams?.itemId),
        },
      },
    };

    if (routeParams?.tabId || routeParams?.tabApiKey) {
      newParams.parentTabId = routeParams?.tabId;
      newParams.parentTabApiKey = routeParams?.tabApiKey || routeParams?.tabId;
      delete newParams.tabId;
      delete newParams.tabApiKey;
    }
    if (newParentItems.length) {
      newParams.parentItems = newParentItems;
    }
    navigation.navigate(view, newParams);
  };

  const renderHeader = () => {
    let headerComponent =
      header && getRenderComponent(header, {...props, user});
    return headerComponent ? React.cloneElement(headerComponent, props) : null;
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      setMenuVisible(!isMenuVisible); // hide menu during animation
    }, 300);
  };

  const contextValue = useMemo(() => {
    return {
      navigateTo,
      toggleMenu,
      isMenuVisible,
    };
  });

  return (
    <LeftMenuContext.Provider value={contextValue}>
      <>
        {renderHeader()}
        <Container styles={styles} direction={isMobile ? 'column' : 'row'}>
          <Menus
            styles={styles}
            selectedItem={selectedItem}
            selectedView={selectedScreen}
            entities={menus}
            itemScreens={itemScreens}
            screens={screens}
            params={routeParams}
            selectedScreenSection={selectedScreenSection}
            setSelectedScreenSection={setSelectedScreenSection}
            selectedMenuSection={selectedMenuSection}
            setSelectedMenuSection={setSelectedMenuSection}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isMenuVisible={isMenuVisible}
            setMenuVisible={setMenuVisible}
            toggleMenu={toggleMenu}
          />
          <ViewContainer styles={styles}>{viewComponent}</ViewContainer>
        </Container>
      </>
    </LeftMenuContext.Provider>
  );
};

export const LeftMenuNewView = props => {
  let isFieldMissing = false;
  const styles = useStyles(LeftMenuViewStyles);
  const {fetch} = useAppStateContext();
  const {user} = useAppStateContext();
  let {route: {params} = {}} = props || {};

  const screenPath = screenPaths[props?.route?.name];
  const {requiredFields = []} = screenPath || {};
  if (screenPath && requiredFields) {
    requiredFields.forEach(field => {
      if (!props?.route?.params?.[field]) {
        isFieldMissing = true;
      }
    });
  }

  useEffect(() => {
    if (isFieldMissing) {
      fetchRequiredFieldData({fetch, ...props});
    }
  }, []);

  if (isFieldMissing) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <>
      <LeftMenuNewViewComponent {...props} styles={styles} />
      {user &&
      params &&
      params.helpId &&
      user.help_contents &&
      user.help_contents[params.helpId] ? (
        <FloatingHelpButton {...props} />
      ) : null}
    </>
  );
};
