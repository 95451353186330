import React from 'react';
import {Table} from '../../../components/table/Table';
import {getPreviousMonth, useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/table/Headers';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';

export const DeliveryVsInvoiceReport = props => {
  const filterDetails = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const {filterValues} = filterDetails;
  const {params = {}} = filterValues;
  const {download} = useAppStateContext();
  return (
    <Table
      {...props}
      renderHeader={() => (
        <TableHeader
          actions={[
            <PeriodRangeFilter {...filterDetails} />,
            <GroupFilter
              {...filterDetails}
              filters={[
                {
                  field: 'organization',
                  label: 'Organization',
                  type: 'multiAutoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  api: '/organizations',
                },
                {
                  field: 'product',
                  label: 'Product',
                  type: 'multiAutoComplete',
                  suggestionField: 'name',
                  valueField: 'name',
                  api: '/products',
                },
              ]}
            />,
            <Button
              text="Download"
              onPress={() => {
                download({
                  uri: '/exportExcelHelper',
                  props: {
                    props: {
                      fetchProps: {
                        api: '/deliveryVsInvoiceReport',
                        addOnFilter: {...filterValues.filter},
                        params: {
                          ...filterValues.params,
                        },
                      },
                      column: {
                        'Order Type':
                          'project_resource_working_data.project.projecttype',
                        'Customer Name': 'customer.name',
                        'Delivery Number':
                          'project_resource_working_data.delivery_number',
                        'Delivered Hours': 'billed_hours',
                        'Invoiced Hours': 'quantity',
                        'Delivered Amount(INR)': 'deliveryedAmount',
                        'Delivered Amount(FCY)': 'deliveryedAmountFcy',
                        'Invoiced Amount(INR)': 'baseinvoice_amount',
                        'Invoice Amount(FCY)': 'invoice_amount',
                        Currency: 'order.currency_id.currency',
                        Rate: 'project_resource_working_data.rate',
                        'Exchange Rate in order': 'order.exchange_rate',
                        'Exchange rate actual': 'exchange_rate',
                        'Delivery Exchange Rate':
                          'project_resource_working_data.exchange_rate',
                        Product: 'product.name',
                      },
                    },
                  },
                });
              }}
            />,
          ]}
        />
      )}
      params={{...params}}
      limit={5000}
      api="/deliveryVsInvoiceReport"
      addOnFilter={{...filterValues.filter}}
      columns={[
        {
          type: 'colorTag',
          header: 'Type',
          field: 'order.order_type',
          value: ({row}) => (row?.order?.order_type == 'r' ? 'T&M' : 'Fixed'),
          footer: 'TOTAL',
          colorField: row =>
            row?.order?.order_type == 'r' ? 'ACCENT1' : 'ACCENT2',
        },
        {
          type: 'colorTag',
          header: 'Customer',
          field: 'customer.name',
          minWidth: 250,
          colorField: randomColor,
        },
        {
          type: 'colorTag',
          header: 'Delivery Number',
          field: 'project_resource_working_data.delivery_number',
          width: 160,
          colorField: randomColor,
        },
        {
          type: 'number',
          header: 'Delivered Hours',
          aggregate: true,
          field: 'billed_hours',
          width: 120,
        },
        {
          type: 'number',
          header: 'Invoiced Hours',
          field: 'quantity',
          aggregate: true,
          width: 120,
        },
        {
          type: 'number',
          header: 'Delivered Amount(INR)',
          field: 'deliveryedAmount',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 180,
        },
        {
          type: 'number',
          header: 'Delivered Amount(FCY)',
          field: 'deliveryedAmountFcy',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 180,
        },
        {
          type: 'number',
          header: 'Invoice Amount(INR)',
          field: 'baseinvoice_amount',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 180,
        },
        {
          type: 'number',
          header: 'Invoice Amount(FCY)',
          field: 'invoice_amount',
          aggregate: true,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 180,
        },
        {
          type: 'colorTag',
          header: ' Currency ',
          field: 'order.currency_id.currency',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 100,
        },
        {
          type: 'number',
          header: ' Rate ',
          field: 'project_resource_working_data.rate',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 80,
        },
        {
          type: 'number',
          header: 'Ex-rate in Order ',
          field: 'order.exchange_rate',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 180,
        },
        {
          type: 'number',
          header: 'Ex-rate in Invoice (Advance) ',
          field: 'exchange_rate_advance',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 200,
          visible: false,
        },
        {
          type: 'number',
          header: 'Ex-rate in Invoice (Actual) ',
          field: 'exchange_rate',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 200,
        },
      ]}
    />
  );
};
