import {Text} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {UserAvatarGroupChip} from '../../../components/avatar/UserAvatar';
import {IconButton} from '../../../components/button/IconButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';

const DeliveredProjectResourceWorkingTable = props => {
  const {
    route: {params = {}} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period,
  } = props;
  const color = useTheme('colors');
  return (
    <Table
      variant={'bordered'}
      params={{period}}
      eventSourceId={['ActiveOrderOfOrdersAndDeliveries']}
      addOnFilter={{...addOnFilter}}
      search={searchValue}
      searchFields="delivery_number"
      api={`/projectResourceWorking/delivered`}
      selection={{
        actions: [
          {
            title: 'Create Invoice',
            onPress: data => {
              const {selectedData = [], selectedIds = []} = data;
              let deliveryDefaultData = {};
              if (selectedData.length > 0) {
                deliveryDefaultData.customer = selectedData[0].customer;
                deliveryDefaultData.organization = selectedData[0].organization;
                deliveryDefaultData.product = selectedData[0].product;
                deliveryDefaultData.exchange_rate =
                  selectedData[0].exchange_rate;
                deliveryDefaultData.pricing_type = selectedData[0].order_type;
                deliveryDefaultData.deliveryId = selectedIds;
              }
              navigation.navigate('create-draft-invoice', {
                ...props,
                deliveryDefaultData: deliveryDefaultData,
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarGroupChip
                maxWidth={200}
                value={row?.project_resource_employee}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          width: 180,
        },
        {
          header: 'Delivery No.',
          field: 'delivery_number',
          type: 'colorTag',
          colorField: randomColor,
          width: 140,
        },
        {
          field: 'variable_potential',
          header: 'Working Pot.',
          type: 'number',
          width: 120,
        },
        {
          header: 'Approved',
          children: [
            {
              header: 'EWD',
              align: 'right',
              width: 80,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_ewd_days || 0} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    first_attendance_type_id: '5382ea3470f5d40200321611',
                    second_attendance_type_id: '5382ea3470f5d40200321611',
                  },
                });
              },
            },
            {
              header: 'Leaves',
              align: 'right',
              width: 80,
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.approve_leave_days.toFixed(1) || 0} d`}
                  </Text>
                );
              },
              onPress: ({row}) => {
                navigation.navigate('attendance-verified-order', {
                  row,
                  period,
                  attendanceFilter: {
                    first_attendance_type_id: '53744b5b3eabca020078060b',
                    second_attendance_type_id: '53744b5b3eabca020078060b',
                  },
                });
              },
            },
          ],
        },
        {
          header: 'Unapproved',
          children: [
            {
              header: 'EWD',
              width: 80,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.unapprove_ewd_days || 0} d`}
                  </Text>
                );
              },
            },
            {
              header: 'Absence',
              width: 80,
              align: 'right',
              render: ({row, styles: {rowText = {}} = {}}) => {
                return (
                  <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                    {`${row?.absence || 0} d`}
                  </Text>
                );
              },
            },
          ],
        },
        {
          header: 'Efforts',
          width: 80,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.actual_hours && `${row?.actual_hours} h`}
              </Text>
            );
          },
        },
        {
          header: 'Output',
          width: 80,
          align: 'right',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText, color: color?.INFORMATION_HIGH}}>
                {row?.billed_hours && `${(row?.billed_hours).toFixed(1)} h`}
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Create Draft Invoice',
          onPress: ({row}) => {
            let deliveryDefaultData = {};
            if (row && row.customer?._id) {
              if (row?.customer?._id) {
                deliveryDefaultData.customer = row?.customer;
              }
              if (row?.organization?._id) {
                deliveryDefaultData.organization = row?.organization;
              }
              if (row?.product?._id) {
                deliveryDefaultData.product = row?.product;
              }
              if (row?.order_type) {
                deliveryDefaultData.pricing_type = row?.order_type;
              }
              if (row?.exchange_rate) {
                deliveryDefaultData.exchange_rate = row?.exchange_rate;
              }
              if (row?._id) {
                deliveryDefaultData.deliveryId = [row?._id];
              }
            }
            navigation.navigate('create-draft-invoice', {
              deliveryDefaultData: deliveryDefaultData,
            });
          },
        },
      ]}
    />
  );
};

export const DeliveredProjectResourceWorkingTab = props => {
  const {route: {params = {}} = {}, navigation, user} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter = {}, params: filterParams = {}} = filterValues;
  const {period = {}} = filterParams;
  const {employee = {}} = user;
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    tandm: {
      apiKey: 'active',
      label: 'T&M',
      view: (
        <DeliveredProjectResourceWorkingTable
          tab={'tandm'}
          period={period}
          addOnFilter={{
            ...filter,
            order_type: 'r',
            approval_status: 'Delivered',
            account_owner_id: employee?._id,
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
      api: '/projectResourceWorking/delivered',
      search: searchValue,
      searchFields: 'delivery_number',
      addOnFilter: {
        ...filter,
        order_type: 'r',
        approval_status: 'Delivered',
        account_owner_id: employee?._id,
      },
      params: {
        period,
      },
      limit: 1000,
      actions: [
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/projectResourceWorking/delivered',
                    search: searchValue,
                    searchFields: 'delivery_number',
                    addOnFilter: {
                      ...filter,
                      order_type: 'r',
                      approval_status: 'Delivered',
                      account_owner_id: employee?._id,
                    },
                    params: {
                      period,
                    },
                  },
                  column: {
                    Owner: 'account_owner_id.name',
                    'Delivery Owner': 'delivery_owner_id.name',
                    Employee: 'project_resource_users.name',
                    Tool: 'tool.name',
                    Type: 'order_type_export',
                    Delivery: 'delivery_number',
                    Customer: 'customer.name',
                    'Delivery Date': 'delivery_date',
                    'Due Date': 'due_date',
                    'Variable Potential': 'variable_potential',
                    currency: 'order.basecurrency_id.currency',
                    Amount: 'amount',
                    'Base Amount': 'base_amount',
                    Pending: 'pending_amount',
                    'Base Pending': 'base_pending_amount',
                    Status: 'status',
                    'Billing Hrs': 'billing_hrs',
                    Rate: 'rate',
                    Description: 'description',
                    'Delivered Hrs': 'billed_hours_export',
                  },
                },
              },
            });
          }}
        />,
      ],
    },

    fixed: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <DeliveredProjectResourceWorkingTable
          tab={'fixed'}
          period={period}
          addOnFilter={{
            ...filter,
            order_type: 'ot',
            approval_status: 'Delivered',
            account_owner_id: employee?._id,
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
      api: '/projectResourceWorking/delivered',
      search: searchValue,
      searchFields: 'delivery_number',
      addOnFilter: {
        ...filter,
        order_type: 'ot',
        approval_status: 'Delivered',
        account_owner_id: employee?._id,
      },
      params: {
        period,
      },
      limit: 1000,
      actions: [
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportExcelHelper',
              props: {
                props: {
                  fetchProps: {
                    api: '/projectResourceWorking/delivered',
                    search: searchValue,
                    searchFields: 'delivery_number',
                    addOnFilter: {
                      ...filter,
                      order_type: 'ot',
                      approval_status: 'Delivered',
                      account_owner_id: employee?._id,
                    },
                    params: {
                      period,
                    },
                  },
                  column: {
                    Owner: 'account_owner_id.name',
                    'Delivery Owner': 'delivery_owner_id.name',
                    Employee: 'project_resource_users.name',
                    Tool: 'tool.name',
                    Type: 'order_type_export',
                    Delivery: 'delivery_number',
                    Customer: 'customer.name',
                    'Delivery Date': 'delivery_date',
                    'Due Date': 'due_date',
                    'Variable Potential': 'variable_potential',
                    currency: 'order.basecurrency_id.currency',
                    Amount: 'amount',
                    'Base Amount': 'base_amount',
                    Pending: 'pending_amount',
                    'Base Pending': 'base_pending_amount',
                    Status: 'status',
                    'Billing Hrs': 'billing_hrs',
                    Rate: 'rate',
                    Description: 'description',
                    'Delivered Hrs': 'billed_hours_export',
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Resource',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'project_resource_employee',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project',
            },
          ]}
        />,
      ]}
    />
  );
};
