import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const DepartmentForm = props => {
  const {departmentId} = props.route.params;

  let {onSubmit} = useFormSubmit({
    uri: '/departments',
    eventSourceId: 'Department',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction={'Save'}
      api={`/departments/${departmentId}`}
      fields={{
        name: 1,
        code: 1,
        is_active: 1,
        is_direct: 1,
        managers: {name: 1},
      }}
      layoutFields={[
        {
          label: 'Name',
          placeholder: 'Enter Name',
          field: 'name',
          type: 'text',
        },
        {label: 'Code', placeholder: 'Enter code', field: 'code', type: 'text'},
        {
          label: 'Active',
          field: 'is_active',
          type: 'switch',
          size: {sm: 6, md: 6},
        },
        {
          label: 'Direct',
          field: 'is_direct',
          type: 'switch',
          size: {sm: 6, md: 6},
        },
        {
          placeholder: 'Managers',
          label: 'Managers',
          field: 'managers',
          type: 'multiAutoComplete',
          suggestionHeader: 'Select an option',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const AddDepartmentForm = props => {
  return <DepartmentForm header={'Add Department'} {...props} />;
};

export const UpdateDepartmentForm = props => {
  return <DepartmentForm mode="edit" header={'Update Department'} {...props} />;
};
