import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

const EmployeeListWithDetailAllocation = props => {
  let {
    params = {},
    filterValues: {filter = {}, params: filterParams = {}} = {},
  } = props || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      addOnFilter={{...filter}}
      api={`/resourceallocation`}
      variant={'bordered'}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[
                  {value: employee?.official_email_id},
                  {value: `${employee?.experience} yrs`},
                ]}
              />
            );
          },
          minWidth: 300,
        },
        {
          header: 'Front Facing',
          render: ({row}) => {
            const {front_facing_resource} = row;
            if (!front_facing_resource?._id) return null;
            return (
              <UserAvatarWithNameAndEmail
                name={front_facing_resource?.name}
                color={front_facing_resource?.color}
                values={[
                  {value: front_facing_resource?.official_email_id},
                  {value: `${front_facing_resource?.experience} yrs`},
                ]}
              />
            );
          },
          minWidth: 300,
        },
        {
          header: 'Project',
          field: 'project_id.project',
          width: 150,
        },
        {
          header: 'Customer',
          field: 'project_id.customer.name',
          width: 150,
        },
        {
          header: 'Order',
          field: 'order.order_number',
          width: 100,
        },
        {
          header: 'Hours',
          field: 'hours',
          type: 'number',
          width: 80,
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YYYY',
          },
          width: 120,
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YYYY',
          },
          width: 120,
        },
        {
          header: 'Practice',
          field: 'practice.name',
          width: 150,
        },
        {
          header: 'Resource Skill',
          field: 'resource_skill.name',
          width: 150,
        },
        {
          header: 'Resource Tool',
          field: 'resource_tool.name',
          width: 150,
        },
        {
          header: 'Project Skill',
          field: 'project_skill.name',
          width: 150,
        },
        {
          header: 'Project Tool',
          field: 'tool.name',
          width: 150,
        },
        {
          header: 'Account Manager',
          field: 'account_manager.name',
          width: 150,
        },
        {
          header: 'Type',
          field: 'assigned_type',
          width: 150,
        },
        {
          header: 'Level 1 Owner',
          field: 'level_one_owner.name',
          width: 150,
        },
        {
          header: 'Level 2 Owner',
          field: 'level_two_owner.name',
          width: 150,
        },
        {
          header: 'Level 3 Owner',
          field: 'level_three_owner.name',
          width: 150,
        },
      ]}
    />
  );
};

export default EmployeeListWithDetailAllocation;
