import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const bankreconcilation = props => {
    const {
        route: { params },
    } = props;
    let { onSubmit } = useFormSubmit({
        uri: `/voucherlineitems`,
        eventSourceId: 'reconcilation',
        ...props,
    });

    return (
        <Form
            onSubmit={onSubmit}
            header={'Reconcilation Details'}
            submitAction={'Update'}
            layoutFields={[
                {
                    type: 'date',
                    label: 'Bank Transaction Date',
                    placeholder: 'Bank Transaction Date',
                    field: 'bank_transaction_date',
                },
            ]}
        />
    );
};

const ReconcilationDetailForm = props => {
    const {
        route: { params },
    } = props;
    const { row } = params;
    const { onSubmit } = useFormSubmit({
        uri: `/voucherlineitems`,
        eventSourceId: ['reconcilation'],
    });
    return (
        <Form
            api={`/voucherlineitems/${row._id}`}
            mode="edit"
            onSubmit={onSubmit}
            eventSourceId="reconcilation"
            submitAction="Save"
            layoutFields={[
                {
                    type: 'date',
                    field: 'bank_transaction_date',
                    placeholder: 'Bank Transaction Date',
                    label: 'Bank Transaction Date',
                },
            ]}
            {...props}
        />
    );
};

export const UpdateReconcilationDetailForm = props => {
    return <ReconcilationDetailForm header="Update Reconcilation Details" {...props} />;
};

export const BankReconcilationTable = props => {
    const { filterParams, searchValue, route: { params = {} } ,navigation} = props;
    const { accountFilter = {} } = params;
    return (
        <Table
            {...props}
            api={'/getbankreconcilationdata'}
            addOnFilter={{ ...filterParams, ...accountFilter }}
            params={params}
            eventSourceId="reconcilation"
            search={searchValue}
            limit={50}
            columns={[
                {
                    field: 'voucher_id.voucher_no',
                    header: 'Voucher.',
                    type: 'text',
                    width: 600
                },
                {
                    field: 'date',
                    header: 'Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 200

                },
                {
                    type: 'currency',
                    header: "Credit",
                    field: "cr_amount",
                    width: 250
                },
                {
                    field: 'dr_amount',
                    type: 'currency',
                    header: 'Debit',
                    width: 250
                },
                {
                    field: 'bank_transaction_date',
                    header: 'Bank Transaction Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 200
                },
            ]}
            moreActions={[
                {
                    title: 'Voucher',
                    onPress: ({ row }) => {
                        if (row?.voucher_id?._id) {
                            navigation.navigate(`voucherslist`, {
                                voucherfilter: {
                                    _id: row?.voucher_id?._id
                                },
                            });
                        }
                    },
                },
                {
                    title: 'Update Reconcile date',
                    onPress: ({ row }) => {
                        navigation.navigate(`update-reconcile-details`, {
                            row,
                        });
                    },
                },
            ]}
        />
    );
};

export const BankReconcilationTab = props => {
    let { navigation,route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Bank Reconcilation',
            view: (
                <BankReconcilationTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'reconcilation',
            api: '/getbankreconcilationdata',
            addOnFilter: filter,
            search: searchValue,
            //   actions: [
            //     <IconButton
            //       icon={Download}
            //       onPress={() => {
            //         download({
            //           uri: '/exportpaymenttds',
            //           props: {
            //             addOnFilter: { ...filter },
            //           },
            //         });
            //       }}
            //     />,
            //   ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'date',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Account',
                            api: '/accounts',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'account',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};


export const NotBankReconcilationTable = props => {
    const { navigation, filterParams, searchValue, route: { params = {} } } = props;
    const { accountFilter = {} } = params;
    return (
        <Table
            {...props}
            api={'/notbankreconcilationdata'}
            addOnFilter={{ ...filterParams, ...accountFilter }}
            params={params}
            eventSourceId="reconcilation"
            search={searchValue}
            limit={50}
            selection={{
                actions: [
                    {
                        title: 'Reconcilation',
                        onPress: ({ selectedIds }) =>
                            navigation.navigate('update-bank-reconcilation-detail', { selectedIds }),
                    },
                ],
            }}
            columns={[
                {
                    field: 'voucher_id.voucher_no',
                    header: 'Voucher.',
                    type: 'text',
                    width: 600
                },
                {
                    field: 'date',
                    header: 'Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 200

                },
                {
                    type: 'currency',
                    header: "Credit",
                    field: "cr_amount",
                    width: 250
                },
                {
                    field: 'dr_amount',
                    type: 'currency',
                    header: 'Debit',
                    width: 250
                },
            ]}
            moreActions={[
                {
                    title: 'Voucher',
                    onPress: ({ row }) => {
                        if (row?.voucher_id?._id) {
                            navigation.navigate(`voucherslist`, {
                                voucherfilter: {
                                    _id: row?.voucher_id?._id
                                },
                            });
                        }
                    },
                },
                {
                    title: 'Update Reconcile date',
                    onPress: ({ row }) => {
                        navigation.navigate(`update-reconcile-details`, {
                            row,
                        });
                    },
                },
            ]}
        />
    );
};

export const NotBankReconcilationTab = props => {
    let { route: { params } = {} } = props;
    const { searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Bank Reconcilation',
            view: (
                <NotBankReconcilationTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'reconcilation',
            api: '/notbankreconcilationdata',
            addOnFilter: filter,
            search: searchValue,
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'date',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Account',
                            api: '/accounts',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'account',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};

const BankBalanceTable = props => {
    const { navigation, filterParams, searchValue, params } = props;
    return (
        <Table
            {...props}
            api={'/getbankbalancedata'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="reconcilation"
            search={searchValue}
            limit={50}
            columns={[
                {
                    field: 'account.name',
                    header: 'Account',
                    type: 'text',
                    width: 400
                },
                {
                    type: 'currency',
                    header: "Ledger Amount",
                    field: "ledgerAmount",
                    width: 250
                },
                {
                    type: 'currency',
                    header: "Credit",
                    field: "cr_amount",
                    width: 250
                },
                {
                    field: 'dr_amount',
                    type: 'currency',
                    header: 'Debit',
                    width: 250
                },
                {
                    field: 'bankBalance',
                    type: 'currency',
                    header: 'Bank Balance',
                    width: 250
                },
            ]}
            moreActions={[
                {
                    title: 'Reconcile',
                    onPress: ({ row }) => {
                        if (row?.account?._id) {
                            navigation.navigate(`bankreconcilelist`, {
                                accountFilter: {
                                    account: row?.account?._id
                                },
                            });
                        }
                    },
                },
                {
                    title: 'Not Reconcile',
                    onPress: ({ row }) => {
                        if (row?.account?._id) {
                            navigation.navigate(`bankreconcilelist`, {
                                accountFilter: {
                                    account: row?.account?._id
                                },
                            });
                        }
                    },
                },
            ]}
        />
    );
};

export const BankBalanceTab = props => {
    let { route: { params } = {} } = props;
    const { searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        BankReconcilation: {
            label: 'Bank Reconcilation',
            view: (
                <BankReconcilationTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            api: '/getbankreconcilationdata',
            eventSourceId: 'reconcilation',
            addOnFilter: filter,
            search: searchValue,
        },
        NotBankReconcilation: {
            label: 'Not Bank Reconcilation',
            view: (
                <NotBankReconcilationTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            api: '/notbankreconcilationdata',
            eventSourceId: 'reconcilation',
            addOnFilter: filter,
            search: searchValue,
        },
        BankBalance: {
            label: 'Bank Balance',
            view: (
                <BankBalanceTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'reconcilation',
            addOnFilter: filter,
            search: searchValue,
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'date',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Account',
                            api: '/accounts',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'account',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};
