import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ToolForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/tools',
    eventSourceId: 'skillMetricsAdded',
  });
  return (
    <Form
      api={`/tools/${row?._id}`}
      fields={{
        name: 1,
        practice: {name: 1},
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Tool',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Practice',
          field: 'practice',
          type: 'autoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddToolForm = props => {
  return <ToolForm header={'Add Tool'} {...props} />;
};

export const EditToolForm = props => {
  return <ToolForm header={'Edit Tool'} mode="edit" {...props} />;
};
