import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const KPITargetTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'kpitargets',
  });

  return (
    <Table
      eventSourceId={'kpitargets'}
      api={`/kpitargets`}
      renderHeader={() => (
        <TableHeader
          title="KPI Target"
          actions={[
            <AddButton
              title="Add"
              view="add-key-perfermance-indicator-target"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        kpi_id: {name: 1},
        kra_id: {name: 1},
        target_value: 1,
        start_date: 1,
        end_date: 1,
        status: 1,
        entity: {label: 1},
        department: {name: 1},
        role: 1,
      }}
      columns={[
        {
          field: 'kra_id.name',
          header: 'KRA',
          responsive: 'sm',
        },
        {
          field: 'kpi_id.name',
          header: 'KPI',
          responsive: 'sm',
        },
        {
          field: 'department.name',
          header: 'Department',
          responsive: 'sm',
        },
        {
          field: 'entity.label',
          header: 'Entity',
          width: 80,
        },
        {
          field: 'role',
          header: 'Role',
          responsive: 'sm',
          width: 80,
        },
        {
          field: 'target_value',
          header: 'Target',
          responsive: 'sm',
          width: 70,
        },
        {
          field: 'start_date',
          type: 'date',
          header: 'Start date',
          width: 100,
        },
        {
          header: 'End date',
          field: 'end_date',
          type: 'date',
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-perfermance-indicator-target', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/kpitargets/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default KPITargetTable;
