import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab';
import {VendorInvoiceTable} from './VendorInvoiceTable';
import {AddButton} from '../../../components/button/AddButton';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

export const VendorInvoiceTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    all: {
      label: 'All',
      view: (
        <VendorInvoiceTable
          searchValue={searchValue}
          filterValues={filterValues}
          addOnFilter={{
            ...filterValues.filter,
            is_schedule_based: {$in: [null, false]},
          }}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportvendorinvoices',
              props: {
                addOnFilter: {
                  ...filterValues.filter,
                  is_schedule_based: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
      eventSourceId: 'VendorInvoiceView',
      api: '/vendorInvoices/all',
      search: searchValue,
      searchFields: 'invoice_no',
      addOnFilter: {
        ...filterValues.filter,
        is_schedule_based: {$in: [null, false]},
      },
    },
    schedule: {
      label: 'Schedule',
      view: (
        <VendorInvoiceTable
          searchValue={searchValue}
          addOnFilter={{...filterValues.filter, is_schedule_based: true}}
          filterValues={filterValues}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportvendorinvoices',
              props: {
                addOnFilter: {
                  ...filterValues.filter,
                  is_schedule_based: true,
                },
              },
            });
          }}
        />,
      ],
      eventSourceId: 'VendorInvoiceView',
      api: '/vendorInvoices/all',
      search: searchValue,
      searchFields: 'invoice_no',
      addOnFilter: {...filterValues.filter, is_schedule_based: true},
    },
    pending: {
      label: 'Pending',
      view: (
        <VendorInvoiceTable
          searchValue={searchValue}
          addOnFilter={{
            ...filterValues.filter,
            invoice_type: {
              $in: [
                'Invoice',
                'Asset',
                'Expense',
                'Salary',
                'Reimbursement',
                'expense',
                'salary',
              ],
            },
            base_pending_amount: {$gt: 0.0},
          }}
          filterValues={filterValues}
          {...props}
        />
      ),
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportvendorinvoices',
              props: {
                addOnFilter: {
                  ...filterValues.filter,
                  invoice_type: {
                    $in: [
                      'Invoice',
                      'Asset',
                      'Expense',
                      'Salary',
                      'Reimbursement',
                      'expense',
                      'salary',
                    ],
                  },
                  base_pending_amount: {$gt: 0.0},
                },
              },
            });
          }}
        />,
      ],
      eventSourceId: 'VendorInvoiceView',
      api: '/vendorInvoices/all',
      search: searchValue,
      searchFields: 'invoice_no',
      addOnFilter: {
        ...filterValues.filter,
        invoice_type: {
          $in: [
            'Invoice',
            'Asset',
            'Expense',
            'Salary',
            'Reimbursement',
            'expense',
            'salary',
          ],
        },
        base_pending_amount: {$gt: 0.0},
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Payee',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'autoComplete',
              field: 'department',
              label: 'Department',
              placeholder: 'Select',
              api: '/departments',
              valueField: 'name',
              suggestionField: 'name',
            },
            {
              type: 'date',
              field: 'invoice_date',
              label: 'Invoice Date',
            },

            {
              type: 'autoComplete',
              label: 'Invoice Type',
              options: [
                {label: 'Debit', value: 'Debit'},
                {label: 'Direct', value: 'Empcost'},
                {label: 'Salary', value: 'Salary'},
                {label: 'Asset', value: 'Asset'},
                {label: 'Cost', value: 'Cost'},
                {label: 'Expense', value: 'Expense'},
                {label: 'Depreciation', value: 'Depreciation'},
                {label: 'Provision', value: 'Provision'},
                {label: 'Invoice', value: 'Invoice'},
                {label: 'Other', value: 'Other'},
              ],
              placeholder: 'Select',
              field: 'invoice_type',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Approve',
              options: [
                {label: 'True', value: 'true'},
                {label: 'False', value: {$in: [null, false]}},
              ],
              placeholder: 'Select',
              field: 'is_approved',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Hold Salary',
              options: [
                {label: 'True', value: 'true'},
                {label: 'False', value: {$in: [null, false]}},
              ],
              placeholder: 'Select',
              field: 'hold_salary',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Transfer Mode',
              options: [
                {label: 'Account Transfer', value: 'Account Transfer'},
                {label: 'By Cheque', value: 'By Cheque'},
              ],
              placeholder: 'Select',
              field: 'transfer_mode',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'multiAutoComplete',
              label: 'Tax Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
        <AddButton title="Add Invoice" view={'add-vendor-invoice'} />,
      ]}
    />
  );
};
