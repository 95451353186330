export const TabViewStyles = ({theme: {colors, fonts, shadows}}) => {
  return {
    tabBarRowStyle: {
      containerStyle: {
        marginBottom: 12,
      },
    },
    tabItemStyle: {
      itemWrapperStyle: {
        backgroundColor: colors.OUTLINE,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
      },
      firstItemWrapperStyle: {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },
      lastItemWrapperStyle: {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
      itemStyle: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'transparent',
      },
      activeItemStyle: {
        borderRadius: 5,
        backgroundColor: colors.BACKGROUND,
        borderColor: 'transparent',
        ...shadows.Text,
      },
      labelStyle: {
        color: colors.NEUTRAL_MEDIUM,
        ...fonts.SIDE_NAVIGATION,
      },
      activeLabelStyle: {
        color: colors.NEUTRAL_HIGH,
        ...fonts.SIDE_NAVIGATION,
      },
    },
  };
};

export const StatusTabViewStyles = ({theme: {colors, fonts, shadows}}) => {
  const tabViewStyles = TabViewStyles({theme: {colors, fonts, shadows}});
  return {
    ...tabViewStyles,
    tabItemStyle: {
      ...tabViewStyles.tabItemStyle,
      labelStyle: {
        color: colors.INFORMATION_HIGH,
        ...fonts.SIDE_NAVIGATION,
      },
      activeLabelStyle: {
        color: colors.INFORMATION_HIGH,
        ...fonts.SIDE_NAVIGATION,
      },
    },
  };
};
