import React from 'react';
import { Table } from '../../../components/table/Table';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useAppStateContext } from '../../../providers/AppState';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { randomColor } from '../../../utils/util';
import { useTheme } from '@unthinkable/react-theme';
import { IconButton } from '../../../components/button/IconButton';


const FoodCouponReportTable = props => {
    const { filterParams, params } = props;
    return (
        <Table
            {...props}
            api={'/getfoodcouponreport'}
            addOnFilter={filterParams}
            params={{ ...filterParams, ...params }}
            eventSourceId="foodcouponreport"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'name',
                    header: 'Employee Name',
                },
                {
                    field: 'employee_code',
                    header: 'Employee Code.',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    field: 'invoice_date',
                    header: 'Invoice Date',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 180,
                },
                {
                    field: 'paytm_no',
                    header: 'Paytm Number',
                    type: 'text',
                    width: 180,
                },
                {
                    field: 'amount',
                    header: 'Amount',
                    type: 'currency',
                    width: 180,
                },

            ]}
        />
    );
};

export const FoodCouponReportTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Food Coupon',
            view: (
                <FoodCouponReportTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'foodcouponreport',
            addOnFilter: filter,
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportfoodcouponreport',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                    ]}
                />,
            ]}
        />
    );
};
