export const AddCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    AddIcon: icons.Add,
    cardContainerStyle: {
      padding: 24,
      borderRadius: 24,
      borderWidth: 2,
      borderColor: colors.ACCENT3_HIGH,
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
    },
    titleTextStyle: {...fonts.HEADING1, color: colors.NEUTRAL_MEDIUM},
    countTextStyle: {...fonts.TITLE, color: colors.ACCENT3_HIGH},
  };
};

export const MultiCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    AddIcon: icons.Add,
    cardContainerStyle: {
      padding: 24,
      borderRadius: 24,
      borderWidth: 2,
      borderColor: colors.ACCENT3_HIGH,
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
    },
    titleTextStyle: {...fonts.HEADING1, color: colors.NEUTRAL_MEDIUM},
    countTextStyle: {...fonts.TITLE, color: colors.ACCENT3_HIGH},
  };
};

export const aggregateCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
      padding: 8,
      cursor: 'pointer',
      alignItems: 'center',
    },
    label: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    aggregate: {
      ...fonts.HEADING2,
    },
  };
};

export const comparisonIndicatorStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    profitIcon: icons.Profit,
    lossIcon: icons.Loss,
    profit: {
      ...fonts.CAPTION_LARGE,
      color: colors.SUCCESS_HIGH,
    },
    loss: {
      ...fonts.CAPTION_LARGE,
      color: colors.ERROR_HIGH,
    },
  };
};

export const comparisonCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 12,
      paddingRight: 12,
      borderColor: colors.ACCENT3_HIGH,
      borderWidth: 1,
      cursor: 'pointer',
    },
    valueText: {
      ...fonts.HEADING1,
      color: colors.NEUTRAL_HIGH,
    },
    label: {
      ...fonts.FOOTNOTE,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const ChatCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    AskAIIcon: icons.AskAILogo,
    AddIcon: icons.Add,
    sendCardContainerStyle: {
      alignSelf: 'flex-end',
      marginBottom: 16,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: colors.OUTLINE,
      marginRight: 8,
      maxWidth: '80%',
      overflow: 'hidden',
    },

    receiverCardContainerStyle: {
      alignSelf: 'flex-start',
      marginBottom: 16,
      borderTopRightRadius: 12,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: colors.BACKGROUND,
      borderWidth: 1,
      borderColor: colors.BRAND_UPPER_LOW,
      marginLeft: 8,
      maxWidth: '80%',
      overflow: 'hidden',
    },
    titleTextStyle: {...fonts.BODY3, color: colors.NEUTRAL_HIGH},
    rowStyle: {alignItems: 'flex-start', alignSelf: 'flex-start'},
    iconStyle: {height: 32, width: 32},
  };
};

export const percentageCard = ({theme: {fonts, colors, icons}}) => {
  return {
    buttonStyle: {},
    warningTextColor: {color: colors.WARNING_HIGH},
    errorTextColor: {color: colors.ERROR_HIGH},
    successTextColor: {color: colors.SUCCESS_HIGH},
    successBgColor: {backgroundColor: colors.SUCCESS_LOW},
    warningBgColor: {backgroundColor: colors.WARNING_LOW},
    errorBgColor: {backgroundColor: colors.ERROR_LOW},
    iconStyle: {width: 14},
    titleStyle: {...fonts.CAPTION_LARGE},
  };
};

export const GroupContentItemStyles = ({theme: {fonts, colors}}) => {
  return {
    itemContainer: {alignItems: 'center', gap: 8},
    text: {...fonts.BODY2, color: colors.NEUTRAL_MEDIUM},
    secondaryText: {...fonts.FOOTNOTE, color: colors.NEUTRAL_MEDIUM},
    icon: {height: 16, width: 16},
  };
};

export const PrimaryColumnCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      flex: 1,
      rowGap: 2,
      justifyContent: 'center',
      overflow: 'hidden',
    },
    title: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    titleIcon: {
      width: 16,
      height: 16,
    },
    separatorColor: colors.OUTLINE,
    itemsContainer: {
      gap: 6,
      alignItems: 'center',
    },
    item: {
      ...fonts.CAPTION_LARGE,
      color: colors.NEUTRAL_LOW,
    },
    secondaryItem: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_HIGH,
      paddingLeft: 4,
      paddingRight: 4,
      borderRadius: 4,
    },
    icon: {
      height: 12,
      width: 12,
    },
  };
};

export const FeatureColumnCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    flagIcon: icons.FlagBrand,
    containerStyle: {
      flex: 1,
      rowGap: 2,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    imageContainerStyle: {
      borderRadius: 2,
      padding: 5,
      marginTop: 4,
      marginBottom: 4,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.BRAND_UPPER_LOW,
    },
    issueIconContainerStyle: {
      borderRadius: 2,
      height: 24,
      width: 24,
      marginTop: 4,
      marginBottom: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    featureImprovementIcon: icons.FeatureImprovement,
    newFeatureIcon: icons.NewFeature,
    issueIcon: icons.Issue,
  };
};

export const BugIssueHeaderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    textStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
      textAlign: 'center',
    },
  };
};
export const VersionCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    featureIcon: icons.NewFeature,
    container: {
      backgroundColor: colors.SURFACE3,
      borderRadius: 6,
      padding: 10,
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: 'transparent',
    },
    activeContainer: {
      borderColor: colors.BRAND_UPPER_MEDIUM,
    },
    boldTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    currentTextStyle: {
      ...fonts.BODY2,
      color: colors.INFORMATION_HIGH,
      width: 70,
    },
    textStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    dateStyle: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_LOW,
    },
    activeStatusStyle: {
      ...fonts.CAPTION,
      color: colors.INFORMATION_HIGH,
    },
    releasedStatusStyle: {
      ...fonts.CAPTION,
      color: colors.SUCCESS_HIGH,
    },
  };
};
export const LibraryVersionCardStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      backgroundColor: colors.SURFACE3,
      borderRadius: 6,
      height: 60,
      width: 250,
      padding: 10,
      alignItems: 'flex-start',
      flexDirection: 'column',
      borderWidth: 1,
      borderColor: 'transparent',
    },
    activeContainer: {
      borderColor: colors.BRAND_UPPER_MEDIUM,
    },
    boldTextStyle: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },

    textStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};
