import React from 'react';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {TableHeader} from '../../../components/table/Headers';
import {
  AISuggestionRender,
  AISuggestionsButton,
} from '../cellrenders/AISuggestionRenders';
import {AITable} from '../../../components/table/AITable';
import {useTheme} from '@unthinkable/react-theme';
import {Col, Text, View} from '@unthinkable/react-core-components';

const RoleInlineForm = props => {
  const {
    route: {params},
  } = props;
  const {project} = params || {};
  const {_id: project_id} = project || {};

  let {onSubmit} = useFormSubmit({
    uri: '/projectroles',
    eventSourceId: 'Role',
    ...props,
  });
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project_id,
      }}
      layoutFields={[
        {
          label: 'Role',
          field: 'role',
          type: 'text',
        },
      ]}
    />
  );
};

export const ProjectRoleTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {project} = params || {};
  const {_id: project_id} = project || {};
  const {colors, fonts} = useTheme();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Role',
  });

  return (
    <AITable
      renderHeader={() => {
        return (
          <>
            <TableHeader
              title="Roles"
              actions={[
                project?.ai_enabled && (
                  <AISuggestionsButton
                    {...props}
                    entityName="projectrole"
                    filter={{
                      project_id: project._id,
                    }}
                    params={{
                      parent_entity: 'Project',
                      parent_entity_id: project._id,
                      project_id: project._id,
                    }}
                    initialValues={{
                      project_id: project._id,
                    }}
                    eventSourceId="RoleAi"
                  />
                ),
              ]}
            />
            <RoleInlineForm {...props} />
          </>
        );
      }}
      api={`/projectroles`}
      eventSourceId={['Role', 'RoleAi']}
      filter={{
        project_id,
      }}
      fields={{
        role: 1,
        module_id: {module: 1, color: 1},
        desc: 1,
      }}
      secondaryApiProps={{
        api: `/projectRoles/ai`,
        params: {parent_entity: 'Project', parent_entity_id: project?._id},
        eventSourceId: 'RoleAi',
      }}
      columns={[
        {
          header: 'Role',
          render: _props => {
            const {row, styles} = _props;
            let {module_id, aiGenerated} = row;
            module_id =
              aiGenerated && module_id?.map(({module}) => module)?.join(', ');
            return (
              <Col gap={8}>
                <AISuggestionRender
                  {..._props}
                  primaryField="role"
                  secondaryField="desc"
                  entityName="projectrole"
                  filter={{
                    project_id: project._id,
                  }}
                  params={{
                    parent_entity: 'Project',
                    parent_entity_id: project._id,
                    project_id: project._id,
                  }}
                  initialValues={{
                    project_id: project._id,
                  }}
                  eventSourceId="RoleAi"
                />

                {module_id ? (
                  <View
                    style={{
                      paddingTop: 6,
                      paddingRight: 16,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      backgroundColor: colors?.SURFACE3,
                      borderRadius: 12,
                    }}>
                    <Text
                      style={{
                        ...fonts.CAPTION_SMALL,
                        color: colors.NEUTRAL_LOW,
                      }}>
                      Modules:
                      <Text
                        style={{
                          color: colors.NEUTRAL_MEDIUM,
                        }}
                        as="span">{` ${module_id}`}</Text>
                    </Text>
                  </View>
                ) : (
                  void 0
                )}
              </Col>
            );
          },
          colspan: ({row}) => (row.aiGenerated ? 2 : void 0),
        },
        {
          header: 'Modules',
          field: 'module_id',
          labelField: 'module',
          colorField: 'color',
          titleField: 'module',
          maxChips: 3,
          render: MultiColorTag,
        },
      ]}
      onRowPress={({row}) => {
        if (row?.aiGenerated) {
          return;
        }
        navigation.navigate(`role-detail`, {
          ...params,
          role: row,
        });
      }}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this role?',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/projectroles/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
