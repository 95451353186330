import React from 'react';
import {RecursiveTable} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {RecursiveRender} from '@unthinkable/react-table';
import {useFetchData} from '../../../controllers/useFetchData';
import {Row} from '@unthinkable/react-core-components';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

const TrailBalanceRecusiveTable = props => {
  const {params} = props;
  const {navigation, filterParams} = params;
  return (
    <RecursiveTable
      {...props}
      recursiveKey="_children"
      columns={[
        {
          render: RecursiveRender,
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Credit',
          field: 'cr_amount',
          type: 'currency',
        },
        {
          header: 'Debit',
          field: 'dr_amount',
          value: ({row}) => Math.abs(row.dr_amount || 0),
          type: 'currency',
        },
      ]}
    />
  );
};

const TrailBalanceTable = props => {
  const {filterParams, searchValue, params} = props;
  const {data, loading} = useFetchData({
    api: '/gettrailbalance',
    addOnFilter: filterParams,
    params,
    eventSourceId: 'trial',
  });
  return (
    <TrailBalanceRecusiveTable data={data} loading={loading} params={props} />
  );
};

export const TrailBalanceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    all: {
      label: 'Trial',
      view: (
        <TrailBalanceTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
          params={{
            ...params,
            ...filterValues?.params,
          }}
        />
      ),
      eventSourceId: 'Trial',
      addOnFilter: filter,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/trialbalancereport',
              props: {
                addOnFilter: {...filter},
                params: {...params, ...filterValues.params},
                globalParams: globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={{...params, ...filterValues.params}}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'period',
              // asParam: true,
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Currency',
              placeholder: 'Select',
              options: [
                {label: 'INR', value: 'INR'},
                {label: 'Other', value: 'Other'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              valueField: 'label',
              field: 'currency',
            },
          ]}
        />,
      ]}
    />
  );
};
