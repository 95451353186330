import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import React, {useState} from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {randomColor} from '../../../utils/util';

const RenderRequestAction = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['fundTransfer'],
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.transaction_status === 'Pending maker approval') {
    return (
      <View style={{flexDirection: 'row'}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              const {_id} = row;
              invoke({
                uri: `/approvePaymentByFundTransfer`,
                props: {
                  selectedId: _id,
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>
              Req For Bank Transaction
            </Text>
          </View>
        )}
      </View>
    );
  } else
    return (
      <View>
        <Text style={{...rowText}}>{row.transaction_status}</Text>
      </View>
    );
};

const RenderAction = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'fundTransfer',
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View
          style={{
            padding: 4,
            alignItem: 'center',
            textAlign: 'center',
          }}
          onPress={() => {
            const {_id} = row;
            invoke({
              uri: `/sendForFundTansferApproval`,
              props: {
                selectedID: _id,
              },
            });
          }}>
          <Text style={{...rowText, color: '#34C759'}}>Accept</Text>
        </View>
      )}
    </View>
  );
};

export const OnlineFundTransferTable = props => {
  const {navigation, tab, addOnFilter} = props;
  return (
    <Table
      eventSourceId={['fundTransfer']}
      api={`/fundtransfer`}
      onRowPress={({row}) =>
        navigation.navigate('edit-fund-transfer', {
          row,
          readOnly: true,
        })
      }
      addOnFilter={addOnFilter}
      columns={[
        {
          field: 'date',
          header: 'Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 200,
        },
        {
          header: 'Fund Transfer No',
          minWidth: 200,
          field: 'fund_transfer_no',
        },
        {
          header: 'From',
          minWwidth: 300,
          render: ({row}) => {
            return (
              <>
                <Tag color={randomColor()} value={row.from_fund.name} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row.from_fund.account_number,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'To',
          minWidth: 300,
          render: ({row}) => {
            return (
              <>
                <Tag color={randomColor()} value={row.to_fund.name} />
                <PrimaryColumnCard
                  items={[
                    {
                      value: row.to_fund.account_number,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Transfer Amount',
          field: 'transfer_amount',
          type: 'text',
        },
        {
          header: 'Action',
          width: 100,
          align: 'center',
          render: ({row, styles}) => <RenderAction row={row} styles={styles} />,
          visible: () => {
            if (tab === 'ToApprove') return true;
          },
        },
        {
          header: 'Action',
          width: 300,
          align: 'center',
          render: ({row, styles}) => (
            <RenderRequestAction row={row} styles={styles} />
          ),
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Reject',
          confirm: {
            title: 'Reject',
            message: 'Are you sure you want to Reject?',
          },
          visible: ({row}) => {
            if (
              tab === 'ToApprove' ||
              (tab === 'Approved' &&
                row.transaction_status === 'Pending maker approval')
            )
              return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/fundtransfers/${row?._id}`,
              props: {
                transaction_status: 'Rejected',
              },
            });
          },
        },
        {
          title: 'Bank Transaction',
          onPress: ({row}) => {
            navigation.navigate('bank-transaction-fund', {row});
          },
          visible: () => {
            if (tab === 'Approved') return true;
          },
        },
        // {
        //   title: 'Voucher',
        //   onPress: ({row}) => {
        //     navigation.navigate(`open-voucher`, {
        //       extraFilter: {fund_transfer_id: row._id},
        //     });
        //   },
        // },
      ]}
    />
  );
};
