import React from 'react';
import {plural} from 'pluralize';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme, useStyles} from '@unthinkable/react-theme';
import {RowWithSeparator} from '@unthinkable/react-layout';

import {useFilter} from '../../../controllers/useFilter';
import {CardTable} from '../../../components/table/Table';
import {RichTextRenderer} from '../../../components/form-editors/text/RichTextAreaInput';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {
  CUSTOMER_TYPE_ID,
  PROJECT_BUG_TYPE_ID,
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_ISSUE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_MODULE_TYPE_ID,
  PROJECT_SUB_MODULE_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
  PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  PROJECT_TYPE_ID,
  PROJECT_USER_STORY_TYPE_ID,
  PROJECT_VIEW_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {DateTime} from '../../../components/comment';
import {Chip, Tag} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';
import {MyCommentsTableStyles} from './theme';
import {
  FIELDS,
  NAVIGATE_TO,
  POPULATE,
  getParams,
} from '../../common/constants/ScreenConstants';

const prefixIcon = {
  [PROJECT_FEATURE_TYPE_ID]: 'Feature',
  [PROJECT_BUG_TYPE_ID]: 'RedBug',
  [PROJECT_ISSUE_TYPE_ID]: 'IssueMinus',
  [PROJECT_TASK_TYPE_ID]: 'Task',
  [PROJECT_VIEW_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_USER_STORY_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_COMPONENT_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_CONTROLLER_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_MODEL_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_DATA_SERVICE_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID]: 'ObjectsPMT',
  [PROJECT_MODULE_TYPE_ID]: 'Module',
  [PROJECT_SUB_MODULE_TYPE_ID]: 'Module',
  [PROJECT_TYPE_ID]: 'Project',
};

const getTwoParentObjects = ({row}) => {
  const arr = [];
  const {source_id} = row;

  if (
    row?.source_id?.source?._id &&
    row?.source_id?.source?._id !== PROJECT_FEATURE_TYPE_ID
  ) {
    if (row?.source_id?.project_id) {
      arr.push({icon: 'ObjectsPMT', value: row?.source_id?.source_id?.name});
    } else {
      let text = `(${source_id?.source?.label})`;
      if (row?.source_id?.source_id?.name) {
        text = row?.source_id?.source_id?.name + ' ' + text;
      }
      arr.push({value: text});
    }
  }
  if (source_id?.feature_id?.feature) {
    arr.push({
      icon:
        source_id?.feature_id?.type === 'feature'
          ? prefixIcon[PROJECT_FEATURE_TYPE_ID]
          : prefixIcon[PROJECT_ISSUE_TYPE_ID],
      value: row?.source_id?.feature_id?.feature,
    });
  }
  if (row?.source_id?.module_id?.module) {
    arr.push({
      icon: prefixIcon[PROJECT_MODULE_TYPE_ID],
      value: row?.source_id?.module_id?.module,
    });
  }
  if (row?.source_id?.project_id?.project) {
    arr.push({
      icon: prefixIcon[PROJECT_TYPE_ID],
      value: row?.source_id?.project_id?.project,
    });
  }
  return arr;
};

const CommentSourceRender = ({row, navigation}) => {
  let {fetch} = useAppStateContext();
  const {fonts, colors, icons} = useTheme();
  const {container, textWrapperView, dateContainer} = useStyles(
    MyCommentsTableStyles,
  );
  const {source, source_id, comment_by} = row;

  let parentObjects = getTwoParentObjects({row});
  let iconIndex = source?._id;
  if (source?._id === PROJECT_FEATURE_TYPE_ID && source_id?.type === 'issue') {
    iconIndex = PROJECT_ISSUE_TYPE_ID;
  }

  let tagIcon = prefixIcon[iconIndex] || 'OtherObjects';

  const viewLabel = row?.view_id?.label;
  const viewPeriodLabel = row?.view_id?.view_metadata?.periodLabel;
  let infoText = row?.source?.label;

  if (viewLabel) {
    infoText = infoText ? infoText + ' | ' + viewLabel : viewLabel;
  }

  if (viewPeriodLabel) {
    infoText = infoText ? infoText + ' | ' + viewPeriodLabel : viewPeriodLabel;
  }

  const commentDetail = `${row?.source_id?.[source?.display_field]}${
    infoText ? ` (${infoText})` : ''
  }`;

  return (
    <Row gap={16} style={container}>
      <UserAvatar value={comment_by} size={28} />
      <Col
        gap={8}
        style={{
          flex: 1,
          overflow: 'hidden',
        }}>
        <View style={textWrapperView}>
          <RichTextRenderer value={row?.comment} />
        </View>
        <TouchableOpacity
          onPress={async () => {
            let typeId;
            switch (row?.view_id?.view) {
              case 'growth-and-billing':
                typeId = CUSTOMER_TYPE_ID;
                break;
            }

            if (typeId) {
              navigation.navigate(row?.view_id?.view, {
                ...row?.view_id?.view_metadata?.params,
                selectedTab: row?.view_id?.view_metadata?.selectedTab,
              });
            } else {
              const {source, source_id} = row;
              const api = `/${plural(source?.name).toLowerCase()}`;

              try {
                if (NAVIGATE_TO[source?._id]) {
                  const {data} = await fetch({
                    uri: api,
                    props: {
                      fields: FIELDS[source?._id],
                      filter: {_id: source_id?._id},
                      populate: POPULATE[source?._id],
                      only: true,
                    },
                  });
                  const params = getParams({source, source_id, data});

                  if (params) {
                    navigation.navigate(`${NAVIGATE_TO[source?._id]}`, params);
                  }
                }
              } catch (err) {
                console.log('error caught in new function = ', err);
              }
            }
          }}>
          <Tag
            prefix={() => {
              return <Image source={icons[tagIcon]} />;
            }}
            value={commentDetail}
            color={
              row?.source_id?.type === 'issue'
                ? colors.ERROR_MEDIUM
                : row?.source?.color
            }
          />
        </TouchableOpacity>
        <RowWithSeparator
          style={{alignItems: 'center'}}
          separator={
            <Text
              style={{
                ...fonts.BODY3,
                color: colors.NEUTRAL_LOW,
              }}>
              {'<'}
            </Text>
          }>
          <Chip
            prefix={() => {
              return <Image source={icons[parentObjects?.[0]?.icon]} />;
            }}
            value={parentObjects?.[0]?.value}
            color={colors.BACKGROUND}
            textColor={colors.NEUTRAL_MEDIUM}
            maxWidth={200}
          />
          {parentObjects?.length > 1 ? (
            <Chip
              prefix={() => {
                return <Image source={icons[parentObjects?.[1]?.icon]} />;
              }}
              value={parentObjects?.[1]?.value}
              color={colors.BACKGROUND}
              textColor={colors.NEUTRAL_MEDIUM}
              maxWidth={200}
            />
          ) : null}
        </RowWithSeparator>
      </Col>

      <Col style={dateContainer}>
        <DateTime row={row} />
      </Col>
    </Row>
  );
};

export const MyCommentsList = props => {
  const {navigation} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter();

  return (
    <CardTable
      autoHeight
      eventSourceId={['MsgAdded', 'TriggerOnData', 'Task', 'Comment']}
      api={`/myComments`}
      filter={filterValues.filter}
      onRowPress={({row}) => {
        navigation.navigate(`comment-table`, {
          comment_source: row.source,
          comment_source_id: row.source_id,
          view: row.view_id,
        });
      }}
      searchFields={['comment']}
      search={searchValue}
      renderHeader={() => (
        <TableHeader
          title="Comments"
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Source',
                  api: '/types',
                  placeholder: 'Select Source',
                  suggestionField: 'label',
                  valueField: 'label',
                  field: 'source',
                },
              ]}
            />,
          ]}
        />
      )}
      renderRow={({row, index, level}) => (
        <CommentSourceRender row={row} index={index} level={level} {...props} />
      )}
    />
  );
};
