import React, {useState} from 'react';
import {Row} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form-editors/Editors';
import {Switch} from '../../../components/form-editors/switch/Switch';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {AllocationList} from './DetailResourceAllocatedList';
import {
  PracticeWiseActiveOpportunityList,
  PracticeWiseOpportunityList,
  PracticeWiseScheduledOpportunityList,
} from './PracticeWiseOpportunityList';
import {useAppStateContext} from '../../../providers/AppState';
import {Button} from '../../../components/button/Button';

export const PracticeWiseOpportunityTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const [isComparative, setIsComparative] = useState(false);

  const {download} = useAppStateContext();
  const {params: filterParams = {}} = filterValues || {};
  const tabs = {
    allocation: {
      label: 'Practice Wise Opportunity',
      view: (
        <PracticeWiseOpportunityList
          searchValue={searchValue}
          params={params}
          filterParams={filterParams}
          isComparative={isComparative}
        />
      ),
      eventSourceId: 'practiceWiseOpportunity',
      api: '/opportunity/successreport',
      search: searchValue,
      searchFields: 'name',
      params: {...params, ...filterParams},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        // <Row style={{gap: 4}}>
        //   <TextRenderer value={'Static'} />
        //   <Switch onChangeValue={setIsComparative} value={isComparative} />
        //   <TextRenderer value={'Comparative'} />
        // </Row>,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunity/successreport/export`,
              props: {
                ...params,
                ...filterParams,
              },
            });
          }}
        />,
      ]}
    />
  );
};

export const PracticeWiseScheduledOpportunityTab = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const [isComparative, setIsComparative] = useState(false);

  const {download} = useAppStateContext();
  const {params: filterParams = {}} = filterValues || {};
  const tabs = {
    allocation: {
      label: 'Practice Wise Opportunity',
      view: (
        <PracticeWiseScheduledOpportunityList
          searchValue={searchValue}
          params={params}
          filterParams={filterParams}
          isComparative={isComparative}
        />
      ),
      eventSourceId: 'practiceWiseOpportunity',
      api: '/opportunity/successreport',
      search: searchValue,
      searchFields: 'name',
      params: {...params, ...filterParams},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunity/successreport/export`,
              props: {
                ...params,
                ...filterParams,
              },
            });
          }}
        />,
      ]}
    />
  );
};

export const PracticeWiseOpportunityTabNew = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const [isComparative, setIsComparative] = useState(false);

  const {download} = useAppStateContext();
  const {params: filterParams = {}} = filterValues || {};
  const tabs = {
    active: {
      label: 'Active',
      view: (
        <PracticeWiseActiveOpportunityList
          searchValue={searchValue}
          params={params}
          // filterParams={filterParams}
          isComparative={isComparative}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunity/successreport/export`,
              props: {
                ...params,
                ...filterParams,
              },
            });
          }}
        />,
      ],
      eventSourceId: 'practiceWiseOpportunity',
      api: '/opportunity/practice-wise-active',
      search: searchValue,
      searchFields: 'name',
      params: {...params, ...filterParams},
    },
    closed: {
      label: 'Closed',
      view: (
        <PracticeWiseOpportunityList
          searchValue={searchValue}
          params={params}
          filterParams={filterParams}
          isComparative={isComparative}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunity/successreport/export`,
              props: {
                ...params,
                ...filterParams,
              },
            });
          }}
        />,
      ],
      eventSourceId: 'practiceWiseOpportunity',
      api: '/opportunity/successreport',
      search: searchValue,
      searchFields: 'name',
      params: {...params, ...filterParams},
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      // actions={[
      //   // <Row style={{gap: 4}}>
      //   //   <TextRenderer value={'Static'} />
      //   //   <Switch onChangeValue={setIsComparative} value={isComparative} />
      //   //   <TextRenderer value={'Comparative'} />
      //   // </Row>,
      //   <PeriodRangeFilter
      //     onChangeFilter={onChangeFilter}
      //     filterValues={filterValues}
      //   />,
      //   <SearchFilter
      //     value={searchValue}
      //     onChangeFilter={onChangeFilter}
      //     placeholder="Search"
      //   />,
      //   <Button
      //     text="Download"
      //     onPress={() => {
      //       download({
      //         uri: `/opportunity/successreport/export`,
      //         props: {
      //           ...params,
      //           ...filterParams,
      //         },
      //       });
      //     }}
      //   />,
      // ]}
    />
  );
};
