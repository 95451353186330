import { TouchableOpacity } from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TextRenderer } from '../../../components/form/Renderers';
import { TabView } from '../../../components/tab';
import { TableHeader } from '../../../components/table/Headers';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { AllTrainers } from './AllTrainersList';

export const AllTrainerTabs = props => {
  const {navigation} = props;

  const filterProps = useFilter();
  const {
    filterValues: {filter},
  } = filterProps;

  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    ongoing: {
      label: 'Ongoing Training',
      view: <AllTrainers status={'active'} />,
      api: '/allTrainers',
      filter: {
        status: 'active',
        ...filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportAllTrainers',
              props: {
                filter: {status: 'active', ...filter},
              },
            });
          }}
        />,
      ],
    },
    completed: {
      label: 'Completed Training',
      view: <AllTrainers status={'completed'} />,
      api: '/allTrainers',
      filter: {
        status: 'completed',
        ...filter,
      },
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportAllTrainers',
              props: {
                filter: {status: 'completed', ...filter},
              },
            });
          }}
        />,
      ],
    },
  };
  return (
    <TabView
      headerComponent={
        <TableHeader
          title="All Trainers"
          actions={[
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('inactive-trainer-list', {});
              }}>
              <TextRenderer
                value={'Inactive Trainers'}
                style={{color: '#007AFF'}}
              />
            </TouchableOpacity>,
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  label: 'Trainer',
                  type: 'autoComplete',
                  field: 'mentor',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  placeholder: 'Select Trainer',
                },
                {
                  label: 'Course Batch',
                  type: 'autoComplete',
                  field: 'training_batch',
                  api: '/trainingBatches',
                  suggestionField: 'name',
                  placeholder: 'Select Course Batch',
                  secondarySuggestionField: 'batch_number',
                },
              ]}
            />,
          ]}
        />
      }
      tabs={tabs}
      extraFilter={filter}
      {...props}
    />
  );
};
