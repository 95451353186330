import React from 'react';
import { BacklogOrganizationTaskTable } from '../../task/views/BacklogOrganizationTaskTable';
import { ArchivedOrganizationTaskTable } from '../../task/views/ArchivedOrganizationTaskTable';
import { OrganizationActiveTasksGroupByPlan } from '../../task/views/OrganizationActiveTasksGroupByPlan';
import { TabView } from '../../../components/tab';
import { CompletedTasksTable } from './TasksTable';
import { useAppStateContext } from '../../../providers/AppState';
import { useFetchData } from '../../../controllers/useFetchData';
import { BorderLessButton } from '../../../components/button/AddButton';

export const DelegatedTasksTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const {user} = useAppStateContext();
    const {_id: userId} = user || {};
    const delegatedFilter = { delegators: { $in: [userId] } };

    const { data } = useFetchData({
        api: '/projecttasks',
        filter: {
            status: 'archived',
            type: {
                $in: ['Task', 'Continuous'],
            },
            project_id: null,
            ...delegatedFilter,
        },
        eventSourceId: 'Task',
        count: true,
    });

    return (
        <TabView
            actions={[
                <BorderLessButton
                    text={`Archived (${data?.count || 0})`}
                    onPress={() => {
                        navigation.navigate('archived-tasks', {
                            ...params,
                            delegatedFilter,
                        });
                    }}
                />
            ]}
            tabs={{
                backlog: {
                    label: 'Backlog',
                    api: '/organizationtasks',
                    filter: {
                        status: 'backlog',
                        type: {
                            $in: ['Task', 'Continuous'],
                        },
                        project_id: null,
                        ...delegatedFilter,
                    },
                    eventSourceId: 'Task',
                    view: <BacklogOrganizationTaskTable delegatedFilter={delegatedFilter} delegatedTask={true}/>,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan delegatedFilter={delegatedFilter} delegatedTask={true}/>,
                    api: '/organizationtasks',
                    eventSourceId: 'Task',
                    filter: {
                        status: {$in: ['active']},
                        type: {
                            $in: ['Task', 'Continuous'],
                        },
                        project_id: null,
                        ...delegatedFilter,
                    },
                },
                completed: {
                    label: 'Completed',
                    view: <CompletedTasksTable delegatedFilter={delegatedFilter} delegatedTask={true}/>,
                },
                // archived: {
                //     label: 'Archived',
                //     view: <ArchivedOrganizationTaskTable delegatedFilter={delegatedFilter} />,
                //     api: '/organizationtasks',
                //     filter: {
                //         status: 'archived',
                //         type: {
                //             $in: ['Task', 'Continuous'],
                //         },
                //         project_id: null,
                //         ...delegatedFilter,
                //     },
                //     eventSourceId: ['Task'],
                // },
            }}
            {...props}
        />
    );
};
