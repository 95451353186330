import { getPreviousMonth } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import {
    GroupFilter,
    PeriodRangeFilter,
} from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';

export const AMReportTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  filterParams = {...params, ...filterParams};
  return (
    <Table
      params={{...filterParams}}
      variant={'bordered'}
      addOnFilter={{...filter}}
      limit={5000}
      api={'/amReports'}
      columns={[
        {
          header: 'Customer Accounts',
          children: [
            {
              header: 'AM',
              type: 'userAvatarChip',
              field: 'account_owner',
              width: 250,
            },
            {
              header: 'Active',
              type: 'number',
              field: 'active_customer_count',
              width: 120,
              aggregate: true,
              onPress: ({row}) => {
                navigation.navigate('customer-navigation', {
                  filter: {
                    _id: {$in: row?.active_customer_ids},
                  },
                  filterValues: {params: {...params}},
                });
              },
            },
            {
              header: 'Closed',
              type: 'number',
              field: 'closed_customer_count',
              width: 100,
              aggregate: true,
              onPress: ({row}) => {
                navigation.navigate('customer-am-report', {
                  filter: {
                    _id: {$in: row?.closed_customer_ids},
                  },
                });
              },
            },
          ],
        },
        {
          header: 'T&M Resource Order',
          children: [
            {
              header: 'Previous',
              type: 'number',
              field: 'pervious_resource_order',
              aggregate: true,
              formatOptions: {
                maximumFractionDigits: 2,
              },
            },
            {
              header: 'Current',
              type: 'number',
              field: 'current_resource_order',
              aggregate: true,
              formatOptions: {
                maximumFractionDigits: 2,
              },
            },
            {
              header: 'Net Growth',
              type: 'number',
              field: 'net_growth',
              width: 120,
              aggregate: true,
              formatOptions: {
                maximumFractionDigits: 2,
              },
            },
          ],
        },
        {
          header: 'Fixed',
          children: [
            {
              header: 'New Order',
              type: 'number',
              field: 'fixed_new_order',
              aggregate: true,
              formatOptions: {
                maximumFractionDigits: 2,
              },
            },
          ],
        },
        // {
        //   header: 'Avg CSAT',
        //   type: 'number',
        //   field: 'avg_csat',
        //   width: 80,
        // },
        {
          header: 'Revenue',
          type: 'number',
          field: 'revenue',
          width: 120,
          aggregate: true,
        },
        {
          header: 'Collection',
          children: [
            {
              header: '% On Time Payment Collection',
              type: 'number',
              field: 'on_time_payment_collection',
              width: 240,
            },
          ],
        },
      ]}
    />
  );
};

export const AMReportTab = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {download} = useAppStateContext();
  const tabs = {
    am_report: {
      label: 'AM Report',
      view: (
        <AMReportTable
          filterParams={filterParams}
          filter={{...filter, period}}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
          ]}
        />,
        // <IconButton
        //   icon={icons.DownloadBlue}
        //   onPress={() => {
        //     download({
        //       uri: '/exportTandMProjectGrossMarginReport',
        //       props: {
        //         uri: '/projects/teamcostreport',
        //         parameters: {
        //           params: {...filterParams, isCustomerGrossMargin: true},
        //           addOnFilter: {...filter, ...params?.customerFilter},
        //         },
        //       },
        //     });
        //   }}
        // />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
