import React from 'react';
import { TabView } from '../../../components/tab';
import { ProjectMyActiveTasksTable, ProjectMyCompletedTasksTable } from './MyProjectTaskMenus';
import { BacklogOrganizationTaskTable } from './BacklogOrganizationTaskTable';
import { FavoritesTaskTable, OpenTasksTable } from './TasksTable';
import { OrganizationActiveTasksGroupByPlan, OrganizationCompletedTasks } from './OrganizationActiveTasksGroupByPlan';
import { ArchivedOrganizationTaskTable } from './ArchivedOrganizationTaskTable';
import { BorderLessButton } from '../../../components/button/AddButton';
import { useFetchData } from '../../../controllers/useFetchData';
import { useAppStateContext } from '../../../providers/AppState';

export const MyProjectTaskTabs = props => {
    const {
        route: { params },
        navigation,
        user
    } = props;

    return (
        <TabView
            tabs={{
                active: {
                    label: 'Active',
                    view: <ProjectMyActiveTasksTable />,
                },
                completed: {
                    label: 'Completed',
                    view: <ProjectMyCompletedTasksTable />,
                },
            }}
            {...props}
        />
    );
};

export const MyCustomTaskTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const { user } = useAppStateContext();

    const { data } = useFetchData({
        api: '/projecttasks',
        filter: {
            status: 'archived',
            type: {
                $in: ['Task', 'Continuous'],
            },
            project_id: null,
            assigned_to: user?._id,
        },
        eventSourceId: 'Task',
        count: true,
    });

    return (
        <TabView
            actions={[
                <BorderLessButton
                    text={`Archived (${data?.count || 0})`}
                    onPress={() => {
                        navigation.navigate('archived-tasks', {
                            ...params,
                            userId: user?._id,
                        });
                    }}
                />
            ]}
            tabs={{
                backlog: {
                    label: 'Backlog',
                    view: <BacklogOrganizationTaskTable />,
                },
                open: {
                    label: 'Open',
                    view: <OpenTasksTable />,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan />,
                },
                completed: {
                    label: 'Completed',
                    view: <OrganizationCompletedTasks />,
                },
                // archived: {
                //     label: 'Archived',
                //     view: <ArchivedOrganizationTaskTable />,
                // },
                favourites: {
                    label: 'Favourites',
                    view: <FavoritesTaskTable />,
                }
            }}
            {...props}
        />
    );
};

export const MyFavouriteTaskTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const {user} = useAppStateContext(); 

    const { data } = useFetchData({
        api: '/projecttasks',
        filter: {
            status: 'archived',
            type: {
                $in: ['Task', 'Continuous'],
            },
            project_id: null,
            favorite_by: user?._id,
        },
        eventSourceId: 'Task',
        count: true,
    });

    return (
        <TabView
            actions={[
                <BorderLessButton
                    text={`Archived (${data?.count || 0})`}
                    onPress={() => {
                        navigation.navigate('archived-tasks', {
                            ...params,
                            userId: user?._id,
                            favorite: true,
                        });
                    }}
                />
            ]}
            tabs={{
                backlog: {
                    label: 'Backlog',
                    view: <BacklogOrganizationTaskTable favorite={true} />,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan favorite={true} />,
                },
                completed: {
                    label: 'Completed',
                    view: <OrganizationCompletedTasks favorite={true} />,
                },
                // archived: {
                //     label: 'Archived',
                //     view: <ArchivedOrganizationTaskTable favorite={true} />,
                // },
            }}
            {...props}
        />
    );
};

