import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {ColorTag} from '../../../components/chip/ColorChip';
import {Col, Row} from '@unthinkable/react-core-components';
import {PrimaryCardItems} from '../../../components/card/PrimaryColumnCard';

const useActions = () => {
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Environments',
  });

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Environments',
  });

  const deleteEnvironment = {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete',
      message: 'Are you sure you want to delete this environment?',
    },
    visible: ({row}) => !row.is_system,
    onPress: ({row}) => {
      deleteInvoke({
        uri: `/deleteProjectEnvironments/${row._id}`,
      });
    },
  };

  const markInactive = {
    title: 'Mark Inactive',
    variant: 'error',
    confirm: {
      title: 'Mark Inactive',
      message: 'Are you sure you want to inactive this environment?',
    },
    visible: ({row}) =>
      row.environment !== 'production' &&
      row.environment !== 'development' &&
      row.status !== 'inactive',
    onPress: ({row}) => {
      putInvoke({
        uri: `/projectEnvironments/${row._id}`,
        props: {
          status: 'inactive',
        },
      });
    },
  };
  const markActive = {
    title: 'Mark Active',
    confirm: {
      title: 'Mark Active',
      message: 'Are you sure you want to active this environment?',
    },
    visible: ({row}) =>
      row.environment !== 'production' &&
      row.environment !== 'development' &&
      row.status !== 'active',
    onPress: ({row}) => {
      putInvoke({
        uri: `/projectEnvironments/${row._id}`,
        props: {
          status: 'active',
        },
      });
    },
  };

  const markDynamicBranch = {
    title: 'Mark dynamic branch',
    // confirm: {
    //   title: 'Mark Dynamic Branch',
    //   message: 'Are you sure you want to inactive environment?',
    // },
    visible: ({row}) => row.environment === 'qa',
    // onPress: ({row}) => {
    //   putInvoke({
    //     uri: `/deleteProjectEnvironments/${row._id}`,
    //   });
    // },
  };

  return {deleteEnvironment, markInactive, markDynamicBranch, markActive};
};

export const ProjectEnvironmentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;
  const {deleteEnvironment, markInactive, markActive} = useActions();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'Environments',
  });

  return (
    <Table
      api={`/projectEnvironments`}
      filter={{project_id: project?._id}}
      fields={{
        label: 1,
        index: 1,
        status: 1,
        target_environment: {label: 1, color: 1},
        branch: 1,
        source_environment: {label: 1, color: 1},
        environment: 1,
        is_system: 1,
        is_feature_source: 1,
        feature_branch_merge_to: {label: 1},
      }}
      sort={{index: -1}}
      renderHeader={() => (
        <TableHeader
          title={'Environments'}
          actions={[
            <AddButton
              title="Environment"
              view="project-environment-add-form"
              params={params}
            />,
          ]}
        />
      )}
      eventSourceId="Environments"
      draggable={{
        sort: 'desc',
        indexField: 'index',
        updateIndexOnDragEnd: props => {
          const {updatedIndex, row} = props;
          invoke({
            uri: `/projectEnvironments/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      columns={[
        {
          header: 'Environment',
          render: props => {
            let {
              row: {label, color, feature_branch_merge_to, is_feature_source},
            } = props;
            let featureBranchLabel = feature_branch_merge_to
              ?.map(({label} = {}) => label)
              .join(', ');

            return (
              <Col gap={4}>
                <ColorTag value={label} color={color} />
                {featureBranchLabel || is_feature_source ? (
                  <PrimaryCardItems
                    items={[
                      {
                        value: is_feature_source ? 'Feature Source' : void 0,
                      },
                      {
                        value: featureBranchLabel,
                      },
                    ]}
                  />
                ) : (
                  void 0
                )}
              </Col>
            );
          },
        },
        {
          header: 'Branch',
          type: 'colorTag',
          field: 'branch',
          width: 200,
          align: 'center',
        },
        {
          header: 'Merge To',
          render: props => {
            const {
              row: {target_environment = []},
            } = props;
            if (!target_environment || target_environment.length === 0) {
              return null;
            }
            return (
              <Row gap={2}>
                {target_environment.map(({label, color}) => {
                  return <ColorTag value={label} color={color} />;
                })}
              </Row>
            );
          },
          align: 'center',
        },
        {
          header: 'Source Environment',
          type: 'colorTag',
          render: ({row}) => {
            const {source_environment} = row;
            const {label, color} = source_environment;
            return <ColorTag value={label} color={color} />;
          },
          field: 'source_environment',
          align: 'center',
          width: 200,
        },
        {
          header: 'Status',
          type: 'colorTag',
          field: 'status',
          width: 150,
          align: 'center',
        },
      ]}
      onRowPress={({row}) => {
        navigation.navigate(`project-environment-detail`, {
          ...params,
          deployment_environment: {_id: row?._id},
        });
      }}
      moreActions={[markActive, markInactive, deleteEnvironment]}
    />
  );
};
