import React from 'react';
import {TabView} from '../../../components/tab';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {
  ClientActiveProjectTable,
  ClientAllProjectTable,
  ClientCompletedProjectTable,
} from './ClientProjectTableView';
import {AddButton} from '../../../components/button/AddButton';

export const ClientProjectTabs = props => {
  const {
    user,
    route: {params},
  } = props;
  const {org, employee = {}} = user;
  const activeFilter = {
    project_status: 'a',
    owner_id: employee?._id,
    user_organization: org,
  };

  const completedFilter = {
    project_status: 'c',
    owner_id: employee?._id,
    user_organization: org,
  };

  const {searchValue, onChangeFilter} = useFilter({});
  return (
    <TabView
      selectedTab={'active'}
      tabs={{
        active: {
          label: 'Active',
          view: (
            <ClientActiveProjectTable
              addOnFilter={{
                ...activeFilter,
              }}
              searchValue={searchValue}
              {...props}
            />
          ),
          api: `/clientProjects`,
          addOnFilter: {
            ...activeFilter,
          },
          eventSourceId: 'ClientProject',
        },
        completed: {
          label: 'Completed',
          view: (
            <ClientCompletedProjectTable
              addOnFilter={{
                ...completedFilter,
              }}
              searchValue={searchValue}
              {...props}
            />
          ),
          api: `/clientProjects`,
          addOnFilter: {
            ...completedFilter,
          },
          eventSourceId: 'ClientProject',
        },
      }}
      {...props}
      actions={props => {
        const {selectedTab} = props;
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          selectedTab === 'active' && (
            <AddButton
              title={'Project'}
              view={'client-add-project'}
              params={{
                user,
                ...params,
              }}
            />
          ),
        ];
      }}
    />
  );
};

export const ClientProjectAllTabs = props => {
  const {
    user,
    route: {params},
  } = props;
  const {org} = user;
  const {searchValue, onChangeFilter} = useFilter({});
  const activeFilter = {
    project_status: 'a',
    user_organization: org,
  };
  const completedFilter = {
    project_status: 'c',
    user_organization: org,
  };
  return (
    <TabView
      selectedTab={'active'}
      tabs={{
        active: {
          label: 'Acive',
          view: (
            <ClientAllProjectTable
              addOnFilter={{
                ...activeFilter,
              }}
              searchValue={searchValue}
              selectedTab={'active'}
              {...props}
            />
          ),
          api: `/clientProjects`,
          addOnFilter: {
            ...activeFilter,
          },
          eventSourceId: 'ClientProject',
        },

        completed: {
          label: 'Completed',
          view: (
            <ClientAllProjectTable
              addOnFilter={{
                ...completedFilter,
              }}
              searchValue={searchValue}
              selectedTab={'completed'}
              {...props}
            />
          ),
          api: `/clientProjects`,
          addOnFilter: {
            ...completedFilter,
          },
          eventSourceId: 'ClientProject',
        },
      }}
      {...props}
      actions={props => {
        const {selectedTab} = props;
        return [
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          selectedTab === 'active' && (
            <AddButton
              title={'Project'}
              view={'client-add-project'}
              params={{
                user,
                ...params,
              }}
            />
          ),
        ];
      }}
    />
  );
};
