import React from 'react';
import {useInvoke} from '../../../controllers/useInvoke';
import {Form} from '../../../components/form/Form';
import {Labels} from '../constants/Label';

const featureField = ({navigation, params, type}) => {
  return {
    label: Labels.Features,
    field: 'objective_ids',
    type: 'autoComplete',
    api: `/projectFeatures`,
    suggestionField: 'feature',
    valueField: 'feature',
    required: true,
    filter: {
      project_id: params?.project?._id,
      module_id: params?.module?._id,
      status: {$in: ['backlog', 'active']},
      type: type === 'issue' ? 'issue' : {$ne: 'issue'},
      library_id: params?.projectlibrary_id,
    },
    onCreate: ({searchValue, onChange}) => {
      navigation.navigate(`add-feature`, {
        ...params,
        module: params?.module?._id ? params?.module : params.row?.module_id,
        searchValue,
        afterSubmit: ({data}) => onChange && onChange(data),
        feature_type: type,
      });
    },
  };
};

export const MapFeatureForm = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {row, type} = params;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['UserStory', 'Issue'],
  });

  const onSubmit = props => {
    const {objective_ids} = props;

    const uri =
      type === 'issue'
        ? `/issues/map-objectives`
        : `/userstories/map-objectives`;

    invoke({
      uri,
      props: {
        selectedIds: [row?._id],
        objective: objective_ids,
      },
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      header={{
        title: `Map ${Labels.Feature}`,
        secondaryTitle: type === 'issue' ? row.issue : row.userstory,
      }}
      submitAction="Save"
      mode="edit"
      layoutFields={[featureField({navigation, params, type})]}
    />
  );
};

export const MapMultipleFeatureForm = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {selectedIds, resetSelection, ...restParams} = params;
  const {type} = restParams;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Issue', 'UserStory'],
  });

  const onSubmit = props => {
    const {objective_ids} = props;
    invoke({
      uri: `/${type === 'issue' ? 'issues' : 'userstories'}/map-objectives`,
      props: {
        selectedIds,
        objective: objective_ids,
      },
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      afterSubmit={() => {
        resetSelection && resetSelection();
      }}
      header={{
        title: `Map ${Labels.Feature}`,
      }}
      submitAction="Save"
      layoutFields={[featureField({navigation, params: restParams, type})]}
    />
  );
};
