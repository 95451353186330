import React from 'react';
import {
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/table/Headers';
import {ExpandRowIconRender} from '../../../components/table/Renders';
import {GroupTable, Table} from '../../../components/table/Table';
import {useTheme} from '@react-navigation/native';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';

const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const {colors} = useTheme('colors');
  const color = row?.url ? {color: colors['primary']} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.url &&
          Linking.openURL(
            row?.url.startsWith('https') ? row?.url : `https://${row?.url}`,
            '_blank',
          );
      }}>
      <Text
        title={row?.name}
        style={{
          ...rowText,
          ...color,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};

export const MyStudyMaterials = props => {
  const {filterValues, applyFilter} = useFilter();
  return (
    <GroupTable
      renderHeader={() => (
        <TableHeader
          title="Study Materials"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Skill',
                  api: '/skillMetrics',
                  placeholder: 'Select Skill',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: '_id',
                },
              ]}
            />,
          ]}
        />
      )}
      api="/skillMetrics"
      fields={{
        name: 1,
        color: 1,
        materials: {
          name: 1,
          url: 1,
          material_type: {
            name: 1,
            color: 1,
          },
          skill_topic:{
            name:1,
            color:1
          }
        },
      }}
      filter={filterValues.filter}
      groupRow={{
        data: 'materials',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.name} />
              <GroupContentItem value={(row?.materials?.length || 0) +" Materials"} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          field: 'name',
          header: 'Material',
          render: RenderName,
        },
        {
          field: 'skill_topic.name',
          colorField: 'skill_topic.color',
          header: 'Topic',
          type: 'colorChip',
        },
        {
          field: 'material_type.name',
          colorField: 'material_type.color',
          header: 'Category',
          type: 'colorChip',
        },
      ]}
      {...props}
    />
  );
};
