import moment from 'moment';
import {Col, Image, Row, Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';

import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import {TableHeader} from '../../../components/table/Headers';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {
  AISuggestionRender,
  AISuggestionsButton,
} from '../cellrenders/AISuggestionRenders';
import {AITable} from '../../../components/table/AITable';
import {Labels} from '../constants/Label';
import PlusMultipleTextRender from '../cellrenders/PlusMultipleTextRender';
import {SplitScreen, useSplitScreen} from '../../../components/SplitScreen';
import {UpdateUserStoryForm} from './UserStoryForm';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {PROJECT_USER_STORY_TYPE_ID} from '../../common/constants/SourceConstants';

const UserStoryInlineForm = props => {
  const {
    route: {params},
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: ['UserStory', 'ProjectAI'],
    uri: '/projectUserStories',
    ...props,
  });

  const {project, feature_id, module, projectlibrary_id} = params || {};

  const defaultValues = {
    project_id: project?._id,
    module_id: module?._id,
  };

  if (feature_id) {
    defaultValues.objective_ids = [feature_id];
  }

  if (projectlibrary_id) {
    defaultValues.library_id = projectlibrary_id;
  }

  return (
    <InlineForm
      onSubmit={onSubmit}
      eventSourceId={'UserStory'}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          width: 350,
        },
        {
          label: 'User Story',
          field: 'userstory',
          type: 'textArea',
          required: true,
        },
      ]}
    />
  );
};

const deleteUserstory = ({deleteInvoke, params}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete User Story',
    message: `You will not be able to retreive the user story after deletion. Are you sure you want to delete user story?`,
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectUserStories/${row?._id}`,
      props: {},
    });
  },
  visible: !params?.feature?._id,
});

const mapFeature = ({navigation, params}) => ({
  title: `Map ${Labels.Feature}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`map-${Labels.Feature_lower}`, {
      ...params,
      row,
    });
  },
});

const showFeatures = ({navigation, params}) => ({
  title: `View All ${Labels.Features}`,
  onPress: props => {
    const {row} = props;
    navigation.navigate(`display-${Labels.Features_lower}`, {
      ...params,
      row,
      secondaryTitle: `User Story : ${row?.userstory}`,
      keyField: 'userstory',
    });
  },
});

const removeFromFeature = ({putInvoke, params}) => ({
  title: `Remove from ${Labels.Feature}`,
  variant: 'error',
  confirm: {
    title: 'Remove User Story',
    message: `Are you sure you want to remove this user story?`,
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    putInvoke({
      uri: `/userstories/${row?._id}/removeObjective`,
      props: {
        objective_id: params?.feature?._id,
      },
    });
  },
  visible: params?.feature?._id,
});

const mapMultipleFeatures = ({navigation, params}) => {
  return {
    title: `Map ${Labels.Features}`,
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate(`map-multiple-${Labels.Features_lower}`, {
        ...params,
        selectedIds,
        resetSelection,
      });
    },
  };
};

const deleteMultipleUserStories = ({deleteInvoke, params}) => {
  return {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete User Stories',
      message:
        'You will not be able to retreive the userstories after deletion. Are you sure you want to delete all the selected user stories?',
      confirmText: 'Delete',
    },
    onPress: ({selectedIds, resetSelection}) => {
      deleteInvoke({
        uri: `/userstories/batchRemove`,
        props: {
          selectedIds,
        },
      });
      resetSelection?.();
    },
    visible: !params?.feature?._id,
  };
};

const UserStoryTableHeader = ({
  searchValue,
  onChangeFilter,
  subStatus,
  ...props
}) => {
  const {
    route: {params},
    fromProject,
    selectedTab,
  } = props;

  const {project, module, feature} = params || {};
  return (
    <>
      <TableHeader
        title={`${Labels.Requirements}`}
        actions={[
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            placeholder="Search"
          />,
          selectedTab == 'all' &&
          project?.ai_enabled &&
          module &&
          !feature?._id ? (
            <AISuggestionsButton
              {...props}
              entityName="projectuserstory"
              filter={{
                module_id: module._id,
              }}
              eventSourceId="ProjectAI"
              params={{
                parent_entity: 'Module',
                parent_entity_id: module._id,
                module: module.module,
                moduleDesc: module.desc,
                module_id: module._id,
              }}
              initialValues={{
                module_id: module._id,
                project_id: project._id,
              }}
            />
          ) : (
            void 0
          ),
        ]}
      />
      {fromProject
        ? void 0
        : ((selectedTab === 'backlog' && !subStatus) || feature?._id) && (
            <UserStoryInlineForm {...props} />
          )}
    </>
  );
};

const MapObjective = () => {
  const {colors, fonts, icons} = useTheme();
  return (
    <Row gap={4}>
      <Image source={icons.BluePlus} style={{height: 16, width: 16}} />
      <Text style={{color: colors.INFORMATION_HIGH, ...fonts.BODY2}}>
        Map {Labels.Feature.toLowerCase()}
      </Text>
    </Row>
  );
};

const UserStoryCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_USER_STORY_TYPE_ID, label: 'User Story'},
      comment_source_id: {_id: row._id, name: row.userstory},
    }),
  });
};

export const UserStoriesTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: propsFilterValues,
    onChangeFilter: propsOnChangeFilter,
    searchValue: propsSearchValue,
    api = '/projectUserstories',
    selectedTab,
    subStatus,
  } = props;

  const {onChangeFilter, searchValue, filterValues} = useFilter({});

  const {project, module, feature, projectlibrary_id} = params;
  const {colors, fonts} = useTheme();
  const {toggleSplitScreen} = useSplitScreen();
  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId: ['UserStory', 'ProjectAI'],
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: ['UserStory', 'ProjectAI'],
  });

  let filter = {
    ...filterValues?.filter,
    ...propsFilterValues?.filter,
    sub_status: subStatus,
    project_id: project?._id,
    library_id: projectlibrary_id || null,
  };

  if (feature) {
    filter.objective_ids = feature?._id;
  } else if (module) {
    filter.module_id = module?._id;
  }

  return (
    <AITable
      renderHeader={_props => {
        return (
          <UserStoryTableHeader
            {...props}
            {..._props}
            searchValue={propsSearchValue || searchValue}
            onChangeFilter={propsOnChangeFilter || onChangeFilter}
          />
        );
      }}
      eventSourceId={[
        'UserStory',
        'RemoveMappedFeature',
        'Comment',
        'ProjectAI',
      ]}
      selection={{
        actions: [
          mapMultipleFeatures({navigation, params}),
          deleteMultipleUserStories({deleteInvoke, params}),
        ],
      }}
      secondaryApiProps={
        module && project?.ai_enabled && !feature?._id
          ? {
              api: `/projectuserstories/ai`,
              params: {
                parent_entity: 'Module',
                parent_entity_id: module?._id,
              },
              eventSourceId: 'ProjectAI',
            }
          : void 0
      }
      api={api}
      onRowPress={({row, rowIndex}) => {
        toggleSplitScreen({
          ...params,
          userstory: row,
          row,
          rowIndex,
        });
      }}
      fields={{
        userstory: 1,
        code: 1,
        module_id: {module: 1, color: 1},
        objective_ids: {feature: 1, status: 1, releasedOn: 1},
        index: 1,
        desc: 1,
        title: 1,
        acceptance_criteria: 1,
        exit_criteria: 1,
        comments_count: {
          count: 1,
        },
        last_comment: {
          createdAt: 1,
        },
      }}
      filter={filter}
      sort={{
        index: 1,
      }}
      search={propsSearchValue || searchValue}
      searchFields={['userstory', 'title']}
      draggable={{
        sort: 'asc',
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          if (row?.aiGenerated) {
            putInvoke({
              uri: `/pmt/ai/${row?._id}`,
              props: {
                index: updatedIndex,
              },
            });
          } else {
            putInvoke({
              uri: `/projectuserstories/${row?._id}`,
              props: {
                index: updatedIndex,
              },
            });
          }
        },
      }}
      columns={[
        {
          header: 'User Story',
          render: _props => {
            const {row, styles} = _props;
            let primaryTitleColor = void 0;
            if (feature?._id) {
              const {objective_ids: features} = row;
              if (
                !features?.length ||
                features.findIndex(feature => feature._id === feature._id) ===
                  -1
              ) {
                primaryTitleColor = colors.NEUTRAL_MEDIUM;
              }
            }

            let primaryTitle = row?.title || row?.userstory;
            let secondaryTitle = row?.title ? row?.userstory : void 0;
            const acceptanceCriteria = row?.acceptance_criteria?.map(
              item => item?.criteria || item,
            );

            const exitCriteria = row?.exit_criteria?.map(
              item => item?.criteria || item,
            );

            return (
              <Col gap={8}>
                <AISuggestionRender
                  {..._props}
                  params={params}
                  primaryTitleColor={primaryTitleColor}
                  numberOfLines={3}
                  primaryTitle={primaryTitle}
                  secondaryTitle={secondaryTitle}
                  entityName="ProjectUserStory"
                  eventSourceId="ProjectAI"
                />

                {acceptanceCriteria?.length ? (
                  <View
                    style={{
                      paddingTop: 6,
                      paddingRight: 16,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      backgroundColor: colors?.SURFACE3,
                      borderRadius: 12,
                    }}>
                    {acceptanceCriteria?.length ? (
                      <Text
                        style={{
                          ...fonts.CAPTION_SMALL,
                          color: colors.NEUTRAL_LOW,
                          whiteSpace: 'pre-wrap',
                        }}>
                        {'Acceptance Criteria:\n'}
                        <Text style={{color: colors.NEUTRAL_MEDIUM}}>
                          {acceptanceCriteria.map((item, index) => {
                            return `${index + 1}. ${item}\n`;
                          })}
                        </Text>
                      </Text>
                    ) : (
                      void 0
                    )}
                  </View>
                ) : (
                  void 0
                )}
              </Col>
            );
          },
          colspan: ({row}) => (row.aiGenerated ? 4 : void 0),
        },
        // {
        //   type: 'colorTag',
        //   field: 'module_id.module',
        //   colorField: 'module_id.color',
        //   header: 'Module',
        //   width: 150,
        // },
        {
          header: {
            label: Labels.Feature,
            textAlign: 'right',
          },
          render: ({row}) => {
            const columnField =
              selectedTab === 'all'
                ? 'objective_ids'
                : 'status_matched_features';
            return row?.objective_ids?.length ? (
              <PlusMultipleTextRender
                row={row}
                columnField={columnField}
                valueField="feature"
                maxTagWidth={200}
              />
            ) : (
              <MapObjective />
            );
          },
          align: 'center',
          visible: () => !feature,
          width: 250,
          onPress: ({row}) => {
            row?.objective_ids?.length
              ? navigation.navigate(`display-${Labels.Features_lower}`, {
                  ...params,
                  row,
                  tableData: row?.status_matched_features,
                  secondaryTitle: `User Story : ${row?.userstory}`,
                  keyField: 'userstory',
                })
              : navigation.navigate(`map-${Labels.Feature_lower}`, {
                  ...params,
                  row,
                });
          },
          visible: () => !feature,
        },
        UserStoryCommentColumn({navigation, params}),
      ]}
      moreActions={[
        mapFeature({navigation, params}),
        showFeatures({navigation, params}),
        removeFromFeature({putInvoke, params}),
        deleteUserstory({deleteInvoke, params}),
      ]}
    />
  );
};

export const UserStoryScreen = props => {
  return (
    <SplitScreen splitScreen={<UpdateUserStoryForm {...props} />}>
      <UserStoriesTable {...props} />
    </SplitScreen>
  );
};
