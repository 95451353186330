import React from 'react';
import {
  Image,
  Row,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Wrap,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {ColorThemeCardStyle, ColorThemeStyle} from '../cards/ColorViewTheme';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {Confirm} from '../../../components/confirm/Confirm';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {PROJECT_THEME_TYPE_ID} from '../../common/constants/SourceConstants';

const ColorCard = ({color, colorMode, onPress, readOnly, row, feature}) => {
  const {
    deleteIcon,
    UpdateIcon,
    container,
    darkContainer,
    upperPartContainer,
    darkUpperPartContainer,
    colorNameTextContainer,
    colorNameText,
    darkColorNameText,
    colorNameCode,
    darkColorNameCode,
  } = useStyles(ColorThemeCardStyle);

  const deleteInvoke = useInvoke({method: 'put', eventSourceId: 'colors'});

  return (
    <TouchableOpacity
      style={{
        ...container,
        ...(colorMode === 'dark' ? {...darkContainer} : {}),
      }}
      onPress={onPress}>
      <View
        style={{
          ...upperPartContainer,
          ...(colorMode === 'dark' ? {...darkUpperPartContainer} : {}),
          flex: 1,
          overflow: 'hidden',
        }}>
        <View style={{flexDirection: 'row'}}>
          <View title={color?.color_name} style={colorNameTextContainer}>
            <Text
              style={{
                ...colorNameText,
                ...(colorMode === 'dark' ? {...darkColorNameText} : {}),
              }}>
              {color?.color_name}
            </Text>
          </View>
          <Row gap={10} style={{alignItems: 'center'}}>
            {color?.updated ? (
              <Image
                title={'Updated'}
                style={{height: 20, width: 20}}
                source={UpdateIcon}
              />
            ) : (
              void 0
            )}
            {!readOnly ? (
              <Confirm
                variant={'error'}
                title="Delete"
                message="Are you sure you want to delete color?"
                confirmText="Delete"
                onConfirm={() => {
                  deleteInvoke({
                    uri: `/projectAssetVersions/${row?.versionId}/deleteColor`,
                    props: {
                      color,
                      feature: {_id: feature?._id, feature: feature?.feature},
                    },
                  });
                }}>
                <Image source={deleteIcon} />
              </Confirm>
            ) : (
              void 0
            )}
          </Row>
        </View>
        <Text
          style={{
            ...colorNameCode,
            ...(colorMode === 'dark' ? {...darkColorNameCode} : {}),
          }}>
          {color?.[`${colorMode}_mode_color_code`]?.toUpperCase()}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: color?.[`${colorMode}_mode_color_code`],
        }}></View>
    </TouchableOpacity>
  );
};

export const getLightAndDarkColors = ({colors}) => {
  let lightColors = [];
  let darkColors = [];

  const colorCategories = ['Brand', 'Neutral', 'Semantic', 'Custom'];

  colors?.forEach(color => {
    const {category} = color;
    const categoryIndex = colorCategories.indexOf(category);
    lightColors[categoryIndex] = lightColors[categoryIndex] || {
      category,
      colors: [],
    };
    darkColors[categoryIndex] = darkColors[categoryIndex] || {
      category,
      colors: [],
    };
    lightColors[categoryIndex].colors.push(color);
    darkColors[categoryIndex].colors.push(color);
  });
  return {
    lightColors,
    darkColors,
  };
};

export const ColorsView = ({
  colors,
  colorMode,
  header,
  readOnly,
  row,
  feature,
}) => {
  const {
    mainContainer,
    darkmainContainer,
    text,
    darkModeText,
    separator,
    darkModeSeparator,
    container,
  } = useStyles(ColorThemeStyle);

  const navigation = useNavigation();

  return (
    <View
      style={{
        ...mainContainer,
        ...(colorMode === 'dark' ? {...darkmainContainer} : {}),
        flex: 1,
        overflow: 'hidden',
      }}>
      <Text
        style={{
          ...text,
          ...(colorMode === 'dark' ? {...darkModeText} : {}),
        }}>
        {header}
      </Text>
      <View
        style={{
          ...separator,
          ...(colorMode === 'dark' ? {...darkModeSeparator} : {}),
        }}></View>
      {colors.map(categoryWiseColors => {
        return (
          <View style={container}>
            <Text
              style={{
                ...text,
                ...(colorMode === 'dark' ? {...darkModeText} : {}),
              }}>{`${categoryWiseColors.category} Color`}</Text>
            <Wrap gap={16}>
              {categoryWiseColors.colors.map(color => {
                return (
                  <ColorCard
                    readOnly={readOnly}
                    color={color}
                    colorMode={colorMode}
                    row={row}
                    feature={feature}
                    onPress={() => {
                      !readOnly &&
                        navigation.navigate('edit-color', {
                          color,
                          row,
                          feature,
                        });
                    }}
                  />
                );
              })}
            </Wrap>
          </View>
        );
      })}
    </View>
  );
};

export const FeatureColorTheme = ({route: {params}}) => {
  const {readOnly, feature} = params;
  const {data} = useFetchData({
    api: `/features/${feature?._id}/assets/${PROJECT_THEME_TYPE_ID}/asset-data`,
    eventSourceId: ['colors', 'theme'],
  });

  let row = data?.[0];

  const {lightColors, darkColors} = getLightAndDarkColors({
    colors: row?.colors,
  });

  return (
    <>
      <TableHeader
        title={'Color Theme'}
        actions={[
          <AddButton
            title="Add Color"
            view={'add-color'}
            params={{...params, row}}
          />,
        ]}
      />
      <ScrollView>
        <Row gap={20}>
          <ColorsView
            readOnly={readOnly}
            colors={lightColors}
            row={row}
            colorMode="light"
            header="Light Mode Theme"
            feature={feature}
          />
          <ColorsView
            readOnly={readOnly}
            colors={darkColors}
            row={row}
            colorMode="dark"
            header="Dark Mode Theme"
            feature={feature}
          />
        </Row>
      </ScrollView>
    </>
  );
};
