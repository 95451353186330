import React from 'react';
import { TabView } from '../../../components/tab/TabView';

import {
    GroupFilter,
    PeriodRangeMonthWiseBreakupFilter
} from '../../../components/filter/Filters';
import { useFilter } from '../../../controllers/useFilter';

import { Row } from '@unthinkable/react-core-components';
import { getDateRange } from '@unthinkable/react-date-picker/src/DateUtility';
import {
    getZeroTimeDate
} from '@unthinkable/react-filters';
import { AddButton } from '../../../components/button/AddButton';
import { Button } from '../../../components/button/Button';
import { useAppStateContext } from '../../../providers/AppState';
import { ObjectWiseTasks } from '../../common/actions/ObjectWiseTasks';
import {
    CustomPlatformActiveMergedClosed,
    CustomPlatformActiveMergedClosedsolCon,
    CustomPlatformActiveMergedList,
    CustomPlatformActiveMergedListSolCon,
    CustomPlatformActiveMergedNew,
    CustomPlatformUnassignedMergedList,
    CustomPlatformUnassignedMergedListSolCon,
} from './customPlatfromMergedList';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const CustomPlatformMergedTabs = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let tabs = {};
  const {download} = useAppStateContext();
  tabs.new = {
    label: 'New',
    // api: '/opportunities/custom-platform/closed',const
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <CustomPlatformActiveMergedNew
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            // asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Buisness Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Buisness Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />
        {/* <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        /> */}
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/custom-platform/new/export`,
              props: {
                ...params,
                ...filterParams,
                ...filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  tabs.unassigned = {
    label: 'Unassigned',
    api: '/opportunities/custom-platform/unassigned',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <CustomPlatformUnassignedMergedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Business Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />

        <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        />
      </Row>,
    ],
  };
  tabs.active = {
    label: 'In Progress',
    api: '/opportunities/custom-platform/active',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <CustomPlatformActiveMergedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Sub Stage',
            options: [
              'Requirement gathering',
              'Scope Freezing and Estimation',
              'Proposal Drafting',
              'Sales Review',
              'Proposal Walkthrough',
              'Re-Estimation',
              'Negotiation',
              'Walkthrough done',
              'Revise Proposal',
            ],
            placeholder: 'Select',
            field: 'sub_stage',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Buisness Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />
        <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/custom-platform/active/export`,
              props: {
                ...params,
                ...filterParams,
                ...filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    // api: '/opportunities/custom-platform/closed',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <CustomPlatformActiveMergedClosed
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            // asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Buisness Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Buisness Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />
        {/* <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        /> */}
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />

        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/custom-platform/closed/export`,
              props: {
                ...params,
                ...filterParams,
                ...filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const CustomPlatformMergedTabsForSolCon = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    // params: {
    //   period: getCurrentMonth(),
    // },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let tabs = {};
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();

  tabs.unassigned = {
    label: 'Unassigned',
    // api: '/opportunities/custom-platform/unassigned',
    params: params,
    addOnFilter: filter,
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <CustomPlatformUnassignedMergedListSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },

          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Business Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />

        <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        />
      </Row>,
    ],
  };
  tabs.active = {
    label: 'In Progress',
    // api: '/opportunities/custom-platform/active',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    view: (
      <CustomPlatformActiveMergedListSolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Sub Stage',
            options: [
              'Requirement gathering',
              'Scope Freezing and Estimation',
              'Proposal Drafting',
              'Sales Review',
              'Proposal Walkthrough',
              'Re-Estimation',
              'Negotiation',
              'Walkthrough done',
              'Revise Proposal',
            ],
            placeholder: 'Select',
            field: 'sub_stage',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },

          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Buisness Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />
        <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/custom-platform/active/export`,
              props: {
                ...params,
                ...filterParams,
                ...filter,
              },
            });
          }}
        />
        ,
      </Row>,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    // api: '/opportunities/custom-platform/closed',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    filter: {
      $or: [{primary_owner: employeeId}, {tech_person: employeeId}],
    },
    view: (
      <CustomPlatformActiveMergedClosedsolCon
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            // asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },

          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Buisness Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Buisness Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />
        <AddButton
          title="Opportunity"
          view="add-customplatformopportunity"
          params={props?.route?.params}
        />
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />

        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/custom-platform/closed/export`,
              props: {
                ...params,
                ...filterParams,
                ...filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
