import {ModalViewStyles} from '../modal/theme';

export const navHeaderStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    brandIcon: icons.LOCOMOMenuBarIcon,
    chatIcon: icons.Chat,
    copyIcon: icons.CopyBlack,
    reportIcon: icons.ReportIcon,
    unthinkableFlagIcon: icons.UnthinkableFlag,
    badgeBackgroundColor: colors.INFORMATION_HIGH,
    badgeTextColor: colors.BACKGROUND,
    iconStyle: {height: 24, width: 24, marginRight: 20},
    actionSeparator: {
      backgroundColor: colors.OUTLINE,
      width: 1,
    },
    chatIconContainer: {
      height: 28,
      width: 28,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.SURFACE2,
      borderRadius: 4,
      marginRight: 10,
    },
    container: {
      paddingTop: 6,
      paddingRight: 16,
      paddingBottom: 6,
      paddingLeft: 16,
      backgroundColor: 'transparent',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
      minHeight: 41,
    },
    separatorStyle: {
      height: 32,
      width: 1,
      borderRadius: 100,
      backgroundColor: colors.OUTLINE,
    },
    back: icons.Back,
    backIconContainerStyle: {
      height: 28,
      width: 28,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      backgroundColor: colors.SURFACE2,
    },
    // backIconStyle: {
    //   height: 28,
    //   width: 28,
    // },
    title: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },

    bell: icons.Bell,
    bellIconStyle: {
      height: 24,
      width: 24,
    },
  };
};

export const breadcrumbStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    threeDot: icons.ThreeDotHorizontal,
    title: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    itemHoverColor: colors.OUTLINE,
    separator: {
      ...fonts.BODY2,
      color: colors.UPPER_OUTLINE,
    },
    modal: {
      container: {
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        width: 200,
        maxHeight: 300,
        backgroundColor: colors.BACKGROUND,
      },
      itemContainer: {
        borderColor: colors.OUTLINE,
      },
      actionText: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
    },
  };
};

export const appModuleDropdownStyles = ({theme}) => {
  const {fonts, colors, shadows, icons} = theme;
  return {
    DownArrow: icons.BreadCrumbDropdownFilled,
    containerHoverColor: colors.SURFACE2,
    itemHoverColor: colors.OUTLINE,
    modalStyles: {
      ...ModalViewStyles({theme}),
      container: {
        alignItems: 'center',
        marginLeft: 30,
        borderRadius: 12,
        borderWidth: 1,
        padding: 12,
        backgroundColor: colors.BACKGROUND,
        borderColor: colors.OUTLINE,
        maxHeight: 630,
        flexWrap: 'wrap',
        gap: 4,
        ...shadows.M,
      },
    },
    card: {
      width: 286,
      height: 80,
      justifyContent: 'center',
      borderRadius: 8,
    },
    selectedCard: {
      borderWidth: 1,
      borderColor: colors.BRAND_MEDIUM,
      backgroundColor: colors.BACKGROUND,
      ...shadows.S,
    },
    cardHover: {
      backgroundColor: colors.BACKGROUND,
      ...shadows.S,
    },
    iconContainer: {
      height: 32,
      width: 32,
      borderRadius: 6,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.BACKGROUND,
    },
    selectedIconContainer: {
      height: 32,
      width: 32,
      borderRadius: 6,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.BACKGROUND,
    },
    icon: {height: 20, width: 20},
    title: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    name: {
      ...fonts.SIDE_NAVIGATION,
      color: colors.NEUTRAL_HIGH,
    },
    desc: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
