import {Text, View} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import { useAppStateContext } from '../../../providers/AppState';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Resource',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'employee',
      searchFields: ['name', 'official_email_id', 'employee_code'],
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project',
    },
  ];
};

const shiftAllowanceFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
}) => {
  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

const RESOURCE = {
  header: 'Resource',
  render: ({row}) => {
    const {employee} = row;
    return (
      <UserAvatarWithNameAndEmail
        name={employee?.name}
        color={employee?.color}
        official_email_id={employee?.official_email_id}
      />
    );
  },
};

const FROMDATE = {
  header: 'From Date',
  field: 'from_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const TODATE = {
  header: 'To Date',
  field: 'to_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const PROCESSEDDATE = ({tab}) => {
  return {
    header: 'Processed On',
    field: 'processed_date',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
    visible: () => tab == 'processed',
  };
};

const PAYABLEMA = {
  header: 'Payable MA',
  field: 'amount',
  align: 'right',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return <Text style={{...rowText, color: '#34C759'}}>₹{row?.amount}</Text>;
  },
};

const ShiftAllowanceHr = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    tab,
  } = props;
  const {download} = useAppStateContext();
  return (
    <Table
      api={`/dailyincentives/rm`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      selection={
        tab === 'accepted' && {
          actions: [
            {
              title: 'Process',
              onPress: ({selectedIds}) => {
                navigation.navigate('multi-processed-ma', {
                  selectedIds,
                });
              },
            },
            {
              title: 'Download',
              onPress: props =>
                download({
                  uri: '/exportExcelHelper',
                  props: {
                    props: {
                      fetchProps: {
                        skipFields: true,
                        api: '/dailyincentives/rm',
                        addOnFilter: {
                          ...addOnFilter,
                          status: 'accept',
                        },
                        params: {
                          period,
                        },
                      },
                      column: {
                        Employee: 'employee.name',
                        Email: 'employee.official_email_id',
                        'From Date': 'from_date',
                        'To Date': 'to_date',
                        'Actual MA': 'actual_ma',
                        'Payable MA': 'amount',
                        'Accept Date': 'accepted_date',
                      },
                    },
                  },
                }),
            },
          ],
        }
      }
      params={{...params, period}}
      limit={1000}
      eventSourceId={['shiftallowance']}
      columns={[
        RESOURCE,
        FROMDATE,
        TODATE,
        PAYABLEMA,
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <View
                style={{
                  padding: 4,
                  alignItem: 'center',
                  textAlign: 'center',
                }}
                onPress={() => {
                  navigation.navigate('processed-ma', {
                    row,
                  });
                }}>
                <Text style={{...rowText, color: '#007AFF'}}>Process </Text>
              </View>
            );
          },
          visible: () => tab == 'accepted',
        },
        PROCESSEDDATE({tab}),
      ]}
      moreActions={[
        {
          title: 'Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                employee: row?.employee?._id,
              },
            });
          },
        },
        {
          title: 'Attendance',
          onPress: ({row}) => {
            navigation.navigate(`daily-attendance-shift-allowance-tab`, {
              extraAddOnFilter: {
                employee: row.employee,
                attendance_date: {
                  $gte: row.from_date,
                  $lte: row.to_date,
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const ShiftAllowanceHrTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};

  const tabs = {
    accepted: {
      label: 'Accepted',
      view: (
        <ShiftAllowanceHr
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'accept',
          }}
          period={period}
          tab="accepted"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'accepted',
        params,
        period,
      }),
    },

    processed: {
      label: 'Processed',
      view: (
        <ShiftAllowanceHr
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'processed',
          }}
          period={period}
          tab="processed"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'processed',
        params,
        period,
      }),
    },
  };
  return (
    <>
      <TableHeader title={'Shift Allowance'} />
      <TabView tabs={tabs} params={params} {...props} />
    </>
  );
};
