import {Platform} from '@unthinkable/react-core-components';
import icons from '../images';

const breakpoints = {
  sm: 480,
  md: 768,
};

const lightColors = {
  MODAL_BACKGROUND: 'rgba(0,0,0,0.2)',

  BACKGROUND: '#FFFFFF',
  SURFACE1: '#FCFDFD',
  SURFACE2: '#F3F5F6',
  SURFACE3: '#F9FAFB',
  OUTLINE: '#E8EBED',
  UPPER_OUTLINE: '#D4D7D9',

  NEUTRAL_HIGH: '#2B3443',
  NEUTRAL_MEDIUM: '#697186',
  NEUTRAL_LOW: '#939BA9',

  BRAND_HIGH: '#72B031',
  BRAND_UPPER_MEDIUM: '#7DCD28',
  BRAND_MEDIUM: '#B2EE7C',
  BRAND_UPPER_LOW: '#EFFDD8',
  BRAND_LOW: '#F9FEF0',

  SECONDARY_HIGH: '#3562FF',
  SECONDARY_UPPER_MEDIUM: '#4770FF',
  SECONDARY_MEDIUM: '#E3E8FC',
  SECONDARY_LOW: '#F1F4FE',

  ERROR_HIGH: '#D8563F',
  ERROR_MEDIUM: '#FDDCD8',
  ERROR_LOW: ' #FFECEB',

  SUCCESS_HIGH: '#5EA329',
  SUCCESS_MEDIUM: '#E6F7D4',
  SUCCESS_LOW: '#F0F8E7',

  WARNING_HIGH: '#E68A1E',
  WARNING_MEDIUM: '#FFEACD',
  WARNING_LOW: '#FFF7EB',

  INFORMATION_HIGH: '#007AFF',
  INFORMATION_MEDIUM: '#DCECFE',
  INFORMATION_LOW: '#EBF4FF',

  ACCENT1_HIGH: '#64BCE5',
  ACCENT1_MEDIUM: '#E0F5FF',
  ACCENT1_LOW: '#E5F7FF',

  ACCENT2_HIGH: '#EC6BA1',
  ACCENT2_MEDIUM: '#FFE0EF',
  ACCENT2_LOW: '#FFEBF5',

  ACCENT3_HIGH: '#74C7B8',
  ACCENT3_MEDIUM: '#DFFBF6',
  ACCENT3_LOW: '#E9FCF8',

  ACCENT4_HIGH: '#F29B4B',
  ACCENT4_MEDIUM: '#FFEFE0',
  ACCENT4_LOW: '#FFF4EB',

  ACCENT5_HIGH: '#F5D03A',
  ACCENT5_MEDIUM: '#FFF9E0',
  ACCENT5_LOW: '#FFFBEB',

  ACCENT6_HIGH: '#AA99EA',
  ACCENT6_MEDIUM: '#EBE2FD',
  ACCENT6_LOW: '#F2ECFD',
};

const darkColors = {
  MODAL_BACKGROUND: 'rgba(17,17,14,0.7)',

  BACKGROUND: '#222226',
  SURFACE1: '#242528',
  SURFACE2: '#26272B',
  SURFACE3: '#292A2E',
  OUTLINE: '#333438',
  UPPER_OUTLINE: '#373A3E',

  NEUTRAL_HIGH: '#FAFAFA',
  NEUTRAL_MEDIUM: '#A0A4AC',
  NEUTRAL_LOW: '#8D929B',

  BRAND_HIGH: '#C2EE7C',
  BRAND_UPPER_MEDIUM: '#A7E155',
  BRAND_MEDIUM: '#93CA2B',
  BRAND_UPPER_LOW: '#212A18',
  BRAND_LOW: '#29381A',

  SECONDARY_HIGH: '#3360FF',
  SECONDARY_UPPER_MEDIUM: '#3149A0',
  SECONDARY_MEDIUM: '#232D52',
  SECONDARY_LOW: '#1F263E',

  ERROR_HIGH: '#E96F68',
  ERROR_MEDIUM: '#4A1E1C',
  ERROR_LOW: '#2F1E1F',

  SUCCESS_HIGH: '#9BCF63',
  SUCCESS_MEDIUM: '#2F4517',
  SUCCESS_LOW: '#22271C',

  WARNING_HIGH: '#E6A038',
  WARNING_MEDIUM: '#4D3819',
  WARNING_LOW: '#2B251C',

  INFORMATION_HIGH: '#5299E0',
  INFORMATION_MEDIUM: '#1A324C',
  INFORMATION_LOW: '#1C2630',

  ACCENT1_HIGH: '#76BBDC',
  ACCENT1_MEDIUM: '#1A3E4C',
  ACCENT1_LOW: '#1C2B30',

  ACCENT2_HIGH: '#E04F97',
  ACCENT2_MEDIUM: '#471F33',
  ACCENT2_LOW: '#322028',

  ACCENT3_HIGH: '#39DFC1',
  ACCENT3_MEDIUM: '#1B3C36',
  ACCENT3_LOW: '#1F2E2A',

  ACCENT4_HIGH: '#F28C2F',
  ACCENT4_MEDIUM: '#432B14',
  ACCENT4_LOW: '#2B231C',

  ACCENT5_HIGH: '#FFEB38',
  ACCENT5_MEDIUM: '#3E3214',
  ACCENT5_LOW: '#2A271D',

  ACCENT6_HIGH: '#845DD1',
  ACCENT6_MEDIUM: '#312753',
  ACCENT6_LOW: '#2A223A',
};

export const fonts = {
  TITLE: {
    fontSize: 17,
    fontFamily: 'Inter-Bold',
    lineHeight: '20px',
  },
  HEADING1: {
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  HEADING2: {
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  SECTION_HEADING1: {
    fontSize: 15,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
  },
  SECTION_HEADING2: {
    fontSize: 15,
    fontFamily: 'Inter-Medium',
  },
  BODY1: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    lineHeight: '18px',
  },
  BODY2: {
    fontSize: 14,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  BODY3: {
    fontSize: 14,
    fontFamily: 'Inter-Regular',
    lineHeight: '20px',
  },
  ACTION: {
    fontSize: 14,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  SIDE_NAVIGATION: {
    fontSize: 13,
    fontFamily: 'Inter-Medium',
    lineHeight: '18px',
  },
  CAPTION: {
    fontSize: 13,
    fontFamily: 'Inter-Italic',
    lineHeight: '16px',
  },
  CAPTION_LARGE: {
    fontSize: 12,
    fontFamily: 'Inter-Medium',
    lineHeight: '16px',
  },
  CAPTION_SMALL: {
    fontSize: 12,
    fontFamily: 'Inter-Regular',
    lineHeight: '16px',
  },
  FOOTNOTE: {
    fontSize: 10,
    fontFamily: 'Inter-Medium',
    lineHeight: '10px',
  },
  FOOTNOTE_SMALL: {
    fontSize: 10,
    fontFamily: 'Inter-Medium',
    lineHeight: '12px',
  },
};

const lightWebShadow = {
  M: {boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.07)'},
  XL: {boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.16)'},
  S: {boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)'},
  L: {
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
  },
  Text: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  XS: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
  },
};

const lightNativeShadow = {
  M: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  XL: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.08,
    shadowRadius: 40,
  },
  S: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.06,
    shadowRadius: 16,
  },
  L: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.25,
    shadowRadius: 10,
  },
  Text: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
};

const darkWebShadow = {
  M: {boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.16)'},
  XL: {boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.4)'},
  S: {boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)'},
  L: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)',
  },
  Text: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
  },
  XS: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
  },
};

const darkNativeShadow = {
  M: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.04,
    shadowRadius: 16,
  },
  XL: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.04,
    shadowRadius: 40,
  },
  S: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.02,
    shadowRadius: 16,
  },
  L: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  Text: {
    shadowColor: '#FFF',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.05,
    shadowRadius: 4,
  },
};

const lightShadows = Platform.OS === 'web' ? lightWebShadow : lightNativeShadow;
const darkShadows = Platform.OS === 'web' ? darkWebShadow : darkNativeShadow;

const radius = {
  '2xs': 1,
  xs: 2,
  sm: 3,
  md: 4,
  lg: 6,
  xl: 8,
  '2xl': 12,
  '3xl': 16,
  '4xl': 20,
  '5xl': 24,
};

const spacing = {
  '2xs': 2,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
  xl: 12,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  '5xl': 30,
  '6xl': 32,
  '7xl': 36,
};

export default {
  breakpoints,
  lightColors,
  darkColors,
  icons,
  fonts,
  radius,
  spacing,
  lightShadows,
  darkShadows,
};
