import { getDateRange } from '@unthinkable/react-date-picker/src/DateUtility';
import { getZeroTimeDate } from '@unthinkable/react-filters';
import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import {
    GroupFilter,
    PeriodRangeMonthWiseBreakupFilter,
    SearchFilter,
} from '../../../components/filter/Filters';
import { LeftMenuView } from '../../../components/menu/LeftMenuView';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import {
    AllVendorList,
    ExternalBlacklistVendorList,
    ExternalInactiveVendorList,
    ExternalVendorList,
    InactiveVendorList,
    NewVendorList
} from './VendorTableView';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const VendorTabView = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterValues,
    product,
    organization,
  };
  const tabs = {
    new: {
      label: 'New',
      view: (
        <NewVendorList
          filter={{
            $or: [{status: {$in: ['New', null]}}, {status: {$exists: false}}],
          }}
          params={params}
          searchValue={searchValue}
          tabName="new"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/employee',
      filter: {
        $or: [{status: {$in: ['New', null]}}, {status: {$exists: false}}],
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    verified: {
      label: 'Active',
      view: (
        <NewVendorList
          filter={{
            status: 'Active',
          }}
          params={params}
          searchValue={searchValue}
          tabName="verified"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/employee',
      filter: {
        status: 'Active',
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    inactive: {
      label: 'Inactive',
      addOnFilter: filter,
      view: (
        <InactiveVendorList
          {...props}
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/employee',
      filter: {
        status: 'In Active',
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,

        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    all: {
      label: 'All',
      view: (
        <NewVendorList
          params={params}
          searchValue={searchValue}
          tabName="all"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/employee',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Active', 'Inactive', 'Blacklist'],
              field: 'status',
              label: 'Status',
            },
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} />;
};

export const VendorExternalTabView = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  const tabs = {
    new: {
      label: 'New',
      view: (
        <ExternalVendorList
          filter={{
            $or: [{status: {$in: ['New', null]}}, {status: {$exists: false}}],
            ...filter,
          }}
          params={params}
          searchValue={searchValue}
          tabName="new"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/external',
      filter: {
        $or: [{status: {$in: ['New', null]}}, {status: {$exists: false}}],
        ...filter,
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'autoComplete',
              options: ['Recurring', 'Adhoc'],
              field: 'service_type',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    verified: {
      label: 'Active',
      view: (
        <ExternalVendorList
          filter={{
            status: 'Active',
            ...filter,
          }}
          params={params}
          searchValue={searchValue}
          tabName="verified"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/external',
      filter: {
        status: 'Active',
        ...filter,
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'autoComplete',
              options: ['Recurring', 'Adhoc'],
              field: 'service_type',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    inactive: {
      label: 'Inactive',
      view: (
        <ExternalInactiveVendorList
          {...props}
          search={searchValue}
          addOnFilter={filter}
          filterParams={filterParams}
          params={params}
        />
      ),
      eventSourceId: 'VendorView',
      // api: '/vendor/vendor-data/external',
      // filter: {
      //   status: 'In Active',
      // },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'autoComplete',
              options: ['Recurring', 'Adhoc'],
              field: 'service_type',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    blacklist: {
      label: 'Blacklist',
      view: (
        <ExternalBlacklistVendorList
          filter={{
            status: 'Blacklist',
          }}
          params={params}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/external',
      filter: {
        status: 'Blacklist',
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'autoComplete',
              options: ['Recurring', 'Adhoc'],
              field: 'service_type',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
    all: {
      label: 'All',
      view: (
        <ExternalVendorList
          params={params}
          searchValue={searchValue}
          tabName="new"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data/external',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              options: ['Active', 'Inactive', 'Blacklist'],
              field: 'status',
              label: 'Status',
            },
            {
              type: 'autoComplete',
              options: ['Recurring', 'Adhoc'],
              field: 'service_type',
              label: 'Category',
            },
            {
              type: 'autoComplete',
              options: ['Domestic', 'International', 'Online'],
              field: 'vendor_category',
              label: 'Category',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Created On',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} />;
};

export const AllVendorsTabView = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  let {filter} = filterValues;
  let {organization, product, period, ...rest} = params;
  params = {
    ...filterValues,
    product,
    organization,
  };
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllVendorList
          params={params}
          searchValue={searchValue}
          tabName="new"
          {...props}
        />
      ),
      eventSourceId: 'VendorView',
      api: '/vendor/vendor-data',
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_id',
            },
            {
              type: 'autoComplete',
              options: ['Active', 'Inactive'],
              field: 'status',
              label: 'Status',
            },
          ]}
        />,
        <AddButton title="Vendor Registration" view={'add-vendor-form'} />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} />;
};

const VendorLeftMenu = [
  {
    label: 'Employee',
    view: <VendorTabView />,
  },

  {
    label: 'External',
    view: <VendorExternalTabView />,
  },
  {
    label: 'All',
    view: <AllVendorsTabView />,
  },
];

export const VendorMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={VendorLeftMenu} />;
};
