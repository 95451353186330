import {AvatarStyles} from '../../avatar/theme';

export const InputStyles = ({theme: {colors, fonts}}) => {
  return {
    placeholderTextColor: '#7D7D82',
    container: {
      backgroundColor: colors.SURFACE1,
      borderColor: colors.OUTLINE,
    },
    input: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    focus: {
      borderColor: colors.UPPER_OUTLINE,
    },
    error: {
      borderColor: colors.ERROR_MEDIUM,
    },
    errorText: {
      color: colors.ERROR_HIGH,
    },
    helperText: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.CAPTION,
    },
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    label: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
      paddingBottom: 2,
    },
  };
};

export const AITextAreaInputStyles = ({theme}) => {
  const {colors, fonts, icons} = theme;
  return {
    ...InputStyles({theme}),
    aiStyles: {
      RegenerateIcon: icons.ClockWise,
      ImproviseIcon: icons.Sparkle,
      GitCommit: icons.GitCommit,
      actionContainer: {
        borderTopColor: colors.OUTLINE,
      },
      improviseButton: {},
      improviseIconStyle: {},
      improviseText: {
        ...fonts.ACTION,
        color: colors.BRAND_UPPER_MEDIUM,
      },
      originalButton: {},
      originalText: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_MEDIUM,
      },
      normalAIText: {
        ...fonts.BODY2,
        color: colors.BRAND_UPPER_MEDIUM,
      },
      regenerateButton: {},
      regenerateIconStyle: {},
      regenerateText: {
        ...fonts.ACTION,
        color: colors.BRAND_UPPER_MEDIUM,
      },
      commitText: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_LOW,
      },
      separator: {
        backgroundColor: colors.OUTLINE,
      },
    },
  };
};

export const RichTextAreaInputStyles = ({theme: {colors, fonts}}) => {
  return {
    wrapper: {
      borderColor: colors.OUTLINE,
      borderRadius: 6,
      backgroundColor: colors.BACKGROUND,
      minHeight: 150,
    },
    editorStyle: {},
  };
};

export const MultiTextInputStyles = ({theme: {fonts, colors, icon}}) => {
  return {
    containerStyle: {
      marginBottom: 14,
      flexDirection: 'row',
      alignItems: 'center',
    },
    imageContainerStyle: {marginLeft: 13, width: 50, cursor: 'pointer'},
    imageStyle: {height: 36, width: 36},
  };
};

export const LinkInputStyles = ({theme}) => {
  const {colors, fonts} = theme;
  const inputStyles = InputStyles({theme});
  return {
    ...inputStyles,
    text: {
      color: colors.INFORMATION_HIGH,
      textDecoration: 'underline',
      ...fonts.BODY2,
    },
  };
};

export const MentionSuggestionItemStyles = ({theme}) => {
  const {colors, fonts} = theme;
  return {
    container: {
      padding: 8,
      borderBottomWidth: 1,
      borderBottomColor: colors.OUTLINE,
      backgroundColor: colors.SURFACE1,
      gap: 8,
      alignItems: 'center',
    },
    titleContainer: {
      gap: 2,
    },
    title: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitle: {
      ...fonts.CAPTION,
      color: colors.NEUTRAL_MEDIUM,
    },
    avatar: AvatarStyles({theme}),
  };
};
