import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {NavHeader} from '../../../components/nav/NavHeader';
import {TableHeader} from '../../../components/table/Headers';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
import {leadControllers} from '../controllers/leadControllers';
import {STATUS} from './TargetAudienceTable';

export const OtherCampaignHeader = props => {
  const {
    route: {params},
    navigation,
  } = props;
  return (
    <NavHeader
      title="Inreach Campaign"
      {...props}
      actions={[
        <AddButton
          title="Inreach Campaign"
          view="add-othercampaign"
          params={props?.route?.params}
        />,
      ]}
    />
  );
};

const OtherCampaignTableHeader = props => {
  const {
    route: {params},
  } = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter, navigation} =
    props;
  const {filter, params: filterParams} = filterValues;
  return (
    <TableHeader
      actions={[
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'assigned_to',
            },
          ]}
        />,
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64db3dc4334a9d2f6766ded2',
          }}
        />,
        <AddButton
          title="Inreach Campaign"
          view="add-othercampaign"
          params={props?.route?.params}
        />,
        <MoreActions
          actions={[
            {
              title: 'Import Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  source: 'inreach prospects',
                  model: 'PotentialCustomer',
                });
              },
            },
            {
              title: 'Import Apollo Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'apollo prospects',
                  model: 'PotentialCustomer',
                });
              },
            },
          ]}
        />,
      ]}
    />
  );
};

export const OtherCampaignTable = props => {
  let {
    route: {params},
    navigation,
    tabFilter,
    searchValue,
    filterParams,
    filter,
  } = props;
  const {product} = params;
  params = {...params, ...filterParams};

  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64db3dc4334a9d2f6766ded2',
      label: row?.name,
    });
  };
  const {
    qualifiedLeads,
    uqls,
    preuqls,
    prequalifiedLeads,
    prospectsCountForCampaign,
  } = leadControllers({
    ...props,
    params,
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'OtherCampaigns',
  });

  return (
    <Table
      // renderHeader={() => {
      //   return <OtherCampaignTableHeader {...props} {...filterInfo} />;
      // }}
      addOnFilter={{...filter, ...tabFilter}}
      search={searchValue}
      searchFields={'name'}
      api={`/product/${product?._id}/other-campaign`}
      sort={{ql_count: -1}}
      params={params}
      eventSourceId={['OtherCampaigns']}
      onRowPress={({row}) => {
        navigation.navigate(`inmail-message-list`, {
          ...params,
          row,
        });
      }}
      limit={5000}
      columns={[
        {
          field: 'name',
          header: 'Name',
        },
        {
          field: 'assigned_to',
          header: 'Owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          width: 100,
          type: 'number',
          field: 'prospect_count',
          header: 'Prospect',
          aggregate: true,
          onPress: prospectsCountForCampaign,
        },
        {
          width: 140,
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: {render: AggregateComparisonRender},
          onPress: uqls,
          onComparePress: preuqls,
          render: ComparisonRender,
        },
        {
          width: 140,
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'Qualified Lead',
          aggregate: {render: AggregateComparisonRender},
          onPress: qualifiedLeads,
          onComparePress: prequalifiedLeads,
          render: ComparisonRender,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-othercampaign', {...params, row});
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/othercampaigns/${row?._id}`,
              eventSourceId: ['OtherCampaigns'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/othercampaigns/${row?._id}`,
              eventSourceId: ['OtherCampaigns'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/othercampaigns/${row?._id}`,
              eventSourceId: ['OtherCampaigns'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/othercampaigns/${row?._id}`,
              eventSourceId: ['OtherCampaigns'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Show Tasks',
          onPress: showTasks,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/othercampaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};
