import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useAccentColor} from '../../../controllers/useAccentColor';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';
import {Tag} from '../../../components/chip/Chip';

const DEPLOYMENT_STATUS_COLORS = {
  Pending: {
    color: 'ACCENT1',
  },
  Success: {
    color: 'SUCCESS',
  },
  'In Progress': {
    color: 'ACCENT6',
  },
  Failure: {
    color: 'ERROR',
  },
};

export const DeploymentStatusCard = ({row}) => {
  if (!row) {
    return;
  }

  const [deploymentData, deploymentUpdateData] = useState(row);

  const deploymentStatus = deploymentData?.status || 'Pending';

  const {HIGH, LOW} = useAccentColor(
    DEPLOYMENT_STATUS_COLORS[deploymentStatus]?.color,
  );

  useRealTimeSync({
    event: 'JenkinsDeploymentStatus',
    group: 'JenkinsDeployment',
    callBack: ({repo_env_id, build_number, live_url, build_status}) => {
      if (
        deploymentData?.repository_environment_id?.toString() ===
          repo_env_id.toString() &&
        deploymentData.build_number.toString() === build_number.toString()
      ) {
        deploymentUpdateData(doc => ({
          ...doc,
          status: build_status,
        }));
      }
    },
  });
  return <Tag value={deploymentStatus} color={LOW} textColor={HIGH} />;
};

const DeploymentHistoryTable = props => {
  const {
    route: {params},
  } = props;

  const {environment} = params;

  return (
    <Table
      api={`/deploymentHistories`}
      filter={{repository_environment_id: environment._id}}
      fields={{
        build_number: 1,
        status: 1,
        deployed_by: {name: 1, color: 1},
        repository_environment_id: 1,
        createdAt: 1,
      }}
      sort={{createdAt: -1}}
      columns={[
        {
          type: 'userAvatar',
          field: 'deployed_by',
          width: 50,
          align: 'center',
        },
        {
          header: 'Build ID',
          type: 'text',
          field: 'build_number',
          width: 100,
          align: 'center',
        },
        {
          header: 'Status',
          render: ({row}) => <DeploymentStatusCard row={row} />,
        },
        {
          header: 'Started',
          type: 'date',
          field: 'createdAt',
          formatOptions: {fromNow: true},
          width: 150,
        },
        {
          header: 'Duration',
          width: 150,
          render: ({row}) => {
            if (row?.duration) {
              return <TextRenderer value={convertSecondsToHMS(row.duration)} />;
            } else {
              return void 0;
            }
          },
        },

        // {
        //   render: PipelineRender,
        //   width: 60,
        // },
        // {
        //   header: 'Pipeline',
        //   render: PipelineCardRender,
        // },
        // {
        //   header: 'Status',
        //   width: 200,
        //   render: DeploymentStatusRender,
        //   align: 'center',
        // },
        // {
        //   header: 'Started',
        //   field: 'createdAt',
        //   width: 150,
        //   render: ({row}) => {
        //     return (
        //       <TextRenderer
        //         title={formats.formatDate(row.createdAt)}
        //         value={formats.formatDate(row.createdAt, {fromNow: true})}
        //       />
        //     );
        //   },
        // },
      ]}
    />
  );
};

export default DeploymentHistoryTable;
