import React from 'react';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {randomColor} from '../../../utils/util';
import {useTheme} from '@unthinkable/react-theme';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {IconButton} from '../../../components/button/IconButton';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

export const VendorAdvanceTable = props => {
  const {
    filterParams,
    searchValue,
    route: {params = {}},
  } = props;
  return (
    <Table
      {...props}
      api={'/getVendorAdvances'}
      addOnFilter={{...filterParams}}
      params={params}
      eventSourceId="Reconcilation"
      search={searchValue}
      limit={50}
      columns={[
        {
          field: 'payee_id.name',
          header: 'Vendor',
          type: 'text',
        },
        {
          type: 'currency',
          header: 'Pending Amount',
          field: 'advance_amount_pending',
        },
      ]}
    />
  );
};

export const VendorAdvanceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    tds: {
      label: 'Vendor Advances',
      view: (
        <VendorAdvanceTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'entityadvance',
      addOnFilter: filter,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportvendoradvances',
              props: {
                addOnFilter: {...filter},
                params: {...params, ...filterValues.params},
                globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'payment_date',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              suggestionField: 'name',
              valueField: 'name',
              field: 'payee_id',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};

export const CustomerBalanceTable = props => {
  const {
    filterParams,
    searchValue,
    route: {params = {}},
  } = props;
  return (
    <Table
      {...props}
      api={'/getcustomerbalance'}
      addOnFilter={{...filterParams}}
      params={params}
      eventSourceId="balance"
      search={searchValue}
      limit={50}
      columns={[
        {
          field: 'entity.name',
          header: 'Entity',
          type: 'text',
        },
        {
          type: 'currency',
          header: 'Credit',
          field: 'cr_amount',
          aggregate: true,
        },
        {
          field: 'dr_amount',
          type: 'currency',
          header: 'Debit',
          aggregate: true,
        },
        {
          field: 'amount',
          type: 'currency',
          header: 'Balance',
          aggregate: true,
        },
      ]}
    />
  );
};

export const CustomerBalanceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    tds: {
      label: 'Customer Balance',
      view: (
        <CustomerBalanceTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'reconcilation',
      addOnFilter: filter,
      api: '/getcustomerbalance',
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportentiybalance',
              props: {
                addOnFilter: {...filter},
                uri: 'getcustomerbalance',
                globalParams
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'date',
            },
            {
              type: 'autoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account',
            },
            {
              type: 'autoComplete',
              label: 'Entity',
              api: '/getentities',
              suggestionField: 'name',
              valueField: 'name',
              field: 'entity',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};

export const VendorBalanceTable = props => {
  const {
    filterParams,
    searchValue,
    route: {params = {}},
  } = props;
  return (
    <Table
      {...props}
      api={'/getvendorbalance'}
      addOnFilter={{...filterParams}}
      params={params}
      eventSourceId="balance"
      search={searchValue}
      limit={50}
      columns={[
        {
          field: 'entity.name',
          header: 'Entity',
          type: 'text',
        },
        {
          type: 'currency',
          header: 'Credit',
          field: 'cr_amount',
          aggregate: true,
        },
        {
          field: 'dr_amount',
          type: 'currency',
          header: 'Debit',
          aggregate: true,
        },
        {
          field: 'amount',
          type: 'currency',
          header: 'Balance',
          aggregate: true,
        },
      ]}
    />
  );
};

export const VendorBalanceTab = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    tds: {
      label: 'Vendor Balance',
      view: (
        <VendorBalanceTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'reconcilation',
      addOnFilter: filter,
      api: '/getvendorbalance',
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportentiybalance',
              props: {
                addOnFilter: {...filter},
                uri: 'getvendorbalance',
                globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'date',
            },
            {
              type: 'autoComplete',
              label: 'Account',
              api: '/accounts',
              suggestionField: 'name',
              valueField: 'name',
              field: 'account',
            },
            {
              type: 'autoComplete',
              label: 'Entity',
              api: '/getentities',
              suggestionField: 'name',
              valueField: 'name',
              field: 'entity',
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};
