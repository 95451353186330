import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const EditDepartmentRole = props => {
  const {
    route: {params},
  } = props;
  const {departmentRole} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/departmentroles`,
    eventSourceId: 'DepartmentRole',
  });

  return (
    <Form
      header={{
        title: 'Edit Department Role',
        secondaryTitle: departmentRole?.name,
      }}
      mode="edit"
      api={`/departmentroles/${departmentRole._id}`}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          label: 'Name',
        },
      ]}
      onSubmit={onSubmit}
      submitAction="Update"
    />
  );
};
