import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {CustomerTable} from './CustomerTable';
import {
  ActiveEmployeeTable,
  AlumniEmployeeTable,
  InActiveEmployeeTable,
  InSeperationEmployeeTable,
} from './EmployeeTable';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {EmployeeIncentiveScheduleList} from '../../employee/views/EmployeeList';

export const EmployeeTabs = props => {
  const {route: {params} = {}} = props;
  const {period} = params;
  const {onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/active/employees',
    params: {
      ...params,
    },
    view: <ActiveEmployeeTable {...props} searchValue={searchValue} />,
  };
  tabs.inactive = {
    label: 'Inactive',
    api: '/inactive/employees',
    params: {
      ...params,
    },
    view: <InActiveEmployeeTable {...props} searchValue={searchValue} />,
  };
  tabs.seperation = {
    label: 'In Seperation',
    params: {
      ...params,
    },
    api: '/inseperation/employees',
    view: <InSeperationEmployeeTable {...props} searchValue={searchValue} />,
  };
  tabs.alumni = {
    label: 'Alumni',
    api: '/alumni/employees',
    params: {
      ...params,
    },
    view: <AlumniEmployeeTable {...props} searchValue={searchValue} />,
  };
  // tabs.onHold = {
  //   label: 'On Hold',
  //   view: (
  //     <CustomerTable
  //       filter={{
  //         created_on: {
  //           $gte: period.from,
  //           $lte: period.to,
  //         },
  //       }}
  //       sort={{created_on: -1}}
  //       {...props}
  //     />
  //   ),
  // };
  // tabs.recoveryPending = {
  //   label: 'Recovery Pending',
  //   view: (
  //     <CustomerTable
  //       filter={{
  //         created_on: {
  //           $gte: period.from,
  //           $lte: period.to,
  //         },
  //       }}
  //       sort={{created_on: -1}}
  //       {...props}
  //     />
  //   ),
  // };
  // tabs.legal = {
  //   label: 'Legal',
  //   view: (
  //     <CustomerTable
  //       filter={{
  //         created_on: {
  //           $gte: period.from,
  //           $lte: period.to,
  //         },
  //       }}
  //       sort={{created_on: -1}}
  //       {...props}
  //     />
  //   ),
  // };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};

export const EmployeeIncentiveScheduleTabs = props => {
  console.log('here');
  const {route: {params} = {}} = props;
  const {period} = params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter(
    {},
  );
  const {filter, params: filterParams = {}} = filterValues;
  let tabs = {};
  tabs.active = {
    label: 'Incentive schedule',
    // api: '/active/employees',
    params: {
      ...params,
    },
    view: (
      <EmployeeIncentiveScheduleList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee',
              secondarySuggestionField: 'official_email_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            // {
            //   type: 'autoComplete',
            //   field: 'employee.applied_for_resignation',
            //   label: 'Applied for resignation',
            //   options: [
            //     {label: 'True', value: true},
            //     // {value: {$in: [null, false]}, label: 'False'},
            //   ],
            //   placeholder: 'Select',
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   valueField: 'label',
            // },
            // {
            //   type: 'autoComplete',
            //   field: 'employee.employee_status',
            //   label: 'Employee status',
            //   options: ['Active', 'Inactive'],
            //   placeholder: 'Select',
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   valueField: 'label',
            // },
            // {
            //   type: 'autoComplete',
            //   asParam: true,
            //   field: 'employee_status',
            //   label: 'Employee status',
            //   options: ['Active', 'Inactive'],
            //   placeholder: 'Select',
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   valueField: 'label',
            // },
            {
              type: 'date',
              field: 'from_date',
              asParam: true,
              label: 'Effective from',
            },
            {
              type: 'date',
              field: 'to_date',
              asParam: true,
              label: 'Effective to',
            },
          ]}
        />,
      ]}
    />
  );
};
