import React from 'react';
import {RecursiveTable, Table} from '../../../components/table/Table';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useAppStateContext} from '../../../providers/AppState';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {Row} from '@unthinkable/react-core-components';
import {useFetchData} from '../../../controllers/useFetchData';
import {RecursiveRender} from '@unthinkable/react-table';
import {useModuleGlobalParams} from '../../../controllers/useModuleGlobalParams';

const RecurringTableHOC = ({source, ...props}) => {
  const {params} = props;
  const {navigation, filterParams} = params;
  return (
    <RecursiveTable
      {...props}
      recursiveKey="_children"
      columns={[
        {
          render: RecursiveRender,
          header: 'Name',
          field: 'name',
        },
        {
          header: 'Amount',
          field: source === 'asset' ? 'dr_amount' : 'cr_amount',
          value: ({row}) =>
            source === 'asset'
              ? Math.abs(row.dr_amount || 0)
              : row.cr_amount || 0,
          type: 'currency',
        },
      ]}
      moreActions={
        source === 'asset'
          ? [
              {
                title: 'Voucher',
                onPress: ({row}) => {
                  navigation.navigate(`voucherlineitems`, {
                    lineItemFilter: {
                      account: row?.account_id,
                      ...filterParams,
                    },
                  });
                },
              },
            ]
          : []
      }
    />
  );
};

const BalanceSheetTable = props => {
  const {filterParams, searchValue, params} = props;
  const {data, loading} = useFetchData({
    api: '/getbalancesheet',
    addOnFilter: filterParams,
    params,
    eventSourceId: 'balancesheet',
  });
  return (
    <Row style={{gap: 8, overflow: 'hidden', flex: 1}}>
      <RecurringTableHOC
        data={data?.libdata}
        loading={loading}
        source="liability"
        params={props}
      />
      <RecurringTableHOC
        data={data?.assetdata}
        loading={loading}
        source="asset"
        params={props}
      />
    </Row>
  );
};

export const BalanceSheetTabs = props => {
  let {route: {params} = {}} = props;
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {},
  });
  let {filter} = filterValues;
  const globalParams = useModuleGlobalParams(props);
  const tabs = {
    all: {
      label: 'Balance Sheet',
      view: (
        <BalanceSheetTable
          filterParams={filter}
          searchValue={searchValue}
          {...props}
          params={{
            ...params,
            ...filterValues?.params,
          }}
        />
      ),
      eventSourceId: 'balancesheet',
      addOnFilter: filter,
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/getbalancesheetreport',
              props: {
                addOnFilter: {...filter},
                params: {
                  ...params,
                  ...filterValues?.params,
                },
                globalParams,
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={{...params, ...filterValues.params}}
      extraFilter={filter}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'period',
              asParam: true,
            },
            {
              type: 'multiAutoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
          ]}
        />,
      ]}
    />
  );
};
