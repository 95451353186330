import React from 'react';
import {Table} from '../../../components/table/Table';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {
  BilledManagedTeamProjects,
  BilledSDLCProjects,
  BilledStaffaugmentationProjects,
  PerformanceAllTab,
  PerformanceClosedTandMTab,
  PerformanceFixedTab,
} from '../../pmt/views/PerformanceTab';
import {PerformanceUnbilledReportTable} from '../../pmt/views/PerformanceReportTable';

export const ProjectTypeWiseTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      data={[
        {
          _id: 'Billed',
          project_billing_status: 'Billed',
        },
        {
          _id: 'Unbilled',
          project_billing_status: 'Unbilled',
        },
      ]}
      eventSourceId={['ProjectsRole']}
      onRowPress={({row}) => {
        if (row?.project_billing_status == 'Billed') {
          navigation.navigate('project-billed-screen', {
            ...params,
            project: row._id,
            title: row.project_billing_status,
          });
        }
        if (row?.project_billing_status == 'Unbilled') {
          navigation.navigate('project-unbilled-screen', {
            ...params,
            project: row._id,
            title: row.project_billing_status,
          });
        }
      }}
      columns={[
        {
          header: 'Billing status',
          field: 'project_billing_status',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};

export const BilledProjectLeftMenu = props => {
  const {
    route: {params},
  } = props;

  //   const currentMonth = getCurrentMonth();
  const menus = [
    {
      label: 'Staff augmentation',
      view: <BilledStaffaugmentationProjects />,
    },
    {
      label: 'Managed Team',
      view: <BilledManagedTeamProjects />,
    },
    {
      label: 'SDLC',
      view: <BilledSDLCProjects />,
    },
    {
      label: 'All',
      section: true,
      menus: [
        {
          label: 'All',
          view: (
            <PerformanceAllTab
              key="NewProject"
              project_status="a"
              tab="All Active"
              is_project_assign={true}
              {...props}
            />
          ),
          //   view: <DepartmentWiseEmployeeTabs />,
        },
        {
          label: 'Closed',
          view: (
            <PerformanceClosedTandMTab
              project_status="c"
              tab="Completed"
              {...props}
            />
          ),
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const UnBilledProjectLeftMenu = props => {
  const {
    route: {params},
  } = props;

  //   const currentMonth = getCurrentMonth();
  const menus = [
    // {
    //   label: 'Unbilled',
    //   view: <BilledStaffaugmentationProjects />,
    // },
    // {
    //   label: 'Managed Team',
    //   view: <BilledManagedTeamProjects />,
    // },
    // {
    //   label: 'SDLC',
    //   view: <BilledSDLCProjects />,
    // },
    {
      label: 'Unassigned',
      section: true,
      menus: [
        {
          label: 'Active',
          view: (
            <PerformanceUnbilledReportTable
              filter={{
                project_status: 'a',
                projecttype: 'i',
              }}
              tab="Active"
              project_status="a"
              tabName="unbilled"
              fromUnbilled={true}
              {...props}
            />
          ),
        },
        {
          label: 'Closed',
          view: (
            <PerformanceUnbilledReportTable
              filter={{
                project_status: 'c',
                projecttype: 'i',
              }}
              tab="Closed"
              project_status="c"
              tabName="unbilled"
              fromUnbilled={true}
              {...props}
            />
          ),
        },
      ],
    },
    {
      label: 'Retainer',
      section: true,
      menus: [
        {
          label: 'Active',
          view: (
            <PerformanceFixedTab
              project_status="a"
              addOnParamFilter={{is_support_project: true}}
              tab="Assigned"
              {...props}
            />
          ),
          //   view: <DepartmentWiseEmployeeTabs />,
        },
        {
          label: 'Closed',
          view: (
            <PerformanceFixedTab
              project_status="c"
              tab="Completed"
              addOnParamFilter={{is_support_project: true}}
              {...props}
            />
          ),
        },
      ],
    },
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
