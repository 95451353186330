import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const PunchDataForm = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeepunchdatas',
    eventSourceId: 'EmployeePunchData',
  });
  return (
    <Form
      api={`/employeepunchdata/self`}
      addOnFilter={{employee: row.employee, date: row.attendance_date}}
      onSubmit={onSubmit}
      header={'Punch Data'}
      submitAction="Save"
      mode="edit"
      readOnly={true}
      layoutFields={[
        {
          label: 'Date',
          type: 'date',
          field: 'date',
          size: 6,
        },
        {
          label: 'Employee',
          type: 'text',
          field: 'employee.name',
          size: 6,
        },
        {
          label: 'Punch In',
          fields: [
            {
              field: 'punch_data',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  columns={[
                    {
                      header: 'Punch Type',
                      field: 'punch_type_id.name',
                      type: 'text',
                    },
                    {
                      header: 'Punch Time',
                      field: 'time',
                      type: 'text',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
