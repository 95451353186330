import React from 'react';
import { Table } from '../../../components/table/Table';
import { ThreadForm } from './AddThreadForm';
import { TabView } from '../../../components/tab';
import images from '../../../images';
import { CommentColumn } from '../../../app-components/renders/CommentRender';
import {
    Row,
    TouchableOpacity,
    Col,
    Image,
    Text,
    View,
} from '@unthinkable/react-core-components';
import { useComponentTheme, useStyles, useTheme } from '@unthinkable/react-theme';
import { useInvoke } from '../../../controllers/useInvoke';
import { THREAD_ID } from '../../common/constants/SourceConstants';
import { AddButton } from '../../../components/button/AddButton';
import { useAppStateContext } from '../../../providers/AppState';
import { ReviewedTaskRenderStyle } from '../../pmt/cellrenders/theme';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { fonts } from '../../../theme/theme';
import { GroupContentItem } from '../../../components/card/GroupContentItem';

const { Owner } = images;

export const threadCommentColumn = ({ navigation, params }) => {
    return CommentColumn({
        navigation,
        params: ({ row }) => ({
            comment_source: { _id: THREAD_ID, label: 'Thread' },
            comment_source_id: { _id: row._id, name: row.name },
        }),
    });
};

const CollaboratorCellRender = ({
    row,
    onPlusPress,
}) => {

    let {
        addPlus,
        iconStyle,
        iconContainerStyle,
        rowStyle,
    } = useComponentTheme('CountCellRenderStyles');

    return (
        <Row style={rowStyle}>
            {onPlusPress ? (
                <TouchableOpacity
                    onPress={() => {
                        onPlusPress({ row });
                    }}>
                    <Col gap={2} style={iconContainerStyle}>
                        <Image source={addPlus} style={iconStyle} />
                    </Col>
                </TouchableOpacity>
            ) : null}
        </Row>
    );
};


const CollaboratorCount = ({ params, navigation }) => ({
    render: CollaboratorCellRender,
    width: 50,
    responsive: 'sm',
    onPlusPress: props => {
        const { row } = props;
        navigation.navigate(`add-collaborator`, { row });
    },
});


const ThreadDynamicRender = props => {
    const { row, navigation, route: { breadcrumb, ...params } } = props;
    const {
        name,
    } = row;

    const { organization = {}, employee_team = {}, project = {}, milestone = {} } = row;

    const { colors } = useTheme();
    const { textStyle } = useStyles(ReviewedTaskRenderStyle);

    let Component;
    let componentProps = {};
    if (row?.project?.project && row?.milestone?.milestone) {
        Component = TouchableOpacity;
        componentProps = {
            onPress: () => {
                if (row?.project?.project && row?.milestone?.milestone) {
                    navigation.navigate('milestone-menu', {
                        ...params,
                        milestone: { ...row?.milestone },
                        project: row?.project,
                    });
                }
            }
        }
    }
    else {
        Component = View;
    }

    return (
        <Col gap={4}>
            <Text title={name} numberOfLines={2} style={textStyle}>
                {name}
            </Text>
            <Component {...componentProps}>
                <RowWithSeparator gap={6} style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                    {organization?.name ?
                        (<Text style={{ ...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM }}>
                            {organization?.name}
                        </Text>)
                        : (
                            void 0
                        )
                    }
                    {employee_team?.name ?
                        (<Text style={{ ...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM }}>
                            {employee_team?.name}
                        </Text>)
                        : (
                            void 0
                        )
                    }
                    {project?.project ?
                        (<Text style={{ ...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM }}>
                            {project?.project}
                        </Text>)
                        : (
                            void 0
                        )
                    }
                    {milestone?.milestone ?
                        (
                            <Text style={{ ...fonts.CAPTION_SMALL, color: colors.NEUTRAL_MEDIUM }}>
                                {milestone?.milestone}
                            </Text>)
                        : (
                            void 0
                        )
                    }
                </RowWithSeparator>
            </Component>
        </Col>
    );
};

const OverudueTasks = ({ all_tasks }) => ({
    header: all_tasks ? 'Tasks' : 'Overdue Tasks',
    align: 'center',
    render: ({ row }) => {
        const { overdue_tasks = {}, tasks = {} } = row;
        const { count: overdueCount, est_hours: overdueHours } = overdue_tasks;
        const { count: tasksCount, est_hours: tasksHours } = tasks;
        let taskCount = all_tasks ? tasksCount : overdueCount;
        let taskHours = all_tasks ? tasksHours : overdueHours;

        taskCount = taskCount || 0;
        taskHours = taskHours || 0;

        if (taskCount === 0 && taskHours === 0) {
            return null;
        }
        return (
            <RowWithSeparator style={{ gap: 6, alignItems: 'center' }}>
                <GroupContentItem value={`${taskCount} tasks`} />
                <GroupContentItem value={`${taskHours.toFixed(1)} hrs`} />
            </RowWithSeparator>
        );
    },
    width: 150,
});

// Akshat Garg - 22/08/24 - my threads table
export const MyThreadsTable = props => {
    let {
        navigation,
        route: { breadcrumb, ...params },
        addOnFilter,
        isActive,
        isCompleted,
        isDelegated,
        teamThreads,
    } = props;

    const updateInvoke = useInvoke({
        method: 'put',
        eventSourceId: 'markCompleted',
    });

    const completedOnColumn = [];

    if (isCompleted) {
        completedOnColumn.push({
            header: 'Completed On',
            field: 'completed_on',
            width: 150,
            align: 'center',
            type: 'date',
            formatOptions: {
                format: 'DD MMM YY',
            },
        })
    }

    return (
        <Table
            eventSourceId={['threadAdded', 'collaboratorAdded', 'markCompleted', 'Task', 'Comment']}
            api={`/threads`}
            fields={{
                collaborators: {
                    name: 1,
                    color: 1,
                },
                name: 1,
                completed_on: 1,
                milestone: {
                    milestone: 1,
                    due_date: 1,
                    start_date: 1,
                    completed_on: 1,
                    status: 1,
                    color: 1,
                    owner_id: { name: 1, color: 1 },
                    comments_count: { _id: 1 },
                    due_date_reason: 1,
                },
                project: { project: 1 },
                employee_team: {
                    name: 1,
                },
                last_comment: {
                    createdAt: 1,
                },
                comments_count: { _id: 1 },
                organization: { name: 1 },
                overdue_tasks: {
                    est_hours: 1,
                    count: 1,
                },
                tasks: {
                    est_hours: 1,
                    count: 1,
                },
            }}
            filter={addOnFilter}
            onRowPress={({ row }) => {
                navigation.navigate('thread-tasks-menus', {
                    ...params,
                    thread: { ...row },
                    comment_source: THREAD_ID,
                    comment_source_id: { ...row },
                });
            }}
            renderHeader={() => (
                isActive && !isDelegated ? <>
                    <ThreadForm {...props} />
                </> : void 0
            )}
            columns={[
                {
                    header: 'Thread',
                    render: _props => { return ThreadDynamicRender({ ...props, ..._props }) },
                },
                OverudueTasks({ all_tasks: false }),
                OverudueTasks({ all_tasks: true }),
                {
                    header: { icon: Owner },
                    field: 'collaborators',
                    type: 'userAvatarGroup',
                    align: 'right',
                    width: 120,
                    // render: CollaboratorCellRender,
                },
                (isActive ? CollaboratorCount({ params, navigation }) : void 0),
                ...completedOnColumn,
                threadCommentColumn({ params, navigation }),
            ]}
            moreActions={() => isActive ? [
                {
                    title: 'Edit',
                    onPress: ({ row }) => {
                        navigation.navigate('edit-thread', { row });
                    },
                },
                {
                    title: 'Mark Completed',
                    confirm: {
                        title: 'Mark Completed',
                        message:
                            `Are you sure you want to mark this thread as completed ?`,
                        confirmText: 'Confirm',
                    },
                    onPress: ({ row }) => {
                        updateInvoke({
                            uri: `/threads/${row?._id}`,
                            props: {
                                status: 'Completed',
                            }
                        });
                    },
                    visible: !isDelegated ? true : false,
                },
                {
                    title: 'Mark Later',
                    confirm: {
                        title: 'Mark Later',
                        message:
                            `Are you sure you want to mark this thread for later ?`,
                        confirmText: 'Confirm',
                    },
                    onPress: ({ row }) => {
                        updateInvoke({
                            uri: `/threads/${row?._id}`,
                            props: {
                                status: 'Later',
                            }
                        });
                    },
                    visible: !isDelegated ? true : false,
                },
                {
                    title: 'Mark Archive',
                    confirm: {
                        title: 'Mark Archive',
                        message:
                            `Are you sure you want to mark this thread as Archived ?`,
                        confirmText: 'Confirm',
                    },
                    onPress: ({ row }) => {
                        updateInvoke({
                            uri: `/threads/${row?._id}`,
                            props: {
                                status: 'Archived',
                            }
                        });
                    },
                },
                {
                    title: 'Delegate Thread',
                    confirm: {
                        title: 'Delegate Thread',
                        message:
                            `Are you sure you want to delegate this thread ?`,
                        confirmText: 'Confirm',
                    },
                    onPress: ({ row }) => {
                        updateInvoke({
                            uri: `/delegateThread/${row?._id}`,
                        });
                    },
                    visible: !teamThreads && !isDelegated,
                },
            ] : []}
        />
    );
};

export const ThreadTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const { user } = useAppStateContext();

    const newParams = { ...params, threadForm: true };
    const { employee_team, teamThreads } = params;

    let extraFilter = { collaborators: { $in: [user._id] } };

    if (employee_team) {
        extraFilter = { employee_team: employee_team?._id };
    }

    return (
        <TabView
            tabs={{
                active: {
                    label: 'Active',
                    api: '/threads',
                    filter: { status: 'Active', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Active',
                                ...extraFilter,
                            }}
                            user={user}
                            isActive={true}
                            isCompleted={false}
                            teamThreads={teamThreads}
                        />,
                    actions: [
                        <AddButton
                            title="Thread"
                            view="add-threads"
                            params={newParams}
                        />
                    ]
                },
                later: {
                    label: 'Later',
                    api: '/threads',
                    filter: { status: 'Later', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Later',
                                ...extraFilter
                            }}
                            user={user}
                            isActive={false}
                            isCompleted={false}
                        />,
                },
                completed: {
                    label: 'Completed',
                    api: '/threads',
                    filter: { status: 'Completed', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Completed',
                                ...extraFilter
                            }}
                            user={user}
                            isActive={false}
                            isCompleted={true}
                        />,
                },
                archived: {
                    label: 'Archived',
                    api: '/threads',
                    filter: { status: 'Archived', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Archived',
                                ...extraFilter
                            }}
                            user={user}
                            isActive={false}
                            isCompleted={false}
                        />,
                },
            }}
            {...props}
        />
    );
};

export const DelegateThreadTabs = props => {
    const {
        route: { params },
        navigation,
        user
    } = props;

    let extraFilter = { delegators: { $in: [user._id] } };

    return (
        <TabView
            tabs={{
                active: {
                    label: 'Active',
                    api: '/threads',
                    filter: { status: 'Active', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Active',
                                ...extraFilter,
                            }}
                            user={user}
                            isCompleted={false}
                            isDelegated={true}
                            isActive={true}
                        />,
                },
                completed: {
                    label: 'Completed',
                    api: '/threads',
                    filter: { status: 'Completed', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Completed',
                                ...extraFilter,
                            }}
                            user={user}
                            isCompleted={true}
                            isDelegated={true}
                        />,
                },
                archived: {
                    label: 'Archived',
                    api: '/threads',
                    filter: { status: 'Archived', ...extraFilter },
                    eventSourceId: ['threadAdded', 'markCompleted', 'collaboratorAdded'],
                    view:
                        <MyThreadsTable
                            addOnFilter={{
                                status: 'Archived',
                                ...extraFilter
                            }}
                            user={user}
                            isDelegated={true}
                            isCompleted={false}
                        />,
                },
            }}
            {...props}
        />
    );
};