import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

export const LeaveCreditTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['leaveCredit']}
      api={`/leavecredit/setup`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-leave-credit`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee_id?.name}
                color={row?.employee_id?.color}
                official_email_id={row?.employee_id?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month',
          field: 'month_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Year',
          field: 'year_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Leave Credited',
          field: 'leave_credited',
          type: 'text',
          width: 150,
        },
        {
          header: 'Leave Type',
          field: 'leave_type_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Leave Credit Code',
          field: 'leave_credit_code',
          type: 'text',
          width: 150,
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          width: 150,
        },
      ]}
    />
  );
};

export const EsiCycleTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['esicycle']}
      api={`/esi/cycle`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-esi-cycle`, {
          row,
        });
      }}
      columns={[
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          width: 200,
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          width: 200,
        },
      ]}
    />
  );
};

export const SalaryCycleTable = props => {
  const {navigation} = props;
  return (
    <Table
      eventSourceId={['salarycycle']}
      api={`/salary/cycle`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-salary-cycle`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Organization',
          field: 'organization.name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Active From',
          field: 'active_from',
          type: 'date',
          width: 150,
        },
        {
          header: 'From',
          field: 'from',
          type: 'number',
          width: 150,
        },
        {
          header: 'To',
          field: 'to',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};
