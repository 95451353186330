import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const CreditNoteForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/creditnoteams',
    eventSourceId: 'creditNoteAm',
  });
  const {user} = useAppStateContext();
  return (
    <Form
      api={`/creditNote/am/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        requested_by: user._id,
        credit_note_status: 'Open',
      }}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Customer',
          field: 'customer',
          type: 'autoComplete',
          api: '/customers',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'Amount',
          field: 'credit_note_amount',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'Currency',
          field: 'currency',
          type: 'autoComplete',
          api: '/currencies',
          suggestionField: 'currency',
          valueField: 'currency',
          required: true,
          size: 6,
        },
        {
          label: 'Reason',
          field: 'reason',
          type: 'textArea',
          required: true,
          size: 12,
        },
      ]}
      {...props}
    />
  );
};

export const AddCreditNoteAm = props => {
  return <CreditNoteForm header="Add Credit Note" {...props} />;
};

export const EditCreditNoteAm = props => {
  return (
    <CreditNoteForm mode="edit" header={'Update Credit Note'} {...props} />
  );
};

export const RejectCreditNoteReason = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  const {user} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['creditNoteAm'],
  });
  return (
    <Form
      onSubmit={data => {
        invoke({
          uri: `/creditnoteams/${row._id}`,
          props: {
            credit_note_status: 'Reject',
            approved_by: user._id,
            approved_on: getZeroTimeDate(new Date()),
            ...data,
          },
        });
      }}
      header="Reject Credit Note"
      submitActions="save"
      layoutFields={[
        {
          label: 'Reject Reason',
          field: 'rejection_reason',
          type: 'textArea',
          required: true,
          size: 12,
        },
      ]}
      {...props}
    />
  );
};
export const MarkBilledCreditNote = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {row} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['creditNoteAm'],
  });
  return (
    <Form
      onSubmit={data => {
        invoke({
          uri: `/creditnoteams/${row._id}`,
          props: {
            credit_note_status: 'Billed',
            ...data,
          },
        });
      }}
      header="Bill Credit Note"
      submitActions="save"
      layoutFields={[
        {
          label: 'Invoice',
          field: 'invoice',
          type: 'autoComplete',
          api: '/revenueInvoiceSuggestions',
          suggestionField: 'invoice_number',
          valueField: 'invoice_number',
          required: true,
          size: 12,
          filter: {
            customer: row?.customer?._id,
          },
        },
      ]}
      {...props}
    />
  );
};
