import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const DepartmentTable = ({navigation}) => {
  return (
    <Table
      eventSourceId={["Department","DepartmentRole","DepartmentTag"]}
      api={`/departments`}
      renderHeader={() => (
        <TableHeader
          title="Department"
          actions={[<AddButton title="Add Department" view="add-department" />]}
        />
      )}
      fields={{
        name: 1,
        code: 1,
        parent: {name: 1},
        managers: {name: 1},
        department_role_count: {
          _id: 1,
        },
        department_tag_count: {
          _id: 1,
        },
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`department-detail`, {departmentId: row._id});
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          header: 'Tags',
          align: 'right',
          render: CountCellRender,
          count_field: 'department_tag_count',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('department-tags', {
              department: row,
            });
          },
        },
        {
          header: 'Roles',
          align: 'right',
          render: CountCellRender,
          count_field: 'department_role_count',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('department-roles', {
              department: row,
            });
          },
        },
      ]}
    />
  );
};

export default DepartmentTable;
