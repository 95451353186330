import React, {forwardRef} from 'react';
import {
  AutoComplete as AutoCompleteComponent,
  MultiAutoComplete as MultiAutoCompleteComponent,
  Cascader as CascaderComponent,
  AutoCompleteRenderer as AutoCompleteRendererComponent,
  MultiAutoCompleteRenderer as MACRenderer,
  CascaderRenderer as CascaderRendererComponent,
} from '@unthinkable/react-autocomplete';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {
  AutoCompleteLinkStyles,
  AutoCompleteStyles,
  CascaderStyles,
} from './theme';
import {useAppStateContext} from '../../../providers/AppState';
import {getAccentColor} from '../../../controllers/useAccentColor';
import {Image, Text} from '@unthinkable/react-core-components';
import {
  BreadCrumbItemContainer,
  BreadCrumbChildItemContainer,
  BreadCrumbIconContainer,
  BreadCrumbTextContainer,
} from '../../nav/styles';

const resolveColor = colors => color => {
  const {LOW, MEDIUM, HIGH} = getAccentColor(color, colors) || {};
  return {
    // backgroundColor: LOW,
    // textColor: HIGH,
    chip: {
      color: MEDIUM,
    },
    avatar: {
      color: HIGH,
      borderColor: LOW,
    },
  };
};

export const AutoComplete = forwardRef(({onCreate, options, ...props}, ref) => {
  const styles = useStyles(AutoCompleteStyles);
  const {fetch} = useAppStateContext();
  const colors = useTheme('colors');

  if (options && typeof options === 'function') {
    options = options(props?.form);
  }

  return (
    <AutoCompleteComponent
      ref={ref}
      styles={styles}
      fetch={fetch}
      resolveColor={resolveColor(colors)}
      options={options}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});

const AutoCompleteLinkComponent = props => {
  const {DownArrow, rowText, containerHoverColor, itemHoverColor} = useStyles(
    AutoCompleteLinkStyles,
  );
  const {value, maxWidth = 200, inputProps, valueField, onLinkPress} = props;
  if (!value) {
    return;
  }

  return (
    <BreadCrumbItemContainer hoverColor={containerHoverColor} gap={1}>
      <BreadCrumbChildItemContainer
        onPress={onLinkPress}
        hoverColor={itemHoverColor}
        className="breadcrumbTextContainer">
        <BreadCrumbTextContainer styles={{maxWidth}} className="child">
          <Text numberOfLines={1} style={rowText}>
            {value?.[valueField]}
          </Text>
        </BreadCrumbTextContainer>
      </BreadCrumbChildItemContainer>
      <BreadCrumbChildItemContainer
        hoverColor={itemHoverColor}
        className="breadcrumbIconContainer">
        <BreadCrumbIconContainer
          className="child"
          onPress={e => {
            inputProps?.onFocus(e);
          }}>
          <Image source={DownArrow} />
        </BreadCrumbIconContainer>
      </BreadCrumbChildItemContainer>
    </BreadCrumbItemContainer>
  );
};

export const AutoCompleteLink = props => {
  return (
    <AutoComplete {...props}>
      <AutoCompleteLinkComponent {...props} />
    </AutoComplete>
  );
};

export const MultiAutoComplete = forwardRef(
  ({onCreate, options, ...props}, ref) => {
    const styles = useStyles(AutoCompleteStyles);
    const {fetch} = useAppStateContext();
    const colors = useTheme('colors');
    if (options && typeof options === 'function') {
      options = options(props?.form);
    }
    return (
      <MultiAutoCompleteComponent
        ref={ref}
        styles={styles}
        fetch={fetch}
        resolveColor={resolveColor(colors)}
        options={options}
        {...props}
        onCreate={
          onCreate
            ? _props => {
                onCreate({..._props, ...props});
              }
            : void 0
        }
      />
    );
  },
);

export const Cascader = forwardRef(({onCreate, ...props}, ref) => {
  const styles = useStyles(CascaderStyles);
  const {fetch} = useAppStateContext();
  const {values, setFieldValue} = props.form;

  return (
    <CascaderComponent
      ref={ref}
      styles={styles}
      fetch={fetch}
      setFieldValue={setFieldValue}
      values={values}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});

export const AutoCompleteRenderer = props => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <AutoCompleteRendererComponent
      {...props}
      styles={styles}
      resolveColor={resolveColor(colors)}
    />
  );
};

export const MultiAutoCompleteRenderer = props => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <MACRenderer
      {...props}
      styles={styles}
      resolveColor={resolveColor(colors)}
    />
  );
};

export const CascaderRenderer = props => {
  const styles = useStyles(CascaderStyles);
  const {values} = props.form;
  return (
    <CascaderRendererComponent {...props} values={values} styles={styles} />
  );
};
