import React from 'react';
import {BorderedGroupTable, Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Text} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';

export const RenderCount = ({row, ...rest}) => {
  const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      {...rest}
      color={SUCCESS_LOW}
      textColor={SUCCESS_HIGH}
      value={row?.selected_count || 0}
      borderRadius={6}
    />
  );
};

export const ExperienceWiseAnalytics = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    searchValue,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      search={searchValue}
      searchFields="name"
      api={`/employees/engg-analytics/experiencewise`}
      variant={'bordered'}
      columns={[
        {
          header: 'Experience',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {experience_range} = row;
            if (experience_range == '10+') {
              return (
                <Tag value={experience_range} color={colors.ACCENT3_MEDIUM} />
              );
            }
            if (experience_range == '6-10' || experience_range == '1-2') {
              return (
                <Tag value={experience_range} color={colors.ACCENT5_MEDIUM} />
              );
            }
            if (experience_range == '4-6') {
              return (
                <Tag value={experience_range} color={colors.ACCENT2_MEDIUM} />
              );
            }
            if (experience_range == '2-4') {
              return (
                <Tag value={experience_range} color={colors.ACCENT4_MEDIUM} />
              );
            }
            if (experience_range == '0-1') {
              return (
                <Tag value={experience_range} color={colors.ACCENT6_MEDIUM} />
              );
            }
          },
          width: 240,
        },
        {
          header: 'Total',
          field: 'total_count',
          type: 'number',
          count_field: 'total_count',
          render: CountCellRender,
          aggregate: true,
          width: 240,
          onPress: ({row}) => {
            const employeeIds = row?._children.map(item => item._id) || [
              'xxxx',
            ];
            navigation.navigate(`employee-workload`, {
              employeeFilter: {_id: {$in: employeeIds}},
            });
          },
        },
        {
          header: 'Avg. Experience',
          field: 'avgExperience',
          aggregate: true,
          render: ({row, styles = {rowText: {}}}) => {
            return (
              <Text style={{...styles.rowText}}>{row?.avgExperience} yrs</Text>
            );
          },
          width: 140,
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 1,
          },
        },
        {
          header: 'Billed',
          field: 'billed_count',
          aggregate: true,
          width: 140,
          type: 'number',
          count_field: 'billed_count',
          render: CountCellRender,
        },
        {
          header: 'Unbillable',
          children: [
            {
              header: 'Total',
              width: 140,
              field: 'unbilled_count',
              aggregate: true,
              type: 'number',
              count_field: 'unbilled_count',
              render: CountCellRender,
              onPress: ({row}) => {
                navigation.navigate('employee-analytics-ubilled-view', {
                  row: row?.unbilledTeam,
                });
              },
            },
            // {
            //   header: 'Cost',
            //   width: 100,
            // },
            {
              header: 'Available for Opp.',
              width: 140,
              field: 'available_for_opportunity',
              aggregate: true,
              type: 'number',
              count_field: 'available_for_opportunity',
              render: CountCellRender,
              onPress: ({row}) => {
                navigation.navigate('skill-wise-available-employee', {
                  employeeFilters: {
                    _id: {$in: row.available_for_opportunity_team},
                  },
                });
              },
            },
          ],
        },
      ]}
    />
  );
};

export const SkillWiseAnalytics = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    addOnFilter,
    searchValue,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <BorderedGroupTable
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="name"
      api={`/employees/skillwiseAnalytics`}
      variant={'bordered'}
      groupRow={{
        data: '_children.data._children',
        aggregate: '_children.aggregates',
        header: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text style={{...styles}}>{row?.names}</Text>
              <Text style={{...styles}}>
                {row?._children?.aggregates?.unbilled_count?.toFixed(0)}{' '}
                Unbillable
              </Text>
              <Text style={{...styles}}>
                {row?._children?.aggregates?.available_for_opportunity?.toFixed(
                  0,
                )}{' '}
                Available For Opp.
              </Text>
              <Text style={{...styles}}>
                {row?._children?.aggregates?.avgTeamExperienceAvailableOpportunity?.toFixed(
                  1,
                )}{' '}
                Avg. Experience
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Skill',
          width: 140,
          render: ({row}) => {
            const {name = '', color = ''} = row;
            return <Tag value={name} color={color} />;
          },
        },
        {
          header: 'Total',
          field: 'total_count',
          type: 'number',
          aggregate: true,
          width: 80,
          onPress: ({row}) => {
            const employeeIds = row?._children.map(item => item._id) || [
              'xxxx',
            ];
            navigation.navigate(`employee-workload`, {
              employeeFilter: {_id: {$in: employeeIds}},
            });
          },
          render: props => {
            const {row, styles: {rowText = {}} = {}} = props;
            const newProps = {
              ...props,
              count_field: 'total_count',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <Text
                  style={{
                    ...rowText,
                    color: '#A3A3A3',
                    fontSize: 13,
                    marginTop: 4,
                  }}>
                  {row?.avgTeamExperience && `${row?.avgTeamExperience} yr`}
                </Text>
              </>
            );
          },
        },
        {
          header: 'Unbilled',
          field: 'unbilled_count',
          type: 'number',
          aggregate: true,
          width: 220,
          onPress: ({row}) => {
            row.unbilledTeam?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {
                    $in: row.unbilledTeam,
                  },
                },
              });
          },
          render: props => {
            const {row = {}} = props;
            const newProps = {
              ...props,
              count_field: 'unbilled_count',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {value: 'Bench Avg: ' + `${row?.unbilledPercentage} %`},
                    {value: 'Exp: ' + `${row?.avgUnbilledExperience} yr`},
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Available',
          field: 'available_for_opportunity',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.available_for_opportunity_team?.length &&
              navigation.navigate('skill-wise-available-employee', {
                employeeFilters: {
                  _id: {
                    $in: row.available_for_opportunity_team,
                  },
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'available_for_opportunity',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {value: 'Bench Avg: ' + `${row?.availablePercentage} %`},
                    {
                      value:
                        'Exp: ' +
                        `${row?.avgAvailableExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'To Be Free',
          field: 'toBeFreeCount',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.toBeFreeTeamIds?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {
                    $in: row.toBeFreeTeamIds,
                  },
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'toBeFreeCount',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {
                      value:
                        'Bench Avg: ' +
                        `${row?.toBeFreePercentage.toFixed(1)} %`,
                    },
                    {
                      value:
                        'Exp: ' +
                        `${row?.toBeAvgFreeExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Planned Exit',
          field: 'plannedExitCount',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.plannedExitTeamIds?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {
                    $in: row.plannedExitTeamIds,
                  },
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'plannedExitCount',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {
                      value:
                        'Bench Avg: ' +
                        `${row?.plannedExitPercentage.toFixed(1)} %`,
                    },
                    {
                      value:
                        'Exp: ' +
                        `${row?.plannedExitExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Secondary Skill',
          field: 'secondarySkillCount',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.secondarySkillIds?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {
                    $in: row.secondarySkillIds,
                  },
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'secondarySkillCount',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {
                      value:
                        'Bench Avg: ' +
                        `${row?.secondarySkillPercentage.toFixed(1)} %`,
                    },
                    {
                      value:
                        'Exp: ' +
                        `${row?.secondarySkillExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Projected Unbilled',
          field: 'projectedUnBilledCount',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.projectedUnBilledTeamIds?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {
                    $in: row.projectedUnBilledTeamIds,
                  },
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'projectedUnBilledCount',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {
                      value:
                        'Bench Avg: ' +
                        `${row?.projectedUnBilledPercentage.toFixed(1)} %`,
                    },
                    {
                      value:
                        'Exp: ' +
                        `${row?.projectedUnBilledExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
        {
          header: 'Projected Available',
          field: 'projectedAvailableCount',
          aggregate: true,
          type: 'number',
          width: 220,
          onPress: ({row}) => {
            row.projectedAvailableTeamIds?.length &&
              navigation.navigate('employee-workload', {
                employeeFilter: {
                  _id: {$in: row.projectedAvailableTeamIds},
                },
              });
          },
          render: props => {
            const {row} = ({} = props);
            const newProps = {
              ...props,
              count_field: 'projectedAvailableCount',
            };
            return (
              <>
                <CountCellRender {...newProps} />
                <PrimaryColumnCard
                  items={[
                    {
                      value:
                        'Bench Avg: ' +
                        `${row?.projectedAvailablePercentage.toFixed(1)} %`,
                    },
                    {
                      value:
                        'Exp: ' +
                        `${row?.projectedAvailableExperience?.toFixed(1)} yr`,
                    },
                  ]}
                />
              </>
            );
          },
        },
      ]}
    />
  );
};

export const RatingWiseAnalytics = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    searchValue,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      search={searchValue}
      searchFields="name"
      api={`/employees/engg-analytics/ratingwise`}
      variant={'bordered'}
      columns={[
        {
          header: 'Rating',
          render: ({row}) => {
            const {name} = row;
            const colors = useTheme('colors');
            if (name) {
              if (name == 'A+') {
                return (
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'CE'}
                    borderRadius={6}
                  />
                );
              }
              if (name == 'A') {
                return (
                  <Chip
                    // {...rest}
                    color={colors.ACCENT6_LOW}
                    textColor={colors.ACCENT6_HIGH}
                    value={'OE'}
                    borderRadius={6}
                  />
                );
              }
              if (name == 'B+') {
                return (
                  <Chip
                    // {...rest}
                    color={colors.ACCENT1_LOW}
                    textColor={colors.ACCENT1_HIGH}
                    value={'ME'}
                    borderRadius={6}
                  />
                );
              }

              if (name == 'B') {
                return (
                  <Chip
                    // {...rest}
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'PE'}
                    borderRadius={6}
                  />
                );
              }
              if (name == 'C') {
                return (
                  <Chip
                    // {...rest}
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'DE'}
                    borderRadius={6}
                  />
                );
              }
            }
          },
          width: 240,
        },
        {
          header: 'Total',
          field: 'total_count',
          type: 'number',
          count_field: 'total_count',
          render: CountCellRender,
          aggregate: true,
          width: 240,
          onPress: ({row}) => {
            const employeeIds = row?._children.map(item => item._id) || [
              'xxxx',
            ];
            navigation.navigate(`employee-workload`, {
              employeeFilter: {_id: {$in: employeeIds}},
            });
          },
        },
        {
          header: 'Billed',
          field: 'billed_count',
          aggregate: true,
          width: 240,
          type: 'number',
          count_field: 'billed_count',
          render: CountCellRender,
        },
        {
          header: 'Unbillable',
          children: [
            {
              header: 'Total',
              width: 180,
              field: 'unbilled_count',
              aggregate: true,
              type: 'number',
              count_field: 'unbilled_count',
              render: CountCellRender,
              onPress: ({row}) => {
                navigation.navigate('employee-analytics-ubilled-view', {
                  row: row?.unbilledTeam,
                });
              },
            },
            {
              header: 'Available for Opp.',
              field: 'available_for_opportunity',
              count_field: 'available_for_opportunity',
              aggregate: true,
              render: CountCellRender,
              align: 'center',
              width: 150,
              onPress: ({row}) => {
                navigation.navigate('skill-wise-available-employee', {
                  employeeFilters: {
                    _id: {$in: row.available_for_opportunity_team},
                  },
                });
              },
            },
          ],
        },
      ]}
    />
  );
};
