import React, {useEffect, useState} from 'react';
import {useToast} from '@unthinkable/react-toast';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {TabView} from '../../../components/tab/TabView';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
  UserAvatarForParichayWithNameDOB,
} from '../../reimbursement/components/ReimbursementColumnRenders';

import {AddButton} from '../../../components/button/AddButton';
import {
  ActivityIndicator,
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {employeeController} from '../controllers/employeeController';
import {useAppStateContext} from '../../../providers/AppState';

const ConsentDoc = resourceUrl => ({
  header: 'Consent',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.consent_document && row?.consent_document != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.consent_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const MaternityDoc = resourceUrl => ({
  header: 'Maternity',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.maternity_document && row?.maternity_document != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.maternity_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});
const PFDoc = resourceUrl => ({
  header: 'PF',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.pf_document && row?.pf_document != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.pf_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const EPFForm2Doc = resourceUrl => ({
  header: 'EPF form 2',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.epf_form_2 && row?.epf_form_2 != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.epf_form_2);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const EPFForm11Doc = resourceUrl => ({
  header: 'EPF form 2',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.epf_form_11 && row?.epf_form_11 != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.epf_form_11);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const Gratuity = resourceUrl => ({
  header: 'Gratuity',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.gratuity_document && row?.gratuity_document != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.gratuity_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const Nominee = resourceUrl => ({
  header: 'Nominee',
  align: 'center',

  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (
      row?.nominee_delaration_document &&
      row?.nominee_delaration_document != null
    ) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.nominee_delaration_document);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
});

const RenderCreateEmployee = ({row = {}, styles = {}}) => {
  const [loading] = useState(false);
  const colors = useTheme('colors');

  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}>
            <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
              Create employee
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

export const EmployeeParichayList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  const colors = useTheme('colors');
  const icon = useTheme('icons');

  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);
  const {createEmployee, createEmployeeFromParichay} =
    employeeController(props);
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['employeeparichay']}
      searchFields={['name']}
      api={'/employeeParichays'} //query on backend
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarForParichayWithNameDOB //??
                name={row?.full_name}
                color={row?.color}
                dob={row?.dob}
              />
            );
          },
          width: 280,
        },
        {
          header: 'Gender',
          field: 'gender',
          type: 'text',
          width: 200,
        },
        {
          header: 'Email',
          field: 'email_id',
        },
        {
          field: 'mobile_number',
          align: 'center',
          // width: 60,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.mobile_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
        {
          header: 'Actions',
          align: 'right',
          render: ({row, styles}) => (
            <RenderCreateEmployee row={row} styles={styles} />
          ),
          onPress: createEmployeeFromParichay,
          // width: 80,
        },

        // {
        //   header: 'Marital Status',
        //   field: 'marital_status',
        //   type: 'text',
        // },
        // {
        //   header: 'Nationality',
        //   field: 'nationality.name',
        //   type: 'text',
        // },
      ]}
      moreActions={() => [
        {
          title: 'Parichay Details',
          onPress: ({row}) => {
            navigation.navigate('edit-employee-parichay-form', {
              parichayId: row?._id,
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeParichayDocList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  const {resourceUrl, download} = useAppStateContext();

  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={['employeeparichay']}
      searchFields={['name']}
      api={'/parichaymaildocuments'} //query on backend
      columns={[
        ConsentDoc(resourceUrl),
        MaternityDoc(resourceUrl),
        PFDoc(resourceUrl),
        EPFForm2Doc(resourceUrl),
        EPFForm11Doc(resourceUrl),
        Gratuity(resourceUrl),
        Nominee(resourceUrl),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-employee-parichay-documents', {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeParichayTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.all = {
    label: 'Employee Parichay',
    api: '/employeeparichays',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeParichayList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <AddButton
        title="Employee Parichay"
        view="add-employee-parichay-form-for-hr"
        params={props?.route?.params}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};

export const EmployeeParichayDocTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.all = {
    label: 'Employee Parichay Documents',
    api: '/parichaymaildocuments',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeParichayDocList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
  };

  return <TabView tabs={tabs} {...props} />;
};
