import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useFetchFormData} from '../../../controllers/useFetchData';

export const AssetRegisterForm = props => {
  const {
    route: {params} = {},
    header = 'Asset registration',
    readOnly = false,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetRegister',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      // readOnly={readOnly}
      isDualMode={true}
      fields={{
        asset_type: 1,
        purchase_category: {_id: 1, name: 1},
        location: {_id: 1, name: 1},
        asset_documents: 1,
        date: 1,
        code: 1,
        serial_no: 1,
        imei_number: 1,
        brand: 1,
        model: 1,
        specification: 1,
        wlan_mac: 1,
        mac_address: 1,
        asset_os_type: 1,
        bar_code: 1,
        warranty_valid: 1,
        warranty_due_date: 1,
        is_asset_insured: 1,
        asset_insured_on: 1,
        policy_number: 1,
        insurance_company: 1,
        insurance_expiry_date: 1,
        payee_id: {id: 1, name: 1},
        current_amount: 1,
        license_key: 1,
      }}
      // beforeSubmit={({data}) => {
      //   return {data: {...data, status: 'Unassigned', asset_status: 'Active'}};
      // }}
      onSubmit={onSubmit}
      header={header}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Asset details',
          fields: [
            {
              label: 'Asset type',
              field: 'asset_type',
              type: 'autoComplete',
              options: ['WIP', 'Ready To Use', 'Asset Inventory'],
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Vendor',
              field: 'payee_id',
              type: 'autoComplete',
              api: '/vendors',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Purchase category',
              field: 'purchase_category',
              type: 'autoComplete',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Location',
              field: 'location',
              type: 'autoComplete',
              api: '/offices',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Asset purchase date',
              field: 'date',
              type: 'date',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'current_amount',
              size: 4,
              readOnly: true,
            },

            {
              label: 'Assets photos/docs.',
              field: 'asset_documents',
              type: 'file',
              readOnly: true,
              // size: 6,
            },
          ],
        },
        {
          label: 'Specifications',
          fields: [
            {
              label: 'Code',
              field: 'code',
              type: 'text',
              readOnly: true,
              size: 4,
            },
            {
              label: 'Serial number',
              field: 'serial_no',
              type: 'text',
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'IMEI number',
              field: 'imei_number',
              type: 'text',
              readOnly: true,
              size: 4,
            },
            {
              label: 'Brand',
              field: 'brand',
              type: 'text',
              readOnly: true,
              required: true,
              size: 4,
            },
            {
              label: 'Model',
              field: 'model',
              type: 'text',
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Specification',
              field: 'specification',
              type: 'text',
              readOnly: true,
              size: 4,
            },
            {
              label: 'WLAN MAC',
              field: 'wlan_mac',
              type: 'text',
              // readOnly: true,
              size: 3,
            },
            {
              label: 'MAC Address',
              field: 'mac_address',
              type: 'text',
              // readOnly: true,
              size: 3,
            },
            {
              label: 'OS Type',
              field: 'asset_os_type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Windows', value: 'Windows'},
                {label: 'Ubuntu', value: 'Ubuntu'},
                {label: 'Others', value: 'Others'},
              ],
              type: 'autoComplete',
              // readOnly: true,
              size: 3,
            },
            {
              label: 'License key',
              field: 'license_key',
              type: 'text',
              size: 3,
            },
            {
              placeholder: 'Bar Code',
              field: 'bar_code',
              type: 'file',
              // readOnly: true,
              size: 12,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Warranty',
              placeholder: 'Warranty',
              type: 'checkbox',
              field: 'warranty_valid',
            },
            {
              label: 'Warranty due Date',
              placeholder: 'Warranty due Date',
              type: 'date',
              field: 'warranty_due_date',
              visible: ({values}) => values?.warranty_valid,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Insured',
              placeholder: 'Insured',
              type: 'checkbox',
              field: 'is_asset_insured',
              // required: true,
            },
            {
              label: 'Insured on',
              placeholder: 'Insured on',
              type: 'date',
              field: 'asset_insured_on',
              visible: ({values}) => values?.is_asset_insured,
              // required: true,
              size: 3,
            },
            {
              label: 'Policy no.',
              placeholder: 'Policy no.',
              type: 'text',
              field: 'policy_number',
              visible: ({values}) => values?.is_asset_insured,
              // required: true,
              size: 3,
            },
            {
              label: 'Insurance company',
              placeholder: 'Insurance company',
              type: 'text',
              field: 'insurance_company',
              visible: ({values}) => values?.is_asset_insured,
              // required: true,
              size: 3,
            },
            {
              label: 'Insurance expiry/renewal date',
              placeholder: 'Insurance expiry/renewal date',
              type: 'date',
              field: 'insurance_expiry_date',
              visible: ({values}) => values?.is_asset_insured,
              // required: true,
              size: 3,
            },
          ],
        },
      ]}
    />
  );
};

export const EditableAssetRegisterForm = props => {
  const {
    route: {params} = {},
    header = 'Asset registration',
    readOnly = false,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetRegister',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      isDualMode={true}
      fields={{
        asset_type: 1,
        purchase_category: {_id: 1, name: 1},
        location: {_id: 1, name: 1},
        asset_documents: 1,
        date: 1,
        code: 1,
        serial_no: 1,
        imei_number: 1,
        brand: 1,
        model: 1,
        specification: 1,
        wlan_mac: 1,
        mac_address: 1,
        asset_os_type: 1,
        bar_code: 1,
        warranty_valid: 1,
        warranty_due_date: 1,
        is_asset_insured: 1,
        asset_insured_on: 1,
        policy_number: 1,
        insurance_company: 1,
        insurance_expiry_date: 1,
        payee_id: {id: 1, name: 1},
        current_amount: 1,
        license_key: 1,
      }}
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'Unassigned', asset_status: 'Active'}};
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Asset details',
          fields: [
            {
              label: 'Asset type',
              field: 'asset_type',
              type: 'autoComplete',
              options: ['WIP', 'Ready To Use', 'Asset Inventory'],
              required: true,
              size: 4,
            },
            {
              label: 'Vendor',
              field: 'payee_id',
              type: 'autoComplete',
              api: '/vendors',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              required: true,
              size: 4,
            },
            {
              label: 'Purchase category',
              field: 'purchase_category',
              type: 'autoComplete',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              size: 4,
              required: true,
            },
            {
              label: 'Location',
              field: 'location',
              type: 'autoComplete',
              api: '/offices',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              size: 4,
              required: true,
            },
            {
              label: 'Asset purchase date',
              field: 'date',
              type: 'date',
              size: 4,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'current_amount',
              size: 4,
            },

            {
              label: 'Assets photos/docs.',
              field: 'asset_documents',
              type: 'file',
              // size: 6,
            },
          ],
        },
        {
          label: 'Specifications',
          fields: [
            {
              label: 'Code',
              field: 'code',
              type: 'text',

              size: 4,
            },
            {
              label: 'Serial number',
              field: 'serial_no',
              type: 'text',
              required: true,

              size: 4,
            },
            {
              label: 'IMEI number',
              field: 'imei_number',
              type: 'text',

              size: 4,
            },
            {
              label: 'Brand',
              field: 'brand',
              type: 'text',

              required: true,
              size: 4,
            },
            {
              label: 'Model',
              field: 'model',
              type: 'text',
              required: true,

              size: 4,
            },
            {
              label: 'Specification',
              field: 'specification',
              type: 'text',

              size: 4,
            },
            {
              label: 'WLAN MAC',
              field: 'wlan_mac',
              type: 'text',
              size: 3,
            },
            {
              label: 'MAC Address',
              field: 'mac_address',
              type: 'text',
              size: 3,
            },
            {
              label: 'OS Type',
              field: 'asset_os_type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Windows', value: 'Windows'},
                {label: 'Ubuntu', value: 'Ubuntu'},
                {label: 'Others', value: 'Others'},
              ],
              type: 'autoComplete',
              // readOnly: true,
              size: 3,
            },
            {
              label: 'License key',
              field: 'license_key',
              type: 'text',
              size: 3,
            },
            {
              placeholder: 'Bar Code',
              field: 'bar_code',
              type: 'file',

              size: 12,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Warranty',
              placeholder: 'Warranty',
              type: 'checkbox',
              field: 'warranty_valid',
            },
            {
              label: 'Warranty due Date',
              placeholder: 'Warranty due Date',
              type: 'date',
              field: 'warranty_due_date',
              visible: ({values}) => values?.warranty_valid,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Insured',
              placeholder: 'Insured',
              type: 'checkbox',
              field: 'is_asset_insured',
              required: true,
            },
            {
              label: 'Insured on',
              placeholder: 'Insured on',
              type: 'date',
              field: 'asset_insured_on',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Policy no.',
              placeholder: 'Policy no.',
              type: 'text',
              field: 'policy_number',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance company',
              placeholder: 'Insurance company',
              type: 'text',
              field: 'insurance_company',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance expiry/renewal date',
              placeholder: 'Insurance expiry/renewal date',
              type: 'date',
              field: 'insurance_expiry_date',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
          ],
        },
      ]}
    />
  );
};

export const EditableAssetRegisterFormIt = props => {
  const {
    route: {params} = {},
    header = 'Asset registration',
    readOnly = false,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetRegister',
  });
  return (
    <Form
      api={`/assetinventories/${row?._id}`}
      mode="edit"
      isDualMode={true}
      fields={{
        asset_type: 1,
        purchase_category: {_id: 1, name: 1},
        location: {_id: 1, name: 1},
        asset_documents: 1,
        date: 1,
        code: 1,
        serial_no: 1,
        imei_number: 1,
        brand: 1,
        model: 1,
        specification: 1,
        wlan_mac: 1,
        mac_address: 1,
        asset_os_type: 1,
        bar_code: 1,
        warranty_valid: 1,
        warranty_due_date: 1,
        is_asset_insured: 1,
        asset_insured_on: 1,
        policy_number: 1,
        insurance_company: 1,
        insurance_expiry_date: 1,
        payee_id: {id: 1, name: 1},
        current_amount: 1,
        license_key: 1,
      }}
      beforeSubmit={({data}) => {
        return {data: {...data, status: 'Unassigned', asset_status: 'Active'}};
      }}
      onSubmit={onSubmit}
      header={header}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          label: 'Asset details',
          fields: [
            {
              label: 'Asset type',
              field: 'asset_type',
              type: 'autoComplete',
              options: ['WIP', 'Ready To Use', 'Asset Inventory'],
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Vendor',
              field: 'payee_id',
              type: 'autoComplete',
              api: '/vendors',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Purchase category',
              field: 'purchase_category',
              type: 'autoComplete',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              // required: true,
              size: 4,
              required: true,
              readOnly: true,
            },
            {
              label: 'Location',
              field: 'location',
              type: 'autoComplete',
              api: '/offices',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              size: 4,
              required: true,
              readOnly: true,
            },
            {
              label: 'Asset purchase date',
              field: 'date',
              type: 'date',
              size: 4,
              readOnly: true,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'current_amount',
              size: 4,
              readOnly: true,
            },

            {
              label: 'Assets photos/docs.',
              field: 'asset_documents',
              type: 'file',
              readOnly: true,
              // size: 6,
            },
          ],
        },
        {
          label: 'Specifications',
          fields: [
            {
              label: 'Code',
              field: 'code',
              type: 'text',
              readOnly: true,

              size: 4,
            },
            {
              label: 'Serial number',
              field: 'serial_no',
              type: 'text',
              required: true,

              size: 4,
            },
            {
              label: 'IMEI number',
              field: 'imei_number',
              type: 'text',

              size: 4,
            },
            {
              label: 'Brand',
              field: 'brand',
              type: 'text',
              readOnly: true,
              required: true,
              size: 4,
            },
            {
              label: 'Model',
              field: 'model',
              type: 'text',
              required: true,
              readOnly: true,
              size: 4,
            },
            {
              label: 'Specification',
              field: 'specification',
              type: 'text',
              readOnly: true,

              size: 4,
            },
            {
              label: 'WLAN MAC',
              field: 'wlan_mac',
              type: 'text',
              size: 3,
            },
            {
              label: 'MAC Address',
              field: 'mac_address',
              type: 'text',
              size: 3,
            },
            {
              label: 'OS Type',
              field: 'asset_os_type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Windows', value: 'Windows'},
                {label: 'Ubuntu', value: 'Ubuntu'},
                {label: 'Others', value: 'Others'},
              ],
              type: 'autoComplete',
              // readOnly: true,
              size: 3,
            },
            {
              label: 'License key',
              field: 'license_key',
              type: 'text',
              size: 3,
            },
            {
              placeholder: 'Bar Code',
              field: 'bar_code',
              type: 'file',
              readOnly: true,
              size: 12,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Warranty',
              placeholder: 'Warranty',
              type: 'checkbox',
              field: 'warranty_valid',
            },
            {
              label: 'Warranty due Date',
              placeholder: 'Warranty due Date',
              type: 'date',
              field: 'warranty_due_date',
              visible: ({values}) => values?.warranty_valid,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Insured',
              placeholder: 'Insured',
              type: 'checkbox',
              field: 'is_asset_insured',
              required: true,
            },
            {
              label: 'Insured on',
              placeholder: 'Insured on',
              type: 'date',
              field: 'asset_insured_on',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Policy no.',
              placeholder: 'Policy no.',
              type: 'text',
              field: 'policy_number',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance company',
              placeholder: 'Insurance company',
              type: 'text',
              field: 'insurance_company',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance expiry/renewal date',
              placeholder: 'Insurance expiry/renewal date',
              type: 'date',
              field: 'insurance_expiry_date',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
          ],
        },
      ]}
    />
  );
};

export const SubAssetRegisterForm = props => {
  const {
    route: {params} = {},
    header = 'Asset registration',
    readOnly = false,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/assetinventories',
    eventSourceId: 'AssetRegister',
  });
  console.log('row>>>>>>>..', row);
  const {data} = useFetchFormData({
    api: '/assetinventories',
    filter: {
      _id: row?._id,
    },
    fields: {
      date: 1,
      warranty_valid: 1,
      asset_type: 1,
      warranty_due_date: 1,
      asset_documents: 1,
      is_asset_insured: 1,
      asset_insured_on: 1,
      policy_number: 1,
      insurance_company: 1,
      insurance_expiry_date: 1,
      capitalization_date: 1,
      payee_id: 1,
      quantity: 1,
      amount: 1,
      tax_amount: 1,
      total_amount: 1,
      current_amount: 1,
      income_tax_current_amount: 1,
      location: 1,
      account_id: 1,
      invoice_no: 1,
      organization: 1,
      vendor_invoice: 1,
      itemid: 1,
      product: 1,
      team: 1,
      description: 1,
      brand: 1,
      model: 1,
      serial_no: 1,
      type: 1,
      purchase_category: 1,
      asset_status: 1,
      is_verified: 1,
      code: 1,
      status: 1,
    },
  });
  if (!data) {
    return null;
  }
  return (
    <Form
      // api={`/assetinventories/${row?._id}`}
      // mode="edit"
      // readOnly={readOnly}
      // isDualMode={true}
      defaultValues={{
        parent_asset: row?._id,
        taged_with: row?._id,
        date: data?.date,
        warranty_due_date: data?.warranty_due_date,
        asset_documents: data?.asset_documents,
        is_asset_insured: data?.is_asset_insured,
        asset_insured_on: data?.asset_insured_on,
        policy_number: data?.policy_number,
        insurance_company: data?.insurance_company,
        insurance_expiry_date: data?.insurance_expiry_date,
        capitalization_date: data?.capitalization_date,
        payee_id: data?.payee_id,
        quantity: data?.quantity,
        amount: data?.amount,
        tax_amount: data?.tax_amount,
        total_amount: data?.total_amount,
        income_tax_current_amount: data?.income_tax_current_amount,
        location: data?.location,
        account_id: data?.account_id,
        invoice_no: data?.invoice_no,
        organization: data?.organization,
        vendor_invoice: data?.vendor_invoice,
        itemid: data?.itemid,
        product: data?.product,
        team: data?.team,
        description: data?.description,
        brand: data?.brand,
        model: data?.model,
        serial_no: data?.serial_no,
        purchase_category: data?.purchase_category,
        asset_status: data?.asset_status,
        is_verified: data?.is_verified,
        status: data?.status,
      }}
      // beforeSubmit={({data}) => {
      //   return {data: {...data, status: 'Unassigned', asset_status: 'Active'}};
      // }}
      onSubmit={onSubmit}
      header={header}
      submitAction="Save"
      eventSourceId="asset"
      layoutFields={[
        {
          field: 'type',
          type: 'autoComplete',
          label: 'Type',
          api: '/assetmastercategories',
          valueField: 'name',
          suggestionField: 'name',
          onChangeValue: (value, e, {setFieldValue}) => {
            console.log('value>>>>>>>>..', value);
            setFieldValue('code', `${data?.code}/${value?.name?.[0]}`);
          },
        },
      ]}
    />
  );
};

export const EditAssetRegisterForm = props => {
  return (
    <AssetRegisterForm
      header="Edit Asset registration"
      mode="edit"
      {...props}
    />
  );
};

export const DetailAssetRegisterForm = props => {
  return (
    <AssetRegisterForm
      header="Detail Asset registration"
      mode="edit"
      readOnly={true}
      {...props}
    />
  );
};

export const NewAssetRegisterFormForIt = props => {
  return (
    <EditableAssetRegisterFormIt
      header="Asset registration details"
      {...props}
    />
  );
};

export const AddSubAssetForm = props => {
  return (
    <SubAssetRegisterForm
      header="Sub Asset"
      // mode="edit"
      // readOnly={true}
      {...props}
    />
  );
};

export const NewAssetRegisterFormForPi = props => {
  return (
    <EditableAssetRegisterForm header="Asset registration details" {...props} />
  );
};
