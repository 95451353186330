import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable, Table} from '../../../components/table/Table';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {
  Col,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  Image,
  View,
} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import moment from 'moment';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Chip} from '../../../components/chip/Chip';

const createMilestone = ({navigation, params}) => ({
  title: 'Request',
  onPress: props => {
    const {row} = props;
    navigation.navigate('edit-milestone', {
      row: row?.order,
    });
  },
});

export const ProjectOrderMappings = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {project, formTAndM} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      api={`/projectordermaps`}
      fields={{
        start_date: 1,
        end_date: 1,
        consume: 1,
        profile: 1,
        hours: 1,
        project_plan_status: 1,
        order: {
          _id: 1,
          order_number: 1,
          order_end_date: 1,
          order_date: 1,
          est_order_hrs: 1,
        },
        project: {project: 1},
      }}
      renderHeader={() => (
        <>
          <Col>
            <TableHeader title={'Order Mappings'} />
          </Col>
        </>
      )}
      filter={{
        project: project?._id,
      }}
      eventSourceId={'projectordersmaps'}
      columns={[
        {
          header: 'Order',
          field: 'order.order_number',
          type: 'text',
          // width: 250,
        },
        {
          type: 'date',
          field: 'start_date',
          header: 'Start Date',
          width: 250,
        },
        {
          type: 'date',
          field: 'end_date',
          header: 'End Date',
          //   width: 250,
        },
        !formTAndM && {
          type: 'text',
          field: 'project_plan_status',
          header: 'Project Plan Status',
          width: 200,
          align: 'right',
          render: ({row}) => {
            if (row?.project_plan_status == 'Approval Pending') {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('edit-milestone', {
                        // row: row?.order,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      displayTextColor
                      color={'INFORMATION'}
                      value={'Approval Pending'}
                    />
                  </TouchableOpacity>
                </>
              );
            }
            if (row?.project_plan_status == 'Submission Pending') {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('edit-milestone', {
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={'Outline'}
                      textColor={'NEUTRAL_LOW'}
                      value={'Submission Pending'}
                    />
                  </TouchableOpacity>
                </>
              );
            }
            if (row?.project_plan_status == 'Rejected') {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('edit-milestone', {
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      displayTextColor
                      color={'ERROR'}
                      value={'Rejected'}
                    />
                  </TouchableOpacity>
                </>
              );
            }
            if (row?.project_plan_status == 'Approved') {
              return (
                <>
                  <Chip
                    // {...rest}
                    displayTextColor
                    color={'SUCCESS'}
                    value={'Approved'}
                  />
                </>
              );
            }
          },
        },
      ]}
      // moreActions={() => [createMilestone({navigation, params})]}
    />
  );
};

export const ProjectOrderMappingsForAm = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {project} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <Table
      api={`/projectordermaps`}
      eventSourceId={['project-report']}
      fields={{
        start_date: 1,
        end_date: 1,
        consume: 1,
        profile: 1,
        hours: 1,
        project_plan_status: 1,
        order: {
          _id: 1,
          order_number: 1,
          order_end_date: 1,
          order_date: 1,
          est_order_hrs: 1,
        },
        project: {project: 1},
      }}
      renderHeader={() => (
        <>
          <Col>
            <TableHeader title={'Order Mappings'} />
          </Col>
        </>
      )}
      filter={{
        project: project?._id,
      }}
      columns={[
        {
          header: 'Order',
          field: 'order.order_number',
          type: 'text',
          // width: 250,
        },
        {
          type: 'date',
          field: 'start_date',
          header: 'Start Date',
          width: 250,
        },
        {
          type: 'date',
          field: 'end_date',
          header: 'End Date',
          //   width: 250,
        },
        {
          type: 'text',
          field: 'project_plan_status',
          header: 'Project Plan Status',
          width: 200,
          align: 'right',
          render: ({row}) => {
            if (row?.project_plan_status == 'Approval Pending') {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('approve-milestone', {
                        // row: row?.order,
                        row,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      displayTextColor
                      color={'INFORMATION'}
                      value={'Approval Pending'}
                    />
                  </TouchableOpacity>
                </>
              );
            }
            if (row?.project_plan_status == 'Submission Pending') {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('edit-milestone', {
                        row: row?.order,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      color={'Outline'}
                      textColor={'NEUTRAL_LOW'}
                      value={'Submission Pending'}
                    />
                  </TouchableOpacity>
                </>
              );
            }
            if (row?.project_plan_status == 'Rejected') {
              return (
                <>
                  <Chip
                    // {...rest}
                    displayTextColor
                    color={'ERROR'}
                    value={'Rejected'}
                  />
                  {/* <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('edit-milestone', {
                        row: row?.order,
                      });
                    }}>
                    <Chip
                      // {...rest}
                      displayTextColor
                      color={'NEUTRAL'}
                      value={'Submission Pending'}
                    />
                  </TouchableOpacity> */}
                </>
              );
            }
            if (row?.project_plan_status == 'Approved') {
              return (
                <>
                  <Chip
                    // {...rest}
                    displayTextColor
                    color={'SUCCESS'}
                    value={'Approved'}
                  />
                </>
              );
            }
          },
        },
      ]}
      // moreActions={() => [createMilestone({navigation, params})]}
    />
  );
};

export const ProjectPlanDetailList = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {project} = params;
  const {resourceUrl} = useAppStateContext();
  return (
    <GroupTable
      api={`/projectPlanningDetails`}
      renderHeader={() => (
        <>
          <Col>
            <TableHeader title={'Project Plan Details'} />
          </Col>
        </>
      )}
      filter={{
        project: project?._id,
      }}
      groupRow={{
        data: 'order_milestone',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.order_number} />
              <GroupContentItem
                value={`Start: ${moment(row?.order_date).format('DD MMM YY')}`}
              />
              <GroupContentItem
                value={`End: ${moment(row?.order_end_date).format(
                  'DD MMM YY',
                )}`}
              />
              <GroupContentItem value={row.est_order_hrs} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'M.No.',
          field: 'order_milestone_no',
          type: 'colorTag',
          width: 160,
        },
        {
          header: 'Description',
          field: 'description',
        },
        {
          header: 'Start Date',
          field: 'start_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
          width: 80,
          align: 'center',
        },
        {
          header: 'End Date',
          field: 'end_date',
          formatOptions: {format: 'DD MMM YY'},
          type: 'date',
          width: 80,
          align: 'center',
        },
        {
          header: 'Est. Hrs.',
          field: 'est_man_hour',
          type: 'duration',
          width: 80,
          suffix: 'Hrs',
        },
      ]}
    />
  );
};
