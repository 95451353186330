export const LeftMenuViewStyles = ({
  theme: {icons, colors, fonts, shadows},
}) => {
  return {
    toggleIcon: icons.ExpandCollapse,
    sm: {
      viewContainer: {
        backgroundColor: colors.BACKGROUND,
        marginTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    md: {
      menuBarWidth: 250,
      collapsedMenuBarWidth: 30,
      viewContainer: {
        backgroundColor: colors.BACKGROUND,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
        borderLeftWidth: 1,
        borderLeftColor: colors.OUTLINE,
      },
      collapseView: {
        backgroundColor: colors.SURFACE3,
      },
    },
    menuBarContainer: {
      backgroundColor: 'transparent',
    },
    menuBarScrollView: {
      padding: 8,
    },
    menuItemContainer: {
      borderRadius: 6,
      paddingLeft: 8,
      paddingRight: 8,
    },
    menuItemContainerHoverColor: colors.SURFACE2,
    selectedMenuItemContainer: {
      backgroundColor: colors.OUTLINE,
    },
    menuDot: {
      backgroundColor: colors.OUTLINE,
    },
    selectedMenuDot: {
      backgroundColor: colors.NEUTRAL_MEDIUM,
    },
    menuDotHoverColor: colors.NEUTRAL_MEDIUM,
    menuItemRowContainer: {
      height: 36,
      gap: 8,
      alignItems: 'center',
    },
    menuItemLabel: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
    selectedMenuItemLabel: {
      color: colors.NEUTRAL_HIGH,
    },
    countContainer: {},
    countText: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.CAPTION_LARGE,
    },
    groupContainer: {
      borderTopWidth: 1,
      borderTopColor: colors.OUTLINE,
    },
    sectionContainer: {
      borderRadius: 6,
    },
    sectionMenuItemContainer: {
      marginLeft: 16,
    },
    selectedSectionContainer: {
      backgroundColor: colors.OUTLINE,
    },
    sectionItemContainerHoverColor: colors.SURFACE2,
    sectionItemContainer: {
      height: 36,
      gap: 8,
      alignItems: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 6,
    },
    sectionItemLabel: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.BODY2,
    },
    selectedSectionItemLabel: {
      color: colors.NEUTRAL_HIGH,
    },
    toggleButton: {
      ...shadows.L,
    },
  };
};

export const FloatingHelpButtonStyes = ({
  theme: {icons, colors, fonts, shadows},
}) => {
  return {
    helpIcons: icons.HelpIcons,
    helpCharacterIcons: icons.HelpCharacterIcon,
    helpGuideCharacterIcons: icons.HelpGuideCharacterIcon,
    sm: {
      floatingButtonContainer: {
        position: 'absolute',
        bottom: '0',
        right: '0',
      },
    },
    md: {
      floatingButtonContainer: {
        position: 'absolute',
        bottom: '0',
        right: '0',
      },
    },
  };
};
