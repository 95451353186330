import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const LeaveCredit = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/leavecredits',
    eventSourceId: 'leaveCredit',
  });
  return (
    <Form
      api={`/leavecredit/setup/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Employee',
          type: 'autoComplete',
          field: 'employee_id',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Month',
          field: 'month_id',
          type: 'autoComplete',
          api: '/monthtypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Year',
          field: 'year_id',
          type: 'autoComplete',
          api: '/yeartypes',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          label: 'Leave Credit Code',
          field: 'leave_credit_code',
          type: 'text',
          size: 6,
          required: true,
        },
        {
          label: 'Leave Type',
          field: 'leave_type_id',
          type: 'autoComplete',
          api: '/leavetypes',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddLeaveCreditForm = props => {
  return <LeaveCredit header="Add Leave Credit" {...props} />;
};

export const EditLeaveCreditForm = props => {
  return <LeaveCredit mode="edit" header={'Update Leave Credit'} {...props} />;
};

export const EsiCycle = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/esicycles',
    eventSourceId: 'esicycle',
  });
  return (
    <Form
      api={`/esi/cycle/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'From Date',
          field: 'from_date',
          type: 'date',
          required: true,
          size: 6,
        },
        {
          label: 'To Date',
          field: 'to_date',
          type: 'date',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddEsiCycleForm = props => {
  return <EsiCycle header={'Add Esi Cycle'} {...props} />;
};

export const EditEsiCycleForm = props => {
  return <EsiCycle mode="edit" header={'Update Esi Cycle'} {...props} />;
};

export const SalaryCycle = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/salarycycles',
    eventSourceId: 'salarycycle',
  });
  return (
    <Form
      api={`/salary/cycle/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: '/organizations',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Active From',
          field: 'active_from',
          type: 'date',
          size: 6,
          required: true,
        },
        {
          label: 'From',
          field: 'from',
          type: 'number',
          required: true,
          size: 6,
        },
        {
          label: 'To',
          field: 'to',
          type: 'number',
          size: 6,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const AddSalaryCycleForm = props => {
  return <SalaryCycle header="Add Leave Credit" {...props} />;
};

export const EditSalaryCycleForm = props => {
  return <SalaryCycle mode="edit" header={'Update Leave Credit'} {...props} />;
};
