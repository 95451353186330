import {Chip} from '@unthinkable/react-chip';
import {Col, Image, Text} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Tag} from '../../../components/chip/Chip';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {BorderedGroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {LastCommunicationRender} from '../../../utils/util';
import {objectTasksMoreAction} from '../../common/actions/ObjectWiseTasks';
import {OPPORTUNITY_TYPE_ID} from '../../common/constants/SourceConstants';
import {customDigitalPlatformOppControllers} from '../../leadGeneration/controllers/customDigitalPlatformControllers';
import {leadControllers} from '../../leadGeneration/controllers/leadControllers';
import {staffAugmentationControllers} from '../../leadGeneration/controllers/staffAugmentationControllers';

const RenderCloseDate = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.outcome_date) {
    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.outcome_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Open for: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (row?.status && (row?.status == 'Drop' || row?.status == 'Lost')) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'invalidRfq') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          Drop
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'Won') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'Partially Won') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.WARNING_HIGH,
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderOpenSince = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.creation_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.creation_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Open for: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

export const ActiveCandidateRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.active_count || 0}
      borderRadius={6}
    />
  );
};
export const AssignedCandidateRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.assigned_count || 0}
      borderRadius={6}
    />
  );
};
export const ScreeningCandidateRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.screening_count || 0}
      borderRadius={6}
    />
  );
};
export const InterviewCandidateRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.interview_count || 0}
      borderRadius={6}
    />
  );
};
export const InterviewDueCandidateRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={row?.result_due_count || 0}
      borderRadius={6}
    />
  );
};
export const LostCandidateRender = ({row, ...rest}) => {
  const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={ERROR_LOW}
      textColor={ERROR_HIGH}
      value={row?.lost_count || 0}
      borderRadius={6}
    />
  );
};

export const LostPositionRender = ({row, ...rest}) => {
  const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  if (row?.lost_position_count) {
    return (
      <Chip
        {...rest}
        color={ERROR_LOW}
        textColor={ERROR_HIGH}
        value={row?.lost_position_count}
        borderRadius={6}
      />
    );
  } else {
    return (
      <Chip
        {...rest}
        color={ERROR_LOW}
        textColor={ERROR_HIGH}
        value={0}
        borderRadius={6}
      />
    );
  }
};

export const DropCandidateRender = ({row, ...rest}) => {
  const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={WARNING_LOW}
      textColor={WARNING_HIGH}
      value={row?.drop_count || 0}
      borderRadius={6}
    />
  );
};

export const DropPositionRender = ({row, ...rest}) => {
  const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={WARNING_LOW}
      textColor={WARNING_HIGH}
      value={row?.drop_position_count || 0}
      borderRadius={6}
    />
  );
};

export const WonClosedRender = ({row, ...rest}) => {
  const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      {...rest}
      color={SUCCESS_LOW}
      textColor={SUCCESS_HIGH}
      value={row?.selected_count || 0}
      borderRadius={6}
    />
  );
};

export const CountryRender = props => {
  const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'customer_id.country.name',
          type: 'colorTag',
          colorField: 'customer_id.country.color',
        },
        {
          field: 'potential_customer.country.name',
          type: 'colorTag',
          colorField: 'potential_customer.country.color',
        },
      ]}
    />
  );
};

export const EmployeeSizeRender = props => {
  const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'customer_id.employee_count.number',
          type: 'text',
        },
        {
          field: 'potential_customer.employee_count.number',
          type: 'text',
        },
      ]}
    />
  );
};

// const CustomerRender = props => {
//   const {
//     row,
//     styles: {rowText},
//   } = props;

//   if (row?.rfr_type == 'PotentailCustomer') {
//     const underline = row?.potential_customer?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.potential_customer?.website &&
//                 Linking.openURL(
//                   row?.potential_customer?.website.startsWith('https')
//                     ? row?.potential_customer?.website
//                     : `https://${row?.potential_customer?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.potential_customer?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   } else if (row?.rfr_type == 'Customer') {
//     const underline = row?.customer_id?.website
//       ? {textDecoration: 'underline'}
//       : void 0;
//     return (
//       <>
//         <Col>
//           <TouchableOpacity
//             onPress={() => {
//               row?.customer_id?.website &&
//                 Linking.openURL(
//                   row?.customer_id?.website.startsWith('https')
//                     ? row?.customer_id?.website
//                     : `https://${row?.customer_id?.website}`,
//                   '_blank',
//                 );
//             }}>
//             <Text
//               numberOfLines={1}
//               style={{
//                 ...rowText,
//                 ...underline,
//               }}>
//               {row.customer_id?.name}
//             </Text>
//           </TouchableOpacity>
//         </Col>
//       </>
//     );
//   }
// };

const CustomerRender = props => {
  const {row} = props;
  const {CustomerOpportunity, ProspectIcon, CustomerNew} = useTheme('icons');

  if (row?.potential_customer) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.potential_customer?.name}
        icon={ProspectIcon}
        variant="secondary"
        items={[
          {
            value: row?.potential_customer?.employee_count?.number,
            backgroundColor: row?.potential_customer?.employee_count?.color,
            numberOfLines: 1,
          },
          {
            value: row?.potential_customer?.country?.name,
            backgroundColor: row?.potential_customer?.country?.color,
            numberOfLines: 1,
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  } else if (row?.customer_id) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.customer_id?.name}
        icon={CustomerNew}
        variant="secondary"
        items={[
          {
            value: row?.customer_id?.employee_count?.number,
            backgroundColor: row?.customer_id?.employee_count?.color,
            numberOfLines: 1,
            // onPress: () => {
            //   console.log(':::::::::::checking');
            // },
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
          {
            value: row?.customer_id?.country?.name,
            backgroundColor: row?.customer_id?.country?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.rfr_type == 'PotentailCustomer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.potential_customer?.lead_score}
        </Text>
      </>
    );
  } else if (row?.rfr_type == 'Customer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.customer_id?.lead_score}
        </Text>
      </>
    );
  }
};

const CUSTOMER = {
  header: 'Organisation',
  render: CustomerRender,
  width: 300,
};

const RenderSkills = props => {
  const {row} = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  const skillName = row?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return (
    <Col
      style={
        {
          // alignItems: 'center',
          // textAlign: 'center',
        }
      }>
      <Tag value={skillName} />
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
          // alignItems: 'center',
          // textAlign: 'center',
        }}>
        {`${row?.experience?.experience || ''} y`}
      </Text>
    </Col>
  );
};

// export const ActiveEngineeringOpportunitiesList = props => {
//   let {
//     route: {params},
//     filterParams,
//     navigation,
//   } = props;
//   params = {...params, ...filterParams};
//   const {
//     markEnginneringOpportunityClose,
//     showInterviewResources,
//     markOpportunityPositionLost,
//     markOpportunityPositionDrop,
//   } = leadControllers(props);
//   const {
//     activeCandidateScreen,
//     lostCandidateScreen,
//     dropCandidateScreen,
//     WonCandidateScreen,
//   } = staffAugmentationControllers(props);
//   const {addProjectionOnOpportunity, customPlatformOpportunityCommunications} =
//     customDigitalPlatformOppControllers(props);
//   return (
//     <Table
//       api="/opportunities/engineering-active"
//       eventSourceId={[
//         'CustomPlatformOpportunity',
//         'communicationAdded',
//         'StaffAugmentation',
//       ]}
//       {...props}
//       params={params}
//       limit={1000}
//       variant={'bordered'}
//       // selection={{
//       //   actions: [
//       //     {title: 'Mark Close', onPress: markOpportunityClose},
//       //     {title: 'Assign Pre Sales', onPress: assignPreSales},
//       //   ],
//       // }}
//       onRowPress={row => {
//         props.navigation.navigate('edit-staffaugmentationopportunity', row);
//       }}
//       columns={[
//         {
//           header: 'Open Since',
//           render: RenderOpenSince,
//           // align: 'right',
//           width: 120,
//         },
//         CUSTOMER,
//         {
//           header: 'Sales',
//           field: 'owner',
//           type: 'userAvatar',
//           width: 64,
//         },
//         // {
//         //   header: 'Size',
//         //   render: EmployeeSizeRender,
//         // },
//         // {
//         //   header: 'Country',
//         //   render: CountryRender,
//         // },
//         // {
//         //   header: 'Industry',

//         //   field: 'industry.name',
//         //   type: 'colorTag',
//         //   colorField: 'industry.color',
//         // },
//         {
//           header: 'Score',
//           render: leadScoreRender,
//           align: 'right',
//           width: 68,
//         },

//         {
//           header: 'Requirement',
//           children: [
//             {
//               header: 'Mode',
//               align: 'right',
//               render: ({row}) => {
//                 const {CAPTION_LARGE} = useTheme('fonts');
//                 const colors = useTheme('colors');
//                 const {work_location, process} = row;
//                 if (process == 'Direct' || process == 'Trial') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT5_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//                 if (process == 'Test & Interview' || process == 'Test') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT4_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//                 if (process == 'Interview') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT6_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//               },
//               width: 150,
//             },
//             {
//               header: 'Skill',
//               render: RenderSkills,
//               width: 110,
//             },
//             // MODE,
//             // POSITIONOPEN,
//             {
//               header: 'Count',
//               field: 'no_of_resources',
//               align: 'right',
//               width: 70,
//               aggregate: true,
//               onPress: ({row}) => {
//                 showInterviewResources({row});
//               },
//               // width: 112,
//             },
//           ],
//         },
//         {
//           header: 'Active Positions',
//           //   render: ActiveCandidateRender,
//           field: 'active_positions',
//           aggregate: true,
//           align: 'right',
//           //   onPress: ({row}) => {
//           //     showInterviewResources({row});
//           //   },
//           width: 140,
//         },
//         {
//           header: 'Candidates',
//           children: [
//             {
//               width: 64,
//               header: 'Active',
//               field: 'active_count',
//               aggregate: true,
//               render: ActiveCandidateRender,
//               align: 'right',
//               onPress: ({row}) => {
//                 activeCandidateScreen({row});
//               },
//             },
//             {
//               width: 64,
//               field: 'lost_count',
//               aggregate: true,
//               aggregate: true,
//               render: LostCandidateRender,
//               align: 'right',
//               header: 'Lost',
//               onPress: ({row}) => {
//                 lostCandidateScreen({row});
//               },
//             },
//             {
//               width: 64,
//               header: 'Drop',
//               aggregate: true,
//               field: 'drop_count',
//               render: DropCandidateRender,
//               align: 'right',
//               onPress: ({row}) => {
//                 dropCandidateScreen({row});
//               },
//             },
//           ],
//         },
//         {
//           header: 'Closed Positions',
//           children: [
//             {
//               width: 64,
//               header: 'Won',
//               aggregate: true,
//               field: 'selected_count',
//               render: WonClosedRender,
//               align: 'right',
//               onPress: ({row}) => {
//                 WonCandidateScreen({row});
//               },
//             },
//             {
//               width: 64,
//               header: 'Lost',
//               aggregate: true,
//               field: 'lost_position_count',
//               render: LostPositionRender,
//               align: 'right',
//             },
//             {
//               width: 64,
//               header: 'Drop',
//               aggregate: true,
//               field: 'drop_position_count',
//               render: DropPositionRender,
//               align: 'right',
//             },
//           ],
//         },
//         //country: 200

//         {
//           header: 'Communication',
//           onPress: ({row}) => {
//             customPlatformOpportunityCommunications({row});
//           },
//           render: LastCommunicationRender,
//           width: 132,
//         },
//       ]}
//       moreActions={() => [
//         objectTasksMoreAction({
//           navigation,
//           params,
//           titleField: 'requirement',
//           source: OPPORTUNITY_TYPE_ID,
//         }),
//         {title: 'Lost Position', onPress: markOpportunityPositionLost},
//         {title: 'Drop Position', onPress: markOpportunityPositionDrop},
//         {title: 'Mark Close', onPress: markEnginneringOpportunityClose},
//         {
//           title: 'Add Projection',
//           onPress: addProjectionOnOpportunity,
//         },
//         // {
//         //   title: 'Communications',
//         //   onPress: customPlatformOpportunityCommunications,
//         // },
//       ]}
//     />
//   );
// };

export const ActiveEngineeringOpportunitiesList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams, dataGrouped: true};
  const {
    markEnginneringOpportunityClose,
    showInterviewResources,
    markOpportunityPositionLost,
    markOpportunityPositionDrop,
  } = leadControllers(props);
  const {
    activeCandidateScreen,
    lostCandidateScreen,
    dropCandidateScreen,
    WonCandidateScreen,
  } = staffAugmentationControllers(props);
  const {addProjectionOnOpportunity, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <BorderedGroupTable
      api="/opportunities/engineering-active"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      limit={1000}
      variant={'bordered'}
      // selection={{
      //   actions: [
      //     {title: 'Mark Close', onPress: markOpportunityClose},
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.name} />
              <GroupContentItem value={`${row.no_of_resources}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Open Since',
          render: RenderOpenSince,
          // align: 'right',
          width: 120,
        },
        CUSTOMER,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 64,
        },
        // {
        //   header: 'Size',
        //   render: EmployeeSizeRender,
        // },
        // {
        //   header: 'Country',
        //   render: CountryRender,
        // },
        // {
        //   header: 'Industry',

        //   field: 'industry.name',
        //   type: 'colorTag',
        //   colorField: 'industry.color',
        // },
        {
          header: 'Score',
          render: leadScoreRender,
          align: 'right',
          width: 68,
        },
        {
          header: 'Skill & Exp',
          render: RenderSkills,
          width: 110,
        },
        {
          header: 'Mode',
          align: 'right',
          render: ({row}) => {
            const {CAPTION_LARGE} = useTheme('fonts');
            const colors = useTheme('colors');
            const {work_location, process} = row;
            if (process == 'Direct' || process == 'Trial') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT5_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Test & Interview' || process == 'Test') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT4_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Interview') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT6_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
          },
          width: 150,
        },
        {
          header: 'Positions',
          children: [
            // MODE,
            // POSITIONOPEN,
            {
              header: 'Total',
              field: 'no_of_resources',
              align: 'right',
              width: 70,
              aggregate: true,
              onPress: ({row}) => {
                showInterviewResources({row});
              },
              // width: 112,
            },
            {
              header: 'Active',
              //   render: ActiveCandidateRender,
              field: 'active_positions',
              aggregate: true,
              align: 'right',
              //   onPress: ({row}) => {
              //     showInterviewResources({row});
              //   },
              width: 140,
            },
          ],
        },

        {
          header: 'Candidates',
          children: [
            {
              width: 64,
              header: 'Active',
              field: 'active_count',
              aggregate: true,
              render: ActiveCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            {
              width: 90,
              header: 'Screening',
              field: 'screening_count',
              aggregate: true,
              render: ScreeningCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            {
              width: 90,
              header: 'Interview',
              field: 'interview_count',
              aggregate: true,
              render: InterviewCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            {
              width: 90,
              header: 'Result due',
              field: 'result_due_count',
              aggregate: true,
              render: InterviewDueCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            // {
            //   width: 64,
            //   field: 'lost_count',
            //   aggregate: true,
            //   aggregate: true,
            //   render: LostCandidateRender,
            //   align: 'right',
            //   header: 'Lost',
            //   onPress: ({row}) => {
            //     lostCandidateScreen({row});
            //   },
            // },
            // {
            //   width: 64,
            //   header: 'Drop',
            //   aggregate: true,
            //   field: 'drop_count',
            //   render: DropCandidateRender,
            //   align: 'right',
            //   onPress: ({row}) => {
            //     dropCandidateScreen({row});
            //   },
            // },
          ],
        },
        // {
        //   header: 'Closed Positions',
        //   children: [
        //     {
        //       width: 64,
        //       header: 'Won',
        //       aggregate: true,
        //       field: 'selected_count',
        //       render: WonClosedRender,
        //       align: 'right',
        //       onPress: ({row}) => {
        //         WonCandidateScreen({row});
        //       },
        //     },
        //     {
        //       width: 64,
        //       header: 'Lost',
        //       aggregate: true,
        //       field: 'lost_position_count',
        //       render: LostPositionRender,
        //       align: 'right',
        //     },
        //     {
        //       width: 64,
        //       header: 'Drop',
        //       aggregate: true,
        //       field: 'drop_position_count',
        //       render: DropPositionRender,
        //       align: 'right',
        //     },
        //   ],
        // },
        //country: 200

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          // width: 132,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Lost Position', onPress: markOpportunityPositionLost},
        {title: 'Drop Position', onPress: markOpportunityPositionDrop},
        {title: 'Mark Close', onPress: markEnginneringOpportunityClose},
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        // {
        //   title: 'Communications',
        //   onPress: customPlatformOpportunityCommunications,
        // },
      ]}
    />
  );
};

export const ActiveEngineeringOpportunitiesListDeliveryOwner = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams, dataGrouped: true};
  const {
    markEnginneringOpportunityClose,
    showInterviewResources,
    markOpportunityPositionLost,
    markOpportunityPositionDrop,
  } = leadControllers(props);
  const {
    activeCandidateScreen,
    activeCandidateScreenDeliveryOwner,
    lostCandidateScreen,
    dropCandidateScreen,
    WonCandidateScreen,
  } = staffAugmentationControllers(props);
  const {addProjectionOnOpportunity, customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  return (
    <BorderedGroupTable
      api="/opportunities/engineering-active"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      limit={1000}
      variant={'bordered'}
      // selection={{
      //   actions: [
      //     {title: 'Mark Close', onPress: markOpportunityClose},
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      onRowPress={row => {
        props.navigation.navigate(
          'edit-staffaugmentationopportunity-deliveryowner',
          row,
        );
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.name} />
              <GroupContentItem value={`${row.no_of_resources}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Open Since',
          render: RenderOpenSince,
          // align: 'right',
          width: 120,
        },
        CUSTOMER,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 64,
        },
        // {
        //   header: 'Size',
        //   render: EmployeeSizeRender,
        // },
        // {
        //   header: 'Country',
        //   render: CountryRender,
        // },
        // {
        //   header: 'Industry',

        //   field: 'industry.name',
        //   type: 'colorTag',
        //   colorField: 'industry.color',
        // },
        {
          header: 'Score',
          render: leadScoreRender,
          align: 'right',
          width: 68,
        },
        {
          header: 'Mode',
          align: 'right',
          render: ({row}) => {
            const {CAPTION_LARGE} = useTheme('fonts');
            const colors = useTheme('colors');
            const {work_location, process} = row;
            if (process == 'Direct' || process == 'Trial') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT5_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Test & Interview' || process == 'Test') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT4_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Interview') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT6_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
          },
          width: 150,
        },
        {
          header: 'Skill & Exp',
          render: RenderSkills,
          width: 110,
        },
        {
          header: 'Positions',
          children: [
            // MODE,
            // POSITIONOPEN,
            {
              header: 'Total',
              field: 'no_of_resources',
              align: 'right',
              width: 70,
              aggregate: true,
              // onPress: ({row}) => {
              //   showInterviewResources({row});
              // },
              // width: 112,
            },
            {
              header: 'Active Positions',
              //   render: ActiveCandidateRender,
              field: 'active_positions',
              aggregate: true,
              align: 'right',
              //   onPress: ({row}) => {
              //     showInterviewResources({row});
              //   },
              width: 140,
            },
          ],
        },

        {
          header: 'Candidates',
          children: [
            {
              width: 64,
              header: 'Active',
              field: 'active_count',
              aggregate: true,
              render: ActiveCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreenDeliveryOwner({row});
              },
            },
            {
              width: 90,
              header: 'Screening',
              field: 'screening_count',
              aggregate: true,
              render: ScreeningCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreenDeliveryOwner({row});
              },
            },
            {
              width: 90,
              header: 'Interview',
              field: 'interview_count',
              aggregate: true,
              render: InterviewCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreenDeliveryOwner({row});
              },
            },
            {
              width: 90,
              header: 'Result due',
              field: 'result_due_count',
              aggregate: true,
              render: InterviewDueCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreenDeliveryOwner({row});
              },
            },
            // {
            //   width: 64,
            //   field: 'lost_count',
            //   aggregate: true,
            //   aggregate: true,
            //   render: LostCandidateRender,
            //   align: 'right',
            //   header: 'Lost',
            //   onPress: ({row}) => {
            //     lostCandidateScreen({row});
            //   },
            // },
            // {
            //   width: 64,
            //   header: 'Drop',
            //   aggregate: true,
            //   field: 'drop_count',
            //   render: DropCandidateRender,
            //   align: 'right',
            //   onPress: ({row}) => {
            //     dropCandidateScreen({row});
            //   },
            // },
          ],
        },
        // {
        //   header: 'Closed Positions',
        //   children: [
        //     {
        //       width: 64,
        //       header: 'Won',
        //       aggregate: true,
        //       field: 'selected_count',
        //       render: WonClosedRender,
        //       align: 'right',
        //       onPress: ({row}) => {
        //         WonCandidateScreen({row});
        //       },
        //     },
        //     {
        //       width: 64,
        //       header: 'Lost',
        //       aggregate: true,
        //       field: 'lost_position_count',
        //       render: LostPositionRender,
        //       align: 'right',
        //     },
        //     {
        //       width: 64,
        //       header: 'Drop',
        //       aggregate: true,
        //       field: 'drop_position_count',
        //       render: DropPositionRender,
        //       align: 'right',
        //     },
        //   ],
        // },
        //country: 200

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          // width: 132,
        },
      ]}
    />
  );
};

// export const ClosedEngineeringOpportunitiesList = props => {
//   let {
//     route: {params},
//     filterParams,
//     navigation,
//   } = props;
//   params = {...params, ...filterParams};
//   const {showInterviewResources} = leadControllers(props);
//   const {lostCandidateScreen, WonCandidateScreen} =
//     staffAugmentationControllers(props);
//   const {customPlatformOpportunityCommunications} =
//     customDigitalPlatformOppControllers(props);
//   const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
//   return (
//     <Table
//       api="/opportunities/engineering-closed"
//       eventSourceId={[
//         'CustomPlatformOpportunity',
//         'communicationAdded',
//         'StaffAugmentation',
//       ]}
//       {...props}
//       params={params}
//       limit={1000}
//       variant={'bordered'}
//       // selection={{
//       //   actions: [
//       //     ,
//       //     {title: 'Assign Pre Sales', onPress: assignPreSales},
//       //   ],
//       // }}
//       onRowPress={row => {
//         props.navigation.navigate('edit-staffaugmentationopportunity', row);
//       }}
//       columns={[
//         // {
//         //   header: 'Open Since',
//         //   render: RenderOpenSince,
//         //   width: 120,
//         // },
//         CUSTOMER,
//         {
//           header: 'Sales',
//           field: 'owner',
//           type: 'userAvatar',
//           width: 80,
//         },
//         // {
//         //   header: 'Size',
//         //   render: EmployeeSizeRender,
//         // },
//         // {
//         //   header: 'Country',
//         //   render: CountryRender,
//         // },
//         // {
//         //   header: 'Industry',

//         //   field: 'industry.name',
//         //   type: 'colorTag',
//         //   colorField: 'industry.color',
//         // },
//         {
//           header: 'Score',
//           render: leadScoreRender,
//           align: 'right',
//           width: 68,
//         },

//         {
//           header: 'Requirement',
//           children: [
//             {
//               header: 'Mode',
//               align: 'right',
//               render: ({row}) => {
//                 const {CAPTION_LARGE} = useTheme('fonts');
//                 const colors = useTheme('colors');
//                 const {work_location, process} = row;
//                 if (process == 'Direct' || process == 'Trial') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT5_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//                 if (process == 'Test & Interview' || process == 'Test') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT4_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//                 if (process == 'Interview') {
//                   return (
//                     <>
//                       <Tag value={process} color={colors.ACCENT6_MEDIUM} />
//                       <Text
//                         numberOfLines={1}
//                         style={{
//                           ...CAPTION_LARGE,
//                         }}>
//                         {work_location}
//                       </Text>
//                     </>
//                   );
//                 }
//               },
//               width: 150,
//             },
//             {
//               header: 'Skill',
//               render: RenderSkills,
//               width: 110,
//             },
//             // MODE,
//             // POSITIONOPEN,
//             {
//               header: 'Count',
//               field: 'no_of_resources',
//               align: 'right',
//               width: 70,
//               aggregate: true,
//               onPress: ({row}) => {
//                 showInterviewResources({row});
//               },
//               // width: 112,
//             },
//           ],
//         },
//         {
//           header: 'Closed Positions',
//           children: [
//             {
//               width: 80,
//               header: 'Won',
//               render: WonClosedRender,
//               aggregate: true,
//               field: 'selected_count',
//               align: 'right',
//               onPress: ({row}) => {
//                 WonCandidateScreen({row});
//               },
//             },
//             {
//               width: 80,
//               header: 'Lost',
//               render: LostPositionRender,
//               aggregate: true,
//               field: 'lost_position_count',
//               align: 'right',
//             },
//             {
//               width: 80,
//               header: 'Drop',
//               aggregate: true,
//               field: 'drop_position_count',
//               render: DropPositionRender,
//               align: 'right',
//             },
//           ],
//         },
//         {
//           header: 'Rejected Candidates',
//           //   render: ActiveCandidateRender,
//           field: 'lost_count',
//           aggregate: true,
//           align: 'right',
//           onPress: ({row}) => {
//             lostCandidateScreen({row});
//           },
//           width: 160,
//         },
//         {
//           header: 'Lost/Drop Reason',
//           field: 'outcome_description_comment',
//           type: 'colorTag',
//           width: 180,
//         },
//         {
//           header: 'Closed On',
//           width: 120,
//           render: RenderCloseDate,
//         },
//         {
//           header: 'Communication',
//           onPress: ({row}) => {
//             customPlatformOpportunityCommunications({row});
//           },
//           render: LastCommunicationRender,
//           width: 132,
//         },
//       ]}
//       moreActions={({row}) => [
//         objectTasksMoreAction({
//           navigation,
//           params,
//           titleField: 'requirement',
//           source: OPPORTUNITY_TYPE_ID,
//         }),
//         ...(row.potential_customer?._id && row.status == 'Won'
//           ? [
//               {
//                 title: 'Make Customer',
//                 onPress: addCustomerFromCustomPlatformOpportunity,
//               },
//             ]
//           : []),
//       ]}
//     />
//   );
// };

export const ClosedEngineeringOpportunitiesGroupList = props => {
  let {
    route: {params},
    navigation,
    filterParams,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    ...filterParams,
  };
  const {showInterviewResources} = leadControllers(props);
  const {lostCandidateScreen, WonCandidateScreen} =
    staffAugmentationControllers(props);
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
  return (
    <BorderedGroupTable
      api="/opportunities/engineering-closed"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      limit={1000}
      variant={'bordered'}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.rfr} positions`} />
              <GroupContentItem value={`${row.wonCount} Won`} />
            </RowWithSeparator>
          );
        },
      }}
      // selection={{
      //   actions: [
      //     ,
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        // {
        //   header: 'Open Since',
        //   render: RenderOpenSince,
        //   width: 120,
        // },
        // CUSTOMER,
        {
          header: 'Organisation',
          render: CustomerRender,
          width: 300,
        },
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        // {
        //   header: 'Size',
        //   render: EmployeeSizeRender,
        // },
        // {
        //   header: 'Country',
        //   render: CountryRender,
        // },
        // {
        //   header: 'Industry',

        //   field: 'industry.name',
        //   type: 'colorTag',
        //   colorField: 'industry.color',
        // },
        {
          header: 'Score',
          render: leadScoreRender,
          align: 'right',
          width: 68,
        },
        {
          header: 'Skill & Exp',
          render: RenderSkills,
          width: 150,
        },
        {
          header: 'Mode',
          align: 'right',
          render: ({row}) => {
            const {CAPTION_LARGE} = useTheme('fonts');
            const colors = useTheme('colors');
            const {work_location, process} = row;
            if (process == 'Direct' || process == 'Trial') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT5_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Test & Interview' || process == 'Test') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT4_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Interview') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT6_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
          },
          width: 150,
        },

        {
          header: 'Positions',
          field: 'no_of_resources',
          align: 'right',
          width: 80,
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        {
          header: 'Cadidates',
          children: [
            {
              header: 'Total',
              field: 'assigned_count',
              aggregate: true,
              align: 'right',
              width: 100,
              render: AssignedCandidateRender,
              aggregate: true,
              onPress: ({row}) => {
                showInterviewResources({row});
              },
              // width: 112,
            },
            {
              header: 'Won',
              field: 'selected_count',
              aggregate: true,
              align: 'right',
              width: 100,
              render: WonClosedRender,
              aggregate: true,
              onPress: ({row}) => {
                showInterviewResources({row});
              },
              // width: 112,
            },
            {
              header: 'Lost',
              field: 'lost_count',
              aggregate: true,
              align: 'right',
              width: 100,
              // render: WonClosedRender,
              render: props => {
                const {
                  row,
                  styles: {rowText},
                } = props;
                const colors = useTheme('colors');
                if (row?.lost_count) {
                  return (
                    <>
                      <Text
                        numberOfLines={1}
                        style={{
                          ...rowText,
                          color: colors.ERROR_HIGH,
                          align: 'center',
                        }}>
                        {row?.lost_count}
                      </Text>
                    </>
                  );
                } else {
                  return (
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        // color: colors.ERROR_HIGH,
                        align: 'center',
                      }}>
                      0
                    </Text>
                  );
                }
              },
              aggregate: true,
              onPress: ({row}) => {
                showInterviewResources({row});
              },
              // width: 112,
            },
            {
              header: 'Drop',
              field: 'drop_count',
              aggregate: true,
              align: 'right',
              width: 100,
              render: props => {
                const {
                  row,
                  styles: {rowText},
                } = props;
                const colors = useTheme('colors');
                if (row?.drop_count) {
                  return (
                    <>
                      <Text
                        numberOfLines={1}
                        style={{
                          ...rowText,
                          color: colors.WARNING_HIGH,
                          align: 'center',
                        }}>
                        {row?.drop_count}
                      </Text>
                    </>
                  );
                } else {
                  return (
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        align: 'center',
                      }}>
                      0
                    </Text>
                  );
                }
              },
              aggregate: true,
              onPress: ({row}) => {
                showInterviewResources({row});
              },
              // width: 112,
            },
          ],
        },

        // {
        //   header: 'Requirement',
        //   children: [
        //     {
        //       header: 'Mode',
        //       align: 'right',
        //       render: ({row}) => {
        //         const {CAPTION_LARGE} = useTheme('fonts');
        //         const colors = useTheme('colors');
        //         const {work_location, process} = row;
        //         if (process == 'Direct' || process == 'Trial') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT5_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //         if (process == 'Test & Interview' || process == 'Test') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT4_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //         if (process == 'Interview') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT6_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //       },
        //       width: 150,
        //     },

        //     // MODE,
        //     // POSITIONOPEN,
        //   ],
        // },
        // {
        //   header: 'Closed Positions',
        //   children: [
        //     {
        //       width: 80,
        //       header: 'Won',
        //       render: WonClosedRender,
        //       aggregate: true,
        //       field: 'selected_count',
        //       align: 'right',
        //       onPress: ({row}) => {
        //         WonCandidateScreen({row});
        //       },
        //     },
        //     {
        //       width: 80,
        //       header: 'Lost',
        //       render: LostPositionRender,
        //       aggregate: true,
        //       field: 'lost_position_count',
        //       align: 'right',
        //     },
        //     {
        //       width: 80,
        //       header: 'Drop',
        //       aggregate: true,
        //       field: 'drop_position_count',
        //       render: DropPositionRender,
        //       align: 'right',
        //     },
        //   ],
        // },
        // {
        //   header: 'Rejected Candidates',
        //   //   render: ActiveCandidateRender,
        //   field: 'lost_count',
        //   aggregate: true,
        //   align: 'right',
        //   onPress: ({row}) => {
        //     lostCandidateScreen({row});
        //   },
        //   width: 160,
        // },
        // {
        //   header: 'Lost/Drop Reason',
        //   field: 'outcome_description_comment',
        //   type: 'colorTag',
        //   width: 180,
        // },
        // {
        //   header: 'Closed On',
        //   width: 120,
        //   render: RenderCloseDate,
        // },
        {
          header: 'Status',
          render: RenderStatus,
          width: 120,
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        ...(row.potential_customer?._id && row.status == 'Won'
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const NewEngineeringOpportunitiesGroupList = props => {
  let {
    route: {params},
    navigation,
    filterParams,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    ...filterParams,
  };
  const {showInterviewResources} = leadControllers(props);
  const {lostCandidateScreen, WonCandidateScreen} =
    staffAugmentationControllers(props);
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  const {addCustomerFromCustomPlatformOpportunity} = leadControllers(props);
  return (
    <BorderedGroupTable
      api="/opportunities/engineering-new"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      limit={1000}
      variant={'bordered'}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} created`} />
            </RowWithSeparator>
          );
        },
      }}
      // selection={{
      //   actions: [
      //     ,
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        // {
        //   header: 'Open Since',
        //   render: RenderOpenSince,
        //   width: 120,
        // },
        // CUSTOMER,
        {
          header: 'Organisation',
          render: CustomerRender,
          minWidth: 300,
        },
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        // {
        //   header: 'Size',
        //   render: EmployeeSizeRender,
        // },
        // {
        //   header: 'Country',
        //   render: CountryRender,
        // },
        // {
        //   header: 'Industry',

        //   field: 'industry.name',
        //   type: 'colorTag',
        //   colorField: 'industry.color',
        // },
        {
          header: 'Score',
          render: leadScoreRender,
          align: 'right',
          width: 68,
        },

        {
          header: 'Skill',
          render: RenderSkills,
          width: 150,
        },
        {
          header: 'Mode',
          align: 'right',
          render: ({row}) => {
            const {CAPTION_LARGE} = useTheme('fonts');
            const colors = useTheme('colors');
            const {work_location, process} = row;
            if (process == 'Direct' || process == 'Trial') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT5_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Test & Interview' || process == 'Test') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT4_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
            if (process == 'Interview') {
              return (
                <>
                  <Tag value={process} color={colors.ACCENT6_MEDIUM} />
                  <Text
                    numberOfLines={1}
                    style={{
                      ...CAPTION_LARGE,
                    }}>
                    {work_location}
                  </Text>
                </>
              );
            }
          },
          width: 150,
        },
        {
          header: 'Total',
          field: 'no_of_resources',
          align: 'right',
          width: 70,
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        {
          header: 'Candidates',
          field: 'assigned_count',
          aggregate: true,
          align: 'right',
          width: 100,
          render: AssignedCandidateRender,
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        {
          header: 'Won',
          field: 'selected_count',
          aggregate: true,
          align: 'right',
          width: 100,
          render: WonClosedRender,
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        {
          header: 'Lost',
          field: 'lost_count',
          aggregate: true,
          align: 'right',
          width: 100,
          // render: WonClosedRender,
          render: props => {
            const {
              row,
              styles: {rowText},
            } = props;
            const colors = useTheme('colors');
            if (row?.lost_count) {
              return (
                <>
                  <Text
                    numberOfLines={1}
                    style={{
                      ...rowText,
                      color: colors.ERROR_HIGH,
                      align: 'center',
                    }}>
                    {row?.lost_count}
                  </Text>
                </>
              );
            } else {
              return (
                <Text
                  numberOfLines={1}
                  style={{
                    ...rowText,
                    // color: colors.ERROR_HIGH,
                    align: 'center',
                  }}>
                  0
                </Text>
              );
            }
          },
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          align: 'right',
          width: 100,
          render: props => {
            const {
              row,
              styles: {rowText},
            } = props;
            const colors = useTheme('colors');
            if (row?.drop_count) {
              return (
                <>
                  <Text
                    numberOfLines={1}
                    style={{
                      ...rowText,
                      color: colors.WARNING_HIGH,
                      align: 'center',
                    }}>
                    {row?.drop_count}
                  </Text>
                </>
              );
            } else {
              return (
                <Text
                  numberOfLines={1}
                  style={{
                    ...rowText,
                    align: 'center',
                  }}>
                  0
                </Text>
              );
            }
          },
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          // width: 112,
        },
        // {
        //   header: 'Requirement',
        //   children: [
        //     {
        //       header: 'Mode',
        //       align: 'right',
        //       render: ({row}) => {
        //         const {CAPTION_LARGE} = useTheme('fonts');
        //         const colors = useTheme('colors');
        //         const {work_location, process} = row;
        //         if (process == 'Direct' || process == 'Trial') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT5_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //         if (process == 'Test & Interview' || process == 'Test') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT4_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //         if (process == 'Interview') {
        //           return (
        //             <>
        //               <Tag value={process} color={colors.ACCENT6_MEDIUM} />
        //               <Text
        //                 numberOfLines={1}
        //                 style={{
        //                   ...CAPTION_LARGE,
        //                 }}>
        //                 {work_location}
        //               </Text>
        //             </>
        //           );
        //         }
        //       },
        //       width: 150,
        //     },

        //     // MODE,
        //     // POSITIONOPEN,
        //   ],
        // },
        // {
        //   header: 'Closed Positions',
        //   children: [
        //     {
        //       width: 80,
        //       header: 'Won',
        //       render: WonClosedRender,
        //       aggregate: true,
        //       field: 'selected_count',
        //       align: 'right',
        //       onPress: ({row}) => {
        //         WonCandidateScreen({row});
        //       },
        //     },
        //     {
        //       width: 80,
        //       header: 'Lost',
        //       render: LostPositionRender,
        //       aggregate: true,
        //       field: 'lost_position_count',
        //       align: 'right',
        //     },
        //     {
        //       width: 80,
        //       header: 'Drop',
        //       aggregate: true,
        //       field: 'drop_position_count',
        //       render: DropPositionRender,
        //       align: 'right',
        //     },
        //   ],
        // },
        // {
        //   header: 'Rejected Candidates',
        //   //   render: ActiveCandidateRender,
        //   field: 'lost_count',
        //   aggregate: true,
        //   align: 'right',
        //   onPress: ({row}) => {
        //     lostCandidateScreen({row});
        //   },
        //   width: 160,
        // },
        // {
        //   header: 'Lost/Drop Reason',
        //   field: 'outcome_description_comment',
        //   type: 'colorTag',
        //   width: 180,
        // },
        // {
        //   header: 'Closed On',
        //   width: 120,
        //   render: RenderCloseDate,
        // },
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={({row}) => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        ...(row.potential_customer?._id && row.status == 'Won'
          ? [
              {
                title: 'Make Customer',
                onPress: addCustomerFromCustomPlatformOpportunity,
              },
            ]
          : []),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};
export const ActiveEngineeringOpportunitiesListForAnalytics = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {
    markEnginneringOpportunityClose,
    showInterviewResources,
    markOpportunityPositionLost,
    markOpportunityPositionDrop,
  } = leadControllers(props);
  const {
    activeCandidateScreen,
    lostCandidateScreen,
    dropCandidateScreen,
    WonCandidateScreen,
  } = staffAugmentationControllers(props);
  const {addProjectionOnOpportunity} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      api="/opportunities/engineering-active"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      limit={1000}
      filter={{
        _id: {$in: params?.row},
      }}
      variant={'bordered'}
      // selection={{
      //   actions: [
      //     {title: 'Mark Close', onPress: markOpportunityClose},
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        CUSTOMER,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        {
          header: 'Requirement',
          field: 'no_of_resources',
          align: 'right',
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          width: 120,
        },
        {
          header: 'Active Positions',
          field: 'active_positions',
          aggregate: true,
          align: 'right',
          width: 140,
        },
        {
          header: 'Candidates',
          children: [
            {
              width: 80,
              header: 'Active',
              field: 'active_count',
              aggregate: true,
              render: ActiveCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            {
              width: 80,
              field: 'lost_count',
              aggregate: true,
              aggregate: true,
              render: LostCandidateRender,
              align: 'right',
              header: 'Lost',
              onPress: ({row}) => {
                lostCandidateScreen({row});
              },
            },
            {
              width: 80,
              header: 'Drop',
              aggregate: true,
              field: 'drop_count',
              render: DropCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                dropCandidateScreen({row});
              },
            },
          ],
        },
        {
          header: 'Closed Positions',
          children: [
            {
              width: 80,
              header: 'Won',
              aggregate: true,
              field: 'selected_count',
              render: WonClosedRender,
              align: 'right',
              onPress: ({row}) => {
                WonCandidateScreen({row});
              },
            },
            {
              width: 80,
              header: 'Lost',
              aggregate: true,
              field: 'lost_position_count',
              render: LostPositionRender,
              align: 'right',
            },
            {
              width: 80,
              header: 'Drop',
              aggregate: true,
              field: 'drop_position_count',
              render: DropPositionRender,
              align: 'right',
            },
          ],
        },
        {
          header: 'Open Since',
          width: 150,
          render: RenderOpenSince,
          align: 'right',
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Lost Position', onPress: markOpportunityPositionLost},
        {title: 'Drop Position', onPress: markOpportunityPositionDrop},
        {title: 'Mark Close', onPress: markEnginneringOpportunityClose},
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
      ]}
    />
  );
};

export const ClosedEngineeringOpportunitiesListForAnalytics = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  params = {...params, ...filterParams};
  const {showInterviewResources} = leadControllers(props);
  const {lostCandidateScreen, WonCandidateScreen} =
    staffAugmentationControllers(props);
  return (
    <Table
      api="/opportunities/engineering-closed"
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      variant={'bordered'}
      // selection={{
      //   actions: [
      //     ,
      //     {title: 'Assign Pre Sales', onPress: assignPreSales},
      //   ],
      // }}
      filter={{
        _id: {$in: params?.row},
      }}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        CUSTOMER,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        // MODE,
        // POSITIONOPEN,
        {
          header: 'Requirement',
          field: 'no_of_resources',
          aggregate: true,
          align: 'right',
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          width: 120,
        },
        {
          header: 'Closed Positions',
          children: [
            {
              width: 80,
              header: 'Won',
              render: WonClosedRender,
              aggregate: true,
              field: 'selected_count',
              align: 'right',
              onPress: ({row}) => {
                WonCandidateScreen({row});
              },
            },
            {
              width: 80,
              header: 'Lost',
              render: LostPositionRender,
              aggregate: true,
              field: 'lost_position_count',
              align: 'right',
            },
            {
              width: 80,
              header: 'Drop',
              aggregate: true,
              field: 'drop_position_count',
              render: DropPositionRender,
              align: 'right',
            },
          ],
        },
        {
          header: 'Rejected Candidates',
          //   render: ActiveCandidateRender,
          field: 'lost_count',
          aggregate: true,
          align: 'right',
          onPress: ({row}) => {
            lostCandidateScreen({row});
          },
          width: 160,
        },
        {
          header: 'Closed On',
          width: 150,
          render: RenderCloseDate,
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
      ]}
    />
  );
};

export const ActiveEngineeringOpportunitiesListForEmployeeMenu = props => {
  let {
    route: {params},
    // filterParams,
    navigation,
  } = props;
  params = {...params};
  const {filterValues, applyFilter} = useFilter();
  const {params: filterParams, filter} = filterValues;
  params = {...params, ...filterParams};

  const {
    markEnginneringOpportunityClose,
    showInterviewResources,
    markOpportunityPositionLost,
    markOpportunityPositionDrop,
  } = leadControllers(props);
  const {
    activeCandidateScreen,
    lostCandidateScreen,
    dropCandidateScreen,
    WonCandidateScreen,
  } = staffAugmentationControllers(props);
  const {addProjectionOnOpportunity} =
    customDigitalPlatformOppControllers(props);
  return (
    <Table
      api="/opportunities/engineering-active"
      renderHeader={() => (
        <TableHeader
          title="Opportunities"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Owner',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  field: 'owner',
                },
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer_id',
                },
                {
                  type: 'autoComplete',
                  label: 'Skill',
                  api: '/skills',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'skill',
                },
                {
                  type: 'autoComplete',
                  label: 'Potential Customer',
                  api: '/potentialcustomers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'potential_customer',
                },
                {
                  type: 'autoComplete',
                  label: 'Mode',
                  options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
                  placeholder: 'Select',
                  field: 'process',
                },
                {
                  type: 'autoComplete',
                  label: 'Engagement Type',
                  options: [
                    'Trial Engagement',
                    'Billable Engagement',
                    'Replacement',
                  ],
                  placeholder: 'Select',
                  field: 'rfr_engagement_type',
                },
                {
                  type: 'date',
                  field: 'next_task_date',
                  asParam: true,
                  label: 'Next Task date',
                },
                {
                  type: 'date',
                  field: 'outcome_date',
                  asParam: true,
                  label: 'Outcome date',
                },
                {
                  type: 'date',
                  field: 'creation_date',
                  asParam: true,
                  label: 'Creation date',
                },
              ]}
            />,
          ]}
        />
      )}
      eventSourceId={[
        'CustomPlatformOpportunity',
        'communicationAdded',
        'StaffAugmentation',
      ]}
      {...props}
      params={params}
      addOnFilter={filter}
      limit={1000}
      variant={'bordered'}
      onRowPress={row => {
        props.navigation.navigate('edit-staffaugmentationopportunity', row);
      }}
      columns={[
        CUSTOMER,
        {
          header: 'Sales',
          field: 'owner',
          type: 'userAvatar',
          width: 80,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 200,
        },
        // MODE,
        // POSITIONOPEN,
        {
          header: 'Requirement',
          field: 'no_of_resources',
          align: 'right',
          aggregate: true,
          onPress: ({row}) => {
            showInterviewResources({row});
          },
          width: 120,
        },
        {
          header: 'Active Positions',
          //   render: ActiveCandidateRender,
          field: 'active_positions',
          aggregate: true,
          align: 'right',
          //   onPress: ({row}) => {
          //     showInterviewResources({row});
          //   },
          width: 140,
        },
        {
          header: 'Candidates',
          children: [
            {
              width: 80,
              header: 'Active',
              field: 'active_count',
              aggregate: true,
              render: ActiveCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                activeCandidateScreen({row});
              },
            },
            {
              width: 80,
              field: 'lost_count',
              aggregate: true,
              aggregate: true,
              render: LostCandidateRender,
              align: 'right',
              header: 'Lost',
              onPress: ({row}) => {
                lostCandidateScreen({row});
              },
            },
            {
              width: 80,
              header: 'Drop',
              aggregate: true,
              field: 'drop_count',
              render: DropCandidateRender,
              align: 'right',
              onPress: ({row}) => {
                dropCandidateScreen({row});
              },
            },
          ],
        },
        {
          header: 'Closed Positions',
          children: [
            {
              width: 80,
              header: 'Won',
              aggregate: true,
              field: 'selected_count',
              render: WonClosedRender,
              align: 'right',
              onPress: ({row}) => {
                WonCandidateScreen({row});
              },
            },
            {
              width: 80,
              header: 'Lost',
              aggregate: true,
              field: 'lost_position_count',
              render: LostPositionRender,
              align: 'right',
            },
            {
              width: 80,
              header: 'Drop',
              aggregate: true,
              field: 'drop_position_count',
              render: DropPositionRender,
              align: 'right',
            },
          ],
        },
        {
          header: 'Open Since',
          width: 150,
          render: RenderOpenSince,
          align: 'right',
        },
      ]}
      moreActions={() => [
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {title: 'Lost Position', onPress: markOpportunityPositionLost},
        {title: 'Drop Position', onPress: markOpportunityPositionDrop},
        {title: 'Mark Close', onPress: markEnginneringOpportunityClose},
        {
          title: 'Add Projection',
          onPress: addProjectionOnOpportunity,
        },
      ]}
    />
  );
};
