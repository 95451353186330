import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {getPreviousMonth} from '@unthinkable/react-filters';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {TabView} from '../../../components/tab';
import {IconButton} from '../../../components/button/IconButton';

const ProjectEfficiencyTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      {...props}
      params={{...filterParams, key: 'Projectefficiency'}}
      addOnFilter={filter}
      api={'/projects/fixedcostreport'}
      limit={5000}
      columns={[
        {
          header: 'Project',
          minWidth: 250,
          render: ({row}) => {
            let icon = '';
            let type = '';
            if (row.project_id.tool === 'Locomo') {
              icon = icons?.LocomoTool;
            } else if (row.project_id.tool === 'Jira') {
              icon = icons?.JiraIcon;
            } else {
              icon = icons?.OtherTool;
            }
            if (row?.project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (row?.project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (row?.project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <Row
                style={{
                  flex: 1,
                  rowGap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                gap={8}>
                <Row
                  style={{
                    borderRadius: 2,
                    padding: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={icon} />
                </Row>
                <PrimaryColumnCard
                  primaryTitle={row?.project_id?.project}
                  items={[
                    {
                      value: row?.project_id?.code,
                    },
                    // {
                    //   value: type,
                    // },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'AM',
          field: 'project_id.owner_id',
          type: 'userAvatar',
          width: 60,
        },
        {
          header: 'CSM',
          field: 'project_id.csm',
          type: 'userAvatar',
          width: 60,
        },
        {
          header: 'Opening Pot.',
          field: 'allocatedHours',
          type: 'number',
        },
        {
          render: CountCellRender,
          align: 'right',
          header: 'Allocated',
          field: 'team',
          count_field: 'team',
          onPress: ({row}) => {
            navigation.navigate('eng_efficiency_report', {
              employeeId: row.employeeIds,
              project_id: row?.project_id?._id,
              api: '/employee/fixedEngineeringReport',
              period: filterParams.period,
            });
            // navigation.navigate(`employee-workload-for-project`, {
            //   employeeFilter: {
            //     _id: {$in: row.employeeIds},
            //   },
            //   project_id: row?.project_id?._id,
            //   formProjectAllocation: true,
            // });
          },
        },
        {
          header: 'Delivered',
          field: 'deliveredHours',
          type: 'number',
        },
        {
          header: 'Billing Eff.',
          field: 'efficiency',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'All Billing Efficiency',
          onPress: ({row}) => {
            const {project_id = {}} = row;
            navigation.navigate('project-all-efficiency', {
              billingEfficiencyProjectWiseFilter: {
                project_id: project_id._id,
                period: {
                  from: project_id?.project_start_date,
                  to: project_id?.project_end_date,
                },
              },
            });
          },
        },
      ]}
    />
  );
};

const ProjectAllEfficiencyTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      {...props}
      params={{...filterParams, key: 'Projectefficiency'}}
      addOnFilter={filter}
      api={'/projects/fixedcostreport'}
      limit={5000}
      columns={[
        {
          header: 'Project',
          minWidth: 250,
          render: ({row}) => {
            let icon = '';
            let type = '';
            if (row.project_id.tool === 'Locomo') {
              icon = icons?.LocomoTool;
            } else if (row.project_id.tool === 'Jira') {
              icon = icons?.JiraIcon;
            } else {
              icon = icons?.OtherTool;
            }
            if (row?.project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (row?.project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (row?.project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <Row
                style={{
                  flex: 1,
                  rowGap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                gap={8}>
                <Row
                  style={{
                    borderRadius: 2,
                    padding: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={icon} />
                </Row>
                <PrimaryColumnCard
                  primaryTitle={row?.project_id?.project}
                  items={[
                    {
                      value: row?.project_id?.code,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'AM',
          field: 'project_id.owner_id',
          type: 'userAvatar',
          width: 60,
        },
        {
          header: 'CSM',
          field: 'project_id.csm',
          type: 'userAvatar',
          width: 60,
        },
        {
          header: 'Opening Pot.',
          field: 'allocatedHours',
          type: 'number',
        },
        {
          render: CountCellRender,
          align: 'right',
          header: 'Allocated',
          field: 'team',
          count_field: 'team',
          onPress: ({row}) => {
            navigation.navigate('eng_efficiency_report', {
              employeeId: row.employeeIds,
              project_id: row?.project_id?._id,
              api: '/employee/fixedEngineeringReport',
              period: filterParams.period,
            });
          },
        },
        {
          header: 'Delivered',
          field: 'deliveredHours',
          type: 'number',
        },
        {
          header: 'Billing Eff.',
          field: 'efficiency',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'All Billing Efficiency',
          onPress: ({row}) => {
            const {project_id = {}} = row;
            navigation.navigate('project-all-efficiency', {
              billingEfficiencyProjectWiseFilter: {
                project_id: project_id._id,
                period: {
                  from: project_id?.project_start_date,
                  to: project_id?.project_end_date,
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const ProjectEfficiencyTab = props => {
  let {route: {params} = {}, pmFilter = {}} = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getPreviousMonth(),
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {download} = useAppStateContext();
  const tabs = {
    project_gross_margin_report: {
      apiKey: 'active',
      label: 'Project Billing Efficiency',
      view: (
        <ProjectEfficiencyTable
          key="FixedGrossMargin"
          filterParams={filterParams}
          filter={{...filter, period, ...pmFilter}}
          isCustomerGrossMargin={true}
        />
      ),
      // api: '/projects/fixedcostreport',
      // addOnFilter: {...filter, period},
      // params: {...filterParams},
      // limit: 5000,
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'project_id',
              api: '/projects',
              suggestionField: 'project',
              valueField: 'project',
              label: 'Project',
            },
          ]}
        />,
        <IconButton
          icon={icons.Download}
          onPress={() => {
            download({
              uri: '/exportTandMProjectGrossMarginReport',
              props: {
                uri: '/projects/teamcostreport',
                parameters: {
                  params: {...filterParams, isCustomerGrossMargin: false},
                  addOnFilter: {
                    ...filter,
                    ...params?.customerFilter,
                    ...pmFilter,
                  },
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const ProjectEfficiencyTableAM = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  const icons = useTheme('icons');
  return (
    <Table
      {...props}
      params={{
        ...filterParams,
        account_owner: employeeId?._id,
      }}
      addOnFilter={{...filter}}
      api={'/projectBillingEfficiencyForPmAndAm'}
      limit={5000}
      columns={[
        {
          header: 'Project',
          minWidth: 250,
          render: ({row}) => {
            let icon = '';
            let type = '';
            if (row.project_id.tool === 'Locomo') {
              icon = icons?.LocomoTool;
            } else if (row.project_id.tool === 'Jira') {
              icon = icons?.JiraIcon;
            } else {
              icon = icons?.OtherTool;
            }
            if (row?.project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (row?.project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (row?.project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <Row
                style={{
                  flex: 1,
                  rowGap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                gap={8}>
                <Row
                  style={{
                    borderRadius: 2,
                    padding: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={icon} />
                </Row>
                <PrimaryColumnCard
                  primaryTitle={row?.project_id?.project}
                  items={[
                    {
                      value: row?.project_id?.code,
                    },
                    {
                      value: type,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          render: CountCellRender,
          header: 'Team',
          field: 'team',
          count_field: 'team',
          align: 'center',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('eng_efficiency_report', {
              employeeId: row.employeeIds,
              project_id: row?.project_id?._id,
              api: '/employee/fixedEngineeringReport',
              period: filterParams.period,
            });
          },
        },
        {
          header: 'CSM',
          field: 'project_id.csm',
          type: 'userAvatar',
        },
        {
          header: 'Effort',
          field: 'allocatedHours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Delivered',
          field: 'deliveredHours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Efficiency',
          field: 'efficiency',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};

export const ProjectEfficiencyTablePM = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  const icons = useTheme('icons');
  return (
    <Table
      params={{
        ...filterParams,
        owner_id: employeeId?._id,
      }}
      addOnFilter={{...filter}}
      api={'/projectBillingEfficiencyForPmAndAm'}
      limit={5000}
      columns={[
        {
          header: 'Project',
          minWidth: 250,
          render: ({row}) => {
            let icon = '';
            let type = '';
            if (row.project_id.tool === 'Locomo') {
              icon = icons?.LocomoTool;
            } else if (row.project_id.tool === 'Jira') {
              icon = icons?.JiraIcon;
            } else {
              icon = icons?.OtherTool;
            }
            if (row?.project_id?.projecttype === 'tm') {
              type = 'T&M';
            } else if (row?.project_id?.projecttype === 'i') {
              type = 'Internal';
            } else if (row?.project_id?.projecttype === 'f') {
              type = 'Fixed';
            }
            return (
              <Row
                style={{
                  flex: 1,
                  rowGap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                gap={8}>
                <Row
                  style={{
                    borderRadius: 2,
                    padding: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={icon} />
                </Row>
                <PrimaryColumnCard
                  primaryTitle={row?.project_id?.project}
                  items={[
                    {
                      value: row?.project_id?.code,
                    },
                    {
                      value: type,
                    },
                  ]}
                />
              </Row>
            );
          },
        },
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          render: CountCellRender,
          header: 'Team',
          field: 'team',
          count_field: 'team',
          align: 'center',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('eng_efficiency_report', {
              employeeId: row.employeeIds,
              project_id: row?.project_id?._id,
              api: '/employee/fixedEngineeringReport',
              period: filterParams.period,
            });
          },
        },
        {
          header: 'CSM',
          field: 'project_id.csm',
          type: 'userAvatar',
        },
        {
          header: 'Effort',
          field: 'allocatedHours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Delivered',
          field: 'deliveredHours',
          type: 'number',
          width: 150,
        },
        {
          header: 'Efficiency',
          field: 'efficiency',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};

export const ProjectAllEfficiencyTab = props => {
  let {route: {params} = {}, pmFilter = {}} = props;
  const {billingEfficiencyProjectWiseFilter = {}} = params || {};
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    // params: {
    //   period: getPreviousMonth(),
    // },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const tabs = {
    project_gross_margin_report: {
      apiKey: 'active',
      label: 'All Billing Efficiency',
      view: (
        <ProjectAllEfficiencyTable
          key="FixedGrossMargin"
          filterParams={{
            ...filterParams,
            period: billingEfficiencyProjectWiseFilter?.period,
          }}
          filter={{
            ...filter,
            ...billingEfficiencyProjectWiseFilter,
          }}
          isCustomerGrossMargin={true}
        />
      ),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
