import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {Durations} from './components/Durations';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {randomColor} from '../../../utils/util';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useProjectActions} from '../../pmt/actions/ProjectActions';
import moment from 'moment';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'autoComplete',
      label: 'Order',
      api: '/orders',
      placeholder: 'Select',
      suggestionField: 'order_number',
      valueField: 'order_number',
      field: 'order',
    },
    {
      type: 'multiAutoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Delivery Status',
      placeholder: 'Select',
      options: ['Active', 'WIP Added', 'Delivered'],
      suggestionField: 'name',
      valueField: 'name',
      field: 'status',
    },
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'orginization',
    },
  ];
};

const orderAndDeliveriesFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
}) => {
  const {download} = useAppStateContext();
  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    tab === 'Delivered' && (
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/pmDeliveredOrdersAndDelivers/export`,
            props: {
              params: {...params, period, fromFixed: true},
            },
          });
        }}
      />
    ),
  ];
};

const renderProject = ({icons, projectDetailForOutputs}) => {
  return {
    header: 'Project',
    minWidth: 260,
    render: ({row}) => {
      let icon = '';
      if (row?.project?.tool === 'Locomo') {
        icon = icons?.LocomoTool;
      } else if (row?.project?.tool === 'Jira') {
        icon = icons?.JiraIcon;
      } else {
        icon = icons?.OtherTool;
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image source={icon} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project?.project}
            items={[
              {
                value: row?.project?.code,
              },
              {
                value: `${
                  row?.start_date
                    ? moment(row?.start_date).format('DD MMM YY')
                    : ''
                } - ${
                  row?.due_date ? moment(row?.due_date).format('DD MMM YY') : ''
                }`,
              },
            ]}
          />
        </Row>
      );
    },
    onPress: projectDetailForOutputs,
  };
};

const DURATION = {
  header: 'Duration',
  align: 'center',
  width: 180,
  render: ({row, styles: {rowText = {}} = {}}) => {
    return Durations({
      styles: rowText,
      fromDate: row?.start_date,
      toDate: row?.due_date,
    });
  },
};

const BILLING_MODEL = {
  header: 'Billing Model',
  field: 'order.billing_type',
  type: 'colorTag',
  colorField: randomColor,
  align: 'center',
};

const CSM = {
  header: 'CSM/PM',
  // align: 'center',
  width: 180,
  render: ({row}) => {
    return (
      <UserAvatarChip
        value={
          row?.customer?.csm?._id
            ? row?.customer?.csm
            : row?.customer?.account_owner
        }
      />
    );
  },
};

const CUSTOMER = {
  header: 'Customer',
  field: 'customer.name',
  width: 180,
};

const orderEngg = ({tabName}) => {
  return {
    render: CountCellRender,
    count_field: 'orderEngg',
    type: 'chip',
    header: 'Order Engg.',
    field: 'orderEngg',
    align: 'right',
    // onPress: ({row}) => {
    //   const {project_team} = row;
    //   navigation.navigate(`employee-workload-for-project`, {
    //     employeeFilter: {_id: {$in: project_team}},
    //   });
    // },
    visible: () => tabName !== 'sdlcplus',
    width: 100,
  };
};

const allocation = ({navigation, period}) => {
  return {
    render: CountCellRender,
    count_field: 'allocated',
    field: 'allocated',
    type: 'chip',
    header: 'Allocated',
    align: 'right',
    aggregate: true,
    onPress: ({row}) => {
      const {projectEmployeeIds} = row;
      navigation.navigate(`employee-monthwise-workload-for-project-team`, {
        employeeFilter: {
          _id: {$in: projectEmployeeIds},
          project_id: row?.project?._id,
        },
        period,
      });
    },
    width: 80,
  };
};

const ORDERANDALLOCATED = {
  render: CountCellRender,
  count_field: 'allocated',
  field: 'allocated',
  type: 'chip',
  header: 'Order/Allocated',
  align: 'right',
  width: 140,
};

const LEAVESINMM = {
  header: 'Leaves MM',
  align: 'right',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.total_leave_hours_mm || 0} MM`}</Text>
    );
  },
};

const ACTUALPROJECTDELIVERY = {
  header: 'Actual delivery',
  field: 'billed_hours',
  type: 'number',
};

const ActiveOrdersAndDeliveriesForFixed = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    fromVerified = false,
  } = props;
  const icons = useTheme('icons');
  const {projectDetailForOutputs} = useProjectActions({
    navigation,
    params,
  });
  return (
    <Table
      api={`/pmOrdersAndDelivers/fixed`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{...params, fromVerified, period}}
      limit={2000}
      eventSourceId={[
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ]}
      columns={[
        renderProject({icons, projectDetailForOutputs}),
        CUSTOMER,
        CSM,
        {
          header: 'Rem. vs Pot.',
          align: 'right',
          aggregate: true,
          field: 'unDeliveredHours',
          width: 100,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={`${row?.unDeliveredHours} MM`}
                items={[
                  {
                    value: `${row?.totalEstimatedHours} MM`,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Growth',
          field: 'growth',
          align: 'right',
          aggregate: true,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.growth && `${row?.growth} MM`}
              />
            );
          },
        },
        allocation({navigation, period}),
        {
          header: 'Proj. delivery',
          align: 'right',
          aggregate: true,
          field: 'targetHours',
          render: ({row}) => {
            return (
              <View
                onPress={() => {
                  navigation.navigate('customer-finished-hours-list', {
                    filter: {
                      project_resource_working_data: {
                        $in: row?._children?.map(({_id}) => _id),
                      },
                      status: 'Delivered',
                      order_type: 'ot',
                      is_internal: {$in: [null, false]},
                    },
                  });
                }}>
                <PrimaryColumnCard
                  primaryTitle={row?.targetHours && `${row?.targetHours} MM`}
                  // items={[
                  //   {
                  //     value: row?.amount && `₹ ${(row?.amount).toFixed(2)}`,
                  //   },
                  // ]}
                />
              </View>
            );
          },
        },
        {
          header: 'Act. delivery',
          align: 'right',
          aggregate: true,
          field: 'delivered_hours',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={
                  row?.delivered_hours && `${row?.delivered_hours} MM`
                }
                // items={[
                //   {
                //     value: row?.actualAmount && `₹ ${(row?.actualAmount).toFixed(2)}`,
                //   },
                // ]}
              />
            );
          },
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate(
                    'projectresourceworking-active-order-for-fixed',
                    {
                      addOnFilter: {
                        _id: {$in: row?._children?.map(({_id}) => _id)},
                      },
                      period,
                      row,
                    },
                  );
                }}
                style={{...rowText, color: '#007AFF'}}>
                Verify
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Add Target Order',
          onPress: ({row}) => {
            navigation.navigate('add-fixed-order-projection', {
              projectionfilter: {
                project: row?.project?._id,
              },
              row,
              fromFixedOutput: true,
            });
          },
        },
      ]}
    />
  );
};

const DeliveredOrdersAndDeliveriesForFixed = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      api={`/pmDeliveredOrdersAndDelivers/fixed`}
      search={searchValue}
      addOnFilter={{...addOnFilter, order_type: 'ot'}}
      params={{...params, period}}
      limit={1000}
      eventSourceId={[
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ]}
      columns={[
        renderProject({icons}),
        CUSTOMER,
        CSM,
        {
          header: 'Delivered Date',
          field: 'delivered_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Total Potential',
          field: 'project_resource_working_data.variable_potential',
          type: 'number',
        },
        ACTUALPROJECTDELIVERY,
      ]}
      // moreActions={[
      // {
      //   title: 'View orders',
      //   onPress: ({row}) => {
      //     navigation.navigate('projectresourceworking-active-order', {
      //       addOnFilter: {
      //         order: row?.order?._id,
      //         approval_status: {$in: ['Delivered', 'Partially Delivered']},
      //       },
      //       period,
      //     });
      //   },
      // },
      // {
      //   title: 'Delivery Progress',
      //   onPress: ({row}) => {
      //     navigation.navigate('delivery-progress', {
      //       deliveryProgressFilter: {
      //         project_resource_working_data:
      //           row?.project_resource_working_data?._id,
      //         order_type: 'ot',
      //         status: {$in: ['Delivered', 'Fully Delivered']},
      //       },
      //       period,
      //       fromSelfView: true,
      //     });
      //   },
      // },
      // ]}
    />
  );
};

export const OrdersAndDeliveriesForFixedTab = props => {
  const {route: {params} = {}} = props;
  const {fixedOutputFilterFormGrowthAndBilling = {}} = params || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    fixed_active: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: {$ne: 'Support'},
            ...fixedOutputFilterFormGrowthAndBilling,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: {$ne: 'Support'},
        ...fixedOutputFilterFormGrowthAndBilling,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: {$ne: 'Support'},
            ...fixedOutputFilterFormGrowthAndBilling,
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: {$ne: 'Support'},
        order_type: 'ot',
        ...fixedOutputFilterFormGrowthAndBilling,
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForFixedRetainerTab = props => {
  const {route: {params} = {}} = props;
  const {fixedOutputFilterFormGrowthAndBilling = {}} = params || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    support_active: {
      apiKey: 'active',
      label: 'Retainer',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: 'Support',
            ...fixedOutputFilterFormGrowthAndBilling,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: 'Support',
        ...fixedOutputFilterFormGrowthAndBilling,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: 'Support',
            ...fixedOutputFilterFormGrowthAndBilling,
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: 'Support',
        order_type: 'ot',
        ...fixedOutputFilterFormGrowthAndBilling,
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForFixedTabForAM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    fixed_active: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: {$ne: 'Support'},
            account_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: {$ne: 'Support'},
        account_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            account_owner_id: employee?._id,
            order_exists_type: {$ne: 'Support'},
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        account_owner_id: employee?._id,
        order_type: 'ot',
        order_exists_type: {$ne: 'Support'},
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForFixedRetainerTabForAM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    fixed_active: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: 'Support',
            account_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: 'Support',
        account_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            account_owner_id: employee?._id,
            order_exists_type: 'Support',
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        account_owner_id: employee?._id,
        order_type: 'ot',
        order_exists_type: 'Support',
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForFixedTabForPM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    fixed_active: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: {$ne: 'Support'},
            delivery_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: {$ne: 'Support'},
        delivery_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            delivery_owner_id: employee?._id,
            order_exists_type: {$ne: 'Support'},
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        delivery_owner_id: employee?._id,
        order_type: 'ot',
        order_exists_type: {$ne: 'Support'},
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForFixedRetainerTabForPM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    fixed_active: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            order_exists_type: 'Support',
            delivery_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        order_exists_type: 'Support',
        delivery_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDelivers/fixed',
      limit: 1000,
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <ActiveOrdersAndDeliveriesForFixed
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            delivery_owner_id: employee?._id,
            order_exists_type: 'Support',
          }}
          period={period}
          fromVerified={true}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesFixed',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        delivery_owner_id: employee?._id,
        order_type: 'ot',
        order_exists_type: 'Support',
      },
      params: {...params, fromVerified: true, period},
      api: '/pmOrdersAndDelivers/fixed',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
