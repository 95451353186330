import React, {useState} from 'react';
import {RecursiveRender} from '@unthinkable/react-table';
import {RecursiveTable} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {useFetchData} from '../../../controllers/useFetchData';
import {Col, Linking} from '@unthinkable/react-core-components';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';
import {Tag} from '../../../components/chip/Chip';
import {PrStatusChip} from '../../../components/chip/PrStatusChip';
import moment from 'moment';
import {TextRenderer} from '../../../components/form/Renderers';
import {FeatureStatus} from '../constants/FeatureStages';

export const navigateToPr = () => {
  const postInvoke = useInvoke();

  const createPr = async params => {
    let link;
    try {
      const {data} = await postInvoke({
        uri: `/getPullRequestLink`,
        props: params,
      });
      link = data.link;
    } catch (err) {}

    if (link) {
      Linking.openURL(link, '_blank');
    }
  };

  return {createPr};
};

const Repository = {
  field: 'repository',
  header: 'Repository',
  render: props => <RecursiveRender {...props} />,
  indent: false,
  responsive: 'sm',
};

const VCS = {
  field: 'vcs.type',
  header: 'VCS',
  type: 'text',
  width: 200,
};

const statusColor = {
  new: 'Accent 1',
  in_progress: 'Information',
  Success: 'Success',
  error: 'Error',
};

const StatusRender = ({
  _status,
  _percentage,
  _prs,
  navigation,
  params,
  // boilerplate_process_id,
}) => {
  if (_status === 'in_progress') {
    return (
      <Tag
        displayTextColor={true}
        color={statusColor['In Progress']}
        value={`In Progress ( ${_percentage}% )`}
      />
    );
  } else if (_status === 'success') {
    let {status_link, open_pr_count = 0, branch_id} = _prs || {};
    return (
      <Col gap={4} style={{alignItems: 'center'}}>
        <Tag
          displayTextColor={true}
          color={statusColor['Success']}
          value={`Success`}
        />
        {/* <TextRenderer
          value={moment(boilerplate_process_id.createdAt).format(
            'DD MMM',
          )} /> */}
        {open_pr_count ? (
          <PrStatusChip
            onPress={() => {
              if (open_pr_count === 1) {
                status_link && Linking.openURL(status_link, '_blank');
              } else {
                navigation.navigate('pull-request-table', {
                  ...params,
                  filter: {branch_id},
                  secondaryTitle: 'Boilerplate Prs',
                });
              }
            }}
            value={`${open_pr_count} Pending PR`}
          />
        ) : (
          void 0
        )}
      </Col>
    );
  } else {
    return (
      <Tag
        displayTextColor={true}
        color={statusColor[_status]}
        value={_status}
      />
    );
  }
};

// export const BoilerplateStatus = ({
//   boilerplate_process_id,
//   navigation,
//   params,
// }) => {
//   const {data: {status, progress_percentage} = {}, data} = useFetchData({
//     api: `/longProcesses/${boilerplate_process_id?._id}`,
//     fields: {status: 1, progress_percentage: 1},
//     eventSourceId: 'Repository',
//   });

//   const RealTimeData = ({percentage, status, prs}) => {
//     const [{_percentage, _status, _prs}, updateStatus] = useState({
//       _percentage: percentage,
//       _status: status,
//       _prs: prs,
//     });

//     useRealTimeSync({
//       event: 'BoilerplateStatus',
//       callBack: ({processId, progress_percentage, status, prs}) => {
//         if (processId === boilerplate_process_id._id) {
//           updateStatus({
//             _percentage: progress_percentage,
//             _status: status,
//             _prs: prs,
//           });
//         }
//       },
//     });

//     const props = {
//       navigation,
//       params,
//       _status,
//       _percentage,
//       _prs,
//       boilerplate_process_id,
//     };

//     return <StatusRender {...props} />;
//   };

//   return data ? (
//     <RealTimeData percentage={progress_percentage} status={status} />
//   ) : null;
// };

export const RepositoriesTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;

  const {createPr} = navigateToPr();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Repository',
  });

  const editRepository = ({row}) => {
    navigation.navigate('repositories-detail', {
      ...params,
      repository: row,
      repository_id: row?._id,
    });
  };

  const {data = []} = useFetchData({
    api: `/projectEnvironments`,
    filter: {
      project_id: project?._id,
    },
    sort: {
      createdAt: -1,
    },
    fields: {
      environment: 1,
      label: 1,
      target_environment: {environment: 1, label: 1},
    },
  });

  const moreActions = [];

  if (data) {
    let isQaLocking;
    data.forEach(doc => {
      if (doc.environment === FeatureStatus.qa.value) {
        isQaLocking = true;
      }
      if (doc.target_environment?.environment) {
        moreActions.push({
          title: `Create PR ${doc.label} to ${doc.target_environment?.label}`,
          onPress: async ({row}) => {
            await createPr({
              repository_id: row?._id,
              source_environment: doc.environment,
              target_environment: doc.target_environment?.environment,
            });
          },
        });
      }
    });

    if (isQaLocking) {
      moreActions.push({
        title: 'Lock QA',
        onPress: async ({row}) => {
          await postInvoke({
            uri: `/lockBranch`,
            props: {
              repository_id: row?._id,
              environment: FeatureStatus.qa.value,
            },
          });
        },
      });
    }
  }

  moreActions.push(
    {
      title: 'Update Access Token',
      onPress: ({row}) => {
        navigation.navigate('update-repository-access-token', {
          ...params,
          repository: row,
        });
      },
    },
    {
      title: 'Delete',
      variant: 'error',
      confirm: {
        title: 'Delete',
        message: 'Are you sure you want to Delete?',
        confirmText: 'Delete',
      },
      onPress: ({row}) => {
        deleteInvoke({
          uri: `/projectRepositories/${row?._id}`,
        });
      },
    },
  );

  return (
    <RecursiveTable
      renderHeader={() => {
        return (
          <TableHeader
            title={'Repositories'}
            actions={[
              <AddButton
                title="Repository"
                view="add-repository"
                params={{...params}}
              />,
            ]}
          />
        );
      }}
      defaultExpanded={true}
      eventSourceId={['Repository', 'RepositoryEnvironmentConfigurations']}
      api={`/projects/${project?._id}/repositories`}
      recursiveKey={'dependent_repositories'}
      onRowPress={editRepository}
      columns={[
        Repository,
        VCS,
        // {
        //   header: 'BoilerPlate',
        //   render: ({row}) => {
        //     const {boilerplate_process_id} = row;
        //     if (
        //       boilerplate_process_id?.status === 'success' ||
        //       boilerplate_process_id?.status === 'error'
        //     ) {
        //       const props = {
        //         navigation,
        //         params,
        //         _prs: row.prs,
        //         _status: boilerplate_process_id?.status,
        //         boilerplate_process_id,
        //       };
        //       return <StatusRender {...props} />;
        //     }

        //     // if (boilerplate_process_id) {
        //     //   return (
        //     //     <BoilerplateStatus
        //     //       boilerplate_process_id={boilerplate_process_id}
        //     //       navigation={navigation}
        //     //       params={params}
        //     //     />
        //     //   );
        //     // }
        //   },
        //   type: 'colorTag',
        //   width: 250,
        //   align: 'right',
        // },
      ]}
      moreActions={moreActions}
    />
  );
};
