import React from 'react';
import {Table} from '../../../components/table/Table';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import moment from 'moment';
import {TextRenderer} from '../../../components/form/Renderers';
import {Row, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useInvoke} from '../../../controllers/useInvoke';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {Rating} from '@unthinkable/react-star-rating';

const RenderDate = ({value}) => {
  return (
    <View style={{gap: 8, flexDirection: 'column'}}>
      <TextRenderer value={moment(value).format('DD MMM YY')} />
      <GroupContentItem value={moment(value).format('hh:mm A')} />
    </View>
  );
};

const RenderRaisedBy = ({value}) => {
  return (
    <Row style={{alignItems: 'center', gap: 8}}>
      <UserAvatar value={value} size={28} />
      <View style={{gap: 4, flexDirection: 'column'}}>
        <TextRenderer value={value.name} />
        <GroupContentItem value={value.employee_code} />{' '}
      </View>
    </Row>
  );
};

const RenderTime = ({value}) => {
  return (
    <GroupContentItem
      value={
        (Number.parseInt(value / 60)
          ? Number.parseInt(value / 60) + ' hrs '
          : '') +
        (value % 60) +
        ' mins'
      }
    />
  );
};

const TICKET_STATUS = {
  IN_PROGRESS: 'In Progress',
  TEMPORARILY_CLOSED: 'Temporarily Closed',
  CLOSED: 'Closed',
};
const SUBJECT = {
  type: 'text',
  header: 'Subject',
  field: 'subject',
  width: 200,
};
const RAISED_BY = {
  render: RenderRaisedBy,
  header: 'Employee',
  field: 'raised_by',
  width: 180,
};
const CURRENT_STATUS = {
  type: 'colorTag',
  align: 'center',
  header: 'Current Status',
  field: 'status',
  width: 150,
};
const CATEGORY = {
  type: 'text',
  header: 'Category',
  field: 'category.name',
  width: 150,
  numberOfLines: 2,
};
const DEPENDENCY = {
  type: 'text',
  header: 'Dependency',
  field: 'dependency_status',
  width: 150,
  numberOfLines: 2,
};
const WORKING_TIME = {
  render: RenderTime,
  header: 'Working Time',
  field: 'agent_working_time',
  width: 120,
};
const DEPENDENCY_TIME = {
  render: RenderTime,
  header: 'Working Time',
  field: 'agent_dependency_time',
  width: 150,
};
const EXPECTED_CLOSURE = {
  render: RenderDate,
  header: 'Target Closure',
  field: 'expected_close',
  width: 150,
};

const FIELDS = {
  subject: 1,
  raised_by: {
    name: 1,
    employee_code: 1,
    official_email_id: 1,
  },
  status: 1,
  category: {
    name: 1,
  },
  dependency_status: 1,
  agent_working_time: 1,
  expected_close: 1,
  employee_team: {
    name: 1,
  },
  raised_on_date: 1,
  ticket_number: 1,
  reopened: 1,
  priority: 1,
  description: 1,
  attachment: 1,
  last_agent_response_on: 1,
  last_employee_response_on: 1,
  temporarily_closed_on: 1,
  agent_assigned_on: 1,
};

const RenderName = ({row}) => {
  return (
    <View style={{gap: 8}}>
      <TextRenderer value={row.subject} style={{numberOfLines: 1}} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={`#${row?.ticket_number}`} />
        <GroupContentItem value={row?.category?.name} />
      </RowWithSeparator>
    </View>
  );
};

const RenderRaisedOn = ({row}) => {
  const {ERROR_HIGH, SUCCESS_HIGH, WARNING_HIGH, ACCENT5_HIGH} =
    useTheme('colors');
  const PRIORITY_WISE_COLOR = {
    Urgent: ERROR_HIGH,
    High: WARNING_HIGH,
    Medium: ACCENT5_HIGH,
    Low: SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        flexDirection: 'columns',
        gap: 8,
        borderLeftColor: PRIORITY_WISE_COLOR[row.priority],
        borderLeftWidth: 2,
        padding: 12,
        margin: -12,
      }}>
      <TextRenderer value={moment(row.raised_on_date).format('DD MMM YY')} />
      <GroupContentItem value={moment(row.raised_on_date).format('hh:mm a')} />
    </View>
  );
};

export const chatScreen = ({row, navigation, params}) => {
  navigation.navigate('ticket-history', {
    row,
    title: row?.subject,
    ...params,
  });
};

const RenderAssignedOn = ({row}) => {
  const {ERROR_HIGH, SUCCESS_HIGH, WARNING_HIGH, ACCENT5_HIGH} =
    useTheme('colors');
  const PRIORITY_WISE_COLOR = {
    Urgent: ERROR_HIGH,
    High: WARNING_HIGH,
    Medium: ACCENT5_HIGH,
    Low: SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        flexDirection: 'columns',
        gap: 8,
        borderLeftColor: PRIORITY_WISE_COLOR[row.priority],
        borderLeftWidth: 2,
        padding: 12,
        margin: -12,
      }}>
      <GroupContentItem
        value={moment(row.agent_assigned_on).format('DD MMM hh:mm A')}
      />
      <GroupContentItem
        value={moment(row.raised_on_date).format('DD MMM hh:mm A')}
      />
    </View>
  );
};
const RenderResponseDate = ({row}) => {
  const {last_agent_response_on, last_employee_response_on} = row;
  return (
    <View style={{gap: 8, flexDirection: 'column'}}>
      {last_employee_response_on && (
        <GroupContentItem
          value={moment(last_employee_response_on).format('DD MMM hh:mm A')}
        />
      )}
      {last_agent_response_on && (
        <GroupContentItem
          value={moment(last_agent_response_on).format('DD MMM hh:mm A')}
        />
      )}
    </View>
  );
};

export const OpenAssignedTicketsTable = props => {
  const {
    navigation,
    route: {params = {}},
    user: {employee = {}},
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['tickets'],
  });

  return (
    <Table
      {...props}
      api={`/tickets`}
      eventSourceId={'tickets'}
      onRowPress={({row}) => {
        chatScreen({row, navigation, params});
      }}
      fields={{...FIELDS, agent_dependency_time: 1}}
      filter={{
        status: {
          $nin: [TICKET_STATUS.CLOSED, TICKET_STATUS.TEMPORARILY_CLOSED],
        },
        agent: employee?._id,
      }}
      columns={[
        {
          header: 'Assigned On',
          render: RenderAssignedOn,
          width: 160,
        },
        {
          header: 'Description',
          render: RenderName,
          minWidth: 250,
        },
        RAISED_BY,
        EXPECTED_CLOSURE,
        DEPENDENCY,
        {
          header: 'Last Response',
          render: RenderResponseDate,
          width: 140,
        },
      ]}
      moreActions={[
        {
          title: 'Ticket Details',
          onPress: ({row}) => {
            navigation.navigate('ticket-detail', {
              ticketId: row?._id,
            });
          },
        },
        {
          title: 'Mark In Progress',
          confirm: {
            title: 'Mark In Progress',
            message: 'Are you sure you want to Mark In Progress?',
          },
          onPress: ({row}) => {
            return invoke({
              uri: `/tickets/${row?._id}`,
              props: {
                status: TICKET_STATUS.IN_PROGRESS,
              },
            });
          },
        },
        // {
        //   title: 'Change Category',
        //   onPress: ({row}) => {
        //     navigation.navigate('change-category-form', {
        //       ticketDetails: row,
        //     });
        //   },
        // },
        {
          title: 'Mark Temporarily Closed',
          onPress: ({row}) => {
            navigation.navigate('temporarily-closed-reason', {
              ticketDetails: row,
              status: TICKET_STATUS.TEMPORARILY_CLOSED,
            });
          },
        },
        {
          title: 'Mark Closed',
          confirm: {
            title: 'Mark Closed',
            message: 'Are you sure you want to Mark Closed?',
          },
          onPress: ({row}) => {
            return invoke({
              uri: `/tickets/${row?._id}`,
              props: {
                status: TICKET_STATUS.CLOSED,
                closed_on: new Date(),
              },
            });
          },
        },
        {
          title: 'Change Agent',
          onPress: ({row}) => {
            navigation.navigate('change-agent-form', {
              ticketDetails: row,
            });
          },
        },
        {
          title: 'Change Team',
          onPress: ({row}) => {
            navigation.navigate('change-department-form', {
              ticketDetails: row,
            });
          },
        },
        {
          title: 'Set Dependency',
          onPress: ({row}) => {
            navigation.navigate('set-dependency-form', {
              ticketDetails: row,
            });
          },
        },
      ]}
    />
  );
};

export const TemporarilyClosedAssignedTicketsTable = props => {
  const {
    route: {params = {}},
    navigation,
    user: {employee = {}},
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['tickets'],
  });

  return (
    <Table
      {...props}
      api={`/tickets`}
      eventSourceId={'tickets'}
      onRowPress={({row}) => {
        chatScreen({row, navigation, params});
      }}
      fields={{
        ...FIELDS,
        temporarily_closed_reason: 1,
        temporarily_closed_on: 1,
      }}
      filter={{
        status: TICKET_STATUS.TEMPORARILY_CLOSED,
        agent: employee?._id,
      }}
      columns={[
        {
          header: 'Assigned On',
          render: RenderAssignedOn,
          width: 160,
        },
        {
          header: 'Description',
          render: RenderName,
          minWidth: 250,
        },
        RAISED_BY,
        {
          render: RenderDate,
          header: 'Under Observation Since',
          field: 'temporarily_closed_on',
          width: 200,
        },
        {
          type: 'textArea',
          header: 'Reason',
          field: 'temporarily_closed_reason',
          width: 120,
          numberOfLines: 2,
        },
      ]}
      moreActions={[
        {
          title: 'Ticket Details',
          onPress: ({row}) => {
            navigation.navigate('ticket-detail', {
              ticketId: row?._id,
            });
          },
        },
        {
          title: 'Mark Closed',
          confirm: {
            title: 'Mark Closed',
            message: 'Are you sure you want to Mark Closed?',
          },
          onPress: ({row}) => {
            return invoke({
              uri: `/tickets/${row?._id}`,
              props: {
                status: TICKET_STATUS.CLOSED,
                closed_on: new Date(),
              },
            });
          },
        },
      ]}
    />
  );
};

const RenderClosedStatus = ({row}) => {
  const {is_delayed, delay_time = 0, early_time = 0} = row;
  const IsDelay = is_delayed ? 'Delayed' : 'On Time';
  let DelayText =
    'By ' +
    (Number.parseInt(delay_time / 60)
      ? Number.parseInt(delay_time / 60) + ' hrs '
      : '') +
    (delay_time % 60) +
    ' mins';
  if (isNaN(delay_time)) {
    DelayText = 'By ' + delay_time;
  }
  const EarlyText =
    (Number.parseInt(early_time / 60)
      ? Number.parseInt(early_time / 60) + ' hrs '
      : '') +
    (early_time % 60) +
    ' mins' +
    ' early';
  const {SUCCESS_HIGH, SUCCESS_LOW, ERROR_HIGH, ERROR_LOW} = useTheme('colors');
  const backgroundColorsMap = {
    Delayed: ERROR_LOW,
    'On Time': SUCCESS_LOW,
  };
  const textColorsMap = {
    Delayed: ERROR_HIGH,
    'On Time': SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        backgroundColor: backgroundColorsMap[IsDelay],
        padding: 12,
        margin: -12,
        gap: 8,
      }}>
      <TextRenderer
        value={IsDelay}
        style={{
          color: textColorsMap[IsDelay],
        }}
      />
      <GroupContentItem value={is_delayed ? DelayText : EarlyText} />
    </View>
  );
};

export const ClosedAssignedTicketsTable = props => {
  let {
    route: {params = {}},
    navigation,
    user: {employee = {}},
    filterParams,
  } = props;

  params = {
    ...params,
    ...filterParams,
  };
  const RatingRenderer = ({row, ...rest}) => {
    return <Rating {...rest} value={row['feedback']} readOnly />;
  };

  return (
    <Table
      {...props}
      params={params}
      api={`/tickets`}
      eventSourceId={'tickets'}
      onRowPress={({row}) => {
        chatScreen({row, navigation, params});
      }}
      fields={{
        ...FIELDS,
        closed_on: 1,
        time_taken_to_close: 1,
        feedback: 1,
        agent_working_time: 1,
        is_delayed:1,
        delay_time:1,
        early_time:1,
      }}
      filter={{status: TICKET_STATUS.CLOSED, agent: employee?._id}}
      columns={[
        {
          header: 'Assigned On',
          render: RenderAssignedOn,
          width: 160,
        },
        {
          header: 'Description',
          render: RenderName,
          minWidth: 250,
        },
        RAISED_BY,
        {
          render: RenderDate,
          header: 'Closed On',
          field: 'closed_on',
          width: 120,
        },
        {
          render: RenderClosedStatus,
          header: 'Resolution Time',
          width: 160,
        },
        {
          render: RatingRenderer,
          header: 'Feedback',
          width: 120,
        },
      ]}
      moreActions={[
        {
          title: 'Ticket Details',
          onPress: ({row}) => {
            navigation.navigate('ticket-detail', {
              ticketId: row?._id,
            });
          },
        },
      ]}
    />
  );
};
