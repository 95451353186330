import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {MultiStepForm} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {AssignEmailMessageSequence} from './AssignEmailMessageSequence';
import {AssignInmailMessageSequence} from './AssignInmailMessageSequence';
import {LoadEmailThemes} from './LoadEmailTheme';
import {LoadInmailThemes} from './LoadInmailThemes';
import {LoadProspectsTable} from './LoadProspectList';

export const OtherCampaignForm = props => {
  const {
    route: {params},
    navigation,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/othercampaigns',
    eventSourceId: 'OtherCampaigns',
    ...props,
  });
  const {product, row, afterSubmit} = params;
  const invoke = useInvoke({
    method: 'post',
    close: false,
    eventSourceId: ['OtherCampaigns'],
  });
  return (
    <MultiStepForm
      computations={{
        employee_team: [
          value => value?.assigned_to?.employee_team,
          ['assigned_to'],
        ],
      }}
      fields={{
        name: 1,
        date: 1,
        assigned_to: {
          name: 1,
          employee_team: 1,
        },
        communication_type: 1,
        target_audience: {
          name: 1,
          industry: 1,
        },
        campaign_type: 1,
        profile_owner: {
          name: 1,
        },
        channel: {name: 1},
        employee_team: {name: 1},
        organization: {name: 1},
      }}
      afterSubmit={afterSubmit}
      api={`/othercampaigns/${row?._id}`}
      onSubmit={onSubmit}
      header={'Add Inreach Campaign'}
      submitAction={'Save'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        experiment_on: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
        status: 'In Experiment',
      }}
      steps={[
        {
          title: 'About',
          fields: [
            {
              label: 'Target Audience',
              field: 'target_audience',
              type: 'autoComplete',
              api: `/buyerPersonas`,
              suggestionField: 'name',
              valueField: 'name',
              fields: {
                name: 1,
                industry: {
                  name: 1,
                },
              },
              secondarySuggestionField: 'industry.name',
              size: 'medium',
              required: true,
            },
            {
              label: 'Name',
              field: 'name',
              type: 'text',
              size: 'medium',
              required: true,
            },
            {
              label: 'Campaign Type',
              field: 'campaign_type',
              type: 'autoComplete',
              options: ['Email', 'Inmail'],
              required: true,
            },
            {
              label: 'Ownership',
              fields: [
                {
                  label: 'Owner',
                  field: 'assigned_to',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 'medium',
                  fields: {
                    name: 1,
                    official_email_id: 1,
                    employee_team: 1,
                  },
                  required: true,
                },
                {
                  label: 'Linkedin Profile',
                  field: 'profile_owner',
                  type: 'autoComplete',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  size: 'medium',
                  required: ({values}) => values?.campaign_type == 'Inmail',
                  visible: ({values}) => values?.campaign_type == 'Inmail',
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Channel',
                  field: 'channel',
                  type: 'autoComplete',
                  api: `/channels`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  filter: {
                    _id: {
                      $nin: ['66d2ec16f897151a53daf08b'],
                    },
                  },
                  required: true,
                },
                {
                  label: 'Employee Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  filter: {
                    _id: {
                      $in: [
                        '654c6ebd64126a8c199e9537',
                        '655b1c352722272f13e98953',
                      ],
                    },
                  },
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  type: 'radioGroup',
                  field: 'communication_type',
                  label: 'Communication Type',
                  options: ['Automatic', 'Manual'],
                  dependentFields: ['loaded_prospects', 'loaded_messages'],
                },
              ],
            },
          ],
          saveOnNext: true,
        },
        {
          title: 'Load Prospects',
          render: formProps => (
            <LoadProspectsTable
              {...props}
              formProps={formProps}
              source={'inreach prospects'}
            />
          ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-prospects-on-other-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Select Message',
          render: formProps =>
            formProps?.values.campaign_type == 'Inmail' ? (
              <LoadInmailThemes {...props} formProps={formProps} />
            ) : (
              <LoadEmailThemes {...props} formProps={formProps} />
            ),
          onNext: async ({values}) => {
            await invoke({
              uri: '/load-messages-on-other-campaign',
              props: values,
            });
          },
        },
        {
          title: 'Assign Sequence',
          fields: [
            {
              render: (_, formProps) =>
                formProps?.values.campaign_type == 'Inmail' ? (
                  <AssignInmailMessageSequence
                    {...props}
                    formProps={formProps}
                  />
                ) : (
                  <AssignEmailMessageSequence
                    {...props}
                    formProps={formProps}
                  />
                ),
            },
          ],
          onNext: _ => {
            navigation.goBack();
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditOtherCampaign = props => {
  return (
    <OtherCampaignForm
      mode="edit"
      header={'Inreach Campaign Detail'}
      {...props}
    />
  );
};
