import React from 'react';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {DateChip, InputBox} from '@unthinkable/react-chat';
import {useStyles} from '@unthinkable/react-theme';
import {ChatMessageStyles} from '../../../app-components/renders/chat/theme';
import {useAppStateContext} from '../../../providers/AppState';
import {FlatList, Row, View} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {TextRenderer} from '../../../components/form/Renderers';
import {getGroupedMessages} from '@unthinkable/react-chat/src/utils';
import moment from 'moment';
import {Time} from '@unthinkable/react-chat/src/Styles';
import {MentionsInput} from '@unthinkable/react-mentions-input';
import {FileInput} from '../../../components/form-editors/file/FileInput';
import {TicketHistoryMessage} from './TicketHistoryMessages';

const HISTORY_TYPES = {
  CREATED: 'Created',
  IN_PROGRESS: 'In Progress',
  TEMPORARILY_CLOSED: 'Temporarily Closed',
  CLOSED: 'Closed',
  ASSIGNED: 'Assigned',
  TEAM: 'Team',
  DEPENDENCY_STATUS: 'Dependency Status',
  MESSAGE: 'Message',
  REOPEN: 'Reopen',
};

const TicketChatList = props => {
  const {
    renderRow,
    data = [],
    timeField = 'time',
    onSend,
    disabled,
    ...rest
  } = props;
  const {fetch, user} = useAppStateContext();
  const groupedData = getGroupedMessages({data, timeField});
  const [attachment, setAttachment] = React.useState([]);
  return (
    <>
      <FlatList inverted data={groupedData} renderItem={renderRow} {...props} />
      {!disabled && (
        <InputBox
          {...rest}
          fetch={fetch}
          api={`/employeeSuggestions`}
          suggestionField="name"
          secondarySuggestionField="official_email_id"
          mentionField="id"
          onPress={async props => {
            await onSend({...props, attachment});
            setAttachment([]);
          }}
          sender={user.name}
          inputComponent={inputProps => {
            return (
              <View style={{flex: 1, flexDirection: 'row'}}>
                <View style={{flex: 1}}>
                  <MentionsInput {...inputProps} />
                </View>
                <View style={{width: 180}}>
                  <FileInput
                    multiple
                    placeholder=""
                    inline
                    value={attachment}
                    onChangeValue={setAttachment}
                  />
                </View>
              </View>
            );
          }}
        />
      )}
    </>
  );
};

export const TicketHistoryScreen = props => {
  const {
    route: {
      params: {row},
    },
  } = props;
  const styles = useStyles(ChatMessageStyles);
  const {user: {employee = {}} = {}, fetch} = useAppStateContext();
  const {primaryBubble, secondaryBubble} = styles;
  let {data} = useFetchData({
    api: `/getTicketHistoryData`,
    eventSourceId: ['MsgAdded'],
    filter: {
      ticket: row?._id,
    },
    fields: {
      comment: 1,
      sent_by: {
        name: 1,
      },
      agent_name: 1,
      team_name: 1,
      updatedAt: 1,
      type: 1,
      ticket: 1,
      dependency_status: 1,
      team: {
        name: 1,
      },
      attachment: 1,
    },
    sort: {
      updatedAt: 1,
    },
  });
  if (Array.isArray(data)) {
    data.unshift({
      type: HISTORY_TYPES.MESSAGE,
      comment: `${row?.description}`,
      updatedAt: row.raised_on_date,
      sent_by: row.raised_by,
      attachment: row?.attachment,
    });

    data.unshift({
      type: HISTORY_TYPES.CREATED,
      sent_by: row?.raised_by,
      ticket: row?._id,
      updatedAt: row.raised_on_date,
    });
  }
  let invoke = useInvoke({method: 'post', eventSourceId: ['MsgAdded','tickets']});
  let sendUri = `/ticketHistories`;

  const onSend = async ({message, mentions, attachment}) => {
    const messageData = {};
    messageData.type = HISTORY_TYPES.MESSAGE;
    messageData.comment = message;
    messageData.sent_by = employee?._id;
    messageData.updatedAt = new Date();
    messageData.ticket = row?._id;
    messageData.cc = mentions;
    messageData.attachment = attachment;

    // if (mentions.length) {
    //   const pattern = /@\[(.*?)\]\((.*?)\)/g;
    //   messageData.comment = message.replace(pattern, '@$1');
    // }

    await invoke({
      uri: sendUri,
      props: {
        ...messageData,
      },
    });
  };

  let previousDate = void 0;

  const RenderDate = ({item}) => {
    let currentDate = getZeroTimeDate(item?.updatedAt);
    if (!previousDate || getZeroTimeDate(previousDate) < currentDate) {
      previousDate = currentDate;
      return <DateChip date={currentDate} />;
    } else {
      return null;
    }
  };

  const renderRow = ({item}) => {
    const {type} = item || {};
    let changedPerson = '';
    let historyMessage = '';
    let value = '';
    if (item?.sent_by?._id == employee?._id) {
      changedPerson += 'You';
    } else {
      changedPerson += item?.sent_by?.name;
    }
    if (type == HISTORY_TYPES.CREATED) {
      historyMessage += `${changedPerson} created a Ticket.`;
    } else if (type == HISTORY_TYPES.MESSAGE) {
      return (
        <View>
          {item.updatedAt ? <RenderDate item={item} /> : void 0}
          <TicketHistoryMessage
            styles={styles}
            {...(employee?._id == item?.['sent_by']?._id
              ? {
                  position: 'right',
                  background: primaryBubble,
                }
              : {
                  sender: item?.sent_by?.name,
                  position: 'left',
                  background: secondaryBubble,
                })}
            time={item?.['updatedAt']}
            message={item?.['comment']}
            attachment={item?.attachment}
          />
        </View>
      );
    } else if (type == HISTORY_TYPES.IN_PROGRESS) {
      historyMessage += `${item.agent_name} have changed status to -`;
      value = item?.type;
    } else if (type == HISTORY_TYPES.DEPENDENCY_STATUS) {
      historyMessage += `Dependency Status has been changed to -`;
      value = item?.dependency_status;
    } else if (type == HISTORY_TYPES.ASSIGNED) {
      historyMessage += `Ticket has been assigned to -`;
      value = item.agent_name;
    } else if (type == HISTORY_TYPES.TEAM) {
      historyMessage += `Team has been changed to -`;
      value = item?.team_name;
    } else if (
      type == HISTORY_TYPES.TEMPORARILY_CLOSED ||
      type == HISTORY_TYPES.CLOSED
    ) {
      historyMessage +=
        type == HISTORY_TYPES.TEMPORARILY_CLOSED
          ? `Closed the ticket temporarily, Reason - `
          : `${changedPerson} closed the ticket.`;
      value = type == HISTORY_TYPES.TEMPORARILY_CLOSED ? item?.comment : '';
    } else if (type == HISTORY_TYPES.REOPEN) {
      historyMessage += 'Ticket has been reopened.';
    }
    return (
      <View>
        {item.updatedAt ? <RenderDate item={item} /> : void 0}
        <View
          style={{
            alignSelf:
              item?.sent_by?._id == employee?._id ? 'flex-end' : 'flex-start',
            flexDirection: 'column',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
          }}>
          <Row
            style={{
              alignItems: 'center',
            }}>
            <TextRenderer value={historyMessage} />
            <TextRenderer
              value={value}
              style={{
                fontWeight: 'bold',
              }}
            />
          </Row>
          <Time
            styles={{
              messageBox: {
                time: {
                  alignSelf: 'flex-end',
                },
              },
            }}>
            {moment(item.updatedAt).format('hh:mmA')}
          </Time>
        </View>
      </View>
    );
  };

  return (
    <TicketChatList
      {...props}
      disabled={row.status == 'Closed' || row.status == 'Temporarily Closed'}
      onSend={onSend}
      styles={styles}
      data={data}
      renderRow={renderRow}
      fetch={fetch}
      inverted={false}
    />
  );
};
