import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {
  Linking,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

const RenderWebsite = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  let {website = ''} = row || {};
  const underline = website ? {textDecoration: 'underline'} : void 0;

  website =
    website && (website.startsWith('https') ? website : `https://${website}`);

  return (
    <TouchableOpacity
      onPress={() => {
        website && Linking.openURL(website, '_blank');
      }}>
      <Text
        style={{
          ...rowText,
          ...underline,
        }}>
        {website}
      </Text>
    </TouchableOpacity>
  );
};

export const PotentialCustomersHubspotLeadsTable = props => {
  let {
    route: {params},
  } = props;

  const {
    populateHubspotContact,
    makeUqlFromHubSpotContact,
    generateHubSpotContactUQL,
    editHubSpotContact,
    pageVisits,
  } = leadControllers(props);

  return (
    <Table
      api={`/hubspotleads`}
      params={params}
      sort={{createdate: -1}}
      eventSourceId={['updateUQL', 'updateHubspotContact']}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
        },
        {
          field: 'country',
          header: 'Country',
          type: 'text',
          width: 150,
        },
        // {
        //   field: 'email',
        //   header: 'Email',
        //   type: 'text',
        // },
        {
          field: 'website',
          header: 'Website',
          render: RenderWebsite,
        },
        {
          field: 'requirements',
          header: 'Requirement',
          type: 'text',
        },
        // {
        //   field: 'first_url',
        //   header: 'First URL',
        //   type: 'text',
        // },
        {
          field: 'createdate',
          header: 'Added At',
          type: 'date',
        },
        {
          field: 'uqlUpdateStatus',
          header: 'UQL',
          type: 'boolean',
          width: 80,
        },
      ]}
      moreActions={[
        {
          title: 'Move to UQL',
          onPress: generateHubSpotContactUQL,
        },
        {
          title: 'Edit',
          onPress: editHubSpotContact,
        },
        {
          title: 'Page Visits',
          onPress: pageVisits,
        },
      ]}
    />
  );
};
