import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {TextRenderer} from '../../../components/form/Renderers';
import moment from 'moment';
import CountCellRender from '../../../app-components/renders/CountCellRender';

const updateStatus = ({row, status, invoke}) => {
  invoke({
    uri: `/trainingprograms/${row?._id}`,
    props: {
      status: status,
    },
  });
};

export const RenderPlan = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="Plan"
      style={{...rowText, color: colors['INFORMATION_HIGH']}}
    />
  );
};

export const TrainingProgramTable = props => {
  const {
    route: {params},
    navigation,
    filter = {},
    status,
    searchValue,
  } = props;

  const {product} = params;
  const invoke = useInvoke({
    eventSourceId: 'TrainingPrograms',
    method: 'put',
  });

  return (
    <Table
      eventSourceId={'TrainingPrograms'}
      api="/trainingPrograms"
      fields={{
        name: 1,
        description: 1,
        skill_metrics: {
          name: 1,
        },

        training_duration: 1,
        skill_leader: {
          name: 1,
          color: 1,
        },
        archived_on: 1,
        course_type: 1,
        course_duration: 1,
        training_roadmap: 1,
      }}
      filter={filter}
      addOnFilter={{status, product}}
      search={searchValue}
      searchFields={['name']}
      columns={[
        {
          field: 'name',
          header: 'Training',
          type: 'text',
        },
        {
          field: 'course_type',
          header: 'Type',
          type: 'colorTag',
          colorField: row =>
            row?.course_type == 'Tech' ? '#FFF7D6' : '#F5E0FF',
          width: 120,
        },
        {
          field: 'skill_metrics.name',
          header: 'Skill',
          type: 'text',
          width: 120,
        },
        {
          field: 'skill_leader',
          header: 'Leader',
          type: 'userAvatar',
          width: 98,
        },
        {
          render: CountCellRender,
          count_field: 'training_roadmap.length',
          isNestedObject: true,
          header: 'Topics',
          align: 'right',
          width: 80,
          visible: status == 'active',
        },
        {
          field: row => (row.course_duration ? row.course_duration + ' d' : ''),
          header: 'Duration',
          type: 'text',
          align: 'right',
          width: 85,
        },
        {
          render: RenderPlan,
          onPress: ({row}) => {
            navigation.navigate('plan-training-program', {
              ...params,
              training_program: row,
            });
          },
          visible: status == 'draft',
          width: 57,
        },
        {
          type: 'date',
          field: 'archived_on',
          header: 'Archived On',
          format: date => moment(date).format('DD MMM YY'),
          visible: status == 'archived',
          width: 120,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-training-program', {
              ...params,
              training_program: row,
            });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to Mark Active?',
          },
          visible: status == 'archived',
          onPress: ({row}) => updateStatus({row, status: 'active', invoke}),
        },
        {
          title: 'Archive',
          confirm: {
            title: 'Mark Archived',
            message: 'Are you sure you want to Mark Archived?',
          },
          visible: status == 'active',
          onPress: ({row}) => updateStatus({row, status: 'archived', invoke}),
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
          },
          onPress: ({row}) => updateStatus({row, status: 'deleted', invoke}),
          variant: 'error',
        },

        {
          title: 'Edit Plan',
          onPress: ({row}) => {
            navigation.navigate('plan-training-program', {
              ...params,
              training_program: row,
            });
          },
          visible: status == 'active',
        },
        {
          title: 'Duplicate',
          onPress: ({row}) => {
            navigation.navigate('duplicate-training-program', {
              ...params,
              training_program: row,
            });
          },
          visible: status == 'draft',
        },
      ]}
      {...props}
    />
  );
};
