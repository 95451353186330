import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {Text} from '@unthinkable/react-core-components';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import { useInvoke } from '../../../controllers/useInvoke';

const NAME = {
  header: 'Name',
  field: 'name',
};

const renderDaysValue = daysValues => {
  return daysValues ? <Text>True</Text> : <Text>False</Text>;
};

const ScheduleTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  return (
    <Table
      eventSourceId={'Schedule'}
      onRowPress={props => {
        navigation.navigate(`edit-schedule-form`, {...props});
      }}
      api={`/schedules`}
      params={params}
      search={searchValue}
      searchFields="name"
      columns={[
        NAME,
        {
          header: 'Monday',
          field: 'monday',
          render: ({row}) => {
            return renderDaysValue(row.monday);
          },
        },
        {
          header: 'Tuesday',
          field: 'tuesday',
          render: ({row}) => {
            return renderDaysValue(row.tuesday);
          },
        },
        {
          header: 'Wednesday',
          field: 'wednesday',
          render: ({row}) => {
            return renderDaysValue(row.wednesday);
          },
        },
        {
          header: 'Thursday',
          field: 'thursday',
          render: ({row}) => {
            return renderDaysValue(row.thursday);
          },
        },
        {
          header: 'Friday',
          field: 'friday',
          render: ({row}) => {
            return renderDaysValue(row.friday);
          },
        },
        {
          header: 'Saturday',
          field: 'saturday',
          render: ({row}) => {
            return renderDaysValue(row.saturday);
          },
        },
        {
          header: 'Sunday',
          field: 'sunday',
          render: ({row}) => {
            return renderDaysValue(row.sunday);
          },
        },
      ]}
    />
  );
};

const ShiftsTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  return (
    <Table
      eventSourceId={'Shifts'}
      onRowPress={props => {
        navigation.navigate(`edit-shifts-form`, {...props});
      }}
      api={`/shifts`}
      params={params}
      search={searchValue}
      searchFields="name"
      columns={[
        NAME,
        {
          header: 'Cut Off',
          field: 'cut_off',
        },
        {
          header: 'Duration',
          field: 'duration',
        },
        {
          header: 'Start Time',
          field: 'start_time',
        },
      ]}
    />
  );
};

const LeaveTypeTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  return (
    <Table
      eventSourceId={'LeaveType'}
      onRowPress={props => {
        navigation.navigate(`edit-leave-type-form`, {...props});
      }}
      api={`/leavetypes`}
      params={params}
      search={searchValue}
      searchFields={['name', 'short_name']}
      columns={[
        NAME,
        {
          header: 'Short Name',
          field: 'short_name',
        },
      ]}
    />
  );
};

const LeavePolicyTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  return (
    <Table
      eventSourceId={'LeavePolicy'}
      onRowPress={props => {
        navigation.navigate(`edit-leave-policy-form`, {...props});
      }}
      api={`/leavepolicies`}
      params={params}
      search={searchValue}
      searchFields="name"
      columns={[
        NAME,
        {
          header: 'Sandwich Policy',
          field: 'is_sandwich_applicable',
        },
      ]}
    />
  );
};

const HolidayCalendarTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;

  const {filterValues} = useFilter();
  const {params: filterParams = {}} = filterValues;
  return (
    <Table
      eventSourceId={'HolidayCalendar'}
      onRowPress={props => {
        navigation.navigate(`edit-holiday-calendar-form`, {...props});
      }}
      api={`/holidaycalendars`}
      params={filterParams}
      search={searchValue}
      searchFields="name"
      columns={[
        NAME,
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
        },
      ]}
    />
  );
};

const CronHistoryTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  return (
    <Table
      eventSourceId={'CronHistory'}
      fields={{
        date: 1,
        location: {
          name: 1,
        },
        employee: {
          name: 1,
        },
      }}
      onRowPress={props => {
        navigation.navigate(`edit-cron-history-form`, {...props});
      }}
      api={`/cronhistories`}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
        },
        {
          header: 'Location',
          field: 'location.name',
        },
        {
          header: 'Employee',
          field: 'employee.name',
        },
      ]}
    />
  );
};

const PunchHistoryTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'PunchHistory',
  });

  const deletePunchHistory = ({ uri }) => {
    invoke({
      uri,
    });
  };

  return (
    <Table
      eventSourceId={'PunchHistory'}
      fields={{
        date: 1,
        location: {
          name: 1,
        },
      }}
      onRowPress={props => {
        navigation.navigate(`edit-punch-history-form`, {...props});
      }}
      api={`/punchhistories`}
      sort={{date: -1}}
      columns={[
        {
          header: 'Date',
          field: 'date',
          type: 'date',
        },
        {
          header: 'Location',
          field: 'location.name',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          variant: 'error',
          confirm: {
            title: 'Delete Punch History',
            message: 'Are you sure you want to delete this Punch History?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deletePunchHistory({
              uri: `/punchhistories/${row?._id}`,
            });
          },
        },
      ]}
    />
  );
};

export {
  ScheduleTable,
  ShiftsTable,
  LeaveTypeTable,
  LeavePolicyTable,
  HolidayCalendarTable,
  CronHistoryTable,
  PunchHistoryTable,
};
