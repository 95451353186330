import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab/TabView';
import { TableHeader } from '../../../components/table/Headers';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import { TrainingProgramTable } from './TrainingProgramTable';

export const TrainingProgramTab = props => {
  const {route: {params} = {},fromSkillLeader} = props;
  const {filterValues, applyFilter, searchValue, onChangeFilter} = useFilter();
  const {filter={}} = filterValues || {};
  const {
    user: {employee},
  } = useAppStateContext();
  if (fromSkillLeader) {
    filter['skill_leader'] = employee?._id;
  }
  const {product} = params;
  const tabs = {
    draftTab: {
      label: 'Unplanned',
      filter:{
        ...filter,
        product,
        status:'draft'
      },
      api:"/trainingPrograms",
      eventSourceId:'TrainingPrograms',
      view: (
        <TrainingProgramTable
          {...props}
          searchValue={searchValue}
          filter={filter}
          status="draft"
        />
      ),
    },
    activeTab: {
      api:"/trainingPrograms",
      filter:{
        ...filter,
        product,
        status:'active'
      },
      label: 'Active',
      eventSourceId:'TrainingPrograms',
      view: (
        <TrainingProgramTable
          {...props}
          searchValue={searchValue}
          filter={filter}
          status="active"
        />
      ),
    },
    droppedTab: {
      label: 'Archived',
      api:"/trainingPrograms",
      filter:{
        ...filter,
        product,
        status:'archived'
      },
      eventSourceId:'TrainingPrograms',
      view: (
        <TrainingProgramTable
          {...props}
          searchValue={searchValue}
          filter={filter}
          status="archived"
        />
      ),
    },
  };
  let actions = [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search"
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Skills',
          api: '/skillmetrics',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'skill_metrics',
        },
      ]}
    />,
    <AddButton
      title="Skill Course"
      view="add-training-program"
      params={params}
    />,
  ];
  return (
    <TabView
      headerComponent={<TableHeader title="Skill Course" />}
      tabs={tabs}
      actions={actions}
      params={params}
      {...props}
    />
  );
};
