export const staffAugmentationControllers = props => {
  const {params, navigation} = props;
  const assignedCount = ({row}) => {
    navigation.navigate(`staff-augmentation-resource`, {
      row,
      filter: {
        opportunity_id: row?._id,
        invalid_interview: {$ne: true},
      },
    });
  };

  const activeCount = ({row}) => {
    navigation.navigate(`staff-augmentation-resource`, {
      row,
      filter: {
        opportunity_id: row?._id,
        status: 'Active',
        outcome: null,
        // invalid_interview: {$exists: false},
        invalid_interview: {$ne: true},
      },
    });
  };

  const activeCandidateScreen = ({row}) => {
    navigation.navigate(`active-candidate-resource-table`, {
      row,
      filter: {
        opportunity_id: row?._id,
        status: 'Active',
        outcome: null,
        invalid_interview: {$ne: true},
      },
    });
  };
  const activeCandidateScreenDeliveryOwner = ({row}) => {
    navigation.navigate(`active-candidate-resource-table-deliveryowner`, {
      row,
      filter: {
        opportunity_id: row?._id,
        status: 'Active',
        outcome: null,
        invalid_interview: {$ne: true},
      },
    });
  };

  const lostCandidateScreen = ({row}) => {
    navigation.navigate(`lost-candidate-resource-table`, {
      row,
      filter: {
        opportunity_id: row?._id,
        outcome: 'Rejected',
        invalid_interview: {$ne: true},
      },
    });
  };

  const dropCandidateScreen = ({row}) => {
    navigation.navigate(`drop-candidate-resource-table`, {
      row,
      filter: {
        opportunity_id: row?._id,
        outcome: 'Drop',
        invalid_interview: {$ne: true},
      },
    });
  };

  const WonCandidateScreen = ({row}) => {
    navigation.navigate(`won-candidate-resource-table`, {
      row,
      filter: {
        opportunity_id: row?._id,
        outcome: 'Selected',
        invalid_interview: {$ne: true},
      },
    });
  };

  const selectedCount = ({row}) => {
    navigation.navigate(`staff-augmentation-resource`, {
      row,
      filter: {
        opportunity_id: row?._id,
        outcome: 'Selected',
      },
    });
  };

  const opportunityCommunications = ({row}) => {
    navigation.navigate(`show-communication`, {
      ...params,
      row,
      props: {
        fromOpportunity: true,
      },
    });
  };

  return {
    assignedCount,
    activeCount,
    selectedCount,
    opportunityCommunications,
    activeCandidateScreen,
    lostCandidateScreen,
    dropCandidateScreen,
    WonCandidateScreen,
    activeCandidateScreenDeliveryOwner
  };
};
