import React from 'react';
import { Form, InlineForm } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

export const ThreadForm = props => {
  let {
    navigation,
    route: { params },
    mode,
  } = props;

  const { user } = useAppStateContext();

  const { row, threadForm } = params;
  const { onSubmit } = useFormSubmit({
    uri: '/threads',
    eventSourceId: 'threadAdded',
    ...props,
  });

  return (
    (mode || threadForm) ?
      <Form
        api={`/threads/${row?._id}`}
        fields={{
          name: 1,
          employee_team: { name: 1 },
          organization: { name: 1 },
          project: { project: 1 },
          milestone: { milestone: 1 },
        }}
        beforeSubmit={({ data }) => {
          if (mode) {
            return data;
          }
          else {
            return {
              data: {
                ...data,
                status: 'Active',
                collaborators: user?._id
              }
            };
          }
        }}
        onSubmit={onSubmit}
        header={{ title: 'Add Threads' }}
        layoutFields={[
          {
            label: 'Thread',
            type: 'text',
            field: 'name',
            required: true,
          },
          {
            label: 'Employee Team',
            type: 'autoComplete',
            api: '/employeeteams',
            field: 'employee_team',
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            label: 'Organization',
            type: 'autoComplete',
            api: '/organizations',
            field: 'organization',
            suggestionField: 'name',
            valueField: 'name',
          },
          {
            groups: [
              {
                label: 'Milestone',
                fields: [
                  {
                    label: 'Project',
                    field: 'project',
                    type: 'autoComplete',
                    api: `/projects`,
                    suggestionField: 'project',
                    valueField: 'project',
                  },
                  {
                    label: 'MileStone',
                    field: 'milestone',
                    type: 'autoComplete',
                    api: `/projectmilestones`,
                    filter: ({ values }) => {
                      const { project } = values;
                      const { _id: projectId } = project || {};
                      return { project_id: projectId };
                    },
                    suggestionField: 'milestone',
                    valueField: 'milestone',
                  },
                ],
              },
            ]
          }
        ]}
        {...props}
      /> :
      <InlineForm
        api={`/threads/${row?._id}`}
        fields={{
          name: 1,
        }}
        onSubmit={onSubmit}
        beforeSubmit={({ data }) => {
          return {
            data: {
              ...data,
              status: 'Active',
              collaborators: user?._id
            }
          };
        }}
        layoutFields={[
          {
            label: 'Thread',
            type: 'text',
            field: 'name',
            required: true,
          },
        ]}
        {...props}
      />
  );
};

export const CollaboratorForm = props => {

  const {
    navigation,
    route: { params },
  } = props;
  const { row } = params;
  const { onSubmit } = useFormSubmit({
    uri: '/threads',
    eventSourceId: 'collaboratorAdded',
    ...props,
  });

  return (
    <Form
      api={`/threads/${row?._id}`}
      fields={{
        collaborators: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      header={{ title: 'Add Threads' }}
      layoutFields={[
        {
          label: 'collaborators',
          type: 'multiAutoComplete',
          field: 'collaborators',
          api: '/users',
          filter: {
            status: 'a',
          },
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const EditThreadForm = props => {
  return <ThreadForm header={{ title: 'Edit Thread' }} mode="edit" {...props} />;
};

export const EditCollaboratorForm = props => {
  return <CollaboratorForm header={{ title: 'Add Collaborators' }} mode="edit" {...props} />;
};
