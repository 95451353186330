import moment from 'moment';

export const getTime = (time, dateFormat = 'DD MMM YY') => {
  let getYear = new Date(moment(time).format('YYYY')).getFullYear();
  let currentYear = new Date().getFullYear();

  if (getYear === currentYear) {
    dateFormat = 'DD MMM';
  }
  return moment(time).format(dateFormat);
};

export const compareTwoDatesGreaterEquals = (date1, date2) => {
  if (date1 && date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    let day1 = date1.getUTCDate();
    let month1 = date1.getUTCMonth();
    let year1 = date1.getUTCFullYear();

    let day2 = date2.getUTCDate();
    let month2 = date2.getUTCMonth();
    let year2 = date2.getUTCFullYear();

    let d1 = new Date('2014-01-10');
    d1.setUTCFullYear(year1);
    d1.setUTCMonth(month1);
    d1.setUTCDate(day1);
    d1.setUTCHours(0);
    d1.setUTCMinutes(0);
    d1.setUTCSeconds(0);
    d1.setUTCMilliseconds(0);

    let d2 = new Date('2014-01-10');
    d2.setUTCFullYear(year2);
    d2.setUTCMonth(month2);
    d2.setUTCDate(day2);
    d2.setUTCHours(0);
    d2.setUTCMinutes(0);
    d2.setUTCSeconds(0);
    d2.setUTCMilliseconds(0);

    if (Number(d1.getTime()) >= Number(d2.getTime())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const updateStatusByIdInData = ({data, socketData = {}}) => {
  const index = data.findIndex(item => item._id === socketData._id);
  if (index !== -1) {
    if (socketData?.status) {
      data[index].status = socketData.status;
    }
    if (socketData?.error) {
      data[index].error = socketData.error;
    }
  }
  return data;
};
