import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

export const AddOutreachCampaign = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/inmailCampaigns',
    eventSourceId: 'inmailCampaignAdded',
    ...props,
  });

  const {
    route: {params},
  } = props;
  const {product, row, afterSubmit, searchValue} = params;
  return (
    <Form
      api={`/inmailCampaigns/${row?._id}`}
      fields={{
        name: 1,
        date: 1,
        inmail: {
          inmail_subject: 1,
        },
        profile_owner: {
          name: 1,
        },
        assigned_to: {
          name: 1,
        },
        communication_type: 1,
        target_audience: {
          name: 1,
          industry: 1,
        },
        linkedin_profile: {email: 1},
        email_profile: {email: 1},
      }}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
      header={'Add Inmail Outreach Campaign'}
      submitAction={'Save Setup'}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        experiment_on: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
        name: searchValue,
        status: 'In Experiment',
        system_controlled: true,
      }}
      layoutFields={[
        {
          label: 'Target Audience',
          field: 'target_audience',
          type: 'autoComplete',
          api: '/buyerPersonas',
          suggestionField: 'name',
          valueField: 'name',
          size: 'medium',
          fields: {
            name: 1,
            industry: 1,
          },
          required: true,
        },
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          size: 'medium',
          required: true,
        },
        {
          label: 'Ownership',
          fields: [
            {
              label: 'Owner',
              field: 'assigned_to',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 'medium',
              required: true,
            },
            {
              label: 'Linkedin Profile',
              field: 'linkedin_profile',
              type: 'autoComplete',
              api: '/linkedinprofiles',
              suggestionField: 'email',
              valueField: 'email',
              size: 'medium',
              required: true,
            },
            {
              label: 'Email Profile',
              field: 'email_profile',
              type: 'autoComplete',
              api: '/emailprofiles',
              suggestionField: 'email',
              valueField: 'email',
              size: 'medium',
              required: true,
            },
          ],
        },
        {
          fields: [
            {
              type: 'radioGroup',
              field: 'communication_type',
              label: 'Communication Type',
              options: ['Automatic', 'Manual'],
              dependentFields: ['loaded_prospects', 'loaded_messages'],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditOutreachCampaignForm = props => {
  return (
    <AddOutreachCampaign
      mode="edit"
      header={'Inmail Campaign Detail'}
      {...props}
    />
  );
};
