import React from 'react';
import {Table} from '../../../components/table/Table';
import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab';
import {useInvoke} from '../../../controllers/useInvoke';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import images from '../../../images';
import { ThreadGoalForm } from './AddThreadGoalForm';

const {Owner} = images;

const ThreadGoalAndIssuesTable = props => {
    const {
        navigation,
        route: {breadcrumb , ...params},
        searchValue,
        addOnFilter,
        isCompleted,
        issues,
      } = props;
      
      const updateInvoke = useInvoke({
        method: 'put',
        eventSourceId: 'markCompleted',
      });

      return (
        <>
        {!isCompleted ? <ThreadGoalForm props={{...props, issues}}/> : void 0}
        <Table
          eventSourceId={['threadGoal', 'markCompleted']}
          api={issues ? '/threadissues' : '/threadgoals'}
          search={searchValue}
          searchFields={issues ? 'issue' : 'goal'}
          fields={{
            owner: {
              name: 1,
              color: 1,
            },
            goal: 1,
            issue: 1,
          }}
          filter={{...addOnFilter}}
          onRowPress={({row})=>{
            row = {...row, issues};
            navigation.navigate('edit-thread-goal-issue', {row});
          }}
          columns={[
            {
              header: issues ? 'Issue' : 'Goal',
              field: issues ? 'issue' : 'goal',
              type: 'text',
            },
            {
              header: {icon: Owner},
              field: 'owner.name',
              type: 'colorChip',
              colorField: 'owner.color',
              align: 'center',
            },
          ]}
          moreActions={() => 
            !isCompleted ?
            [
                {
                    title: 'Mark Completed',
                    confirm: {
                        title: 'Mark Completed',
                        message:
                            `Are you sure you want to mark this ${issues ? 'issue' : 'goal'} as completed ?`,
                        confirmText: 'Confirm',
                        },
                    onPress: ({row}) => {
                        updateInvoke({
                        uri: issues ? `/threadissues/${row?._id}` : `/threadgoals/${row?._id}`,
                        props: {
                            status: 'Completed',
                        }
                      });
                    },
                },
          ] : []}
        />
        </>
      );
};
  
export const ThreadGoalTabs = props => {
    const {
      route: {params},
    } = props;
  
    const {thread} = params;
    const threadFilter = thread?._id ? {thread: thread._id} : {};

    const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
      params: {},
    });
    const {filter: extraFilter} = filterValues;
  
    let addOnFilter = {
      ...extraFilter,
      ...threadFilter,
    };
  
    return (
      <TabView
        tabs={{
          active: {
            label: 'Active',
            api: '/threadgoals',
            eventSourceId: ['threadGoal', 'markCompleted'],
            filter: {
              status: 'Active',
              ...addOnFilter,
            },
            search: searchValue,
            searchFields: 'goal',
            view: (
              <ThreadGoalAndIssuesTable
                searchValue={searchValue}
                addOnFilter={{
                  status: 'Active',
                  ...addOnFilter,
                }}
                isCompleted={false}
                issues={false}
              />
            ),
          },
          completed: {
            label: 'Completed ',
            api: '/threadgoals',
            eventSourceId: ['threadGoal', 'markCompleted'],
            filter: {
              status: 'Completed',
              ...addOnFilter,
            },
            search: searchValue,
            searchFields: 'goal',
            view: (
              <ThreadGoalAndIssuesTable
                searchValue={searchValue}
                addOnFilter={{
                  status: 'Completed',
                  ...addOnFilter,
                }}
                isCompleted={true}
                issues={false}
              />
            ),
          },
        }}
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        ]}
        {...props}
      />
    );
};
  
export const ThreadIssuesTabs = props => {
    const {
      route: {params},
    } = props;
  
    const {thread} = params;
    const threadFilter = thread?._id ? {thread: thread._id} : {};

    const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
      params: {},
    });
    const {filter: extraFilter} = filterValues;
  
    let addOnFilter = {
      ...extraFilter,
      ...threadFilter,
    };
  
    return (
      <TabView
        tabs={{
          active: {
            label: 'Active',
            api: '/threadissues',
            eventSourceId: ['threadGoal', 'markCompleted'],
            filter: {
              status: 'Active',
              ...addOnFilter,
            },
            search: searchValue,
            searchFields: 'issue',
            view: (
              <ThreadGoalAndIssuesTable
                searchValue={searchValue}
                addOnFilter={{
                  status: 'Active',
                  ...addOnFilter,
                }}
                isCompleted={false}
                issues={true}
              />
            ),
          },
          completed: {
            label: 'Completed ',
            api: '/threadissues',
            eventSourceId: ['threadGoal', 'markCompleted'],
            filter: {
              status: 'Completed',
              ...addOnFilter,
            },
            search: searchValue,
            searchFields: 'issue',
            view: (
              <ThreadGoalAndIssuesTable
                searchValue={searchValue}
                addOnFilter={{
                  status: 'Completed',
                  ...addOnFilter,
                }}
                isCompleted={true}
                issues={true}
              />
            ),
          },
        }}
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        ]}
        {...props}
      />
    );
};
