import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {
  CronHistoryTable,
  HolidayCalendarTable,
  LeavePolicyTable,
  LeaveTypeTable,
  PunchHistoryTable,
  ScheduleTable,
  ShiftsTable,
} from './AttendanceTableView';
import {AddButton} from '../../../components/button/AddButton';

export const AttendanceTabView = props => {
  const {route: {params} = {}} = props;
  const {searchValue, onChangeFilter} = useFilter();

  const tabs = {
    schedule: {
      label: 'Schedule',
      view: <ScheduleTable searchValue={searchValue} />,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton title={'Add Schedule'} view="add-schedule-form" />,
      ],
    },
    shifts: {
      label: 'Shifts',
      view: <ShiftsTable searchValue={searchValue} />,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton title={'Add Shifts'} view="add-shifts-form" />,
      ],
    },
    leave_types: {
      label: 'Leave Types',
      view: <LeaveTypeTable searchValue={searchValue} />,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton title={'Add Leave Types'} view="add-leave-type-form" />,
      ],
    },
    leave_policies: {
      label: 'Leave Policies',
      view: <LeavePolicyTable searchValue={searchValue} />,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton title={'Add Leave Policies'} view="add-leave-policy-form" />,
      ],
    },
    holiday_calendar: {
      label: 'Holiday Calendar',
      view: <HolidayCalendarTable searchValue={searchValue} />,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AddButton
          title={'Add Holiday Calendar'}
          view="add-holiday-calendar-form"
        />,
      ],
    },
    // cron_history: {
    //   label: 'Cron History',
    //   view: <CronHistoryTable />,
    //   actions: [
    //     <AddButton title={'Add Cron History'} view="add-cron-history-form" />,
    //   ],
    // },
    // punch_history: {
    //   label: 'Punch History',
    //   view: <PunchHistoryTable />,
    //   actions: [
    //     <AddButton title={'Add Punch History'} view="add-punch-history-form" />,
    //   ],
    // },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
