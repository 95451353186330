import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ProjectMarkHoldForm = props => {
  const {
    route: {params},
  } = props;
  const {row = {}} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/projects/' + row?._id,
    eventSourceId: 'Project',
    method: 'put',
  });

  return (
    <Form
      onSubmit={onSubmit}
      header="Mark Hold"
      submitAction="Save"
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            project_status: 'h',
          },
        };
      }}
      layoutFields={[
        {
          label: 'Hold Date',
          field: 'on_hold_date',
          type: 'date',
          required: true,
        },
        {
          label: 'Reason',
          field: 'on_hold_reason',
          type: 'textArea',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
