import React, {useState} from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '../../../components/chip/Chip';
import {Col, Image, Text, View} from '@unthinkable/react-core-components';
import {leadControllers} from '../../leadGeneration/controllers/leadControllers';
import moment from 'moment';
import {customerController} from '../controller/CustomerController';
import {Tag} from '../../../components/chip/Chip';
import {useInvoke} from '../../../controllers/useInvoke';
import {numDifferentiation} from './CustomerOverview';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {TableHeader} from '../../../components/table/Headers';
import {AmountRender} from '../../order/views/components/AmountRender';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

function dateDiffInDays(fromDate, toDate) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
  );
  const utc2 = Date.UTC(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
  );
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (row['last_communication_date'] &&
          moment(row['last_communication_date']).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const RenderActions = ({row = {}, styles = {}, navigation}) => {
  const [loading, setLoading] = useState(false);
  const colors = useTheme('colors');

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Customer'],
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.is_customer_nurturable === true) {
    return (
      <Text style={{...rowText, color: colors.SUCCESS_HIGH}}>
        {'Nurturable'}
      </Text>
    );
  } else if (row.is_customer_nurturable === false) {
    return (
      <Text style={{...rowText, color: colors.WARNING_HIGH}}>
        {'Not Nurturable'}
      </Text>
    );
  } else if (!row.is_customer_nurturable) {
    return (
      <View style={{flexDirection: 'row', gap: 20}}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/customers/${row._id}`,
                  props: {
                    is_customer_nurturable: true,
                  },
                });
              }}>
              <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
                Yes{' '}
              </Text>
            </View>
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/customers/${row._id}`,
                  props: {
                    is_customer_nurturable: false,
                  },
                });
              }}>
              <Text style={{...rowText, color: colors.INFORMATION_HIGH}}>
                No
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }
};

const RenderOpenSince = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.first_order_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.first_order_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Tenure: {row.open_since} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderOpenSinceForChurn = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  let todayDate = getZeroTimeDate(new Date());

  let open_since = dateDiffInDays(
    getZeroTimeDate(row.first_order_date),
    todayDate,
  );

  if (row?.first_order_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.first_order_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Tenure: {open_since} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderOpenSinceForInactive = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.first_order_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.first_order_date).format('DD MMM YY')}
          </Text>
          {row?.dormant_date ? (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              Tenure : {row.closed_since} d
            </Text>
          ) : (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              Legal for: {row.closed_since} d
            </Text>
          )}
          {/* <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Tenure for: {row.open_since} d
          </Text> */}
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderClosedOn = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.dormant_date || row?.legal_marked_on) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.dormant_date || row?.legal_marked_on).format(
              'DD MMM YY',
            )}
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const unmarkDuplicate = ({row, invoke}) => {
  invoke({
    uri: `/customers/${row?._id}`,
    props: {
      is_duplicate: null,
    },
  });
};

const markActive = ({row, invoke}) => {
  let active_dates = row?.active_dates || [];
  active_dates.push(getZeroTimeDate(new Date()));
  invoke({
    uri: `/customers/${row?._id}`,
    props: {
      lost_type: null,
      active_status: 'Active',
      dormant_date: null,
      is_dormant: null,
      active_dates: active_dates,
      lost_reason: null,
      //while marking a customer active on manaze they dont set the lost_type as null
      //should i also maintain a dormant date array
    },
  });
};

const RenderLostReason = props => {
  const {row} = props;
  const colors = useTheme('colors');
  if (
    row?.lost_reason == 'Project(s) Completed' ||
    row?.lost_reason == 'Poor delivery quality'
  ) {
    return (
      <>
        <Col gap={2}>
          <Tag value={row?.lost_reason} color={colors.WARNING_MEDIUM} />
          <Text style={{fontSize: 13, color: colors.NEUTRAL_LOW}}>
            On {moment(row?.dormant_date).format('DD MMM, YY')}
          </Text>
        </Col>
      </>
    );
  } else if (row?.lost_reason == 'Payment Issue') {
    return (
      <>
        <Col gap={2}>
          <Tag value={row?.lost_reason} color={colors.ERROR_MEDIUM} />
          <Text style={{fontSize: 13, color: colors.NEUTRAL_LOW}}>
            On {moment(row?.dormant_date).format('DD MMM, YY')}
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

// const RenderCustomer = props => {
//   const {row, navigation} = props;
//   const icons = useTheme('icons');
//   const fonts = useTheme('fonts');
//   const colors = useTheme('colors');
//   if (row?.name) {
//     if (row?.is_duplicate) {
//       return (
//         <>
//           <Row style={{alignItems: 'center'}} gap={10}>
//             <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
//               {row?.name}
//             </Text>
//             <TouchableOpacity
//               onPress={() => {
//                 navigation.navigate('duplicate-customer-parent', {
//                   row,
//                 });
//               }}>
//               <Image source={icons.EstChange} style={{height: 14, width: 14}} />
//             </TouchableOpacity>
//           </Row>
//         </>
//       );
//     } else {
//       return (
//         <>
//           <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
//             {row?.name}
//           </Text>
//         </>
//       );
//     }
//   } else {
//     return void 0;
//   }
// };

const RenderCustomer = props => {
  // no duplicate pop up right now ask about toubable opca
  const {row} = props;
  const {CustomerOpportunity} = useTheme('icons');

  if (row?.name) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.name}
        variant="secondary"
        items={[
          {
            value: row?.employee_count?.number,
            backgroundColor: row?.employee_count?.color,
            numberOfLines: 1,
            // onPress: () => {
            //   console.log(':::::::::::checking');
            // },
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
          {
            value: row?.country?.name,
            backgroundColor: row?.country?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const RenderOverDueAmt = props => {
  let {
    row,
    value,
    format,
    formatOptions,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (value) {
    value = format(value, formatOptions);
    return (
      <>
        <Col style={{alignItems: 'flex-end'}}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: colors.ERROR_HIGH,
            }}>
            {value}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Max aging: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.ERROR_HIGH,
            fontSize: 13,
          }}>
          0
        </Text>
      </>
    );
  }
};

const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <>
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          color: colors.NEUTRAL_LOW,
        }}>
        {row.lead_score}
      </Text>
    </>
  );
};

const ACCOUNT_OWNER = {
  header: 'Account Owner',
  field: 'owner',
  type: 'userAvatarChip',
};

const CUSTOMER_NAME = {
  header: 'Customer',
  field: 'name',
};

const EMPLOYEE_COUNT = {
  header: 'Size',
  field: 'employee_count.number',
};

const COUNTRY = {
  header: 'Country',
  field: 'country.name',
  colorField: 'country.color',
  type: 'colorTag',
};

const INDUSTRY = {
  header: 'Industry',
  field: 'industry.name',
  colorField: 'industry.color',
  type: 'colorTag',
};

const STATUS = {
  header: 'Status',
  field: 'active_status',
};

const BUSINESS = {
  header: 'Business',
  field: 'business',
};

const ACQUIRED_ON = {
  header: 'Acquired On',
  field: 'created_on',
  type: 'date',
};

const LEAD_VALUE = {
  header: 'Score',
  field: 'lead_score',
  render: leadScoreRender,
  toFixed: 2,
  align: 'right',
  aggregate: true,
};

const LAST_COMMUNICATION = customerCommunications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: customerCommunications,
});

export const AllCustomerTable = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    filter,
    searchValue,
    filterParams,
    activeTabFilter,
  } = props;

  const {
    params: {period},
  } = filterValues || params.filterValues;

  params = {period, ...params, ...filterParams};
  const {product} = params;
  const {customerCommunications} = leadControllers(props);
  const {post, download} = useAppStateContext();
  const {activityLogOfCustomer, CustomerCommunications} =
    customerController(props);
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...filter, ...activeTabFilter},
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Name: 'name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',
                      'PM contact 1 First Name': 'pm_contact_1_first_name',
                      'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      'PM contact 1 Email': 'pm_contact_1_email',
                      'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      'PM contact 2 First Name': 'pm_contact_2_first_name',
                      'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      'PM contact 2 Email': 'pm_contact_2_email',
                      'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      'PM contact 3 First Name': 'pm_contact_3_first_name',
                      'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      'PM contact 3 Email': 'pm_contact_3_email',
                      'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      'PM contact 4 First Name': 'pm_contact_4_first_name',
                      'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      'PM contact 4 Email': 'pm_contact_4_email',
                      'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      'PM contact 5 First Name': 'pm_contact_5_first_name',
                      'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      'PM contact 5 Email': 'pm_contact_5_email',
                      'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      'Sales contact 1 First Name':
                        'sales_contact_1_first_name',
                      'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      'Sales contact 1 Email': 'sales_contact_1_email',

                      'Sales contact 2 First Name':
                        'sales_contact_2_first_name',
                      'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      'Sales contact 2 Email': 'sales_contact_2_email',

                      'Sales contact 3 First Name':
                        'sales_contact_3_first_name',
                      'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      'Sales contact 3 Email': 'sales_contact_3_email',

                      'Sales contact 4 First Name':
                        'sales_contact_4_first_name',
                      'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      'Sales contact 4 Email': 'sales_contact_4_email',

                      'Sales contact 5 First Name':
                        'sales_contact_5_first_name',
                      'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      'Sales contact 5 Email': 'sales_contact_5_email',

                      'Billing contact 1 First Name': 'contact_1_first_name',
                      'Billing contact 1 Last Name': 'contact_1_last_name',
                      'Billing contact 1 Email': 'contact_1_email',

                      'Billing contact 2 First Name': 'contact_2_first_name',
                      'Billing contact 2 Last Name': 'contact_2_last_name',
                      'Billing contact 2 Email': 'contact_2_email',

                      'Billing contact 3 First Name': 'contact_3_first_name',
                      'Billing contact 3 Last Name': 'contact_3_last_name',
                      'Billing contact 3 Email': 'contact_3_email',
                      'Billing contact 3 Linkendin Url':
                        'contact_3_linkedin_url',
                      'Billing contact 4 First Name': 'contact_4_first_name',
                      'Billing contact 4 Last Name': 'contact_4_last_name',
                      'Billing contact 4 Email': 'contact_4_email',

                      'Billing contact 5 First Name': 'contact_5_first_name',
                      'Billing contact 5 Last Name': 'contact_5_last_name',
                      'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/product/${product?._id}/allCustomer`}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      // limit={5000}
      params={params}
      searchFields="name"
      addOnFilter={{...activeTabFilter, ...filter}}
      search={searchValue}
      variant={'bordered'}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120,
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Status',
          field: 'active_status',
          width: 100,
        },
        // {
        //   header: 'Avg. Rate.',
        //   width: 90,
        //   render: ({row, styles}) => {
        //     return (
        //       <AmountRender
        //         row={row}
        //         styles={styles}
        //         amount={row?.avg_rate || 0}
        //         currency={row.base_currency?.currency}
        //       />
        //     );
        //   },
        // },
        // {
        //   type: 'indianCurrency',
        //   aggregate: true,
        //   header: 'Last Month Rev.',
        //   field: 'total_revenue_amt',
        //   width: 130,
        //   align: 'right',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   toFixed: 2,
        //   onPress: ({row}) => {
        //     navigation.navigate('revenue-amout-customer-overview', {
        //       row: row?._id,
        //       period: period,
        //     });
        //   },
        // },
        // {
        //   header: 'Current Team Size',
        //   count_field: 'current_team_size',
        //   field: 'current_team_size',
        //   align: 'right',
        //   render: CountCellRender,
        //   width: 180,
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('current-team-size-customer', {
        //       row: row.current_team_ids,
        //     });
        //   },
        // },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          aggregate: true,
          field: 'overdue_amt',
          align: 'right',
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
            });
          },
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            CustomerCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`customer-detail-new`, {
              ...params,
              customerId: row._id,
              row,
            });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
          visible: ({row}) => row?.active_status == 'Inactive',
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/customers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};

export const AllCustomerTableAm = props => {
  let {
    navigation,
    route: {params},
    filterValues,
    filter,
    searchValue,
    filterParams,
    activeTabFilter,
  } = props;

  const {
    params: {period},
  } = filterValues || params.filterValues;

  params = {period, ...params, ...filterParams};
  const {product} = params;
  const {customerCommunications} = leadControllers(props);
  const {
    post,
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();
  const {activityLogOfCustomer, CustomerCommunications} =
    customerController(props);
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...filter, ...activeTabFilter, owner: employeeId?._id},
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Name: 'name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',
                      'PM contact 1 First Name': 'pm_contact_1_first_name',
                      'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      'PM contact 1 Email': 'pm_contact_1_email',
                      'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      'PM contact 2 First Name': 'pm_contact_2_first_name',
                      'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      'PM contact 2 Email': 'pm_contact_2_email',
                      'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      'PM contact 3 First Name': 'pm_contact_3_first_name',
                      'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      'PM contact 3 Email': 'pm_contact_3_email',
                      'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      'PM contact 4 First Name': 'pm_contact_4_first_name',
                      'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      'PM contact 4 Email': 'pm_contact_4_email',
                      'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      'PM contact 5 First Name': 'pm_contact_5_first_name',
                      'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      'PM contact 5 Email': 'pm_contact_5_email',
                      'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      'Sales contact 1 First Name':
                        'sales_contact_1_first_name',
                      'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      'Sales contact 1 Email': 'sales_contact_1_email',

                      'Sales contact 2 First Name':
                        'sales_contact_2_first_name',
                      'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      'Sales contact 2 Email': 'sales_contact_2_email',

                      'Sales contact 3 First Name':
                        'sales_contact_3_first_name',
                      'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      'Sales contact 3 Email': 'sales_contact_3_email',

                      'Sales contact 4 First Name':
                        'sales_contact_4_first_name',
                      'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      'Sales contact 4 Email': 'sales_contact_4_email',

                      'Sales contact 5 First Name':
                        'sales_contact_5_first_name',
                      'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      'Sales contact 5 Email': 'sales_contact_5_email',

                      'Billing contact 1 First Name': 'contact_1_first_name',
                      'Billing contact 1 Last Name': 'contact_1_last_name',
                      'Billing contact 1 Email': 'contact_1_email',

                      'Billing contact 2 First Name': 'contact_2_first_name',
                      'Billing contact 2 Last Name': 'contact_2_last_name',
                      'Billing contact 2 Email': 'contact_2_email',

                      'Billing contact 3 First Name': 'contact_3_first_name',
                      'Billing contact 3 Last Name': 'contact_3_last_name',
                      'Billing contact 3 Email': 'contact_3_email',
                      'Billing contact 3 Linkendin Url':
                        'contact_3_linkedin_url',
                      'Billing contact 4 First Name': 'contact_4_first_name',
                      'Billing contact 4 Last Name': 'contact_4_last_name',
                      'Billing contact 4 Email': 'contact_4_email',

                      'Billing contact 5 First Name': 'contact_5_first_name',
                      'Billing contact 5 Last Name': 'contact_5_last_name',
                      'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/product/${product?._id}/allCustomer`}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      // limit={5000}
      params={params}
      searchFields="name"
      addOnFilter={{
        ...activeTabFilter,
        ...filter,
        $or: [
          {owner: employeeId},
          {
            owner: {$in: employeeTeam},
          },
        ],
      }}
      search={searchValue}
      variant={'bordered'}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120,
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Avg. Rate.',
          width: 90,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.avg_rate || 0}
                currency={row.base_currency?.currency}
              />
            );
          },
        },
        {
          type: 'indianCurrency',
          aggregate: true,
          header: 'Last Month Rev.',
          field: 'total_revenue_amt',
          width: 130,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('revenue-amout-customer-overview', {
              row: row?._id,
              period: period,
            });
          },
        },
        // {
        //   header: 'Current Team Size',
        //   count_field: 'current_team_size',
        //   field: 'current_team_size',
        //   align: 'right',
        //   render: CountCellRender,
        //   width: 180,
        //   aggregate: true,
        //   onPress: ({row}) => {
        //     navigation.navigate('current-team-size-customer', {
        //       row: row.current_team_ids,
        //     });
        //   },
        // },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          aggregate: true,
          field: 'overdue_amt',
          align: 'right',
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
            });
          },
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            CustomerCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`customer-detail-new`, {
              ...params,
              customerId: row._id,
              row,
            });
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
          visible: ({row}) => row?.active_status == 'Inactive',
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};

export const ActiveCustomerTable = props => {
  let {
    route: {params},
    params: propParams,
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
    activeTabFilter,
    filter: propsFilter,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues || params.filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  let filter = {};
  if (params && params.filter) {
    filter = {...params.filter};
  }
  const {customerCommunications} = leadControllers(props);
  const {post, download} = useAppStateContext();
  const {
    markCustomerLost,
    makeDuplicateCustomer,
    activityLogOfCustomer,
    CustomerCommunications,
  } = customerController(props);

  const markLost = ({row}) => {
    post({
      uri: `/customers/${row?._id}`,
      method: 'put',
      props: {
        active_status: 'Inactive',
        lost_date: getZeroTimeDate(new Date()),
      },
    });
  };
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });

  const fetchProps = {
    params: {...params, ...propParams},
    // search,
    search: searchValue,
    addOnFilter: {
      ...filter,
      ...activeTabFilter,
      ...addOnFilter,
      ...propsFilter,
    },
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };

  return (
    <Table
      {...props}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Name: 'name',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      'Sow Latest Date': 'sow_latest_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',

                      // 'PM contact 1 First Name': 'pm_contact_1_first_name',
                      // 'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      // 'PM contact 1 Email': 'pm_contact_1_email',
                      // 'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      // 'PM contact 2 First Name': 'pm_contact_2_first_name',
                      // 'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      // 'PM contact 2 Email': 'pm_contact_2_email',
                      // 'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      // 'PM contact 3 First Name': 'pm_contact_3_first_name',
                      // 'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      // 'PM contact 3 Email': 'pm_contact_3_email',
                      // 'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      // 'PM contact 4 First Name': 'pm_contact_4_first_name',
                      // 'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      // 'PM contact 4 Email': 'pm_contact_4_email',
                      // 'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      // 'PM contact 5 First Name': 'pm_contact_5_first_name',
                      // 'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      // 'PM contact 5 Email': 'pm_contact_5_email',
                      // 'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      // 'Sales contact 1 First Name':
                      //   'sales_contact_1_first_name',
                      // 'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      // 'Sales contact 1 Email': 'sales_contact_1_email',

                      // 'Sales contact 2 First Name':
                      //   'sales_contact_2_first_name',
                      // 'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      // 'Sales contact 2 Email': 'sales_contact_2_email',

                      // 'Sales contact 3 First Name':
                      //   'sales_contact_3_first_name',
                      // 'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      // 'Sales contact 3 Email': 'sales_contact_3_email',

                      // 'Sales contact 4 First Name':
                      //   'sales_contact_4_first_name',
                      // 'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      // 'Sales contact 4 Email': 'sales_contact_4_email',

                      // 'Sales contact 5 First Name':
                      //   'sales_contact_5_first_name',
                      // 'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      // 'Sales contact 5 Email': 'sales_contact_5_email',

                      // 'Billing contact 1 First Name': 'contact_1_first_name',
                      // 'Billing contact 1 Last Name': 'contact_1_last_name',
                      // 'Billing contact 1 Email': 'contact_1_email',

                      // 'Billing contact 2 First Name': 'contact_2_first_name',
                      // 'Billing contact 2 Last Name': 'contact_2_last_name',
                      // 'Billing contact 2 Email': 'contact_2_email',

                      // 'Billing contact 3 First Name': 'contact_3_first_name',
                      // 'Billing contact 3 Last Name': 'contact_3_last_name',
                      // 'Billing contact 3 Email': 'contact_3_email',
                      // 'Billing contact 3 Linkendin Url':
                      //   'contact_3_linkedin_url',
                      // 'Billing contact 4 First Name': 'contact_4_first_name',
                      // 'Billing contact 4 Last Name': 'contact_4_last_name',
                      // 'Billing contact 4 Email': 'contact_4_email',

                      // 'Billing contact 5 First Name': 'contact_5_first_name',
                      // 'Billing contact 5 Last Name': 'contact_5_last_name',
                      // 'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/product/activeCustomer`}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      // params={params}
      // filter={{...filter, ...activeTabFilter}}
      // search={searchValue}
      // searchFields="name"
      eventSourceId={['Customer']}
      limit={20000}
      // limit={10}
      variant={'bordered'}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120, //changed from 120 to 140 becuase open changed tenure
        },
        {
          header: 'Customer',
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 180},
        // {...INDUSTRY, width: 200},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Avg. Rate.',
          width: 90,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.avg_rate || 0}
                currency={row.base_currency?.currency}
              />
            );
          },
        },
        {
          type: 'indianCurrency',
          aggregate: true,
          header: 'Last Month Rev.',
          field: 'total_revenue_amt',
          width: 130,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('revenue-amout-customer-overview', {
              row: row?._id,
              period: period,
            });
          },
        },
        // {
        //   header: 'Current Team Size',
        //   // width: 100,
        // },
        {
          header: 'Current Team Size',
          // render: CountCellRender,
          count_field: 'current_team_size',
          field: 'current_team_size',
          align: 'right',
          width: 180,
          aggregate: true,
          render: CountCellRender,
          onPress: ({row}) => {
            navigation.navigate('current-team-size-customer', {
              row: row.current_team_ids,
            });
          },
        },
        // {
        //   header: 'Growth (Last Month)',
        //   children: [
        //     {
        //       type: 'number',
        //       aggregate: true,
        //       header: 'In Revenue',
        //       field: 'total_revenue_amt',
        //       width: 120,
        //       render: RenderInvoice,
        //       align: 'right',
        //       formatOptions: {
        //         maximumFractionDigits: 2,
        //       },
        //       toFixed: 2,
        //       onPress: ({row}) => {
        //         navigation.navigate('revenue-amout-customer-overview', {
        //           row: row?._id,
        //           period: filterParams?.period,
        //         });
        //       },
        //     },
        //     {
        //       type: 'number',
        //       aggregate: true,
        //       header: 'In MM',
        //       field: 'total_mm_one_month',
        //       align: 'center',
        //       width: 150,
        //       compareField: 'total_mm_two_months',
        //       render: ComparisonRender,
        //       aggregate: {
        //         render: AggregateComparisonRender,
        //       },
        //       formatOptions: {
        //         maximumFractionDigits: 2,
        //       },
        //       toFixed: 2,
        //       // onPress: ({row}) => {
        //       //   navigation.navigate('man-months-customer-overview', {
        //       //     row: row?._id,
        //       //     period: filterParams?.period,
        //       //   });
        //       // },
        //     },
        //   ],
        // },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          aggregate: true,
          field: 'overdue_amt',
          align: 'right',
          // width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
              // period: filterParams?.period,
            });
          },
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            CustomerCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        // LAST_COMMUNICATION(customerCommunications),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`customer-detail-new`, {
              ...params,
              customerId: row._id,
              row,
            });
          },
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Mark Inactive',
          onPress: markCustomerLost,
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Duplicate Customer',
          onPress: makeDuplicateCustomer,
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Mark Legal',
          confirm: {
            title: 'Mark Legal',
            message: 'Are you sure you want to mark this customer as legal?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/customers/${row?._id}`,
              props: {
                legal: true,
                legal_marked_on: new Date(),
              },
            });
          },
        },
        {
          title: 'Unmark Duplicate',
          confirm: {
            title:
              'Are you sure you want to unmark this customer as duplicate?',
          },
          onPress: ({row}) => unmarkDuplicate({row, invoke}),
          visible: ({row}) => row?.is_duplicate,
        },
        {
          title: 'Update CSM',
          onPress: ({row}) => {
            navigation.navigate(`update-customer-csm`, {
              ...params,
              row,
            });
          },
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/customers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};

export const ActiveCustomerTableAm = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
    activeTabFilter,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues || params.filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  let filter = {};
  if (params && params.filter) {
    filter = {...params.filter};
  }
  const {customerCommunications} = leadControllers(props);
  const {
    post,
    user: {employee: {_id: employeeId, team: employeeTeam = []} = {}},
  } = useAppStateContext();
  const {markCustomerLost, makeDuplicateCustomer, activityLogOfCustomer} =
    customerController(props);

  const markLost = ({row}) => {
    post({
      uri: `/customers/${row?._id}`,
      method: 'put',
      props: {
        active_status: 'Inactive',
        lost_date: getZeroTimeDate(new Date()),
      },
    });
  };
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const {download} = useAppStateContext();
  console.log('employeeId', employeeId);
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...filter, ...activeTabFilter, owner: employeeId?._id},
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };

  return (
    <Table
      {...props}
      api={`/product/activeCustomer`}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Name: 'name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',
                      'PM contact 1 First Name': 'pm_contact_1_first_name',
                      'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      'PM contact 1 Email': 'pm_contact_1_email',
                      'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      'PM contact 2 First Name': 'pm_contact_2_first_name',
                      'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      'PM contact 2 Email': 'pm_contact_2_email',
                      'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      'PM contact 3 First Name': 'pm_contact_3_first_name',
                      'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      'PM contact 3 Email': 'pm_contact_3_email',
                      'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      'PM contact 4 First Name': 'pm_contact_4_first_name',
                      'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      'PM contact 4 Email': 'pm_contact_4_email',
                      'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      'PM contact 5 First Name': 'pm_contact_5_first_name',
                      'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      'PM contact 5 Email': 'pm_contact_5_email',
                      'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      'Sales contact 1 First Name':
                        'sales_contact_1_first_name',
                      'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      'Sales contact 1 Email': 'sales_contact_1_email',

                      'Sales contact 2 First Name':
                        'sales_contact_2_first_name',
                      'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      'Sales contact 2 Email': 'sales_contact_2_email',

                      'Sales contact 3 First Name':
                        'sales_contact_3_first_name',
                      'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      'Sales contact 3 Email': 'sales_contact_3_email',

                      'Sales contact 4 First Name':
                        'sales_contact_4_first_name',
                      'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      'Sales contact 4 Email': 'sales_contact_4_email',

                      'Sales contact 5 First Name':
                        'sales_contact_5_first_name',
                      'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      'Sales contact 5 Email': 'sales_contact_5_email',

                      'Billing contact 1 First Name': 'contact_1_first_name',
                      'Billing contact 1 Last Name': 'contact_1_last_name',
                      'Billing contact 1 Email': 'contact_1_email',

                      'Billing contact 2 First Name': 'contact_2_first_name',
                      'Billing contact 2 Last Name': 'contact_2_last_name',
                      'Billing contact 2 Email': 'contact_2_email',

                      'Billing contact 3 First Name': 'contact_3_first_name',
                      'Billing contact 3 Last Name': 'contact_3_last_name',
                      'Billing contact 3 Email': 'contact_3_email',
                      'Billing contact 3 Linkendin Url':
                        'contact_3_linkedin_url',
                      'Billing contact 4 First Name': 'contact_4_first_name',
                      'Billing contact 4 Last Name': 'contact_4_last_name',
                      'Billing contact 4 Email': 'contact_4_email',

                      'Billing contact 5 First Name': 'contact_5_first_name',
                      'Billing contact 5 Last Name': 'contact_5_last_name',
                      'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      params={params}
      filter={{
        ...filter,
        ...activeTabFilter,
        $or: [
          {owner: employeeId},
          {
            owner: {$in: employeeTeam},
          },
        ],
      }}
      search={searchValue}
      searchFields="name"
      eventSourceId={['Customer']}
      limit={20000}
      // limit={10}
      variant={'bordered'}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120, //changed from 120 to 140 becuase open changed tenure
        },
        {
          header: 'Customer',
          // width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 180},
        // {...INDUSTRY, width: 200},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          type: 'indianCurrency',
          aggregate: true,
          header: 'Last Month Rev.',
          field: 'total_revenue_amt',
          // width: 120,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('revenue-amout-customer-overview', {
              row: row?._id,
              period: period,
            });
          },
        },
        // {
        //   header: 'Current Team Size',
        //   // width: 100,
        // },
        {
          header: 'Current Team Size',
          // render: CountCellRender,
          count_field: 'current_team_size',
          field: 'current_team_size',
          align: 'right',
          width: 180,
          aggregate: true,
          render: CountCellRender,
          onPress: ({row}) => {
            navigation.navigate('current-team-size-customer', {
              row: row.current_team_ids,
            });
          },
        },
        // {
        //   header: 'Growth (Last Month)',
        //   children: [
        //     {
        //       type: 'number',
        //       aggregate: true,
        //       header: 'In Revenue',
        //       field: 'total_revenue_amt',
        //       width: 120,
        //       render: RenderInvoice,
        //       align: 'right',
        //       formatOptions: {
        //         maximumFractionDigits: 2,
        //       },
        //       toFixed: 2,
        //       onPress: ({row}) => {
        //         navigation.navigate('revenue-amout-customer-overview', {
        //           row: row?._id,
        //           period: filterParams?.period,
        //         });
        //       },
        //     },
        //     {
        //       type: 'number',
        //       aggregate: true,
        //       header: 'In MM',
        //       field: 'total_mm_one_month',
        //       align: 'center',
        //       width: 150,
        //       compareField: 'total_mm_two_months',
        //       render: ComparisonRender,
        //       aggregate: {
        //         render: AggregateComparisonRender,
        //       },
        //       formatOptions: {
        //         maximumFractionDigits: 2,
        //       },
        //       toFixed: 2,
        //       // onPress: ({row}) => {
        //       //   navigation.navigate('man-months-customer-overview', {
        //       //     row: row?._id,
        //       //     period: filterParams?.period,
        //       //   });
        //       // },
        //     },
        //   ],
        // },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          aggregate: true,
          field: 'overdue_amt',
          align: 'right',
          // width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
              // period: filterParams?.period,
            });
          },
        },

        // LAST_COMMUNICATION(customerCommunications),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`customer-detail-new`, {
              ...params,
              customerId: row._id,
              row,
            });
          },
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Mark Inactive',
          onPress: markCustomerLost,
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Duplicate Customer',
          onPress: makeDuplicateCustomer,
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Mark Legal',
          confirm: {
            title: 'Mark Legal',
            message: 'Are you sure you want to mark this customer as legal?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/customers/${row?._id}`,
              props: {
                legal: true,
                legal_marked_on: new Date(),
              },
            });
          },
        },
        {
          title: 'Unmark Duplicate',
          confirm: {
            title:
              'Are you sure you want to unmark this customer as duplicate?',
          },
          onPress: ({row}) => unmarkDuplicate({row, invoke}),
          visible: ({row}) => row?.is_duplicate,
        },
        {
          title: 'Update CSM',
          onPress: ({row}) => {
            navigation.navigate(`update-customer-csm`, {
              ...params,
              row,
            });
          },
          visible: ({row}) => !row?.is_duplicate,
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};
// export const LostCustomerTable = props => {
//   let {params = {}, navigation, filterParams, searchValue, addOnFilter} = props;

//   // params = {...params, ...filterParams};
//   const {product} = params;
//   const {customerCommunications} = leadControllers(props);
//   const invoke = useInvoke({
//     eventSourceId: 'Customer',
//     method: 'put',
//   });

//   return (
//     <Table
//       api={`/product/lostCustomer`}
//       onRowPress={({row}) => {
//         navigation.navigate(`customer-details`, {
//           ...params,
//           customerId: row._id,
//           row,
//         });
//       }}
//       params={{...params, ...filterParams}}
//       searchFields="name"
//       search={searchValue}
//       eventSourceId={['Customer']}
//       filter={addOnFilter}
//       variant={'bordered'}
//       columns={[
//         {...CUSTOMER_NAME, width: 300},
//         {...COUNTRY, width: 300},
//         {...INDUSTRY, width: 300},
//         {...LEAD_VALUE, width: 80},
//         {
//           header: 'AM',
//           field: 'owner',
//           type: 'userAvatar',
//           align: 'center',
//           width: 80,
//         },
//         {
//           header: 'Reason',
//           render: RenderLostReason,
//           width: 200,
//         },
//       ]}
//       moreActions={[
//         {
//           title: 'Mark Active',
//           confirm: {
//             title: 'Are you sure you want to mark this customer as active?',
//           },
//           onPress: ({row}) => markActive({row, invoke}),
//         },
//       ]}
//     />
//   );
// };

export const LostCustomerTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
    inActiveTabFilter,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const {activityLogOfCustomer, CustomerCommunications} =
    customerController(props);
  return (
    <GroupTable
      {...props}
      api={`/product/lostCustomer`}
      params={params}
      search={searchValue}
      limit={1000}
      addOnFilter={{...inActiveTabFilter}}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Customer`} />
            </RowWithSeparator>
          );
        },
      }}
      searchFields="name"
      eventSourceId={['Customer']}
      columns={[
        {
          header: 'Inactive Since',
          render: RenderClosedOn,
          align: 'center',
          // width: 120,
          width: 120,
        },
        {
          header: 'Customer',
          width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 300},
        // {...INDUSTRY, width: 300},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Since',
          render: RenderOpenSinceForInactive,
          align: 'center',
          width: 120,
        },
        {
          header: 'Reason',
          render: RenderLostReason,
          // width: 200,
        },
        // {
        //   header: 'Overdue',
        //   aggregate: true,
        //   align: 'right',
        //   field: 'overdue_amt',
        //   type: 'indianCurrency',
        //   // width: 150,
        //   render: RenderOverDueAmt,
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   toFixed: 2,
        //   onPress: ({row}) => {
        //     navigation.navigate('overdue-pending-amt-customer-list', {
        //       row: row?._id,
        //       // period: filterParams?.period,
        //     });
        //   },
        // },
        {
          header: 'Is Nurturable',
          // width: 200,
          minWidth: 120,
          align: 'left',
          render: ({row, styles}) => (
            <RenderActions row={row} styles={styles} />
          ),
        },
        {
          header: 'Communication',
          onPress: ({row}) => {
            CustomerCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/customers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

const LastCommunicationRender = ({row}) => {
  const {CommunicationBlue} = useTheme('icons');
  return (
    <Chip
      color="Information"
      displayTextColor={true}
      value={
        (row?.potential_customer &&
          row.potential_customer?.last_communication_date &&
          moment(row.potential_customer?.last_communication_date).format(
            'DD MMM, YY',
          )) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

export const NewCustomerTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
    inActiveTabFilter,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const {post, download} = useAppStateContext();

  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...inActiveTabFilter},
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };
  const {activityLogOfCustomer, CustomerCommunications} =
    customerController(props);
  return (
    <GroupTable
      {...props}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Download',
      //       onPress: props =>
      //         download({
      //           uri: '/exportExcelHelper',
      //           props: {
      //             props: {
      //               // ...props,
      //               selectedIds: props.selectedIds,
      //               fetchProps: {
      //                 ...fetchProps,
      //                 api: '/customerMasterDataDownload',
      //               },
      //               column: {
      //                 Status: 'status',
      //                 Organization: 'organization.name',
      //                 Industry: 'industry.name',
      //                 Relationship: 'owner.name',
      //                 Name: 'name',
      //                 Currency: 'base_currency.currency',
      //                 // Address: 'place.description', ///
      //                 Address: 'correspondence_address',
      //                 'Place of Supply': 'place_of_supply',
      //                 'Credit Days': 'credit_days',
      //                 'Type Of Supply': 'type_of_supply',
      //                 GSTIN: 'gstin',
      //                 PAN: 'pan_no',
      //                 TAN: 'tan_no',
      //                 'First Order Date': 'first_order_date',
      //                 Website: 'website',
      //                 Channel: 'lead_source.name',
      //                 Campaign: 'campaign_type.label',
      //                 'Employee Count': 'employee_count.number',
      //                 'Lead Score': 'lead_score',
      //                 'Lost Date': 'dormant_date',
      //                 'Geographical Category': 'geography_location.name',
      //                 'Organisation Type': 'organization_category.name',
      //                 'PM contact 1 First Name': 'pm_contact_1_first_name',
      //                 'PM contact 1 Last Name': 'pm_contact_1_last_name',
      //                 'PM contact 1 Email': 'pm_contact_1_email',
      //                 'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
      //                 'PM contact 2 First Name': 'pm_contact_2_first_name',
      //                 'PM contact 2 Last Name': 'pm_contact_2_last_name',
      //                 'PM contact 2 Email': 'pm_contact_2_email',
      //                 'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
      //                 'PM contact 3 First Name': 'pm_contact_3_first_name',
      //                 'PM contact 3 Last Name': 'pm_contact_3_last_name',
      //                 'PM contact 3 Email': 'pm_contact_3_email',
      //                 'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
      //                 'PM contact 4 First Name': 'pm_contact_4_first_name',
      //                 'PM contact 4 Last Name': 'pm_contact_4_last_name',
      //                 'PM contact 4 Email': 'pm_contact_4_email',
      //                 'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
      //                 'PM contact 5 First Name': 'pm_contact_5_first_name',
      //                 'PM contact 5 Last Name': 'pm_contact_5_last_name',
      //                 'PM contact 5 Email': 'pm_contact_5_email',
      //                 'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
      //                 'Sales contact 1 First Name':
      //                   'sales_contact_1_first_name',
      //                 'Sales contact 1 Last Name': 'sales_contact_1_last_name',
      //                 'Sales contact 1 Email': 'sales_contact_1_email',

      //                 'Sales contact 2 First Name':
      //                   'sales_contact_2_first_name',
      //                 'Sales contact 2 Last Name': 'sales_contact_2_last_name',
      //                 'Sales contact 2 Email': 'sales_contact_2_email',

      //                 'Sales contact 3 First Name':
      //                   'sales_contact_3_first_name',
      //                 'Sales contact 3 Last Name': 'sales_contact_3_last_name',
      //                 'Sales contact 3 Email': 'sales_contact_3_email',

      //                 'Sales contact 4 First Name':
      //                   'sales_contact_4_first_name',
      //                 'Sales contact 4 Last Name': 'sales_contact_4_last_name',
      //                 'Sales contact 4 Email': 'sales_contact_4_email',

      //                 'Sales contact 5 First Name':
      //                   'sales_contact_5_first_name',
      //                 'Sales contact 5 Last Name': 'sales_contact_5_last_name',
      //                 'Sales contact 5 Email': 'sales_contact_5_email',

      //                 'Billing contact 1 First Name': 'contact_1_first_name',
      //                 'Billing contact 1 Last Name': 'contact_1_last_name',
      //                 'Billing contact 1 Email': 'contact_1_email',

      //                 'Billing contact 2 First Name': 'contact_2_first_name',
      //                 'Billing contact 2 Last Name': 'contact_2_last_name',
      //                 'Billing contact 2 Email': 'contact_2_email',

      //                 'Billing contact 3 First Name': 'contact_3_first_name',
      //                 'Billing contact 3 Last Name': 'contact_3_last_name',
      //                 'Billing contact 3 Email': 'contact_3_email',
      //                 'Billing contact 3 Linkendin Url':
      //                   'contact_3_linkedin_url',
      //                 'Billing contact 4 First Name': 'contact_4_first_name',
      //                 'Billing contact 4 Last Name': 'contact_4_last_name',
      //                 'Billing contact 4 Email': 'contact_4_email',

      //                 'Billing contact 5 First Name': 'contact_5_first_name',
      //                 'Billing contact 5 Last Name': 'contact_5_last_name',
      //                 'Billing contact 5 Email': 'contact_5_email',
      //               },
      //             },
      //           },
      //         }),
      //     },
      //   ],
      // }}
      api={`/product/newCustomer`}
      params={params}
      search={searchValue}
      limit={1000}
      addOnFilter={{...inActiveTabFilter}}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          const leadScore = row?.children.reduce((pre, curr) => {
            return pre + curr.lead_score;
          }, 0);
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Customer`} />
              <GroupContentItem value={`${leadScore.toFixed(2)} Score`} />
            </RowWithSeparator>
          );
        },
      }}
      searchFields="name"
      eventSourceId={['Customer']}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120,
        },
        {
          header: 'Customer',
          // width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 300},
        // {...INDUSTRY, width: 300},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        // {
        //   header: 'Overdue',
        //   aggregate: true,
        //   align: 'right',
        //   field: 'overdue_amt',
        //   type: 'indianCurrency',
        //   // width: 150,
        //   render: RenderOverDueAmt,
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   toFixed: 2,
        //   onPress: ({row}) => {
        //     navigation.navigate('overdue-pending-amt-customer-list', {
        //       row: row?._id,
        //       // period: filterParams?.period,
        //     });
        //   },
        // },
        // {
        //   header: 'Communication',
        //   onPress: ({row}) => {
        //     CustomerCommunications({row});
        //   },
        //   render: LastCommunicationRender,
        //   width: 132,
        // },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/customers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const LostCustomerTableAm = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    addOnFilter,
    searchValue,
    inActiveTabFilter,
  } = props;
  let {organization, product, ...rest} = params;
  const {
    params: {period},
  } = filterValues;

  params = {
    ...addOnFilter,
    ...rest,
    period,
    product,
    organization,
  };
  const {customerCommunications} = leadControllers(props);
  const {activityLogOfCustomer} = customerController(props);
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });
  const {
    post,
    user: {employee: {team: employeeTeam, _id: employeeId} = {}} = {},
  } = useAppStateContext();
  return (
    <GroupTable
      {...props}
      api={`/product/lostCustomer`}
      params={params}
      search={searchValue}
      addOnFilter={{
        $or: [
          {account_owner_id: employeeId},
          {account_owner_id: {$in: employeeTeam}},
        ],
        ...inActiveTabFilter,
      }}
      // filter={{owner: employeeId}}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      groupRow={{
        data: 'children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Customer`} />
            </RowWithSeparator>
          );
        },
      }}
      searchFields="name"
      eventSourceId={['Customer']}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSinceForInactive,
          align: 'center',
          width: 120,
        },
        {
          header: 'Customer',
          width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 300},
        // {...INDUSTRY, width: 300},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Inactive Since',
          render: RenderClosedOn,
          align: 'center',
          // width: 120,
          width: 120,
        },
        {
          header: 'Reason',
          render: RenderLostReason,
          // width: 200,
        },
        {
          header: 'Overdue',
          aggregate: true,
          align: 'right',
          field: 'overdue_amt',
          type: 'indianCurrency',
          // width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
              // period: filterParams?.period,
            });
          },
        },
        {
          header: 'Is Nurturable',
          // width: 200,
          minWidth: 120,
          align: 'left',
          render: ({row, styles}) => (
            <RenderActions row={row} styles={styles} />
          ),
        },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};

// export const LeaglCustomerTable = props => {
//   let {
//     route: {params},
//     navigation,
//     filterValues,
//     searchValue,
//     addOnFilter,
//     legalTabFilter,
//   } = props;
//   let {organization, product, ...rest} = params;
//   const {
//     params: {period},
//   } = filterValues;

//   params = {
//     ...addOnFilter,
//     ...rest,
//     period,
//     product,
//     organization,
//   };
//   const {customerCommunications} = leadControllers(props);
//   const invoke = useInvoke({
//     eventSourceId: 'Customer',
//     method: 'put',
//   });

//   return (
//     <GroupTable
//       {...props}
//       api={`/product/legalCustomer`}
//       params={params}
//       search={searchValue}
//       addOnFilter={{...legalTabFilter}}
//       onRowPress={({row}) => {
//         navigation.navigate(`customer-details`, {
//           ...params,
//           customerId: row._id,
//           row,
//         });
//       }}
//       groupRow={{
//         data: 'children',
//         // defaultExpanded: true,
//         leftContent: ({row}) => {
//           return (
//             <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
//               <GroupContentItem value={row.key} />
//               <GroupContentItem value={`${row.count} Customer`} />
//             </RowWithSeparator>
//           );
//         },
//       }}
//       searchFields="name"
//       eventSourceId={['Customer']}
//       columns={[
//         {
//           header: 'Customer',
//           // width: 360,
//           render: ({row}) => {
//             return <RenderCustomer row={row} navigation={navigation} />;
//           },
//         },
//         // {...COUNTRY, width: 300},
//         // {...INDUSTRY, width: 300},
//         {...LEAD_VALUE, width: 68},
//         {
//           header: 'AM',
//           field: 'owner',
//           type: 'userAvatar',
//           align: 'center',
//           width: 68,
//         },
//         {
//           header: 'Since',
//           render: RenderOpenSince,
//           align: 'center',
//           width: 140,
//         },
//         {
//           header: 'Overdue',
//           type: 'indianCurrency',
//           align: 'right',
//           aggregate: true,
//           field: 'overdue_amt',
//           // width: 150,
//           render: RenderOverDueAmt,
//           formatOptions: {
//             maximumFractionDigits: 2,
//           },
//           toFixed: 2,
//           onPress: ({row}) => {
//             navigation.navigate('overdue-pending-amt-customer-list', {
//               row: row?._id,
//               period: filterParams?.period,
//             });
//           },
//         },
//         {
//           header: 'Legal Since',
//           render: RenderClosedOn,
//           align: 'center',
//           width: 120,
//         },
//       ]}
//       moreActions={[
//         {
//           title: 'Mark Active',
//           confirm: {
//             title: 'Are you sure you want to mark this customer as active?',
//           },
//           onPress: ({row}) => markActive({row, invoke}),
//         },
//       ]}
//     />
//   );
// };

export const LeaglCustomerTable = props => {
  let {
    route: {params},
    navigation,
    // filterValues,
    searchValue,
    addOnFilter,
    legalTabFilter,
  } = props;
  let {organization, product, period, ...rest} = params;
  // const {
  //   params: {period},
  // } = filterValues;

  const {
    user: {employee: employeeId},
    download,
  } = useAppStateContext();

  params = {
    ...addOnFilter,
    ...rest,
    // period,
    product,
    organization,
  };
  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: legalTabFilter,
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };
  const {customerCommunications} = leadControllers(props);
  const {activityLogOfCustomer, CustomerCommunications} =
    customerController(props);
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });

  return (
    <Table
      {...props}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Name: 'name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',
                      'PM contact 1 First Name': 'pm_contact_1_first_name',
                      'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      'PM contact 1 Email': 'pm_contact_1_email',
                      'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      'PM contact 2 First Name': 'pm_contact_2_first_name',
                      'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      'PM contact 2 Email': 'pm_contact_2_email',
                      'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      'PM contact 3 First Name': 'pm_contact_3_first_name',
                      'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      'PM contact 3 Email': 'pm_contact_3_email',
                      'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      'PM contact 4 First Name': 'pm_contact_4_first_name',
                      'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      'PM contact 4 Email': 'pm_contact_4_email',
                      'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      'PM contact 5 First Name': 'pm_contact_5_first_name',
                      'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      'PM contact 5 Email': 'pm_contact_5_email',
                      'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      'Sales contact 1 First Name':
                        'sales_contact_1_first_name',
                      'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      'Sales contact 1 Email': 'sales_contact_1_email',

                      'Sales contact 2 First Name':
                        'sales_contact_2_first_name',
                      'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      'Sales contact 2 Email': 'sales_contact_2_email',

                      'Sales contact 3 First Name':
                        'sales_contact_3_first_name',
                      'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      'Sales contact 3 Email': 'sales_contact_3_email',

                      'Sales contact 4 First Name':
                        'sales_contact_4_first_name',
                      'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      'Sales contact 4 Email': 'sales_contact_4_email',

                      'Sales contact 5 First Name':
                        'sales_contact_5_first_name',
                      'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      'Sales contact 5 Email': 'sales_contact_5_email',

                      'Billing contact 1 First Name': 'contact_1_first_name',
                      'Billing contact 1 Last Name': 'contact_1_last_name',
                      'Billing contact 1 Email': 'contact_1_email',

                      'Billing contact 2 First Name': 'contact_2_first_name',
                      'Billing contact 2 Last Name': 'contact_2_last_name',
                      'Billing contact 2 Email': 'contact_2_email',

                      'Billing contact 3 First Name': 'contact_3_first_name',
                      'Billing contact 3 Last Name': 'contact_3_last_name',
                      'Billing contact 3 Email': 'contact_3_email',
                      'Billing contact 3 Linkendin Url':
                        'contact_3_linkedin_url',
                      'Billing contact 4 First Name': 'contact_4_first_name',
                      'Billing contact 4 Last Name': 'contact_4_last_name',
                      'Billing contact 4 Email': 'contact_4_email',

                      'Billing contact 5 First Name': 'contact_5_first_name',
                      'Billing contact 5 Last Name': 'contact_5_last_name',
                      'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/product/legalCustomer`}
      params={params}
      search={searchValue}
      addOnFilter={{...legalTabFilter}}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      searchFields="name"
      eventSourceId={['Customer']}
      columns={[
        {
          header: 'Legal Since',
          render: RenderClosedOn,
          align: 'center',
          width: 120,
        },
        {
          header: 'Customer',
          // width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 300},
        // {...INDUSTRY, width: 300},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 140,
        },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          align: 'right',
          aggregate: true,
          field: 'overdue_amt',
          // width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
              period: filterParams?.period,
            });
          },
        },

        {
          header: 'Communication',
          onPress: ({row}) => {
            CustomerCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/customers`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const LeaglCustomerTableAm = props => {
  let {
    route: {params},
    navigation,
    // filterValues,
    searchValue,
    addOnFilter,
    legalTabFilter,
  } = props;
  let {organization, product, period, ...rest} = params;
  // const {
  //   params: {period},
  // } = filterValues;

  const {
    user: {employee: employeeId},
    download,
  } = useAppStateContext();

  params = {
    ...addOnFilter,
    ...rest,
    // period,
    product,
    organization,
  };
  const {customerCommunications} = leadControllers(props);
  const {activityLogOfCustomer} = customerController(props);
  const invoke = useInvoke({
    eventSourceId: 'Customer',
    method: 'put',
  });

  const fetchProps = {
    params,
    // search,
    search: searchValue,
    addOnFilter: {...legalTabFilter, owner: employeeId?._id},
    searchFields: ['name'],
    // api: '/product/activeCustomer',
  };

  return (
    <Table
      {...props}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps: {
                      ...fetchProps,
                      api: '/customerMasterDataDownload',
                    },
                    column: {
                      Status: 'status',
                      Organization: 'organization.name',
                      Industry: 'industry.name',
                      Relationship: 'owner.name',
                      Name: 'name',
                      Currency: 'base_currency.currency',
                      // Address: 'place.description', ///
                      Address: 'correspondence_address',
                      'Place of Supply': 'place_of_supply',
                      'Credit Days': 'credit_days',
                      'Type Of Supply': 'type_of_supply',
                      GSTIN: 'gstin',
                      PAN: 'pan_no',
                      TAN: 'tan_no',
                      'First Order Date': 'first_order_date',
                      Website: 'website',
                      Channel: 'lead_source.name',
                      Campaign: 'campaign_type.label',
                      'Employee Count': 'employee_count.number',
                      'Lead Score': 'lead_score',
                      'Lost Date': 'dormant_date',
                      'Geographical Category': 'geography_location.name',
                      'Organisation Type': 'organization_category.name',
                      'PM contact 1 First Name': 'pm_contact_1_first_name',
                      'PM contact 1 Last Name': 'pm_contact_1_last_name',
                      'PM contact 1 Email': 'pm_contact_1_email',
                      'PM contact 1 Linkendin Url': 'pm_contact_1_linkedin_url',
                      'PM contact 2 First Name': 'pm_contact_2_first_name',
                      'PM contact 2 Last Name': 'pm_contact_2_last_name',
                      'PM contact 2 Email': 'pm_contact_2_email',
                      'PM contact 2 Linkendin Url': 'pm_contact_2_linkedin_url',
                      'PM contact 3 First Name': 'pm_contact_3_first_name',
                      'PM contact 3 Last Name': 'pm_contact_3_last_name',
                      'PM contact 3 Email': 'pm_contact_3_email',
                      'PM contact 3 Linkendin Url': 'pm_contact_3_linkedin_url',
                      'PM contact 4 First Name': 'pm_contact_4_first_name',
                      'PM contact 4 Last Name': 'pm_contact_4_last_name',
                      'PM contact 4 Email': 'pm_contact_4_email',
                      'PM contact 4 Linkendin Url': 'pm_contact_4_linkedin_url',
                      'PM contact 5 First Name': 'pm_contact_5_first_name',
                      'PM contact 5 Last Name': 'pm_contact_5_last_name',
                      'PM contact 5 Email': 'pm_contact_5_email',
                      'PM contact 5 Linkendin Url': 'pm_contact_5_linkedin_url',
                      'Sales contact 1 First Name':
                        'sales_contact_1_first_name',
                      'Sales contact 1 Last Name': 'sales_contact_1_last_name',
                      'Sales contact 1 Email': 'sales_contact_1_email',

                      'Sales contact 2 First Name':
                        'sales_contact_2_first_name',
                      'Sales contact 2 Last Name': 'sales_contact_2_last_name',
                      'Sales contact 2 Email': 'sales_contact_2_email',

                      'Sales contact 3 First Name':
                        'sales_contact_3_first_name',
                      'Sales contact 3 Last Name': 'sales_contact_3_last_name',
                      'Sales contact 3 Email': 'sales_contact_3_email',

                      'Sales contact 4 First Name':
                        'sales_contact_4_first_name',
                      'Sales contact 4 Last Name': 'sales_contact_4_last_name',
                      'Sales contact 4 Email': 'sales_contact_4_email',

                      'Sales contact 5 First Name':
                        'sales_contact_5_first_name',
                      'Sales contact 5 Last Name': 'sales_contact_5_last_name',
                      'Sales contact 5 Email': 'sales_contact_5_email',

                      'Billing contact 1 First Name': 'contact_1_first_name',
                      'Billing contact 1 Last Name': 'contact_1_last_name',
                      'Billing contact 1 Email': 'contact_1_email',

                      'Billing contact 2 First Name': 'contact_2_first_name',
                      'Billing contact 2 Last Name': 'contact_2_last_name',
                      'Billing contact 2 Email': 'contact_2_email',

                      'Billing contact 3 First Name': 'contact_3_first_name',
                      'Billing contact 3 Last Name': 'contact_3_last_name',
                      'Billing contact 3 Email': 'contact_3_email',
                      'Billing contact 3 Linkendin Url':
                        'contact_3_linkedin_url',
                      'Billing contact 4 First Name': 'contact_4_first_name',
                      'Billing contact 4 Last Name': 'contact_4_last_name',
                      'Billing contact 4 Email': 'contact_4_email',

                      'Billing contact 5 First Name': 'contact_5_first_name',
                      'Billing contact 5 Last Name': 'contact_5_last_name',
                      'Billing contact 5 Email': 'contact_5_email',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/product/legalCustomer`}
      params={params}
      search={searchValue}
      addOnFilter={{...legalTabFilter, owner: employeeId}}
      // filter={{owner: employeeId}}
      onRowPress={({row}) => {
        navigation.navigate(`customer-details`, {
          ...params,
          customerId: row._id,
          row,
        });
      }}
      searchFields="name"
      eventSourceId={['Customer']}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 140,
        },
        {
          header: 'Customer',
          // width: 360,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 300},
        // {...INDUSTRY, width: 300},
        {...LEAD_VALUE, width: 68},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 68,
        },
        {
          header: 'Overdue',
          type: 'indianCurrency',
          align: 'right',
          aggregate: true,
          field: 'overdue_amt',
          // width: 150,
          render: RenderOverDueAmt,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('overdue-pending-amt-customer-list', {
              row: row?._id,
              period: filterParams?.period,
            });
          },
        },
        {
          header: 'Legal Since',
          render: RenderClosedOn,
          align: 'center',
          width: 120,
        },
      ]}
      moreActions={[
        {
          title: 'Mark Active',
          confirm: {
            title: 'Are you sure you want to mark this customer as active?',
          },
          onPress: ({row}) => markActive({row, invoke}),
        },
        {
          title: 'Activity Log',
          onPress: activityLogOfCustomer,
        },
      ]}
    />
  );
};

export const CustomerForAmReport = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {filter = {}} = params;
  const {customerCommunications} = leadControllers(props);
  return (
    <Table
      api={`/customers`}
      renderHeader={() => <TableHeader title="Customers" />}
      fields={{
        name: 1,
        employee_count: {number: 1},
        country: {name: 1},
        industry: {name: 1},
        active_status: 1,
        lead_score: 1,
        last_communication: 1,
        last_communication_date: 1,
      }}
      params={params}
      addOnFilter={filter}
      variant={'bordered'}
      columns={[
        CUSTOMER_NAME,
        EMPLOYEE_COUNT,
        COUNTRY,
        INDUSTRY,
        STATUS,
        LEAD_VALUE,
        LAST_COMMUNICATION(customerCommunications),
      ]}
    />
  );
};

export const CustomerChurnDetailList = props => {
  let {
    route: {params},
    navigation,
    filter = {},
    search,
  } = props;

  return (
    <Table
      // {...props}
      api={`/customers`}
      searchFields={['name', 'website', 'email']}
      addOnFilter={{...filter}}
      search={search}
      fields={{
        employee_count: {number: 1, color: 1},
        owner: {name: 1, color: 1},
        name: 1,
        country: {name: 1, color: 1},
        industry: {name: 1, color: 1},
        geography_location: {_id: 1, name: 1},
        created_on: 1,
        last_communication: 1,
        last_communication_date: 1,
        campaign_id: 1,
        channel: {label: 1, color: 1},
        campaign_type: {label: 1, color: 1},
        lead_score: 1,
        is_duplicate: 1,
        parent_customer: {name: 1},
        organization_category: {_id: 1, name: 1},
        description: 1,
        first_order_date: 1,
      }}
      sort={{first_order_date: -1}}
      columns={[
        {
          header: 'Since',
          render: RenderOpenSinceForChurn,
          align: 'center',
          width: 180, //changed from 120 to 140 becuase open changed tenure
        },
        {
          header: 'Customer',
          // width: 380,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        // {...COUNTRY, width: 180},
        // {...INDUSTRY, width: 200},
        {...LEAD_VALUE, width: 180},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 180,
        },
        // {
        //   type: 'indianCurrency',
        //   aggregate: true,
        //   header: 'Last Month Rev.',
        //   field: 'total_revenue_amt',
        //   // width: 120,
        //   align: 'right',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   toFixed: 2,
        //   onPress: ({row}) => {
        //     navigation.navigate('revenue-amout-customer-overview', {
        //       row: row?._id,
        //       period: period,
        //     });
        //   },
        // },
        // {
        //   header: 'Current Team Size',
        //   // render: CountCellRender,
        //   count_field: 'current_team_size',
        //   field: 'current_team_size',
        //   align: 'right',
        //   width: 180,
        //   aggregate: true,
        // },
        // {
        //   header: 'Overdue',
        //   type: 'indianCurrency',
        //   aggregate: true,
        //   field: 'overdue_amt',
        //   align: 'right',
        //   // width: 150,
        //   render: RenderOverDueAmt,
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   toFixed: 2,
        //   onPress: ({row}) => {
        //     navigation.navigate('overdue-pending-amt-customer-list', {
        //       row: row?._id,
        //     });
        //   },
        // },
      ]}
      // moreActions={() => [
      //   {
      //     title: 'Edit',
      //     onPress: ({row}) => {
      //       navigation.navigate(`customer-detail-new`, {
      //         ...params,
      //         customerId: row._id,
      //         row,
      //       });
      //     },
      //     visible: ({row}) => !row?.is_duplicate,
      //   },
      //   {
      //     title: 'Mark Inactive',
      //     onPress: markCustomerLost,
      //     visible: ({row}) => !row?.is_duplicate,
      //   },
      //   {
      //     title: 'Duplicate Customer',
      //     onPress: makeDuplicateCustomer,
      //     visible: ({row}) => !row?.is_duplicate,
      //   },
      //   {
      //     title: 'Mark Legal',
      //     confirm: {
      //       title: 'Mark Legal',
      //       message: 'Are you sure you want to mark this customer as legal?',
      //       confirmText: 'Confirm',
      //     },
      //     onPress: ({row}) => {
      //       invoke({
      //         uri: `/customers/${row?._id}`,
      //         props: {
      //           legal: true,
      //           legal_marked_on: new Date(),
      //         },
      //       });
      //     },
      //   },
      //   {
      //     title: 'Unmark Duplicate',
      //     confirm: {
      //       title:
      //         'Are you sure you want to unmark this customer as duplicate?',
      //     },
      //     onPress: ({row}) => unmarkDuplicate({row, invoke}),
      //     visible: ({row}) => row?.is_duplicate,
      //   },
      //   {
      //     title: 'Update CSM',
      //     onPress: ({row}) => {
      //       navigation.navigate(`update-customer-csm`, {
      //         ...params,
      //         row,
      //       });
      //     },
      //     visible: ({row}) => !row?.is_duplicate,
      //   },
      // ]}
    />
  );
};

export const CustomerTeamSizeList = props => {
  const {
    route: {params},
  } = props;
  const {row = []} = params;
  return (
    <Table
      api={`/employeeassignments`}
      filter={{_id: {$in: row}}}
      renderHeader={() => {
        return <TableHeader title="Total Delivery" />;
      }}
      fields={{
        employee: {_id: 1, name: 1, color: 1, official_email_id: 1},
        project_id: {_id: 1, project: 1, color: 1},
        from_date: 1,
        to_date: 1,
        hours: 1,
        assigned_type: 1,
      }}
      columns={[
        {
          header: 'Engineer',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Project',
          type: 'colorTag',
          field: 'project_id.project',
          colorField: 'project_id.color',
        },
        {
          field: 'from_date',
          header: 'From Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'to_date',
          header: 'To Date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'hours',
          header: 'Hours',
          type: 'number',
        },
      ]}
    />
  );
};
