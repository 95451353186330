import React from 'react';
import { MultiStepForm } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { useFormSubmit } from '../../../controllers/useSubmitForm';

const VendorTableViewDetail = props => {
  const {
    route: {params = {}},
  } = props;
  const {onSubmit} = useFormSubmit({
    eventSourceId: 'VendorView',
    uri: '/vendors',
  });
  const {row} = params;
  return (
    <MultiStepForm
      api={`/vendor/details/${row?._id}`}
      fields={{}}
      onSubmit={onSubmit}
      defaultValues={{
        created_on: new Date(),
        status: 'New',
      }}
      submitAction="Save"
      steps={[
        {
          title: 'Basic Information',
          fields: [
            {
              label: 'About Vendor',
              fields: [
                {
                  field: 'name',
                  label: 'Vendor Name',
                  type: 'text',
                  size: 6,
                  required: true,
                },
                {
                  field: 'vendor_category',
                  label: 'Vendor Category',
                  type: 'autoComplete',
                  options: ['Domestic', 'International', 'Online'],
                  size: 6,
                  required: true,
                },
                {
                  type: 'text',
                  field: 'contact_person',
                  label: 'Contact Person Name',
                  required: true,
                  size: 4,
                },
                {
                  type: 'number',
                  field: 'contact_number',
                  label: 'Contact No.',
                  required: true,
                  size: 4,
                },
                {
                  type: 'text',
                  field: 'email',
                  placeholder: 'Email',
                  size: 4,
                  label: 'Email',
                },
                {
                  type: 'textArea',
                  field: 'purpose',
                  label: 'Purpose',
                  required: true,
                },
                {
                  field: 'category',
                  label: 'Vendor Type',
                  type: 'multiAutoComplete',
                  api: '/vendorcategories',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                  filter: () => {
                    return {
                      _id: {
                        $in: [
                          '5373762d305ae50200359267',
                          '53a519d3b05ac802002c67ce',
                        ],
                      },
                    };
                  },
                },
                {
                  field: 'employee_team',
                  label: 'Employee Team',
                  type: 'autoComplete',
                  api: '/employeeteams',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  visible: ({values}) => {
                    // console.log('values>>>>>>', values);
                    if (
                      values?.category?.[0]?._id == '5373762d305ae50200359267'
                    ) {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (
                      values?.category?.[0]?._id == '5373762d305ae50200359267'
                    ) {
                      return true;
                    }
                  },
                },
                {
                  field: 'service_type',
                  label: 'Service Type',
                  type: 'autoComplete',
                  options: ['Recurring', 'Adhoc'],
                  size: 6,
                  required: true,
                  visible: ({values}) => {
                    if (
                      values?.category?.[0]?._id == '53a519d3b05ac802002c67ce'
                    ) {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (
                      values?.category?.[0]?._id == '53a519d3b05ac802002c67ce'
                    ) {
                      return true;
                    }
                  },
                },
                {
                  type: 'file',
                  field: 'contract_attachment',
                  placeholder: 'Contract information',
                  label: 'Contract information',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Address Details',
              fields: [
                {
                  field: 'correspondenceAddress',
                  label: 'Address',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'corresponding_address_city',
                  label: 'City/Village/Town',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'correspondenceState',
                  label: 'State',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'correspondencePinCode',
                  label: 'Pin Code',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'correspondenceCountry',
                  label: 'Country',
                  type: 'autoComplete',
                  api: '/countries',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  collapsedFields: [
                    {
                      field: 'address_proof',
                      label: 'Address Proof',
                      type: 'file',
                    },
                  ],
                },
              ],
            },
            {
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_vrf_recieved',
                  label: 'Is VRF Recieved',
                  size: 6,
                },
                {
                  type: 'file',
                  field: 'vrf_format',
                  label: 'VRF Format',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Accounting',
          fields: [
            {
              label: 'Accounting',
              fields: [
                {
                  type: 'autoComplete',
                  api: '/organizations',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'organization',
                  label: 'Organization',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  api: '/products',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'product',
                  label: 'Product',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  api: '/offices',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'location_id',
                  label: 'Location',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  api: '/employeeteams',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'team',
                  label: 'Team',
                  size: 6,
                  required: true,
                },
              ],
            },
          ],
        },
        {
          title: 'Taxation & compliances',
          fields: [
            {
              type: 'checkbox',
              field: 'is_sez_vendor',
              label: 'Is SEZ Vendor',
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'is_msme_vendor',
              label: 'Is MSME Vendor',
              size: 4,
            },
            {
              type: 'checkbox',
              field: 'is_itr_vendor',
              label: 'Is ITR Registered',
              size: 4,
            },
            {
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_gst_registered',
                  label: 'Is GST Registered',
                },
                {
                  field: 'gstin',
                  type: 'text',
                  label: 'GSTIN',
                  size: 3,
                  required: ({values}) => values && values.is_gst_registered,
                  visible: ({values}) => values && values.is_gst_registered,
                },
                {
                  field: 'gst_state',
                  type: 'text',
                  label: 'GST State',
                  size: 3,
                  required: ({values}) => values && values.is_gst_registered,
                  visible: ({values}) => values && values.is_gst_registered,
                },
                {
                  field: 'gst_state_code',
                  type: 'text',
                  label: 'Gst State Code',
                  size: 3,
                  required: ({values}) => values && values.is_gst_registered,
                  visible: ({values}) => values && values.is_gst_registered,
                },
                {
                  type: 'file',
                  field: 'gst_certificate',
                  label: 'GST Certificate',
                  size: 3,
                  required: ({values}) => values && values.is_gst_registered,
                  visible: ({values}) => values && values.is_gst_registered,
                },
              ],
            },
            {
              label: 'PAN Details',
              fields: [
                {
                  field: 'pan_no',
                  label: 'PAN No',
                  type: 'text',
                  size: 6,
                  required: true,
                },
                {
                  type: 'file',
                  field: 'pan_copy',
                  label: 'PAN Copy',
                },
              ],
            },
            {
              groups: [
                {
                  field: 'vendor_approval_details',
                  label: 'Allowed Expense',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'Expense Account',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          field: 'account',
                          size: 6,
                        },
                        {
                          label: 'Amount',
                          field: 'amount',
                          type: 'number',
                          size: 6,
                        },
                      ]}
                      columns={[
                        {
                          header: 'Expense Account',
                          type: 'text',
                          field: 'account.name',
                        },
                        {
                          header: 'Amount',
                          field: 'amount',
                          type: 'number',
                          align: 'left',
                        },
                      ]}
                    />
                  ),
                },
                {
                  field: 'vendor_tds_rate',
                  label: 'Vendor TDS Rate',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'TDS',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          field: 'account',
                          size: 6,
                        },
                        {
                          label: 'Rate %',
                          field: 'rate_percent',
                          type: 'number',
                          size: 6,
                        },
                        {
                          label: 'Valid From',
                          field: 'valid_from',
                          type: 'date',
                          size: 6,
                        },
                        {
                          label: 'Valid To',
                          field: 'valid_upto',
                          type: 'date',
                          size: 6,
                        },
                      ]}
                      columns={[
                        {
                          header: 'TDS',
                          type: 'text',
                          field: 'account.name',
                        },
                        {
                          header: 'Rate %',
                          field: 'rate_percent',
                          type: 'number',
                          align: 'left',
                        },
                        {
                          header: 'Valid From',
                          field: 'valid_from',
                          type: 'date',
                        },
                        {
                          header: 'Valid To',
                          field: 'valid_upto',
                          type: 'date',
                        },
                      ]}
                    />
                  ),
                },
                {
                  field: 'vendor_vat_rate',
                  label: 'Vendor VAT Rate',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'VAT',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          field: 'account',
                          size: 6,
                        },
                        {
                          field: 'rate_percent',
                          type: 'text',
                          label: 'Rate %',
                          size: 6,
                        },
                        {
                          field: 'valid_from',
                          type: 'date',
                          label: 'Valid From',
                          size: 6,
                        },
                        {
                          field: 'valid_upto',
                          type: 'date',
                          label: 'Valid To',
                          size: 6,
                        },
                      ]}
                      columns={[
                        {
                          field: 'account.name',
                          type: 'text',
                          header: 'VAT',
                        },
                        {
                          field: 'rate_percent',
                          type: 'number',
                          header: 'Rate %',
                          align: 'left',
                        },
                        {
                          field: 'valid_from',
                          type: 'date',
                          header: 'Valid From',
                        },
                        {
                          field: 'valid_upto',
                          type: 'date',
                          header: 'Valid To',
                        },
                      ]}
                    />
                  ),
                },
                {
                  field: 'vendor_gst_rate',
                  label: 'Vendor GST Rate',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      fields={[
                        {
                          label: 'IGST',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          field: 'igst',
                          size: 6,
                        },
                        {
                          field: 'igst_percent',
                          type: 'number',
                          label: 'IGST %',
                          size: 6,
                        },
                        {
                          field: 'cgst',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          label: 'CGST',
                          size: 6,
                        },
                        {
                          field: 'cgst_percent',
                          type: 'number',
                          label: 'CGST %',
                          size: 6,
                        },
                        {
                          field: 'sgst',
                          type: 'autoComplete',
                          api: '/accounts',
                          suggestionField: 'name',
                          valueField: 'name',
                          label: 'SGST',
                          size: 6,
                        },
                        {
                          field: 'sgst_percent',
                          type: 'number',
                          label: 'SGST %',
                          size: 6,
                        },
                        {
                          field: 'valid_from',
                          type: 'date',
                          label: 'Valid From',
                          size: 6,
                        },
                        {
                          field: 'valid_upto',
                          type: 'date',
                          label: 'Valid To',
                          size: 6,
                        },
                      ]}
                      columns={[
                        {
                          header: 'IGST',
                          type: 'text',
                          field: 'igst.name',
                        },
                        {
                          field: 'igst_percent',
                          type: 'number',
                          header: 'IGST %',
                        },
                        {
                          field: 'cgst.name',
                          type: 'text',
                          header: 'CGST',
                        },
                        {
                          field: 'cgst_percent',
                          type: 'number',
                          header: 'CGST %',
                        },
                        {
                          field: 'sgst.name',
                          type: 'text',
                          header: 'SGST',
                        },
                        {
                          field: 'sgst_percent',
                          type: 'number',
                          header: 'SGST %',
                        },
                        {
                          field: 'valid_from',
                          type: 'date',
                          header: 'Valid From',
                        },
                        {
                          field: 'valid_upto',
                          type: 'date',
                          header: 'Valid To',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Payment Details',
          fields: [
            {
              label: 'Bank Account Details',
              fields: [
                {
                  field: 'account_number',
                  label: 'Account Number',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'ifsc_code',
                  label: 'IFSC Code',
                  type: 'text',
                  size: 6,
                },
                {
                  field: 'account_type',
                  options: ['Current', 'Saving'],
                  label: 'Account Type',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  field: 'currency_id',
                  label: 'Currency',
                  api: '/currencies',
                  suggestionField: 'currency',
                  valueField: 'currency',
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  field: 'payable_account',
                  label: 'Account Payable',
                  type: 'autoComplete',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  field: 'pre_payment_account',
                  label: 'Advance Account',
                  type: 'autoComplete',
                  api: '/accounts',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            {
              label: 'Mode of Payment',
              fields: [
                {
                  type: 'radioGroup',
                  field: 'payment_mode',
                  options: [
                    {label: 'NEFT', value: 'NEFT'},
                    {label: 'Credit Card', value: 'Credit Card'},
                  ],
                  // required: true,
                },
              ],
            },
            {
              field: 'credit_days',
              label: 'Credit Days',
              type: 'number',
              size: 6,
            },
            {
              groups: [
                {
                  label: 'Vendor limit',
                  fields: [
                    {
                      type: 'number',
                      field: 'vendor_approval_limit',
                      label: 'Vendor Limit',
                    },
                  ],
                },
                {
                  label: 'Allowed expenses',
                  fields: [
                    {
                      field: 'approved_mail',
                      label: 'Approved document',
                      type: 'file',
                      size: 6,
                    },
                    {
                      field: 'document',
                      label: 'Invoice',
                      type: 'file',
                      multiple: true,
                      size: 6,
                    },
                  ],
                },
                {
                  label: 'Team',
                  fields: [
                    {
                      field: 'team',
                      api: '/employeeteams',
                      type: 'autoComplete',
                      valueField: 'name',
                      suggestionField: 'name',
                      label: 'Team',
                      size: 6,
                      required: true,
                    },
                    {
                      field: 'expense_approved_by',
                      type: 'autoComplete',
                      label: 'Expense Approve By',
                      api: '/employeeSuggestions',
                      suggestionField: 'name',
                      secondarySuggestionField: 'official_email_id',
                      size: 6,
                      required: true,
                    },
                  ],
                },
                {
                  label: 'Accounting',
                  fields: [
                    {
                      field: 'organization',
                      api: '/organizations',
                      type: 'autoComplete',
                      label: 'Organization',
                      searchField: 'name',
                      suggestionField: 'name',
                      required: true,
                      size: 6,
                    },
                    {
                      field: 'product',
                      api: '/products',
                      type: 'autoComplete',
                      label: 'Product',
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                      size: 6,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
      // layoutFields={[
      //   {
      //     label: 'Vendor Details',
      //     fields: [
      //       {
      //         field: 'contract',
      //         label: 'Contract',
      //         type: 'text',
      //         size: 6,
      //       },
      //       {
      //         field: 'email',
      //         label: 'Email',
      //         type: 'text',
      //         size: 6,
      //       },
      //       {
      //         field: 'contact_number',
      //         label: 'Contact Number',
      //         type: 'text',
      //         size: 6,
      //       },
      //       {
      //         field: 'contact_person',
      //         label: 'Contact Person',
      //         type: 'text',
      //         size: 6,
      //       },
      //       {
      //         field: 'vendor_hsn_code',
      //         label: 'HSN/SAC Code',
      //         type: 'text',
      //         size: 6,
      //       },
      //       {
      //         field: 'concern_person',
      //         label: 'Concern Person',
      //         api: '/employeeSuggestions',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         type: 'autoComplete',
      //         size: 6,
      //       },
      //       {
      //         field: 'purpose',
      //         label: 'Description',
      //         type: 'text',
      //         size: 6,
      //       },
      //     ],
      //   },
      //   {
      //     fields: [
      //       {
      //         type: 'checkbox',
      //         field: 'is_sez_vendor',
      //         label: 'Is SEZ Vendor',
      //         size: 6,
      //       },
      //       {
      //         type: 'checkbox',
      //         field: 'is_msme_vendor',
      //         label: 'Is MSME Vendor',
      //         size: 6,
      //       },
      //       {
      //         type: 'checkbox',
      //         field: 'is_gst_registered',
      //         label: 'Is GST Registered',
      //         size: 6,
      //       },
      //       {
      //         type: 'checkbox',
      //         field: 'is_itr_vendor',
      //         label: 'Is ITR Registered',
      //         size: 6,
      //       },
      //     ],
      //   },

      //   {
      //     label: 'Taxation Details(GST Information)',
      //     fields: [
      //       {
      //         field: 'gstin',
      //         type: 'text',
      //         label: 'GSTIN',
      //         size: 6,
      //         visible: ({row}) => row && row.is_gst_registered,
      //       },
      //       {
      //         field: 'gst_state',
      //         type: 'text',
      //         label: 'GST State',
      //         size: 6,
      //         visible: ({row}) => row && row.is_gst_registered,
      //       },
      //       {
      //         field: 'gst_state_code',
      //         type: 'text',
      //         label: 'Gst State Code',
      //         size: 6,
      //         visible: ({row}) => row && row.is_gst_registered,
      //       },
      //       {
      //         field: 'vat_number',
      //         type: 'text',
      //         label: 'VAT',
      //         size: 6,
      //       },
      //       {
      //         type: 'file',
      //         field: 'gst_certificate',
      //         label: 'GST Certificate',
      //         size: 6,
      //         visible: ({row}) => row && row.is_gst_registered,
      //       },
      //     ],
      //   },
      //   {
      //     label: 'Accounting Information',
      //     fields: [
      //       {
      //         field: 'payable_account',
      //         label: 'Account Payable',
      //         type: 'autoComplete',
      //         api: '/accounts',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         size: 6,
      //       },
      //       {
      //         field: 'pre_payment_account',
      //         label: 'Advance Account',
      //         type: 'autoComplete',
      //         api: '/accounts',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         size: 6,
      //       },
      //       {
      //         field: 'credit_days',
      //         label: 'Credit Days',
      //         type: 'number',
      //         size: 6,
      //       },
      //       {
      //         field: 'pan_no',
      //         label: 'PAN No',
      //         type: 'text',
      //         size: 6,
      //       },
      //     ],
      //   },

      //   {
      //     label: 'Document Details',
      //     fields: [
      //       {
      //         type: 'file',
      //         field: 'pan_copy',
      //         label: 'PAN Copy',
      //       },
      //       {
      //         type: 'file',
      //         field: 'cheque_copy',
      //         label: 'Cheque Copy',
      //       },
      //     ],
      //   },
      //   {
      //     label: 'Mode Of Payment',
      //     fields: [
      //       {
      //         type: 'autoComplete',
      //         options: ['NEFT', 'Credit Card'],
      //         field: 'payment_mode',
      //         label: 'Payment Mode',
      //       },
      //     ],
      //   },
      //   {
      //     label: 'Vendor Limit',
      //     fields: [
      //       {
      //         type: 'number',
      //         field: 'vendor_approval_limit',
      //         label: 'Vendor Limit',
      //       },
      //     ],
      //   },
      //   {
      //     groups: [
      //       {
      //         field: 'vendor_approval_details',
      //         label: 'Allowed Expense',
      //         nested: true,
      //         render: props => (
      //           <NestedTable
      //             {...props}
      //             fields={[
      //               {
      //                 label: 'Account Payable',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 field: 'account',
      //                 size: 6,
      //               },
      //               {
      //                 label: 'Amount',
      //                 field: 'amount',
      //                 type: 'number',
      //                 size: 6,
      //               },
      //             ]}
      //             columns={[
      //               {
      //                 header: 'Account Payable',
      //                 type: 'text',
      //                 field: 'account',
      //               },
      //               {
      //                 header: 'Amount',
      //                 field: 'amount',
      //                 type: 'number',
      //                 align: 'left',
      //               },
      //             ]}
      //           />
      //         ),
      //       },
      //     ],
      //   },
      //   {
      //     groups: [
      //       {
      //         field: 'CustomerTdsRate',
      //         label: 'Vendor TDS Rate',
      //         nested: true,
      //         render: props => (
      //           <NestedTable
      //             {...props}
      //             fields={[
      //               {
      //                 label: 'Account Payable',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 field: 'account',
      //                 size: 6,
      //               },
      //               {
      //                 label: 'Percentage',
      //                 field: 'rate_percent',
      //                 type: 'number',
      //                 size: 6,
      //               },
      //               {
      //                 label: 'Valid From',
      //                 field: 'valid_from',
      //                 type: 'date',
      //                 size: 6,
      //               },
      //               {
      //                 label: 'Valid Till',
      //                 field: 'valid_upto',
      //                 type: 'date',
      //                 size: 6,
      //               },
      //             ]}
      //             columns={[
      //               {
      //                 header: 'Account Payable',
      //                 type: 'text',
      //                 field: 'account',
      //               },
      //               {
      //                 header: 'Percentage',
      //                 field: 'rate_percent',
      //                 type: 'number',
      //                 align: 'left',
      //               },
      //               {
      //                 header: 'Valid From',
      //                 field: 'valid_from',
      //                 type: 'date',
      //               },
      //               {
      //                 header: 'Valid Till',
      //                 field: 'valid_upto',
      //                 type: 'date',
      //               },
      //             ]}
      //           />
      //         ),
      //       },
      //     ],
      //   },
      //   {
      //     groups: [
      //       {
      //         field: 'customerVatRate',
      //         label: 'Vendor VAT Rate',
      //         nested: true,
      //         render: props => (
      //           <NestedTable
      //             {...props}
      //             fields={[
      //               {
      //                 label: 'Account Payable',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 field: 'account',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'rate_percent',
      //                 type: 'text',
      //                 label: 'Rate %',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'valid_from',
      //                 type: 'date',
      //                 label: 'Valid From',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'valid_upto',
      //                 type: 'date',
      //                 label: 'Valid To',
      //                 size: 6,
      //               },
      //             ]}
      //             columns={[
      //               {
      //                 field: 'account',
      //                 type: 'text',
      //                 header: 'Account Payable',
      //               },
      //               {
      //                 field: 'rate_percent',
      //                 type: 'number',
      //                 header: 'Rate %',
      //                 align: 'left',
      //               },
      //               {
      //                 field: 'valid_from',
      //                 type: 'date',
      //                 header: 'Valid From',
      //               },
      //               {
      //                 field: 'valid_upto',
      //                 type: 'date',
      //                 header: 'Valid To',
      //               },
      //             ]}
      //           />
      //         ),
      //       },
      //     ],
      //   },
      //   {
      //     groups: [
      //       {
      //         field: 'customerGstRate',
      //         label: 'Vendor GST Rate',
      //         nested: true,
      //         render: props => (
      //           <NestedTable
      //             {...props}
      //             fields={[
      //               {
      //                 label: 'IGST',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 field: 'igst',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'igst_percent',
      //                 type: 'number',
      //                 label: 'IGST Percent',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'cgst',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 label: 'CGST',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'cgst_percent',
      //                 type: 'number',
      //                 label: 'CGST Percent',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'sgst',
      //                 type: 'autoComplete',
      //                 api: '/accounts',
      //                 suggestionField: 'name',
      //                 valueField: 'name',
      //                 label: 'SGST',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'sgst_percent',
      //                 type: 'number',
      //                 label: 'SGST Percent',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'valid_from',
      //                 type: 'date',
      //                 label: 'Valid From',
      //                 size: 6,
      //               },
      //               {
      //                 field: 'valid_upto',
      //                 type: 'date',
      //                 label: 'Valid To',
      //                 size: 6,
      //               },
      //             ]}
      //             columns={[
      //               {
      //                 header: 'IGST',
      //                 type: 'text',
      //                 field: 'igst',
      //               },
      //               {
      //                 field: 'igst_percent',
      //                 type: 'number',
      //                 header: 'IGST Percent',
      //               },
      //               {
      //                 field: 'cgst',
      //                 type: 'text',
      //                 header: 'CGST',
      //               },
      //               {
      //                 field: 'cgst_percent',
      //                 type: 'number',
      //                 header: 'CGST Percent',
      //               },
      //               {
      //                 field: 'sgst',
      //                 type: 'text',
      //                 header: 'SGST',
      //               },
      //               {
      //                 field: 'sgst_percent',
      //                 type: 'number',
      //                 header: 'SGST Percent',
      //               },
      //               {
      //                 field: 'valid_from',
      //                 type: 'date',
      //                 header: 'Valid From',
      //               },
      //               {
      //                 field: 'valid_upto',
      //                 type: 'date',
      //                 header: 'Valid To',
      //               },
      //             ]}
      //           />
      //         ),
      //       },
      //     ],
      //   },
      //   {
      //     label: 'Account',
      //     fields: [
      //       {
      //         type: 'autoComplete',
      //         api: '/organizations',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'organization',
      //         label: 'Organization',
      //         size: 6,
      //       },
      //       {
      //         type: 'autoComplete',
      //         api: '/products',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'product',
      //         label: 'Product',
      //         size: 6,
      //       },
      //       {
      //         type: 'autoComplete',
      //         api: '/offices',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'location_id',
      //         label: 'Location',
      //         size: 6,
      //       },
      //       {
      //         type: 'autoComplete',
      //         api: '/departments',
      //         suggestionField: 'name',
      //         valueField: 'name',
      //         field: 'department',
      //         label: 'Department',
      //         size: 6,
      //       },
      //       {
      //         type: 'text',
      //         field: 'purpose',
      //         label: 'Purpose',
      //         size: 6,
      //       },
      //     ],
      //   },
      // ]}
      {...props}
    />
  );
};

export const EditVendorForm = props => {
  return (
    <VendorTableViewDetail {...props} mode="edit" header={'Vendor Details'} />
  );
};

export const AddVendorForm = props => {
  return <VendorTableViewDetail {...props} header={'Add Vendor'} />;
};
