import {useTheme} from '@unthinkable/react-theme';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  Image,
  Row,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {isMobile, resolveExp} from '@unthinkable/react-utils';
import {WithModal} from '../../../components/modal/WithModal';
import {AIIconButton, IconButton} from '../../../components/button/IconButton';
import {ColorTag} from '../../../components/chip/ColorChip';
import {useAIActions} from '../actions/AIActions';
import {ButtonGroup} from '../../../components/button/Button';
import {useFetchData} from '../../../controllers/useFetchData';
import pluralize from 'pluralize';

export const AISuggestionsButton = props => {
  const {
    navigation,
    params,
    entityName,
    uri,
    filter,
    suggestionUri,
    eventSourceId = 'ProjectAI',
    newPromptName = 'EntityNewSuggestions',
    improvePromptName = 'EntityImproveSuggestions',
    morePromptName = 'EntityMoreSuggestions',
    improveMorePromptName = 'EntityImproveMoreSuggestions',
  } = props;
  const {icons} = useTheme();

  const pluralEntityName = entityName ? pluralize.plural(entityName) : void 0;

  const {data: suggestionCountData, loading: suggestionDataLoading} =
    useFetchData({
      api: suggestionUri || `/${pluralEntityName}/ai`,
      count: true,
      params,
      eventSourceId,
    });

  const {data: entityCountData, loading: entityDataLoading} = useFetchData({
    api: uri || `/${pluralEntityName}`,
    count: true,
    filter,
    params,
    eventSourceId,
  });

  const {
    loadingAction,
    onNewSuggestions,
    onMoreSuggestions,
    onImproveSuggestions,
    onAcceptAll,
    onRejectAll,
  } = useAIActions({
    ...props,
    pluralEntityName,
  });
  if (suggestionDataLoading !== false && entityDataLoading !== false) {
    return null;
  }

  const isMoreSuggestion = entityCountData?.count > 0;

  let actions = [];
  if (suggestionCountData?.count) {
    const {entity_category_prompts} = params;
    let options = [];

    if (entity_category_prompts?.length) {
      options = entity_category_prompts?.map(prompt => {
        return {
          label: prompt?.label || prompt.category,
          value: {...prompt, prompt_name: 'ImproveDetailSuggestion'},
        };
      });
      options.unshift({
        label: params.label || 'All',
        value: {},
      });
    }
    actions.push(
      <AIIconButton
        icon={icons.ChatWithAI}
        title={
          isMoreSuggestion
            ? 'Chat with AI to improve more suggestions'
            : 'Chat with AI to improve suggestions'
        }
        loading={loadingAction === 'ImproveSuggestions'}
        disabled={loadingAction}
        onPress={() => {
          navigation.navigate('ai-suggestion-form', {
            options,
            onSubmit: onImproveSuggestions({
              prompt_name: isMoreSuggestion
                ? improveMorePromptName
                : improvePromptName,
              is_more_suggestion: isMoreSuggestion ? true : void 0,
            }),
          });
        }}
      />,
    );
    actions.push(
      <AIIconButton
        icon={icons.Regenerate}
        title={'Refresh'}
        loading={loadingAction === 'NewSuggestions'}
        disabled={loadingAction}
        onPress={onNewSuggestions({
          prompt_name: isMoreSuggestion ? morePromptName : newPromptName,
          is_more_suggestion: isMoreSuggestion ? true : void 0,
        })}
      />,
    );
    actions.push(
      <AIIconButton
        icon={icons.AcceptAI}
        title="Accept All"
        loading={loadingAction === 'AcceptAllSuggestions'}
        disabled={loadingAction}
        onPress={onAcceptAll}
      />,
    );
    actions.push(
      <AIIconButton
        icon={icons.CrossAI}
        title="Reject All"
        loading={loadingAction === 'RejectAllSuggestions'}
        disabled={loadingAction}
        onPress={onRejectAll}
      />,
    );
  } else if (isMoreSuggestion) {
    actions.push(
      <AIIconButton
        title={'Ask AI for more suggestions'}
        icon={icons.GenerateAI}
        loading={loadingAction === 'MoreSuggestions'}
        disabled={loadingAction}
        onPress={() => {
          navigation.navigate('ai-suggestion-form', {
            onSubmit: onMoreSuggestions({
              prompt_name: morePromptName,
              is_more_suggestion: true,
            }),
          });
        }}
      />,
    );
  } else {
    actions.push(
      <AIIconButton
        title={'Ask AI for suggestions'}
        icon={icons.GenerateAI}
        loading={loadingAction === 'NewSuggestions'}
        disabled={loadingAction}
        onPress={onNewSuggestions({
          prompt_name: newPromptName,
        })}
      />,
    );
  }

  return <ButtonGroup>{actions}</ButtonGroup>;
};

export const AcceptRejectButton = ({onAccept, onReject}) => {
  const theme = useTheme();
  const {icons} = theme;
  return (
    <Row gap={8}>
      {onAccept ? (
        <IconButton
          icon={icons.GreyTick}
          title="Accept"
          onPress={() => {
            onAccept();
          }}
        />
      ) : (
        void 0
      )}
      {onReject ? (
        <IconButton
          icon={icons.Close}
          title="Reject"
          onPress={() => {
            onReject();
          }}
        />
      ) : (
        void 0
      )}
    </Row>
  );
};

export const AIGeneratedIcon = () => {
  const icons = useTheme('icons');
  return (
    <Image
      source={icons.AISuggested}
      style={{
        height: 20,
        width: 90,
      }}
    />
  );
};

export const AIReviewSuggestion = ({
  row,
  onAccept,
  onReject,
  primaryTitle,
  secondaryTitle,
}) => {
  const icons = useTheme('icons');

  return (
    <WithModal
      width={isMobile ? void 0 : 400}
      renderModal={({hide}) => {
        return (
          <TouchableOpacity onPress={() => {}}>
            <PrimaryColumnCard
              primaryTitle={primaryTitle}
              secondaryTitle={secondaryTitle}
              numberOfLines={6}
            />
            <View style={{alignItems: 'flex-end', paddingTop: 8}}>
              <AcceptRejectButton
                onAccept={() => {
                  hide();
                  onAccept({row});
                }}
                onReject={() => {
                  hide();
                  onReject({row});
                }}
              />
            </View>
          </TouchableOpacity>
        );
      }}
      position={isMobile ? 'screenBottom' : 'bottom'}
      placement="start">
      <Image
        source={icons.ViewSuggestion}
        style={{
          height: 20,
        }}
      />
    </WithModal>
  );
};

export const AISuggestionRender = props => {
  const {row, primaryField, secondaryField, acceptBody, ...restProps} = props;
  const {onAccept, onReject} = useAIActions(props);
  const {icons, fonts, colors} = useTheme();

  const primaryTitle = resolveExp(row, primaryField);
  let secondaryTitle = secondaryField
    ? resolveExp(row, secondaryField)
    : void 0;

  const {aiGenerated, aiSuggestion} = row || {};

  let renderComponent = (
    <PrimaryColumnCard
      numberOfLines={1}
      secondaryTitleNumberOfLines={2}
      primaryTitle={primaryTitle}
      secondaryTitle={secondaryTitle}
      secondaryTitleFont={fonts.BODY3}
      secondaryTitleColor={colors.NEUTRAL_MEDIUM}
      {...restProps}
      renderIcon={() => {
        if (aiGenerated) {
          return <AIGeneratedIcon />;
        } else if (aiSuggestion) {
          const reviewPrimaryTitle = resolveExp(aiSuggestion, primaryField);
          let reviewSecondaryTitle = secondaryField
            ? resolveExp(aiSuggestion, secondaryField)
            : void 0;
          return (
            <AIReviewSuggestion
              row={row}
              primaryTitle={reviewPrimaryTitle}
              secondaryTitle={reviewSecondaryTitle}
              onAccept={onAccept}
              onReject={onReject}
            />
          );
        } else {
          return void 0;
        }
      }}
    />
  );
  if (aiGenerated) {
    renderComponent = (
      <Row style={{alignItems: 'center'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>{renderComponent}</View>
        <IconButton
          icon={icons.Close}
          title="Reject"
          onPress={() => {
            onReject({row});
          }}
        />
      </Row>
    );
  }
  return renderComponent;
};
