import { LeftMenuView } from '../../../components/menu/LeftMenuView';
import { EmployeeSelfAppraisalTable } from './EmployeeAppraisalTable';
import {HRInProcessAppraisalTab, HRPendingAppraisalTab, HRProcessedAppraisalTab } from './HRAppraisalTabs';
import { SelfAppraisal } from './SelfAppraisalForm';

export const EmployeeAppraisalMenu = props => {
    const menus = [
        {
            label: 'Pending',
            view: <HRPendingAppraisalTab />,
        },
        {
            label: 'In Process',
            view: <HRInProcessAppraisalTab />,
        },
        {
            label: 'Processed',
            view: <HRProcessedAppraisalTab />,
        },
        // {
        //     label: 'Closed',
        //     view: <HRClosedAppraisalTab />,
        // },
        // {
        //     label: 'Extended',
        //     view: <HRExtendedAppraisalTab />,
        // },
        // {
        //     label: 'On Hold',
        //     view: <HROnHoldAppraisalTab />,
        // },
        // {
        //     label: 'Promotion Recommendations',
        //     view: <HRPromotionRecommendationsAppraisalTab />,
        // },
        // {
        //     label: 'Inactive',
        //     view: <HRInactiveAppraisalTab />,
        // },
    ];
    const { route: { params } = {} } = props;

    return <LeftMenuView {...props} params={params} menus={menus} />;
};


export const SelfAppraisalMenu = props => {
    const menus = [
        {
            label: 'Performance Management',
            view: <SelfAppraisal />,
        },
        {
            label: 'Previous Appraisal',
            view: <EmployeeSelfAppraisalTable />,
        },
    ];
    const { route: { params } = {} } = props;

    return <LeftMenuView {...props} params={params} menus={menus} />;
};