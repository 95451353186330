import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const UpdateDueDateForm = props => {
  const {
    route: {params},
  } = props;

  const {row = {}, api} = params;
  let {onSubmit} = useFormSubmit({
    uri: api,
    eventSourceId: 'Task',
  });

  return (
    <Form
      onSubmit={onSubmit}
      api={api + '/' + row?._id}
      submitAction="Save"
      mode="edit"
      header={{
        title: 'Update Due Date',
        secondaryTitle: row.name,
      }}
      layoutFields={[
        {
          label: 'Due Date',
          field: 'due_date',
          type: 'date',
        },
      ]}
    />
  );
};
