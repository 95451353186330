import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {TableHeader} from '../../../components/table/Headers';
import {useAppStateContext} from '../../../providers/AppState';
import {PerformanceBilledReportTableForAm} from './PerformanceReportTable';
import {ProjectEfficiencyTableAM} from './ProjectEfficiencyTab';

const groupFilterArray = [
  {
    type: 'autoComplete',
    label: 'Customer',
    api: '/customers',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'customer',
  },
  {
    type: 'autoComplete',
    options: [
      'Client Managed',
      'Daffodil Managed',
      'Hybrid Managed',
      'Daffodil Managed - Support',
    ],
    field: 'project_sub_category',
    label: 'Managed By',
  },
  {
    label: 'Tool',
    field: 'tool',
    placeholder: 'Select Tool',
    type: 'autoComplete',
    options: ['Locomo', 'Other'],
  },
  {
    label: 'Feedback Mode',
    field: 'feedback_mode',
    placeholder: 'Select Feedback Mode',
    type: 'autoComplete',
    options: ['Automatic', 'Manual'],
  },
  {
    label: 'Last automatic feedback request',
    field: 'automatic_feedback_request_success_date',
    type: 'date',
  },
  {
    label: 'Last automatic feedback failure',
    field: 'automatic_feedback_request_failure_date',
    type: 'date',
  },
];

export const ProjectTabsAm = props => {
  let {route: {params} = {}, project_status, tab, is_project_assign} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  const tabs = {
    staff_augmentation: {
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'staffaugmentation',
            is_project_assign,
          }}
          tabName="staff"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'staffaugmentation',
          }}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        account_owner: employeeId?._id,
        new_project_type: 'staffaugmentation',
        is_project_assign,
      },
      limit: 2000,
      params: filter,
    },

    managed_team: {
      label: 'Managed Team',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'managedteam',
            is_project_assign,
          }}
          tabName="managed"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'managedteam',
          }}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        account_owner: employeeId?._id,
        new_project_type: 'managedteam',
        is_project_assign,
      },
      limit: 2000,
      params: filter,
    },

    sdlc: {
      label: 'SDLC',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'sdlc',
            is_project_assign,
          }}
          tabName="sdlc"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlc'}}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        new_project_type: 'sdlc',
        account_owner: employeeId?._id,
        is_project_assign,
      },
      limit: 2000,
      params: filter,
    },

    sdlcplus: {
      label: 'SDLC+',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            projecttype: 'f',
            account_owner: employeeId?._id,
            is_project_assign,
            new_project_type: 'sdlcplus',
          }}
          tabName="sdlcplus"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        projecttype: 'f',
        account_owner: employeeId?._id,
        is_project_assign,
        new_project_type: 'sdlcplus',
      },
      limit: 2000,
      params: filter,
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title={`Billed Projects`} />}
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const ProjectTandMTabsAm = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  const tabs = {
    staff_augmentation: {
      apiKey: 'active',
      label: 'Staff Augmentation',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'staffaugmentation',
          }}
          tabName="staff"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'staffaugmentation',
          }}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        account_owner: employeeId?._id,
        new_project_type: 'staffaugmentation',
      },
      limit: 2000,
      params: filter,
    },

    managed_team: {
      apiKey: 'active',
      label: 'Managed Team',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'managedteam',
          }}
          tabName="managed"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{
            product: params?.product?._id,
            new_project_type: 'managedteam',
          }}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        account_owner: employeeId?._id,
        new_project_type: 'managedteam',
      },
      limit: 2000,
      params: filter,
    },

    sdlc: {
      apiKey: 'active',
      label: 'SDLC',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            account_owner: employeeId?._id,
            new_project_type: 'sdlc',
          }}
          tabName="sdlc"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlc'}}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        new_project_type: 'sdlc',
        account_owner: employeeId?._id,
      },
      limit: 2000,
      params: filter,
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title={`Billed Projects`} />}
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const ProjectFixedTabsAm = props => {
  let {
    route: {params} = {},
    project_status,
    tab,
    addOnParamFilter = {},
  } = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      ...addOnParamFilter,
    },
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  const tabs = {
    sdlcplus: {
      label: 'Fixed',
      apiKey: 'active',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            projecttype: 'f',
            account_owner: employeeId?._id,
            new_project_type: 'sdlcplus',
          }}
          tabName="sdlcplus"
          project_status={project_status}
        />
      ),
      actions: [
        <AddButton
          title={'Project'}
          view={'add-project'}
          params={{product: params?.product?._id, new_project_type: 'sdlcplus'}}
        />,
      ],
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        projecttype: 'f',
        account_owner: employeeId?._id,
        new_project_type: 'sdlcplus',
      },
      limit: 2000,
      params: filter,
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title={`Billed Projects`} />}
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};

export const ProjectEfficiencyTabAM = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const tabs = {
    project_gross_margin_report: {
      apiKey: 'active',
      label: 'Project Efficiency',
      view: (
        <ProjectEfficiencyTableAM
          key="FixedGrossMargin"
          filterParams={filterParams}
          filter={{...filter, period}}
          isCustomerGrossMargin={true}
        />
      ),
      actions: [
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              field: 'project_id',
              api: '/projects',
              suggestionField: 'project',
              valueField: 'project',
              label: 'Project',
            },
          ]}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const PendingSDLCProjectTabsAm = props => {
  let {route: {params} = {}, project_status, tab} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {params: filterParams} = filterValues;
  let filter = {...params, ...filterParams};
  const tabs = {
    sdlcplus: {
      apiKey: 'active',
      label: 'Fixed',
      view: (
        <PerformanceBilledReportTableForAm
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          searchValue={searchValue}
          params={{filter}}
          addOnFilter={{
            ...filterValues.filter,
            project_status: project_status,
            projecttype: 'f',
            account_owner: employeeId?._id,
            project_plan_status: 'Approval Pending',
          }}
          tabName="sdlcplus"
          project_status={project_status}
        />
      ),
      api: '/project/performance-report',
      search: searchValue,
      searchFields: 'project',
      addOnFilter: {
        ...filterValues.filter,
        project_status: project_status,
        projecttype: 'f',
        account_owner: employeeId?._id,
        project_plan_status: 'Approval Pending',
      },
      limit: 2000,
      params: filter,
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title={`Billed Projects`} />}
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={groupFilterArray}
        />,
      ]}
      params={params}
      {...props}
    />
  );
};
