import React from 'react';
import {useFilter} from '@unthinkable/react-filters';

import {TabView} from '../../../components/tab';
import {UserStoryScreen} from './UserstoryTable';

export const UserStoryTab = props => {
  let {
    route: {params},
  } = props;

  const {onChangeFilter, searchValue, filterValues} = useFilter({});
  const {project, module, feature, projectlibrary_id} = params;

  let filter = {
    ...filterValues.filter,
    project_id: project?._id,
    library_id: projectlibrary_id || null,
  };

  if (feature) {
    filter.objective_ids = feature?._id;
  } else if (module) {
    filter.module_id = module?._id;
  }

  const countProps = {
    search: searchValue,
    searchFields: ['userstory'],
    eventSourceId: ['UserStory', 'RemoveMappedFeature', 'ProjectAI'],
    filter: filter,
  };

  return (
    <TabView
      tabs={{
        all: {
          label: 'All',
          view: (
            <UserStoryScreen
              api={'/projectUserstories'}
              selectedTab={'all'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              {...props}
            />
          ),
          api: '/projectUserstories',
          ...countProps,
        },
        backlog: {
          label: 'Backlog',
          view: (
            <UserStoryScreen
              api={'/userstories/backlog'}
              selectedTab={'backlog'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              subStatus={null}
              {...props}
            />
          ),
          api: '/userstories/backlog',
          ...countProps,
          filter: {...filter, sub_status: null},
        },
        architecture: {
          label: 'Architecture',
          view: (
            <UserStoryScreen
              api={'/userstories/backlog'}
              selectedTab={'backlog'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              subStatus="Architecture"
              {...props}
            />
          ),
          api: '/userstories/backlog',
          ...countProps,
          filter: {...filter, sub_status: 'Architecture'},
        },
        development: {
          label: 'Development',
          view: (
            <UserStoryScreen
              api={'/userstories/development'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              {...props}
            />
          ),
          api: '/userstories/development',
          ...countProps,
        },
        qa: {
          label: 'QA',
          view: (
            <UserStoryScreen
              api={'/userstories/qa'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              {...props}
            />
          ),
          api: '/userstories/qa',
          ...countProps,
        },
        released: {
          label: 'Released',
          view: (
            <UserStoryScreen
              api={'/userstories/released'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              {...props}
            />
          ),
          api: '/userstories/released',
          ...countProps,
        },
      }}
    />
  );
};
