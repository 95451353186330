import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const AddCustomPlatformOpportunityForm = props => {
  const {route} = props;
  const {route: {params} = {}, header = 'Add Custom Platform Opportunity'} =
    props;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ...props,
  });
  const {row} = params;
  const {user, fetch, post} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  let defaultValues = {
    rfr_type: 'Customer',
    opportunity_type: 'CustomPlatformOpportunity',
    owner: employeeId,
    organization: params?.organization || employee?.organization,
    product: params?.product || employee?.product,
    quote_status: 'Pending',
    rfr_status: 'Received',
    creation_date: new Date(),
    status: 'New',
  };

  if (route?.name == 'add-customplatformopportunity-proposal') {
    const {leadId} = params;
    const {data} = useFetchFormData({
      api: '/potentialCustomers',
      filter: {
        _id: leadId,
      },
      fields: {
        name: 1,
        owner: {_id: 1, name: 1},
        industry: {_id: 1, name: 1},
        department: {_id: 1, name: 1},
        product: {_id: 1, name: 1},
        organization: {_id: 1, name: 1},
        campaign_type: {_id: 1, label: 1, color: 1},
      },
    });
    if (!data) {
      return null;
    }
    const {
      _id,
      owner,
      department,
      product,
      organization,
      campaign_type,
      name,
      ...restdata
    } = data;

    defaultValues = {
      ...restdata,
      account_owner: owner,
      potential_customer: {_id: leadId, name: name},
      rfr_type: 'Customer',
      opportunity_type: 'CustomPlatformOpportunity',
      owner: employeeId,
      organization,
      product,
      quote_status: 'Pending',
      rfr_status: 'Received',
      creation_date: new Date(),
      status: 'New',
      campaign_type: campaign_type,
    };
  }
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      header={header}
      submitAction={'Save'}
      api={`/opportunities/${row?._id}`}
      fields={{
        rfr_type: 1,
        customer_id: {name: 1},
        potential_customer: {name: 1},
        requirement: 1,
        description: 1,
        skill: {name: 1},
        practice: {name: 1},
        type: 1,
        priority: 1,
        due_date: 1,
        budget: 1,
        emd: 1,
        tender_fee: 1,
        bid_opening_date: 1,
        pre_bid_meeting_date: 1,
        product: {name: 1},
        organization: {name: 1},
        tech_person: {name: 1},
        requirement_document: 1,
        primary_owner: {name: 1},
        buisness_analyst: {name: 1},
        rfq_owner: {name: 1},
        creation_date: 1,
        owner: {name: 1},
        employee_team: {name: 1},
        channel: {name: 1},
      }}
      layoutFields={[
        // {
        //   type: 'radioGroup',
        //   field: 'rfr_type',
        //   // options: ['Customer'],
        //   // options: ['Customer', 'PotentailCustomer'],
        //   options: [
        //     {
        //       label: 'Customer',
        //       value: 'Customer',
        //     },
        //     // {
        //     //   label: 'Potential Customer',
        //     //   value: 'PotentailCustomer',
        //     // },
        //   ],
        // },
        {
          fields: [
            {
              label: 'Customer',
              field: 'customer_id',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              visible: ({values}) =>
                values && values.rfr_type && values.rfr_type === 'Customer',
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/customers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           channel: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.channel);
              //   }
              // },
            },
            {
              label: 'Potential Customer',
              field: 'potential_customer',
              type: 'autoComplete',
              api: `/potentialcustomers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // onChangeValue: async (value, e, {setFieldValue}) => {
              //   if (value?._id) {
              //     const {data: customerData} = await fetch({
              //       uri: `/potentialCustomers/${value?._id}`,
              //       props: {
              //         fields: {
              //           industry: {_id: 1, name: 1},
              //           country: {_id: 1, name: 1},
              //           lead_score: 1,
              //           website: 1,
              //           email: 1,
              //           campaign_type: {_id: 1, label: 1},
              //         },
              //       },
              //     });
              //     setFieldValue('industry', customerData?.industry);
              //     setFieldValue('country', customerData?.country);
              //     setFieldValue('campaign_type', customerData?.campaign_type);
              //   }
              // },
              visible: ({values}) =>
                values &&
                values.rfr_type &&
                values.rfr_type === 'PotentailCustomer',
            },
            {
              type: 'textArea',
              field: 'requirement',
              label: 'Requirement',
              required: true,
            },
            {
              // type: 'richText',
              type: 'textArea',
              field: 'description',
              label: 'Description',
              required: true,
            },
            {
              type: 'date',
              field: 'creation_date',
              label: 'Created On',
              required: true,
              size: 6,
            },
            // {
            //   field: 'skill',
            //   type: 'multiAutoComplete',
            //   api: '/tools',
            //   label: 'Skill',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   required: true,
            //   size: 6,
            // },
            {
              label: 'Practice',
              field: 'practice',
              type: 'multiAutoComplete',
              api: '/practices',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              field: 'type',
              label: 'Type',
              type: 'autoComplete',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Services-POC',
                  value: 'Proof of Concept',
                },
                {
                  label: 'Services-Fixed Cost',
                  value: 'Fixed Cost',
                },
                {
                  label: 'Services-D&F',
                  value: 'Discovery and Framework',
                },
                {
                  label: 'Services-Bidding',
                  value: 'Bidding',
                },
                {
                  label: 'Services-Tender',
                  value: 'Tender',
                },
                // 'Bidding',
                // 'Services',
                // 'Tender',
              ],
              size: 6,
            },
            {
              field: 'priority',
              type: 'autoComplete',
              options: ['High', 'Medium', 'Low'],
              size: 6,
              label: 'Priority',
            },
            {
              type: 'date',
              field: 'due_date',
              label: 'Target Submission',
              required: true,
              size: 6,
              validate: (field, {values}) => {
                if (
                  field &&
                  getZeroTimeDate(field) <
                    getZeroTimeDate(values?.creation_date)
                ) {
                  return 'Target Submission cannot be less than Creation Date';
                }
              },
            },
          ],
        },
        {
          type: 'text',
          field: 'budget',
          label: 'Budget',
          size: 6,
        },
        {
          type: 'number',
          visible: ({values}) =>
            values && values.type && values.type === 'Tender',
          // mandatory: true,
          field: 'emd',
          label: 'EMD',
          size: 6,
        },
        {
          type: 'number',
          visible: ({values}) =>
            values && values.type && values.type === 'Tender',
          // mandatory: true,
          field: 'tender_fee',
          label: 'Tender Fee',
          size: 6,
        },
        {
          type: 'date',
          visible: ({values}) =>
            values && values.type && values.type === 'Tender',
          // mandatory: true,
          field: 'bid_opening_date',
          label: 'Bid Opening Date',
          size: 6,
        },
        {
          type: 'date',
          visible: ({values}) =>
            values && values.type && values.type === 'Tender',
          // mandatory: true,
          field: 'pre_bid_meeting_date',
          label: 'Pre Bid Opening Date',
          size: 6,
        },
        {
          label: 'Product',
          field: 'product',
          type: 'autoComplete',
          api: `/products`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Channel',
          field: 'channel',
          type: 'autoComplete',
          api: `/channels`,
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            _id: {
              $nin: ['66d2ec16f897151a53daf08b'],
            },
          },
          size: 6,
        },
        {
          label: 'Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: `/employeeteams`,
          filter: {
            _id: {
              $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
            },
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Sales Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          visible: ({}) => {
            if (
              employeeId == '53a437e36dc89c02007cbbf0' ||
              employeeId == '540458a0dd329b0c9672efc1'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          label: 'Technical Consultant',
          field: 'tech_person',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          validate: async (fieldValue, {values}) => {
            const {data} = await post({
              uri: `/customplatform/techconsultantValidation`,
              method: 'post',
              props: {
                techCon: fieldValue,
                formValues: values,
              },
            });
            if (data) {
              return data;
            }
          },
        },
        {
          label: 'Solution Consultant',
          field: 'primary_owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          validate: async (fieldValue, {values}) => {
            const {data} = await post({
              uri: `/customplatform/consultantValidation`,
              method: 'post',
              props: {
                solCon: fieldValue,
                formValues: values,
              },
            });
            if (data) {
              return data;
            }
          },
        },
        {
          label: 'Business Analyst',
          field: 'buisness_analyst',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          label: 'Buisness Consultant',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          placeholder: 'Attachment',
          label: 'Attachment',
          field: 'requirement_document',
          multiple: true,
          type: 'file',
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const EditCustomPlatformOpportunityForm = props => {
  if (props?.route?.name == 'presales-opportunity-details') {
    return (
      <AddCustomPlatformOpportunityForm
        header={'Pre Sale Opportunity Details'}
        readOnly={true}
        mode={'edit'}
        isDualMode
        {...props}
      />
    );
  }
  return (
    <AddCustomPlatformOpportunityForm
      header={'Edit Custom Platform Opportunity'}
      mode={'edit'}
      isDualMode
      {...props}
    />
  );
};

export const MarkOpportunityCloseForm = props => {
  const params = props?.route?.params;
  const selectedIds = params?.selectedIds;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Close'}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Close Type',
          type: 'autoComplete',
          field: 'status',
          options: ['Won', 'Partially Won', 'Lost', 'Drop'],
          rules: {required: true},
        },
        {
          type: 'number',
          visible: ({values}) =>
            values && values.status && values.status === 'Won',
          field: 'won_resource',
          label: 'Won Resource',
        },
        {
          type: 'date',
          visible: ({values}) =>
            values &&
            values.status &&
            (values.status === 'Won' ||
              values.status === 'Partially Won' ||
              values.status === 'Drop' ||
              values.status === 'Lost'),
          field: 'outcome_date',
          label: 'Outcome Date',
        },
        {
          label: 'Partially Won Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Partially Won',
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
            'Delay in Preparation for Interview',
          ],
        },
        {
          label: 'Drop Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Drop',
          options: [
            'Client Not Responding',
            'Position is On Hold',
            'Non Agreement in Terms & Conditions',
            'Change in Business Model',
            'Candidate Not available-Experience requirement outside limit',
            'Candidate Not available-skillset requirement outside limit',
            'Candidate Rejected-Experience requirement outside limit',
            'Candidate Rejected-skillset requirement outside limit',
          ],
        },
        {
          label: 'Lost Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Lost',
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
            'Delay in Preparation for Interview',
          ],
        },
        {
          type: 'textArea',
          visible: ({values}) =>
            values &&
            values.status &&
            (values.status === 'Partially Won' ||
              values.status === 'Drop' ||
              values.status === 'Lost'),
          field: 'outcome_description_comment',
          label: 'Outcome Description Comment',
        },
      ]}
    />
  );
};

export const MarkEnggOpportunityCloseForm = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'CustomPlatformOpportunity',
    uri: '/opportunities',
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Close'}
      submitAction={'Save'}
      defaultValues={{
        _id: row?._id,
        close_date: new Date(),
      }}
      layoutFields={[
        {
          label: 'Close Type',
          type: 'autoComplete',
          field: 'status',
          options: ['Won', 'Partially Won', 'Lost', 'Drop'],
          rules: {required: true},
        },
        {
          type: 'number',
          visible: ({values}) =>
            values && values.status && values.status === 'Won',
          field: 'won_resource',
          label: 'Won Resource',
        },
        {
          type: 'date',
          visible: ({values}) =>
            values &&
            values.status &&
            (values.status === 'Won' ||
              values.status === 'Partially Won' ||
              values.status === 'Drop' ||
              values.status === 'Lost'),
          field: 'outcome_date',
          label: 'Outcome Date',
        },
        {
          label: 'Partially Won Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Partially Won',
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
            'Delay in Preparation for Interview',
          ],
        },
        {
          label: 'Drop Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Drop',
          options: [
            'Client Not Responding',
            'Position is On Hold',
            'Non Agreement in Terms & Conditions',
            'Change in Business Model',
            'Candidate Not available-Experience requirement outside limit',
            'Candidate Not available-skillset requirement outside limit',
            'Candidate Rejected-Experience requirement outside limit',
            'Candidate Rejected-skillset requirement outside limit',
          ],
        },
        {
          label: 'Lost Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.status && values.status === 'Lost',
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
            'Delay in Preparation for Interview',
          ],
        },
        {
          type: 'textArea',
          visible: ({values}) =>
            values &&
            values.status &&
            (values.status === 'Partially Won' ||
              values.status === 'Drop' ||
              values.status === 'Lost'),
          field: 'outcome_description_comment',
          label: 'Outcome Description Comment',
        },
      ]}
    />
  );
};

export const AssignPreSalesForm = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        props.status = 'Active';
        props.rfq_owner_assign_date = getZeroTimeDate(new Date());
        (props.sub_stage = 'New'), (props.sub_stage_date = new Date());
        onSubmit(props);
      }}
      header={'Assign Pre Sales'}
      submitAction={'Assign'}
      // defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Pre Sales',
          field: 'rfq_owner',
          type: 'multiAutoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
        {
          label: 'Practice',
          field: 'practice',
          type: 'multiAutoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
        },
      ]}
    />
  );
};

export const UpdateSubStage = props => {
  const params = props.route.params;
  const selectedIds = params?.selectedIds;
  let {onSubmit} = useFormSubmit({
    uri: '/opportunities',
    eventSourceId: 'CustomPlatformOpportunity',
    ids: selectedIds,
  });
  return (
    <Form
      onSubmit={props => {
        props.sub_stage_date = new Date();
        onSubmit(props);
      }}
      header={'Select Sub Stage'}
      submitAction={'Update'}
      layoutFields={[
        {
          label: 'Sub Stage',
          type: 'autoComplete',
          field: 'sub_stage',
          options: [
            'Business Requirement Document & Technical Assistance',
            'Scope Freezing and Estimation',
            'Proposal Drafting',
          ],
          rules: {required: true},
        },
      ]}
    />
  );
};
