import React from 'react';
import {CompactAddButton} from '../../../components/button/AddButton';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {AttendanceTabView} from '../../leadGeneration/views/AttendanceTabView';
import AccountTable, {AccountsTabView} from './AccountTable';
import {ActiveElementsTable} from './ActiveElementsTable';
import {CountryTable} from './CountryTable';
import {CurrencyTable} from './CurrencyTable';
import {EmployeeSizeTab} from './EmployeeSizeTab';
import {FinancialYearTable} from './FinancialYearTable';
import {GeographicCategoryTable} from './GeographicCategoryTable';
import {IndustryTab} from './IndustryTab';
import {OrganizationCategoryTable} from './OrganizationCategoryTable';
import {OrganizationTable} from './OrganizationTable';
import {TypesTable} from './TypesTable';
import {DataEntityTaskAssignmentTabs} from './DataEntityTaskAssignmentTabs';
import {ToolTabs} from './ToolTabs';

const menus = [
  // {
  //   label: 'Access Groups',
  //   view: <AccessGroupTable />,
  //   actions: [
  //     <CompactAddButton title={'Access Group'} view={'add-access-group'} />,
  //   ],
  // },
  // {
  //   label: 'User Access',
  //   view: <UserAccessTable />,
  //   actions: [
  //     <CompactAddButton title={'User Access'} view={'add-user-access'} />,
  //   ],
  // },
  // {
  //   label: 'Employee Access',
  //   view: <EmployeeAccessTable />,
  //   actions: [
  //     <CompactAddButton
  //       title={'Employee Access'}
  //       view={'add-employee-access'}
  //     />,
  //   ],
  // },
  // {
  //   label: 'Department',
  //   view: <DepartmentTable />,
  //   api: '/departments',
  //   actions: [
  //     <CompactAddButton title={'Department'} view={'add-department'} />,
  //   ],
  // },

  // {
  //   label: 'Department Input',
  //   view: <DepartmentInputTable />,
  //   api: '/departmentinputs',
  //   actions: [
  //     <CompactAddButton
  //       title={'Department Input'}
  //       view={'add-department-input'}
  //     />,
  //   ],
  // },
  // {
  //   label: 'Department Output',
  //   view: <DepartmentOutputTable />,
  //   api: '/departmentoutputs',
  //   actions: [
  //     <CompactAddButton
  //       title={'Department Output'}
  //       view={'add-department-output'}
  //     />,
  //   ],
  // },
  // {
  //   label: 'Product',
  //   view: <ProductTable />,
  //   api: '/products',
  //   actions: [<CompactAddButton title={'Product'} view={'add-product'} />],
  // },
  // {
  //   label: 'Office',
  //   view: <OfficeTable />,
  //   actions: [<CompactAddButton title={'Office'} view={'add-office'} />],
  // },
  {
    label: 'Organization',
    view: <OrganizationTable />,
    actions: [
      <CompactAddButton title={'Organization'} view={'add-organization'} />,
    ],
  },
  {
    label: 'Organization Category',
    view: <OrganizationCategoryTable />,
    actions: [
      <CompactAddButton
        title={'Organization Category'}
        view={'add-organization-category'}
      />,
    ],
  },
  {
    label: 'Geographic Category',
    view: <GeographicCategoryTable />,
    actions: [
      <CompactAddButton
        title={'Geographic Category'}
        view={'add-geographic-category'}
      />,
    ],
  },

  {
    label: 'Country',
    view: <CountryTable />,
  },
  {
    label: 'Attendance',
    view: <AttendanceTabView />,
  },
  {
    label: 'Financial Year',
    view: <FinancialYearTable />,
    actions: [
      <CompactAddButton title={'Financial Year'} view={'add-financial-year'} />,
    ],
  },
  {
    label: 'Currency',
    view: <CurrencyTable />,
    actions: [<CompactAddButton title={'Currency'} view={'add-currency'} />],
  },
  {
    label: 'Active Elements',
    view: <ActiveElementsTable />,
    actions: [
      <CompactAddButton
        title={'Active Elements'}
        view={'add-active-elements'}
      />,
    ],
  },
  {
    label: 'Data Entity',
    view: <TypesTable />,
    actions: [<CompactAddButton title={'Data Entity'} view={'add-types'} />],
  },
  {
    label: 'Data Entity Assignments',
    view: <DataEntityTaskAssignmentTabs />,
  },
  {
    label: 'Employee Size',
    api: '/EmployeeSizes',
    view: <EmployeeSizeTab />,
    actions: [
      <CompactAddButton title={'Employee Size'} view={'add-employee-size'} />,
    ],
  },
  {
    label: 'Industry',
    api: '/industries',
    view: <IndustryTab />,
    actions: [<CompactAddButton title={'Industry'} view={'add-industry'} />],
  },
  {
    label: 'Tool',
    api: '/tools',
    view: <ToolTabs />,
    actions: [<CompactAddButton title={'Industry'} view={'add-tools'} />],
  },
  // {
  //   label: 'Customers',
  //   view: <CustomerTableTabView />,
  // },
  // {
  //   label: 'Skill Matrix',
  //   view: <SkillMetricsTable />,
  // },
  // {
  //   label: 'Employees',
  //   view: <EmployeeList />,
  // },
  // {
  //   label: 'Account',
  //   api: '/accounts',
  //   view: <AccountsTabView />,
  //   actions: [<CompactAddButton title={'Accounts'} view={'create-account'} />],
  // },
];

const SetupMenu = props => {
  const {route: {params} = {}} = props;
  return <LeftMenuView {...props} params={params} menus={menus} />;
};

export default SetupMenu;
