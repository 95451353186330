import {useTheme} from '@unthinkable/react-theme';
import {AddButton} from '../../../components/button/AddButton';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {GroupBy} from '../../../components/table/GroupBy';
import {useFilter} from '../../../controllers/useFilter';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {useAppStateContext} from '../../../providers/AppState';
import {
  CreditNotePendingTable,
  CreditNotePendingTableAm,
  RevenueInvoiceActualTable,
  RevenueInvoiceActualTableAm,
  RevenueInvoiceAdvancedTable,
  RevenueInvoiceAdvancedTableAm,
  RevenueInvoiceAllTable,
  RevenueInvoiceAllTableAm,
} from './RevenueInvoiceTable';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

const filters = ({tab}) => {
  return [
    {
      type: 'multiAutoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'date',
      label: 'Invoice Date',
      field: 'invoice_date',
    },
    {
      type: 'multiAutoComplete',
      label: 'Account Manager',
      api: '/employeeSuggestions',
      searchFields: ['name', 'official_email_id', 'employee_code'],
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'account_owner_id',
    },
    ...(tab == 'All'
      ? [
          {
            type: 'autoComplete',
            label: 'Invoice type',
            placeholder: 'Select',
            options: [
              {label: 'Actual', value: 'i'},
              {label: 'Advance', value: 'a'},
              {label: 'Credit', value: 'c'},
              {label: 'Debit', value: 'd'},
            ],
            keyField: 'value',
            suggestionField: 'label',
            valueField: 'label',
            field: 'invoice_type',
          },
        ]
      : []),
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'organization',
    },
    {
      type: 'autoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Locations',
      api: '/offices',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'location',
    },
    {
      type: 'autoComplete',
      label: 'Sitting Location',
      api: '/offices',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'sitting_location',
    },
    {
      type: 'autoComplete',
      label: 'Status',
      placeholder: 'Select',
      options: ['New', 'Fully Invoiced', 'Partially Invoiced'],
      field: 'status',
    },
    {
      type: 'autoComplete',
      label: 'Type',
      placeholder: 'Select',
      options: [
        {label: 'Fixed', value: 'ot'},
        {label: 'T&M', value: 'r'},
      ],
      keyField: 'value',
      suggestionField: 'label',
      valueField: 'label',
      field: 'pricing_type',
    },
    {
      type: 'autoComplete',
      label: 'Invoice Category',
      placeholder: 'Select',
      options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
      field: 'invoice_category',
    },
  ];
};

const commonFilters = ({
  api,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'All',
  params = {},
  download,
  Download,
  filter,
  groupBy = {},
  showGroupBy = false,
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search Invoice Number"
    />,
    showGroupBy && <GroupBy {...groupBy} />,
    <IconButton
      icon={Download}
      onPress={() => {
        download({
          uri: '/downloadInvoices',
          props: {
            uri: api,
            parameters: {
              params,
              addOnFilter: {
                ...filter,
              },
            },
          },
        });
      }}
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    <AddButton
      title="Add Revenue Invoice"
      view="create-revenue-invoice"
      params={params}
    />,
  ];
};

export const RevenueInvoiceTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const tabs = {
    revenue_invoice_all: {
      label: 'All',
      view: (
        <RevenueInvoiceAllTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/all`,
      addOnFilter: {...filter},
      actions: commonFilters({
        api: `/revenueInvoices/all`,
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
        download,
        Download,
        filter,
      }),
    },

    revenue_invoice_advance: {
      label: 'Advance',
      view: (
        <RevenueInvoiceAdvancedTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/advance`,
      addOnFilter: {...filter},
      actions: commonFilters({
        api: `/revenueInvoices/advance`,
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Advance',
        params,
        download,
        Download,
        filter,
      }),
    },

    revenue_invoice_actual: {
      label: 'Actual',
      view: (
        <RevenueInvoiceActualTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/actual`,
      addOnFilter: {...filter},
      actions: commonFilters({
        api: `/revenueInvoices/actual`,
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Actual',
        params,
        download,
        Download,
        filter,
      }),
    },

    Credit: {
      label: 'Credit Note',
      view: (
        <CreditNotePendingTable
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
        />
      ),
      api: `/revenueInvoices/all`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {...filter, invoice_type: 'c'},
      actions: [
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/downloadInvoices',
              props: {
                uri: '/revenueInvoices/all',
                parameters: {
                  params,
                  addOnFilter: {...filter, invoice_type: 'c'},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const RevenueInvoiceTabAm = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const groupBy = useGroupBy({
    options: [
      // {
      //   label: 'AM',
      //   field: 'account_owner_id',
      //   groupRow: {
      //     title: 'name',
      //     data: '_children',
      //     defaultExpanded: true,
      //   },
      // },
      {
        label: 'Customer',
        field: 'customer',
        aggregates: {
          base_invoice_amount: 'sum',
        },
        groupRow: {
          // title: 'name',
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem value={row?.name} />
                <GroupContentItem
                  value={row?.base_invoice_amount?.toFixed(2)}
                />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const tabs = {
    revenue_invoice_all: {
      label: 'All',
      view: (
        <RevenueInvoiceAllTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/all`,
      addOnFilter: {
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },

    revenue_invoice_actual: {
      label: 'Actual',
      view: (
        <RevenueInvoiceActualTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/actual`,
      addOnFilter: {
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Actual',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },
    Credit: {
      label: 'Credit Note',
      view: (
        <CreditNotePendingTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      api: `/revenueInvoices/all`,
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        ...filter,
        invoice_type: 'c',
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
    },
    revenue_invoice_advance: {
      label: 'Advance',
      view: (
        <RevenueInvoiceAdvancedTableAm
          {...props}
          searchValue={searchValue}
          addOnFilter={filter}
          groupBy={groupBy}
        />
      ),
      eventSourceId: 'revenue-invoice',
      api: `/revenueInvoices/advance`,
      addOnFilter: {
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...filter,
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Advance',
        params,
        groupBy,
        showGroupBy: true,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
