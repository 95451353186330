import React from 'react';
import {
  BorderedGroupTable,
  GroupTable,
  Table,
} from '../../../components/table/Table';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {Chip, Tag} from '../../../components/chip/Chip';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';
import {TableHeader} from '../../../components/table/Headers';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {
  CardItem,
  PrimaryColumnCard,
} from '../../../components/card/PrimaryColumnCard';
import {customDigitalPlatformOppControllers} from '../../leadGeneration/controllers/customDigitalPlatformControllers';
import {objectTasksMoreAction} from '../../common/actions/ObjectWiseTasks';
import {OPPORTUNITY_TYPE_ID} from '../../common/constants/SourceConstants';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useAppStateContext} from '../../../providers/AppState';
import {LastCommunicationRender} from '../../../utils/util';

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  if (
    row?.status &&
    (row?.status == 'Drop' ||
      row?.status == 'Lost' ||
      row?.status == 'Partially Won')
  ) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'invalidRfq') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          Drop
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.status && row?.status == 'Won') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            overflow: 'hidden',
          }}>
          {row?.status}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else if (row?.is_drop) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'Red',
            overflow: 'hidden',
          }}>
          {'Drop'}
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.outcome_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RenderType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  if (
    row?.opportunity_type &&
    row?.opportunity_type == 'CustomPlatformOpportunity'
  ) {
    return (
      <>
        <Tag value={'CP'} color={'#FCF4D4'} />
      </>
    );
  } else if (
    row?.opportunity_type &&
    row?.opportunity_type == 'StaffAugmentationOpportunity'
  ) {
    return (
      <>
        <Tag value={'SA'} color={'#DAFDFE'} />
      </>
    );
  } else {
    return 0;
  }
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'next_task', type: 'text'},
        {
          field: 'next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const RenderCloseDate = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.creation_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.creation_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Open for: {row.days_difference} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const CustomerRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');

  if (row?.rfr_type == 'PotentailCustomer') {
    const underline = row?.potential_customer?.website
      ? {textDecoration: 'underline'}
      : void 0;
    return (
      <>
        <Col>
          <TouchableOpacity
            onPress={() => {
              row?.potential_customer?.website &&
                Linking.openURL(
                  row?.potential_customer?.website.startsWith('https')
                    ? row?.potential_customer?.website
                    : `https://${row?.potential_customer?.website}`,
                  '_blank',
                );
            }}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                ...underline,
              }}>
              {row.potential_customer?.name}
            </Text>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              {row.industry?.name}
            </Text>
          </TouchableOpacity>
        </Col>
      </>
    );
  } else if (row?.rfr_type == 'Customer') {
    const underline = row?.customer_id?.website
      ? {textDecoration: 'underline'}
      : void 0;
    return (
      <>
        <Col>
          <TouchableOpacity
            onPress={() => {
              row?.customer_id?.website &&
                Linking.openURL(
                  row?.customer_id?.website.startsWith('https')
                    ? row?.customer_id?.website
                    : `https://${row?.customer_id?.website}`,
                  '_blank',
                );
            }}>
            <Text
              numberOfLines={1}
              style={{
                ...rowText,
                ...underline,
              }}>
              {row.customer_id?.name}
            </Text>
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_LOW,
              }}>
              {row.industry?.name}
            </Text>
          </TouchableOpacity>
        </Col>
      </>
    );
  }
};

const CompanyRender = props => {
  const {row} = props;
  const {CustomerOpportunity, CustomerNew, ProspectIcon} = useTheme('icons');

  if (row?.potential_customer) {
    return (
      <PrimaryColumnCard
        gap={6}
        primaryTitle={row?.potential_customer?.name}
        renderIcon={() => {
          return (
            <Row gap={6}>
              <Tag
                color={'SURFACE2'}
                textColor={'NEUTRAL_LOW'}
                value={row.potential_customer?.lead_score}
              />
              <Image source={ProspectIcon} />
            </Row>
          );
        }}
        variant="secondary"
        items={[
          {
            value: row?.potential_customer?.employee_count?.number,
            backgroundColor: row?.potential_customer?.employee_count?.color,
            numberOfLines: 1,
          },
          {
            value: row?.potential_customer?.country?.name,
            backgroundColor: row?.potential_customer?.country?.color,
            numberOfLines: 1,
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  } else if (row?.customer_id) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.customer_id?.name}
        // icon={CustomerNew}
        renderIcon={() => {
          return (
            <Row gap={6}>
              <Tag
                color={'SURFACE2'}
                textColor={'NEUTRAL_LOW'}
                value={row.customer_id?.lead_score}
              />
              <Image source={CustomerNew} />
            </Row>
          );
        }}
        variant="secondary"
        items={[
          {
            value: row?.customer_id?.employee_count?.number,
            backgroundColor: row?.customer_id?.employee_count?.color,
            numberOfLines: 1,
            // onPress: () => {
            //   console.log(':::::::::::checking');
            // },
          },
          {
            value: row?.customer_id?.country?.name,
            backgroundColor: row?.customer_id?.country?.color,
            numberOfLines: 1,
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const leadScoreRender = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.rfr_type == 'PotentailCustomer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.potential_customer?.lead_score}
        </Text>
      </>
    );
  } else if (row?.rfr_type == 'Customer') {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_LOW,
          }}>
          {row.customer_id?.lead_score}
        </Text>
      </>
    );
  }
};

const EmployeeCountRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'potential_customer.employee_count.number',
          type: 'colorTag',
          titleField: 'potential_customer.employee_count.number',
          colorField: 'potential_customer.employee_count.color',
        },
        {
          field: 'customer_id.employee_count.number',
          titleField: 'customer_id.employee_count.number',
          colorField: 'customer_id.employee_count.color',
          type: 'colorTag',
        },
      ]}
    />
  );
};

const CountryRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {
          field: 'potential_customer.country.name',
          titleField: 'potential_customer.country.name',
          colorField: 'potential_customer.country.color',

          type: 'colorTag',
        },

        {
          titleField: 'customer_id.country.name',
          colorField: 'customer_id.country.color',
          field: 'customer_id.country.name',
          type: 'colorTag',
        },
      ]}
    />
  );
};

const CUSTOMER = {
  header: 'Organisation',
  render: CompanyRender,
  align: 'left',
  width: 300,
};

const CHANNEL = {
  header: 'Channel',
  field: 'campaign_type.label',
  colorField: 'campaign_type.color',
  type: 'colorTag',
  width: 100,
};

const LEADSCORE = {
  header: 'Opportunity',
  render: CustomerRender,
  align: 'left',
};

const LostReasonRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'outcome_description_comment', type: 'colorTag'},
        // {
        //   field: 'outcome_date',
        //   type: 'date',
        // },
      ]}
    />
  );
};

const renderRequirements = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  if (row && row?.opportunity_type == 'CustomPlatformOpportunity') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 14,
        }}
        title={row?.requirement}>
        {row?.requirement}
      </Text>
    );
  }
  if (row && row?.opportunity_type == 'StaffAugmentationOpportunity') {
    const skillName = row?.skill
      ?.map(element => {
        return element?.name;
      })
      .join(', ');
    if (!skillName) return null;
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 14,
        }}
        title={row?.requirement}>
        {`${skillName} (${row?.no_of_resources} candidates)`}
      </Text>
    );
  }
};

export const AllOpportunitiesCombinedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);
  const {download} = useAppStateContext();
  const fetchProps = {
    params: params,
    api: '/opportunities/all-active-opportunities',
  };
  return (
    <Table
      {...props}
      {...fetchProps}
      // params={params}

      // api="/opportunities/all-active-opportunities"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      // onRowPress={row => {
      //   navigation.navigate('edit-customplatformopportunity', row);
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    ...props,
                    fetchProps,
                    column: {
                      Date: 'creation_date',
                      Sales: 'owner.name',
                      Type: 'opportunity_type',
                    },
                  },
                },
              }),
          },
        ],
      }}
      searchFields="requirement"
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        CHANNEL,

        {
          header: 'Opp. Type',
          render: RenderType,
          width: 100,
        },
        {
          header: 'Requirement',
          render: renderRequirements,
          minWidth: 250,
        },
        {
          field: 'owner',
          width: 68,
          header: 'Sales',
          type: 'userAvatar',
        },

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const AllLostOpportunitiesCombinedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  return (
    <GroupTable
      {...props}
      params={params}
      api="/opportunities/all-closed-opportunities"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      limit={5000}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} closed`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Open Date',
          render: RenderCloseDate,
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        CHANNEL,

        {
          header: 'Opp. Type',
          render: RenderType,
          width: 100,
        },
        {
          header: 'Requirement',
          render: renderRequirements,
          minWidth: 250,
        },
        {
          field: 'owner',
          width: 68,
          header: 'Sales',
          type: 'userAvatar',
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 110,
        },
        {header: 'Lost Reason', render: LostReasonRender, width: 120},
        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        // {
        //   header: 'Next Task',
        //   render: NextTaskRender,
        //   width: 180,
        // },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};

export const AllNewOpportunitiesCombinedList = props => {
  let {
    route: {params},
    filterParams,
    navigation,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  const {customPlatformOpportunityCommunications} =
    customDigitalPlatformOppControllers(props);

  return (
    <GroupTable
      {...props}
      params={params}
      api="/opportunities/all-new-opportunities"
      eventSourceId={['CustomPlatformOpportunity', 'communicationAdded']}
      searchFields="requirement"
      limit={5000}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} created`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Open Since',
          render: RenderCloseDate,
          align: 'center',
          width: 112,
        },
        {...CUSTOMER},
        CHANNEL,

        {
          header: 'Opp. Type',
          render: RenderType,
          width: 100,
        },
        {
          header: 'Requirement',
          render: renderRequirements,
          minWidth: 250,
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 110,
        },
        {
          field: 'owner',
          width: 68,
          header: 'Sales',
          type: 'userAvatar',
        },

        {
          header: 'Communication',
          onPress: ({row}) => {
            customPlatformOpportunityCommunications({row});
          },
          render: LastCommunicationRender,
          width: 132,
        },
        {
          header: 'Next Task',
          render: NextTaskRender,
          width: 180,
        },
      ]}
      moreActions={[
        objectTasksMoreAction({
          navigation,
          params,
          titleField: 'requirement',
          source: OPPORTUNITY_TYPE_ID,
        }),
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            row = {
              ...row,
              api: `/opportunities`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
      ]}
    />
  );
};
