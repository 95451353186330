import {getCurrentMonth, getPreviousMonth} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import ProjectWiseAllocations from './ProjectWiseAllocations';

export const ProjectWiseAllocationTab = props => {
  let {route: {params} = {}} = props;
  const {department} = params;
  if (!params.period) {
    params.period = getCurrentMonth();
  }
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: {
      department,
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};
  let filterParams = {...filter};

  const tabs = {
    active_poject_wise_allocation: {
      label: 'Active',
      view: (
        <ProjectWiseAllocations
          searchValue={searchValue}
          filterParams={{...filterParams, project_status: 'a'}}
          period={period}
          {...props}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/projectAllocations',
      addOnFilter: {...filterParams, project_status: 'a'},
      limit: 2000,
    },
    // completed_poject_wise_allocation: {
    //   label: 'Completed',
    //   view: (
    //     <ProjectWiseAllocations
    //       searchValue={searchValue}
    //       filterParams={{...filterParams, project_status: 'c'}}
    //       period={period}
    //       {...props}
    //     />
    //   ),
    //   eventSourceId: 'allocatedResource',
    //   api: '/projectAllocations',
    //   addOnFilter: {...filterParams, project_status: 'c'},
    //   limit: 2000,
    // },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
          ]}
        />,
      ]}
    />
  );
};
