import React from 'react';
import {NonEnggTeams} from '../employee/views/NonEnggTeams';
import DepartmentTable from '../organizationTask/views/DepartmentTable';
import KeyPerformanceIndicatorTable from '../organizationTask/views/KeyPerformanceIndicatorTable';
import KeyResponsibilityAreaTable from '../organizationTask/views/KeyResponsibilityAreaTable';
import KpiApiTable from '../organizationTask/views/KpiApiTables';
import KPIPermissionTable from '../organizationTask/views/KPIPermissionTable';
import KPITargetTable from '../organizationTask/views/KPITargetTable';
import MyKPITable from '../organizationTask/views/MyKPITable';
import {featureMenus, featureScreens} from '../pmt/ProjectManagementNavigation';
import {ArchivedOrganizationTaskTable} from '../task/views/ArchivedOrganizationTaskTable';
import {BacklogOrganizationTaskTable} from '../task/views/BacklogOrganizationTaskTable';
import {
  MyEfficiencyTable,
  MyTeamEfficiencyTable,
} from '../task/views/EfficiencyTable';
import {OrganizationTaskReviewTab} from '../task/views/MyOrganizationTaskMenus';
import {
  ProjectMyActiveTasksTable,
  ProjectMyCompletedTasksTable,
  ProjectTaskReviewTab,
} from '../task/views/MyProjectTaskMenus';
import {
  OrganizationActiveTasksGroupByPlan,
  OrganizationCompletedTasks,
} from '../task/views/OrganizationActiveTasksGroupByPlan';
import {
  FavoritesTaskTable,
  OpenTasksTable,
  TeamTaskAllTable,
} from '../task/views/TasksTable';
import {
  LeadsTabsForFollowUp,
  OpportunityLeadsTabsForFollowUp,
  QualifiedLeadsTabsForFollowUp,
} from '../leadGeneration/views/LeadTableTabView';
import {ProspectsTabForFollowUp} from '../leadGeneration/views/ProspectTableTabView';
import {DelegateThreadTabs, ThreadTabs} from '../task/views/MyThreadTable';
import { DelegatedTasksTabs } from '../task/views/DelegatedTasksTabs';
import { MyCustomTaskTabs, MyFavouriteTaskTabs, MyProjectTaskTabs } from '../task/views/MyTaskTabs';

export const ScreenMapping = [
  {
    id: 'todaysfollowupprospect',
    view: props => {
      return <ProspectsTabForFollowUp path="task" {...props} />;
    },
  },
  {
    id: 'todaysfollowuplead',
    view: props => {
      return <LeadsTabsForFollowUp path="task" {...props} />;
    },
  },
  {
    id: 'todaysfollowupqualifiedlead',
    view: props => {
      return <QualifiedLeadsTabsForFollowUp path="task" {...props} />;
    },
  },
  {
    id: 'todaysfollowopportunitylead',
    view: props => {
      return <OpportunityLeadsTabsForFollowUp path="task" {...props} />;
    },
  },
  {
    id: 'departmentalltask',
    view: props => {
      return (
        <NonEnggTeams key="task" departmentTitle="Department Wise" {...props} />
      );
    },
  },
  {
    id: 'teamtask',
    view: props => {
      return (
        <NonEnggTeams
          key="task"
          departmentTitle="Team Wise"
          departmentTasks={true}
          fromMyView={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'taskactive',
    view: props => <ProjectMyActiveTasksTable {...props} />,
  },
  {
    id: 'taskcompleted',
    view: props => <ProjectMyCompletedTasksTable {...props} />,
  },
  {
    id: 'myProjectTaskTab',
    view: props => <MyProjectTaskTabs {...props} />,
  },
  {
    id: 'myCustomTaskTab',
    view: props => <MyCustomTaskTabs {...props} />,
  },
  {
    id: 'myFavouriteTaskTab',
    view: props => <MyFavouriteTaskTabs {...props} />,
  },
  {
    id: 'taskreview',
    view: props => <ProjectTaskReviewTab {...props} />,
  },
  {
    id: 'taskopen',
    label: 'Open',
    view: props => <OpenTasksTable {...props} />,
  },
  {
    id: 'taskbacklog',
    label: 'Backlog',
    view: props => <BacklogOrganizationTaskTable {...props} />,
  },
  {
    id: 'taskactiveOrganization',
    view: props => <OrganizationActiveTasksGroupByPlan {...props} />,
  },
  {
    id: 'taskCompletedOrganization',
    view: props => <OrganizationCompletedTasks {...props} />,
  },
  {
    id: 'taskfavorites',
    view: props => <FavoritesTaskTable {...props} />,
  },
  {
    id: 'taskreviews',
    view: props => <OrganizationTaskReviewTab {...props} />,
  },
  {
    id: 'taskarchived',
    view: props => <ArchivedOrganizationTaskTable {...props} />,
  },
  {
    id: 'output',
    view: ({user}) => {
      return <MyEfficiencyTable user={user} fromMyView={true} />;
    },
  },
  {
    id: 'teamtaskTable',
    view: props => {
      return <TeamTaskAllTable {...props} />;
    },
  },
  {
    id: 'teamoutput',
    view: ({user}) => {
      return <MyTeamEfficiencyTable user={user} showOnlyDisqualified={false} />;
    },
  },
  {
    id: 'deptperformance',
    view: props => {
      return <NonEnggTeams departmentTitle="Department Wise" {...props} />;
    },
  },
  {
    id: 'mykpi',
    view: <MyKPITable />,
  },
  {
    id: 'alldepartments',
    view: <DepartmentTable />,
  },
  {
    id: 'kra',
    view: <KeyResponsibilityAreaTable />,
  },
  {
    id: 'kpi',
    view: <KeyPerformanceIndicatorTable />,
  },
  {
    id: 'kpiapis',
    view: <KpiApiTable />,
  },
  {
    id: 'kpitargets',
    view: <KPITargetTable />,
  },
  {
    id: 'kpipermissions',
    view: <KPIPermissionTable />,
  },
  {
    id: 'myThreads',
    view: ({user}) => {
      return <ThreadTabs user={user} />;
    },
  },
  {
    id: 'delegateThreads',
    view: ({user}) => {
      return <DelegateThreadTabs user={user} />;
    },
  },
  {
    id: 'delegateTasks',
    view: <DelegatedTasksTabs />
  },
];

export const addOnMenus = {
  taskprojects: {
    menuItems: featureMenus,
  },
};

export const addOnScreens = featureScreens;

// export const Screens = [
//   {
//     menuItemId: 'departmenttasks',
//     views: [
//       {
//         id: 'departmentalltask',
//         label: 'All',
//         view: props => {
//           return (
//             <NonEnggTeams
//               key="task"
//               departmentTitle="Department Wise"
//               {...props}
//             />
//           );
//         },
//       },
//       {
//         id: 'teamtask',
//         label: 'Team',
//         view: props => {
//           return <NonEnggTeams key="task" fromMyView={true} {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'taskprojects',
//     views: [
//       {
//         id: 'taskactive',
//         label: 'Active',
//         view: props => <ProjectMyActiveTasksTable {...props} />,
//       },
//       {
//         id: 'taskreview',
//         label: 'Reviews',
//         view: props => <ProjectTaskReviewTab {...props} />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'taskcustoms',
//     views: [
//       {
//         id: 'taskopen',
//         label: 'Open',
//         view: props => <OpenTasksTable {...props} />,
//       },
//       {
//         id: 'taskbacklog',
//         label: 'Backlog',
//         view: props => <BacklogOrganizationTaskTable {...props} />,
//       },
//       {
//         id: 'taskactiveOrganization',
//         label: 'Active',
//         view: props => <OrganizationActiveTasksGroupByPlan {...props} />,
//       },
//       {
//         id: 'taskfavorites',
//         label: 'Favorites',
//         view: props => <FavoritesTaskTable {...props} />,
//       },
//       {
//         id: 'taskreviews',
//         label: 'Reviews',
//         view: props => <OrganizationTaskReviewTab {...props} />,
//       },
//       {
//         id: 'taskarchived',
//         label: 'Archived',
//         view: props => <ArchivedOrganizationTaskTable {...props} />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'outputs',
//     views: [
//       {
//         id: 'output',
//         label: 'Output',
//         view: ({user}) => {
//           return <MyEfficiencyTable user={user} fromMyView={true} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'teamtasks',
//     views: [
//       {
//         id: 'teamtaskTable',
//         label: 'Task',
//         view: props => {
//           return <TeamTasksTable {...props} />;
//         },
//       },
//       {
//         id: 'teamoutput',
//         label: 'Output',
//         view: ({user}) => {
//           return (
//             <MyTeamEfficiencyTable user={user} showOnlyDisqualified={false} />
//           );
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'deptperformances',
//     views: [
//       {
//         id: 'deptperformance',
//         label: 'Dept. Performance',
//         view: props => {
//           return <NonEnggTeams departmentTitle="Department Wise" {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'mykpi',
//     views: [{id: 'mykpi', label: 'My KPIs', view: <MyKPITable />}],
//   },
//   {
//     menuItemId: 'alldepartments',
//     views: [
//       {id: 'alldepartments', label: 'Departments', view: <DepartmentTable />},
//     ],
//   },
//   {
//     menuItemId: 'kra',
//     views: [
//       {
//         id: 'kra',
//         label: 'KRA',
//         view: <KeyResponsibilityAreaTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'kpi',
//     views: [
//       {
//         id: 'kpi',
//         label: 'KPI',
//         view: <KeyPerformanceIndicatorTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'kpiapis',
//     views: [
//       {
//         id: 'kpiapis',
//         label: 'APIs',
//         view: <KpiApiTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'kpitargets',
//     views: [
//       {
//         id: 'kpitargets',
//         label: 'KPI Targets',
//         view: <KPITargetTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'kpipermissions',
//     views: [
//       {
//         id: 'kpipermissions',
//         label: 'KPI Permission',
//         view: <KPIPermissionTable />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'departmenttasks',
//     label: 'Department Performance',

//     showSingleView: true,
//   },
//   {
//     id: 'teamtasks',
//     label: 'Team Task',
//   },
//   {
//     id: 'mytask',
//     label: 'My Task',
//     section: true,
//     menus: [
//       {
//         id: 'taskprojects',
//         label: 'Project Task',
//         menuItems: featureMenus,
//       },
//       {
//         id: 'taskcustoms',
//         label: 'Custom Task',
//       },
//       {
//         id: 'outputs',
//         label: 'Output',
//       },
//     ],
//   },

//   {
//     id: 'organizationtasksetup',
//     label: 'Setup',
//     section: true,
//     menus: [
//       {
//         id: 'mykpi',
//         label: 'My KPIs',
//       },
//       {
//         id: 'alldepartments',
//         label: 'Departments',
//       },
//       {
//         id: 'kra',
//         label: 'KRA',
//       },
//       {
//         id: 'kpi',
//         label: 'KPI',
//       },
//       {
//         id: 'kpitargets',
//         label: 'KPI Targets',
//       },
//       {
//         id: 'kpipermissions',
//         label: 'KPI Permission',
//       },
//       {
//         id: 'kpiapis',
//         label: 'KPIs APIs',
//       },
//     ],
//   },
//   // {
//   //   id: 'deptperformances',
//   //   label: 'Dept. Performance',
//   //   visible: isAccessible,
//   // },
// ];
