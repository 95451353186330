import React from 'react';

import {Table} from '../../../components/table/Table';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';

export const ClientUserTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      search={searchValue}
      searchFields={['name', 'email']}
      eventSourceId={['ClientUser']}
      api={`/clientUsers`}
      columns={[
        {
          header: 'Name',
          field: 'name',
          render: ({row}) => {
            return (
              <UserAvatarChip value={{color: row?.color, name: row?.name}} />
            );
          },
          minWidth: 300,
        },
        {
          header: 'Email',
          field: 'email',
        },
      ]}
      moreActions={[
        {
          title: 'Edit User',
          onPress: ({row}) => {
            navigation.navigate(`client-edit-user`, {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const AllUserTable = props => {
  const {
    navigation,
    route: {params},
    searchValue,
  } = props;
  return (
    <Table
      {...props}
      search={searchValue}
      searchFields={['name', 'email']}
      eventSourceId={['AllUser']}
      api={`/users`}
      columns={[
        {
          header: 'Name',
          field: 'name',
          render: ({row}) => {
            return (
              <UserAvatarChip value={{color: row?.color, name: row?.name}} />
            );
          },
          minWidth: 300,
        },
        {
          header: 'Email',
          field: 'email',
        },
        {
          type: 'text',
          field: 'userType',
          header: 'User type',
        },
      ]}
      moreActions={[
        {
          title: 'Edit User',
          onPress: ({row}) => {
            navigation.navigate(`update-all-user`, {
              row,
            });
          },
        },
      ]}
    />
  );
};
