import React from 'react';
import {useAccentColor} from '../../controllers/useAccentColor';
import {Avatar, AvatarGroup} from './Avatar';
import {Avatar as AvatarComponent} from '@unthinkable/react-avatar';
import {AvatarChip} from '../chip/Chip';
import {Wrap} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {AvatarChipStyles} from '../chip/theme';

export const UserAvatar = ({value, size = 24, ...props}) => {
  if (!value) {
    return null;
  }
  const {name, email, color} = value;
  const {HIGH, MEDIUM} = useAccentColor(color);
  return (
    <Avatar
      {...props}
      value={name || email}
      color={HIGH}
      borderColor={MEDIUM}
      size={size}
    />
  );
};

export const UserAvatarGroup = ({value, size = 24, ...props}) => {
  const colors = useTheme('colors');
  if (!value || !Array.isArray(value)) {
    return null;
  }
  value = value.map((_value, index) => {
    return <UserAvatar value={_value} key={index} size={size} />;
  });
  return (
    <AvatarGroup
      avatarSize={size}
      avatars={value}
      avatar={{
        color: colors.ACCENT1_HIGH,
        borderColor: colors.ACCENT1_MEDIUM,
      }}
      {...props}
    />
  );
};

export const UserAvatarChip = ({value, size = 20, ...props}) => {
  if (!value) {
    return null;
  }
  const {name, email, color} = value;
  const {HIGH, MEDIUM, LOW} = useAccentColor(color);

  const {avatar} = useStyles(AvatarChipStyles);

  return (
    <AvatarChip
      value={name || email}
      avatar={
        <AvatarComponent
          styles={avatar}
          value={name || email}
          color={HIGH}
          borderColor={LOW}
          size={size}
        />
      }
      backgroundColor={MEDIUM}
      {...props}
    />
  );
};

export const UserAvatarGroupChip = ({value, ...props}) => {
  if (!value || !Array.isArray(value)) {
    return null;
  }
  return (
    <Wrap gap={8}>
      {value.map(_value => {
        return <UserAvatarChip value={_value} {...props} />;
      })}
    </Wrap>
  );
};
