import { Linking, Text, TouchableOpacity } from '@unthinkable/react-core-components';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';


const form16Doc = resourceUrl => ({
    header: 'File',
    align: 'center',
    render: ({ row }) => {
        const colors = useTheme('colors');
        if (row?.document && row?.document != null) {
            return (
                <TouchableOpacity
                    onPress={() => {
                        const url = resourceUrl(row?.document);
                        Linking.openURL(url, '_blank');
                    }}>
                        <Text style={{fontSize: 13, color: colors.NEUTRAL_LOW}}>{row.document.filename} </Text>
                </TouchableOpacity>
            );
        }
    },
});

const EmployeeForm16Table = props => {
    const { filterParams, params } = props;
    const { resourceUrl, download } = useAppStateContext();
    return (
        <Table
            {...props}
            api={'/getemployeeform16'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="form16"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'employee_id.name',
                    header: 'Employee',
                },
                form16Doc(resourceUrl),
            ]}
        />
    );
};

export const EmployeeForm16Tab = props => {
    let { navigation, route: { params } = {} } = props;
    const { filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Employee Form16',
            view: (
                <EmployeeForm16Table filterParams={filter} {...props} />
            ),
            eventSourceId: 'form16',
            addOnFilter: filter,
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'autoComplete',
                            label: 'Employee',
                            api: '/employeeSuggestions',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'employee_id',
                            searchFields: ['name', 'official_email_id', 'employee_code'],
                        },
                        {
                            type: 'autoComplete',
                            label: 'Financial Year',
                            api: '/FinancialYears',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'financial_year',
                        },
                    ]}
                />,
            ]}
        />
    );
};


export const EmployeeSelfForm16Table = props => {
    const { filterParams, params } = props;
    const { resourceUrl, download } = useAppStateContext();
    return (
        <Table
            {...props}
            api={'/getemployeeform16/self'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="form16"
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'employee_id.name',
                    header: 'Employee',
                },
                form16Doc(resourceUrl),
            ]}
        />
    );
};