import React, {useState} from 'react';
import {
  PROJECT_MODEL_IMPORT_SAMPLE_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {BaseForm, InlineForm} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  ToggleGraphView,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {DataEntityTable, dataEntityExportParams} from './DataEntityScreen';
import {DataEntityDetailForm} from './DataEntityForm';
import {useFetchData} from '../../../../controllers/useFetchData';
import {SplitScreen} from '../../../../components/SplitScreen';
import {TabView} from '../../../../components/tab';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {SearchFilter} from '../../../../components/filter/Filters';
import {AISuggestionsButton} from '../../cellrenders/AISuggestionRenders';
import {
  AIDataEntityGraphView,
  DataEntityGraphView,
} from '../../../../components/graphflow/DataEntityGraphView';
import {Table} from '../../../../components/table/Table';
import {GraphHeader} from '../../headers/Header';
import {
  GraphExportActions,
  GraphMoreActions,
} from '../../../../app-components/renders/GraphActions';
import {View} from '@unthinkable/react-core-components';
import {GroupBy} from '../../../../components/table/GroupBy';

const DataEntitiesInlineForm = props => {
  const {
    route: {params = {}},
    navigation,
    sourceIds = [],
    Component = InlineForm,
    ...rest
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Model',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params;

  const defaultValues = {
    source: PROJECT_MODEL_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <Component
      {...rest}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Model',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
          suggestionField: 'model',
          valueField: 'model',
          searchFields: ['model'],
          required: true,
          filter: {_id: {$nin: sourceIds}},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-model`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

const EVENT_SOURCE_ID = [
  'Feature',
  'Task',
  'Model',
  'DataService',
  'Controller',
  'DataImport',
  'ProjectAI',
  'MapUserstory',
  'ProjectModelView',
];

const FeaturesDataEntitiesTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    fetchParams = {},
    selectedId,
  } = props;

  const {feature} = params;

  const {
    assetDetail,
    tasks,
    removeFromFeature,
    generateCode,
    versions,
    changeModule,
    changeDatabase,
    updateModuleMultipleAssets,
    updateDatabaseMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_MODEL_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/projectModels',
    source: PROJECT_MODEL_TYPE_ID,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_MODEL_TYPE_ID}
          assetInlineForm={<DataEntitiesInlineForm {...props} />}
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={dataEntityExportParams({
            ...fetchParams,
            fromFeature: true,
          })}
          {...headerProps}
          importSampleId={PROJECT_MODEL_IMPORT_SAMPLE_ID}
          navigation={navigation}
          route={props.route}
        />
      )}
      selection={{
        actions: [updateDatabaseMultipleAssets, updateModuleMultipleAssets],
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'model',
          colorField: 'color',
          header: 'Model',
        },
        !selectedId && assetColumns.userstory_count,
        assetColumns.api_count,
        assetColumns.data_service_count,
        assetColumns.model_view_count,
        !selectedId && assetColumns.tasks({onPress: tasks}),
        !selectedId && assetColumns.est_hrs,
        !selectedId && assetColumns.assignees,
        !selectedId && assetColumns.code_editor,
      ]}
      onRowPress={assetDetail}
      moreActions={[
        generateCode,
        versions,
        changeModule,
        changeDatabase,
        removeFromFeature,
      ]}
      {...fetchParams}
    />
  );
};

const FeatureDataEntitiesGraph = props => {
  const {
    navigation,
    route: {params},
    fetchParams,
  } = props;

  const {feature} = params;

  const {data = [], loading} = useFetchData({
    api: `/features/${feature?._id}/asset-model-graph-view`,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_MODEL_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const renderHeader = props => {
    return (
      <FeatureActiveDataEntityGraphHeader
        {...props}
        excelParams={dataEntityExportParams({
          ...fetchParams,
          fromFeature: true,
        })}
        params={params}
      />
    );
  };

  return (
    <DataEntityGraphView
      renderHeader={renderHeader?.(props)}
      onNodePress={({item, readOnly}) => {
        assetDetail({row: item, readOnly});
      }}
      data={data}
      loading={loading}
      {...props}
    />
  );
};

const FeatureActiveDataEntities = props => {
  const {
    route: {params},
    isGraphView,
  } = props;

  const {feature} = params;

  const fetchParams = {
    api: `/features/${feature?._id}/assets/${PROJECT_MODEL_TYPE_ID}/asset-data`,
    eventSourceId: EVENT_SOURCE_ID,
  };

  const DataEntityView = isGraphView
    ? FeatureDataEntitiesGraph
    : FeaturesDataEntitiesTable;

  return (
    <SplitScreen splitScreen={<DataEntityDetailForm {...props} />}>
      <DataEntityView fetchParams={fetchParams} {...props} />
    </SplitScreen>
  );
};

const AllDataEntityGraph = ({renderHeader, ...props}) => {
  const {navigation, route: {params} = {}} = props;
  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_MODEL_TYPE_ID,
  });
  const {groupBy, ...restProps} = props;

  return (
    <>
      <AIDataEntityGraphView
        renderHeader={renderHeader?.(props)}
        fromAll
        onNodePress={({item, readOnly}) => {
          assetDetail({row: item, readOnly});
        }}
        secondaryApiProps={
          params?.feature?._id && {
            api: `/projectModels/ai`,
            params: {
              parent_entity: 'Module',
              parent_entity_id: params?.module?._id,
            },
            eventSourceId: 'DataEntityAi',
          }
        }
        {...restProps}
      />
    </>
  );
};

const FeatureAllDataEntityGraphHeader = ({
  searchValue,
  onChangeFilter,
  params,
  ...props
}) => {
  return (
    <GraphHeader
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
        <GraphExportActions fileName={'Models'} {...props} />,
        <GraphMoreActions {...props} />,
        <AssetAddButton title={'Model'} view={'add-model'} params={params} />,
      ]}
    />
  );
};

const FeatureActiveDataEntityGraphHeader = ({params, ...props}) => {
  return (
    <GraphHeader
      leftActions={[
        <View style={{flex: 1, overflow: 'hidden'}}>
          <DataEntitiesInlineForm
            {...props}
            Component={BaseForm}
            inline
            hideInputError
          />
        </View>,
      ]}
      actions={[
        <GraphExportActions fileName={'Models'} {...props} />,
        <GraphMoreActions {...props} />,
      ]}
    />
  );
};

export const AllDataEntitiesScreen = props => {
  const {
    route: {params},
    isGraphView,
    api,
    groupBy,
  } = props;

  const {project, feature, module} = params;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_MODEL_TYPE_ID].sourceField;

  const tableProps = {
    api:
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_MODEL_TYPE_ID}/all-data`,
    addOnFilter: filterValues.filter,
    search: searchValue,
    searchFields: sourceField,
    filter: {
      module_id: module?._id,
    },
    eventSourceId: EVENT_SOURCE_ID,
    params: {
      show_in_development: !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };
  if (isGraphView) {
    tableProps.limit = 10000;
    tableProps.params = {show_in_development: false};
  }

  const renderHeader = props => {
    if (isGraphView) {
      return (
        <FeatureAllDataEntityGraphHeader
          {...filterProps}
          {...props}
          excelParams={dataEntityExportParams({
            ...tableProps,
            fromFeature: true,
            ids: props?.selectedIds,
          })}
          params={params}
        />
      );
    }
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
          <ExportExcelAction
            selectedIds={props?.selectedIds}
            params={dataEntityExportParams({...tableProps, fromFeature: true})}
          />,
          <AssetAddButton title={'Model'} view={'add-model'} params={params} />,
        ]}
      />
    );
  };
  const DataEntityView = isGraphView ? AllDataEntityGraph : DataEntityTable;
  return (
    <SplitScreen splitScreen={<DataEntityDetailForm {...props} />}>
      <DataEntityView
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        fromFeature
        fromAllView
      />
    </SplitScreen>
  );
};

export const FeatureDataEntitiesTab = props => {
  const [isGraphView, setGraphView] = useState();
  const {
    route: {params},
  } = props;
  const {project, module, feature} = params;
  let tabs = {
    active: {
      label: 'In Development',
      view: <FeatureActiveDataEntities {...props} isGraphView={isGraphView} />,
    },
    all: {
      label: 'All',
      view: <AllDataEntitiesScreen {...props} isGraphView={isGraphView} />,
      actions: [
        project?.ai_enabled && module && feature && (
          <AISuggestionsButton
            {...props}
            entityName="projectmodel"
            filter={{
              module_id: module._id,
            }}
            params={{
              parent_entity: 'Module',
              parent_entity_id: module._id,
              project_id: project._id,
              module_id: module._id,
              label: 'Model',
              entity_category_prompts: [
                {
                  category: 'Detail',
                  prompt_name: 'NewDetailSuggestion',
                  label: 'Model Detail',
                },
              ],
            }}
            initialValues={{
              status: 'active',
              project_id: project._id,
              module_id: module._id,
              source: PROJECT_MODEL_TYPE_ID,
              feature_id: feature?._id,
            }}
            eventSourceId="DataEntityAi"
          />
        ),
      ],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      actions={[
        <ToggleGraphView
          isGraphView={isGraphView}
          setGraphView={setGraphView}
        />,
      ]}
    />
  );
};
