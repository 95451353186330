import React from 'react';
import {plural} from 'pluralize';
import {GroupTable, Table} from '../../../components/table/Table';
import {
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_PULL_REQUEST_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {useInvoke} from '../../../controllers/useInvoke';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {
  EstHrsChipRender,
  EstHrsColumnRender,
} from '../cellrenders/EstHrsChipRender';
import {RenderMarkActive} from '../../task/views/TasksTable';
import DurationChip from '../../../components/chip/DurationChip';
import {TableHeader} from '../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import StatusRender from '../cellrenders/StatusRender';
import {useToast} from '@unthinkable/react-toast';
import Images from '../../../images';
import {LineSeparator, RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {getTime} from '../utility';
import {FeatureDesignScreensRender} from '../cellrenders/DesignUploadCellRender';
import {
  Col,
  Image,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Badge} from '../../../components/badge/Badge';
import {Rating} from '@unthinkable/react-star-rating';
import {ActionText} from '../../../components/action/ActionText';
import {FeatureColumnCard} from '../../../components/card/FeatureColumnCard';
import {useTheme} from '@unthinkable/react-theme';
import {useFeatureActions} from '../actions/FeatureActions';
import {CommentColumn} from '../../../app-components/renders/CommentRender';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {Labels} from '../constants/Label';
import {FeatureStatus, QAStages} from '../constants/FeatureStages';
import {AddFilledAction} from '../../../components/action/AddAction';

const {StageCircle, PullRequest} = Images;

const releasedFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {releasedOn, feature, code} = row;
    if (releasedOn) {
      releasedOn = `Released on ${getTime(releasedOn)}`;
    }

    const {colors} = useTheme();

    return (
      <FeatureColumnCard
        row={row}
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            value: code,
          },
          {
            value: releasedOn,
          },
        ]}
      />
    );
  },
};

const activeFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {createdAt, created_by, feature, code} = row;

    const {colors} = useTheme();

    if (createdAt) {
      createdAt = `Created on ${getTime(createdAt)}`;
    }
    if (created_by?.name) {
      created_by = `By : ${created_by?.name}`;
    }

    return (
      <FeatureColumnCard
        row={row}
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            value: code,
          },
          {
            value: created_by,
          },
          {
            value: createdAt,
          },
        ]}
      />
    );
  },
};

const roadmapFeatureColumn = {
  header: Labels.Feature,
  responsive: 'sm',
  render: ({row}) => {
    let {createdAt, feature, code} = row;
    if (createdAt) {
      createdAt = `Created on ${getTime(createdAt)}`;
    }

    const {colors} = useTheme();

    return (
      <FeatureColumnCard
        primaryTitle={feature}
        secondaryTitleColor={colors.ACCENT6_HIGH}
        items={[
          {
            value: code,
          },
          {
            value: createdAt,
          },
        ]}
        row={row}
      />
    );
  },
};

const featureTasks = ({params, navigation}) => ({
  render: CountCellRender,
  width: 80,
  header: 'Tasks',
  align: 'right',
  released_count_field: 'feature_completed_task_count',
  count_field: 'feature_task_count',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`feature-tasks`, {
      ...params,
      feature: {_id: row?._id, status: row?.status},
      module: row?.module_id,
      title: row?.feature,
      source: PROJECT_FEATURE_TYPE_ID,
      displaySource: true,
    });
  },
});

const totalHours = ({showCompleteHrs}) => ({
  render: EstHrsChipRender,
  width: 100,
  header: 'Est.',
  align: 'right',
  released_count_field: 'completedHours',
  count_field: 'totalHours',
  showCompleteHrs,
});

const estHrsColumn = ({params, navigation, width}) => ({
  render: EstHrsColumnRender,
  width: width || 130,
  header: 'Est.',
  align: 'right',
  estHrsField: 'totalHours',
  completedHrsField: 'completedHours',
  onPress: ({row}) => {
    navigation.navigate(`features-task-table`, {
      ...params,
      feature: {_id: row?._id},
      title: row?.feature,
    });
  },
});

const featureCommentColumn = ({navigation, params}) => {
  return CommentColumn({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_FEATURE_TYPE_ID, label: 'Feature'},
      comment_source_id: {_id: row._id, name: row.feature},
    }),
  });
};

const edit = ({
  navigation,
  params,
  feature_type,
  readOnly,
  extraParams = {},
}) => ({
  title: readOnly ? 'Detail' : 'Edit',
  onPress: props => {
    const {row} = props;
    navigation.navigate('feature-detail', {
      ...params,
      feature: {_id: row?._id},
      feature_type,
      readOnly,
      ...extraParams,
    });
  },
});

const updateFeatureStage = ({navigation, params, stage}) => ({
  title: 'Update Stage',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-feature-stage', {
      ...params,
      row,
      stage,
    });
  },
});

const dueDate = ({navigation}) => ({
  field: 'due_date',
  header: 'Due on',
  type: 'date',
  onPress: ({row}) => {
    navigation.navigate('replan-form', {
      row,
      api: '/projectfeatures',
    });
  },
  width: 72,
  fallbackRender: () => {
    return <AddFilledAction />;
  },
  align: 'center',
});

const changeModule = ({navigation, params}) => ({
  title: 'Update Module',
  onPress: props => {
    const {row} = props;
    navigation.navigate('change-module', {
      ...params,
      row,
      api: '/projectfeatures',
      secondaryTitle: `${row?.feature}`,
    });
  },
  visible: ({row}) => !row.library_id,
});

const updateReleasedOn = ({navigation, params}) => ({
  title: 'Update Released On',
  onPress: ({row}) => {
    navigation.navigate('releasedon-form', {
      row,
    });
  },
});

const markReleasedForFeature = ({invoke, toast = () => {}}) => ({
  title: 'Mark Released',
  confirm: {
    title: 'Release Mark',
    message: 'Are you sure you want to Mark Release?',
  },
  onPress: async props => {
    const {row} = props;
    await invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'released',
      },
    });
  },
});

const markActive = ({invoke}) => ({
  width: 180,
  type: 'text',
  render: RenderMarkActive,
  displayText: ({row}) => {
    return row?.type === 'feature'
      ? 'Define Architecture'
      : 'Move to Development';
  },
  align: 'center',
  onPress: ({row}) => {
    const updateValues =
      row?.type === 'feature'
        ? {sub_status: 'Architecture'}
        : {status: 'active'};

    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: updateValues,
    });
  },
});

const assignees = {
  header: 'Assignees',
  field: 'assignee',
  type: 'userAvatarGroup',
  width: 90,
  align: 'right',
  maxAvatars: 3,
};

const RatingRenderer = ({row}) => {
  if (row?.performance_review) {
    return <Rating value={row['performance_review']} readOnly />;
  }
  return <ActionText text={'Review'} />;
};

const review = ({navigation}) => {
  return {
    type: 'rating',
    width: 100,
    align: 'center',
    render: RatingRenderer,
    onPress: props => {
      const {row} = props;
      navigation.navigate('feature-review', {row});
    },
  };
};

const design = ({navigation, params}) => ({
  render: FeatureDesignScreensRender,
  width: 70,
  header: 'Design',
  align: 'right',
  field: 'design_count',
  onPress: ({row}) => {
    if (row?.design_count) {
      navigation.navigate('task-image-previewer', {...params, row});
    }
  },
});

const markFeatureDelete = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message:
      'Are you sure you want to delete this item? This action cannot be undone.',
    confirmText: 'Delete',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/projectfeatures/${row?._id}/delete`,
      props: {},
    });
  },
});

const moveToBacklog = ({invoke}) => ({
  title: 'Move to Backlog',
  variant: 'subtle',
  confirm: {
    title: 'Move to Backlog',
    message: 'Are you sure you want to move this to Backlog?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'backlog',
        due_date: null,
        sub_status: null,
      },
    });
  },
});

const moveToDevelopment = ({invoke}) => ({
  title: 'Move to Development',
  confirm: {
    title: 'Move to Development',
    message: 'Are you sure you want to move this to Development?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'active',
        sub_status: null,
      },
    });
  },
});

const defineArchitecture = ({invoke}) => ({
  title: 'Define Architecture',
  confirm: {
    title: 'Define Architecture',
    message: 'Are you sure you want to move this to Architecture?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'backlog',
        sub_status: 'Architecture',
      },
    });
  },
  visible: ({row}) => row?.type === 'feature',
});

const moveToActive = ({invoke}) => ({
  title: 'Mark Active',
  variant: 'subtle',
  confirm: {
    title: 'Mark Active',
    message: 'Are you sure you want to mark this as active?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'active',
        releasedOn: null,
        released_by: null,
      },
    });
  },
});

const featureModule = {
  type: 'colorTag',
  field: 'module_id.module',
  colorField: 'module_id.color',
  header: 'Module',
  width: 170,
};

const markQA = ({invoke}) => ({
  title: 'Mark QA',
  confirm: {
    title: 'Mark QA',
    message: 'Are you sure you want to move this to QA?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectfeatures/${row?._id}/markQa`,
      message: 'Marked QA',
    });
  },
  visible: ({row}) =>
    row.status !== FeatureStatus.qa.value || row.sub_status === 'Failed',
});

const markUAT = ({invoke}) => ({
  title: 'Mark UAT',
  confirm: {
    title: 'Mark UAT',
    message: 'Are you sure you want to move this to UAT?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        sub_status: 'UAT',
      },
    });
  },
  visible: ({row}) => {
    const {sub_status} = row;
    return sub_status !== 'UAT';
  },
  visible: ({row}) => row.status === FeatureStatus.qa.value,
});

const featureStatus = {
  render: StatusRender,
  status_field: 'sub_status',
  header: {icon: StageCircle, label: 'Stage'},
  width: 120,
  align: 'center',
};

const featureDetailScreen = ({
  row,
  project,
  featureType,
  clickedSource,
  navigation,
  params,
  taskQualification,
  navigateTo,
}) => {
  const navigationParams = {
    ...params,
    project: project || row?.project_id,
    project_id: project?._id || row?.project_id?._id,
    featureType: featureType || row.type || 'feature',
    feature: row,
    feature_id: row._id,
    module: row?.module_id,
    module_id: row?.module_id?._id,
    sub_status: row?.sub_status,
    clickedSource,
    taskQualification,
  };
  if (navigateTo) {
    navigationParams.breadcrumb = {
      title: row?.feature,
    };
    navigateTo(`feature-detail-screen-new`, navigationParams);
  } else {
    navigation.navigate('feature-detail-screen', navigationParams);
  }
};

const prCount = ({params, navigation, navigateTo}) => ({
  width: 45,
  align: 'right',
  render: ({row}) => {
    if (!row?.open_pr_count) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          featureDetailScreen({
            row,
            clickedSource: PROJECT_PULL_REQUEST_TYPE_ID,
            navigation,
            params,
            project: params.project,
            navigateTo,
          });
        }}>
        <Badge value={row?.open_pr_count}>
          <Image source={PullRequest} />
        </Badge>
      </TouchableOpacity>
    );
  },
});

const markArchived = ({invoke}) => ({
  title: 'Mark Archived',
  variant: 'subtle',
  confirm: {
    title: 'Mark Archived',
    message: 'Are you sure you want to mark this as archive?',
    confirmText: 'Confirm',
  },
  onPress: ({row}) => {
    invoke({
      uri: `/projectfeatures/${row?._id}`,
      props: {
        status: 'archived',
        due_date: null,
      },
    });
  },
});

export const BacklogFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    fromModule,
    groupBy,
    filter,
    filterParams,
    searchValue,
    selectedTab,
  } = props;

  const {project, milestone, projectlibrary_id} = params;
  const {sub_status} = filter;

  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Feature'});

  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      allowDynamicGrouping={true}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      search={searchValue}
      searchFields={['feature', 'code']}
      filter={{
        milestone_id: milestone?._id,
        ...filter,
      }}
      eventSourceId={['Feature', 'Task']}
      api={`/features/backlog`}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      params={{
        project_id: project._id,
        module_id: params?.module?._id,
        ...filterParams,
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          ...featureModule,
          visible: !fromModule && !projectlibrary_id,
        },
        activeFeatureColumn,
        selectedTab === 'Architecture' &&
          design({
            params,
            navigation,
          }),
        featureTasks({params, navigation}),
        {
          render: DurationChip,
          width: 100,
          header: 'Est.',
          align: 'right',
          field: 'totalHours',
        },
        assignees,
        !sub_status && markActive({invoke}),
      ]}
      moreActions={() => [
        edit({navigation, params}),
        changeModule({navigation, params}),
        sub_status && moveToBacklog({invoke}),
        sub_status && moveToDevelopment({invoke}),
        markArchived({invoke}),
        markFeatureDelete({deleteInvoke}),
      ]}
    />
  );
};

export const ReleasedFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    extraParams = {},
    showHeader,
    addOnFilter,
    fromModule,
  } = props;

  const {project, module} = params;
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );
  const toast = useToast();
  const {featureActivityLog} = useFeatureActions({navigation, params});
  let {params: filterParams = {}, filter} = filterValues || {};

  params = {
    ...params,
    ...filterParams,
  };

  const filters = {
    ...filter,
    ...extraParams?.filter,
    ...addOnFilter,
  };
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      renderHeader={() => {
        if (showHeader) {
          return (
            <TableHeader
              title="Released"
              actions={[
                <SearchFilter
                  value={searchValue}
                  onChangeFilter={onChangeFilter}
                />,
                <GroupFilter
                  filterValues={filterValues}
                  applyFilter={applyFilter}
                  filters={[
                    {
                      type: 'autoComplete',
                      label: 'Module',
                      api: '/projectmodules',
                      placeholder: 'Select Module',
                      suggestionField: 'module',
                      valueField: 'module',
                      field: 'module_id',
                      filter: {project_id: project?._id},
                    },
                    {
                      type: 'autoComplete',
                      label: 'Assignee',
                      field: 'assigned_to',
                      api: `/projects/${project._id}/members`,
                      placeholder: 'Select',
                      suggestionField: 'name',
                      secondarySuggestionField: 'email',
                      valueField: 'name',
                      avatar: true,
                      asParam: true,
                    },
                  ]}
                />,
              ]}
            />
          );
        }
      }}
      search={searchValue || extraParams?.searchValue}
      searchFields={['feature', 'code']}
      filter={filters}
      eventSourceId={['Feature', 'Task', 'Comment']}
      api={`/features/released`}
      params={{
        project_id: project._id,
        module_id: module?._id,
        type: 'feature',
        ...extraParams,
        ...filterParams,
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {...featureModule, visible: !fromModule},
        releasedFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        totalHours({params, navigation, showCompleteHrs: false}),
        assignees,
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params, readOnly: true}),
        updateReleasedOn({navigation, params}),
        changeModule({navigation, params}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
      ]}
    />
  );
};

export const ProjectReleasedFeaturesTable = props => {
  const {
    navigation,
    route: {params},
    filter,
    showHeader,
    searchValue: searchFilter,
    filterParams,
    fromModule,
    fromProject,
    limit,
  } = props;

  const {project, milestone, module, projectlibrary_id} = params;
  const {ERROR_HIGH, WARNING_HIGH, SUCCESS_HIGH, UPPER_OUTLINE} =
    useTheme('colors');
  const toast = useToast();
  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );

  const {featureActivityLog} = useFeatureActions({navigation, params});
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <GroupTable
      eventSourceId={['Feature', 'Task', 'Comment']}
      api={`/projects/${project._id}/releasedFeatures`}
      limit={limit || (fromProject ? 10 : 100)}
      renderHeader={props =>
        showHeader && (
          <TableHeader
            title="Released"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Assignee',
                    field: 'assigned_to',
                    api: `/projects/${project._id}/members`,
                    placeholder: 'Select Assignee',
                    suggestionField: 'name',
                    secondarySuggestionField: 'email',
                    valueField: 'name',
                    avatar: true,
                    asParam: true,
                  },
                  {
                    type: 'autoComplete',
                    label: 'Module',
                    api: '/projectmodules',
                    placeholder: 'Select Module',
                    suggestionField: 'module',
                    valueField: 'module',
                    field: 'module_id',
                    filter: {project_id: project?._id},
                  },
                  !params?.milestone?._id && {
                    type: 'autoComplete',
                    label: 'Milestone',
                    api: '/projectmilestones',
                    placeholder: 'Select Milestone',
                    suggestionField: 'milestone',
                    valueField: 'milestone',
                    field: 'milestone_id',
                    filter: {project_id: project?._id},
                  },
                ]}
              />,
            ]}
          />
        )
      }
      searchFields={['feature', 'code']}
      search={searchValue || searchFilter}
      params={{
        ...filterValues?.params,
        ...filterParams,
        project_id: project._id,
        skipNoDataWeek: !fromProject,
      }}
      filter={{
        module_id: module?._id,
        milestone_id: milestone?._id,
        ...filterValues?.filter,
        ...filter,
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          taskQualification: true,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 200,
          visible: !fromModule && !projectlibrary_id,
        },
        releasedFeatureColumn,
        design({params, navigation}),
        totalHours({params, navigation, showCompleteHrs: false}),
        assignees,
        review({navigation}),
        featureCommentColumn({params, navigation}),
      ]}
      groupRow={{
        data: 'data',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {
            count = 0,
            bugs_count = 0,
            disqualified_tasks_count = 0,
          } = row || {};
          let featureCount = `${count} ${Labels.Feature}${
            count > 1 ? 's' : ''
          }`;
          return (
            <RowWithSeparator
              style={{gap: 8, alignItems: 'center'}}
              separator={<LineSeparator color={UPPER_OUTLINE} />}>
              <GroupContentItem value={row?.week_number} />
              <GroupContentItem value={featureCount} />
              {disqualified_tasks_count ? (
                <GroupContentItem
                  title="Disqualified"
                  value={disqualified_tasks_count + ' Disqualified'}
                />
              ) : (
                void 0
              )}
              {bugs_count ? (
                <GroupContentItem
                  title="Bugs"
                  value={bugs_count + ' hrs Bug'}
                />
              ) : (
                void 0
              )}
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          const {disable_release_efficiency} = row;
          if (disable_release_efficiency) {
            return <View style={{height: 28, width: 100}} />;
          }
          const {
            totalAllocatedHrs = 0,
            efficiencyHours = 0,
            team_size = 0,
            completedHours = 0,
            efficiencyPercentage: efficiency = 0,
          } = row || {};

          let color = '';
          if (efficiency >= 80) {
            color = SUCCESS_HIGH;
          } else if (efficiency >= 65) {
            color = WARNING_HIGH;
          } else {
            color = ERROR_HIGH;
          }

          let variant = fromProject ? 'secondary' : 'primary';

          let render = (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              {fromProject ? (
                <>
                  <GroupContentItem
                    value={`${efficiencyHours.toFixed()} h / ${totalAllocatedHrs.toFixed()} h`}
                    variant={variant}
                  />
                  <GroupContentItem
                    value={team_size ? `${team_size} Members` : '0 Members'}
                    variant={variant}
                  />
                </>
              ) : (
                <GroupContentItem
                  value={`Est Hrs : ${completedHours?.toFixed(1)} h`}
                  variant={variant}
                />
              )}
            </RowWithSeparator>
          );
          if (fromProject) {
            return (
              <Col
                style={{
                  alignItems: 'flex-end',
                }}>
                <GroupContentItem
                  value={efficiency?.toFixed() + '% Efficiency'}
                  color={color}
                />
                {render}
              </Col>
            );
          }
          return render;
        },
      }}
      moreActions={() => [
        edit({navigation, params, readOnly: true}),
        moveToActive({invoke}),
        updateReleasedOn({navigation, params}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
      ]}
    />
  );
};

export const RoadMapTable = props => {
  let {
    navigation,
    route: {params},
    stage,
    searchValue,
    filter,
    filterParams,
    groupBy,
  } = props;

  const {project, milestone, module, projectlibrary_id} = params;

  const toast = useToast();
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Feature'});

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'Feature',
      'Model',
      'ThirdPartyIntegration',
      'Controller',
      'Component',
      'StyledComponent',
      'View',
      'DataService',
      'TestCase',
      'projectDataServicesLibrary',
    ],
  });

  const {navigateTo} = useLeftMenuContext() || {};

  const {featureActivityLog} = useFeatureActions({navigation, params});

  return (
    <Table
      search={searchValue}
      allowDynamicGrouping={true}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields={['feature', 'code']}
      eventSourceId={[
        'Feature',
        'Task',
        'View',
        'Milestone',
        'Design',
        'Bug',
        'Comment',
      ]}
      api={`/features/active`}
      filter={{
        module_id: module?._id,
        milestone_id: milestone?._id,
        ...filter,
        project_id: project?._id,
      }}
      params={filterParams}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 150,
          visible: !module && !projectlibrary_id,
        },
        roadmapFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        estHrsColumn({params, navigation, width: stage === 'qa' ? 100 : 130}),
        dueDate({navigation}),
        assignees,
        stage === FeatureStatus.qa.value && featureStatus,
        (stage === FeatureStatus.qa.value ||
          stage === FeatureStatus.active.value) &&
          prCount({params, navigation, navigateTo}),
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [
        edit({navigation, params}),
        changeModule({navigation, params}),
        // updateFeatureStage({navigation, params, stage}),
        markQA({invoke}),
        markUAT({invoke}),
        markReleasedForFeature({invoke, toast}),
        stage !== FeatureStatus.qa.value && defineArchitecture({invoke}),
        stage !== FeatureStatus.qa.value && moveToBacklog({invoke}),
        {
          title: 'Activity Logs',
          onPress: featureActivityLog,
        },
        markFeatureDelete({deleteInvoke}),
      ]}
    />
  );
};

export const ArchiveFeaturesTable = props => {
  let {
    navigation,
    route: {params},
    fromModule,
    filter,
    searchValue,
    filterParams,
  } = props;
  const {project, module, projectlibrary_id} = params;
  const invoke = useInvoke({method: 'put', eventSourceId: 'Feature'});
  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      renderHeader={() => {
        return <TableHeader title={`Archived ${plural(Labels.Feature)}`} />;
      }}
      search={searchValue}
      searchFields={['feature', 'code']}
      filter={{
        module_id: module?._id,
        ...filter,
        project_id: project?._id,
        library_id: projectlibrary_id,
      }}
      params={{
        ...filterParams,
      }}
      eventSourceId={['Feature', 'Task', 'Comment']}
      api={`/features/archive`}
      draggable={{
        updateIndexOnDragEnd: ({updatedIndex, row}) => {
          invoke({
            uri: `/projectfeatures/${row?._id}`,
            props: {
              index: updatedIndex,
            },
          });
        },
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          ...featureModule,
          visible: !fromModule,
        },
        activeFeatureColumn,
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={() => [edit({navigation, params}), moveToBacklog({invoke})]}
    />
  );
};

export const QAFeaturesTable = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const {project, milestone} = params;

  const {navigateTo} = useLeftMenuContext() || {};

  return (
    <Table
      eventSourceId={[
        'Feature',
        'Task',
        'View',
        'Milestone',
        'Design',
        'Bug',
        'Comment',
      ]}
      api={`/features/active`}
      filter={{
        sub_status: {
          $in: QAStages.map(option => option.value),
        },
        project_id: project?._id,
        milestone_id: milestone?._id,
      }}
      onRowPress={({row}) => {
        featureDetailScreen({
          row,
          project,
          navigation,
          params,
          navigateTo,
        });
      }}
      columns={[
        {
          type: 'colorTag',
          field: 'module_id.module',
          colorField: 'module_id.color',
          header: 'Module',
          width: 150,
        },
        roadmapFeatureColumn,
        design({params, navigation}),
        featureTasks({params, navigation}),
        estHrsColumn({params, navigation, width: 100}),
        assignees,
        featureStatus,
        prCount({params, navigation, navigateTo}),
        featureCommentColumn({params, navigation}),
      ]}
    />
  );
};
