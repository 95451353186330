import React from 'react';
import { Form } from '../../../components/form/Form';
const {useFormSubmit} = require('../../../controllers/useSubmitForm');

export const AddTrainingMaterial = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/trainingmaterials',
    eventSourceId: ['TrainingMaterials', 'StudyMaterials'],
    ...props,
  });
  const {
    navigation,
    route: {params},
  } = props;
  const {skill_topic, training_material, skill_metrics} = params;
  return (
    <Form
      header="Add Training Material"
      api={'/trainingmaterials/' + training_material?._id}
      submitAction="Save"
      fields={{
        name: 1,
        days: 1,
        url: 1,
        material_type: {
          name: 1,
        },
        skill_topic: {
          name: 1,
        },
      }}
      onSubmit={onSubmit}
      defaultValues={{
        skill_topic,
        skill_metrics,
      }}
      layoutFields={[
        {
          field: 'name',
          label: 'Material',
          type: 'text',
          required: true,
        },
        {
          field: 'url',
          type: 'text',
          label: 'Link',
          required: true,
        },
        {
          label: 'Topic',
          placeholder: 'Topic',
          field: 'skill_topic',
          type: 'autoComplete',
          api: '/skilltopics',
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            skill_metrics: skill_metrics?._id,
          },
          onCreate: ({searchValue, onChange}) => {
            navigation.navigate(`add-skill-topic`, {
              ...params,
              searchValue,
              afterSubmit: ({data}) => onChange && onChange(data),
            });
          },
        },
        {
          field: 'material_type',
          type: 'autoComplete',
          label: 'Category',
          api: `/trainingMaterialTypes`,
          suggestionField: 'name',
          valueField: 'name',
          onCreate: () => {
            navigation.navigate('add-training-material-type');
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditTrainingMaterial = props => {
  return (
    <AddTrainingMaterial header="Edit Study Material" mode="edit" {...props} />
  );
};
