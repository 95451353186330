import {Text, View} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import moment from 'moment';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {IconButton} from '../../../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Resource',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'employee',
      searchFields: ['name', 'official_email_id', 'employee_code'],
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project',
    },
  ];
};

const shiftAllowanceFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
}) => {
  const icons = useTheme('icons');
  const {download} = useAppStateContext();
  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    tab === 'accepted' && (
      <IconButton
        icon={icons.Download}
        onPress={() => {
          download({
            uri: '/exportExcelHelper',
            props: {
              props: {
                fetchProps: {
                  skipFields: true,
                  api: '/dailyincentives/rm',
                  addOnFilter: {
                    ...filterValues?.filter,
                    status: 'accept',
                  },
                  params: {
                    period,
                  },
                },
                column: {
                  Employee: 'employee.name',
                  Email: 'employee.official_email_id',
                  'From Date': 'from_date',
                  'To Date': 'to_date',
                  'Actual MA': 'actual_ma',
                  'Payable MA': 'amount',
                  'Accept Date': 'accepted_date',
                  'Process Date': 'processed_date',
                },
              },
            },
          });
        }}
      />
    ),
  ];
};

const RESOURCE = {
  header: 'Resource',
  render: ({row}) => {
    const {employee} = row;
    return (
      <UserAvatarWithNameAndEmail
        name={employee?.name}
        color={employee?.color}
        official_email_id={employee?.official_email_id}
      />
    );
  },
};

const FROMDATE = {
  header: 'From Date',
  field: 'from_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const TODATE = {
  header: 'To Date',
  field: 'to_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const ACCEPTEDDATE = ({tab}) => {
  return {
    header: 'Accepted On',
    field: 'accepted_date',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
    visible: () => tab == 'accepted',
  };
};

const PROCESSEDDATE = ({tab}) => {
  return {
    header: 'Processed On',
    field: 'processed_date',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
    visible: () => tab == 'processed',
  };
};

const REJECTEDDATE = ({tab}) => {
  return {
    header: 'Reason',
    align: 'center',
    render: ({row}) => {
      return (
        <PrimaryColumnCard
          primaryTitle={row?.reason}
          items={[
            {
              value:
                row?.rejected_date &&
                moment(row?.rejected_date).format('DD MMM YY'),
            },
          ]}
        />
      );
    },
    visible: () => tab == 'rejected',
  };
};

const ACTUALMA = ({navigation, tab}) => {
  return {
    header: 'Actual MA',
    field: 'actual_ma',
    type: 'currency',
    onPress: ({row}) => {
      tab == 'open' &&
        navigation.navigate('update-actual-ma', {
          row,
        });
    },
  };
};

const PAYABLEMA = {
  header: 'Payable MA',
  field: 'amount',
  align: 'right',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText, color: '#34C759'}}>
        ₹{row?.amount?.toFixed(2)}
      </Text>
    );
  },
};

const ShiftAllowanceRM = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
    tab,
  } = props;

  const updateInvoke = useInvoke({
    method: 'put',
    eventSourceId: ['shiftallowance'],
    throwError: true,
  });

  return (
    <Table
      api={`/dailyincentives/rm`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{...params, period}}
      limit={1000}
      eventSourceId={['shiftallowance']}
      columns={[
        RESOURCE,
        FROMDATE,
        TODATE,
        ACTUALMA({navigation, tab}),
        PAYABLEMA,
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <View style={{flexDirection: 'row'}}>
                <View
                  style={{
                    padding: 4,
                    alignItem: 'center',
                    textAlign: 'center',
                  }}
                  onPress={() => {
                    navigation.navigate('accept-ma', {
                      row,
                    });
                  }}>
                  <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
                </View>
                <View
                  style={{
                    padding: 4,
                    alignItem: 'center',
                    textAlign: 'center',
                  }}
                  onPress={() => {
                    navigation.navigate('reject-ma', {
                      row,
                    });
                  }}>
                  <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
                </View>
              </View>
            );
          },
          visible: () => tab == 'open',
        },
        ACCEPTEDDATE({tab}),
        PROCESSEDDATE({tab}),
        REJECTEDDATE({tab}),
      ]}
      moreActions={[
        (tab == 'accepted' || tab == 'rejected') && {
          title: 'Mark Open',
          confirm: {
            title: 'Mark Open',
            message: 'Are you sure you want to open this shift allowance?',
            confirmText: 'Open',
          },
          onPress: props => {
            const {row} = props;
            updateInvoke({
              uri: `/dailyincentives/${row?._id}`,
              props: {
                ...props,
                params: {
                  ...props.params,
                  status: 'open',
                  accepted_date: void 0,
                  rejected_date: void 0,
                },
              },
            });
          },
        },
        {
          title: 'Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                employee: row?.employee?._id,
              },
            });
          },
        },
        {
          title: 'Attendance',
          onPress: ({row}) => {
            navigation.navigate(`daily-attendance-shift-allowance-tab`, {
              extraAddOnFilter: {
                employee: row.employee,
                attendance_date: {
                  $gte: row.from_date,
                  $lte: row.to_date,
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const ShiftAllowanceForRMTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};

  const tabs = {
    open: {
      label: 'Open',
      view: (
        <ShiftAllowanceRM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'new',
          }}
          period={period}
          tab="open"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'open',
        params,
        period,
      }),
    },

    accepted: {
      label: 'Accepted',
      view: (
        <ShiftAllowanceRM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'accept',
          }}
          period={period}
          tab="accepted"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'accepted',
        params,
        period,
      }),
    },

    processed: {
      label: 'Processed',
      view: (
        <ShiftAllowanceRM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'processed',
          }}
          period={period}
          tab="processed"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'processed',
        params,
        period,
      }),
    },

    rejected: {
      label: 'Rejected',
      view: (
        <ShiftAllowanceRM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'reject',
          }}
          period={period}
          tab="rejected"
        />
      ),
      actions: shiftAllowanceFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'rejected',
        params,
        period,
      }),
    },
  };
  return (
    <>
      <TableHeader
        actions={[
          <AddButton title={'Shift Allowance'} view={'add-ma'} />,
          <AddButton
            title={'Generate Shift Allowance'}
            view={'generate-shift-allowance'}
          />,
        ]}
        title={'Shift Allowance'}
      />
      <TabView tabs={tabs} params={params} {...props} />
    </>
  );
};
