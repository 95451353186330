import {
  Image,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {
  getCurrentMonth,
  getPreviousMonth,
  useFilter,
} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {IconButton} from '../../../components/button/IconButton';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {TaxDeclarationHrView} from './TaxDeclarationHrView';

export const FixedSalaryHrTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  const {download} = useAppStateContext();
  return (
    <Table
      eventSourceId={['fixedSalary']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Fixed Salary"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                asParam
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'employee',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      addOnFilter={{...filterValues.filter}}
      params={filterValues.params}
      api={`/fixedsalary/all`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`fixed-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & Year',
          type: 'text',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
          width: 200,
        },
        {
          header: 'Add On',
          // render: ({row, styles: {rowText = {}}}) => {
          //   if (row.gross_amount) {
          //     return (
          //       <Text
          //         style={{
          //           ...rowText,
          //         }}>
          //         {/* {(row?.gross_amount).toFixed(1)} */}
          //       </Text>
          //     );
          //   } else {
          //     return void 0;
          //   }
          // },
          type: 'currency',
          width: 200,
        },
        {
          header: 'Deductions',
          render: ({row, styles}) => {
            let Deduction =
              Number(row.gross_amount) - Number(row.payable_amount) || 0;
            return (
              <Text style={{...styles?.rowText, color: '#FF9500'}}>
                {'₹' + Deduction?.toFixed(2) || 0}
              </Text>
            );
          },
          type: 'currency',
          width: 200,
        },
        {
          header: 'Payable',
          type: 'currency',
          render: ({row, styles}) => {
            let Payable = row.payable_amount || 0;
            return (
              <Text
                style={{
                  ...styles.rowText,
                  color: '#007AFF',
                }}>
                {'₹' + Payable?.toFixed(2)}
              </Text>
            );
          },
          width: 200,
        },
        {
          render: props => {
            const {
              styles: {rowText},
              row,
            } = props;
            const colors = useTheme('colors');
            const icons = useTheme('icons');
            return (
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={() => {
                  // download({uri: `/salarySlipDownload/${row._id}`});
                  download({
                    uri: `/generateSalarySlipData`,
                    props: {
                      requestId: row._id,
                    },
                  });
                }}>
                <Image
                  source={icons.DownloadBlue}
                  style={{height: 16, width: 16, marginRight: 5}}></Image>
                <Text style={{...rowText, color: '#007AFF'}}>Payslip</Text>
              </TouchableOpacity>
            );
          },
          width: 150,
        },
      ]}
    />
  );
};

export const IncentiveSalaryHrTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  return (
    <Table
      api={'/incentiveSalary/all'}
      params={{...filterValues.params}}
      addOnFilter={{...filterValues.filter}}
      renderHeader={() => {
        return (
          <TableHeader
            title="Incentive Salary"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                asParam
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'employee',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`incentive-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & year',
          width: 200,
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Component',
          field: 'component_details.salary_component_id.name',
          type: 'text',
          width: 250,
        },
        {
          header: 'Add On',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles?.rowText}}>
                {moment(row.invoice_date).format('DD MMM YY')}
              </Text>
            );
          },
          width: 150,
        },
        {
          header: 'Payable',
          field: 'component_details.payable_amount',
          type: 'currency',
          width: 150,
        },
        {
          header: 'Gross',
          field: 'component_details.gross_amount',
          type: 'currency',
          width: 150,
        },
      ]}
    />
  );
};

export const BonusSalaryHrTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {filterValues, applyFilter, onChangeFilter} = useFilter({
    params: {
      period: params.period || getPreviousMonth(),
    },
  });
  return (
    <Table
      api={'/bonus/all'}
      params={{...filterValues.params}}
      addOnFilter={{...filterValues.filter}}
      renderHeader={() => {
        return (
          <TableHeader
            title="Bonus Salary"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                asParam
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    field: 'employee',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`bonus-component-detail`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Month & year',
          width: 200,
          render: ({row}) => {
            return (
              <Text
                style={{
                  color: '#737373',
                  fontSize: '15px',
                  lineHeight: '20px',
                }}>
                {row.month_id.name} {row.year_id.name}
              </Text>
            );
          },
        },
        {
          header: 'Component',
          field: 'component_details.salary_component_id.name',
          type: 'text',
          width: 250,
        },
        {
          header: 'Add On',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles?.rowText}}>
                {moment(row.invoice_date).format('DD MMM YY')}
              </Text>
            );
          },
          width: 150,
        },
        {
          header: 'Payable',
          field: 'payable_amount',
          type: 'currency',
          width: 150,
        },
        {
          header: 'Gross',
          field: 'gross_amount',
          type: 'currency',
          width: 150,
        },
      ]}
    />
  );
};

export const TaxDeclarationHrTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const tabs = {
    open: {
      label: 'Open',
      view: (
        <TaxDeclarationHrView
          tab="open"
          addOnFilter={{status: 'Open', ...filterValues.filter}}
          {...props}
        />
      ),
    },
    submitted: {
      label: 'Submitted',
      view: (
        <TaxDeclarationHrView
          tab="submitted"
          addOnFilter={{status: 'Submitted', ...filterValues.filter}}
          {...props}
        />
      ),
    },
    reOpen: {
      label: 'Re-Open',
      view: (
        <TaxDeclarationHrView
          tab="reopen"
          addOnFilter={{status: 'ReOpen', ...filterValues.filter}}
          {...props}
        />
      ),
    },
    accepted: {
      label: 'Accepted',
      view: (
        <TaxDeclarationHrView
          tab="accepted"
          addOnFilter={{status: 'Accepted', ...filterValues.filter}}
          {...props}
        />
      ),
    },
    all: {
      label: 'All',
      view: (
        <TaxDeclarationHrView
          tab="all"
          addOnFilter={{...filterValues.filter}}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'employee_id',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              label: 'Financial Year',
              type: 'autoComplete',
              api: '/financialYears',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'financial_year_id',
            },
            {
              label: 'Designation',
              type: 'autoComplete',
              api: '/designations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_id.designation',
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

export const KPIIncentiveHRTable = props => {
  let {navigation, addOnFilter} = props;
  const {applyFilter, filterValues} = useFilter({period: getCurrentMonth()});
  let {filter, params} = filterValues;
  params = {...params};
  if (params.subsidiary) {
    params.subsidiary = {_id: {$in: params.subsidiary.map(item => item._id)}};
  }
  if (filter?.employee_filter) {
    addOnFilter = {
      ...addOnFilter,
      ...filter,
    };
  }
  const {download} = useAppStateContext();
  const icons = useTheme('icons');
  return (
    <Table
      api={'/kpiincentive/hr'}
      params={params}
      eventSourceId={'kpiincentiveSalary'}
      addOnFilter={addOnFilter}
      // search={searchValue}
      limit={5000}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`ic-incentive-salary-detail-tab`, {
          row: {...row, ...params},
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="KPI Incentive"
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'date',
                    field: 'period',
                    asParam: true,
                    label: 'Date',
                  },
                  {
                    type: 'multiAutoComplete',
                    field: 'subsidiary',
                    asParam: true,
                    label: 'Organization',
                    api: '/organizations',
                    suggestionField: 'name',
                    valueField: 'name',
                  },
                  {
                    type: 'autoComplete',
                    field: 'employee_filter',
                    label: 'Employee',
                    api: '/employeeSuggestions',
                    suggestionField: 'name',
                    valueField: 'name',
                    searchFields: [
                      'name',
                      'official_email_id',
                      'employee_code',
                    ],
                    secondarySuggestionField: 'official_email_id',
                  },
                ]}
              />,
              // <IconButton
              //   icon={icons.Download}
              //   onPress={() => {
              //     download({
              //       uri: '/exportExcelHelper',
              //       props: {
              //         props: {
              //           fetchProps: {
              //             params,
              //             addOnFilter: addOnFilter,
              //             api: '/kpiincentive/hr',
              //           },
              //           column: {
              //             'Employee Name': 'user.employee.name',
              //             'Employee Code': 'user.employee.employee_code',
              //             Month: 'month_id.name',
              //             Year: 'year_id.name',
              //             Variable: 'variable',
              //           },
              //         },
              //       },
              //     });
              //   }}
              // />,
              <AddButton
                title="Send Variable Mail"
                view="send-variable-mail"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.user?.employee?.name}
                color={row?.user?.employee?.color}
                official_email_id={row?.user?.employee?.employee_code}
              />
            );
          },
          minWidth: 200,
        },
        ,
        {
          header: 'Month & year',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.month_id?.name} {row?.year_id?.name}
              </Text>
            );
          },
        },
        {
          header: 'Amount',
          field: 'variable',
          type: 'currency',
        },
      ]}
    />
  );
};
