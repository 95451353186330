export const ConfirmStyles = ({theme: {colors, fonts}}) => {
  return {
    modal: {
      sm: {
        container: {
          width: '90%',
        },
      },
      md: {
        container: {
          width: 480,
        },
      },
    },
    container: {
      padding: 20,
    },
    title: {
      ...fonts.TITLE,
      color: colors.NEUTRAL_HIGH,
    },
    secondaryTitle: {
      marginTop: 4,
      ...fonts.BODY1,
      color: colors.NEUTRAL_MEDIUM,
    },
    messageText: {
      marginTop: 12,
      marginBottom: 8,
      ...fonts.BODY3,
      color: colors.NEUTRAL_MEDIUM,
    },
    footerContainer: {
      marginTop: 12,
      gap: 12,
    },
    cancelButton: {
      container: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.OUTLINE,
        flex: 1,
      },
      text: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_HIGH,
      },
    },
    confirmButton: {
      container: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.BRAND_UPPER_MEDIUM,
        flex: 1,
      },
      text: {
        ...fonts.ACTION,
        color: colors.BACKGROUND,
      },
    },
    error: {
      cancelButton: {
        container: {
          cursor: 'pointer',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.OUTLINE,
          flex: 1,
        },
        text: {
          ...fonts.ACTION,
          color: colors.ERROR_HIGH,
        },
      },
      confirmButton: {
        container: {
          cursor: 'pointer',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 16,
          paddingRight: 16,
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.ERROR_HIGH,
          flex: 1,
        },
        text: {
          ...fonts.ACTION,
          color: colors.BACKGROUND,
        },
      },
    },
  };
};
