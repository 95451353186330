import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import React, {useState} from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {AmountRender} from '../../order/views/components/AmountRender';
import { featureCommentColumn } from './ExpenseRequestTable';

const RenderStatus = ({row, styles, reffer_to_coo, fromMyView, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'expenserequest',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  let {
    user: {employee},
  } = useAppStateContext();
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          {row.reffer_to_coo && fromMyView ? (
            <View
              style={{
                ...rowText,
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
                color: '#737373',
              }}>
              Forwarded
            </View>
          ) : (
            <>
              <View
                style={{
                  padding: 4,
                  alignItem: 'center',
                  textAlign: 'center',
                }}
                onPress={() => {
                  invoke({
                    uri: `/expenserequests/${row?._id}`,
                    props: {
                      status: 'Approved',
                      approved_date: new Date(),
                      approved_by: employee._id,
                    },
                  });
                }}>
                <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
              </View>
              <View
                style={{
                  padding: 4,
                  alignItem: 'center',
                  textAlign: 'center',
                }}
                onPress={() => {
                  navigation.navigate('reject-expense-request', {
                    row,
                    reffer_to_coo,
                  });
                }}>
                <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
              </View>
            </>
          )}
        </View>
      )}
    </>
  );
};

export const ExpenseApproverTable = props => {
  let {
    navigation,
    tab,
    addOnFilter,
    reffer_to_coo,
    fromMyView,
    searchValue,
    route: {params},
  } = props;
  let {
    user: {employee},
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'expenserequest',
  });
  if (!reffer_to_coo) {
    addOnFilter = {
      ...addOnFilter,
      approver: employee?._id,
    };
  } else {
    addOnFilter = {
      ...addOnFilter,
      reffer_to_coo: true,
    };
  }
  return (
    <Table
      eventSourceId={['expenserequest']}
      api={`/expenserequest/all`}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{
        ...addOnFilter,
      }}
      onRowPress={({row}) => {
        if (tab === 'ExpenseApproverActive') {
          navigation.navigate('edit-expense-request', {row});
        } else {
          navigation.navigate('edit-expense-request', {row, readOnly: true});
        }
      }}
      columns={[
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 200,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 200,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Requester',
          field: 'requester',
          type: 'userAvatar',
        },
        reffer_to_coo && {
          header: 'Forwarded by',
          field: 'approver',
          type: 'userAvatar',
        },
        {
          header: 'Action',
          visible: ({values}) => {
            if (tab === 'ExpenseApproverActive') return true;
          },
          align: 'center',
          render: ({row, styles}) => {
            return (
              <RenderStatus
                row={row}
                styles={styles}
                navigation={navigation}
                reffer_to_coo={reffer_to_coo}
                fromMyView={fromMyView}
              />
            );
          },
        },
        {
          header: 'Status',
          visible: ({values}) => {
            if (tab === 'ExpenseApproverClosed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            if (row?.status === 'Approved') {
              color = '#34C759';
            } else if (row?.status === 'Rejected') {
              color = '#FF5247';
            }
            return (
              <Text style={{...styles.rowText, color: color}}>
                {row.status}
              </Text>
            );
          },
        },
        {
          header: 'Invoice',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDue') return true;
          },
        },
        {
          header: 'Invoiced',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDone') return true;
          },
        },
        featureCommentColumn({params, navigation}),
      ]}
      moreActions={[
        {
          title: 'Forward To COO',
          visible: ({values}) => {
            if (tab === 'ExpenseApproverActive' && !reffer_to_coo) return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequests/${row._id}`,
              props: {
                reffer_to_coo: true,
              },
            });
          },
        },
      ]}
    />
  );
};

export const InvoiceGenerationTable = props => {
  let {navigation, addOnFilter, tab} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'expenserequest',
  });
  return (
    <Table
      eventSourceId={['expenserequest']}
      api={`/expenserequest/finance`}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        {
          header: 'Payment type',
          field: 'mode_of_payment',
          type: 'text',
          width: 150,
        },
        {
          header: 'Invoice',
          width: 150,
          // visible: () => {
          //   if (tab === 'InvoiceGenerationDone') return true;
          // },
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={moment(row?.invoice_date).format('DD/MM/YY')}
                items={[{value: row.invoice_number}]}
              />
            );
          },
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 200,
        },
        {
          header: 'Payment Mode',
          field: 'payment_type',
          type: 'text',
          width: 150,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 150,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
        },
        {
          header: 'Approved',
          field: 'approved_date',
          type: 'date',
        },
        {
          header: 'Invoice',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDue') return true;
          },
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#007AFF'}}>Create</Text>
            );
          },
          onPress: ({row}) => {
            navigation.navigate('add-vendor-invoice', {
              row: {
                draft_invoice: row._id,
                payment_type: row.mode_of_payment,
                payee_id: row.vendor,
                purpose: row.description,
                bill_number: row.bill_number,
                bill_date: row.bill_date,
                document: row.document,
                approved_mail: row.approved_mail,
                hsn_number: row.hsn_number,
                hsn_description: row.hsn_description,
                team: row.team,
                expense_approved_by: row.approver,
                organization: row.organization,
                product: row.product,
                approved_amount: row.amount,
                accrued_type: row.expense_across,
                draft_approved_by: row.approver,
                requested_by: row.requester,
                // schedule_from_date: row.provision_from_date,
                // schedule_to_date: row.provision_to_date,
              },
              expense_view: true,
            });
          },
        },
        {
          header: 'Invoiced',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDone') return true;
          },
        },
        {
          header: 'Organization',
          width: 200,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.organization?.name}
                items={[{value: row?.product?.name}]}
              />
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDue') return true;
          },
          onPress: ({row}) => {
            navigation.navigate('edit-expense-request', {row});
          },
        },
        {
          title: 'Forward To COO',
          visible: ({values}) => {
            if (tab === 'ExpenseApproverActive') return true;
          },
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequests/${row._id}`,
              props: {
                reffer_to_coo: true,
              },
            });
          },
        },
        {
          title: 'Reject',
          visible: ({values}) => {
            if (tab === 'InvoiceGenerationDue') return true;
          },
          confirm: {
            title: 'Reject Draft',
            message: 'Are you sure you want to reject?',
          },
          message: 'Draft rejected successfully',
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequests/${row._id}`,
              props: {
                status: 'Rejected',
                rejected_on: new Date(),
                rejected_by: employee,
              },
            });
          },
        },
      ]}
    />
  );
};

export const ExpenseRequestedAccountsTable = props => {
  let {navigation, addOnFilter, tab} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'expenserequest',
  });
  return (
    <Table
      eventSourceId={['expenserequest']}
      api={`/expenserequest/all`}
      addOnFilter={{
        ...addOnFilter,
        status: 'Requested',
      }}
      columns={[
        {
          header: 'Payment type',
          field: 'mode_of_payment',
          type: 'text',
          width: 150,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          width: 250,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 200,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 150,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 200,
        },
        {
          header: 'Requester',
          field: 'requester',
          type: 'userAvatar',
          width: 150,
        },
        {
          header: 'Request Date',
          field: 'request_date',
          type: 'date',
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-expense-request', {row});
          },
        },
        {
          title: 'Reject',
          onPress: ({row}) => {
            invoke({
              uri: `/expenserequests/${row._id}`,
              props: {
                status: 'Rejected',
                rejected_on: new Date(),
                rejected_by: employee,
              },
            });
          },
        },
      ]}
    />
  );
};
