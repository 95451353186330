import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';

const deleted = ({deleteInvoke}) => ({
  title: 'Delete',
  variant: 'error',
  confirm: {
    title: 'Delete',
    message: 'Are you sure you want to delete cloud?',
  },
  onPress: ({row}) => {
    deleteInvoke({
      uri: `/cloudInfrastructures/${row?._id}`,
      props: {},
    });
  },
});

export const CloudInfraTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {project} = params;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'CloudInfra',
  });

  return (
    <Table
      eventSourceId="CloudInfra"
      renderHeader={() => (
        <TableHeader
          title="Cloud Infrastructure"
          actions={[
            <AddButton
              title="Cloud Infrastructure"
              view="cloud-infra-add-form"
              params={params}
            />,
          ]}
        />
      )}
      api={`/cloudInfrastructures`}
      filter={{project_id: project._id}}
      variant={'none'}
      columns={[
        {
          header: 'Cloud',
          field: 'cloud',
          type: 'text',
        },
        {
          header: 'AWS service',
          field: 'cloud_service',
          type: 'text',
        },
        {
          header: 'EKS type',
          field: 'eks_type',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`cloud-infra-detail`, {
              ...params,
              cloud: row,
            });
          },
        },
        deleted({deleteInvoke}),
      ]}
    />
  );
};
