import React from 'react';
import {Row, Image, Text} from '@unthinkable/react-core-components';
import {useStyles, useTheme} from '@unthinkable/react-theme';

import {FeatureColumnCardStyles} from './theme';
import {PrimaryColumnCard} from './PrimaryColumnCard';

const FeatureIcon = ({imageContainerStyle, image}) => {
  return (
    <Row style={imageContainerStyle}>
      <Image source={image} />
    </Row>
  );
};

const IssueIcon = ({
  issueIconContainerStyle,
  iconText,
  textColor,
  backgroundColor,
}) => {
  return (
    <Row style={[issueIconContainerStyle, {backgroundColor: backgroundColor}]}>
      <Text style={{color: textColor}}>{iconText}</Text>
    </Row>
  );
};

export const FeatureColumnCard = ({
  primaryTitle,
  primaryTitleColor,
  secondaryTitle,
  secondaryTitleColor,
  numberOfLines = 1,
  items,
  row,
  isIssue,
}) => {
  const styles = useStyles(FeatureColumnCardStyles);
  const {
    containerStyle,
    imageContainerStyle,
    issueIconContainerStyle,
    newFeatureIcon,
    issueIcon,
  } = styles;
  const {colors} = useTheme();

  let image, iconText;

  let textColor, backgroundColor;

  if (isIssue) {
    iconText = row?.priority?.[0] || '-';
    switch (row.priority) {
      case 'URGENT':
        textColor = colors.ERROR_HIGH;
        backgroundColor = colors.ERROR_LOW;
        break;
      case 'HIGH':
        textColor = colors.ACCENT4_HIGH;
        backgroundColor = colors.ACCENT4_LOW;
        break;
      case 'MEDIUM':
        textColor = colors.ACCENT1_HIGH;
        backgroundColor = colors.ACCENT1_LOW;
        break;
      case 'LOW':
        textColor = colors.ACCENT3_HIGH;
        backgroundColor = colors.ACCENT3_LOW;
        break;
      default:
        textColor = colors.NEUTRAL_LOW;
        backgroundColor = colors.SURFACE2;
    }
  } else if (row?.type === 'issue') {
    image = issueIcon;
    imageContainerStyle.backgroundColor = colors.ERROR_LOW;
  } else {
    image = newFeatureIcon;
  }

  return (
    <Row style={containerStyle} gap={8}>
      {isIssue ? (
        <IssueIcon
          issueIconContainerStyle={issueIconContainerStyle}
          iconText={iconText}
          textColor={textColor}
          backgroundColor={backgroundColor}
        />
      ) : (
        <FeatureIcon imageContainerStyle={imageContainerStyle} image={image} />
      )}
      <PrimaryColumnCard
        primaryTitle={primaryTitle}
        items={items}
        primaryTitleColor={primaryTitleColor}
        numberOfLines={numberOfLines}
        secondaryTitle={secondaryTitle}
        secondaryTitleColor={secondaryTitleColor}
      />
    </Row>
  );
};
