import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from '../../order/views/components/Durations';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  alreadyProcessed,
  rejectReimbursement,
} from '../controllers/StatusController';
import {activityLogReimbursement} from '../controllers/ReimbursementActivityLogController';
import {
  UserAvatarWithDateChip,
  renderType,
} from '../components/ReimbursementColumnRenders';
import {useAppStateContext} from '../../../providers/AppState';
import {useFetchData} from '../../../controllers/useFetchData';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

const repenReimbursement = ({navigation, params}) => ({
  title: 'Re-open ',
  onPress: props => {
    const {row} = props;
    navigation.navigate('add-remark-reimbursement', {
      reimbursement: row?._id,
    });
  },
});

const showVendorExpense = ({navigation, params}) => ({
  title: 'Show expense',
  onPress: props => {
    const {row} = props;
    console.log('row-', row);
    navigation.navigate('edit-vendor-invoice-details', {
      row: {
        _id: row?.vendor_invoice?._id,
      },
    });
  },
});

const AccountReimbursementColumns = [
  {
    render: props => (
      <UserAvatarWithDateChip
        field={'employee'}
        dateField={'createdAt'}
        requested
        {...props}
      />
    ),
    header: 'Employee',
    width: 200,
  },
  {
    // field: 'reimbursement_type',
    align: 'center',
    render: renderType,
    header: 'Type',
  },
  {
    field: 'description',
    type: 'text',
    header: 'Description',
  },

  {
    header: 'Duration',
    align: 'center',
    render: ({row}) => {
      return Durations({
        toDate: row?.to_date,
        fromDate: row?.from_date,
      });
    },
    width: 200,
  },

  {
    field: 'total_amount',
    type: 'number',
    header: 'Amount',
  },
];

export const ReimbursementAccountPendingTable = props => {
  let {navigation, route, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};

  const fetchProps = {
    params,
    // search,
    addOnFilter: {organization: organization?._id, ...filterValues.filter},
    api: '/reimbursement/account/Pending',
  };
  const {user, download} = useAppStateContext();

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementAccountPending',
      'ReimbursementAccountProcessed',
      'ReimbursementAccountRefferedPending',
      'ReimbursementAccountApproved',
      'ReimbursementAccountReopen',
    ],
  });

  return (
    <Table
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
      ]}
      api={`/reimbursement/account/Pending`}
      addOnFilter={{organization: organization?._id, ...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      Approver: 'approved_by.name',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Pending reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        ...AccountReimbursementColumns,
        {
          field: 'approved_by',
          type: 'userAvatarChip',
          header: 'Approver',
          width: 200,
        },
      ]}
      moreActions={() => [
        // alreadyProcessed({invoke}),
        rejectReimbursement({navigation}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementAccountRefferedPendingTable = props => {
  let {navigation, route, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementAccountRefferedPending',
      'ReimbursementAccountProcessed',
    ],
  });

  const fetchProps = {
    params,
    // search,

    addOnFilter: {
      referred: true,
      organization: organization?._id,
      ...filterValues.filter,
    },
    api: '/reimbursement/account/RefferedPending',
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
      ]}
      api={`/reimbursement/account/RefferedPending`}
      addOnFilter={{
        referred: true,
        organization: organization?._id,
        ...filterValues.filter,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      Approver: 'approved_by.name',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Referred Pending reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        ...AccountReimbursementColumns,
        {
          field: 'referred_by',
          type: 'userAvatarChip',
          header: 'Referred by',
        },
      ]}
      moreActions={() => [
        // alreadyProcessed({invoke}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementAccountApprovedTable = props => {
  let {navigation = {}, route = {}, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementAccountApproved',
      'ReimbursementAccountProcessed',
      'ReimbursementAccountReopen',
    ],
  });

  const {
    user: {employee: employeeId},
    download,
  } = useAppStateContext();

  const fetchProps = {
    params,
    // search,
    addOnFilter: {organization: organization?._id, ...filterValues.filter},
    api: '/reimbursement/account/Approved',
  };
  return (
    <Table
      api={`/reimbursement/account/Approved`}
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
      ]}
      addOnFilter={{organization: organization?._id, ...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      Approver: 'approved_by.name',
                      'Approved on': 'approved_on',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Approved reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        ...AccountReimbursementColumns,
        {
          render: props => (
            <UserAvatarWithDateChip
              field={props?.row?.approver ? 'approver' : 'approved_by'}
              // field={'approver'}
              dateField={'approved_on'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },
      ]}
      moreActions={() => [
        // alreadyProcessed({invoke}),
        {
          title: 'Create Invoice',
          onPress: props => {
            const {row} = props;
            let accumulated_docs = [];
            if (row?.attachment) {
              accumulated_docs.push(row.attachment);
            }
            if (
              row?.reimbursement_detail &&
              row.reimbursement_detail.length > 0
            ) {
              for (const data of row.reimbursement_detail) {
                if (data.detail_attachment) {
                  accumulated_docs.push(data.detail_attachment);
                }
              }
            }
            navigation.navigate(`add-vendor-invoice-reimbursment`, {
              row: {
                employee: row?.employee,
                reimbursement: row?._id,
                // document: row?.attachment,
                document: accumulated_docs,
                hsn_number: '1',
                gst_applicability: 'Non GST Supply',
                supply_location: 'Interstate Supply',
                team: row?.employee?.employee_team,
                expense_approved_by: row.approved_by,
                exp_amount: row.total_amount, //
                purpose: row.description,
                reimbursement_approved_by: row?.approved_by,
                requested_by: row?.employee,
              },
            });
          },
        },
        {
          title: 'Refer to COO',
          confirm: {
            title: 'Forward Request (COO)',
            message: 'Are you sure you want to forward?',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/reimbursements/${row?._id}`,
              props: {
                referred: true,
                // referred_by: employeeId?._id,
                referred_by: row?.approved_by?._id,
                status: 'RefferedPending',
                referred_on: getZeroTimeDate(new Date()),
                approver: '53a437e96dc89c02007cbcc7', //COO id to be replaced }
              },
            });
          },
          visible: ({row}) => !row?.referred,
        },
        repenReimbursement({navigation, params}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementAccountProcessedTable = props => {
  let {navigation, route, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};
  const fetchProps = {
    params,
    // search,
    addOnFilter: {organization: organization?._id, ...filterValues.filter},
    api: '/reimbursement/account/Processed',
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      api={`/reimbursement/account/Processed`}
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
      ]}
      addOnFilter={{organization: organization?._id, ...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      Approver: 'approved_by.name',
                      'Processed on': 'processed_on',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Processed reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'employee'}
              dateField={'createdAt'}
              requested
              {...props}
            />
          ),
          header: 'Employee',
          width: 200,
        },
        {
          // field: 'reimbursement_type',
          align: 'center',
          render: renderType,
          header: 'Type',
        },
        {
          field: 'description',
          type: 'text',
          header: 'Description',
        },
        {
          render: props => (
            <UserAvatarWithDateChip
              field={props?.row?.approver ? 'approver' : 'approved_by'}
              dateField={'approved_on'}
              approve
              {...props}
            />
          ),
          header: 'Approved By',
        },

        {
          field: 'total_amount',
          type: 'number',
          header: 'Amount',
        },
        {
          field: 'processed_on',
          type: 'date',
          header: 'Processed On',
          width: 200,
        },
      ]}
      moreActions={() => [
        showVendorExpense({navigation, params}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};

export const ReimbursementAccountRejectedTable = props => {
  let {navigation, route, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};
  const fetchProps = {
    params,
    // search,
    addOnFilter: {organization: organization?._id, ...filterValues.filter},
    api: '/reimbursement/account/Rejected',
  };
  const {user, download} = useAppStateContext();

  return (
    <Table
      api={`/reimbursement/account/Rejected`}
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
        'ReimbursementAccountReject',
      ]}
      addOnFilter={{organization: organization?._id, ...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      'Rejected by': 'rejected_by.name',
                      'Rejected on': 'rejected_on',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Rejected reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'employee'}
              dateField={'createdAt'}
              requested
              {...props}
            />
          ),
          header: 'Employee',
          width: 200,
        },
        {
          // field: 'reimbursement_type',
          align: 'center',
          render: renderType,
          header: 'Type',
        },
        {
          field: 'description',
          type: 'text',
          header: 'Description',
        },
        {
          render: props => (
            <UserAvatarWithDateChip
              field={'rejected_by'}
              dateField={'rejected_on'}
              approve
              {...props}
            />
          ),
          header: 'Rejected By',
        },

        {
          field: 'total_amount',
          type: 'number',
          header: 'Amount',
        },
        {
          field: 'rejected_on',
          type: 'date',
          header: 'Rejected On',
          width: 200,
        },
      ]}
      moreActions={() => [activityLogReimbursement({navigation, params})]}
    />
  );
};

export const ReimbursementAccountReOpenTable = props => {
  let {navigation, route, filterValues} = props || {};
  let {params = {}} = route || {};
  let {organization = {}} = params || {};
  const fetchProps = {
    params,
    // search,
    addOnFilter: {organization: organization?._id, ...filterValues.filter},
    api: '/reimbursement/account/ReOpen',
  };
  const {user, download} = useAppStateContext();

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementAccountApproved',
      'ReimbursementAccountProcessed',
      'ReimbursementAccountReopen',
    ],
  });

  return (
    <Table
      api={`/reimbursement/account/ReOpen`}
      eventSourceId={[
        'ReimbursementAccountPending',
        'ReimbursementAccountProcessed',
        'ReimbursementAccountRefferedPending',
        'ReimbursementAccountApproved',
        'ReimbursementAccountReopen',
      ]}
      addOnFilter={{organization: organization?._id, ...filterValues.filter}}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`non-editable-reimbursement-request`, {
          reimbursement: row?._id,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'From date': 'from_date',
                      'To date': 'to_date',
                      Description: 'description',
                      Amount: 'total_amount',
                      Approver: 'approved_by.name',
                      Reason: 'remark',
                      Organization: 'employee.organization.name',
                      Product: 'employee.product.name',
                      Department: 'employee.department.name',
                    },
                    filename: 'Reopen reimbursements',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        ...AccountReimbursementColumns,
        {
          field: 'remark',
          type: 'text',
          header: 'Reason',
        },
      ]}
      moreActions={() => [
        // alreadyProcessed({invoke}),
        activityLogReimbursement({navigation, params}),
      ]}
    />
  );
};
