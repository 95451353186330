import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

const options = Array.from({length: 24}, (_, index) => (index + 1).toString());

export const OutreachCampaignForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/inmailCampaigns',
    eventSourceId: 'inmailCampaignAdded',
    ...props,
  });

  const {
    route: {params},
  } = props;
  const {row} = params;

  return (
    <Form
      api={`/inmailCampaigns/${row?._id}`}
      fields={{
        connection_request_day_limit: 1,
        email_day_limit: 1,
        message_day_limit: 1,
        auto_schedule: 1,
        auto_withdrawal: 1,
        auto_withdrawal_time: 1,
        follow_request_day_limit: 1,
        degree_of_connection_day_limit: 1,
        observer: {name: 1},
        like_post_day_limit: 1,
        inmail_message_day_limit: 1,
        country: {name: 1},
        user_segment_id: {name: 1},
        usecase_personalization_id: {name: 1},
        personalization_prompt_id: {name: 1},
        base_model_id: {name: 1},
      }}
      onSubmit={onSubmit}
      header={'Inmail Campaign Detail'}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Auto Withdrawal',
          field: 'auto_withdrawal',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          label: 'Auto withdrawal In Days',
          field: 'auto_withdrawal_time',
          type: 'number',
          visible: ({values}) => {
            return values.auto_withdrawal;
          },
        },

        {
          label: 'Auto Schedule',
          field: 'auto_schedule',
          type: 'autoComplete',
          options: [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          suggestionField: 'label',
          keyField: 'value',
          valueField: 'label',
        },
        {
          label: 'Observers',
          field: 'observer',
          placeholder: 'Assign observer',
          type: 'multiAutoComplete',
          api: `/users`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          valueField: 'name',
        },
        {
          label: 'Country',
          field: 'country',
          placeholder: 'Country',
          type: 'autoComplete',
          api: `/countries`,
          suggestionField: 'name',
          valueField: 'name',
        },
      ]}
      {...props}
    />
  );
};

export const SettingsOutreachCampaignForm = props => {
  return (
    <OutreachCampaignForm
      mode="edit"
      header={'Inmail Campaign Detail'}
      {...props}
    />
  );
};
