import {Text} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import {NestedTableModalRender} from '../../../app-components/renders/NestedTableModalRender';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {Table} from '../../../components/table/Table';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const AdvanceFieldNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Advance Details'}}
      field={'advance_details'}
      fields={[
        {
          field: 'invoice_number',
          type: 'autoComplete',
          api: '/revenueInvoiceSuggestions',
          suggestionField: 'invoice_number',
          valueField: 'invoice_number',
          label: 'Invoice Number',
        },
        {
          field: 'conversion_rate',
          type: 'number',
          label: 'Conversion Rate',
        },
        {
          field: 'adjusted_amount',
          type: 'number',
          label: 'Adjusted Amount',
        },
        // {
        //   field: 'base_adjusted_amount',
        //   type: 'number',
        //   label: 'Base Amount',
        // },
      ]}
      columns={[
        {
          field: 'invoice_number.invoice_number',
          type: 'text',
          header: 'Invoice Number',
          minWidth: 150,
        },
        {
          field: 'conversion_rate',
          type: 'number',
          header: 'Conversion Rate',
          width: 125,
        },
        {
          field: 'adjusted_amount',
          type: 'currency',
          header: 'Adjusted Amount',
          width: 135,
        },
        // {
        //   field: 'base_adjusted_amount',
        //   type: 'currency',
        //   header: 'Base Adjusted Amount',
        //   width: 165,
        // },
      ]}
    />
  );
};

const DeductionNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Deduction'}}
      field={'deduction'}
      fields={[
        {
          field: 'account',
          type: 'autoComplete',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
          label: 'Account',
        },
        {
          field: 'amount',
          type: 'number',
          label: 'Amount',
        },
        // {
        //   field: 'base_amount',
        //   type: 'number',
        //   label: 'Base Amount',
        // },
      ]}
      columns={[
        {
          field: 'account.name',
          type: 'text',
          header: 'Account',
        },
        {
          field: 'amount',
          type: 'currency',
          header: 'Amount',
        },
        // {
        //   field: 'base_amount',
        //   type: 'currency',
        //   header: 'Base Amount',
        // },
      ]}
    />
  );
};

const CreditNoteNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Credit Note'}}
      field={'credit_notes'}
      columns={[
        {
          field: 'invoice.invoice_number',
          header: 'Invoice',
          type: 'autoComplete',
          api: '/revenueInvoiceSuggestions',
          suggestionField: 'name',
          valueField: 'name',
        },
        {
          field: 'invoice_amount',
          header: 'Invoice Amount',
          type: 'number',
          width: 200,
        },
        {
          type: 'number',
          placeholder: 'Exchange Rate',
          field: 'exchange_rate',
          header: 'Exchange Rate',
          width: 200,
        },
      ]}
      fields={[
        {
          field: 'invoice',
          label: 'Invoice',
          type: 'autoComplete',
          api: '/revenueInvoiceSuggestions',
          filter: {
            invoice_type: 'c',
            pending_receipt_amount: {$lte: -1},
          },
          suggestionField: 'invoice_number',
          valueField: 'invoice_number',
        },
        {
          field: 'invoice_amount',
          label: 'Invoice Amount',
          type: 'number',
        },
        {
          type: 'number',
          placeholder: 'Exchange Rate',
          field: 'exchange_rate',
          label: 'Exchange Rate',
        },
      ]}
    />
  );
};

const InvoiceAmountSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');

  const data = [
    {
      key: 'invoice_amount',
      name: 'Invoice amount',
      amount: values?.invoice_amount || 0,
      currency: values?.currency || 'INR',
    },
    {
      key: 'total_tax_amount',
      name: 'Total tax amount',
      amount: values?.total_tax_amount || 0,
      currency: values?.currency || 'INR',
    },
    {
      key: 'advance_details',
      name: 'Advances',
      amount: values?.advance_amount || 0,
      currency: values?.currency || 'INR',
    },
    {
      key: 'deduction',
      name: 'Deductions',
      amount: values?.deduction_amount || 0,
      currency: values?.currency || 'INR',
    },
    {
      key: 'CreditNote',
      name: 'Credit Note',
      amount: values?.credit_note_amount || 0,
      currency: values?.currency || 'INR',
    },
    {
      key: 'total',
      name: 'Total',
      amount: values?.total_invoice_amount || 0,
      currency: values?.currency || 'INR',
    },
  ];
  return (
    <Table
      data={data}
      columns={[
        {
          render: ({row, ...rest}) => {
            const {styles} = rest;
            return <Text style={styles?.groupRowText}>{row?.name}</Text>;
          },
        },
        {
          render: ({row}) => {
            let renderNestedTable = null;
            if (row?.key === 'advance_details') {
              renderNestedTable = <AdvanceFieldNestedTable {...props} />;
            } else if (row?.key === 'deduction') {
              renderNestedTable = <DeductionNestedTable {...props} />;
            } else if (row?.key === 'CreditNote') {
              renderNestedTable = <CreditNoteNestedTable {...props} />;
            }
            return renderNestedTable;
          },
        },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = (
              <Text style={styles?.rowText}>{`${Number(row?.amount).toFixed(
                1,
              )} ${row.currency?.currency}`}</Text>
            );
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>{`${Number(row?.amount).toFixed(1)} ${
                  row.currency.currency
                }`}</Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

const visibilityForInvoiceType = (type = [], values) => {
  let {invoice_type = ''} = values || {};
  return type.includes(invoice_type);
};

const InvoiceComputations = fetch => ({
  base_currency: {
    compute: async value => {
      if (value?.organization?._id) {
        const {data: organizationData} = await fetch({
          uri: '/organizations',
          props: {
            filter: {
              _id: value?.organization?._id,
            },
            fields: {_id: 1, currency: {_id: 1, currency: 1}},
            only: true,
          },
        });
        return organizationData?.currency;
      }
    },
    dependencies: ['organization'],
  },
  'set default fields from customer': {
    compute: async value => {
      if (value?.customer?._id) {
        const {data: customerData} = await fetch({
          uri: '/customers',
          props: {
            filter: {
              _id: value?.customer?._id,
            },
            fields: {
              _id: 1,
              hsn_no: 1,
              hsn_description: 1,
              type_of_supply: 1,
              place_of_supply: 1,
              invoice_category: 1,
              location_id: {_id: 1, name: 1},
              organization: {_id: 1, name: 1},
              product: {_id: 1, name: 1},
              owner: {_id: 1, name: 1},
              credit_days: 1,
            },
            only: true,
          },
        });
        const creditdays = customerData?.credit_days || 7;
        const dueDate = getZeroTimeDate(new Date());
        return {
          invoice_category: customerData.invoice_category,
          type_of_supply: customerData.type_of_supply,
          place_of_supply: customerData.place_of_supply,
          hsn_no: customerData.hsn_no,
          hsn_description: customerData.hsn_description,
          account_owner_id: customerData.owner,
          location: customerData?.location_id,
          organization: customerData?.organization,
          product: customerData?.product,
          due_date: dueDate.setDate(dueDate.getDate() + creditdays),
        };
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_date'],
  },
  'set month and year': {
    compute: async value => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getUTCFullYear();
      const {data: monthResult} = await fetch({
        uri: '/monthtypes',
        props: {
          filter: {
            key: currentMonth + 1,
          },
          only: true,
        },
      });
      const {data: yearResult} = await fetch({
        uri: '/yeartypes',
        props: {
          filter: {
            name: currentYear,
          },
          only: true,
        },
      });
      return {service_month: monthResult, service_year: yearResult};
    },
    multi: true,
    dependencies: ['customer'],
  },
  exchange_rate: {
    compute: async value => {
      if (value?.currency?._id && value.invoice_date) {
        const {data: exchangeData} = await fetch({
          uri: '/daywisecurrencies',
          props: {
            filter: {
              currency: value?.currency?._id,
              from_date: {$lte: value.invoice_date},
              $or: [{to_date: null}, {to_date: {$gte: value.invoice_date}}],
            },
            fields: {_id: 1, exchange_rate: 1},
            only: true,
          },
        });
        return exchangeData?.exchange_rate;
      }
    },
    dependencies: ['currency', 'invoice_date'],
  },
  'set customer details': {
    compute: async value => {
      const {customer, invoice_date} = value;
      if (customer?._id && invoice_date) {
        const {data: customerData} = await fetch({
          uri: `/customer/taxdetails/${customer?._id}`,
          props: {
            date: value.invoice_date,
          },
        });
        let {
          product,
          receivable_account,
          advanced_account,
          foreigner,
          location_id,
          base_currency,
          customer_tds_rate,
          customer_vat_rate,
          customer_gst_rate,
        } = customerData || {};
        const response = {};
        if (base_currency) {
          response.currency = base_currency;
        }
        if (customer_vat_rate?.length > 0) {
          customer_vat_rate = customer_vat_rate[0];
        }
        if (customer_gst_rate?.length > 0) {
          customer_gst_rate = customer_gst_rate[0];
        }
        if (receivable_account) {
          response.clinet_account = receivable_account;
        }
        if (advanced_account) {
          response.advance_account = advanced_account;
        }
        if (location_id) {
          response.location = location_id;
        }
        if (product) {
          response.product = product;
        }
        if (customer_gst_rate?.igst) {
          response.igst_account_id = customer_gst_rate?.igst;
        }
        if (customer_gst_rate?.cgst) {
          response.cgst_account_id = customer_gst_rate?.cgst;
        }
        if (customer_gst_rate?.sgst) {
          response.sgst_account_id = customer_gst_rate?.sgst;
        }
        if (customer_vat_rate?.account) {
          response.vat_account = customer_vat_rate?.account;
        }
        const finalResponse = {
          ...response,
          product,
          foreigner,
          customer_vat_rate,
          customer_gst_rate,
        };
        return finalResponse;
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_date'],
  },
  'set customer delivery details': {
    compute: async value => {
      const {
        customer,
        organization,
        invoice_type,
        product,
        pricing_type,
        exchange_rate = 1,
      } = value;
      if (
        customer?._id &&
        organization?._id &&
        invoice_type &&
        product?._id &&
        pricing_type
      ) {
        const {data: deliveryData} = await fetch({
          uri: `/projectresourceworkingdata/pending/${customer?._id}`,
          props: {
            customer,
            organization,
            invoice_type,
            orderType: pricing_type,
            product,
          },
        });

        if (deliveryData && deliveryData.length > 0) {
          const details = deliveryData.map(delivery => {
            let account = void 0;
            if (delivery?.customer?.is_domestic) {
              account = {
                _id: '53ae899879df44bac9b0ba22',
                name: 'Domestic Services',
              };
            } else {
              account = {
                _id: '53ae7ccb79df44bac9b0ba1a',
                name: 'Export Services',
              };
            }
            const lineitem = {
              delivery_id: {
                _id: delivery._id,
                delivery_number: delivery.delivery_number,
                rate: delivery.rate,
              },
              quantity: delivery.pending_billing_hrs,
              amount: delivery.pending_amount,
              basedelivery_amount:
                delivery.exchange_rate * delivery.pending_amount,
              baseinvoice_amount: exchange_rate * delivery.pending_amount,
              description: delivery.description,
              delivery_rate: delivery.exchange_rate,
              product: delivery.product,
              hsn_sac_code: value.hsn_no,
              delivered_hours: delivery.billed_hours,
            };
            if (account) {
              lineitem['account'] = account;
            }
            return lineitem;
          });
          if (details && details.length > 0) {
            return {invoice_details: details};
          }
        } else {
          return {invoice_details: []};
        }
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_type', 'pricing_type'],
  },
  'amount total in invoice': {
    compute: values => {
      let baseTotalAmount = 0;
      let totalAmount = 0;
      let totalTaxAmount = 0;
      let baseTotalTaxAmount = 0;
      let sgst_amount = 0;
      let cgst_amount = 0;
      let igst_amount = 0;
      let vat_amount = 0;
      let high_educationcess_amount = 0;
      let educationcess_amount = 0;
      let servicetax_amount = 0;
      let base_sgst_amount = 0;
      let base_cgst_amount = 0;
      let base_igst_amount = 0;
      let base_vat_amount = 0;
      let base_high_educationcess_amount = 0;
      let base_educationcess_amount = 0;
      let base_servicetax_amount = 0;
      let other_amount = 0;
      let invoicetotal_otheramount = 0;
      if (values.invoice_details && values.invoice_details.length > 0) {
        values.invoice_details.forEach(invoice => {
          if (invoice.baseinvoice_amount) {
            baseTotalAmount += invoice.baseinvoice_amount;
          }
          if (invoice.amount) {
            totalAmount += invoice.amount;
          }
          if (invoice.advance_amount) {
            totalAmount -= invoice.advance_amount;
            baseTotalAmount -= invoice.advance_amount * values.exchange_rate;
          }
          if (invoice.servicetax_amount) {
            totalTaxAmount += invoice.servicetax_amount;
            baseTotalTaxAmount += invoice.base_servicetax_amount;
            servicetax_amount += invoice.servicetax_amount;
            base_servicetax_amount += invoice.base_servicetax_amount;
          }
          if (invoice.educationcess_amount) {
            totalTaxAmount += invoice.educationcess_amount;
            baseTotalTaxAmount += invoice.base_educationcess_amount;
            educationcess_amount += invoice.educationcess_amount;
            base_educationcess_amount += invoice.base_educationcess_amount;
          }
          if (invoice.high_educationcess_amount) {
            totalTaxAmount += invoice.high_educationcess_amount;
            baseTotalTaxAmount += invoice.base_high_educationcess_amount;
            high_educationcess_amount += invoice.high_educationcess_amount;
          }
          if (invoice.igst_amount) {
            totalTaxAmount += invoice.igst_amount;
            baseTotalTaxAmount += invoice.base_igst_amount;
            igst_amount += invoice.igst_amount;
            base_igst_amount += invoice.base_igst_amount;
          }
          if (invoice.vat_amount) {
            totalTaxAmount += invoice.vat_amount;
            baseTotalTaxAmount += invoice.base_vat_amount;
            vat_amount += invoice.vat_amount;
            base_vat_amount += invoice.base_vat_amount;
          }
          if (invoice.cgst_amount) {
            totalTaxAmount += invoice.cgst_amount;
            baseTotalTaxAmount += invoice.base_cgst_amount;
            cgst_amount += invoice.cgst_amount;
            base_cgst_amount += invoice.base_cgst_amount;
          }
          if (invoice.sgst_amount) {
            totalTaxAmount += invoice.sgst_amount;
            baseTotalTaxAmount += invoice.base_sgst_amount;
            sgst_amount += invoice.sgst_amount;
            base_sgst_amount += invoice.base_sgst_amount;
          }
          if (invoice.sgst_amount) {
            other_amount += invoice.other_amount;
          }
          if (invoice.other_amount) {
            other_amount += invoice.other_amount;
          }
          if (invoice.total_invoice_otheramount) {
            invoicetotal_otheramount += invoice.total_invoice_otheramount;
          }
        });
      }
      const response = {
        base_invoice_amount: baseTotalAmount,
        invoice_amount: totalAmount,
        base_total_tax_amount: baseTotalTaxAmount,
        total_tax_amount: totalTaxAmount,
        other_amount,
        invoicetotal_otheramount,
        sgst_amount,
        cgst_amount,
        vat_amount,
        igst_amount,
        servicetax_amount,
        high_educationcess_amount,
        educationcess_amount,
        base_sgst_amount,
        base_cgst_amount,
        base_vat_amount,
        base_igst_amount,
        base_servicetax_amount,
        base_high_educationcess_amount,
        base_educationcess_amount,
      };
      return response;
    },
    multi: true,
    dependencies: ['exchange_rate', 'invoice_details'],
  },
  'set invoice_details': {
    compute: values => {
      let advance_amount = values.advance_amount || 0;
      if (values.invoice_details && values.invoice_details.length > 0) {
        const details = values.invoice_details.map(invoice => {
          invoice = {...invoice};
          if (invoice.amount && advance_amount) {
            if (invoice.amount >= advance_amount) {
              invoice.advance_amount = advance_amount;
              advance_amount = 0;
            } else {
              invoice.advance_amount = invoice.amount;
              advance_amount = advance_amount - invoice.advance_amount;
            }
          } else {
            invoice.advance_amount = 0;
            advance_amount = advance_amount - invoice.advance_amount;
          }
          return invoice;
        });
        if (details && details.length > 0) {
          return {invoice_details: [...details]};
        }
      }
    },
    multi: true,
    dependencies: ['advance_amount', 'exchange_rate'],
  },
  'set deduction total in invoice': {
    compute: value => {
      let baseTotalAmount = 0;
      let totalAmount = 0;
      if (value.deduction && value.deduction.length > 0) {
        value.deduction.forEach(size => {
          if (size.base_amount) {
            baseTotalAmount += size.base_amount;
          }
          if (size.amount) {
            totalAmount += size.amount;
          }
        });
      }
      return {
        base_deduction_amount: baseTotalAmount,
        deduction_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['deduction'],
  },
  base_total_invoice_amount: {
    compute: value => {
      let amount = 0;
      if (value.base_total_tax_amount) {
        amount += value.base_total_tax_amount;
      }
      if (value.base_invoice_amount) {
        amount += value.base_invoice_amount;
      }
      if (value.base_deduction_amount) {
        amount -= value.base_deduction_amount;
      }
      if (value.credit_note_base_amount) {
        amount -= value.credit_note_base_amount;
      }
      if (value.invoicetotal_otheramount) {
        amount += value.invoicetotal_otheramount;
      }
      // if (value.base_advance_amount) {
      //   amount -= value.base_advance_amount;
      // }
      return {base_total_invoice_amount: amount};
    },
    multi: true,
    dependencies: [
      'total_tax_amount',
      'credit_note_base_amount',
      'base_invoice_amount',
      'base_deduction_amount',
      'invoicetotal_otheramount',
      'base_advance_amount',
      'base_total_tax_amount',
    ],
  },
  total_invoice_amount: {
    compute: value => {
      let amount = 0;
      if (value.total_tax_amount) {
        amount += value.total_tax_amount;
      }
      if (value.invoice_amount) {
        amount += value.invoice_amount;
      }
      if (value.deduction_amount) {
        amount -= value.deduction_amount;
      }
      if (value.credit_note_amount) {
        amount -= value.credit_note_amount;
      }
      if (value.other_amount) {
        amount += value.other_amount;
      }
      // if (value.advance_amount) {
      //   amount -= value.advance_amount;
      // }
      return {total_invoice_amount: amount};
    },
    multi: true,
    dependencies: [
      'total_tax_amount',
      'credit_note_amount',
      'invoice_amount',
      'deduction_amount',
      'other_amount',
      'advance_amount',
    ],
  },
  'set advance total in invoice': {
    compute: values => {
      let baseAdvanceAmount = 0;
      let advanceAmount = 0;
      if (values.advance_details && values.advance_details.length > 0) {
        values.advance_details.forEach(advance => {
          if (advance.adjusted_amount) {
            advanceAmount += advance.adjusted_amount;
          }
          if (advance.base_adjusted_amount) {
            baseAdvanceAmount += advance.base_adjusted_amount;
          }
        });
      }
      return {
        advance_amount: advanceAmount,
        base_advance_amount: baseAdvanceAmount,
      };
    },
    multi: true,
    dependencies: ['advance_details'],
  },
  'set delivery_count': {
    compute: values => {
      const deliveryCount =
        (values.invoice_details && values.invoice_details.length) || 0;
      return {
        delivery_count: deliveryCount,
      };
    },
    multi: true,
    dependencies: ['invoice_details'],
  },
  credit_notes: {
    compute: async values => {
      const {customer, invoice_type} = values || {};
      if (customer && invoice_type) {
        const {data: creditNotesData} = await fetch({
          uri: '/revenueinvoices',
          props: {
            filter: {
              customer: customer._id,
              // is_fluctuated: {$in: [false, null]},
              invoice_type: 'c',
              pending_receipt_amount: {$lte: -1},
            },
            fields: {
              _id: 1,
              invoice_number: 1,
              pending_receipt_amount: 1,
              exchange_rate: 1,
              clinet_account: 1,
            },
          },
        });
        let credit_note_amount = 0;
        let base_credit_note_amount = 0;
        if (creditNotesData && creditNotesData.length > 0) {
          const creditNotesResult = creditNotesData.map(row => {
            row.invoice = {_id: row._id, invoice_number: row.invoice_number};
            row.invoice_amount = -1 * row.pending_receipt_amount;
            row.base_invoice_amount =
              row.exchange_rate &&
              -1 * row.pending_receipt_amount * row.exchange_rate;
            row.account = row.clinet_account;
            row.exchange_rate = row.exchange_rate;
            delete row.clinet_account;
            delete row.pending_receipt_amount;
            delete row.invoice_number;
            delete row._id;
            credit_note_amount += row.invoice_amount;
            base_credit_note_amount += row.base_invoice_amount;
            return row;
          });
          if (
            invoice_type &&
            (invoice_type === 'a' ||
              invoice_type === 'i' ||
              invoice_type === 'd')
          ) {
            return {
              credit_notes: creditNotesResult,
              credit_note_amount,
              base_credit_note_amount,
            };
          } else {
            return {credit_notes: []};
          }
        } else {
          return {credit_notes: []};
        }
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_type'],
  },
  'set credit_notes total in invoice': {
    compute: values => {
      let totalAmount = 0;
      let basetotalAmount = 0;
      if (values.credit_notes && values.credit_notes.length > 0) {
        values.credit_notes.forEach(size => {
          if (size.invoice_amount) {
            totalAmount += size.invoice_amount;
            basetotalAmount += size.base_invoice_amount;
          }
        });
      }
      return {
        base_credit_note_amount: basetotalAmount,
        credit_note_base_amount: basetotalAmount,
        credit_note_amount: totalAmount,
      };
    },
    multi: true,
    dependencies: ['credit_notes'],
  },
  advance_details: {
    compute: async values => {
      const {customer, invoice_type} = values || {};
      if (
        customer &&
        customer._id &&
        (invoice_type === 'i' || invoice_type === 'd')
      ) {
        const {data: advanceData} = await fetch({
          uri: '/revenueinvoices',
          props: {
            filter: {
              customer: customer._id,
              invoice_type: 'a',
              pending_advance_amount: {$gt: 1},
            },
            fields: {
              _id: 1,
              invoice_number: 1,
              pending_advance_amount: 1,
              base_pending_advance_amount: 1,
              exchange_rate: 1,
            },
          },
        });
        if (advanceData && advanceData.length > 0) {
          let advance_amount = 0;
          let base_advance_amount = 0;
          const advanceDetails = advanceData.map(invoice => {
            const advanceLineItem = {
              _id: invoice._id,
              invoice_number: {
                _id: invoice._id,
                invoice_number: invoice?.invoice_number,
              },
              adjusted_amount: invoice.pending_advance_amount,
              base_adjusted_amount: invoice.base_pending_advance_amount,
              conversion_rate: invoice.exchange_rate,
            };
            advance_amount += advanceLineItem.adjusted_amount;
            base_advance_amount += advanceLineItem.base_adjusted_amount;
            return advanceLineItem;
          });
          if (advanceDetails && advanceDetails.length) {
            return {
              advance_details: advanceDetails,
              base_advance_amount,
              advance_amount,
            };
          }
        }
      }
      if (customer && (invoice_type === 'a' || invoice_type === 'p')) {
        return {advance_details: []};
      }
    },
    multi: true,
    dependencies: ['customer', 'invoice_type', 'invoice_date'],
  },
  nestedComputations: {
    invoice_details: {
      hsn_sac_code: {
        compute: (values, {_parentValues}) => {
          if (_parentValues.hsn_no) {
            return _parentValues.hsn_no;
          }
        },
        dependencies: ['_id', '_parentValues.hsn_no'],
      },
      amount: {
        compute: (values, {_parentValues}) => {
          if (
            _parentValues.exchange_rate &&
            values.delivery_id &&
            values.delivery_id.rate &&
            values.quantity
          ) {
            let amount = values.delivery_id.rate * values.quantity;
            amount = Math.round(amount);
            return amount;
          }
        },
        dependencies: [
          'quantity',
          'delivery_rate',
          '_parentValues.exchange_rate',
        ],
      },
      basedelivery_amount: {
        compute: (values, {_parentValues}) => {
          if (_parentValues.exchange_rate && values.amount) {
            if (
              values.delivery_rate &&
              values.amount &&
              values.amount > 0 &&
              values.description
            ) {
              const basedeliveryAmount = values.delivery_rate * values.amount;
              return basedeliveryAmount;
            }
          }
        },
        dependencies: [
          'amount',
          'delivery_rate',
          '_parentValues.exchange_rate',
        ],
      },
      baseinvoice_amount: {
        compute: (values, {_parentValues}) => {
          if (_parentValues.exchange_rate && values.amount) {
            const baseinvoiceAmount =
              values.amount * _parentValues.exchange_rate;
            return baseinvoiceAmount;
          }
        },
        dependencies: ['amount', '_parentValues.exchange_rate'],
      },
      update_tax_amount: {
        compute: (value, {_parentValues}) => {
          if (_parentValues && _parentValues.exchange_rate && value.amount) {
            const exchange_rate = _parentValues.exchange_rate || 0;
            const taxResult = updateTaxAmount(value.invoice_tax) || {};
            const response = {};
            response['servicetax_amount'] = taxResult['Service Tax'] || 0;
            response['vat_amount'] = taxResult['Vat'] || 0;
            response['educationcess_amount'] = taxResult['Cess'] || 0;
            response['high_educationcess_amount'] = taxResult['Hcess'] || 0;
            response['kk_amount'] = taxResult['KK'] || 0;
            response['igst_amount'] = taxResult['IGST'] || 0;
            response['cgst_amount'] = taxResult['CGST'] || 0;
            response['sgst_amount'] = taxResult['SGST'] || 0;

            response['base_servicetax_amount'] =
              taxResult['Service Tax'] * exchange_rate || 0;
            response['base_vat_amount'] = taxResult['Vat'] * exchange_rate || 0;
            response['base_educationcess_amount'] =
              taxResult['Cess'] * exchange_rate || 0;
            response['base_high_educationcess_amount'] =
              taxResult['Hcess'] * exchange_rate || 0;
            response['base_kk_amount'] = taxResult['KK'] * exchange_rate || 0;
            response['base_igst_amount'] =
              taxResult['IGST'] * exchange_rate || 0;
            response['base_cgst_amount'] =
              taxResult['CGST'] * exchange_rate || 0;
            response['base_sgst_amount'] =
              taxResult['SGST'] * exchange_rate || 0;
            return {...response};
          }
        },
        multi: true,
        dependencies: ['invoice_tax', '_parentValues.exchange_rate'],
      },
      update_total_tax_amount: {
        compute: (values, {_parentValues}) => {
          const {exchange_rate} = _parentValues;
          const {
            amount,
            servicetax_amount,
            vat_amount,
            educationcess_amount,
            high_educationcess_amount,
            kk_amount,
            igst_amount,
            cgst_amount,
            sgst_amount,
          } = values;
          if (exchange_rate && values.amount) {
            const finalValues = {};
            const total_tax_amount =
              (servicetax_amount || 0) +
              (vat_amount || 0) +
              (educationcess_amount || 0) +
              (high_educationcess_amount || 0) +
              (kk_amount || 0) +
              (igst_amount || 0) +
              (cgst_amount || 0) +
              (sgst_amount || 0);
            finalValues.total_tax_amount = total_tax_amount || 0;
            finalValues.base_total_tax_amount =
              total_tax_amount * exchange_rate;
            finalValues.base_servicetax_amount =
              servicetax_amount * exchange_rate;
            finalValues.base_vat_amount = vat_amount * exchange_rate;
            finalValues.base_educationcess_amount =
              educationcess_amount * exchange_rate;
            finalValues.base_high_educationcess_amount =
              high_educationcess_amount * exchange_rate;
            finalValues.base_kk_amount = kk_amount * exchange_rate;
            finalValues.base_igst_amount = igst_amount * exchange_rate;
            finalValues.base_cgst_amount = cgst_amount * exchange_rate;
            finalValues.base_sgst_amount = sgst_amount * exchange_rate;
            return {...finalValues};
          }
        },
        multi: true,
        dependencies: [
          'servicetax_amount',
          'vat_amount',
          'educationcess_amount',
          'high_educationcess_amount',
          'kk_amount',
          'igst_amount',
          'cgst_amount',
          'sgst_amount',
        ],
      },
      nestedComputations: {
        invoice_tax: {
          'set amount in tax': {
            compute: (value, {_parentValues}) => {
              const type = value.tax_type;
              const nestedValues = _parentValues || {};
              const parentValue = nestedValues._parentValues || {};
              let amount = nestedValues.baseinvoice_amount || 0;
              if (parentValue.exchange_rate) {
                amount = nestedValues.amount || 0;
              }
              const advanceAmount = nestedValues.advance_amount || 0;
              amount = amount - advanceAmount;

              return calculateTaxAmount(type, amount, parentValue);
            },
            multi: true,
            dependencies: ['tax_type'],
          },
        },
      },
    },
    deduction: {
      base_amount: {
        compute: (values, {_parentValues}) => {
          if (_parentValues.exchange_rate && values.amount) {
            return values.amount * _parentValues.exchange_rate;
          }
        },
        dependencies: ['amount', '_parentValues.exchange_rate'],
      },
    },
    credit_notes: {
      // exchange_rate: {
      //   compute: (values, {_parentValues}) => {
      //     if (_parentValues.exchange_rate && values.invoice_amount) {
      //       return _parentValues.exchange_rate;
      //     }
      //   },
      //   dependencies: ['_parentValues.exchange_rate'],
      // },
      base_invoice_amount: {
        compute: values => {
          if (values.invoice_amount && values.exchange_rate) {
            return values.invoice_amount * values.exchange_rate;
          }
        },
        dependencies: ['invoice_amount', 'exchange_rate'],
      },
    },
    advance_details: {
      adjusted_amount: {
        multi: true,
        compute: async value => {
          if (value.invoice_number && value.invoice_number._id) {
            const {data: advanceData} = await fetch({
              uri: '/revenueinvoices',
              props: {
                filter: {
                  _id: value.invoice_number._id,
                  invoice_type: 'a',
                },
                only: true,
              },
            });
            if (advanceData.pending_advance_amount) {
              return {
                adjusted_amount: advanceData.pending_advance_amount,
                conversion_rate: advanceData.exchange_rate,
              };
            }
          }
        },
        dependencies: ['invoice_number'],
      },
      base_adjusted_amount: {
        compute: value => {
          if (value.adjusted_amount && value.conversion_rate) {
            return value.adjusted_amount * value.conversion_rate;
          }
        },
        dependencies: ['adjusted_amount', 'conversion_rate'],
      },
    },
  },
});

const updateTaxAmount = tax => {
  const taxDetails =
    tax &&
    tax.reduce((prev, currentInfo) => {
      const type = currentInfo.tax_type;
      let currentamount = currentInfo.amount;
      if (type && type.name && currentamount) {
        if (prev[type.name]) {
          currentamount += Number(prev[type.name]);
        }
        prev[type.name] = Number(currentamount);
      }
      return prev;
    }, {});
  return taxDetails;
};

export const calculateTaxAmount = (type, amount, info) => {
  if (info && type && type.name === 'TDS' && amount && amount > 0) {
    if (
      info.customer_tds_rate &&
      info.customer_tds_rate.account &&
      info.customer_tds_rate.rate_percent &&
      info.customer_tds_rate.rate_percent > 0
    ) {
      return {
        amount: Number(
          (
            (Number(info.customer_tds_rate.rate_percent) / 100) *
            amount
          ).toFixed(2),
        ),
        rate: Number(info.customer_tds_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'Vat' && amount && amount > 0) {
    if (
      info.customer_vat_rate &&
      info.customer_vat_rate.account &&
      info.customer_vat_rate.rate_percent &&
      info.customer_vat_rate.rate_percent > 0
    ) {
      return {
        amount: Number(
          (
            (Number(info.customer_vat_rate.rate_percent) / 100) *
            amount
          ).toFixed(2),
        ),
        rate: Number(info.customer_vat_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'IGST' && amount && amount > 0) {
    if (
      info.customer_gst_rate &&
      info.customer_gst_rate.igst &&
      info.customer_gst_rate.igst_percent &&
      info.customer_gst_rate.igst_percent > 0
    ) {
      return {
        amount: Number(
          (
            (Number(info.customer_gst_rate.igst_percent) / 100) *
            amount
          ).toFixed(2),
        ),
        rate: Number(info.customer_gst_rate.igst_percent),
      };
    }
  } else if (info && type && type.name === 'CGST' && amount && amount > 0) {
    if (
      info.customer_gst_rate &&
      info.customer_gst_rate.cgst &&
      info.customer_gst_rate.cgst_percent &&
      info.customer_gst_rate.cgst_percent > 0
    ) {
      return {
        amount: Number(
          (
            (Number(info.customer_gst_rate.cgst_percent) / 100) *
            amount
          ).toFixed(2),
        ),
        rate: Number(info.customer_gst_rate.cgst_percent),
      };
    }
  } else if (info && type && type.name === 'SGST' && amount && amount > 0) {
    if (
      info.customer_gst_rate &&
      info.customer_gst_rate.sgst &&
      info.customer_gst_rate.sgst_percent &&
      info.customer_gst_rate.sgst_percent > 0
    ) {
      return {
        amount: Number(
          (
            (Number(info.customer_gst_rate.sgst_percent) / 100) *
            amount
          ).toFixed(2),
        ),
        rate: Number(info.customer_gst_rate.sgst_percent),
      };
    }
  } else {
    return void 0;
  }
};

const InvoiceForm = props => {
  const {
    route: {params},
  } = props;
  const {onSubmit} = useFormSubmit({
    uri: '/revenueinvoices',
    eventSourceId: ['revenue-invoice', 'draft-invoice'],
  });
  const {department, product, row = {}} = params || {};
  const {_id, invoice_date, due_date, ...rest} = row;
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  return (
    <Form
      api={`/revenueInvoices/get-invoice/${row._id}`}
      defaultValues={{
        invoice_type: 'i',
        product: employeeData?.product,
        organization: employeeData?.organization,
        base_currency: employeeData?.organization?.currency,
        currency: employeeData?.organization?.currency,
        invoice_date: getZeroTimeDate(new Date()),
        account_owner_id: {_id: employeeData._id, name: employeeData.name},
        owner: {_id: employeeData._id, name: employeeData.name},
        ...rest,
        draft_invoice: _id,
        status: 'New',
      }}
      computations={{...InvoiceComputations(fetch)}}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Type',
          field: 'invoice_type',
          type: 'radioGroup',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Actual', value: 'i'},
            {label: 'Advance', value: 'a'},
            // {label: 'Credit', value: 'c'},
            // {label: 'Debit', value: 'd'},
          ],
        },
        {
          label: 'Basic',
          fields: [
            {
              placeholder: 'Customer',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              label: 'Customer',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              type: 'date',
              field: 'invoice_date',
              label: 'Invoice Date',
              placeholder: 'Date',
              size: 6,
            },
            {
              type: 'date',
              field: 'due_date',
              label: 'Due Date',
              placeholder: 'Date',
              size: 4,
            },
            {
              field: 'pricing_type',
              placeholder: 'Type',
              label: 'Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              type: 'autoComplete',
              options: [
                {label: 'Fixed', value: 'ot'},
                {label: 'T&M', value: 'r'},
              ],
              size: 6,
              // required: true,
            },
            // {
            //   field: 'total_invoice_amount',
            //   placeholder: 'Total Amount',
            //   label: 'Total Amount',
            //   type: 'number',
            //   size: 4,
            // },
            {
              field: 'exchange_rate',
              placeholder: 'Exchange Rate',
              label: 'Exchange Rate',
              type: 'number',
              required: true,
              size: 6,
            },
            {
              placeholder: 'Currency',
              field: 'currency',
              type: 'autoComplete',
              api: `/currencies`,
              label: 'Currency',
              suggestionField: 'currency',
              valueField: 'currency',
              size: 6,
            },
            {
              field: 'delivery_count',
              placeholder: 'Delivery Count',
              label: 'Delivery Count',
              type: 'number',
              readOnly: true,
              size: 6,
            },
            {
              field: 'follow_advance_sequencing',
              label: 'Follow Advance Sequencing',
              type: 'checkbox',
              size: 4,
              visible: ({values}) => values?.invoice_type === 'i',
              // required: true,
            },
          ],
        },
        // {
        //   field: 'invoice_amount',
        //   placeholder: 'Amount',
        //   label: 'Amount',
        //   type: 'text',
        //   size: 4,
        // },
        // {
        //   field: 'total_tax_amount',
        //   placeholder: 'Total Tax Amount',
        //   label: 'Total Tax Amount',
        //   type: 'text',
        //   size: 4,
        // },
        // {
        //   field: 'advance_amount',
        //   placeholder: 'Less Advance',
        //   label: 'Less Advance',
        //   type: 'text',
        //   size: 4,
        // },
        // {
        //   field: 'base_deduction_amount',
        //   placeholder: 'Less Deduction',
        //   label: 'Less Deduction',
        //   type: 'text',
        //   size: 4,
        // },
        // {
        //   field: 'credit_note_amount',
        //   placeholder: 'Less Credit Note',
        //   label: 'Less Credit Note',
        //   type: 'text',
        //   size: 4,
        // },
        // {
        //   placeholder: 'Currency',
        //   field: 'currency',
        //   type: 'autoComplete',
        //   api: `/currencies`,
        //   label: 'Currency',
        //   suggestionField: 'currency',
        //   valueField: 'currency',
        //   size: 4,
        // },
        // {
        //   placeholder: 'Base Currency',
        //   field: 'base_currency',
        //   type: 'autoComplete',
        //   api: `/currencies`,
        //   label: 'Base Currency',
        //   suggestionField: 'currency',
        //   valueField: 'currency',
        //   size: 4,
        // },
        // {
        //   field: 'delivery_count',
        //   placeholder: 'Delivery Count',
        //   label: 'Delivery Count',
        //   type: 'number',
        //   size: 4,
        // },

        // {
        //   field: 'credit_note',
        //   placeholder: 'Credit Note',
        //   label: 'Credit Note',
        //   type: 'autoComplete',
        //   api: `/revenueinvoices`,
        //   filter: {
        //     invoice_type: 'c',
        //   },
        //   suggestionField: 'invoice_number',
        //   valueField: 'invoice_number',
        //   size: 4,
        // },

        {
          label: 'Invoice Details',
          header: ({values}) => {
            if (values.length) {
              return {count: values?.invoice_details.length};
            }
          },
          fields: [
            {
              field: 'invoice_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  selection={{
                    deleteAction: true,
                  }}
                  columns={[
                    {
                      field: 'delivery_id.delivery_number',
                      header: 'Delivery No',
                      type: 'text',
                      minWidth: 150,
                      placeholder: 'Delivery',
                    },
                    {
                      field: 'account.name',
                      header: 'Account',
                      type: 'text',
                      placeholder: 'Account',
                    },
                    {
                      header: 'Description',
                      field: 'description',
                      minWidth: 150,
                    },
                    {
                      header: 'Quantity',
                      field: 'quantity',
                      type: 'number',
                      formatOptions: {
                        maximumFractionDigits: 2,
                      },
                    },
                    {
                      header: 'Amount',
                      field: 'amount',
                      type: 'number',
                      formatOptions: {
                        maximumFractionDigits: 2,
                      },
                    },
                    {
                      header: 'Tax Amount',
                      field: 'total_tax_amount',
                      type: 'number',
                    },
                    // {
                    //   field: 'account.name',
                    //   header: 'Account',
                    //   type: 'text',
                    //   placeholder: 'Account',
                    // },
                    // {
                    //   header: 'Advance',
                    //   field: 'advance_amount',
                    //   type: 'number',
                    //   width: 100,
                    // },
                    // {
                    //   header: 'Hsn',
                    //   field: 'hsn_sac_code',
                    //   width: 100,
                    // },
                    {
                      innerNested: true,
                      header: 'Tax',
                      label: 'Tax Details',
                      fields: [
                        {
                          type: 'text',
                          field: 'invoice_tax',
                          nested: true,
                          render: props => {
                            return (
                              <NestedTable
                                {...props}
                                columns={[
                                  {
                                    field: 'tax_type.name',
                                    header: 'Tax Type',
                                  },
                                  {
                                    field: 'rate',
                                    header: 'Rate',
                                    type: 'number',
                                  },
                                  {
                                    field: 'amount',
                                    header: 'Amount',
                                    type: 'number',
                                  },
                                ]}
                                fields={[
                                  {
                                    field: 'tax_type',
                                    label: 'Tax Type',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    api: '/taxtypes',
                                    type: 'autoComplete',
                                  },
                                  {
                                    field: 'rate',
                                    label: 'Rate',
                                    type: 'number',
                                  },
                                  {
                                    field: 'amount',
                                    label: 'amount',
                                    type: 'number',
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                      width: 150,
                    },
                    {
                      header: 'Invoice Hrs',
                      field: 'delivered_hours',
                      type: 'number',
                      formatOptions: {
                        maximumFractionDigits: 2,
                      },
                    },
                    // {
                    //   header: 'Adjust',
                    //   field: 'adjust',
                    //   width: 100,
                    //   type: 'checkbox',
                    // },
                    // {
                    //   header: 'Is Sevice',
                    //   field: 'is_serviceable',
                    //   width: 100,
                    //   type: 'checkbox',
                    // },
                  ]}
                  fields={[
                    {
                      field: 'delivery_id',
                      header: 'Delivery',
                      type: 'autoComplete',
                      api: `/projectresourceworkingdata`,
                      suggestionField: 'delivery_number',
                      valueField: 'delivery_number',
                      placeholder: 'Delivery Id',
                    },
                    {
                      field: 'account',
                      api: '/accounts',
                      type: 'autoComplete',
                      valueField: 'name',
                      suggestionField: 'name',
                      label: 'Account',
                    },
                    {
                      type: 'textArea',
                      placeholder: 'Description',
                      field: 'description',
                      header: 'Description',
                    },
                    {
                      type: 'number',
                      placeholder: 'Quantity',
                      field: 'quantity',
                      header: 'Quantity',
                    },
                    {
                      type: 'number',
                      placeholder: 'Amount',
                      field: 'amount',
                      header: 'Amount',
                      formatOptions: {
                        maximumFractionDigits: 2,
                      },
                    },
                    // {
                    //   type: 'text',
                    //   placeholder: 'HSN',
                    //   field: 'hsn_sac_code',
                    //   header: 'HSN',
                    // },
                    // {
                    //   type: 'number',
                    //   placeholder: 'Total Tax',
                    //   field: 'total_tax_amount',
                    //   header: 'Total Tax',
                    // },

                    // {
                    //   type: 'number',
                    //   placeholder: 'Vat',
                    //   field: 'vat_amount',
                    //   header: 'Vat',
                    // },
                    // {
                    //   type: 'number',
                    //   placeholder: 'ServiceTax',
                    //   field: 'servicetax_amount',
                    //   header: 'ServiceTax',
                    // },
                    // {
                    //   type: 'number',
                    //   placeholder: 'Igst',
                    //   field: 'igst_amount',
                    //   header: 'Igst',
                    // },
                    // {
                    //   type: 'number',
                    //   placeholder: 'Sgst',
                    //   field: 'sgst_amount',
                    //   header: 'Sgst',
                    // },
                    // {
                    //   type: 'number',
                    //   placeholder: 'Cgst',
                    //   field: 'cgst_amount',
                    //   header: 'Cgst',
                    // },
                    // {
                    //   type: 'checkbox',
                    //   field: 'adjust',
                    //   header: 'Adjust',
                    //   label: 'Adjust',
                    //   size: 6,
                    // },
                    // {
                    //   type: 'checkbox',
                    //   field: 'is_serviceable',
                    //   header: 'Is Service',
                    //   label: 'Is Service',
                    //   size: 6,
                    // },
                  ]}
                />
              ),
            },
          ],
        },
        {
          render: (_, props) => {
            return <InvoiceAmountSummary {...props} />;
          },
        },

        {
          label: 'Accounting',
          fields: [
            {
              label: 'Organization',
              placeholder: 'Organization',
              field: 'organization',
              type: 'autoComplete',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Product',
              placeholder: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
            {
              label: 'Tax Location',
              placeholder: 'Tax Location',
              field: 'location',
              type: 'autoComplete',
              api: `/offices`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
            {
              field: 'account_owner_id',
              type: 'autoComplete',
              label: 'Account Owner',
              placeholder: 'Account Owner',
              size: 4,
              api: '/employeeSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
            },
            {
              field: 'hsn_no',
              placeholder: 'HSN',
              type: 'text',
              label: 'HSN',
              size: 4,
            },
            {
              field: 'hsn_description',
              placeholder: 'HSN Description',
              type: 'text',
              label: 'HSN Description',
              size: 4,
            },
            {
              field: 'place_of_supply',
              placeholder: 'Place of supply',
              type: 'text',
              label: 'Place of supply',
              size: 4,
              required: true,
            },
            {
              label: 'Type Of Supply',
              type: 'autoComplete',
              field: 'type_of_supply',
              options: ['B2B', 'B2C', 'SEZWOP', 'Credit Note'],
              placeholder: 'Type Of Supply',
              size: 4,
              required: true,
            },
            {
              field: 'delivery_address',
              type: 'autoComplete',
              api: `/customer/delivery-address`,
              params: ({values: {customer} = {}}) => customer,
              size: 4,
              placeholder: 'Delivery Address',
              label: 'Delivery Address',
              valueField: 'name',
              suggestionField: 'name',
            },
            {
              label: 'Invoice Category',
              type: 'autoComplete',
              field: 'invoice_category',
              options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
              placeholder: 'Invoice Category',
              size: 4,
            },
          ],
        },
        {
          fields: [
            {
              field: 'softex_number',
              placeholder: 'Softex No.',
              label: 'Softex No.',
              type: 'text',
              size: 4,
            },
            {
              placeholder: 'Service Month',
              field: 'service_month',
              type: 'autoComplete',
              api: `/monthtypes`,
              label: 'Service Month',
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
            {
              placeholder: 'Service Year',
              field: 'service_year',
              type: 'autoComplete',
              api: `/yeartypes`,
              label: 'Service Year',
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
            },
          ],
        },
        // {
        //   label: 'Advance Details',
        //   fields: [
        //     {
        //       field: 'advance_details',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'invoice_number.invoice_number',
        //               header: 'Invoice Number',
        //               suggestionField: 'name',
        //               valueField: 'name',
        //               api: '/revenueinvoices',
        //               filter: {
        //                 invoice_type: 'a',
        //                 pending_advance_amount: {$gt: 0},
        //               },
        //               type: 'autoComplete',
        //             },
        //             {
        //               field: 'adjusted_amount',
        //               header: 'Adjusted Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'conversion_rate',
        //               header: 'Conversion Rate',
        //               type: 'number',
        //               width: 200,
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'invoice_number',
        //               label: 'Invoice',
        //               suggestionField: 'invoice_number',
        //               valueField: 'invoice_number',
        //               api: '/revenueinvoices',
        //               filter: {
        //                 invoice_type: 'a',
        //                 pending_advance_amount: {$gt: 0},
        //               },
        //               type: 'autoComplete',
        //             },
        //             {
        //               field: 'adjusted_amount',
        //               label: 'Adjusted Amount',
        //               type: 'number',
        //             },
        //             {
        //               field: 'conversion_rate',
        //               label: 'Conversion Rate',
        //               type: 'number',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'Deduction Details',
        //   fields: [
        //     {
        //       field: 'deduction',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'account.name',
        //               header: 'Account',
        //               suggestionField: 'name',
        //               valueField: 'name',
        //               api: '/accounts',
        //               type: 'autoComplete',
        //             },
        //             {
        //               field: 'amount',
        //               header: 'Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'base_amount',
        //               header: 'Base Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'account',
        //               label: 'Account',
        //               suggestionField: 'name',
        //               valueField: 'name',
        //               api: '/accounts',
        //               type: 'autoComplete',
        //             },
        //             {
        //               field: 'amount',
        //               label: 'Amount',
        //               type: 'number',
        //             },
        //             {
        //               field: 'base_amount',
        //               label: 'Base Amount',
        //               type: 'number',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        // {
        //   label: 'Credit Note',
        //   fields: [
        //     {
        //       field: 'credit_notes',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           columns={[
        //             {
        //               field: 'invoice.invoice_number',
        //               header: 'Invoice',
        //               type: 'autoComplete',
        //               api: '/revenueinvoices',
        //               suggestionField: 'name',
        //               valueField: 'name',
        //             },
        //             {
        //               field: 'invoice_amount',
        //               header: 'Invoice Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               type: 'number',
        //               placeholder: 'Exchange Rate',
        //               field: 'exchange_rate',
        //               header: 'Exchange Rate',
        //               width: 200,
        //             },
        //           ]}
        //           fields={[
        //             {
        //               field: 'invoice',
        //               label: 'Invoice',
        //               type: 'autoComplete',
        //               api: '/revenueinvoices',
        //               filter: {
        //                 invoice_type: 'c',
        //                 pending_receipt_amount: {$lte: -1},
        //               },
        //               suggestionField: 'invoice_number',
        //               valueField: 'invoice_number',
        //             },
        //             {
        //               field: 'invoice_amount',
        //               label: 'Invoice Amount',
        //               type: 'number',
        //             },
        //             {
        //               type: 'number',
        //               placeholder: 'Exchange Rate',
        //               field: 'exchange_rate',
        //               label: 'Exchange Rate',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

export const NonEditableRevenueInvoice = props => {
  return (
    <InvoiceForm
      mode="edit"
      header={{
        title: 'Invoice Detail',
        secondaryTitle: props?.route?.params?.row?.invoice_number || '',
      }}
      readOnly={true}
      {...props}
    />
  );
};

export const EditableRevenueInvoice = props => {
  return (
    <InvoiceForm
      mode="edit"
      header={{
        title: 'Update Invoice',
        secondaryTitle: props?.route?.params?.row?.invoice_number || '',
      }}
      {...props}
    />
  );
};

export const CreateRevenueInvoice = props => {
  return <InvoiceForm header="Create Invoice" {...props} />;
};

const AdjustInvoice = props => {
  const {
    route: {params},
    api,
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/revenueInvoices/adjust-invoice',
    eventSourceId: ['revenue-invoice'],
  });
  let response = {};
  if (row.invoice_type === 'a' && !row?.advanceInvoice) {
    response.other_amount = row.pending_advance_amount;
  }
  if (row?.advanceInvoice) {
    response.advanceInvoiceReceipt = true;
  }
  return (
    <Form
      api={api}
      onSubmit={onSubmit}
      eventSourceId="revenue-invoice"
      submitAction="Save"
      defaultValues={{
        invoice: row._id,
        invoice_date: row.invoice_date,
        clinet_account: row.clinet_account,
        invoice_type: 'c',
        other_amount: row.pending_receipt_amount,
        ...response,
      }}
      layoutFields={[
        {
          label: 'Invoice Type',
          field: 'invoice_type',
          type: 'radioGroup',
          valueField: 'label',
          keyField: 'value',
          options: [
            {label: 'Credit', value: 'c'},
            {label: 'Bad Debt', value: 'b'},
          ],
        },
        {
          type: 'date',
          field: 'invoice_date',
          placeholder: 'Date',
          label: 'Date',
        },
        {
          type: 'number',
          field: 'other_amount',
          placeholder: 'Amount',
          label: 'Amount',
        },
        {
          type: 'autoComplete',
          field: 'clinet_account',
          suggestionField: 'name',
          valueField: 'name',
          api: '/accounts',
          label: 'Client Account',
          placeholder: 'Select',
        },
      ]}
      {...props}
    />
  );
};

export const AdjustInvoiceForm = props => {
  return (
    <AdjustInvoice
      header="Adjust Invoice"
      api={`/revenueInvoices/get-invoice`}
      {...props}
    />
  );
};

export const MarkIsServiceInvoice = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/revenueInvoices',
    eventSourceId: ['revenue-invoice'],
  });

  return (
    <Form
      {...props}
      api={`/revenueInvoices/get-invoice/${row?._id}`}
      fields={{is_service_invoice: 1}}
      mode="edit"
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'is_service_invoice',
          required: true,
          options: [
            {value: true, label: 'Yes'},
            {value: false, label: 'No'},
          ],
        },
      ]}
    />
  );
};

export const MarkIsServiceInvoiceForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkIsServiceInvoice
      header={{
        title: 'Mark Is Service Invoice',
      }}
      {...props}
    />
  );
};

const DownloadInvoice = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {download} = useAppStateContext();
  return (
    <Form
      onSubmit={props => {
        download({
          uri: `/revenueInvoices/download-invoice/${row._id}`,
          props: {
            add_bank_details_in_pdf: props.add_bank_details_in_pdf,
            show_hours: props.show_hours || false,
          },
        });
      }}
      eventSourceId="revenue-invoice"
      submitAction="Save"
      defaultValues={{
        _selectedIds_: [row._id],
        ...row,
        add_bank_details_in_pdf: true,
        show_hours: true,
      }}
      layoutFields={[
        {
          field: 'add_bank_details_in_pdf',
          label: 'Add Bank Details',
          type: 'switch',
          size: 6,
        },
        {
          field: 'show_hours',
          label: 'Show Hours',
          type: 'switch',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const DownloadInvoicePDF = props => {
  return <DownloadInvoice header="Download Invoice" {...props} />;
};

const SubmissionDateForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/revenueinvoices`,
    eventSourceId: ['revenue-invoice'],
  });
  return (
    <Form
      api={`/revenueInvoices/get-invoice/${row._id}`}
      onSubmit={onSubmit}
      mode="edit"
      eventSourceId="revenue-invoice"
      submitAction="Save"
      layoutFields={[
        {
          field: 'invoice_submission_date',
          type: 'date',
          placeholder: 'Invoice Submission Date',
          label: 'Invoice Submission Date',
        },
      ]}
      {...props}
    />
  );
};

export const UpdateInvoiceSubmissionDateForm = props => {
  return (
    <SubmissionDateForm header="Update Invoice Submission Date" {...props} />
  );
};

const SoftexDetailForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: `/revenueinvoices`,
    eventSourceId: ['revenue-invoice'],
  });
  return (
    <Form
      api={`/revenueInvoices/get-invoice/${row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      eventSourceId="revenue-invoice"
      submitAction="Save"
      layoutFields={[
        {
          type: 'text',
          field: 'softex_number',
          placeholder: 'Softex Number',
          label: 'Softex Number',
        },
        {
          type: 'date',
          field: 'softex_date',
          placeholder: 'Softex Date',
          label: 'Softex Date',
        },
      ]}
      {...props}
    />
  );
};
export const UpdateSoftexDetailsForm = props => {
  return <SoftexDetailForm header="Update Softex Details" {...props} />;
};

const SettleInvoiceForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {user} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    uri: `/revenueinvoices`,
    eventSourceId: ['revenue-invoice'],
  });
  return (
    <Form
      api={`/revenueInvoices/get-invoice/${row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const changes = {
          adjusted_by: user?.employee?._id,
        };
        if (data.adjust_type === 'Settle') {
          changes['pending_receipt_amount'] = 0;
          changes['base_pending_receipt_amount'] = 0;
        }
        return {
          data: {
            ...data,
            ...changes,
          },
        };
      }}
      eventSourceId="revenue-invoice"
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'adjust_type',
          options: ['Settle', 'Disputed', 'Legal'],
          placeholder: 'Select Type',
          label: 'Adjust Type',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
export const SettleInvoice = props => {
  return <SettleInvoiceForm header="Settle Invoice" {...props} />;
};
