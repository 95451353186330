import React from 'react';
import {DownloadButtonTheme} from '../../../components/button/theme';
import {SearchFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';

const getDateToStringNew = date => {
  if (!date) {
    date = new Date();
  }
  let mm = new Date(date).getMonth() + 1;
  let dd = new Date(date).getDate();
  return [
    new Date(date).getFullYear() + '-',
    (mm > 10 ? '' : '0') + mm + '-',
    (dd > 9 ? '' : '0') + dd,
  ].join('');
};

export const BankTables = props => {
  const {navigation} = props;
  const { filter, onChangeFilter, searchValue} = useFilter();
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'Banks',
  });
  return (
    <Table
      eventSourceId={['Banks']}
      api={`/bank`}
      renderHeader={() => (
        <TableHeader
          title="Banks"
          actions={
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />
          }
        />
      )}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-banks`, {
          row,
        });
      }}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
          minWidth: 400,
        },
        {
          header: 'Account Name',
          field: 'account_id.name',
          type: 'text',
          width: 400,
        },
        {
          header: 'Effective Balance and Date',
          type: 'text',
          width: 300,
          render: ({row, styles}) => {
            let balance = row?.effective_balance || '';
            let time = row?.time || ' ';
            let resultDate = (row?.date && getDateToStringNew(row.date)) || '';
            let effectivebalance =
              (resultDate && time && `${balance} (${resultDate} ${time})`) ||
              '';
            if (!effectivebalance) return '';
            return effectivebalance;
          },
        },
      ]}
      moreActions={[
        {
          title: 'Check Balance',
          onPress: ({row}) => {
            invoke({
              uri: `/checkBalance`,
              props: {
                accountId: row?._id,
              },
            });
          },
        },
        {
          title: 'Download Statement of Account',
          onPress: ({row}) => {
            navigation.navigate('download-statement-of-account', {row});
          },
        },
      ]}
    />
  );
};
