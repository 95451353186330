import React from 'react';
import {useComponentTheme, useTheme} from '@unthinkable/react-theme';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useToast} from '@unthinkable/react-toast';

import AddCountCellRender from '../../../../app-components/renders/AddCountCellRender';
import {useInvoke} from '../../../../controllers/useInvoke';
import {useAppStateContext} from '../../../../providers/AppState';
import {DesignUploadCellRender} from '../../cellrenders/DesignUploadCellRender';
import Images from '../../../../images';
import {FeatureAssetHeader, FeatureAssetSummary} from '../../headers/Header';
import {
  AddButton,
  BorderLessButton,
} from '../../../../components/button/AddButton';
import {useSplitScreen} from '../../../../components/SplitScreen';
import PlusMultipleTextRender from '../../cellrenders/PlusMultipleTextRender';
import {
  LOCOMO_PROJECT_ID,
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID,
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_QA_TEST_CASE_TYPE_ID,
  PROJECT_THEME_TYPE_ID,
  PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  PROJECT_VIEW_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {hasValue} from '@unthinkable/react-utils';
import {UnderlineButton} from '../../../../components/button/UnderlineButton';
import {CountPlusRender} from '../../../../app-components/renders/CountPlusRender';
import {Labels} from '../../constants/Label';
import {Chip} from '../../../../components/chip/Chip';
import moment from 'moment';

const {DownloadBlue, Owner, Code} = Images;

export const assetMappings = {
  [PROJECT_VIEW_TYPE_ID]: {
    sourceField: 'view',
    model: 'projectviews',
    eventSourceId: ['View', 'ProjectAI'],
    requiredFields: [{value: 'view', label: 'Name'}],
    displayName: 'screen',
  },
  [PROJECT_COMPONENT_TYPE_ID]: {
    sourceField: 'component',
    displayName: 'component',
    model: 'projectComponents',
    eventSourceId: 'Component',
    requiredFields: [{value: 'component', label: 'Component'}],
  },
  [PROJECT_DATA_SERVICE_LIBRARY_TYPE_ID]: {
    sourceField: 'name',
    displayName: 'name',
    model: 'projectDataServiceLibraries',
    eventSourceId: 'DataServiceLibrary',
    requiredFields: [{value: 'name', label: 'Name'}],
  },
  [PROJECT_THEME_TYPE_ID]: {
    sourceField: 'name',
    displayName: 'name',
    model: 'projectthemes',
    eventSourceId: 'theme',
    requiredFields: [{value: 'name', label: 'Name'}],
  },
  [PROJECT_MODEL_TYPE_ID]: {
    sourceField: 'model',
    displayName: 'model',
    model: 'projectModels',
    eventSourceId: ['Model', 'DataEntityAi'],
    requiredFields: [{value: 'model', label: 'Model'}],
  },
  [PROJECT_CONTROLLER_TYPE_ID]: {
    sourceField: 'controller',
    displayName: 'api',
    model: 'projectcontrollers',
    eventSourceId: 'Controller',
    requiredFields: [
      {value: 'controller', label: 'Name'},
      {value: 'uri', label: 'Path'},
      {value: 'method', label: 'Method'},
    ],
  },
  [PROJECT_DATA_SERVICE_TYPE_ID]: {
    sourceField: 'name',
    displayName: 'service',
    model: 'projectdataservices',
    eventSourceId: ['DataService'],
    requiredFields: [{value: 'name', label: 'Name'}],
  },
  [PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID]: {
    sourceField: 'name',
    displayName: 'integration',
    model: 'projectThirdPartyIntegrations',
    eventSourceId: 'ThirdPartyIntegration',
    requiredFields: [{value: 'name', label: 'Name'}],
  },
  [PROJECT_QA_TEST_CASE_TYPE_ID]: {
    sourceField: 'name',
    displayName: 'test case',
    model: 'qatestcases',
    eventSourceId: 'TestCase',
    requiredFields: [{value: 'name', label: 'Title'}],
  },
};

export const useAssetActions = ({
  navigation,
  params,
  feature_id,
  source,
  fromReleased,
  ...props
}) => {
  const {feature} = params;
  const assetMapping = assetMappings[source] || {};
  const {
    model = assetMapping.model,
    sourceField = assetMapping.sourceField,
    eventSourceId = assetMapping.eventSourceId,
  } = props;

  const {toggleSplitScreen} = useSplitScreen();

  const putInvoke = useInvoke({
    method: 'put',
    eventSourceId,
  });

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: eventSourceId,
  });

  const tasks = ({row}) => {
    navigation.navigate(`feature-tasks-list`, {
      ...params,
      source,
      source_id: row?.source_id?._id || row?.source_id,
      feature_id,
      row,
      title: row?.[sourceField],
      fromFeatureDetail: true,
      afterSubmit: ({data}) => {
        if (row?._id && !row?.owner_id && data?.assigned_to?._id)
          putInvoke({
            uri: `/projectAssetVersions/${row?.versionId}`,
            props: {
              owner_id: data.assigned_to._id,
            },
          });
      },
    });
  };

  const removeFromFeature = {
    title: `Remove from ${Labels.Feature}`,
    variant: 'error',
    confirm: {
      title: 'Remove',
      message: `Are you sure you want to remove this from the ${Labels.Feature}?`,
    },
    onPress: ({row}) => {
      deleteInvoke({
        uri: `/projectAssetVersions/${row?.versionId}/delete`,
        props: {
          source_id: row?.source_id?._id || row?.source_id,
          source: row?.source,
          feature_id: feature_id,
        },
      });
    },
  };

  const generateCode = {
    title: `Sync Code`,
    confirm: {
      title: 'Sync Code',
      message: `Are you sure you want to generate code for this?`,
    },
    onPress: ({row}) => {
      const source = row?.source;
      const source_id = row?.source_id?._id || row?.source_id;
      putInvoke({
        uri: `/projects/${params?.project?._id}/assets/${source}/source/${source_id}/generate-code`,
        props: {
          feature_id,
        },
      });
    },
  };

  const addDesign = ({row}) => {
    navigation.navigate('add-asset-design', {
      ...params,
      project_id: row?.project_id,
      secondaryTitle: row?.[sourceField],
      source,
      row,
    });
  };

  const viewDesign = ({row}) => {
    navigation.navigate('asset-designs-viewer', {
      ...params,
      history_id: row?.versionId,
      source_id: row?.source_id,
      [sourceField]: row?.[sourceField],
      currentFeatureDesign: row.feature_design,
    });
  };

  const versions = {
    title: 'View Versions',
    onPress: ({row}) => {
      navigation.navigate('asset-version', {
        ...params,
        row,
        source_id: row?.source_id || row?._id,
        fromReleased,
        type_id: source,
      });
    },
  };

  const assetDetail = ({row, rowIndex, readOnly}) => {
    toggleSplitScreen({
      ...params,
      row,
      rowIndex,
      [sourceField]: row?.[sourceField],
      readOnly:
        readOnly !== undefined
          ? readOnly
          : !feature || feature.status === 'released',
    });
  };

  const mergeReferences = ({targetApi}) => ({
    title: 'Merge References',
    visible: () => !feature,
    onPress: props => {
      const {row} = props;
      const addOnFilter = {};
      if (params?.project?._id?.toString() !== LOCOMO_PROJECT_ID) {
        // locomo project id
        addOnFilter.status = 'released';
      }
      navigation.navigate(`merge-detail`, {
        ...params,
        source: {_id: row?._id},
        title: 'Merge References',
        secondaryTitle: row?.[sourceField],
        targetField: {
          api: targetApi,
          suggestionField: sourceField,
          valueField: sourceField,
          filter: {
            project_id: params?.project?._id,
            _id: {$nin: [row?._id]},
            ...addOnFilter,
            // status: 'released',
          },
        },
        uri: `/assets/${source}/merge`,
        eventSourceId,
      });
    },
  });

  const changeModule = {
    title: 'Update Module',
    onPress: ({row}) => {
      navigation.navigate('change-module', {
        ...params,
        row,
        api: `/${model}`,
        secondaryTitle: row[sourceField],
      });
    },
  };

  const changeDatabase = {
    title: 'Update Database Name',
    onPress: ({row}) => {
      navigation.navigate('change-database-name', {
        ...params,
        row,
      });
    },
  };

  const changeDataEntity = {
    title: 'Update Model',
    onPress: ({row}) => {
      navigation.navigate('change-data-entity', {
        ...params,
        row,
        api: `/${model}`,
        secondaryTitle: row[sourceField],
      });
    },
  };

  const changeAPI = {
    title: 'Update API',
    onPress: ({row}) => {
      navigation.navigate('change-api', {
        ...params,
        row,
        api: `/${model}`,
        secondaryTitle: row[sourceField],
      });
    },
  };

  const deleteAsset = {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete Permanently',
      message: `This will permanently delete the item and its associated data. This action can not be undone. Are you sure you want to proceed?`,
    },
    onPress: ({row}) => {
      const deleteId = row?.source_id || row?._id;
      deleteInvoke({
        uri: `/asset/${source}/${deleteId}/delete`,
        props: {},
      });
    },
    visible: !feature,
  };

  const deleteMultipleAssets = {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete Permanently',
      message: `This will permanently delete all the items and its associated data. This action can not be undone. Are you sure you want to proceed?`,
    },
    onPress: ({selectedIds, resetSelection}) => {
      deleteInvoke({
        uri: `/asset/${source}/batchRemove`,
        props: {
          selectedIds,
        },
      });
      resetSelection?.();
    },
  };

  const updateModuleMultipleAssets = {
    title: 'Update Module',
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate('change-module', {
        ...params,
        api: `/${model}`,
        selectedIds,
      });
      resetSelection?.();
    },
  };

  const updateDatabaseMultipleAssets = {
    title: 'Update Database Name',
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate('change-database-name', {
        ...params,
        selectedIds,
      });
      resetSelection?.();
    },
  };

  const updateDataEntityMultipleAssets = {
    title: 'Update Model',
    onPress: ({selectedIds, resetSelection}) => {
      navigation.navigate('change-data-entity', {
        ...params,
        api: `/${model}`,
        selectedIds,
      });
      resetSelection?.();
    },
  };

  const copyTestcase = {
    title: 'Copy Testcase',
    onPress: ({row}) => {
      navigation.navigate('copy-qa-test-case', {
        ...params,
        testCase: row,
      });
    },
    visible: () => feature,
  };

  return {
    assetDetail,
    tasks,
    removeFromFeature,
    generateCode,
    addDesign,
    viewDesign,
    versions,
    mergeReferences,
    changeModule,
    changeDatabase,
    changeDataEntity,
    changeAPI,
    deleteAsset,
    deleteMultipleAssets,
    updateModuleMultipleAssets,
    updateDatabaseMultipleAssets,
    updateDataEntityMultipleAssets,
    copyTestcase,
  };
};

export const featureAssetColumns = ({
  navigation,
  params,
  api,
  source,
  ...props
} = {}) => {
  const {feature} = params || {};

  const fonts = useTheme('fonts');
  const {colors} = useTheme();

  const assetMapping = assetMappings[source] || {};
  const {displayName} = assetMapping;
  const {model = assetMapping.model, sourceField = assetMapping.sourceField} =
    props;

  return {
    tasks: ({onPress}) => {
      return {
        render: AddCountCellRender,
        width: 90,
        header: 'Tasks',
        align: 'right',
        totalCountField: 'feature_task_count',
        completedCountField: 'feature_completed_task_count',
        onPress,
      };
    },
    dataEntity: {
      type: 'colorTag',
      field: 'model_id.model',
      colorField: 'model_id.color',
      header: 'Model',
      width: 150,
    },
    multipleApis: {
      render: PlusMultipleTextRender,
      header: 'API',
      columnField: 'controller_ids',
      valueField: 'controller',
      align: 'center',
      readOnly: true,
      width: 200,
      onPress: ({row}) => {
        navigation.navigate('change-api', {
          ...params,
          row,
          api: `/${model}`,
          secondaryTitle: row[sourceField],
          readOnly: true,
          title: 'APIs',
        });
      },
    },
    linked_to: {
      render: PlusMultipleTextRender,
      width: 150,
      header: 'Linked to',
      columnField: 'linked_to_screens',
      valueField: 'view',
      type: 'text',
      align: 'center',
      readOnly: feature?.status === 'released',
      onPress: ({row}) => {
        navigation.navigate('map-screen', {
          ...params,
          ...row,
          secondaryTitle: row?.view,
          linked_to_screens: row?.linked_to_screens,
          readOnly: feature?.status === 'released',
        });
      },
    },
    designs: ({onUpload, onView}) => {
      return {
        width: 100,
        header: 'Designs',
        render: ({row}) => {
          const {colors, icons} = useTheme();
          let {addPlus, iconStyle, iconContainerStyle} = useComponentTheme(
            'DesignCellRenderStyles',
          );
          const {isDesignUpdated} = row;

          let textColor;
          if (isDesignUpdated) {
            textColor = colors.INFORMATION_HIGH;
          }
          const designCount =
            (row.desktop_attachments?.length || 0) +
            (row.mobile_attachments?.length || 0) +
            (row.tab_attachments?.length || 0);

          return (
            <Row>
              <DesignUploadCellRender
                row={row}
                value={designCount}
                onUpload={onUpload}
                onView={onView}
                uploading={false}
                textColor={isDesignUpdated ? colors.INFORMATION_HIGH : void 0}
                icon={isDesignUpdated ? icons.BrushIcon : icons.BrushGrey}
              />
              {feature?.status !== 'released' && !isDesignUpdated && (
                <TouchableOpacity
                  onPress={() => {
                    onUpload({row});
                  }}
                  style={iconContainerStyle}>
                  <Image source={addPlus} style={iconStyle} />
                </TouchableOpacity>
              )}
            </Row>
          );
        },
        align: 'center',
      };
    },
    est_hrs: {
      header: 'Est.',
      field: 'feature_est_hrs',
      type: 'duration',
      width: 70,
      align: 'right',
    },
    testcase_status: {
      render: ({row}) => {
        let {execution_status, error_message, last_executed_on} = row;
        if (!execution_status) {
          return void 0;
        }
        execution_status = execution_status.toString().toLowerCase();
        return (
          <Col style={{alignItems: 'flex-start'}} gap={12}>
            <Chip
              displayTextColor
              value={execution_status === 'pass' ? 'Pass' : 'Fail'}
              color={execution_status === 'pass' ? 'success' : 'error'}
            />
            {last_executed_on ? (
              <Text
                title={error_message}
                style={{...fonts.CAPTION_LARGE, color: colors.NEUTRAL_LOW}}>
                {moment(last_executed_on).fromNow()}
              </Text>
            ) : (
              void 0
            )}
          </Col>
        );
      },
      header: 'Status',
      width: 100,
    },
    userstory_count: {
      render: CountPlusRender,
      count_field: 'user_story_ids',
      header: 'User Stories',
      align: 'center',
      color: colors.INFORMATION_HIGH,
      width: 100,
      onPress: ({row}) => {
        navigation.navigate('all-project-userstory-list', {
          ...params,
          asset: row,
          api,
          assetName: row[sourceField],
          assetType: displayName,
        });
      },
      onPlusPress: ({row}) => {
        navigation.navigate('map-userstories', {
          ...params,
          asset: row,
          api,
          assetName: row[sourceField],
          assetType: displayName,
        });
      },
    },
    api_count: {
      type: 'text',
      render: ({row}) => {
        const {api_count} = row;
        return (
          <Chip
            value={api_count || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'APIs',
      align: 'right',
      color: colors.INFORMATION_HIGH,
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('all-project-api-list', {
          ...params,
          dataEntity: row,
        });
      },
    },
    model_view_count: {
      type: 'text',
      render: ({row}) => {
        const {model_view_count} = row;
        return (
          <Chip
            value={model_view_count || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'Views',
      align: 'right',
      color: colors.INFORMATION_HIGH,
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('project-model-view-list', {
          ...params,
          dataEntity: row,
        });
      },
    },
    api_services_count: {
      type: 'text',
      render: ({row}) => {
        const {api_services_count} = row;
        return (
          <Chip
            value={api_services_count || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'Services',
      align: 'right',
      color: colors.INFORMATION_HIGH,
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('all-project-data-service-list', {
          ...params,
          apiEndpoint: row,
          paramsFilter: {
            controller_ids: {_id: row._id, controller: row.controller},
          },
          title: `API: ${row.controller}`,
          referenceAsset: 'api',
        });
      },
    },
    data_service_count: {
      type: 'text',
      field: 'data_service_count',
      render: ({row}) => {
        const {data_service_count} = row;
        return (
          <Chip
            value={data_service_count || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'Services',
      align: 'right',
      width: 80,
      color: colors.INFORMATION_HIGH,
      onPress: ({row}) => {
        navigation.navigate('all-project-data-service-list', {
          ...params,
          dataEntity: row,
          paramsFilter: {model_id: {_id: row._id, model: row.model}},
          referenceAsset: 'model',
        });
      },
    },
    assignees: {
      header: 'Assignees',
      field: 'feature_assignees',
      type: 'userAvatarGroup',
      width: 100,
      align: 'center',
      maxAvatars: 3,
    },
    code_editor: {
      render: ({row}) => {
        const {folder_path_id, file_name, controller} = row;
        if (!folder_path_id || !file_name) {
          return null;
        }
        return (
          <TouchableOpacity
            title={'View File'}
            style={{
              margin: -8,
              padding: 8,
            }}
            onPress={() => {
              navigation.navigate('code-editor', {
                ...params,
                folder_path_id,
                file_name: file_name?.name || controller,
              });
            }}>
            <Image
              source={Code}
              style={{
                height: 16,
                width: 16,
              }}
            />
          </TouchableOpacity>
        );
      },
      width: 40,
    },
    modelView: {
      header: 'Views',
      color: colors.INFORMATION_HIGH,
      align: 'center',
      render: ({row}) => {
        return (
          <Chip
            value={row?.model_view_ids?.length || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('screen-model-view', {
          ...params,
          row,
        });
      },
    },
    api: {
      header: 'APIs',
      color: colors.INFORMATION_HIGH,
      align: 'center',
      render: ({row}) => {
        return (
          <Chip
            value={row?.controller_ids?.length || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('screen-apis', {
          ...params,
          row,
        });
      },
    },
  };
};

export const ExportExcelAction = ({
  text = 'Export',
  selectedIds,
  params,
  onPress,
}) => {
  const {download} = useAppStateContext();
  params = {
    ...params,
  };

  if (selectedIds?.length) {
    params.ids = selectedIds;
  }

  return (
    <BorderLessButton
      icon={DownloadBlue}
      text={text}
      onPress={e => {
        if (onPress) {
          onPress(e, {params});
        } else {
          download({
            uri: `/generateExcel`,
            props: params,
          });
        }
      }}
    />
  );
};

export const FeatureAssetHeaderRow = ({
  feature,
  assetId,
  assetInlineForm,
  exportParams,
  selectedIds,
  api,
  showImportData,
  importSampleId,
  ...props
}) => {
  const toast = useToast();
  const {data, navigation, route: {params} = {}} = props;
  const featureId = feature?._id;
  const projectId = feature?.project_id?._id;

  const sourceIds = data?.map(row => row?.source_id);

  const postInvoke = useInvoke({
    method: 'post',
    throwError: true,
  });

  return (
    <FeatureAssetHeader
      inlineForm={
        assetInlineForm && feature?.status !== 'released' ? (
          <View style={{flex: 1, overflow: 'hidden', maxWidth: 500}}>
            {React.cloneElement(assetInlineForm, {sourceIds})}
          </View>
        ) : (
          void 0
        )
      }
      rightActions={[
        featureId ? (
          <FeatureAssetSummary
            api={`/features/${featureId}/assets/${assetId}/asset-summary`}
            {...props}
          />
        ) : (
          void 0
        ),
        (showImportData || importSampleId) && (
          <BorderLessButton
            onPress={() => {
              navigation.navigate('project-import-data', {
                ...params,
                source: assetId,
                sampleId: importSampleId,
              });
            }}
            text={'Import'}
          />
        ),
        <ExportExcelAction params={exportParams} selectedIds={selectedIds} />,
        PROJECT_QA_TEST_CASE_TYPE_ID === assetId && [
          <AddButton
            onPress={async () => {
              try {
                await postInvoke({
                  uri: `/projects/${projectId}/features/${featureId}/requestExecution`,
                });
              } catch (err) {
                toast({message: err.message, type: 'Error'});
              }
            }}
            text={'Execute'}
          />,
          <AssetAddButton
            title={'TestCases'}
            view={'add-qa-test-case'}
            params={params}
          />,
        ],
      ]}
    />
  );
};

export const AssetAddButton = ({params, ...props}) => {
  const {feature} = params;
  if (!feature || feature.status === 'released') {
    return null;
  }
  return <AddButton params={params} {...props} />;
};

export const assetFilters = ({project, module_id}) => {
  return {
    module: {
      type: 'autoComplete',
      label: 'Module',
      api: '/projectmodules',
      placeholder: 'Select Module',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      filter: {
        project_id: project?._id,
      },
      visible: () => !module_id,
    },
    owner: {
      type: 'autoComplete',
      label: 'Owner',
      api: `/projects/${project?._id}/members`,
      placeholder: 'Select Owner',
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      valueField: 'name',
      field: 'owner_id',
      avatar: true,
    },
    design_owner: {
      type: 'autoComplete',
      label: 'Design Owner',
      api: `/projects/${project?._id}/members`,
      placeholder: 'Select Owner',
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      valueField: 'name',
      field: 'design_owner_id',
      avatar: true,
    },
    dataEntity: {
      type: 'autoComplete',
      label: 'Model',
      api: '/projectModels',
      placeholder: 'Select Model',
      suggestionField: 'model',
      valueField: 'model',
      field: 'model_id',
      filter: {
        project_id: project?._id,
      },
    },
    method: {
      placeholder: 'Select Method',
      label: 'Method',
      type: 'autoComplete',
      field: 'method',
      options: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
    },
    testcaseType: {
      type: 'autoComplete',
      label: 'Type',
      placeholder: 'Select Type',
      field: 'type',
      options: [
        'Functional',
        'Non-Functional',
        'UI/UX',
        'Security',
        'Performance',
        'Responsive',
        'Cross-Browser',
        'API',
        'Other',
      ],
    },
  };
};

export const AssetColumns = ({
  navigation,
  params,
  fromAllView,
  api,
  source,
  ...props
} = {}) => {
  const {project, feature} = params;
  const {colors} = useTheme();
  const fonts = useTheme('fonts');

  const assetMapping = assetMappings[source] || {};
  const {displayName} = assetMapping;
  const {model = assetMapping.model, sourceField = assetMapping.sourceField} =
    props;

  const primaryTextColor = ({row}) => {
    if (feature?._id && !row.in_development) {
      return colors.NEUTRAL_MEDIUM;
    }
  };

  return {
    model_name: {
      field: 'model',
      header: 'Name',
      type: 'text',
      color: primaryTextColor,
    },
    view: {
      type: 'text',
      field: 'view',
      header: 'Name',
      minWidth: 150,
      color: primaryTextColor,
    },
    feature: {
      field: 'feature_id.feature',
      type: 'text',
      header: 'Feature',
    },
    module: {
      type: 'colorTag',
      field: 'module_id.module',
      colorField: 'module_id.color',
      header: 'Module',
      width: 170,
      visible: !params?.module_id,
    },
    testcase_status: {
      render: ({row}) => {
        let {execution_status, error_message, last_executed_on} = row;
        if (!execution_status) {
          return void 0;
        }
        execution_status = execution_status.toString().toLowerCase();
        return (
          <Col style={{alignItems: 'flex-start'}} gap={12}>
            <Chip
              displayTextColor
              value={execution_status === 'pass' ? 'Pass' : 'Fail'}
              color={execution_status === 'pass' ? 'success' : 'error'}
            />
            {last_executed_on ? (
              <Text
                title={error_message}
                style={{...fonts.CAPTION_LARGE, color: colors.NEUTRAL_LOW}}>
                {moment(last_executed_on).fromNow()}
              </Text>
            ) : (
              void 0
            )}
          </Col>
        );
      },
      header: 'Status',
      width: 100,
    },
    userstory_count: {
      render: CountPlusRender,
      count_field: 'user_story_ids',
      header: 'User Stories',
      align: 'center',
      color: colors.INFORMATION_HIGH,
      width: 100,
      onPress: ({row}) => {
        navigation.navigate('all-project-userstory-list', {
          ...params,
          asset: row,
          api,
          assetName: row[sourceField],
          assetType: displayName,
        });
      },
      hidePlus: !params?.module_id,
      onPlusPress: ({row}) => {
        navigation.navigate('map-userstories', {
          ...params,
          asset: row,
          api,
          assetName: row[sourceField],
          assetType: displayName,
        });
      },
    },
    api_count: {
      type: 'text',
      render: ({row}) => {
        const {api_count} = row;
        return (
          <Chip
            value={api_count}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      color: colors.INFORMATION_HIGH,
      header: 'APIs',
      align: 'right',
      width: 80,
      onPress: ({row}) => {
        fromAllView
          ? navigation.navigate('all-project-api-list', {
              ...params,
              api: `/projects/${project?._id}/asset/${PROJECT_CONTROLLER_TYPE_ID}/all-data`,
              dataEntity: {model: row?.model, _id: row?._id},
              dataEntityFilter: {model_id: {_id: row._id, model: row.model}},
              fromDataEntity: true,
            })
          : navigation.navigate('project-api-list', {
              dataEntity: {model: row?.model, _id: row?._id},
              fromDataEntity: true,
            });
      },
    },
    model_view_count: {
      type: 'text',
      render: ({row}) => {
        const {model_view_count} = row;
        return (
          <Chip
            value={model_view_count}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      color: colors.INFORMATION_HIGH,
      header: 'Views',
      align: 'right',
      width: 100,
      onPress: ({row}) => {
        navigation.navigate('project-model-view-list', {
          ...params,
          dataEntity: row,
        });
      },
    },
    api_services_count: {
      type: 'text',
      render: ({row}) => {
        const {api_services_count} = row;
        return (
          <Chip
            value={api_services_count}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'Services',
      align: 'right',
      color: colors.INFORMATION_HIGH,
      width: 80,
      onPress: ({row}) => {
        fromAllView
          ? navigation.navigate('all-project-data-service-list', {
              ...params,
              api: `/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_TYPE_ID}/all-data`,
              apiEndpoint: {_id: row?._id, controller: row?.controller},
              paramsFilter: {
                controller_ids: {_id: row?._id, controller: row?.controller},
              },
              title: `API: ${row?.controller}`,
              referenceAsset: 'api',
            })
          : navigation.navigate('project-data-service-list', {
              apiEndpoint: {_id: row?._id, controller: row?.controller},
              referenceAsset: 'api',
              title: `API: ${row?.controller}`,
            });
      },
    },
    data_service_count: {
      type: 'text',
      render: ({row}) => {
        const {data_service_count} = row;
        return (
          <Chip
            value={data_service_count}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      header: 'Services',
      color: colors.INFORMATION_HIGH,
      align: 'right',
      width: 100,
      onPress: ({row}) => {
        fromAllView
          ? navigation.navigate('all-project-data-service-list', {
              ...params,
              api: `/projects/${project?._id}/asset/${PROJECT_DATA_SERVICE_TYPE_ID}/all-data`,
              dataEntity: {_id: row?._id, model: row?.model},
              paramsFilter: {model_id: {_id: row._id, model: row.model}},
              referenceAsset: 'model',
            })
          : navigation.navigate('project-data-service-list', {
              dataEntity: {_id: row?._id, model: row?.model},
              referenceAsset: 'model',
            });
      },
    },
    version: {field: 'version', type: 'number', header: 'Version', width: 80},
    apiName: {
      type: 'text',
      field: 'controller',
      header: 'Name',
      width: 200,
      color: primaryTextColor,
    },
    apiUri: {
      type: 'text',
      field: 'uri',
      header: 'Path',
      color: primaryTextColor,
    },
    apiMethod: {
      type: 'colorTag',
      field: 'method',
      header: 'Method',
      width: 80,
      align: 'center',
    },
    dataService: {
      type: 'text',
      field: 'name',
      header: 'Name',
      color: primaryTextColor,
    },
    code: {
      header: 'Code',
      field: 'code',
      type: 'text',
      width: 150,
      color: primaryTextColor,
    },
    component: {
      type: 'text',
      field: 'component',
      header: 'Component',
      responsive: 'sm',
      color: primaryTextColor,
    },
    parentComponent: {
      type: 'text',
      field: 'component_id.component',
      header: 'Parent Component',
      responsive: 'sm',
      color: primaryTextColor,
    },
    integration: {
      type: 'text',
      field: 'name',
      header: 'Name',
      color: primaryTextColor,
    },
    title: {
      header: 'Title',
      field: 'name',
      type: 'text',
      color: primaryTextColor,
    },
    type: {
      type: 'text',
      field: 'type',
      header: 'Type',
      width: 150,
      color: primaryTextColor,
    },
    owner: {
      type: 'userAvatar',
      field: 'owner_id',
      header: 'Owner',
      width: 70,
      align: 'center',
    },
    designOwner: {
      type: 'userAvatar',
      field: 'design_owner_id',
      header: {icon: Owner, label: 'Design'},
      width: 100,
      align: 'center',
    },
    dataEntity: {
      type: 'colorTag',
      field: 'model_id.model',
      header: 'Model',
      colorField: 'model_id.color',
      width: 150,
      visible: !params.dataEntity,
    },
    api: {
      type: 'text',
      field: 'controller_id.controller',
      header: 'API',
      width: 200,
    },
    multipleApis: {
      render: PlusMultipleTextRender,
      header: 'API',
      columnField: 'controller_ids',
      valueField: 'controller',
      align: 'center',
      width: 200,
      readOnly: true,
      onPress: ({row}) => {
        navigation.navigate('change-api', {
          ...params,
          row,
          api: `/${model}`,
          secondaryTitle: row[sourceField],
          readOnly: true,
          title: 'APIs',
        });
      },
    },
    designs: {
      render: DesignUploadCellRender,
      width: 100,
      header: 'Designs',
      align: 'center',
      value: ({row}) => {
        return (
          (row.desktop_attachments?.length || 0) +
          (row.mobile_attachments?.length || 0) +
          (row.tab_attachments?.length || 0)
        );
      },
      uploading: false,
      onView: ({row}) => {
        const data = [{...row, name: row?.view || row?.component}];
        navigation.navigate('asset-designs-viewer', {
          ...params,
          data,
        });
      },
    },
    linked_to: {
      render: PlusMultipleTextRender,
      minWidth: 150,
      header: 'Linked to',
      columnField: 'linked_to_screens',
      valueField: 'view',
      type: 'text',
      align: 'center',
      readOnly: true,
    },
    code_editor: {
      render: ({row}) => {
        const {folder_path_id, file_name, controller} = row;
        if (!folder_path_id || !file_name) {
          return null;
        }
        return (
          <TouchableOpacity
            title={'View File'}
            style={{
              margin: -8,
              padding: 8,
            }}
            onPress={() => {
              navigation.navigate('code-editor', {
                ...params,
                folder_path_id,
                file_name: file_name?.name || controller,
              });
            }}>
            <Image
              source={Code}
              style={{
                height: 16,
                width: 16,
              }}
            />
          </TouchableOpacity>
        );
      },
      width: 40,
    },
    modelView: {
      header: 'Views',
      color: colors.INFORMATION_HIGH,
      align: 'center',
      render: ({row}) => {
        return (
          <Chip
            value={row?.model_view_ids?.length || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('screen-model-view', {
          ...params,
          readOnly: true,
          row,
        });
      },
    },
    api: {
      header: 'APIs',
      color: colors.INFORMATION_HIGH,
      align: 'center',
      render: ({row}) => {
        return (
          <Chip
            value={row?.controller_ids?.length || 0}
            color={colors.INFORMATION_LOW}
            textColor={colors.INFORMATION_HIGH}
          />
        );
      },
      width: 80,
      onPress: ({row}) => {
        navigation.navigate('screen-apis', {
          ...params,
          readOnly: true,
          row,
        });
      },
    },
  };
};

export const useAssetFormActions = ({
  source,
  feature_id,
  row,
  ...props
} = {}) => {
  const assetMapping = assetMappings[source] || {};
  const {eventSourceId = assetMapping.eventSourceId} = props;

  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId,
    throwError: true,
  });
  const putInvoke = useInvoke({method: 'put', eventSourceId, throwError: true});

  const createAsset = async props => {
    return await postInvoke({
      uri: '/createAsset',
      props,
    });
  };

  const updateHistory = async props => {
    const {_id, status, released_on, ...restProps} = props;
    if (row.versionId) {
      return await putInvoke({
        uri: `/edit-asset-version/${row.versionId}`,
        props: {...restProps, source, source_id: row.source_id},
      });
    } else {
      console.log('else');
      return await postInvoke({
        uri: '/projectAssetVersions',
        props: {
          ...restProps,
          source,
          feature_id,
          source_id: row._id,
          is_changed: true,
        },
      });
    }
  };

  const updateAsset = async props => {
    const {
      _id,
      status,
      api_count,
      data_service_count,
      model_view_count,
      screen_count,
      released_on,
      source_id,
      ...restProps
    } = props;
    return await putInvoke({
      uri: `/projectModels/${_id}`,
      props: {...restProps},
    });
  };

  const requiredValidation = ({data}) => {
    assetMappings[source]?.requiredFields.forEach(requiredField => {
      let field;
      let label;
      let message;
      if (typeof requiredField === 'string') {
        field = requiredField;
        label = requiredField;
      } else {
        field = requiredField.value;
        label = requiredField.label;
        message = requiredField.message;
      }

      if (!hasValue(data[field])) {
        throw new Error(message || `${label} is required`);
      }
    });
  };

  const assetBeforeSubmit =
    ({data: oldData, skipRequiredValidation}) =>
    ({data} = {}) => {
      if (!skipRequiredValidation) {
        requiredValidation({data});
      }
      if (row.versionId) {
        const newData = {};
        for (let field in data) {
          if (JSON.stringify(data[field]) !== JSON.stringify(oldData[field])) {
            newData[field] = data[field];
          }
        }
        return {data: newData};
      } else {
        return {data};
      }
    };
  return {
    createAsset,
    updateHistory,
    updateAsset,
    assetBeforeSubmit,
    requiredValidation,
  };
};

export const ToggleGraphView = ({isGraphView, setGraphView}) => {
  return (
    <UnderlineButton
      onPress={() => {
        setGraphView(!isGraphView);
      }}
      text={isGraphView ? 'List View' : 'Graph View'}
    />
  );
};
