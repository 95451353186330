import React from 'react';
import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter, PeriodRangeMonthWiseBreakupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { TabView } from '../../../components/tab';
import { useVoucherActions } from '../../Expense/actions/VoucherActions';

export const VoucherTrailReport = props => {
    const {
        navigation,
        route: { params },
        filterParams
    } = props;
    const { filterValues, onChangeFilter, applyFilter } = useFilter({
        params: {
            period: params.period || getCurrentMonth(),
        },
    });
    return (
        <Table
            {...props}
            params={filterValues.params}
            addOnFilter={{ ...filterValues.filter }}
            variant={'bordered'}
            eventSourceId={['voucherauditreport']}
            limit={2000}
            renderHeader={() => (
                <TableHeader
                    title="Voucher Trail Report"
                    actions={[
                        <PeriodRangeMonthWiseBreakupFilter
                            onChangeFilter={onChangeFilter}
                            filterValues={filterValues}
                        />,
                        <GroupFilter
                            filterValues={filterValues}
                            applyFilter={applyFilter}
                            filters={[
                                {
                                    type: 'multiAutoComplete',
                                    label: 'Organization',
                                    api: '/organizations',
                                    placeholder: 'Select',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    field: 'organization',
                                },
                                {
                                    type: 'autoComplete',
                                    label: 'Product',
                                    api: '/products',
                                    placeholder: 'Select',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    field: 'product',
                                },
                            ]}
                        />,
                    ]}
                />
            )}
            searchFields="name"
            api={`/getvoucheraudittrail`}
            columns={[
                {
                    header: 'Type',
                    field: 'name',
                },
                {
                    render: CountCellRender,
                    aggregate: true,
                    count_field: 'total',
                    header: 'Total',
                    field: 'total',
                    align: 'right',
                    onPress: ({ row }) => {
                        navigation.navigate('voucherstraillist', {
                            voucherIds: row.totalIds,
                            api: '/vouchers',
                        });
                    },
                },
                {
                    render: CountCellRender,
                    aggregate: true,
                    count_field: 'altered',
                    align: 'right',
                    header: 'Altered',
                    field: 'altered',
                    onPress: ({ row }) => {
                        navigation.navigate('voucherstraillist', {
                            voucherIds: row?.alteredIds,
                            api: '/vouchers',
                        });
                    },
                },
                {
                    render: CountCellRender,
                    aggregate: true,
                    count_field: 'nonAltered',
                    align: 'right',
                    header: 'Non Altered',
                    field: 'nonAltered',
                    onPress: ({ row }) => {
                        navigation.navigate('voucherstraillist', {
                            voucherIds: row?.nonAlteredIds,
                            api: '/vouchers',
                        });
                    },
                },
            ]}
        />
    );
};


export const VoucherTrailTable = props => {
    const { navigation, params } = props;
    const { voucherIds } = params;
    const addOnFilter = {}
    if (voucherIds?.length) {
        addOnFilter["_id"] = { $in: voucherIds }
    }
    const {activityLogDetailForVoucher} = useVoucherActions({
        navigation,
        params,
      });
    return (
        <Table
            {...props}
            limit={50}
            addOnFilter={{ ...addOnFilter }}
            api={`/vouchers`}
            onRowPress={({ row }) => {
                navigation.navigate(`edit-voucher`, {
                    row,
                    readOnly: true
                });
            }}
            searchFields={['voucher_no']}
            eventSourceId="voucher"
            columns={[
                {
                    field: 'voucher_no',
                    header: 'Number',
                },
                {
                    type: 'date',
                    field: 'voucher_date',
                    header: 'Date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                    width: 200,
                },

                {
                    field: 'narration',
                    header: 'Narration',
                    width: 250,
                },
                {
                    field: 'cr_amount',
                    header: 'Cr',
                    type: 'currency',
                    formatOptions: {
                        maximumFractionDigits: 1,
                    },
                },
                {
                    field: 'dr_amount',
                    header: 'Dr',
                    type: 'currency',
                    formatOptions: {
                        maximumFractionDigits: 1,
                    },
                },
            ]}
            moreActions={[
                {
                    title: 'Edit',
                    onPress: ({ row }) => {
                        navigation.navigate(`edit-voucher`, {
                            row,
                            readOnly: false
                        });
                    },
                },
                {
                    title: 'Activity History',
                    onPress: activityLogDetailForVoucher,
                },
            ]}
        />
    );
};

export const VoucherTrailTab = props => {
    let { route: { params } = {} } = props;
    console.log("params>>>>>>>", params)
    const { searchValue, onChangeFilter } = useFilter({});
    const { voucherIds } = params;
    const addOnFilter = {}
    if (voucherIds?.length) {
        addOnFilter["_id"] = { $in: voucherIds }
    }
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const tabs = {
        myAttendance: {
            label: 'Voucher',
            view: (
                <VoucherTrailTable
                    searchValue={searchValue}
                    params={params}
                />
            ),
            eventSourceId: 'voucher',
            api: '/vouchers',
            addOnFilter: { ...addOnFilter },
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/exportvouchertrailreportdata',
                            props: {
                                addOnFilter: { voucherIds },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            search={searchValue}
            tabs={tabs}
            params={params}
            actions={[
                <SearchFilter
                    value={searchValue}
                    onChangeFilter={onChangeFilter}
                    placeholder="Search"
                />,
            ]}
        />
    );
};