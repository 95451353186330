import moment from 'moment';
import {Chip} from '@unthinkable/react-chip';
import {useTheme} from '@unthinkable/react-theme';
import {Image} from '@unthinkable/react-core-components';

export const toFixedDecimalValue = (value, precision = 1) => {
  if (value && value % 1 !== 0) {
    return value.toFixed(precision);
  }
  return value;
};

export const generateViewId = ({view_id, tabName, period}) => {
  if (!view_id) {
    return view_id;
  }
  if (tabName) {
    view_id += `_${tabName}`;
  }
  if (period) {
    const startDate = moment(period.from).format('DD_MM_YYYY');
    const endDate = moment(period.to).format('DD_MM_YYYY');
    view_id += `_${startDate}-${endDate}`;
  }
  return view_id;
};

export const generatePeriodLabel = ({period}) => {
  if (!period) {
    return;
  }
  const fromDate = moment.utc(period.from);
  const toDate = moment.utc(period?.to);

  if (period?.filterOf === 'Month') {
    return fromDate.format('MMM YY');
  }
  return fromDate.format('DD MMM YY') + ' - ' + toDate.format('DD MMM YY');
};

const colors = ['#F5E0FF', '#D8FDE2', '#FFF2E0', '#E0F5FF', '#DBFFFE'];
export const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export const LastCommunicationRender = ({row}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  const getLastCommunicationData = entity => {
    return entity && entity.last_communication_date
      ? moment(entity.last_communication_date).format('DD MMM, YY')
      : '-';
  };

  const lastCommunicationDate =
    row &&
    row.potential_customer &&
    row.potential_customer.last_communication_date
      ? getLastCommunicationData(row.potential_customer)
      : row && row.customer_id && row.customer_id.last_communication_date
      ? getLastCommunicationData(row.customer_id)
      : row && row.last_communication_date
      ? getLastCommunicationData(row)
      : '-';

  return (
    <Chip
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={lastCommunicationDate}
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};
