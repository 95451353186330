import { getCurrentMonth } from '@unthinkable/react-filters';
import { Button } from '../../../components/button/Button';
import { GroupFilter, SearchFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { useFilter } from '../../../controllers/useFilter';
import { useAppStateContext } from '../../../providers/AppState';
import DeliveryTable from './DeliveryTable';

export const DeliveryTab = props => {
  let {route: {params} = {}} = props;
  const {download} = useAppStateContext();
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });

  let {filter} = filterValues;
  let filterParams = {};
  if (filter && filter.customer) {
    filterParams['customer'] = filter.customer._id;
  }
  if (filter && filter.order) {
    filterParams['order'] = filter.order._id;
  }
  if (filter && filter.type === 'T&M') {
    filterParams['order_type'] = 'r';
  }
  if (filter && filter.type === 'Fixed') {
    filterParams['order_type'] = 'ot';
  }
  if (filter && filter.account_owner_id) {
    filterParams['account_owner_id'] = filter.account_owner_id._id;
  }
  if (filter && filter.due_date) {
    filterParams['due_date'] = filter.due_date;
  }

  const tabs = {
    All: {
      label: 'Delivery',
      view: (
        <DeliveryTable
          filterParams={filterParams}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'deliveryList',
      api: '/projectResourceWorkingData/get-deliveries',
      search: searchValue,
      searchFields: ['delivery_number'],
    },
  };

  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search Delivery Number"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Date',
              field: 'due_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'Order',
              api: '/orders',
              suggestionField: 'order_number',
              valueField: 'order_number',
              field: 'order',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Type',
              field: 'type',
              options: ['T&M', 'Fixed'],
            },
            {
              type: 'autoComplete',
              label: 'Account Manager',
              field: 'account_owner_id',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              valueField: 'name',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/projectResourceWorkingData/get-deliveries/export`,
              props: {
                params: {...params},
                addOnFilter: {
                  ...filterParams,
                },
              },
            });
          }}
        />,
      ]}
    />
  );
};
