import React from 'react';
import {Chip as ReactChipComponent} from '@unthinkable/react-chip';
import {AvatarChip as AvatarChipComponent} from '@unthinkable/react-chip';
import {useStyles} from '@unthinkable/react-theme';
import {AvatarChipStyles, ChipStyles, TagStyles} from './theme';
import {useAccentColor} from '../../controllers/useAccentColor';

const useChipColor = ({
  color: colorProp,
  textColor: textColorProp,
  displayTextColor,
}) => {
  const {color, LOW, MEDIUM, HIGH} = useAccentColor(colorProp);
  const {color: textColor} = useAccentColor(textColorProp);
  if (color) {
    return {color, textColor};
  }
  return {
    color: displayTextColor ? LOW : MEDIUM,
    textColor: displayTextColor ? HIGH : textColor,
  };
};

export const ChipComponent = ({
  color,
  textColor,
  displayTextColor,
  ...props
}) => {
  const chipColorProps = useChipColor({color, textColor, displayTextColor});
  return <ReactChipComponent {...props} {...chipColorProps} />;
};

export const Chip = props => {
  const styles = useStyles(ChipStyles);
  return <ChipComponent {...props} styles={styles} />;
};

export const Tag = props => {
  const styles = useStyles(TagStyles);
  return <ChipComponent {...props} styles={styles} />;
};

export const AvatarChip = props => {
  const styles = useStyles(AvatarChipStyles);
  return <AvatarChipComponent {...props} styles={styles} />;
};
