import React from 'react';
import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useStyles} from '@unthinkable/react-theme';
import {CardStyles} from './theme';

const Card = ({name, subheading, onPress, icon, blueTick, count, actions}) => {
  let {
    BlueTickIcon,
    RolesMembersIcon,
    RightArrow,
    IconStyle,
    HeaderStyle,
    SubheadingStyle,
    CardTextStyle,
    CardContainer,
    icons,
  } = useStyles(CardStyles);

  let Icon = icons[icon] || RolesMembersIcon;
  let cardComponent = (
    <Row gap={12} style={CardContainer}>
      <Image source={Icon} style={IconStyle} />
      <View style={CardTextStyle}>
        <Row gap={4} style={{alignItems: 'center'}}>
          <Text title={name} numberOfLines={1} style={HeaderStyle}>
            {name}
          </Text>
          {blueTick ? <Image source={BlueTickIcon} /> : void 0}
          {count ? <Text style={HeaderStyle}>{`(${count})`}</Text> : void 0}
        </Row>
        {subheading ? (
          <Text title={subheading} numberOfLines={1} style={SubheadingStyle}>
            {subheading}
          </Text>
        ) : (
          void 0
        )}
      </View>
      {actions?.length ? (
        actions?.map(item => item)
      ) : (
        <Image source={RightArrow} />
      )}
    </Row>
  );
  if (onPress) {
    cardComponent = (
      <TouchableOpacity onPress={onPress}>{cardComponent}</TouchableOpacity>
    );
  }

  return cardComponent;
};

export default Card;
