import {AddButton} from '../../../components/button/AddButton';
import {TabView} from '../../../components/tab/TabView';
import {
  EsiCycleTable,
  LeaveCreditTable,
  SalaryCycleTable,
} from './SalarySetupTables';

export const SalarySetupTab = props => {
  let {
    route: {params},
  } = props;
  const tabs = {
    leave_credit: {
      label: 'Leave Credit',
      view: <LeaveCreditTable {...props} />,
      actions: [
        <AddButton
          title="Leave Credit"
          eventSourceId="leaveCredit"
          view="add-leave-credit"
          params={params}
        />,
      ],
    },
    esi_Cycle: {
      label: 'ESI Cycle',
      view: <EsiCycleTable {...props} />,
      actions: [
        <AddButton
          title="ESI Cycle"
          eventSourceId="esicycle"
          view="add-esi-cycle"
          params={params}
        />,
      ],
    },
    salary_cycle: {
      label: 'Salary Cycle',
      view: <SalaryCycleTable {...props} />,
      actions: [
        <AddButton
          title="Salary Cycle"
          eventSourceId="salarycycle"
          view="add-salary-cycle"
          params={params}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
