import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {useInvoke} from '../../../controllers/useInvoke';
import {STATUS} from '../views/TargetAudienceTable';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import DualActionClickRender from '../../../app-components/renders/DualActionClickRender';
import MultiActionClickRender from '../../../app-components/renders/MultiActionClickRender';

export const OutreachCampaignHeader = props => {
  return (
    <NavHeader
      title="Inmail Campaign"
      actions={[
        <AddButton
          title="Inmail Campaign"
          view="add-inmailcampaign"
          params={props?.route?.params}
        />,
        <MoreActions
          actions={[
            {
              title: 'Import Inmail Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin prospects',
                  model: 'PotentialCustomer',
                });
              },
            },
            {
              title: 'Import Inmail Responses',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin responses',
                  model: 'PotentialCustomer',
                });
              },
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

const RendersAcceptedPercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.accepted_percentage
          ? row?.accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.accepted_percentage || row?.overall_accepted_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_accepted_percentage
          ? row?.overall_accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage
          ? row?.positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const LinkedInCampaignOwner = ({row}) => {
  const owners = [];
  if (row.profile_owner) {
    owners.push(row.profile_owner);
  }
  if (row.assigned_to) {
    owners.push(row.assigned_to);
  }
  return <UserAvatarGroup value={owners} />;
};

const prospectCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'POC',
  align: 'right',

  count_field: 'prospect_count',
  responsive: 'sm',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        for_customer: row.for_customer,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});
const unverifiedContactCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Unv prospects',
  align: 'right',

  count_field: 'unverified_contact_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreachcampaign-contacts-unverified', {
      campaign_id: {_id: row._id, name: row.name},
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});

const dualActionCount = ({params, navigation}) => ({
  render: DualActionClickRender,
  width: 160,
  header: 'Ver/Unv/Sent actions',
  align: 'right',
  left_count_field: 'verified_action_count',
  right_count_field: 'unverified_action_count',
  count_field: 'sent_action_count',
  responsive: 'sm',
  onPressLeft: row => {
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      verified: true,
      status: 'pending',
      showUnverified: false,
    });
  },
  onPressRight: row => {
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      verified: {$in: [null, false]},
      status: 'pending',
      showUnverified: true,
    });
  },
  onPressCount: row => {
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      sent_action: true,
      status: 'completed',
    });
  },
});

const multiActionCount = ({params, navigation}) => ({
  render: MultiActionClickRender,
  width: 150,
  header: 'Email Status',
  align: 'left',
  left_most_count_field: 'email_sent_count',
  left_count_field: 'email_delivered_on_count',
  middle_field: 'email_opened_on_count',
  right_count_field: 'email_bounce_on_count',
  right_most_count_field: 'email_unsubscribed_on_count',
  communication_count_field: 'email_communication_on_count',

  responsive: 'sm',
  onPressLeftMost: row => {
    navigation.navigate('show-actions', {
      ...row,
      addOnFilter: {
        action: 'email',
        status: 'completed',
      },
      sort: {
        completed_on: -1,
      },
      title: 'Sent Email',
    });
  },
  onPressLeft: row => {
    navigation.navigate('show-actions', {
      ...row,
      addOnFilter: {
        action: 'email',
        email_delivered_on: {$exists: true},
      },
      sort: {
        email_delivered_on: -1,
      },
      title: 'Delivered Email',
    });
  },
  onPressMiddle: row => {
    navigation.navigate('show-actions', {
      ...row,
      addOnFilter: {
        action: 'email',
        email_open_on: {$exists: true},
      },
      sort: {
        email_open_on: -1,
      },
      title: 'Open Email',
    });
  },
  onPressRight: row => {
    navigation.navigate('show-actions', {
      ...row,
      addOnFilter: {
        action: 'email',
        email_bounce_on: {$exists: true},
      },
      sort: {
        email_bounce_on: -1,
      },
      title: 'Bounce Email',
    });
  },
  onPressRightMost: row => {
    navigation.navigate('show-actions', {
      ...row,
      addOnFilter: {
        action: 'email',
        email_unsubscribe_on: {$exists: true},
      },
      sort: {
        email_unsubscribe_on: -1,
      },
      title: 'Unsubscribed Email',
    });
  },
  onPressCommunication: row => {
    navigation.navigate('show-communications', {
      ...row,
    });
  },
});

const messageCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Messages',
  align: 'right',

  count_field: 'campaign_message_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-outreach-campaign-message-form`, {
      campaign_id: {_id: row._id, name: row.name},
      target_audience: {
        _id: row.target_audience._id,
        name: row.target_audience.name,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
        buyer_persona: {
          _id: row.target_audience._id,
          name: row.target_audience.name,
        },
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-messages`, {
      campaign_id: {_id: row._id, name: row.name},
      target_audience: {
        _id: row.target_audience._id,
        name: row.target_audience.name,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
        buyer_persona: {
          _id: row.target_audience._id,
          name: row.target_audience.name,
        },
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});

const connectionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Connections',
  align: 'right',
  released_count_field: 'connection_request_accepted_count',
  count_field: 'connection_request_sent_count',
  responsive: 'sm',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {_id: row._id, name: row.name},
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});

export const OutreachCampaignTable = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    searchValue,
    filter,
    tabFilter = {},
  } = props;
  params = {...params, ...filterParams};

  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144b',

      label: row?.name,
    });
  };
  const invoke = useInvoke({
    close: false,
    method: 'put',
    eventSourceId: ['inmailCampaignAdded'],
  });

  const {
    editOutreachCampaign,
    populateRandomContacts,
    checkLinkedinProfile,
    readCampaignConnectionRequest,
    readCampaignMessages,
    sendRandomActions,
    settingsOutreachCampaign,
    moveToNewCampaign,
    populateNextMessage,
    activityLogDetailforInmailCampaign,
    uqls,
    qualifiedLeads,
  } = leadControllers({
    ...props,
    params: {...params, fromInmailCampaign: true},
  });

  return (
    <Table
      eventSourceId={[
        'outreachAdded',
        'inmailCampaignAdded',
        'Task',
        'campaignMessageAdded',
        'reloadImportHistoryLogs',
        'actionVerified',
        'summaryVerified',
        'indexUpdated',
      ]}
      api={`/outreachcampaigns/performance`}
      params={params}
      addOnFilter={{...filter, ...tabFilter}}
      limit={5000}
      search={searchValue}
      searchFields={'name'}
      // onRowPress={inmailMessages}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
          responsive: 'sm',
        },

        {
          width: 80,
          header: 'Owner',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        {
          width: 80,
          header: 'Profile',
          field: 'linkedin_profile',
          type: 'userAvatar',
        },
        // {
        //   render: CountCellRender,
        //   width: 120,
        //   header: 'Outreach Contents',
        //   align: 'right',
        //   count_field: 'campaign_message_count',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`add-outreach-content`, {
        //       ...params,
        //       row,
        //       campaign_type: '64d0fd55ab78dfead48c144b',
        //     });
        //   },
        //   onPress: inmailMessages,
        // },
        // {
        //   width: 100,
        //   type: 'number',
        //   field: 'prospect_count',
        //   header: 'Prospect',
        //   aggregate: true,
        //   onPress: prospectsCountForCampaign,
        // },
        prospectCount({navigation, params}),
        // messageCount({navigation, params}),
        {
          field: 'sent_connection_request_count',
          header: 'Requested',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('outreach-campaign-grouped', {
              ...row,
              sent_action: true,
              status: 'completed',
              filter: {
                action: {$in: ['connection_request']},
                completedOn: {
                  $gte: params?.period?.from,
                  $lte: params?.period?.to,
                },
              },
            });
          },
        },
        {
          field: 'connection_request_accepted_count',
          header: 'Accepted',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: props => {
            const {row} = props;
            navigation.navigate(`outreach-campaign-contacts-accepted`, {
              campaign_id: {_id: row._id, name: row.name},
              defaultValues: {
                campaign_id: {_id: row._id, name: row.name},
              },
              filter: {
                campaign_id: row._id,
                connection_request_accepted_on: {
                  $gte: params?.period?.from,
                  $lte: params?.period?.to,
                },
              },
            });
          },
        },
        {
          field: 'sent_message_count',
          header: 'Messages',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('outreach-campaign-grouped', {
              ...row,
              sent_action: true,
              status: 'completed',
              filter: {
                action: {$in: ['email', 'direct_message', 'inmail_message']},
                completedOn: {
                  $gte: params?.period?.from,
                  $lte: params?.period?.to,
                },
              },
            });
          },
        },
        {
          field: 'replied_count',
          header: 'Replied',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('show-communications', {
              ...row,
              addOnFilter: {
                date: {
                  $gte: params?.period?.from,
                  $lte: params?.period?.to,
                },
                campaign_id: row?._id,
                initiator: 'Client',
              },
            });
          },
        },
        {
          field: 'lead_count',
          header: 'Leads',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 80,
          onPress: uqls,
        },
        {
          field: 'ql_count',
          header: 'QL',
          type: 'text',
          align: 'right',
          responsive: 'sm',
          width: 80,
          onPress: qualifiedLeads,
        },

        // requestedconnectionCount({navigation, params}),
        // acceptedconnectionCount({navigation, params}),

        // unverifiedContactCount({navigation, params}),
        // dualActionCount({navigation, params}),
        // multiActionCount({navigation, params}),
        // connectionCount({navigation, params}),
        // {
        //   type: 'number',
        //   field: 'request_send_count',
        //   header: 'Requests',
        //   aggregate: true,
        //   onPress: requestSentProspects,
        //   width: 100,
        // },
        // {
        //   type: 'number',
        //   header: 'Accepted %',
        //   field: 'accepted_percentage',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   // render: RendersAcceptedPercentage,
        //   onPress: acceptedProspects,
        //   width: 100,
        // },
        // {
        //   type: 'number',
        //   field: 'message_send_count',
        //   header: 'Message Sent',
        //   aggregate: true,
        //   onPress: CampaignProspectOnClickMessageSend,
        //   width: 120,
        // },
        // {
        //   type: 'number',
        //   header: 'Positive %',
        //   field: 'positive_result_percentage',
        //   formatOptions: {
        //     maximumFractionDigits: 2,
        //   },
        //   // render: RendersPositivePercentage,
        //   onPress: CampaignProspectOnClickPostive,
        //   width: 110,
        // },
        // {
        //   type: 'number',
        //   field: 'lead_count',
        //   compareField: 'previous_lead_count',
        //   header: 'Lead',
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        //   onPress: uqls,
        //   onComparePress: preuqls,
        //   render: ComparisonRender,
        //   width: 110,
        // },
        // {
        //   type: 'number',
        //   field: 'ql_count',
        //   compareField: 'previous_ql_count',
        //   header: 'QL',
        //   aggregate: {
        //     render: AggregateComparisonRender,
        //   },
        //   onPress: qualifiedLeads,
        //   onComparePress: prequalifiedLeads,
        //   render: ComparisonRender,
        //   width: 110,
        // },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editOutreachCampaign,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Move to New Campaign',
          onPress: moveToNewCampaign,
        },
        {
          title: 'Populate Random Contacts',
          onPress: populateRandomContacts,
        },
        {
          title: 'Populate Next Message',
          onPress: populateNextMessage,
        },
        {
          title: 'Edit Linkedin Profile',
          onPress: ({row}) => {
            navigation.navigate('edit-linkedin-profile', {
              row: {_id: row.linkedin_profile?._id || row.linkedin_profile},
            });
          },
        },
        {
          title: 'Check Linkedin Profile',
          onPress: checkLinkedinProfile,
        },
        {
          title: 'Edit Email Profile',
          onPress: ({row}) => {
            navigation.navigate('email-profile-edit-form', {
              row: {_id: row.email_profile?._id || row.email_profile},
            });
          },
        },
        {
          title: 'Tasks',
          onPress: showTasks,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Settings',
          onPress: settingsOutreachCampaign,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailforInmailCampaign,
        },
      ]}
    />
  );
};
