import {AMReportTab} from '../customer/views/AMReport';
import {CustomerChurnReportTabs} from '../customer/views/CustomerChurnReport';
import {
  CustomerOverviewDashboard,
  CustomerOverviewDashboardForAm,
} from '../customer/views/CustomerDashboardOverview';
import {CustomerGrossMarginTab} from '../customer/views/CustomerGrossMarginTab';
import {CustomerRevenueTabs} from '../customer/views/CustomerRevenueReport';
import {CustomerCurrencyTable} from '../customer/views/currencyTable';
import {
  FixedOpportunityProjectionTab,
  TnMOpportunityProjectionTab,
} from '../opportunityProjection/views/opportunityProjectionTabs';
import {AllPendingInvoiceTab} from './views/AllDueInvoicingTab';
import {GrowthAndBillingTab} from './views/CurrentBusinessTAndM';
import {DraftInvoiceTab, DraftInvoiceTabAM} from './views/DraftInvoiceTab';
import {PendingInvoiceTab, PendingInvoiceTabAm} from './views/DueInvoiceTab';
import {
  PendingInvoiceTabAm as PendingInvoiceAM,
  PendingInvoiceTab as PendingInvoice,
} from './views/PendingInvoicesTabs';
import {GSTTab} from './views/GSTTable';
import {GrowthAndBillingComparsionTab} from './views/GrowthAndBillingComparsion';
import {InvoiceReportTab} from './views/InvoiceReportTable';
import {NewOrderForAmTab, NewOrderTab} from './views/NewOrderTab';
import {
  FixedOrderTab,
  FixedOrderTabAM,
  TMOrderTab,
  TMOrderTabAm,
} from './views/OrderTab';
import {OverduePaymentReportTable} from './views/OverduePaymentReport';
import {ProjectedVsAchievedTable} from './views/ProjectedVsAchievedTable';
import {RecieptTab, RecieptTabAm} from './views/RecieptTab';
import {
  RevenueInvoiceTab,
  RevenueInvoiceTabAm,
} from './views/RevenueInvoiceTab';
import {SoftexTab} from './views/SoftexTable';
import {CurrencyTable} from '../common/views/CurrencyTable';
import {FreezeRangeTable} from '../common/views/FreezeRangeTable';
import {DueVerificationTAndM} from './views/DueVerificationTAndM';
import {DueVerificationFixed} from './views/DueVerificationFixed';
import {DeliveryVsInvoiceReport} from '../customer/views/DeliveryVsInvoiceReport';
import {
  CreditNoteAccountsTab,
  CreditNoteApproverTab,
  CreditNoteTab,
} from './views/CreditNoteTabs';
import {DeliveredProjectResourceWorkingTab} from '../employee/views/DeliveredProjectResourceWorking';
import {
  DeliveryOwnerBillingStatusReportTab,
  AnchorBillingStatusReportTab,
  InternalBillingTab,
  ModuleOwnerBillingStatusReportTab,
  SelfBillingStatusReportTab,
  TechBillingStatusReportTab,
} from '../employee/views/BillingStatusReportTab';
import {OrdersAndDeliveriesForStaffAgumentation} from './views/OrdersAndDeliveriesForTAndM';

export const ScreenMapping = [
  {
    id: 'customersRevenue',
    view: <CustomerOverviewDashboard />,
  },
  {
    id: 'amperformance',
    view: <AMReportTab />,
  },
  {
    id: 'customeroverview',
    view: <CustomerOverviewDashboardForAm />,
  },
  {
    id: 'newamorder',
    view: props => <NewOrderForAmTab />,
  },
  {
    id: 'fixedamorder',
    view: props => (
      <FixedOrderTabAM
        extraAddOnFilter={{
          is_internal: {$in: [false, null]},
          order_exists_type: {$in: ['New', 'Extension', 'CR']},
        }}
        fromInternal={false}
        {...props}
      />
    ),
  },
  {
    id: 'retaineramorder',
    view: props => (
      <FixedOrderTabAM
        extraAddOnFilter={{
          is_internal: {$in: [false, null]},
          order_exists_type: {$in: ['Support']},
        }}
        fromInternal={false}
        {...props}
      />
    ),
  },
  {
    id: 'tandmamorder',
    view: props => <TMOrderTabAm {...props} />,
  },
  {
    id: 'internalamorder',
    view: props => (
      <FixedOrderTabAM
        extraAddOnFilter={{is_internal: true}}
        fromInternal={true}
        {...props}
      />
    ),
  },
  {
    id: 'dueinvoicingamorder',
    view: props => <PendingInvoiceTabAm {...props} />,
  },
  {
    id: 'alldueinvoicing',
    view: props => {
      const {user} = props;
      const {employee} = user;
      const {_id: employeeId, team: employeeTeam} = employee || {};
      return (
        <AllPendingInvoiceTab
          extraFilter={{
            $or: [
              {account_owner_id: employeeId},
              {account_owner_id: {$in: employeeTeam}},
            ],
          }}
          {...props}
        />
      );
    },
  },
  {
    id: 'draftinvoiceam',
    view: props => <DraftInvoiceTabAM {...props} />,
  },
  {
    id: 'invoiceam',
    view: props => <RevenueInvoiceTabAm {...props} />,
  },
  {
    id: 'unpaidinvoiceam',
    view: props => <PendingInvoiceAM {...props} />,
  },
  {
    id: 'receipt',
    view: props => {
      return <RecieptTabAm {...props} />;
    },
  },
  {
    id: 'growthandbilling',
    view: props => <GrowthAndBillingTab {...props} />,
  },
  {
    id: 'newRevenue',
    view: props => {
      return <NewOrderTab {...props} />;
    },
  },
  {
    id: 'fixedRevenue',
    view: props => {
      return (
        <FixedOrderTab
          extraAddOnFilter={{
            is_internal: {$in: [false, null]},
            order_exists_type: {$in: ['New', 'Extension', 'CR']},
          }}
          fromInternal={false}
          {...props}
        />
      );
    },
  },
  {
    id: 'retainerRevenue',
    view: props => {
      return (
        <FixedOrderTab
          key="RetainerOrder"
          extraAddOnFilter={{
            is_internal: {$in: [false, null]},
            order_exists_type: {$in: ['Support']},
          }}
          fromInternal={false}
          {...props}
        />
      );
    },
  },
  {
    id: 'tm',
    view: props => {
      return <TMOrderTab {...props} />;
    },
  },
  {
    id: 'internal',
    view: props => {
      return (
        <FixedOrderTab
          key="internalOrder"
          extraAddOnFilter={{is_internal: true}}
          fromInternal={true}
          {...props}
        />
      );
    },
  },
  {
    id: 'dueinvoicing',
    view: props => {
      return <PendingInvoiceTab {...props} />;
    },
  },
  {
    id: 'alldueinvoicingPending',
    view: props => {
      return <AllPendingInvoiceTab {...props} />;
    },
  },
  {
    id: 'invoice',
    view: props => {
      return <RevenueInvoiceTab {...props} />;
    },
  },
  {
    id: 'unpaidinvoices',
    view: props => {
      return <PendingInvoice {...props} />;
    },
  },
  {
    id: 'draftinvoices',
    view: props => {
      return <DraftInvoiceTab {...props} />;
    },
  },
  {
    id: 'overduepayment',
    view: <OverduePaymentReportTable />,
  },
  {
    id: 'gst',
    view: <GSTTab />,
  },
  {
    id: 'softex',
    view: <SoftexTab />,
  },
  {
    id: 'invoicesreport',
    view: <InvoiceReportTab />,
  },
  {
    id: 'exchangeratecurrency',
    view: <CustomerCurrencyTable />,
  },
  {
    id: 'receiptTab',
    view: props => {
      return <RecieptTab {...props} />;
    },
  },
  {
    id: 'growthbilling',
    view: <GrowthAndBillingTab />,
  },
  {
    id: 'projectedachieved',
    view: <ProjectedVsAchievedTable />,
  },
  {
    id: 'growthbillingcomparsion',
    view: <GrowthAndBillingComparsionTab />,
  },
  {
    id: 'customergrossmarginreport',
    view: <CustomerGrossMarginTab />,
  },
  {
    id: 'customerwiserevenue',
    view: <CustomerRevenueTabs />,
  },
  {
    id: 'customerwiserevenueam',
    view: ({user}) => (
      <CustomerRevenueTabs
        extraFilter={{owner: user?.employee?._id}}
        fromAm={true}
      />
    ),
  },
  {
    id: 'customerchurnreport',
    view: <CustomerChurnReportTabs />,
  },
  {
    id: 'currency',
    view: <CurrencyTable />,
  },
  {
    id: 'freezeorderrange',
    view: <FreezeRangeTable />,
  },
  {
    id: 'dueverficationtandm',
    view: <DueVerificationTAndM />,
  },
  {
    id: 'dueverficationFixed',
    view: <DueVerificationFixed />,
  },
  {
    id: 'dueverficationtandmAM',
    view: ({user}) => (
      <DueVerificationTAndM
        extraAddOnFilter={{account_owner_id: user?.employee?._id}}
      />
    ),
  },
  {
    id: 'dueverficationFixedAM',
    view: ({user}) => (
      <DueVerificationFixed
        extraAddOnFilter={{account_owner_id: user?.employee?._id}}
      />
    ),
  },
  {
    id: 'dueverficationtandmPM',
    view: ({user}) => (
      <DueVerificationTAndM
        extraAddOnFilter={{delivery_owner_id: user?.employee?._id}}
      />
    ),
  },
  {
    id: 'dueverficationFixedPM',
    view: ({user}) => (
      <DueVerificationFixed
        extraAddOnFilter={{
          delivery_owner_id: user?.employee?._id,
          delivery_owner: user?.employee?._id,
        }}
      />
    ),
  },
  {
    id: 'deliveryvsinvoicereport',
    view: <DeliveryVsInvoiceReport />,
  },
  {
    id: 'creditNoteAm',
    view: <CreditNoteTab />,
  },
  {
    id: 'creditNoteApprover',
    view: <CreditNoteApproverTab />,
  },
  {
    id: 'creditNoteAccounts',
    view: <CreditNoteAccountsTab />,
  },
  {
    id: 'deliveredprojectresourceworkingsam',
    view: props => <DeliveredProjectResourceWorkingTab {...props} />,
  },
  {
    id: 'anchorbillingstatus',
    view: props => <AnchorBillingStatusReportTab {...props} />,
  },
  {
    id: 'techleadbillingstatus',
    view: props => <TechBillingStatusReportTab {...props} />,
  },
  {
    id: 'moduleOwnerbillingstatus',
    view: props => <ModuleOwnerBillingStatusReportTab {...props} />,
  },
  {
    id: 'internalBillingStatus',
    view: props => <InternalBillingTab {...props} />,
  },
  {
    id: 'staffAgumentationBillingStatus',
    view: props => <OrdersAndDeliveriesForStaffAgumentation {...props} />,
  },
  {
    id: 'deliveryOwnerBillingStatus',
    view: props => <DeliveryOwnerBillingStatusReportTab {...props} />,
  },
  {
    id: 'selfBillingStatus',
    view: props => <SelfBillingStatusReportTab {...props} />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'revenue',
//     views: [
//       {
//         id: 'customers',
//         label: 'Customers',
//         view: <CustomerOverviewDashboard />,
//       },
//       {
//         id: 'amperformance',
//         label: 'AM Performance',
//         view: <AMReportTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'revenueam',
//     views: [
//       {
//         id: 'customeroverview',
//         label: 'Overview',
//         view: <CustomerOverviewDashboardForAm />,
//       },
//       {
//         id: 'orderam',
//         label: 'Order',
//         section: true,
//         menus: [
//           {
//             id: 'newamorder',
//             label: 'New',
//             view: props => <NewOrderForAmTab />,
//           },
//           {
//             id: 'fixedamorder',
//             label: 'Fixed',
//             view: props => (
//               <FixedOrderTabAM
//                 addOnFilter={{is_internal: {$in: [false, null]}}}
//                 fromInternal={false}
//                 {...props}
//               />
//             ),
//           },
//           {
//             id: 'tandmamorder',
//             label: 'T&M',
//             view: props => <TMOrderTabAm {...props} />,
//           },
//           {
//             id: 'internalamorder',
//             label: 'Internal',
//             view: props => (
//               <FixedOrderTabAM
//                 addOnFilter={{is_internal: true}}
//                 fromInternal={true}
//                 {...props}
//               />
//             ),
//           },
//           {
//             id: 'dueinvoicingamorder',
//             label: 'Due Invoicing',
//             view: props => <PendingInvoiceTabAm {...props} />,
//           },
//           {
//             id: 'alldueinvoicing',
//             label: 'All Due Invoicing',
//             view: props => {
//               const {user} = props;
//               const {employee} = user;
//               return (
//                 <AllPendingInvoiceTab
//                   extraFilter={{account_owner_id: employee._id}}
//                   {...props}
//                 />
//               );
//             },
//           },
//         ],
//       },
//       {
//         id: 'invoicesam',
//         label: 'Invoices',
//         section: true,
//         menus: [
//           {
//             id: 'draftinvoiceam',
//             label: 'Draft Invoice',
//             view: props => <DraftInvoiceTabAM {...props} />,
//           },
//           {
//             id: 'invoiceam',
//             label: 'Invoice',
//             view: props => <RevenueInvoiceTabAm {...props} />,
//           },
//           {
//             id: 'unpaidinvoiceam',
//             label: 'Unpaid Invoice',
//             view: props => <PendingInvoiceAM {...props} />,
//           },
//         ],
//       },
//       {
//         id: 'receipt',
//         label: 'Receipts',
//         view: props => {
//           return <RecieptTabAm {...props} />;
//         },
//       },
//       // {
//       //   id: 'pipelineam',
//       //   label: 'Pipeline',
//       //   section: true,
//       //   menus: [
//       //     {
//       //       id: 'tandmpipelineam',
//       //       label: 'T&M',
//       //       view: props => {
//       //         const {user} = props;
//       //         return (
//       //           <TnMOpportunityProjectionTab
//       //             user={user}
//       //             extraAddOnFilter={{owner: user?.employee?._id}}
//       //             {...props}
//       //           />
//       //         );
//       //       },
//       //
//       //     },
//       //     {
//       //       id: 'fixedpipelineam',
//       //       label: 'Fixed',
//       //       view: props => {
//       //         const {user} = props;
//       //         return (
//       //           <FixedOpportunityProjectionTab
//       //             user={user}
//       //             extraAddOnFilter={{owner: user?.employee?._id}}
//       //             {...props}
//       //           />
//       //         );
//       //       },
//       //
//       //     },
//       //   ],
//       // },
//       {
//         id: 'growthandbilling',
//         label: 'Growth & Billing',
//         view: props => <GrowthAndBillingTab {...props} />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'orders',
//     views: [
//       {
//         id: 'new',
//         label: 'New',
//         view: props => {
//           return <NewOrderTab {...props} />;
//         },
//       },
//       {
//         id: 'fixed',
//         label: 'Fixed',
//         view: props => {
//           return (
//             <FixedOrderTab
//               addOnFilter={{is_internal: {$in: [false, null]}}}
//               fromInternal={false}
//               {...props}
//             />
//           );
//         },
//       },
//       {
//         id: 'tm',
//         label: 'T&M',
//         view: props => {
//           return <TMOrderTab {...props} />;
//         },
//       },
//       {
//         id: 'internal',
//         label: 'Internal',
//         view: props => {
//           return (
//             <FixedOrderTab
//               addOnFilter={{is_internal: true}}
//               fromInternal={true}
//               {...props}
//             />
//           );
//         },
//       },
//       {
//         id: 'dueinvoicing',
//         label: 'Due Invoicing',
//         view: props => {
//           return <PendingInvoiceTab {...props} />;
//         },
//       },
//       {
//         id: 'alldueinvoicing',
//         label: 'All Due Invoicing',
//         view: props => {
//           return <AllPendingInvoiceTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'invoices',
//     views: [
//       {
//         id: 'invoice',
//         label: 'Invoice',
//         view: props => {
//           return <RevenueInvoiceTab {...props} />;
//         },
//       },
//       {
//         id: 'unpaidinvoices',
//         label: 'Unpaid Invoice',
//         view: props => {
//           return <PendingInvoice {...props} />;
//         },
//       },
//       {
//         id: 'draftinvoices',
//         label: 'Draft Invoice',
//         view: props => {
//           return <DraftInvoiceTab {...props} />;
//         },
//       },
//       {
//         id: 'overduepayment',
//         label: 'Overdue Payment',
//         view: <OverduePaymentReportTable />,
//       },
//       {
//         id: 'gst',
//         label: 'GST',
//         view: <GSTTab />,
//       },
//       {
//         id: 'softex',
//         label: 'Softex',
//         view: <SoftexTab />,
//       },
//       {
//         id: 'invoicesreport',
//         label: 'Reports',
//         view: <InvoiceReportTab />,
//       },
//       {
//         id: 'exchangeratecurrency',
//         label: 'Exchange Rate',
//         view: <CustomerCurrencyTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'receipts',
//     views: [
//       {
//         id: 'receipt',
//         label: 'Receipts',
//         view: props => {
//           return <RecieptTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'projections',
//     views: [
//       // {
//       //   id: 'active-fixed-orders',
//       //   label: 'Active Fixed Orders',
//       //   view: <MyOrdersTable />,
//       // },
//       {
//         id: 'growthbilling',
//         label: 'Growth & Billing',
//         view: <GrowthAndBillingTab />,
//       },
//       {
//         id: 'projectedachieved',
//         label: 'Projected Vs Achieved',
//         view: <ProjectedVsAchievedTable />,
//       },
//       {
//         id: 'growthbillingcomparsion',
//         label: 'Growth & Billing Comparison',
//         view: <GrowthAndBillingComparsionTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'revenuereport',
//     views: [
//       {
//         id: 'customergrossmarginreport',
//         label: 'Customer Gross Margin',
//         view: <CustomerGrossMarginTab />,
//       },
//       {
//         id: 'customerwiserevenue',
//         label: 'Revenue Comparison',
//         view: <CustomerRevenueTabs />,
//       },
//       {
//         id: 'customerchurnreport',
//         label: 'Customer Churn Report',
//         view: <CustomerChurnReportTabs />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'revenue',
//     label: 'Customers',
//   },
//   {
//     id: 'revenueam',
//     label: 'Customers(AM)',
//   },
//   {
//     id: 'orders',
//     label: 'Orders',
//   },
//   {
//     id: 'invoices',
//     label: 'Invoices',
//   },
//   {
//     id: 'receipts',
//     label: 'Receipts',
//   },
//   {
//     id: 'projections',
//     label: 'Projections',
//   },
//   {
//     id: 'revenuereport',
//     label: 'Reports',
//   },
// ];
