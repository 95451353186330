import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';

const LeadKpiTable = props => {
  let {
    route: {params},
  } = props || {};
  let {outCome} = params || {};
  let employeeData = outCome?.opportunityId || [];
  let resourceType = outCome?.ResourceType || "FrontFace";
  return (
    <Table
      eventSourceId={'LeadKpiTable'}
      renderHeader={() => (
        <TableHeader title={`DetailsView ( ${employeeData.length} )`} />
      )}
      data={employeeData}
      columns={[
        {
          field: 'name',
          header: 'Employee',
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'tech_lead_name',
          header: `${resourceType}`,
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'project_name',
          header: 'Project',
          responsive: 'sm',
          type: 'text',
        },
        {
          field: 'from_date',
          header: 'Start Date',
          responsive: 'sm',
          type: 'date',
          align: 'right',
        },
        {
          field: 'to_date',
          header: 'End Date',
          responsive: 'sm',
          type: 'date',
          align: 'right',
        },
        {
          field: 'hours',
          header: 'Availabile Hours',
          responsive: 'sm',
          type: 'text',
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default LeadKpiTable;
