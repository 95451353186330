import {Text, View} from '@unthinkable/react-core-components';
import {getDateRangeDisplay} from '@unthinkable/react-date-picker/src/DateUtility';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {Tag} from '../../../components/chip/Chip';
import {ColorTag} from '../../../components/chip/ColorChip';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {
  BorderedGroupTable,
  GroupTable,
  Table,
} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useTheme} from '@unthinkable/react-theme';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {Chip} from '@unthinkable/react-chip';

export const TotalAttritionTable = props => {
  let {navigation, route, periods} = props || {};
  let {params = {}} = route || {};
  params = {...params, periods};
  return (
    <BorderedGroupTable
      api={'/employees/total-attrition'}
      variant={'bordered'}
      params={params}
      eventSourceId="TotalAttrition"
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: periods?.monthWiseBreakup
                    ? 'Month'
                    : periods?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.desirable + row?.regretable + row?.unknown} Voluntary`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.involuntary} Involuntary`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.total} Total`}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Type',
          // width: 250,
          render: ({row, styles}) => {
            let {rowText = {}} = styles || {};
            const {type} = row || {};
            let color;
            if (type === 'Voluntary') {
              color = 'Accent1';
            } else if (type === 'Engineers') {
              color = 'Accent2';
            } else if (type === 'Non-Engineers') {
              color = 'Accent3';
            } else if (type === 'Transfer Case') {
              color = 'Accent4';
            }
            return <Tag value={row.type} color={color} />;
          },
        },
        {
          header: 'Voluntary',
          children: [
            {
              header: 'Desirable',
              render: CountCellRender,
              count_field: 'desirable',
              align: 'right',
              width: 100,
              onPress: props => {
                navigation.navigate('total-voluntary-tech-table', {
                  ...props,
                  isDesriable: true,
                });
              },
            },
            {
              header: 'Regrettable',
              count_field: 'regretable',
              render: CountCellRender,
              align: 'right',
              width: 100,
              onPress: props => {
                navigation.navigate('total-voluntary-tech-table', {
                  ...props,
                  isRegreatable: true,
                });
              },
            },
            {
              header: 'Unknown',
              count_field: 'unknown',
              render: CountCellRender,
              align: 'right',
              width: 100,
              onPress: props => {
                navigation.navigate('total-voluntary-tech-table', {
                  ...props,
                  isUnknown: true,
                });
              },
            },
          ],
        },
        {
          header: 'Involuntary',
          count_field: 'involuntary',
          render: CountCellRender,
          align: 'right',
          width: 150,
          onPress: props => {
            navigation.navigate('total-voluntary-tech-table', {
              ...props,
              isInvoluntary: true,
            });
          },
        },
        // {
        //   field: 'tech',
        //   header: 'Tech',
        //   type: 'text',
        //   onPress: props => {
        //     navigation.navigate('total-voluntary-tech-table', {...props});
        //   },
        // },
        // {
        //   field: 'nonTech',
        //   header: 'Non-Tech',
        //   type: 'text',
        //   onPress: props => {
        //     navigation.navigate('total-involuntary-nontech-table', {...props});
        //   },
        // },
        {
          count_field: 'total',
          header: 'Total',
          render: CountCellRender,
          type: 'text',
          align: 'right',
          width: 120,
        },
      ]}
    />
  );
};

export const AllVoluntaryAttritionTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};
  const {onChangeFilter, filterValues} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {filterParams, period: periods} = {}} = filterValues;
  params = {...params, periods};
  return (
    <BorderedGroupTable
      renderHeader={() => (
        <TableHeader
          title="Voluntary Attrition"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
            />,
          ]}
        />
      )}
      variant={'bordered'}
      api="/employees/all-voluntary-attrition"
      params={params}
      eventSourceId="TotalAttrition"
      groupRow={{
        data: '_children',
        header: true,
        defaultExpanded: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: periods?.monthWiseBreakup
                    ? 'Month'
                    : periods?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.tech} Tech`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.nonTech} Non-tech`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.total} Total`}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Type',
          type: 'text',
          render: ({row, styles}) => {
            let {rowText = {}} = styles || {};
            const {type} = row || {};
            let color;
            if (type === 'Desirable') {
              color = 'Accent2';
            } else if (type === 'Regrettable') {
              color = 'Accent1';
            }
            return (
              <Tag
                value={type === 'undefined' ? 'Not Available' : type}
                color={color}
              />
            );
          },
        },
        {
          field: 'tech',
          header: 'Tech',
          type: 'text',
          onPress: ({row}) => {
            navigation.navigate('voluntary-wise-tech-attrition', {...row});
          },
        },
        {
          field: 'nonTech',
          header: 'Non-Tech',
          type: 'text',
          onPress: ({row}) => {
            navigation.navigate('voluntary-wise-non-tech-attrition', {
              ...row,
            });
          },
        },
        {
          field: 'total',
          header: 'Total',
          type: 'text',
        },
      ]}
    />
  );
};

export const ReasonRegrettableAttritionTable = props => {
  let {navigation, route, periods} = props || {};
  let {params = {}} = route || {};
  params = {...params, periods};
  return (
    <BorderedGroupTable
      api="/employees/reason-wise-voluntary-attrition"
      eventSourceId="TotalAttrition"
      params={params}
      // variant={'bordered'}
      groupRow={{
        data: '_children',
        header: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: periods?.monthWiseBreakup
                    ? 'Month'
                    : periods?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.count} Total`}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Reasons',
          type: 'text',
          width: 600,
          render: ({row, styles}) => {
            const {name} = row || {};
            let color;
            if (name === 'Alternate') {
              color = 'Accent2';
            } else if (name === 'Dissatisfaction') {
              color = 'Accent1';
            } else if (name === 'Higher Studies') {
              color = 'Accent3';
            } else if (name === 'Personal') {
              color = 'Accent6';
            } else if (name === 'Relocation') {
              color = 'Accent4';
            } else {
              color = 'Accent5';
            }
            return <Tag value={name ? name : 'Not Available'} color={color} />;
          },
          // render: ({row, styles}) => {
          //   let {rowText} = styles;
          //   return (
          //     <Text style={{...rowText}}>{row.name || 'Not Available'}</Text>
          //   );
          // },
        },
        // {
        //   field: 'totalreasonCount',
        //   header: 'Total',
        //   type: 'text',
        // },
        {
          header: 'Tech Resigned',
          width: 200,
          render: CountCellRender,
          count_field: 'resignedCount',
          align: 'right',
          onPress: props => {
            navigation.navigate('reason-wise-resigned-attrition', {...props});
          },
        },
      ]}
    />
  );
};

export const ExperinceRegrettableAttritionTable = props => {
  let {navigation, route, periods} = props || {};
  let {params = {}} = route || {};
  params = {...params, periods};
  return (
    <GroupTable
      api="/employees/experience-wise-voluntary-attrition"
      eventSourceId="TotalAttrition"
      params={params}
      groupRow={{
        data: '_children',
        header: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: periods?.monthWiseBreakup
                    ? 'Month'
                    : periods?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.techExperience} Tech`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.techResigned} Tech Resigned`}
              </Text>
              {/* <Text
                style={{
                  ...styles,
                }}>
                {`${row?.nonTechExperience} Non-Tech`}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.nonTechResigned} Non-Tech Resigned`}
              </Text> */}
              {/* <Text
                style={{
                  ...styles,
                }}>
                {`${row?.totalExperience} Total`}
              </Text> */}
              {/* <Text
                style={{
                  ...styles,
                }}>
                {`${row?.totalResigned} Total Resigned`}
              </Text> */}
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Experience',
          width: 600,

          render: ({row, styles}) => {
            const {experience} = row || {};
            const colors = useTheme('colors');
            if (experience == '10+') {
              return <Tag value={experience} color={colors.ACCENT3_MEDIUM} />;
            }
            if (experience == '6-10' || experience == '1-2') {
              return <Tag value={experience} color={colors.ACCENT5_MEDIUM} />;
            }
            if (experience == '4-6') {
              return <Tag value={experience} color={colors.ACCENT2_MEDIUM} />;
            }
            if (experience == '2-4') {
              return <Tag value={experience} color={colors.ACCENT4_MEDIUM} />;
            }
            if (experience == '0-1') {
              return <Tag value={experience} color={colors.ACCENT6_MEDIUM} />;
            }
          },
        },
        {
          header: 'Tech Resigned',
          width: 200,
          render: CountCellRender,
          count_field: 'techResigned',
          align: 'right',
          onPress: props => {
            navigation.navigate('experience-wise-tech-attrition', {...props});
          },
        },
        // {
        //   header: 'Non Tech',
        //   type: 'text',
        //   render: ({row, styles}) => {
        //     let {rowText} = styles;
        //     return (
        //       <View
        //         style={{
        //           ...styles,
        //           flexDirection: 'row',
        //           justifyContent: 'space-between',
        //         }}>
        //         <Text style={{...rowText}}>{row.nonTechExperience}</Text>
        //         <Text
        //           style={{...rowText, color: 'green'}}
        //           onClick={() => {
        //             navigation.navigate('experience-wise-nontech-attrition', {
        //               ...row,
        //             });
        //           }}>
        //           {row.nonTechResigned}
        //         </Text>
        //       </View>
        //     );
        //   },
        // },
        // {
        //   header: 'Total',
        //   type: 'text',
        //   render: ({row, styles}) => {
        //     let {rowText} = styles;
        //     return (
        //       <View
        //         style={{
        //           ...styles,
        //           flexDirection: 'row',
        //           justifyContent: 'space-between',
        //         }}>
        //         <Text style={{...rowText}}>{row.totalExperience}</Text>
        //         <Text style={{...rowText, color: 'green'}}>
        //           {row.totalResigned ? row.totalResigned : 0}
        //         </Text>
        //       </View>
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const PracticeRegrettableAttritionTable = props => {
  let {navigation, route, periods} = props || {};
  let {params = {}} = route || {};
  params = {...params, periods};
  return (
    <BorderedGroupTable
      api="/employees/practice-wise-voluntary-attrition"
      eventSourceId="TotalAttrition"
      params={params}
      // variant={'bordered'}
      groupRow={{
        data: '_children',
        header: true,
        leftContent: ({row}) => {
          const styles = {
            color: '#7D7D82',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
          };
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <Text
                style={{
                  ...styles,
                  color: '#000000',
                }}>
                {getDateRangeDisplay({
                  from: row?.startDate,
                  to: row?.endDate,
                  filterOf: periods?.monthWiseBreakup
                    ? 'Month'
                    : periods?.filterOf,
                })}
              </Text>
              <Text
                style={{
                  ...styles,
                }}>
                {`${row?.count}/${row?.total}`}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Primary Skill',
          type: 'text',
          width: 600,
          render: ({row, styles}) => {
            let {rowText} = styles;

            const colorArray = [
              'Accent 1',
              'Accent 2',
              'Accent 3',
              'Accent 4',
              'Accent 5',
              'Accent 6',
            ];

            return (
              <ColorTag
                value={row.name || 'No Practice'}
                color={
                  colorArray[Math.floor(Math.random() * colorArray.length)]
                }
              />
            );
          },
        },
        // {
        //   field: 'totalPracticeCount',
        //   header: 'Total',
        //   type: 'text',
        // },
        {
          render: CountCellRender,
          count_field: 'resignedCount',
          align: 'right',
          header: 'Resigned',
          width: 200,
          onPress: props => {
            navigation.navigate('practice-wise-resigned-attrition', {...props});
          },
        },
      ]}
    />
  );
};

export const totalVoluntaryTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  const {
    row: {type, from_date, to_date},
  } = params;
  let desireable_status = null;
  let addOnFilter = {
    legal: {$in: [null, false]},
    exit_type: 'Voluntary',
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    // desireable_status: {$exists: false},
    department: {$in: technicalDepartmentIds},
  };
  if (params?.isDesriable) {
    addOnFilter.desireable_status = 'Desirable';
  }
  if (params?.isRegreatable) {
    addOnFilter.desireable_status = 'Regretable';
  }
  if (params?.isUnknown) {
    addOnFilter.desireable_status = {$exists: false};
  }
  if (params?.isInvoluntary) {
    addOnFilter.exit_type = 'Involuntary';
  }
  if (params?.row?.type == 'Non-Engineers') {
    addOnFilter.department = {$nin: technicalDepartmentIds};
  }
  if (params?.row?.type == 'Engineers') {
    addOnFilter.department = {$in: technicalDepartmentIds};
  }

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};

export const totalVoluntaryNonTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {
    row: {type, from_date, to_date},
  } = params;
  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  let addOnFilter = {
    exit_type: type,
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    department: {
      $nin: technicalDepartmentIds,
    },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};

export const voluntaryWiseTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {type, from_date, to_date} = params;
  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  let addOnFilter = {
    // desireable_status: type,
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    department: {
      $in: technicalDepartmentIds,
    },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};
export const voluntaryWiseNonTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {from_date, to_date, type} = params;
  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  let addOnFilter = {
    // desireable_status: type,
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    department: {
      $nin: technicalDepartmentIds,
    },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};

export const ReasonWiseResignedTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {
    row: {_id, from_date, to_date},
  } = params;
  let addOnFilter = {
    legal: {$in: [null, false]},
    resign_reason: _id,
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};

export const experienceWiseTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {from_date, to_date, row} = params;
  const {techResignedIds} = row;
  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  let addOnFilter = {
    _id: {$in: techResignedIds},
    // resign_date_new: {
    //   $gte: from_date,
    //   $lte: to_date,
    // },
    // department: {
    //   $in: technicalDepartmentIds,
    // },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};
export const experienceWiseNonTechTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {from_date, to_date, nonTechResignedIds} = params;
  const technicalDepartmentIds = [
    '5848ffe17d89cd3f17c7335c',
    '589e9ea16a39a6208813d617',
    '5849228434d56b42ec1aabd2',
    '599c0536ac02221f8db97504',
  ];
  let addOnFilter = {
    _id: {$in: nonTechResignedIds},
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    department: {
      $nin: technicalDepartmentIds,
    },
  };

  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};

export const PracticeWiseResignedTable = props => {
  let {
    navigation,
    route: {params},
  } = props;
  const {
    row: {_id, from_date, to_date},
  } = params;
  let addOnFilter = {
    legal: {$in: [null, false]},
    resource_language: _id,
    resign_date_new: {
      $gte: from_date,
      $lte: to_date,
    },
    department: {
      $in: [
        '5848ffe17d89cd3f17c7335c',
        '589e9ea16a39a6208813d617',
        '5849228434d56b42ec1aabd2',
        '599c0536ac02221f8db97504',
      ],
    },
  };
  if (params?.row?._id) {
    addOnFilter.resource_language = params?.row?._id;
  } else {
    addOnFilter.resource_language = {$exists: false};
  }
  return (
    <Table
      api="/employees/total-voluntary-tech-attrition"
      params={params}
      addOnFilter={addOnFilter}
      eventSourceId="TotalVoluntaryTech"
      columns={[
        {
          header: 'Name',
          type: 'text',
          width: 350,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
        },
        {
          field: 'experience',
          header: 'Experience',
          type: 'text',
        },
        {
          field: 'joining_date',
          header: 'Joining Date',
          type: 'date',
        },
        {
          field: 'resign_date_new',
          header: 'Resign Date',
          type: 'date',
        },
        {
          field: 'expected_relieving_date',
          header: 'Relieving Date',
          type: 'date',
        },
      ]}
    />
  );
};
