import React from 'react';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {BudgetApproverTable} from './BudgetApproverTable';
import {useAppStateContext} from '../../../providers/AppState';

export const BudgetApproverTab = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <BudgetApproverTable
          tab={'BudgetApproverActive'}
          addOnFilter={{status: 'Requested'}}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: `/budget/budgetdetail`,
      addOnFilter: {
        status: 'Requested',
        approver: employee._id,
        ...filterValues.filter,
      },
    },
    close: {
      label: 'Closed',
      view: (
        <BudgetApproverTable
          tab={'BudgetApproverClosed'}
          addOnFilter={{status: {$in: ['Approved', 'Rejected']}}}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: `/budget/budgetdetail`,
      addOnFilter: {
        status: {$in: ['Approved', 'Rejected']},
        approver: employee._id,
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
          ]}
        />,
      ]}
    />
  );
};
