import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {Button} from '../../../components/button/Button';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  ExperienceWiseAnalytics,
  RatingWiseAnalytics,
  SkillWiseAnalytics,
} from './EnggAnalyticsList';

export const EnggAnalyticsTabs = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {department} = params;
  const defaultFilter = {
    department: department,
  };
  if (fromICMenu) {
    defaultFilter.is_manager = false;
  }
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {download} = useAppStateContext();
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    experience: {
      label: 'Experience Wise',
      view: (
        <ExperienceWiseAnalytics
          searchValue={searchValue}
          {...props}
          addOnFilter={{
            ...filter,
          }}
        />
      ),
    },
    skill: {
      label: 'Skill Wise',
      view: (
        <SkillWiseAnalytics
          searchValue={searchValue}
          {...props}
          addOnFilter={{
            ...filter,
          }}
        />
      ),
    },
    rating: {
      label: 'Rating Wise',
      view: (
        <RatingWiseAnalytics
          searchValue={searchValue}
          {...props}
          addOnFilter={{
            ...filter,
          }}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
          ]}
        />,
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, is_AllBenchEmployee: true},
                addOnFilter: {
                  ...filter,
                },
              },
            });
          }}
        />,
      ]}
      {...props}
    />
  );
};
