import React from 'react';
import {Table} from '../../../components/table/Table';
import {TextRenderer} from '../../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';

export const DepartmentPerformaceDetailsTable = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {departmentId, period, addOnFilter, invoice_type} = params;

  const {colors} = useTheme();

  return (
    <Table
      api="/departmentWisePerformanceOverview"
      limit = {50000}
      params={{
        departmentId,
        period: {
          from: period.from,
          to: period.to,
        },
        invoice_type,
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Purchase Category',
          field: 'purchase_category.name',
          type: 'text',
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview', {
              ...params,
              purchaseCategoryId: row?.purchase_category?._id,
              invoice_type,
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview', {
              ...params,
              purchaseCategoryId: row?.purchase_category?._id,
              period: {
                from: moment(period.from).subtract(1, 'months').toDate(),
                to: moment(period.to).subtract(1, 'months').toDate(),
              },
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Difference',
          type: 'text',
          align: 'left',
          field: 'expense_diff',
          formatOptions: {
            maximumFractionDigits: 0,
          },
          width: 200,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                Number(Math.abs(row.expense_diff)).toFixed(0) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          aggregate: true,
        },
      ]}
    />
  );
};

export const DepartmentPerformaceDetailsTableForSalary = props => {
  const {
    route: {params},
    navigation,
  } = props;

  const {departmentId, period, addOnFilter, invoice_type} = params;

  const {colors} = useTheme();

  return (
    <Table
      api="/departmentWisePerformanceOverviewForSalary"
      limit = {50000}
      params={{
        departmentId,
        period: {
          from: period.from,
          to: period.to,
        },
        invoice_type,
      }}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Employee',
          field: 'payee_id',
          type: 'userAvatarChip',
        },
        {
          header: 'Current Expense',
          field: 'current_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview-salary', {
              ...params,
              vendorId: row?.payee_id?._id,
              invoice_type,
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Previous Expense',
          field: 'previous_expense',
          type: 'number',
          aggregate: true,
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('purchase-category-overview-salary', {
              ...params,
              vendorId: row?.payee_id?._id,
              period: {
                from: moment(period.from).subtract(1, 'months').toDate(),
                to: moment(period.to).subtract(1, 'months').toDate(),
              },
            });
          },
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Difference',
          type: 'text',
          align: 'left',
          field: 'expense_diff',
          formatOptions: {
            maximumFractionDigits: 0,
          },
          width: 200,
          render: ({row}) => (
            <TextRenderer
              style={{
                color:
                  row.expense_diff >= 0
                    ? colors.ERROR_HIGH
                    : colors.SUCCESS_HIGH,
              }}
              value={
                Number(Math.abs(row.expense_diff)).toFixed(0) +
                '(' +
                Number(Math.abs(row.expense_diff_percentage).toFixed(2)) +
                '%)'
              }
            />
          ),
          aggregate: true,
        },
      ]}
    />
  );
};
