import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useAppStateContext} from '../../../providers/AppState';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {TextRenderer} from '../../../components/form/Renderers';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {TabView} from '../../../components/tab';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';

const RenderBatch = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer value={row.training_batch?.name} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.training_batch?.training_program?.name} />
        <GroupContentItem
          value={`Batch ${row?.training_batch?.batch_number}`}
        />
      </RowWithSeparator>
    </View>
  );
};

const RenderFeedback = ({row}) => {
  const {training_feedbacks = []} = row;
  const sum = training_feedbacks.reduce((acc, one) => {
    return acc + one.overall_score;
  }, 0);
  const avg_rating = sum / (training_feedbacks.length || 1);
  return <RatingRenderer value={avg_rating} />;
};

const ExtendedDateInfoRender = props => {
  let {row, onIconPress} = props;
  let {to_date, extended_date} = row || {};
  let {EstChange} = useTheme('icons');

  return (
    <Row gap={1}>
      <Text
        style={{
          color: '#A3A3A3',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
        }}>
        {to_date ? moment(extended_date || to_date).format('DD MMM') : ''}
      </Text>
      {extended_date ? (
        <View
          onPress={() => onIconPress({row})}
          style={{marginLeft: 4, height: 16, width: 16}}>
          <Image source={EstChange} />
        </View>
      ) : (
        void 0
      )}
    </Row>
  );
};

export const MyCourses = props => {
  const {navigation, addOnFilter, isCompleted} = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api="/employeeTrainings"
      fields={{
        mentor: {
          name: 1,
          color: 1,
        },
        training_batch: {
          skill_metrics: {
            name: 1,
            color: 1,
          },
          name: 1,
          batch_number: 1,
          training_program: {
            name: 1,
            training_source: 1,
            course_type: 1,
          },
          mentorship_type: 1,
        },
        from_date: 1,
        to_date: 1,
        extended_date_details: 1,
        is_extended: 1,
        employee: {
          name: 1,
        },
        training_feedbacks: {
          overall_score: 1,
        },
        trainee_to_training_feedback: {
          _id: 1,
        },
      }}
      filter={{
        employee: employeeId,
      }}
      addOnFilter={{
        ...addOnFilter,
      }}
      columns={[
        {
          header: 'Batch',
          render: RenderBatch,
        },
        {
          field: 'training_batch.training_program.course_type',
          header: 'Type',
          type: 'colorTag',
          colorField: row =>
            row?.training_batch?.training_program?.course_type == 'Tech'
              ? '#FFF7D6'
              : '#F5E0FF',
          width: 120,
        },
        {
          header: 'Trainer',
          field: 'mentor',
          type: 'userAvatar',
        },
        {
          header: 'Skill',
          field: 'training_batch.skill_metrics.name',
          colorField: 'training_batch.skill_metrics.color',
          type: 'colorTag',
        },
        {
          header: 'Start Date',
          field: 'from_date',
          type: 'date',
        },
        {
          render: ExtendedDateInfoRender,
          header: 'End',
          onIconPress: ({row}) => {
            navigation.navigate('extended_date_details', {
              data: row?.extended_date_details || [],
              header: {
                name: row?.employee?.name,
              },
              training_batch: row.training_batch,
            });
          },
          width: 75,
        },
        {
          header: 'Feedback',
          render: RenderFeedback,
          onPress: ({row}) => {
            navigation.navigate('my-feedbacks', {
              employee_training: row,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'My Attendance',
          onPress: ({row}) => {
            navigation.navigate('my-training-attendance', {
              employee_training: row,
              training_batch: row.training_batch,
            });
          },
        },
        {
          title: 'Give Feedback',
          visible: !!isCompleted,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedbacks', {
              employee_training: row,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const MyCourseTabs = props => {
  const {
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <TabView
      headerComponent={() => <TableHeader title="My Courses" />}
      tabs={{
        active: {
          label: 'Ongoing',
          view: (
            <MyCourses
              addOnFilter={{
                status: {$in: ['active', 'extended']},
              }}
            />
          ),
          api: '/employeeTrainings',
          filter: {
            employee: employeeId,
            status: {$in: ['active', 'extended']},
          },
        },
        completed: {
          label: 'Completed',
          view: (
            <MyCourses
              addOnFilter={{
                status: 'completed',
              }}
              isCompleted
            />
          ),
          api: '/employeeTrainings',
          filter: {
            employee: employeeId,
            status: 'completed',
          },
        },
      }}
      {...props}
    />
  );
};
