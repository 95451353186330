import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {useInvoke} from '../../../controllers/useInvoke';

export const useProjectActions = ({navigation, params}) => {
  const invoke = useInvoke({method: 'put', eventSourceId: 'Project'});
  const {navigateTo} = useLeftMenuContext() || {};

  const projectDetail = ({row, ...props}) => {
    if (navigateTo) {
      navigateTo(`project-detail-new`, {
        _id: row._id,
        project_id: row?._id,
        project: row,
        showHelpData: params?.showHelpData,
      });
    } else {
      navigation.navigate(`project-home`, {
        _id: row._id,
        project: row,
      });
    }
  };

  const projectDetailForOutputs = ({row}) => {
    navigation.navigate(`project-home`, {
      _id: row._id,
      project: row?.project,
    });
  };

  const projectTeam = ({row}) => {
    navigation.navigate('project-team', {
      project: row,
    });
  };

  const editProject = ({row}) => {
    navigation.navigate(`project-detail`, {
      // ...params,
      project: {_id: row?._id},
    });
  };

  const projectDescription = ({row}) => {
    navigation.navigate(`project-description-form`, {
      // ...params,
      project: row,
    });
  };

  const editProjectRouteForNonAdmin = ({row}) => {
    navigation.navigate(`project-detail`, {
      // ...params,
      project: {_id: row?._id},
      formAdmin: true,
    });
  };

  const editUnbilledProject = ({row}) => {
    navigation.navigate(`project-unbilled-detail`, {
      // ...params,
      project: {_id: row?._id},
    });
  };

  const projectOrderMappings = ({row}) => {
    navigation.navigate(`project-order-mapping-list`, {
      // ...params,
      project: {_id: row?._id},
    });
  };

  const projectOrderMappingsForTm = ({row}) => {
    navigation.navigate(`project-order-mapping-list`, {
      project: {_id: row?._id},
      formTAndM: true,
    });
  };

  const projectPlanDetails = ({row}) => {
    navigation.navigate(`project-order-plan-details`, {
      // ...params,
      project: {_id: row?._id},
    });
  };

  const markReleased = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        status: 'released',
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        status: 'backlog',
      },
    });
  };

  const markDelete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        status: 'deleted',
      },
    });
  };

  const activityLogDetail = props => {
    let {row} = props || {};
    navigation.navigate(`activity-logs`, {
      _id: row?._id,
      api: `/projects/${row?._id}/activities`,
    });
  };

  const activityLogDetailForLead = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'owner', select: {name: 1}},
        // {path: 'owner', select: {name: 1}},
      ],
      api: `/potentialcustomers/${row?._id}/activityLogs`,
      displayFields: {
        desc: {
          label: 'Description',
        },
        status: {
          label: 'Status',
        },
        owner: {
          label: 'Owner',
          field: 'owner.name',
        },
        lead_status: {
          label: 'Lead Status',
          field: 'lead_status',
        },
        lost_date: {
          label: 'Lost Date',
        },
        lost_description: {
          label: 'Lost Description',
        },
        lost_description_comment: {
          label: 'Lost Description Comment',
        },
        nurturable_date: {
          label: 'Nurtuable Date',
        },
        last_communication: {
          label: 'Last Communication',
        },
        last_communication_date: {
          label: 'Last Communication Date',
        },
        activity_date: {
          label: 'Activity Date',
        },
        next_task: {
          label: 'Next Task',
        },
        next_task_date: {
          label: 'Next Task Date',
        },
      },
    });
  };

  const activityLogDetailForProspect = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'owner', select: {name: 1}},
        // {path: 'campaign_type', select: {name:1}}
        // {path: 'owner', select: {name: 1}},
      ],
      api: `/potentialcustomers/${row?._id}/activityLogs`,
      displayFields: {
        desc: {
          label: 'Description',
        },
        status: {
          label: 'Status',
        },
        owner: {
          label: 'Owner',
          field: 'owner.name',
        },
        campaign_id: {
          lablel: 'Campaign',
          field: 'campaign_id.name',
        },
        lead_status: {
          label: 'Lead Status',
          field: 'lead_status',
        },
        lost_date: {
          label: 'Lost Date',
        },
        lost_description: {
          label: 'Lost Description',
        },
        lost_description_comment: {
          label: 'Lost Description Comment',
        },
        nurturable_date: {
          label: 'Nurtuable Date',
        },
        last_communication: {
          label: 'Last Communication',
        },
        last_communication_date: {
          label: 'Last Communication Date',
        },
        activity_date: {
          label: 'Activity Date',
        },
        next_task: {
          label: 'Next Task',
        },
        next_task_date: {
          label: 'Next Task Date',
        },
      },
    });
  };

  const activityLogForIncomeTaxAccounts = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [{path: 'owner', select: {name: 1}}],
      api: `/incometaxes/${row?._id}/activityLogs`,
      displayFields: {
        status: {
          label: 'Status',
        },
        submitted_date: {
          label: 'Sunbmitted Date',
        },
        reopen_date: {
          label: 'Reopen Date',
        },
        accepted_date: {
          label: 'Accepted Date',
        },
        submitted_by: {
          label: 'Submitted By',
          field: 'submitted_by.name',
        },
        reopen_by: {
          label: 'Reopen By',
          field: 'reopen_by.name',
        },
        accepted_by: {
          label: 'Accepted By',
          field: 'accepted_by.name',
        },
      },
    });
  };

  const activityLogDetailForOpportunities = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'rfq_owner', select: {name: 1}},
        {path: 'tech_person', select: {name: 1}},
        {path: 'primary_owner', select: {name: 1}},
        {path: 'requirement_document', select: {filename: 1}},
      ],
      api: `/opportunities/${row?._id}/activityLogs`,
      displayFields: {
        submitted_quote_description: {
          label: 'Quote Description',
        },
        quoted_hours: {
          label: 'Quoted Hrs',
        },
        proposal_owner: {
          label: 'Proposal Owner',
          field: 'proposal_owner.name',
        },
        requirement_document: {
          label: 'Attatchment',
          field: 'requirement_document',
          type: 'file',
        },
        rfq_owner: {
          label: 'Pre Sales Owner',
          field: 'rfq_owner.name',
        },
        tech_person: {
          label: 'Solution Consultant',
          field: 'tech_person.name',
        },
        primary_owner: {
          label: 'Primary Solution Consultant',
          field: 'primary_owner.name',
        },
        proposal_link: {
          label: 'Proposal Link',
          field: 'proposal_link',
        },
        quoted_document: {
          label: 'Quoted Document',
        },
        quoted_amount: {
          label: 'Quoted Amount',
        },
        proposal_submit_date: {
          label: 'Proposal Submitted Date',
        },
        skill: {
          label: 'Skill',
        },
      },
    });
  };

  return {
    projectDetail,
    projectDetailForOutputs,
    projectTeam,
    editProject,
    projectDescription,
    markActive,
    editUnbilledProject,
    markReleased,
    markDelete,
    activityLogDetail,
    projectPlanDetails,
    projectOrderMappings,
    activityLogDetailForLead,
    activityLogDetailForProspect,
    activityLogForIncomeTaxAccounts,
    activityLogDetailForOpportunities,
    projectOrderMappingsForTm,
    editProjectRouteForNonAdmin,
  };
};
