import React, {useEffect, useState} from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';

export const TeamAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  return (
    <Table
      api={'/monthlyattendance/lop/direct-team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`team-employee-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'employee',
          type: 'userAvatarChip',
          // onPress: ({row}) => {
          //   navigation.navigate('employee-details-form', {
          //     row,
          //   });
          // },
        },
        {
          header: 'Present',
          field: 'details.present_days',
          type: 'number',
        },
        {
          header: 'Off',
          field: 'details.compensatory_off',
          type: 'number',
          variant: 'secondary',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.details.compensatory_off}
              </Text>
            );
          },
        },
        {
          header: 'EWD',
          field: 'details.ewd_payable',
          type: 'number',
        },
        {
          header: 'Leaves',
          field: 'details.leave_days',
          type: 'number',
        },
        {
          header: 'Holiday',
          field: 'details.holidays',
          type: 'number',
        },
        {
          header: 'Absent',
          field: 'details.absent_days',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: row?.details.absent_days > 0 ? '#FF3B30' : '#737373',
                }}>
                {row?.details.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color:
                    row?.details.un_paid_leaves + row?.details?.absent_days > 0
                      ? '#FF3B30'
                      : '#737373',
                }}>
                {row?.details.un_paid_leaves + row?.details?.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
        {
          header: 'Total',
          type: 'text',
          value: ({row}) => {
            if (row && row?.details) {
              return (
                row?.details?.present_days +
                row?.details?.compensatory_off +
                row?.details?.ewd_payable +
                row?.details?.paid_leaves +
                row?.details?.holidays -
                row?.details?.absent_days -
                row?.details?.un_paid_leaves
              );
            }
          },
          width: 150,
        },
      ]}
    />
  );
};
export const RecusiveTeamAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  return (
    <Table
      api={'/monthlyattendance/lop/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`team-employee-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'employee',
          type: 'userAvatarChip',
          // onPress: ({row}) => {
          //   navigation.navigate('employee-details-form', {
          //     row,
          //   });
          // },
        },
        {
          header: 'Present',
          field: 'details.present_days',
          type: 'number',
        },
        {
          header: 'Off',
          field: 'details.compensatory_off',
          type: 'number',
          variant: 'secondary',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.details.compensatory_off}
              </Text>
            );
          },
        },
        {
          header: 'EWD',
          field: 'details.ewd_payable',
          type: 'number',
        },
        {
          header: 'Leaves',
          field: 'details.leave_days',
          type: 'number',
        },
        {
          header: 'Holiday',
          field: 'details.holidays',
          type: 'number',
        },
        {
          header: 'Absent',
          field: 'details.absent_days',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: row?.details.absent_days > 0 ? '#FF3B30' : '#737373',
                }}>
                {row?.details.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color:
                    row?.details.un_paid_leaves + row?.details?.absent_days > 0
                      ? '#FF3B30'
                      : '#737373',
                }}>
                {row?.details.un_paid_leaves + row?.details?.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
      ]}
    />
  );
};

export const PeopleManagerTeamAttendance = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  return (
    <Table
      api={'/monthlyattendance/lop/people-manager-team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={filter}
      search={searchValue}
      onRowPress={({row}) => {
        navigation.navigate(`team-employee-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      columns={[
        {
          header: 'Name',
          field: 'employee',
          type: 'userAvatarChip',
          // onPress: ({row}) => {
          //   navigation.navigate('employee-details-form', {
          //     row,
          //   });
          // },
        },
        {
          header: 'Present',
          field: 'details.present_days',
          type: 'number',
        },
        {
          header: 'Off',
          field: 'details.compensatory_off',
          type: 'number',
          variant: 'secondary',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                }}>
                {row?.details.compensatory_off}
              </Text>
            );
          },
        },
        {
          header: 'EWD',
          field: 'details.ewd_payable',
          type: 'number',
        },
        {
          header: 'Leaves',
          field: 'details.leave_days',
          type: 'number',
        },
        {
          header: 'Holiday',
          field: 'details.holidays',
          type: 'number',
        },
        {
          header: 'Absent',
          field: 'details.absent_days',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: row?.details.absent_days > 0 ? '#FF3B30' : '#737373',
                }}>
                {row?.details.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'LOP',
          field: 'details.un_paid_leaves',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color:
                    row?.details.un_paid_leaves + row?.details?.absent_days > 0
                      ? '#FF3B30'
                      : '#737373',
                }}>
                {row?.details.un_paid_leaves + row?.details?.absent_days}
              </Text>
            );
          },
        },
        {
          header: 'Payable',
          field: 'details.total_salary_days',
          type: 'number',
        },
        {
          header: 'Total',
          type: 'text',
          value: ({row}) => {
            if (row && row?.details) {
              return (
                row?.details?.present_days +
                row?.details?.compensatory_off +
                row?.details?.ewd_payable +
                row?.details?.paid_leaves +
                row?.details?.holidays -
                row?.details?.absent_days -
                row?.details?.un_paid_leaves
              );
            }
          },
          width: 150,
        },
      ]}
    />
  );
};

export const PeopleManagerLeaveRegisterTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  return (
    <Table
      api={'/monthlyattendance/leaveregister/people-manager'}
      params={params}
      eventSourceId={'leaveRegister'}
      addOnFilter={filter}
      search={searchValue}
      columns={[
        {
          header: 'Name',
          field: 'employee',
          type: 'userAvatarChip',
        },
        {
          header: 'Available',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (
              row &&
              row.leave_details &&
              row.leave_details.opening_leave_balance
            ) {
              return (
                <Text style={{...rowText}}>
                  {row?.leave_details?.opening_leave_balance?.toFixed(1)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Accured',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.accrued_leaves) {
              return (
                <Text style={{...rowText}}>
                  {row.leave_details.accrued_leaves.toFixed(2)}
                </Text>
              );
            } else {
              return '0';
            }
          },
        },
        {
          header: 'Consumed',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.consumed_leaves) {
              let value = parseFloat(
                row.leave_details.consumed_leaves.toFixed(1),
              );
              return (
                <Text style={{...rowText, color: '#007AFF'}}>{value}</Text>
              );
            } else {
              return <Text style={{...rowText, color: '#007AFF'}}>0</Text>;
            }
          },
        },
        {
          header: 'Balance',
          type: 'number',
          render: ({row, styles: {rowText = {}}}) => {
            if (row && row.leave_details && row.leave_details.leave_balance) {
              return (
                <Text style={{...rowText, color: '#34C759'}}>
                  {parseFloat(row.leave_details.leave_balance.toFixed(3))}
                </Text>
              );
            } else {
              return <Text style={{...rowText, color: '#34C759'}}>0</Text>;
            }
          },
        },
      ]}
    />
  );
};

export const TeamUnapprovedAttendanceTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  let addOnFilter = {...filter, status: 'unapproved'};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendanceUnapproved',
  });
  return (
    <Table
      api={'/attendance/team'}
      params={params}
      eventSourceId="teamAttendanceUnapproved"
      addOnFilter={addOnFilter}
      search={searchValue}
      onRowPress={({row}) => {
        if (row && row.status === 'unapproved') {
          navigation.navigate(`update-team-attendance-request`, {
            row,
          });
        }
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee.name',
          type: 'text',
        },
        {
          header: 'Date',
          field: 'attendance_date',
          type: 'date',
        },
        {
          header: 'First',
          field: 'first_attendance_type_id.name',
          type: 'text',
        },
        {
          header: 'Second',
          field: 'second_attendance_type_id.name',
          type: 'text',
        },
        {
          header: 'In',
          field: 'in_time',
          type: 'text',
        },
        {
          header: 'Out',
          field: 'out_time',
          type: 'text',
        },
        {
          header: 'Time',
          field: 'time_in_office',
          type: 'text',
        },
        {
          header: 'Break',
          field: 'break_time',
          type: 'text',
        },
        {
          header: 'Remarks',
          field: 'remarks',
          type: 'text',
        },
      ]}
      moreActions={[
        {
          title: 'Approve',
          variant: 'error',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to Approve this Request?',
          },
          visible: ({row}) => {
            if (row && row.status === 'approve') {
              return false;
            } else {
              return true;
            }
          },
          onPress: ({row}) => {
            if (row && row.status !== 'approve') {
              invoke({
                uri: `/employee/DailyAttendance/team/${row?._id}`,
                props: {
                  ...row,
                  status: 'approve',
                  is_approved: true,
                },
              });
            }
          },
        },
      ]}
    />
  );
};

export const TeamTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch} = useAppStateContext();
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  return (
    <Table
      api={'/team-direct'}
      params={params}
      eventSourceId={'teamAttendance'}
      search={searchValue}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
          // width: 120,
        },
        {
          align: 'center',
          placeholder: 'Email',
          type: 'text',
          label: 'Email',
          width: 80,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.official_email_id});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          placeholder: 'Mobile No',
          align: 'center',
          type: 'text',
          label: 'Mobile No',
          width: 120,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.mobile_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
    />
  );
};

export const PeopleManagerTeamTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isMobileCopied, setIsMobileCopied] = useState(false);
  const toast = useToast();

  const copyText = ({isEmail, email, mobile}) => {
    const textToCopy = isEmail ? email : mobile;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    isEmail ? setIsEmailCopied(true) : setIsMobileCopied(true);
  };

  useEffect(() => {
    if (isEmailCopied) {
      toast({message: 'Email Copied'});
      setIsEmailCopied(false);
    }
    if (isMobileCopied) {
      toast({message: 'Phone No. Copied'});
      setIsMobileCopied(false);
    }
  }, [isEmailCopied, isMobileCopied]);

  const {fetch} = useAppStateContext();
  const colors = useTheme('colors');
  const icon = useTheme('icons');
  return (
    <Table
      api={'/team/people-manager'}
      params={params}
      eventSourceId={'teamAttendance'}
      search={searchValue}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
        },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
          width: 300,
          // width: 120,
        },
        {
          align: 'center',
          placeholder: 'Email',
          type: 'text',
          label: 'Email',
          width: 80,
          render: ({row}) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  copyText({isEmail: true, email: row?.official_email_id});
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image source={icon.atTheRate} />
              </TouchableOpacity>
            );
          },
        },
        {
          placeholder: 'Mobile No',
          align: 'center',
          type: 'text',
          label: 'Mobile No',
          width: 120,
          render: ({row}) => {
            return (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => {
                  copyText({
                    isEmail: false,
                    mobile: row?.mobile_number,
                  });
                }}>
                <Image source={icon.phone} />
              </TouchableOpacity>
            );
          },
        },
      ]}
    />
  );
};
