import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {Labels} from '../constants/Label';

export const FeatureForm = props => {
  const {
    route: {params = {}},
    editForm,
    defaultValues,
  } = props;

  const {project, module, readOnly, milestone, afterSubmit, projectlibrary_id} =
    params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'Feature',
    uri: '/projectfeatures',
    ...props,
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      readOnly={readOnly}
      afterSubmit={afterSubmit}
      layoutFields={[
        {
          label: Labels.Feature,
          type: 'text',
          field: 'feature',
          required: true,
        },
        {
          label: 'Module',
          placeholder: 'Module',
          field: 'module_id',
          type: 'autoComplete',
          changeOnSelect: true,
          api: '/projectmodules',
          filter: {project_id: project?._id, status: {$ne: 'archived'}},
          suggestionField: 'module',
          valueField: 'module',
          readOnly: !!module?._id,
          visible: ({values}) => !values?.library_id,
        },
        {
          collapsedFields: [
            {
              label: 'Milestone',
              field: 'milestone_id',
              type: 'autoComplete',
              api: `/projectmilestones`,
              suggestionField: 'milestone',
              valueField: 'milestone',
              filter: {
                project_id: project?._id,
                status: {$in: ['active', 'backlog']},
              },
              size: 'medium',
              visible: ({values}) =>
                !values?.library_id && (editForm || !milestone?._id),
            },
            {
              label: 'Description',
              type: 'textArea',
              field: 'desc',
              inputProps: {
                minRows: 3,
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddFeatureForm = props => {
  const {
    route: {params},
  } = props;
  const {project, module, projectlibrary_id, feature_type, milestone, status} =
    params;

  const defaultValues = {
    project_id: project?._id,
    milestone_id: milestone,
    library_id: projectlibrary_id,
  };

  if (module?._id) {
    defaultValues.module_id = {_id: module?._id, module: module?.module};
  }

  if (feature_type === 'issue') {
    defaultValues.type = 'issue';
    defaultValues.status = 'active';
  } else {
    defaultValues.type = 'feature';
    defaultValues.status = 'backlog';
  }
  if (status) {
    defaultValues.status = status;
  }

  let header = `Add ${Labels.Feature}`;
  return (
    <FeatureForm header={header} defaultValues={defaultValues} {...props} />
  );
};

export const UpdateFeatureForm = props => {
  const {
    route: {params},
  } = props;
  let {feature} = params;
  let header = `${Labels.Feature} Detail`;
  const {data} = useFetchFormData({
    api: '/projectfeatures',
    filter: {
      _id: feature?._id,
    },
    fields: {
      feature: 1,
      type: 1,
      owner_id: {name: 1},
      desc: 1,
      due_date: 1,
      milestone_id: {milestone: 1},
      module_id: {module: 1},
      library_id: {type: 1},
    },
  });
  if (!data) {
    return null;
  }
  return (
    <FeatureForm
      defaultValues={data}
      header={header}
      editForm={true}
      {...props}
    />
  );
};
