//Faiz Saeed 23/12/23 OutreachCampaignActivity Table
import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {STATUS} from '../views/TargetAudienceTable';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useAppStateContext} from '../../../providers/AppState';
import {INMAIL_CAMPAIGN_TYPE_ID} from '../../common/constants/SourceConstants';

const prospectCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'POC',
  align: 'center',

  count_field: 'prospect_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        for_customer: row.for_customer,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contacts`, {
      campaign_id: {
        _id: row._id,
        name: row.name,
        for_customer: row.for_customer,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});
const unverifiedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Unverified',
  align: 'right',

  count_field: 'unverified_action_count',
  responsive: 'sm',
  styleForCountField: true,

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-verified', {
      ...row,
      verified: {$in: [null, false]},
      status: 'pending',
      showUnverified: true,
    });
  },
});
const verifiedActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Verified',
  align: 'right',

  count_field: 'verified_action_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      verified: true,
      status: 'pending',
      showUnverified: false,
    });
  },
});
const sentActionCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 120,
  header: 'Sent',
  align: 'right',

  count_field: 'sent_action_count',
  responsive: 'sm',

  onPress: props => {
    const {row} = props;
    navigation.navigate('outreach-campaign-grouped', {
      ...row,
      sent_action: true,
      status: 'completed',
    });
  },
});

const messageCount = ({params, navigation}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Actions',
  align: 'right',

  count_field: 'campaign_message_count',
  responsive: 'sm',
  onPlusPress: props => {
    const {row} = props;
    navigation.navigate(`add-outreach-campaign-message-form`, {
      campaign_id: {_id: row._id, name: row.name},
      target_audience: {
        _id: row?.target_audience?._id,
        name: row?.target_audience?.name,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
        buyer_persona: {
          _id: row?.target_audience?._id,
          name: row?.target_audience?.name,
        },
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-messages`, {
      campaign_id: {_id: row._id, name: row.name},
      target_audience: {
        _id: row?.target_audience?._id,
        name: row?.target_audience?.name,
      },
      defaultValues: {
        campaign_id: {_id: row._id, name: row.name},
        buyer_persona: {
          _id: row?.target_audience?._id,
          name: row?.target_audience?.name,
        },
      },
      filter: {
        campaign_id: row._id,
      },
    });
  },
});

export const OutreachCampaignActivityTable = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    searchValue,
    filter,
    tabFilter = {},
  } = props;
  params = {...params, ...filterParams};
  const {user} = useAppStateContext();

  const showTasks = ({row}) => {
    navigation.navigate(`tasks-table`, {
      ...params,
      source_id: row?._id,
      row,
      source: '64d0fd55ab78dfead48c144b',

      label: row?.name,
    });
  };
  filter = {...filter, system_controlled: true};

  const {
    editOutreachCampaign,
    populateRandomContacts,
    checkLinkedinProfile,
    settingsOutreachCampaign,
    moveToNewCampaign,
    populateNextMessage,
    activityLogDetailforInmailCampaign,
    moveAllProspectsToNewCampaign,
    verifyPastMessages,
    loadRandomDataFromList,
  } = leadControllers({
    ...props,
    params: {...params, fromInmailCampaign: true},
  });

  return (
    <Table
      eventSourceId={[
        'outreachAdded',
        'inmailCampaignAdded',
        'Task',
        'campaignMessageAdded',
        'reloadImportHistoryLogs',
        'actionVerified',
        'summaryVerified',
        'indexUpdated',
        'markArchive',
        'moveToNewCampaign',
        'campaignUSPAdded',
      ]}
      api={`/outreachcampaigns/activities`}
      params={params}
      addOnFilter={{...filter, ...tabFilter}}
      search={searchValue}
      searchFields={'name'}
      columns={[
        {
          field: 'name',
          header: 'Name',
          type: 'text',
          responsive: 'sm',
        },

        {
          width: 80,
          header: 'Owner',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        {
          width: 80,
          header: 'Profile',
          field: 'linkedin_profile',
          type: 'userAvatar',
        },
        prospectCount({navigation, params}),
        messageCount({navigation, params}),
        unverifiedActionCount({navigation, params}),
        verifiedActionCount({navigation, params}),
        sentActionCount({navigation, params}),
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editOutreachCampaign,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Move to New Campaign',
          onPress: moveToNewCampaign,
        },
        {
          title: 'Move All Prospects to New Campaign',
          visible: props => {
            const {email} = user || {};
            return (
              email === 'rohit.bansal@daffodilsw.com' ||
              email === 'kanika.kashyap@unthinkable.co'
            );
          },
          onPress: moveAllProspectsToNewCampaign,
        },
        {
          title: 'Move to Batch',
          onPress: ({row}) => {
            navigation.navigate('set-batch', {
              campaign_id: row?._id,
            });
          },
          visible: ({row}) => row?.status === STATUS.RETIRED,
        },
        {
          title: 'Load Random data from List',
          onPress: ({row}) =>
            loadRandomDataFromList({
              row: {...row, campaign_type: INMAIL_CAMPAIGN_TYPE_ID},
            }),
        },
        {
          title: 'Populate Random Contacts',
          onPress: populateRandomContacts,
        },
        {
          title: 'Populate Next Message',
          onPress: populateNextMessage,
        },
        {
          title: 'Verify Past Messages',
          onPress: verifyPastMessages,
        },
        {
          title: 'Set Actual ScheduleOn',
          onPress: ({row}) => {
            navigation.navigate('set-actual-schedule-on', {
              campaign_id: row?._id,
            });
          },
        },
        {
          title: 'Edit Linkedin Profile',
          onPress: ({row}) => {
            navigation.navigate('edit-linkedin-profile', {
              row: {_id: row.linkedin_profile?._id || row.linkedin_profile},
            });
          },
        },
        {
          title: 'Check Linkedin Profile',
          onPress: checkLinkedinProfile,
        },
        {
          title: 'Edit Email Profile',
          onPress: ({row}) => {
            navigation.navigate('email-profile-edit-form', {
              row: {_id: row.email_profile?._id || row.email_profile},
            });
          },
        },
        {
          title: 'Tasks',
          onPress: showTasks,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/inmailcampaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Settings',
          onPress: settingsOutreachCampaign,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetailforInmailCampaign,
        },
      ]}
    />
  );
};
