import React from 'react';
import {Image, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import DurationChip from '../../../components/chip/DurationChip';
import {resolveExp} from '@unthinkable/react-utils';
import {Chip} from '../../../components/chip/Chip';
import Images from '../../../images';
import {toFixedDecimalValue} from '../../../utils/util';

const {EstChange} = Images;

export const EstHrsChipRender = ({count_field, released_count_field, row}) => {
  let releasedCountValue = resolveExp(row, released_count_field);
  let countValue = resolveExp(row, count_field);
  let {est_changed_count} = row || 0;

  let {estChange, estChangeStyle} = useComponentTheme('PlusColumnRenderStyles');

  let colorProps = {};
  if (
    countValue != 0 &&
    releasedCountValue != 0 &&
    countValue === releasedCountValue
  ) {
    colorProps.color = 'SUCCESS';
  }

  return (
    <View>
      <DurationChip showIcon={false} value={countValue} {...colorProps} />
      {est_changed_count ? (
        <View title={'Est effort has been updated'} style={estChangeStyle}>
          <Image source={estChange} />
        </View>
      ) : null}
    </View>
  );
};

export const EstHrsColumnRender = ({estHrsField, completedHrsField, row}) => {
  let estHrs = resolveExp(row, estHrsField);
  let completedHrs = resolveExp(row, completedHrsField);
  const est_changed_count = row?.est_changed_count || 0;
  if (!estHrs) return null;

  let colorProps = {color: 'INFORMATION'};
  let isAllCompleted = false;
  if (estHrs != 0 && completedHrs != 0 && estHrs === completedHrs) {
    colorProps.color = 'SUCCESS';
    isAllCompleted = true;
  }
  estHrs = toFixedDecimalValue(estHrs);

  let value = isAllCompleted
    ? `${estHrs} hr`
    : `${toFixedDecimalValue(completedHrs || 0)}/${estHrs} hr`;

  return (
    <View>
      <Chip displayTextColor={true} value={value} {...colorProps} />
      {est_changed_count ? (
        <View
          title={'Est effort has been updated'}
          style={{
            height: 12,
            width: 12,
            position: 'absolute',
            top: -8,
            right: -7,
          }}>
          <Image source={EstChange} />
        </View>
      ) : null}
    </View>
  );
};
