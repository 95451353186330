import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const InterviewResourcesComputations = fetch => ({
  delivery_owner: {
    compute: async value => {
      if (value?.resource?._id) {
        const {data: employeeData} = await fetch({
          uri: '/getEmployeeDeliveryOwner',
          props: {
            filter: {
              _id: value?.resource?._id,
            },
            fields: {_id: 1, delivery_owner: {_id: 1, name: 1}},
            only: true,
          },
        });
        if (
          employeeData &&
          employeeData.delivery_owner &&
          employeeData.delivery_owner._id
        ) {
          return employeeData?.delivery_owner;
        }
      }
    },
    dependencies: ['resource'],
  },
});
export const AssignInterviewResource = props => {
  const params = props?.route?.params;
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'StaffAugmentation',
    uri: '/staffaugmentationopportunities',
    ...props,
  });
  let defaultValues = {};
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {opportunity} = params;
  if (params?.row) {
    defaultValues = {
      opportunity_id: params?.row?._id,
      skill: params?.row?.skill,
      practice: params?.row?.practice,
      status: 'Active',
      rfr_assigned_date: new Date(),
      process: params?.row?.process,
      product: params?.row?.product?._id,
      organization: params?.row?.organization?._id,
      work_location: params?.row?.work_location,
      creation_date: params?.row?.creation_date,
      owner: params?.row?.owner,
      rfq_owner: params?.row?.rfq_owner,
      opportunity_status: 'Active',
    };
  }
  if (params?.opportunity) {
    defaultValues = {
      opportunity_id: opportunity?._id,
      skill: opportunity?.skill,
      practice: opportunity?.practice,
      status: 'Active',
      rfr_assigned_date: new Date(),
      process: opportunity?.process,
      product: opportunity?.product?._id,
      organization: opportunity?.organization?._id,
      work_location: opportunity?.work_location,
      creation_date: opportunity?.creation_date,
      owner: opportunity?.owner,
      rfq_owner: opportunity.rfq_owner,
      opportunity_status: 'Active',
    };
  }

  return (
    <Form
      {...props}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      computations={{...InterviewResourcesComputations(fetch)}}
      header={'Assign Resource'}
      submitAction={'Assign'}
      layoutFields={[
        {
          placeholder: 'Resource',
          label: 'Resource',
          field: 'resource',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          // size: 6,
        },
        {
          placeholder: 'Mode',
          type: 'autoComplete',
          field: 'mode',
          label: 'Mode',
          options: ['Face to Face', 'Online', 'Phone', 'Test'],
          visible: () => !params?.row.formEmployeeMenu,
          required: true,
          size: 6,
        },
        {
          label: 'Mentor',
          field: 'mentor',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          // required: true,
        },
        {
          label: 'Delivery owner',
          field: 'delivery_owner',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
        },
        {
          placeholder: 'Practice',
          label: 'Practice',
          field: 'practice',
          type: 'autoComplete',
          api: '/practices',
          suggestionField: 'name',
          valueField: 'name',
          required: true,
          size: 6,
          //   rules: {required: true},
        },
        {
          placeholder: 'Category',
          type: 'autoComplete',
          field: 'profile_category',
          label: 'Category',
          options: ['New position', 'Against rejection'],
          // visible: () => !params?.row.formEmployeeMenu,
          required: true,
          size: 6,
        },
        {
          label: 'Assigned against',
          field: 'assigned_against',
          type: 'autoComplete',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          required: true,
          size: 6,
          visible: ({values}) =>
            values?.profile_category == 'Against rejection',
          required: ({values}) =>
            values?.profile_category == 'Against rejection',
        },
        {
          placeholder: 'Resume',
          field: 'profile',
          // multiple: true,
          type: 'file',
          required: true,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const MarkInterviewResourceOutcomeForm = props => {
  const params = props?.route?.params;
  // const selectedIds = params?.selectedIds;
  const {
    row,
    forDrop,
    forLost,
    forWon,
    forShiftToAnotherProject,
    forDeniedByResource,
  } = params;
  let outcome = '';
  if (forDrop) {
    outcome = 'Drop';
  }
  if (forLost) {
    outcome = 'Rejected';
  }
  if (forWon) {
    outcome = 'Selected';
  }

  if (forShiftToAnotherProject) {
    outcome = 'Shift To Another Project';
  }
  if (forDeniedByResource) {
    outcome = 'Denied by Resource';
  }

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
    // ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Outcome'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
        outcome: outcome,
      }}
      layoutFields={[
        // {
        //   placeholder: 'Outcome',
        //   type: 'autoComplete',
        //   field: 'outcome',
        //   options: [
        //     'Denied by Resource',
        //     'Drop',
        //     'Rejected',
        //     'Selected',
        //     'Shift To Another Project',
        //   ],
        //   // rules: {required: true},
        // },
        {
          placeholder: 'Reject Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.outcome && values.outcome === 'Rejected',
          required: ({values}) => {
            if (values && values.outcome && values.outcome === 'Rejected') {
              return true;
            } else {
              return false;
            }
          },
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
          ],
        },

        {
          placeholder: 'Drop Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.outcome && values.outcome === 'Drop',
          required: ({values}) => {
            if (values && values.outcome && values.outcome === 'Drop') {
              return true;
            } else {
              return false;
            }
          },
          options: [
            'Client Not Responding',
            'Position is On Hold',
            'Non Agreement in Terms & Conditions',
            'Change in Business Model',
            'Candidate Rejected-Experience requirement outside limit',
            'Candidate Rejected-skillset requirement outside limit',
            'Candidate shifted to Another Project',
            'Resume(s) Rejected-Experience and skillset requirement within agreed limit',
          ],
        },
        {
          placeholder: 'Denied By Resource Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.outcome && values.outcome === 'Denied by Resource',
          required: ({values}) => {
            if (
              values &&
              values.outcome &&
              values.outcome === 'Denied by Resource'
            ) {
              return true;
            } else {
              return false;
            }
          },
          options: [
            'Delay in Preparation for Interview',
            'Denied by Resource - Onsite',
            'Denied by Resource -Offsite',
          ],
        },

        {
          placeholder: 'Lost Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          visible: ({values}) =>
            values && values.outcome && values.outcome === 'Lost',
          required: ({values}) => {
            if (values && values.outcome && values.outcome === 'Lost') {
              return true;
            } else {
              return false;
            }
          },
          options: [
            'Candidate(s) Rejected In Interview',
            'Candidate(s) Rejected in Test',
            'Resume(s) Rejected-Experience and skillset requirement within limit',
            'Candidate Not available-Experience and skillset requirement within limit',
            'Delay in Sharing Profile',
          ],
        },
        {
          field: 'outcome_rejection_reason',
          visible: ({values}) =>
            values &&
            values.outcome &&
            (values.outcome === 'Lost' ||
              values.outcome === 'Drop' ||
              values.outcome === 'Rejected'),
          type: 'textArea',
          label: 'Reason Description',
        },
        {
          type: 'textArea',
          field: 'video_link',
          label: 'Video link',
        },
        {
          type: 'textArea',
          field: 'client_feedback',
          label: 'Client feedback',
        },
        {
          type: 'textArea',
          field: 'am_feedback',
          label: 'Am feedback',
        },
        {
          type: 'date',
          field: 'outcome_date',
          placeholder: 'Outcome Date',
          required: true,
        },
      ]}
    />
  );
};

export const MarkOutcomeDrop = props => {
  const params = props?.route?.params;
  // const selectedIds = params?.selectedIds;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
    // ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Mark Drop'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
        outcome: 'Drop',
      }}
      layoutFields={[
        // {
        //   placeholder: 'Outcome',
        //   type: 'text',
        //   field: 'outcome',
        //   readOnly: true,
        //   // rules: {required: true},
        // },
        {
          placeholder: 'Drop Reason',
          type: 'autoComplete',
          field: 'outcome_description',
          required: true,
          visible: ({values}) =>
            values && values.outcome && values.outcome === 'Drop',
          required: ({values}) => {
            if (values && values.outcome && values.outcome === 'Drop') {
              return true;
            } else {
              return false;
            }
          },
          options: [
            'Client Not Responding',
            'Position is On Hold',
            'Non Agreement in Terms & Conditions',
            'Change in Business Model',
            'Candidate Rejected-Experience requirement outside limit',
            'Candidate Rejected-skillset requirement outside limit',
            'Candidate shifted to Another Project',
            'Resume(s) Rejected-Experience and skillset requirement within agreed limit',
          ],
        },
        {
          type: 'date',
          field: 'outcome_date',
          placeholder: 'Outcome Date',
          required: true,
        },
      ]}
    />
  );
};

export const ScheduleResourceInterviewForm = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
    // ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Schedule'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'interview_date',
          placeholder: 'Interview Date',
          required: true,
        },
        {
          type: 'textArea',
          field: 'interview_time',
          placeholder: 'Interview time',
          rules: {required: true},
        },
      ]}
    />
  );
};

export const UpdateResourceResumeForm = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
    // ids: selectedIds,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Update'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          placeholder: 'Resume',
          field: 'profile',
          type: 'file',
          // required: true,
          options: {bucketName: 'manaze'},
        },
      ]}
    />
  );
};

export const OutcomeDateCorrectionForm = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'Schedule'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          type: 'date',
          field: 'outcome_date',
          placeholder: 'Outcome',
          // rules: {required: true},
          required: true,
        },
      ]}
    />
  );
};

export const PHFeedbackFrom = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'PH feedback'}
      submitAction={'Save'}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'ph_feedback',
          placeholder: 'Feedback',
          // rules: {required: true},
          required: true,
        },
      ]}
    />
  );
};

export const PHFeedbackFromDual = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'PH feedback'}
      submitAction={'Save'}
      isDualMode={true}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'ph_feedback',
          placeholder: 'Feedback',
          // rules: {required: true},
          required: true,
        },
      ]}
    />
  );
};

export const AmFeedbackFromNonedit = props => {
  const params = props?.route?.params;
  const {row} = params;

  let {onSubmit} = useFormSubmit({
    uri: '/staffaugmentationopportunities',
    eventSourceId: 'StaffAugmentation',
  });
  return (
    <Form
      onSubmit={onSubmit}
      header={'AM feedback'}
      submitAction={'Save'}
      readOnly={true}
      defaultValues={{
        ...row,
      }}
      layoutFields={[
        {
          type: 'textArea',
          field: 'am_feedback',
          placeholder: 'Feedback',
          // rules: {required: true},
          required: true,
        },
      ]}
    />
  );
};
