import { Text } from '@unthinkable/react-core-components';
import { useTheme } from '@unthinkable/react-theme';
import { Form } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import { Table } from '../../../components/table/Table';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const PoInvoiceAmountSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');
  let taxAmount =
    (values.service_tax_amount || 0) +
    (values.education_ses_amount || 0) +
    (values.higher_education_ses_amount || 0) +
    (values.sgst_amount || 0) +
    (values.cgst_amount || 0) +
    (values.igst_amount || 0) +
    (values.vat_amount || 0) +
    (values.tds_amount || 0);
  const data = [
    {
      key: 'Net_Amount',
      name: 'Net Amount',
      amount: values?.payable_amount || 0,
    },
    {
      key: 'Tax_Amount',
      name: 'Tax Amount',
      amount: taxAmount || 0,
    },
    {
      key: 'Gross_Amount',
      name: 'Gross Amount',
      amount: values?.exp_amount || 0,
    },
    {
      key: 'Gross_Amount_BC',
      name: 'Gross Amount(BC)',
      amount: values?.exp_amount || 0,
    },
  ];
  return (
    <Table
      {...props}
      data={data}
      columns={[
        {
          render: ({row, ...rest}) => {
            const {styles} = rest;
            return <Text style={{...styles.groupRowText}}>{row?.name}</Text>;
          },
        },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = (
              <Text style={styles?.rowText}>{`${row?.amount} INR`}</Text>
            );
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>{`${row?.amount} INR`}</Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

const updateTaxAmount = tax => {
  const taxDetails =
    tax &&
    tax.reduce((prev, currentInfo) => {
      const type = currentInfo.tax_type;
      let currentamount = currentInfo.amount;
      if (type && type.name && currentamount) {
        if (prev[type.name]) {
          currentamount += Number(prev[type.name]);
        }
        prev[type.name] = Number(currentamount);
      }
      return prev;
    }, {});
  return taxDetails;
};

const calculateTaxAmount = (type, amount, info) => {
  const {vendor_tds_rate, vendor_vat_rate, vendor_gst_rate} =
  info?._parentValues || {};
  let response = void 0;
  if (type.name === 'TDS' && amount && amount > 0) {
    if (
      vendor_tds_rate &&
      vendor_tds_rate.account &&
      vendor_tds_rate.rate_percent &&
      vendor_tds_rate.rate_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_tds_rate.rate_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_tds_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'Vat' && amount && amount > 0) {
    if (
      vendor_vat_rate &&
      vendor_vat_rate.account &&
      vendor_vat_rate.rate_percent &&
      vendor_vat_rate.rate_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_vat_rate.rate_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_vat_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'IGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.igst &&
      vendor_gst_rate.igst_percent &&
      vendor_gst_rate.igst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.igst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.igst_percent),
      };
    }
  } else if (info && type && type.name === 'CGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.cgst &&
      vendor_gst_rate.cgst_percent &&
      vendor_gst_rate.cgst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.cgst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.cgst_percent),
      };
    }
  } else if (info && type && type.name === 'SGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.sgst &&
      vendor_gst_rate.sgst_percent &&
      vendor_gst_rate.sgst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.sgst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.sgst_percent),
      };
    }
  }
  console.log("response>>>>>>>>>>>>>>>",response)
  return response;
};


const PoInvoiceComputations = fetch => ({
  'amount total in invoice': {
    compute: async value => {
      let service_tax_amount = 0;
      let education_ses_amount = 0;
      let higher_education_ses_amount = 0;
      let sgst_amount = 0;
      let cgst_amount = 0;
      let igst_amount = 0;
      let vat_amount = 0;
      let tds_amount = 0;
      let exp_amount = 0;
      let base_exp_amount = 0;
      if (value.exp_details && value.exp_details.length > 0) {
        value.exp_details.forEach(size => {
          if (size.service_tax_amount) {
            service_tax_amount += size.service_tax_amount;
          }
          if (size.education_ses) {
            education_ses_amount += size.education_ses;
          }
          if (size.higher_education_ses) {
            higher_education_ses_amount += size.higher_education_ses;
          }
          if (size.igst_amount) {
            igst_amount += size.igst_amount;
          }
          if (size.vat_amount) {
            vat_amount += size.vat_amount;
          }
          if (size.cgst_amount) {
            cgst_amount += size.cgst_amount;
          }
          if (size.sgst_amount) {
            sgst_amount += size.sgst_amount;
          }
          if (size.tds_amount) {
            tds_amount += size.tds_amount;
          }
          if (size.purchase_amount) {
            exp_amount += size.purchase_amount;
          }
          if (size.base_purchase_amount) {
            base_exp_amount += size.base_purchase_amount;
          }
        });
      }
      return {
        service_tax_amount: service_tax_amount,
        education_ses_amount: education_ses_amount,
        higher_education_ses_amount: higher_education_ses_amount,
        sgst_amount: sgst_amount,
        cgst_amount: cgst_amount,
        igst_amount: igst_amount,
        vat_amount: vat_amount,
        tds_amount: tds_amount,
        exp_amount: exp_amount,
        base_exp_amount: base_exp_amount,
      };
    },
    multi: true,
    dependencies: ['exp_details', 'payee_id'],
  },
  'set payable and base payable amount': {
    compute: async value => {
      let payableAmount = 0;
      let basePayableAmount = 0;
      if (value.service_tax_amount) {
        payableAmount += value.service_tax_amount;
        basePayableAmount += value.service_tax_amount;
      }
      if (value.education_ses_amount) {
        payableAmount += value.education_ses_amount;
        basePayableAmount += value.education_ses_amount;
      }
      if (value.higher_education_ses_amount) {
        payableAmount += value.higher_education_ses_amount;
        basePayableAmount += value.higher_education_ses_amount;
      }
      if (value.sgst_amount) {
        payableAmount += value.sgst_amount;
        basePayableAmount += value.sgst_amount;
      }
      if (value.cgst_amount) {
        payableAmount += value.cgst_amount;
        basePayableAmount += value.cgst_amount;
      }
      if (value.igst_amount) {
        payableAmount += value.igst_amount;
        basePayableAmount += value.igst_amount;
      }
      if (value.vat_amount) {
        payableAmount += value.vat_amount;
        basePayableAmount += value.vat_amount;
      }
      if (value.tds_amount) {
        payableAmount -= value.tds_amount;
        basePayableAmount -= value.tds_amount;
      }
      if (value.exp_amount) {
        payableAmount += value.exp_amount;
      }
      if (value.base_exp_amount) {
        basePayableAmount += value.base_exp_amount;
      }
      return {
        payable_amount: payableAmount,
        base_payable_amount: basePayableAmount,
      };
    },
    multi: true,
    dependencies: [
      'service_tax_amount',
      'education_ses_amount',
      'higher_education_ses_amount',
      'sgst_amount',
      'cgst_amount',
      'igst_amount',
      'vat_amount',
      'tds_amount',
      'exp_amount',
      'base_exp_amount',
    ],
  },
  'set vendor details': {
    compute: async value => {
      const {payee_id, po_date} = value;
      console.log("value",value)
      if (payee_id?._id && po_date) {
        const {data: vendorData} = await fetch({
          uri: `/vendor/taxdetails/${payee_id?._id}`,
          props: {
            date: value.po_date,
          },
        });
        let {
          product,
          payable_account,
          location_id,
          organization,
          currency_id,
          vendor_tds_rate,
          vendor_vat_rate,
          vendor_gst_rate,
        } = vendorData || {};
        const response = {};
        if (currency_id) {
          response.currency = currency_id;
        }
        if (vendor_vat_rate?.length > 0) {
          vendor_vat_rate = vendor_vat_rate[0];
        }
        if (vendor_gst_rate?.length > 0) {
          vendor_gst_rate = vendor_gst_rate[0];
        }
        if (vendor_tds_rate?.length > 0) {
          vendor_tds_rate = vendor_tds_rate[0];
        }
        if (payable_account) {
          response.vendor_account = payable_account;
        }
        if (location_id) {
          response.location = location_id;
        }
        if (product) {
          response.product = product;
        }
        if (organization) {
          response.organization = organization;
        }
        if (vendor_gst_rate?.igst) {
          response.igst_account_id = vendor_gst_rate?.igst;
        }
        if (vendor_gst_rate?.cgst) {
          response.cgst_account_id = vendor_gst_rate?.cgst;
        }
        if (vendor_gst_rate?.sgst) {
          response.sgst_account_id = vendor_gst_rate?.sgst;
        }
        if (vendor_vat_rate?.account) {
          response.vat_account = vendor_vat_rate?.account;
        }
        if (vendor_tds_rate?.account) {
          response.tds_account = vendor_tds_rate?.account;
        }
        const finalResponse = {
          ...response,
          vendor_gst_rate,
          vendor_tds_rate,
          vendor_vat_rate,
        };
        return finalResponse;
      }
    },
    multi: true,
    dependencies: ['payee_id', 'po_date'],
  },
  'set apporver designation' : {
    multi: true,
    compute: async value => {
      if (value?.approved_by_after_creation?._id) {
        const {data: approverData} = await fetch({
          uri: '/getEmployeeDesignation',
          props: {
            filter: {
              _id: value?.approved_by_after_creation?._id,
            },
            fields: {_id: 1, designation: {name : 1}},
            only: true,
          },
        });
        const approvalDesignation = approverData?.designation || 0;
        return {approver_designation_after_creation: approvalDesignation};
      }
    },
    dependencies: ['approved_by_after_creation'],
  },
  nestedComputations: {
    exp_details: {
      purchase_amount: {
        compute: value => {
          if (value.quantity && value.rate) {
            return {purchase_amount: value.rate * value.quantity};
          }
        },
        multi: true,
        dependencies: ['_id', 'quantity', 'rate', '_parentValue.payee_id'],
      },
      base_purchase_amount: {
        compute: (value, {_parentValues}) => {
          if (
            _parentValues &&
            _parentValues.conversion_rate &&
            value.purchase_amount
          ) {
            return {
              base_purchase_amount:
                _parentValues.conversion_rate * value.purchase_amount,
            };
          }
        },
        multi: true,
        dependencies: [
          'purchase_amount',
          '_parentValues.conversion_rate',
          '_parentValues.payee_id',
        ],
      },
      product: {
        compute: (value, {_parentValues}) => {
          if (_parentValues && _parentValues.product) {
            return {product: _parentValues.product};
          }
        },
        multi: true,
        dependencies: [
          '_parentValues.product',
          '_id',
          '_parentValues.payee_id',
        ],
      },

      update_tax_amount: {
        compute: (value, {_parentValues}) => {
          let result = updateTaxAmount(value.expense_tax);
          let finalJSON = {};
          finalJSON['service_tax_amount'] = result['Service Tax'] || 0;
          finalJSON['vat_amount'] = result['Vat'] || 0;
          finalJSON['education_ses'] = result['Cess'] || 0;
          finalJSON['higher_education_ses'] = result['Hcess'] || 0;
          finalJSON['kk_amount'] = result['KK'] || 0;
          finalJSON['igst_amount'] = result['IGST'] || 0;
          finalJSON['cgst_amount'] = result['CGST'] || 0;
          finalJSON['sgst_amount'] = result['SGST'] || 0;
          finalJSON['tds_amount'] = result['TDS'] || 0;
          return {...finalJSON};
        },
        multi: true,
        dependencies: ['expense_tax'],
      },
      nestedComputations: {
        expense_tax: {
          'set amount in expense Tax': {
            compute: (value, {_parentValues}) => {
              let type = value.tax_type;
              let amount = _parentValues.base_purchase_amount || 0;
              return calculateTaxAmount(type, amount, _parentValues);
            },
            multi: true,
            dependencies: ['tax_type'],
          },
        },
      },
    },
  },
});

const PurchaseOrderForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  let {row, readOnly} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/purchaseorders',
    eventSourceId: 'quotations',
  });
  let {user, fetch} = useAppStateContext();
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      readOnly={readOnly}
      api={`/purchaseorder/${row?._id}`}
      defaultValues={{
        po_no: row.po_number,
        po_date: row.po_date,
        purpose: row.purpose,
        approved_on: row.approved_on,
        approver: row.approved_by,
        requested_by: row.requested_by,
        exp_details: row.exp_details,
        product: row.product,
        location_id: row.location_id,
        organization: row.organization,
        team: row.team,
        payment_terms: row.payment_term,
        document: row.approved_mail,
        poType: 'Asset',
        currency: {_id: '53a44bfafaba6a0200b652bd', currency: 'INR'},
        basecurrency_id: {_id: '53a44bfafaba6a0200b652bd', currency: 'INR'},
        conversion_rate: 1,
        draft_po: row._id,
        _v: 1,
      }}
      computations={{...PoInvoiceComputations(fetch)}}
      submitActions="Save"
      eventSourceId="quotations"
      layoutFields={[
        {
          label: 'PO Type',
          field: 'poType',
          type: 'radioGroup',
          valueField: 'value',
          suggestionField: 'label',
          options: [
            {label: 'Asset', value: 'Asset'},
            {label: 'Expense', value: 'Expense'},
          ],
          size: 12,
        },
        {
          label: 'Basic',
          fields: [
            {
              label: 'Supplier',
              field: 'payee_id',
              type: 'autoComplete',
              api: '/vendors',
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              required: true,
            },
            {
              field: 'purpose',
              label: 'Description',
              type: 'text',
              size: 4,
            },
            {
              type: 'date',
              label: 'PO Date',
              field: 'po_date',
              required: true,
              size: 4,
            },
            {
              type: 'text',
              label: 'HSN Number',
              field: 'hsn_number',
              size: 4,
            },
            {
              field: 'currency',
              label: 'Currency',
              type: 'autoComplete',
              api: '/currencies',
              suggestionField: 'currency',
              valueField: 'currency',
              size: 4,
              required: true,
            },
            {
              field: 'basecurrency_id',
              label: 'Base Currency',
              type: 'autoComplete',
              api: '/currencies',
              suggestionField: 'currency',
              valueField: 'currency',
              size: 4,
              required: true,
            },
            {
              type: 'number',
              field: 'conversion_rate',
              label: 'Conversion Rate',
              size: 4,
            },
            {
              type: 'text',
              label: 'Offer Ref No',
              field: 'offer_ref_no',
              required: true,
              size: 4,
            },
            {
              type: 'date',
              field: 'delivery_date',
              label: 'Delivery Date',
              required: true,
              size: 4,
            },
            {
              field: 'is_advance_paid_orNot',
              placeholder: 'Is Advance Paid',
              type: 'checkbox',
              label: 'Is Advance Paid',
              size: 6,
            },
            {
              type: 'number',
              label: 'Advance Amount',
              visible: ({values}) => values && values.is_advance_paid_orNot,
              field: 'advance_amount',
              required: true,
              size: 6,
            },
          ],
        },
        {
          label: 'PO Details',
          fields: [
            {
              field: 'exp_details',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      label: 'Account',
                      type: 'autoComplete',
                      field: 'purchase_category_account',
                      api: '/accounts',
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      label: 'Item',
                      type: 'text',
                      field: 'purchase_category',
                      required: true,
                    },
                    {
                      field: 'quantity',
                      label: 'Quantity',
                      type: 'number',
                      required: true,
                    },
                    {
                      field: 'rate',
                      label: 'Rate Per Quantity',
                      type: 'number',
                      required: true,
                    },
                    {
                      field: 'purchase_amount',
                      label: 'Amount',
                      type: 'number',
                      required: true,
                    },
                  ]}
                  columns={[
                    {
                      header: 'Account',
                      type: 'autoComplete',
                      field: 'purchase_category_account.name',
                      width: 200,
                    },
                    {
                      header: 'Item',
                      type: 'text',
                      field: 'purchase_category',
                      width: 100,
                    },
                    {
                      header: 'Quantity',
                      field: 'quantity',
                      type: 'number',
                      width: 100,
                    },
                    {
                      header: 'Rate',
                      field: 'rate',
                      type: 'number',
                      width: 80,
                    },
                    {
                      header: 'Amount',
                      field: 'purchase_amount',
                      type: 'number',
                      width: 200,
                    },
                    {
                      innerNested: true,
                      header: 'Tax',
                      label: 'Tax Details',
                      fields: [
                        {
                          type: 'text',
                          field: 'expense_tax',
                          nested: true,
                          render: props => {
                            return (
                              <NestedTable
                                {...props}
                                columns={[
                                  {
                                    field: 'tax_type.name',
                                    header: 'Tax Type',
                                  },
                                  {
                                    field: 'rate',
                                    header: 'Rate',
                                    type: 'number',
                                  },
                                  {
                                    field: 'amount',
                                    header: 'Amount',
                                    type: 'number',
                                  },
                                ]}
                                fields={[
                                  {
                                    field: 'tax_type',
                                    label: 'Tax Type',
                                    suggestionField: 'name',
                                    valueField: 'name',
                                    api: '/taxtypes',
                                    type: 'autoComplete',
                                  },
                                  {
                                    field: 'rate',
                                    label: 'Rate',
                                    type: 'number',
                                  },
                                  {
                                    field: 'amount',
                                    label: 'amount',
                                    type: 'number',
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          render: (_, props) => {
            return <PoInvoiceAmountSummary {...props} />;
          },
        },
        {
          label: 'Price Details',
          fields: [
            {
              type: 'text',
              placeholder: 'Price Basis',
              field: 'price_basis',
              label: 'Price Basis',
              required: true,
              size: 6,
            },
            {
              type: 'text',
              placeholder: 'Price Escalation',
              field: 'price_escalation',
              label: 'Price Escalation',
              required: true,
              size: 6,
            },
            {
              type: 'text',
              placeholder: 'Payment Terms',
              field: 'payment_terms',
              label: 'Payment Terms',
              required: true,
              size: 6,
            },
            {
              type: 'text',
              placeholder: 'Taxes and Duties',
              field: 'taxes_and_duties',
              label: 'Taxes and Duties',
              required: true,
              size: 6,
            },
            {
              type: 'text',
              placeholder: 'Commencement of Service',
              field: 'commencement_of_services',
              label: 'Commencement of Service',
              required: true,
              size: 6,
            },
          ],
        },
        {
          label: 'Special Condition',
          fields: [
            {
              field: 'special_conditions',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  fields={[
                    {
                      label: 'Heading',
                      field: 'heading',
                      type: 'text',
                    },
                    {
                      label: 'Description',
                      field: 'description',
                      type: 'text',
                    },
                  ]}
                  columns={[
                    {
                      header: 'Heading',
                      type: 'text',
                      field: 'heading',
                    },
                    {
                      header: 'Description',
                      type: 'text',
                      field: 'description',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          label: 'Approver Details',
          fields: [
            {
              field: 'approved_by_after_creation',
              label: 'PO Approve By',
              type: 'autoComplete',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              field: 'approver_designation_after_creation',
              label: 'Approver Designation',
              type: 'autoComplete',
              api: '/designations',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
        {
          label: 'Inspection Details',
          fields: [
            {
              type: 'text',
              placeholder: 'Place of Inspection',
              field: 'place_of_inspaction',
              label: 'Place of Inspection',
              required: true,
              size: 6,
            },
            {
              type: 'text',
              placeholder: 'Authority for Inspection',
              field: 'authority_for_inspaction',
              label: 'Authority for Inspection',
              required: true,
              size: 6,
            },
            {
              label: 'delivery Address',
              field: 'delivery_address',
              size: 12,
              type: 'autoComplete',
              options: [
                {
                  label: 'Daffodil Software Private Limited (SEZ)',
                  value:
                    'Daffodil Software Private Limited, 9th Floor, Tower B1, World Tech Park NH 8, Sector 30 DLF, Silokhera, SEZ, Gurugram, Haryana 122001',
                },
                {
                  label: 'Daffodil Software Private Limited (Hisar)',
                  value:
                    'Daffodil Software Private Limited, 6th Floor Metropollis Mall, Delhi Rd, Industrial Area, Hisar, Haryana 125005',
                },
                {
                  label: 'Unthinkable Solutions LLP (SEZ)',
                  value:
                    'Unthinkable Solutions LLP, 9th Floor, Tower B1, World Tech Park NH 8, Sector 30 DLF, Silokhera, SEZ, Gurugram, Haryana 122001',
                },
                {
                  label: 'Unthinkable Solutions LLP (Hisar)',
                  value:
                    'Unthinkable Solutions LLP, 6th Floor Metropollis Mall, Delhi Rd, Industrial Area, Hisar, Haryana 125005',
                },
                {
                  label: 'Astrantia Real Estate Private Ltd',
                  value:
                    'Astrantia Real Estate Private Limited CM 638B, The Camellias, DLF Phase V, Gurgaon, Haryana-122009',
                },
              ],
              valueField: 'value',
              suggestionField: 'label',
              keyField: 'label',
              required: true,
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user.employee._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
          ],
        },
        {
          label: 'Accounting',
          fields: [
            {
              label: 'Organization',
              type: 'autoComplete',
              field: 'organization',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Product',
              type: 'autoComplete',
              field: 'product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Tax Location',
              type: 'autoComplete',
              field: 'location_id',
              api: `/offices`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
          ],
        },
        {
          fields: [
            {
              field: 'approved_mail',
              label: 'Approved Mail',
              type: 'file',
              options: {bucketName: 'manaze'},
              mandatory: true,
              size: 6,
            },
            {
              field: 'document',
              label: 'attachment',
              type: 'file',
              options: {bucketName: 'manaze'},
              multiple: true,
              mandatory: true,
              size: 6,
            },
          ],
        },
      ]}
    />
  );
};

export const AddPurchaseOrder = props => {
  return <PurchaseOrderForm header="Add Purchase Order" {...props} />;
};
export const EditPurchaseOrder = props => {
  return (
    <PurchaseOrderForm mode="edit" header="Edit Purchase Order" {...props} />
  );
};
