import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {ActionText} from '../../../components/action/ActionText';

const DepartmentTable = props => {
  const {navigation, route} = props;
  let navigationRoute =
    route?.params?.screenId === 'userallkpis'
      ? 'view-user-kpis'
      : 'view-department_kpis';
  return (
    <Table
      eventSourceId={'departments'}
      api={`/departments`}
      filter={{_id: {$in: ['5848ffe17d89cd3f17c7335c']}}}
      renderHeader={() => <TableHeader title="Department" />}
      fields={{name: 1}}
      columns={[
        {
          field: 'name',
          header: 'Name',
          responsive: 'sm',
        },
        {
          align: 'center',
          width: 100,
          render: () => <ActionText text="View KPIs" />,
          onPress: ({row}) => {
            navigation.navigate(navigationRoute, {row});
          },
        },
      ]}
      {...props}
    />
  );
};

export default DepartmentTable;
