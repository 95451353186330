import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {IconButton as ButtonComponent} from '@unthinkable/react-button';
import {AIIconButtonStyle, IconButtonStyle} from './theme';
import {ThreeDotLoader} from '@unthinkable/react-activity-indicator';

export const IconButton = props => {
  const style = useStyles(IconButtonStyle);
  return <ButtonComponent styles={style} {...props} />;
};

export const AIIconButton = props => {
  const style = useStyles(AIIconButtonStyle);
  return (
    <ButtonComponent
      styles={style}
      {...props}
      renderLoader={() => <ThreeDotLoader color="white" size={4} />}
    />
  );
};
