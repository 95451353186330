export const AddButton = ({theme: {fonts, colors, icons}}) => {
  return {
    AddIcon: icons.Plus,
    compactAddIcon: icons.Add,
    selectedAddIcon: icons.GreenAdd,
    starIcon: icons.StarIcon,
    buttonStyle: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 6,
      paddingBottom: 6,
      backgroundColor: colors.SURFACE1,
      borderRadius: 6,
      cursor: 'pointer',
      borderColor: colors.UPPER_OUTLINE,
      borderWidth: 1,
    },
    iconStyle: {width: 14},
    titleStyle: {...fonts.ACTION, color: colors.NEUTRAL_MEDIUM},
  };
};

export const ButtonStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
    hoverContainer: {
      backgroundColor: colors.BRAND_HIGH,
    },
  };
};

export const ButtonGroupStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      borderRadius: 6,
      overflow: 'hidden',
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
      alignItems: 'center',
    },
  };
};

export const IconButtonStyle = ({theme: {colors}}) => {
  return {
    container: {
      padding: 6,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
    },
    hoverContainer: {
      backgroundColor: colors.SURFACE2,
    },
  };
};

export const AIIconButtonStyle = ({theme: {colors}}) => {
  return {
    container: {
      padding: 6,
      borderRadius: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
    },
    disabledContainer: {
      backgroundColor: colors.BRAND_UPPER_MEDIUM,
    },
    hoverContainer: {
      backgroundColor: colors.BRAND_HIGH,
    },
  };
};

export const UnderlineButtonStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      paddingTop: 6,
      paddingRight: 12,
      paddingBottom: 6,
      paddingLeft: 12,
      backgroundColor: 'transparent',
    },
    text: {
      ...fonts.BODY2,
      textDecoration: 'underline',
      color: colors.INFORMATION_HIGH,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  };
};

export const OutlineButtonStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      paddingTop: 6,
      paddingRight: 28,
      paddingBottom: 6,
      paddingLeft: 28,
      backgroundColor: 'transparent',
      borderWidth: 1.3,
      borderColor: colors.BRAND_UPPER_MEDIUM,
    },
    disabledContainer: {
      borderColor: colors.NEUTRAL_LOW,
      backgroundColor: 'transparent',
    },
    text: {
      color: colors.BRAND_HIGH,
      ...fonts.ACTION,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
    hoverContainer: {
      borderColor: colors.BRAND_HIGH,
    },
    success: {
      container: {
        paddingTop: 6,
        paddingRight: 28,
        paddingBottom: 6,
        paddingLeft: 28,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors.SUCCESS_HIGH,
      },
      disabledContainer: {
        borderColor: colors.UPPER_OUTLINE,
        backgroundColor: 'transparent',
      },
      text: {
        color: colors.SUCCESS_HIGH,
        ...fonts.ACTION,
      },
      disabledText: {color: colors.UPPER_OUTLINE},
    },
    error: {
      container: {
        paddingTop: 6,
        paddingRight: 28,
        paddingBottom: 6,
        paddingLeft: 28,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors.ERROR_HIGH,
      },
      disabledContainer: {
        borderColor: colors.UPPER_OUTLINE,
        backgroundColor: 'transparent',
      },
      text: {
        color: colors.ERROR_HIGH,
        ...fonts.ACTION,
      },
      disabledText: {color: colors.UPPER_OUTLINE},
    },
  };
};

export const MarkQaDoneButtonTheme = ({theme: {fonts, colors, icons}}) => {
  return {
    activeIcon: icons.GreenTickIcon,
    inactiveIcon: icons.GreenOutlineTick,
    inactiveStyles: {
      container: {
        borderColor: colors.SUCCESS_HIGH,
        borderWidth: 1,
        backgroundColor: colors.BACKGROUND,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
      },
      text: {
        ...fonts.BODY2,
        color: colors.SUCCESS_HIGH,
      },
    },
    activeStyles: {
      container: {
        backgroundColor: colors.SUCCESS_HIGH,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
      },
      text: {
        ...fonts.BODY2,
        color: colors.BACKGROUND,
      },
    },
  };
};

export const FillButtonTheme = ({theme: {fonts, colors}}) => ({
  disabledStyles: {
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: colors.OUTLINE,
      borderRadius: 0,
    },
    text: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_LOW,
    },
  },
  activeStyles: {
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: colors.NEUTRAL_HIGH,
      borderRadius: 0,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
    },
  },
});

export const BorderLessButtonTheme = ({theme: {fonts, colors}}) => ({
  text: {
    ...fonts.ACTION,
    color: colors.INFORMATION_HIGH,
  },
  container: {
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12,
    backgroundColor: 'transparent',
  },
  hoverContainer: {
    backgroundColor: colors.INFORMATION_LOW,
  },
});
export const DownloadButtonTheme = ({theme: {fonts, colors, icons}}) => ({
  downloadIcon: icons.DownloadBlue,
  text: {
    ...fonts.ACTION,
    color: colors.INFORMATION_HIGH,
  },
  container: {
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12,
    backgroundColor: 'transparent',
  },
  hoverContainer: {
    backgroundColor: colors.INFORMATION_LOW,
  },
});
