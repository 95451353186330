import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';
import {CASCADER_NAVIGATE_TO_ADD_VIEW} from '../../common/constants/SourceConstants';
import {PotentialCustomerForm} from './PotentialCustomerForm';

export const AddProspectForm = props => {
  const {
    route: {params},
  } = props;
  const {user} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId} = employee;
  let product = params?.product;
  if (
    (!product || !product._id) &&
    employee &&
    employee.product &&
    employee.product._id
  ) {
    product = employee.product;
  }
  let owner = {_id: employeeId, name: employee.name};

  return (
    <PotentialCustomerForm
      {...props}
      add
      header={'Add Prospect'}
      defaultValues={{
        lead_status: 'Active',
        pipeline_type: 'Lead',
        created_on: getZeroTimeDate(new Date()),
        type: 'Organization',
        product: product,
        organization: params?.organization,
        department: params?.department,
        owner: owner,
        activity_date: getZeroTimeDate(new Date()),
        added_on: getZeroTimeDate(new Date()),
      }}
    />
  );
};

export const EditProspectForm = props => {
  const {route: {params} = {}} = props;
  const {prospectId, row} = params;
  return (
    <PotentialCustomerForm
      {...props}
      beforeSubmit={({data}) => ({
        data: {
          ...data,
          activity_date: getZeroTimeDate(new Date()),
        },
      })}
      api={`/potentialCustomers/${prospectId}`}
      fields={{
        type: 1,
        email: 1,
        website: 1,
        designation: 1,
        name: 1,
        country: {name: 1},
        industry: {name: 1},
        owner: {name: 1},
        phone: 1,
        employee_count: {
          number: 1,
          name: 1,
        },
        inside_sales: {name: 1},
        company_name: 1,
        exact_employee_count: 1,
        campaign_type: {name: 1},
        campaign_id: {name: 1},
        description: 1,
        manual_description: 1,
        product: {name: 1},
        department: {name: 1},
        organization: {name: 1},
        company_linkedin_url: 1,
        geography_location: {name: 1},
        buyer_persona: {name: 1},
        linkedin_url: 1,
        organization_category: {name: 1},
        contact_detail: {
          first_name: 1,
          last_name: 1,
          designation: 1,
          email: 1,
          linkedin_url: 1,
          phone_no: 1,
        },
        channel: {name: 1},
        employee_team: {name: 1},
      }}
      mode="edit"
      isDualMode
      header={{title: 'Edit Prospect', secondaryTitle: row?.name}}
    />
  );
};

export const UpdatePotentialCustomerForm = props => {
  const params = props.route.params;
  const {row} = params;
  const {post, fetch} = useAppStateContext();
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'communicationAdded',
    uri: '/campaigncontacts',
    ...props,
  });

  const {data: leadData} = useFetchFormData({
    api: '/potentialCustomers',
    filter: {
      _id: row?.potential_customer?._id,
    },
    fields: {
      campaign_type: {_id: 1, name: 1},
      campaign_id: {_id: 1, name: 1},
      employee_count: {_id: 1, number: 1},
      organization_category: {_id: 1, name: 1},
      geography_location: {_id: 1, name: 1},
      lead_source: {_id: 1, name: 1},
      industry: {_id: 1, name: 1},
      country: {_id: 1, name: 1},
      lead_score: 1,
      website: 1,
      email: 1,
      lead_status: 1,
      owner: {_id: 1, name: 1},
      pipeline_type: 1,
      stage: {_id: 1, name: 1},
    },
  });

  if (!leadData) {
    return null;
  }
  let type = '';
  if (leadData && leadData.pipeline_type) {
    if (leadData?.pipeline_type == 'Prospect') {
      type = 'Lead';
    }
    if (leadData?.pipeline_type == 'Lead') {
      type = 'Prospect';
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{
        _id: row?._id,
        potential_customer: row?.potential_customer,
        website: leadData?.website,
        campaign_type: leadData?.campaign_type,
        campaign_id: leadData?.campaign_id,
        email: leadData?.email,
        lead_status: leadData?.lead_status,
        owner: leadData?.owner,
        stage: leadData?.stage,
        pipeline_type: type,
        activity_date: getZeroTimeDate(new Date()),
      }}
      header={'Update'}
      submitAction={'Confirm'}
      layoutFields={[
        {
          type: 'autoComplete',
          label: 'Potential Customer',
          api: '/potentialcustomers',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'potential_customer',
          secondarySuggestionField: 'website',
          onChangeValue: async (value, e, {setFieldValue}) => {
            if (value?._id) {
              const {data: potentialCustomerData} = await fetch({
                uri: `/potentialCustomers/${value?._id}`,
                props: {
                  fields: {
                    campaign_type: {_id: 1, name: 1},
                    campaign_id: {_id: 1, name: 1},
                    employee_count: {_id: 1, number: 1},
                    organization_category: {_id: 1, name: 1},
                    geography_location: {_id: 1, name: 1},
                    lead_source: {_id: 1, name: 1},
                    industry: {_id: 1, name: 1},
                    country: {_id: 1, name: 1},
                    lead_score: 1,
                    website: 1,
                    email: 1,
                    lead_status: 1,
                    owner: {_id: 1, name: 1},
                    pipeline_type: 1,
                    stage: {_id: 1, name: 1},
                  },
                },
              });
              if (potentialCustomerData?.pipeline_type) {
                if (potentialCustomerData?.pipeline_type == 'Prospect') {
                  setFieldValue('pipeline_type', 'Lead');
                }
                if (potentialCustomerData?.pipeline_type == 'Lead') {
                  setFieldValue('pipeline_type', 'Prospect');
                }
              }
              setFieldValue('website', potentialCustomerData?.website);
              setFieldValue('email', potentialCustomerData?.email);
              setFieldValue('owner', potentialCustomerData?.owner);
              setFieldValue('stage', potentialCustomerData?.stage);

              setFieldValue(
                'campaign_type',
                potentialCustomerData?.campaign_type,
              );
              setFieldValue('campaign_id', potentialCustomerData?.campaign_id);
            }
          },
        },
        {
          type: 'text',
          field: 'pipeline_type',
          placeholder: 'Type',
          label: 'Type',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Stage',
          placeholder: 'Stage',
          field: 'stage',
          type: 'autoComplete',
          api: `/stages`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          readOnly: true,
        },
        {
          type: 'text',
          field: 'website',
          placeholder: 'Website',
          label: 'Website',
          readOnly: true,
          size: 6,
        },
        {
          type: 'text',
          field: 'email',
          placeholder: 'Email',
          label: 'Email',
          size: 6,
          readOnly: true,
        },
        {
          type: 'text',
          field: 'lead_status',
          placeholder: 'Status',
          label: 'Status',
          size: 6,
          readOnly: true,
        },
        {
          label: 'Owner',
          placeholder: 'Owner',
          field: 'owner',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
          readOnly: true,
        },

        {
          label: 'Campaign Details',
          fields: [
            {
              placeholder: 'Channel & Campaign',
              label: 'Channel & Campaign',
              field: 'campaign_type',
              type: 'cascader',
              readOnly: true,
              cascades: [
                {
                  label: 'Select Source',
                  field: 'campaign_type',
                  api: '/types/suggestionsForLead',
                  suggestionField: 'name',
                  valueField: 'name',
                  readOnly: true,
                },
                {
                  label: 'Campaign Value',
                  placeholder: 'Campaign Id',
                  field: 'campaign_id',
                  type: 'autoComplete',
                  api: '/sourceSuggestions',
                  suggestionField: 'name',
                  valueField: 'name',
                  readOnly: true,
                  params: ({values: {campaign_type} = {}}) => {
                    return {
                      source: campaign_type,
                    };
                  },
                  onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
                    navigation,
                    params,
                  }),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
