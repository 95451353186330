import React from 'react';
import {RecursiveTable} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import {RecursiveRender} from '@unthinkable/react-table';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import { TabView } from '../../../components/tab';

export const AppResourceTabs = props => {
  const {
    route: {params},
    navigation,
    user
  } = props;

  const { applyFilter, filterValues = {},} = useFilter({});
  const { filter = {} } = filterValues;

  return (
    <TabView
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'App Resource',
              api: '/appResources',
              placeholder: 'Select',
              suggestionField: 'id',
              valueField: 'id',
              field: 'id',
            },
          ]}
        />,
        <AddButton
          title="Add App Resource"
          view="add-app-resource"
          params={props?.route?.params}
        />,
      ]}
      tabs={{
        dataEntity: {
          label: 'DataEntity',
          view: <AppResourceTable dataEntity={true} extraFilter={{ ...filter?.id }} />,
        },
        appResources: {
          label: 'App Resource',
          view: <AppResourceTable dataEntity={false} extraFilter={{ ...filter?.id }} />,
        },
      }}
      {...props}
    />
  );
};

const AppResourceTable = props => {
  let {
    route: { params },
    navigation,
    dataEntity,
    extraFilter,
  } = props;
    
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'appResourceRemoved',
  });

  let addOnFilter = {parent: {$eq: null}}

  if(extraFilter?.id){
    addOnFilter = {...extraFilter};
  }

  if (dataEntity) {
    addOnFilter = { ...addOnFilter, type: { $in: ['DataEntity', 'DataView'] } };
  }
  else {
    addOnFilter = { ...addOnFilter, type: { $in: ['Module', 'Menu', 'Screen'] } };
  }
  
  return (
    <RecursiveTable
      eventSourceId={['appResourceAdded', 'appResourceRemoved']}
      api={`/app-resources`}
      fields={{
        label: 1,
        display_label: 1,
        type: 1,
        parent: 1,
        id: 1,
      }}
      filter={{...addOnFilter}}
      sort={{index: 1}}
      onRowPress={({row}) => {
        navigation.navigate('edit-app-resource', {row});
      }}
      columns={[
        {
          header: 'Label',
          field: 'label',
          render: props => <RecursiveRender {...props} />,
          indent: false,
        },

        {
          header: 'Type',
          field: 'type',
          type: 'text',
          width: 200,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-app-resource', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/deleteAppResource/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default AppResourceTable;
