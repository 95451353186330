import {Row} from '@unthinkable/react-core-components';
import {
  getDateRange,
  getZeroTimeDate,
} from '@unthinkable/react-date-picker/src/DateUtility';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  ActiveCustomerTable,
  ActiveCustomerTableAm,
  AllCustomerTable,
  AllCustomerTableAm,
  CustomerChurnDetailList,
  LeaglCustomerTable,
  LeaglCustomerTableAm,
  LostCustomerTable,
  LostCustomerTableAm,
  NewCustomerTable,
} from './CustomersTable';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const CustomerTableTabView = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });

  const {
    onChangeFilter: activeOnChangeFilter,
    searchValue: activeSearchValue,
    applyFilter: activeApplyFilter,
    filterValues: activeFitlerValues,
  } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });

  const {
    onChangeFilter: allOnChangeFilter,
    searchValue: allSearchValue,
    applyFilter: allApplyFilter,
    filterValues: allFitlerValues,
  } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {filter: activeFilter, params: activeFilterParams = {}} =
    activeFitlerValues;

  const {filter: allFilter, params: allFilterParams = {}} = allFitlerValues;

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  let activeParams = {
    ...activeFilterParams,
    ...rest,
    product,
    organization,
  };

  let allParams = {
    ...allFilterParams,
    ...rest,
    product,
    organization,
  };

  const tabs = {
    new: {
      label: 'New',
      view: (
        <NewCustomerTable
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          inActiveTabFilter={filter}
          // addOnFilter={addOnFilter}
          {...props}
        />
      ),
      api: `/product/newCustomer`,
      params: {
        ...params,
      },
      addOnFilter: filter,
      ...props,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Lost Reason',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Project(s) Completed',
                  value: 'Project(s) Completed',
                },
                {
                  label: 'Poor delivery quality',
                  value: 'Poor delivery quality',
                },
                {
                  label: 'Payment Issue',
                  value: 'Payment Issue',
                },
              ],
              placeholder: 'Select',
              field: 'lost_reason',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            // view="add-customer-form"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
    },

    active: {
      label: 'Active',
      view: (
        <ActiveCustomerTable
          params={activeParams}
          filterValues={activeFitlerValues}
          searchValue={activeSearchValue}
          activeTabFilter={activeFilter}
          filter={filter}
          {...props}
        />
      ),
      api: `/product/activeCustomer`,
      limit: 20000,
      params: {
        ...activeParams,
      },
      addOnFilter: activeFilter,
      actions: [
        <SearchFilter
          value={activeSearchValue}
          onChangeFilter={activeOnChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={activeOnChangeFilter}
          filterValues={activeFitlerValues}
          asParam
        />,
        <GroupFilter
          filterValues={activeFitlerValues}
          applyFilter={activeApplyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            // view="add-customer-form"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
      filter,
    },

    lost: {
      label: 'Inactive',
      view: (
        <LostCustomerTable
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          inActiveTabFilter={filter}
          // addOnFilter={addOnFilter}
          {...props}
        />
      ),
      api: `/product/lostCustomer`,
      params: {
        ...params,
      },
      addOnFilter: filter,
      ...props,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Lost Reason',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {
                  label: 'Project(s) Completed',
                  value: 'Project(s) Completed',
                },
                {
                  label: 'Poor delivery quality',
                  value: 'Poor delivery quality',
                },
                {
                  label: 'Payment Issue',
                  value: 'Payment Issue',
                },
              ],
              placeholder: 'Select',
              field: 'lost_reason',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            // view="add-customer-form"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
    },

    legal: {
      label: 'Legal',
      view: (
        <LeaglCustomerTable
          params={{...params, period: void 0}}
          // filterValues={filterValues}
          searchValue={searchValue}
          legalTabFilter={filter}
          // addOnFilter={addOnFilter}
          {...props}
        />
      ),
      api: `/product/legalCustomer`,
      params: {
        ...params,
        period: void 0,
      },
      addOnFilter: filter,
      // ...props,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        // <PeriodRangeFilter
        //   onChangeFilter={onChangeFilter}
        //   filterValues={filterValues}
        //   asParam
        // />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'multiAutoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
    },

    all: {
      label: 'All',
      view: (
        <AllCustomerTable
          params={{...allParams}}
          filterValues={allFitlerValues}
          searchValue={allSearchValue}
          activeTabFilter={allFilter}
          filter={filter}
        />
      ),
      actions: [
        <SearchFilter
          value={allSearchValue}
          onChangeFilter={allOnChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={allOnChangeFilter}
          filterValues={allFitlerValues}
          asParam
        />,
        <GroupFilter
          filterValues={allFitlerValues}
          applyFilter={allApplyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'multiAutoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const CustomerTableTabViewAm = props => {
  let {route: {params} = {}} = props;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    plainFilter: {
      product: [
        {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
        {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
      ],
    },
    filter: {
      product: {
        $in: [
          {_id: '53a42e38a22d3a020035f672', name: 'Software Services'},
          {_id: '53a42e37a22d3a020035f657', name: 'Enterprises Services'},
        ],
      },
    },
  });
  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  if (params && params.filter) {
    filter = {...filter, ...params.filter};
  }
  const {
    download,
    user: {employee: {_id: employeeId, team: employeeTeam} = {}},
  } = useAppStateContext();

  const tabs = {
    active: {
      label: 'Active',
      view: (
        <ActiveCustomerTableAm
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          activeTabFilter={filter}
          {...props}
        />
      ),
      api: `/product/activeCustomer`,
      limit: 20000,
      filter: {
        ...filter,
        $or: [
          {owner: employeeId},
          {
            owner: {$in: employeeTeam},
          },
        ],
      },
      params: {
        ...params,
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <AddButton
          title="Customer"
          // view="add-customer-form"
          // view="create-customer-form"
          view="add-customer-form-new"
          params={props?.route?.params}
        />,
      ],
      ...props,
    },

    lost: {
      label: 'Inactive',
      view: (
        <LostCustomerTableAm
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          inActiveTabFilter={filter}
          // addOnFilter={addOnFilter}
          {...props}
        />
      ),
      api: `/product/lostCustomer`,
      params: {
        ...params,
      },
      filter: {
        $or: [
          {owner: employeeId},
          {
            owner: {$in: employeeTeam},
          },
        ],
      },
      addOnFilter: filter,
      ...props,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
      ],
    },

    legal: {
      label: 'Legal',
      view: (
        <LeaglCustomerTableAm
          params={{...params, period: void 0}}
          // filterValues={filterValues}
          searchValue={searchValue}
          legalTabFilter={{owner: employeeId, ...filter}}
          // addOnFilter={addOnFilter}
          {...props}
        />
      ),
      api: `/product/legalCustomer`,
      params: {
        ...params,
        period: void 0,
      },
      addOnFilter: {
        ...filter,
        $or: [
          {owner: employeeId},
          {
            owner: {$in: employeeTeam},
          },
        ],
      },
      // ...props,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        // <PeriodRangeFilter
        //   onChangeFilter={onChangeFilter}
        //   filterValues={filterValues}
        //   asParam
        // />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              // asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'multiAutoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],

              label: 'AM',
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
      ],
    },
    all: {
      label: 'All',
      view: (
        <AllCustomerTableAm
          params={{...params}}
          filterValues={filterValues}
          searchValue={searchValue}
          activeTabFilter={filter}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
          asParam
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'first_order_date',
              label: 'First Order Date',
            },
            {
              type: 'date',
              field: 'created_on',
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              field: 'industry',
              api: '/industries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Industry',
            },
            {
              type: 'autoComplete',
              field: 'country',
              api: '/countries',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Country',
            },
            {
              type: 'autoComplete',
              field: 'employee_count',
              api: '/employeesizes',
              suggestionField: 'number',
              valueField: 'number',
              label: 'Employee Size',
            },
            {
              type: 'multiAutoComplete',
              label: 'Product',
              placeholder: 'Select',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              placeholder: 'Select',
              api: '/organizations',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Is overdue',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              placeholder: 'Select',
              field: 'is_over_due',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <Row
          gap={12}
          style={{alignItems: 'center', justifyContent: 'space-between'}}>
          <AddButton
            title="Customer"
            view="add-customer-form-new"
            params={props?.route?.params}
          />
        </Row>,
      ],
    },
  };

  return <TabView tabs={tabs} {...props} />;
};

export const ChurnCustomerReportTabView = props => {
  const {route: {params} = {}, navigation} = props;
  const {filter = {}} = params;
  const {filterValues, onChangeFilter, searchValue} = useFilter({});
  let tabs = {};

  tabs.active = {
    label: 'Customers',
    api: '/customers',
    addOnFilter: filter,
    view: (
      <CustomerChurnDetailList
        {...props}
        search={searchValue}
        filter={filter}
        params={params}
      />
    ),
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
