import {Text, View} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Table} from '../../../components/table/Table';
import {randomColor} from '../../../utils/util';

export const TransactionCOOTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
  } = props;
  return (
    <Table
      {...props}
      api={`/transactions/coo`}
      addOnFilter={addOnFilter}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator
              style={{
                gap: 8,
                alignItems: 'center',
              }}>
              <GroupContentItem value={row._id} color="#1A1A1A" />
            </RowWithSeparator>
          );
        },
        rightContent: ({row}) => {
          return (
            <RowWithSeparator
              style={{
                gap: 8,
                alignItems: 'center',
              }}>
              <GroupContentItem value={row?.total} color="#1A1A1A" />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Trans. req',
          width: 200,
        },
        {
          header: 'Vendor',
          width: 220,
        },
        {
          header: 'Description',
          minWidth: 300,
        },
        {
          header: 'Payment Type',
          width: 150,
        },
        {
          header: 'Approver',
          width: 200,
        },
        {
          header: 'Sum of amount',
          width: 200,
        },
      ]}
      renderExpandRow={({row, styles}) => {
        return (
          <Table
            data={row?.children}
            renderHeader={() => (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  lineHeight: '35px',
                  padding: (4, 12, 4, 12),
                  backgroundColor: '#EDEDED',
                  ...styles.rowText,
                }}>
                <Text>{row?.name}</Text>
                <Text>{row?.total}</Text>
              </View>
            )}
            onRowPress={({row}) => {
              navigation.navigate('edit-vendor-payment-details', {
                row: {_id: row.payment_id},
              });
            }}
            columns={[
              {
                width: 200,
                render: ({row, styles}) => {
                  return (
                    <Text
                      style={{
                        ...styles.text,
                        color: '#737373',
                      }}>
                      {row?.date && moment(row?.date).format('DD MMM YY')}
                    </Text>
                  );
                },
              },
              {
                field: 'name',
                type: 'colorTag',
                colorField: randomColor,
                width: 220,
              },
              {
                field: 'description',
                minWidth: 300,
                type: 'text',
              },
              {
                field: 'type',
                width: 150,
                type: 'text',
              },
              {
                field: 'aprover',
                width: 200,
                type: 'colorTag',
                colorField: randomColor,
              },
              {
                field: 'amount',
                type: 'currency',
                width: 200,
              },
            ]}
          />
        );
      }}
    />
  );
};

export const TransactionResultCOOTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
  } = props;
  return (
    <Table
      {...props}
      api={`/banktransanction`}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Processed',
          field: 'transaction_date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Category',
          field: 'category.name',
          type: 'colorTag',
          width: 200,
          colorField: randomColor,
        },
        {
          header: 'Vendor',
          field: 'payee_name',
          type: 'colorTag',
          width: 200,
          colorField: randomColor,
        },
        {
          header: 'Approver',
        },
        {
          header: 'Payment no & bank',
          width: 250,
          render: ({row, styles}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.payment_id?.payment_no || ''}
                items={[
                  {
                    value: row?.fund_id?.name || '',
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Amount',
          type: 'currency',
          field: 'amount',
          width: 200,
        },
      ]}
    />
  );
};
