import React, {useEffect} from 'react';
import {getPreviousWeek} from '@unthinkable/react-filters/src/DateUtility';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {useFilter} from '../../../controllers/useFilter';
import {FeatureApiEndpointTab} from './assets/FeatureApiEndpointsScreen';
import {FeatureTasksTable} from '../../task/views/FeatureTasksTable';
import {
  PROJECT_VIEW_TYPE_ID,
  PROJECT_FEATURE_TYPE_ID,
  PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
  PROJECT_DATA_SERVICE_TYPE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
  PROJECT_COMPONENT_TYPE_ID,
  PROJECT_BUG_TYPE_ID,
  PROJECT_PULL_REQUEST_TYPE_ID,
  MASTER_TASK_VIEW_TYPE_ID,
  MASTER_TASK_CONTROLLER_TYPE_ID,
  MASTER_TASK_MODEL_TYPE_ID,
  MASTER_TASK_DATA_SERVICE_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
  PROJECT_QA_TEST_CASE_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {CodeAnalysisTable} from './CodeAnalysisTable';
import {FeatureDataServiceTab} from './assets/FeatureDataServicesScreen';
import {FeatureThirdPartyIntegrationTab} from './assets/FeatureThirdPartyIntegrationScreen';
import {IssuesTable} from './IssuesTable';
import {FeatureScreenTab} from './assets/FeatureScreens';
import {FeatureQATestCasesTab} from './assets/FeatureQATestCasesScreen';
import {FeatureBugTabView} from './FeatureBugTabView';
import {FeatureDataEntitiesTab} from './assets/FeatureDataEntitiesScreen';
import {FeatureComponentTab} from './assets/FeatureComponentsScreen';
import {UserStoryScreen} from './UserstoryTable';
import getPmtTooltip from '../constants/PmtTooltip';
import {Labels} from '../constants/Label';
import {FeatureStatus} from '../constants/FeatureStages';

const FeatureDetail = props => {
  const {route: {params} = {}, navigation} = props;

  const {feature: {status, type} = {}} = params || {};

  const {filterValues} = useFilter({
    params: {
      period: params?.period || getPreviousWeek(),
    },
  });
  const {params: filterParams = {}} = filterValues;
  const mergedParams = {...params, ...filterParams};
  const PmtTooltip = getPmtTooltip({params});

  const taskMenu = {
    label: 'Tasks',
    view: <FeatureTasksTable />,
    api: '/projecttasks/all',
    params: {
      feature_id: mergedParams?.feature_id,
    },
    eventSourceId: 'Task',
    defaultSelected:
      !params?.masterTaskId &&
      params?.clickedSource === PROJECT_FEATURE_TYPE_ID,
    tooltip: PmtTooltip.TASKS,
  };
  const menus = [
    status === 'released' && taskMenu,
    type === 'feature'
      ? {
          label: `${Labels.Requirements}`,
          view: <UserStoryScreen />,
          api: `/projectUserStories`,
          filter: {
            objective_ids: mergedParams?.feature_id,
          },
          eventSourceId: 'UserStory',
          tooltip: PmtTooltip.REQUIREMENTS,
        }
      : {
          label: 'Issues',
          view: <IssuesTable />,
          tooltip: PmtTooltip.ISSUES,
        },
    {
      label: 'Models',
      view: <FeatureDataEntitiesTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_MODEL_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: ['Model', 'DataImport'],
      defaultSelected:
        params?.clickedSource === PROJECT_MODEL_TYPE_ID ||
        params?.masterTaskId === MASTER_TASK_MODEL_TYPE_ID,
      tooltip: PmtTooltip.SCHEMA,
    },
    {
      label: 'APIs',
      view: <FeatureApiEndpointTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_CONTROLLER_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: 'Controller',
      defaultSelected:
        params?.clickedSource === PROJECT_CONTROLLER_TYPE_ID ||
        params?.masterTaskId === MASTER_TASK_CONTROLLER_TYPE_ID,
      tooltip: PmtTooltip.APIs,
    },
    {
      label: 'Screens',
      view: <FeatureScreenTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_VIEW_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: 'View',
      defaultSelected:
        params?.clickedSource === PROJECT_VIEW_TYPE_ID ||
        params?.masterTaskId === MASTER_TASK_VIEW_TYPE_ID,
      tooltip: PmtTooltip.SCREENS,
    },
    {
      label: 'Services',
      view: <FeatureDataServiceTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_DATA_SERVICE_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: 'DataService',
      defaultSelected:
        params?.clickedSource === PROJECT_DATA_SERVICE_TYPE_ID ||
        params?.masterTaskId === MASTER_TASK_DATA_SERVICE_TYPE_ID,
      // tooltip: PmtTooltip.DATA_SERVICES,
    },
    {
      label: 'Integrations',
      view: <FeatureThirdPartyIntegrationTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: 'ThirdPartyIntegration',
      defaultSelected:
        params?.clickedSource === PROJECT_THIRD_PARTY_INTEGRATION_TYPE_ID,
      tooltip: PmtTooltip.INTEGRATIONS,
    },
    {
      label: 'TestCases',
      view: <FeatureQATestCasesTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_QA_TEST_CASE_TYPE_ID,
        feature_id: mergedParams.feature_id,
      },
      eventSourceId: 'TestCase',
      tooltip: PmtTooltip.TEST_CASES,
    },
    {
      label: 'UI Components',
      view: <FeatureComponentTab />,
      api: '/projectAssetVersions',
      filter: {
        source: PROJECT_COMPONENT_TYPE_ID,
        feature_id: mergedParams?.feature_id,
      },
      eventSourceId: ['Component', 'DataImport'],
      defaultSelected: params?.clickedSource === PROJECT_COMPONENT_TYPE_ID,
      tooltip: PmtTooltip.UI_COMPONENTS,
    },
    (status === FeatureStatus.released.value ||
      status === FeatureStatus.qa.value) && {
      label: 'Bugs',
      view: <FeatureBugTabView />,
      api: '/projectbugs/open',
      eventSourceId: 'Bug',
      defaultSelected: params.clickedSource === PROJECT_BUG_TYPE_ID,
      tooltip: PmtTooltip.BUGS,
    },
    status !== 'released' && taskMenu,
    status === 'released' && {
      label: 'Code Analysis',
      view: <CodeAnalysisTable />,
    },
  ];

  useEffect(() => {
    if (
      params &&
      (params.__navigate?.screen === 'task-edit' ||
        (params.__navigate?.screen === 'comment-table' &&
          params.__navigate.params?.comment_source?._id ===
            PROJECT_TASK_TYPE_ID))
    ) {
      navigation.navigate(params.__navigate.screen, params.__navigate.params);
    }
  }, []);

  return <LeftMenuView {...props} params={mergedParams} menus={menus} />;
};

export default FeatureDetail;
