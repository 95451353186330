import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {
  closeJobOpening,
  holdJobOpening,
  suspendJobOpening,
  activeJobOpening,
} from '../controllers/JobOpeningController';
import {useTheme} from '@unthinkable/react-theme';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Tag} from '../../../components/chip/Chip';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {TextRenderer} from '../../../components/form/Renderers';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

const RenderDesignationAndTeam = ({row, ...rest}) => {
  const {fonts, colors} = useTheme();
  return (
    <View>
      <Text
        title={row?.position_title?.name}
        style={{
          ...rest.styles.text,
          numberOfLines: 1,
        }}>
        {row?.position_title?.name}
      </Text>
      <Text
        title={row?.department?.name}
        style={{
          ...fonts.BODY2,
          color: colors.NEUTRAL_MEDIUM,
          numberOfLines: 1,
        }}>
        {row?.department?.name}
      </Text>
    </View>
  );
};

const RenderPriority = ({row}) => {
  const {priority} = row;
  const priorityWiseColors = {
    Urgent: 'ERROR',
    High: 'Warning',
    Medium: 'ACCENT5',
    Low: 'SUCCESS',
  };
  return (
    <Tag
      value={priority}
      color={priorityWiseColors[priority]}
      textColor={priorityWiseColors[priority]}
      displayTextColor
    />
  );
};

const RenderOpenForReferrals = ({row, invoke, ...rest}) => {
  const colors = useTheme('colors');
  return (
    <Row gap={8}>
      <TouchableOpacity
        onPress={() => {
          invoke({
            uri: '/jobOpenings/' + row?._id,
            props: {
              status: 'Active',
              not_visible_in_referrals: false,
            },
          });
        }}>
        <Text style={{...rest.styles.text, color: colors['SUCCESS_HIGH']}}>
          YES
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          invoke({
            uri: '/jobOpenings/' + row?._id,
            props: {
              status: 'Active',
              not_visible_in_referrals: true,
            },
          });
        }}>
        <Text style={{...rest.styles.text, color: colors['ERROR_HIGH']}}>
          NO
        </Text>
      </TouchableOpacity>
    </Row>
  );
};

const RenderOwner = ({row}) => {
  const {hiring_manager} = row;
  const icons = useTheme('icons');
  if (hiring_manager) {
    return <UserAvatar value={hiring_manager} />;
  } else {
    return (
      <Image
        source={icons.AddPlus}
        style={{
          width: 12,
          height: 12,
        }}
      />
    );
  }
};

export const JobOpeningDraftTable = props => {
  const {navigation} = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'JobOpeningActive',
      'JobOpeningHold',
      'JobOpeningClosed',
      'JobOpeningSuspended',
    ],
  });

  return (
    <Table
      api={`/jobopening/Draft`}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      columns={[
        {
          field: 'requested_on',
          type: 'date',
          header: 'Requested On',
          width: 120,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Designation & Team',
          render: RenderDesignationAndTeam,
        },
        {
          header: 'Experience',
          field: 'experience_required.experience',
          type: 'text',
          width: 120,
        },
        {
          header: 'Skill',
          field: 'must_have_skills',
          render: MultiColorTag,
          labelField: 'name',
          colorField: 'color',
          width: 120,
        },
        {
          header: 'Priority',
          field: 'priority',
          render: RenderPriority,
          width: 120,
        },
        {
          header: 'Owner',
          render: RenderOwner,
          width: 150,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('add-job-owner', {
              jobId: row?._id,
            });
          },
        },
        {
          field: 'no_of_positions',
          header: 'Total Positions',
          type: 'number',
          width: 120,
        },
        {
          header: 'Open For Refferals',
          render: RenderOpenForReferrals,
          align: 'center',
          width: 130,
          invoke,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`editable-job-opening`, {
              jobId: row?._id,
            });
          },
        },
        holdJobOpening({invoke}),
        closeJobOpening({invoke}),
        suspendJobOpening({invoke}),
      ]}
    />
  );
};

export const JobOpeningActiveTable = props => {
  const {navigation} = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'JobOpeningActive',
      'JobOpeningHold',
      'JobOpeningClosed',
      'JobOpeningSuspended',
    ],
  });

  return (
    <Table
      variant={'bordered'}
      api={`/jobopenings`}
      filter={{
        status: 'Active',
      }}
      fields={{
        requested_on: 1,
        position_title: {
          name: 1,
        },
        department: {
          name: 1,
        },
        experience_required: {
          experience: 1,
        },
        must_have_skills: {
          name: 1,
        },
        priority: 1,
        hiring_manager: {
          name: 1,
          color: 1,
        },
        no_of_positions: 1,
        open_for_referrals: 1,
        active_candidate_count: {
          _id: 1,
        },
        closed_candidate_count: {
          _id: 1,
        },
        joined_candidate_count: {
          _id: 1,
        },
      }}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      columns={[
        {
          field: 'requested_on',
          type: 'date',
          header: 'Requested On',
          width: 120,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Designation & Team',
          render: RenderDesignationAndTeam,
        },
        {
          header: 'Experience',
          field: 'experience_required.experience',
          type: 'text',
          width: 100,
        },
        {
          header: 'Skill',
          field: 'must_have_skills',
          render: MultiColorTag,
          labelField: 'name',
          colorField: 'color',
          width: 120,
        },
        {
          header: 'Priority',
          field: 'priority',
          render: RenderPriority,
          width: 100,
        },
        {
          header: 'Owner',
          render: RenderOwner,
          width: 80,
          onPress: ({row}) => {
            navigation.navigate('add-job-owner', {
              jobId: row?._id,
            });
          },
        },
        {
          header: 'Positions',
          children: [
            {
              field: 'no_of_positions',
              header: 'Total',
              type: 'number',
              width: 80,
            },
            {
              field: row =>
                row.no_of_positions
                  ? (row.no_of_positions || 0) -
                    (row.joined_candidate_count || 0)
                  : '',
              header: 'Open',
              type: 'number',
              width: 80,
            },
          ],
        },
        {
          header: 'Candidates',
          children: [
            {
              render: CountCellRender,
              count_field: 'active_candidate_count',
              header: 'Active',
              onPress: ({row = {}}) => {
                navigation.navigate(`jobopening-candidate-tabs`, {
                  job_details: row,
                });
              },
              width: 80,
              onPlusPress: ({row = {}}) => {
                navigation.navigate(`add-candidate`, {
                  job_details: row,
                });
              },
            },
            {
              render: CountCellRender,
              count_field: 'closed_candidate_count',
              header: 'Closed',
              onPress: ({row = {}}) => {
                navigation.navigate(`jobopening-candidate-tabs`, {
                  job_details: row,
                });
              },
              width: 80,
            },
          ],
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`editable-job-opening`, {
              jobId: row?._id,
            });
          },
        },
        holdJobOpening({invoke}),
        closeJobOpening({invoke}),
      ]}
    />
  );
};

const RenderPositionTitle = ({row}) => {
  return (
    <Col>
      <TextRenderer value={row?.position_title?.name} />
      <GroupContentItem value={row?.department?.name} />
    </Col>
  );
};

const RenderRefferAction = ({styles}) => {
  const colors = useTheme('colors');
  return (
    <Text style={{...styles.text, color: colors['ACCENT1_HIGH']}}>
      Refer Candidate
    </Text>
  );
};

export const MyReferralJobOpeningTable = props => {
  let {navigation} = props;
  return (
    <Table
      api={`/jobopenings`}
      filter={{
        status: 'Active',
      }}
      fields={{
        job_created_on: 1,
        position_title: {
          name: 1,
        },
        no_of_positions: 1,
        experience_required: {
          experience: 1,
        },
        must_have_skills: {
          name: 1,
          color: 1,
        },
        department: {
          name: 1,
        },
        priority: 1,
        approved_on: 1,
      }}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate('non-editable-job-opening', {
          jobId: row?._id,
        });
      }}
      columns={[
        {
          field: 'job_created_on',
          type: 'date',
          header: 'Open Since',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          render: RenderPositionTitle,
          header: 'Position',
          type: 'text',
        },
        {
          header: 'Exp.',
          field: 'experience_required.experience',
          type: 'text',
        },
        {
          header: 'Skills',
          field: 'must_have_skills',
          labelField: 'name',
          colorField: 'color',
          titleField: 'name',
          render: MultiColorTag,
        },
        {
          header: 'Priority',
          field: 'priority',
          render: RenderPriority,
        },
        {
          field: 'no_of_positions',
          header: 'Total Position',
          type: 'number',
          align: 'center',
        },
        {
          header: 'Action',
          render: RenderRefferAction,
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate', {
              job_opening: row,
            });
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Refer Candidate',
          onPress: ({row}) => {
            navigation.navigate('add-referral-candidate', {
              job_opening: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const JobOpeningHoldTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'JobOpeningActive',
      'JobOpeningHold',
      'JobOpeningClosed',
      'JobOpeningSuspended',
    ],
  });
  return (
    <Table
      api={`/jobopening/Hold`}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`editable-job-opening`, {
          jobId: row?._id,
        });
      }}
      columns={[
        {
          field: 'requested_on',
          type: 'date',
          header: 'Requested On',
          width: 120,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Designation & Team',
          render: RenderDesignationAndTeam,
        },
        {
          header: 'Experience',
          field: 'experience_required.experience',
          type: 'text',
          width: 100,
        },
        {
          header: 'Skill',
          field: 'must_have_skills',
          render: MultiColorTag,
          labelField: 'name',
          colorField: 'color',
          width: 120,
        },
        {
          header: 'Priority',
          field: 'priority',
          render: RenderPriority,
          width: 100,
        },
        {
          header: 'Owner',
          render: RenderOwner,
          width: 80,
          onPress: ({row}) => {
            navigation.navigate('add-job-owner', {
              jobId: row?._id,
            });
          },
        },
        {
          field: 'closed_candidate_count',
          header: 'Closed Position',
          type: 'number',
          align: 'center',
          width: 120,
        },
        {
          header: 'Selection Percentage',
          render: RenderSelectionPercentage,
          width: 180,
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`editable-job-opening`, {
              jobId: row?._id,
            });
          },
        },
        activeJobOpening({invoke}),
        closeJobOpening({invoke}),
        suspendJobOpening({invoke}),
      ]}
    />
  );
};

const RenderSelectionPercentage = ({row, styles}) => {
  const colors = useTheme('colors');
  return (
    <Text style={{...styles.text, color: colors['ACCENT1_HIGH']}}>
      {Number.parseFloat(
        (row?.joined_candidate_count / row?.candidate_count || 0) * 100,
      ).toFixed(2)}
      %
    </Text>
  );
};

export const JobOpeningClosedTable = props => {
  let {navigation} = props || {};

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'JobOpeningActive',
      'JobOpeningHold',
      'JobOpeningClosed',
      'JobOpeningSuspended',
    ],
  });

  return (
    <Table
      api={`/jobopening/Closed`}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      onRowPress={({row = {}}) => {
        navigation.navigate(`jobopening-candidate-tabs`, {
          job_details: row,
          selectedTab: 'closed',
        });
      }}
      columns={[
        {
          field: 'requested_on',
          type: 'date',
          header: 'Requested On',
          width: 120,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Designation & Team',
          render: RenderDesignationAndTeam,
        },
        {
          header: 'Experience',
          field: 'experience_required.experience',
          type: 'text',
          width: 100,
        },
        {
          header: 'Skill',
          field: 'must_have_skills',
          render: MultiColorTag,
          labelField: 'name',
          colorField: 'color',
          width: 120,
        },
        {
          header: 'Priority',
          field: 'priority',
          render: RenderPriority,
          width: 100,
        },
        {
          header: 'Owner',
          render: RenderOwner,
          width: 80,
          onPress: ({row}) => {
            navigation.navigate('add-job-owner', {
              jobId: row?._id,
            });
          },
        },
        {
          field: 'joined_candidate_count',
          header: 'Closed Position',
          type: 'number',
          align: 'center',
          width: 120,
        },
        {
          header: 'Selection Percentage',
          render: RenderSelectionPercentage,
          width: 180,
        },
        {
          field: 'job_closed_on',
          header: 'Closed On',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 120,
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`editable-job-opening`, {
              jobId: row?._id,
            });
          },
        },
        activeJobOpening({invoke}),
        holdJobOpening({invoke}),
        suspendJobOpening({invoke}),
      ]}
    />
  );
};

export const JobOpeningSuspendedTable = props => {
  let {navigation, route} = props || {};
  let {params = {}} = route || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'JobOpeningActive',
      'JobOpeningHold',
      'JobOpeningClosed',
      'JobOpeningSuspended',
    ],
  });
  return (
    <Table
      api={`/jobopening/Suspended`}
      eventSourceId={[
        'HrMrfApproved',
        'JobOpeningActive',
        'JobOpeningHold',
        'JobOpeningClosed',
        'JobOpeningSuspended',
      ]}
      columns={[
        {
          field: 'position_title',
          header: 'Position',
        },
        {
          header: 'Priority',
          field: 'priority',
        },

        {
          header: 'Hiring Managers',
          field: 'hiring_manager',
          type: 'userAvatarGroup',
          width: 150,
        },
        {
          header: 'Recruiter',
          field: 'request_manager',
          type: 'userAvatarGroup',
          width: 150,
        },

        {
          field: 'no_of_positions',
          header: 'Requested Position',
          type: 'number',
          align: 'center',
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`editable-job-opening`, {
              jobId: row?._id,
            });
          },
        },
        activeJobOpening({invoke}),
        holdJobOpening({invoke}),
        closeJobOpening({invoke}),
      ]}
    />
  );
};
