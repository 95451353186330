import React from 'react';
import {TabView} from '../../../components/tab/TabView';

import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

import {Row} from '@unthinkable/react-core-components';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {AddButton} from '../../../components/button/AddButton';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import {ObjectWiseTasks} from '../../common/actions/ObjectWiseTasks';
import {StaffAugmentationInterviewResourcesTable} from '../../leadGeneration/views/InterviewResourcesTable';
import {
  ActiveEngineeringOpportunitiesList,
  ActiveEngineeringOpportunitiesListDeliveryOwner,
  ClosedEngineeringOpportunitiesGroupList,
  NewEngineeringOpportunitiesGroupList,
} from './EngineeringOpportunitiesActiveList';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const EngineeringOpportunitiesTabView = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  let tabs = {};
  const {download} = useAppStateContext();

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  tabs.new = {
    label: 'New',
    // api: '/opportunities/engineering-closed',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <NewEngineeringOpportunitiesGroupList
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={{...params, ...filterParams}}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },

          {
            type: 'autoComplete',
            label: 'Mode',
            options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
            placeholder: 'Select',
            field: 'process',
          },
          {
            type: 'autoComplete',
            label: 'Engagement Type',
            options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
            placeholder: 'Select',
            field: 'rfr_engagement_type',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: ['Won', 'Lost', 'Partially Won'],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },

          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
          {
            type: 'date',
            field: 'outcome_date',
            asParam: true,
            label: 'Outcome date',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Creation date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        {/* <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        /> */}

        <AddButton
          title="Staff Augmentation"
          view="add-staffaugmentationopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/engineering-closed/export`,
              props: {
                ...params,
                ...filterParams,
                addOnFilter: filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  tabs.active = {
    label: 'Active',
    api: '/opportunities/engineering-active',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <ActiveEngineeringOpportunitiesList
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },

          {
            type: 'autoComplete',
            label: 'Mode',
            options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
            placeholder: 'Select',
            field: 'process',
          },
          {
            type: 'autoComplete',
            label: 'Engagement Type',
            options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
            placeholder: 'Select',
            field: 'rfr_engagement_type',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },

          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Creation date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        {/* <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        /> */}

        <AddButton
          title="Staff Augmentation"
          view="add-staffaugmentationopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/engineering-active/export`,
              props: {
                ...params,
                ...filterParams,
                addOnFilter: filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    // api: '/opportunities/engineering-closed',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <ClosedEngineeringOpportunitiesGroupList
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={{...params, ...filterParams}}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },

          {
            type: 'autoComplete',
            label: 'Mode',
            options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
            placeholder: 'Select',
            field: 'process',
          },
          {
            type: 'autoComplete',
            label: 'Engagement Type',
            options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
            placeholder: 'Select',
            field: 'rfr_engagement_type',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: ['Won', 'Lost', 'Partially Won'],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },

          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
          {
            type: 'date',
            field: 'outcome_date',
            asParam: true,
            label: 'Outcome date',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Creation date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />

        <AddButton
          title="Staff Augmentation"
          view="add-staffaugmentationopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/engineering-closed/export`,
              props: {
                ...params,
                ...filterParams,
                addOnFilter: filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} actions={[]} />;
};

export const InterviewStaffAugOppTabView = props => {
  const {route: {params} = {}} = props;
  let tabs = {};

  tabs.won = {
    label: 'Won',
    ...PotentialCustomerWonStaffAugmentationOpportunityTableApi,
    view: (
      <PotentialCustomerWonStaffAugmentationOpportunityTable
        sort={{outcome_date: -1}}
        {...props}
      />
    ),
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const StaffaugmentationOppResources = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  let tabs = {};
  const {download} = useAppStateContext();

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  tabs.active = {
    label: 'Candidates',
    // api: '/allstaffaugmentationopportunities',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <StaffAugmentationInterviewResourcesTable
        {...props}
        sort={{rfr_assigned_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={{...params, ...filterParams}}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Candidate',
            api: '/employees',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'resource',
            secondarySuggestionField: 'official_email_id',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'rfr_assigned_date',
            label: 'Assigned on',
          },
          {
            type: 'date',
            field: 'interview_date',
            label: 'Interview date',
          },
          {
            type: 'date',
            field: 'outcome_date',
            // asParam: true,
            label: 'Outcome date',
          },
          {
            type: 'autoComplete',
            label: 'Practice',
            api: '/practices',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'practice',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/tools',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'skill',
          },
          {
            type: 'autoComplete',
            label: 'Outcome',
            options: [
              'Denied by Resource',
              'Drop',
              'Rejected',
              'Selected',
              'Shift To Another Project',
            ],
            placeholder: 'Select',
            field: 'outcome',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/allstaffaugmentationopportunities/export`,
              props: {
                ...params,
                ...filterParams,
                addOnFilter: filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} actions={[]} />;
};

export const EngineeringOpportunitiesTabViewForDeliveryOwners = props => {
  let {route: {params} = {}} = props;
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  let tabs = {};
  const {download} = useAppStateContext();

  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  tabs.active = {
    label: 'Active',
    api: '/opportunities/engineering-active',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <ActiveEngineeringOpportunitiesListDeliveryOwner
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },

          {
            type: 'autoComplete',
            label: 'Mode',
            options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
            placeholder: 'Select',
            field: 'process',
          },
          {
            type: 'autoComplete',
            label: 'Engagement Type',
            options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
            placeholder: 'Select',
            field: 'rfr_engagement_type',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },

          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Creation date',
          },
        ]}
      />,
      // <Row
      //   gap={8}
      //   style={{alignItems: 'center', justifyContent: 'space-between'}}>
      //   <ObjectWiseTasks
      //     {...props}
      //     params={params}
      //     object={{
      //       _id: '64e06ebb94f415bdfd0a4aed',
      //     }}
      //   />

      //   <AddButton
      //     title="Staff Augmentation"
      //     view="add-staffaugmentationopportunity"
      //     params={props?.route?.params}
      //   />
      //   <Button
      //     text="Download"
      //     onPress={() => {
      //       download({
      //         uri: `/opportunities/engineering-active/export`,
      //         props: {
      //           ...params,
      //           ...filterParams,
      //           addOnFilter: filter,
      //         },
      //       });
      //     }}
      //   />
      // </Row>,
    ],
  };

  tabs.closed = {
    label: 'Closed',
    // api: '/opportunities/engineering-closed',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <ClosedEngineeringOpportunitiesGroupList
        {...props}
        sort={{creation_date: -1}}
        addOnFilter={filter}
        filterParams={filterParams}
        params={{...params, ...filterParams}}
      />
    ),
    actions: [
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },

          {
            type: 'autoComplete',
            label: 'Mode',
            options: ['Trial', 'Interview', 'Direct', 'Test & Interview'],
            placeholder: 'Select',
            field: 'process',
          },
          {
            type: 'autoComplete',
            label: 'Engagement Type',
            options: ['Trial Engagement', 'Billable Engagement', 'Replacement'],
            placeholder: 'Select',
            field: 'rfr_engagement_type',
          },
          {
            type: 'autoComplete',
            label: 'Closed Status',
            options: ['Won', 'Lost', 'Partially Won'],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },

          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
          {
            type: 'date',
            field: 'outcome_date',
            asParam: true,
            label: 'Outcome date',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Creation date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        />

        <AddButton
          title="Staff Augmentation"
          view="add-staffaugmentationopportunity"
          params={props?.route?.params}
        />
        <Button
          text="Download"
          onPress={() => {
            download({
              uri: `/opportunities/engineering-closed/export`,
              props: {
                ...params,
                ...filterParams,
                addOnFilter: filter,
              },
            });
          }}
        />
      </Row>,
    ],
  };

  return <TabView tabs={tabs} params={params} {...props} actions={[]} />;
};
