import React from 'react';
import {Table} from '../../../../components/table/Table';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ComponentDetailForm} from './ComponentForm';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {
  PROJECT_COMPONENT_IMPORT_SAMPLE_ID,
  PROJECT_COMPONENT_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {AllComponentScreen} from './FeatureComponentsScreen';
import {TabView} from '../../../../components/tab';
import {BorderLessButton} from '../../../../components/button/AddButton';

export const componentExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';

  return {
    addOnFilter,
    search,
    searchFields,
    excelParams: {
      ...params,
      filename: 'Component',
      columns: [
        {header: 'Component', key: 'component', width: 15},
        {
          header: 'Mobile Attachments',
          key: 'mobile_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Mobile Link', key: 'mobile_link', width: 25, link: true},
        {
          header: 'Desktop Attachment',
          key: 'desktop_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Desktop Link', key: 'desktop_link', width: 25, link: true},
        {
          header: 'Tab Attachment',
          key: 'tab_attachments',
          isImage: true,
          width: 25,
        },
        {header: 'Tab Link', key: 'tab_link', width: 25, link: true},
        {header: 'Owner', key: 'owner_id.name', width: 15},
        {header: 'Design Owner', key: 'design_owner_id.name', width: 15},
        {header: 'Description', key: 'desc', width: 20},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

export const ComponentTable = props => {
  const {
    navigation,
    route: {params},
    status,
  } = props;

  const {
    assetDetail,
    mergeReferences,
    deleteAsset,
    deleteMultipleAssets,
    versions,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_COMPONENT_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({navigation, params});

  return (
    <Table
      {...props}
      eventSourceId={['Component', 'DataImport']}
      selection={{
        actions: [deleteMultipleAssets],
      }}
      columns={[
        assetColumns.component,
        assetColumns.designs,
        assetColumns.owner,
        assetColumns.designOwner,
      ]}
      onRowPress={assetDetail}
      moreActions={[
        versions,
        mergeReferences({
          targetApi: '/projectcomponents',
          deleteSource: true,
        }),
        deleteAsset,
      ]}
    />
  );
};

export const IntegratedProjectComponentTable = props => {
  const {
    navigation,
    route: {params},
    status,
  } = props;

  const {assetDetail} = useAssetActions({
    navigation,
    params,
    source: PROJECT_COMPONENT_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({navigation, params});

  return (
    <Table
      {...props}
      columns={[
        assetColumns.component,
        assetColumns.designs,
        assetColumns.owner,
        assetColumns.designOwner,
      ]}
      onRowPress={assetDetail}
    />
  );
};

export const ComponentScreen = props => {
  const {
    route: {params},
  } = props;

  const {project, projectlibrary_id, integratedProject} = params;
  const filterProps = useFilter();
  const {searchValue, filterValues, onChangeFilter, applyFilter} = filterProps;

  const filters = assetFilters({project});

  const tableProps = {
    api: `/projectcomponents/released`,
    addOnFilter: {
      library_id: projectlibrary_id,
      library_version_id: integratedProject
        ? integratedProject.library_version_id
        : null,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'component',
  };

  const renderHeader = ({selectedIds, navigation}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[filters.owner, filters.design_owner]}
          />,
          <BorderLessButton
            onPress={() => {
              navigation.navigate('project-import-data', {
                ...params,
                source: PROJECT_COMPONENT_TYPE_ID,
                sampleId: PROJECT_COMPONENT_IMPORT_SAMPLE_ID,
              });
            }}
            text={'Import'}
          />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={componentExportParams(tableProps)}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ComponentDetailForm {...props} />}>
      <ComponentTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const IntegratedProjectComponentScreen = props => {
  const {
    route: {params},
    version,
  } = props;

  const {project, projectlibrary_id, integratedProject} = params;
  const filterProps = useFilter();
  const {searchValue, filterValues, onChangeFilter, applyFilter} = filterProps;

  const filters = assetFilters({project});

  const tableProps = {
    api: `/projectcomponents/released`,
    addOnFilter: {
      library_id: projectlibrary_id,
      library_version_id: version
        ? version?._id
        : integratedProject
        ? integratedProject.library_version_id
        : null,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'component',
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        title={'UI Components'}
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[filters.owner, filters.design_owner]}
          />,
          <ExportExcelAction
            selectedIds={selectedIds}
            params={componentExportParams(tableProps)}
          />,
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ComponentDetailForm {...props} />}>
      <IntegratedProjectComponentTable
        {...props}
        renderHeader={renderHeader}
        {...tableProps}
        status={'released'}
      />
    </SplitScreen>
  );
};

export const ComponentsTab = props => {
  const {route: {params} = {}} = props;
  const {project, projectlibrary_id, integratedProject} = params;

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: <ComponentScreen {...props} />,
          api: '/projectcomponents',
          filter: {
            status: 'released',
            library_id: projectlibrary_id,
            library_version_id: integratedProject
              ? integratedProject.library_version_id
              : null,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllComponentScreen
              api={`/projects/${project?._id}/asset/${PROJECT_COMPONENT_TYPE_ID}/all-data`}
              {...props}
            />
          ),
          api: `/projects/${project?._id}/asset/${PROJECT_COMPONENT_TYPE_ID}/in-development/count`,
          params: {
            library_id: projectlibrary_id, // check this whether needed
            library_version_id: integratedProject
              ? integratedProject.library_version_id
              : null,
          },
        },
      }}
    />
  );
};
