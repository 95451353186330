import {Labels} from './Label';

export default ({params}) => {
  const {project, milestone, module, feature} = params;
  let ObjectLabel = 'Project';
  if (feature) {
    ObjectLabel = `${Labels.Feature}`;
  } else if (milestone) {
    ObjectLabel = 'Milestone';
  } else if (module) {
    ObjectLabel = 'Module';
  }

  return {
    APIs: `Documentation of APIs for the ${ObjectLabel} request parameters & responses`,
    INTEGRATIONS: `Documentation for all the 3rd party integrations you have facilitated in your software`,
    ISSUES: `Pending, active & resolved issues which have been logged in the live software`,
    MILESTONES: `Active & achieved milestones - which are grouped ${Labels.Features_lower} tied to a higher business goal or plan`,
    MODULES: `Distinct sections or units of the software defined in Locomo, each with specific functionalities and boundaries to organize the project effectively.`,
    FEATURES: `Can be features, enhancements, improvements or resolution of an issue in ${ObjectLabel} . You can view project’s roadmap & released can give you insights of project’s efficiency`,
    REQUIREMENTS: `Specifications and criteria defined within Locomo that the software must meet, based on stakeholder and user input.`,
    ROLES: `Designations assigned to team members within Locomo, based on their responsibilities and expertise in the project.`,
    SCHEMA: `ER diagram of ${ObjectLabel} with further details like fields & code`,
    SCREENS: `All the UI of the ${ObjectLabel} and how these screens are related to each other with component tags & design links`,
    SETTINGS: `Configuration options within Locomo that allow customization of the tool to fit specific project needs and preferences.`,
    TASKS: `Object driven clear actionable tasks in order to achieve ${ObjectLabel} with time estimate & doer & reviewer.`,
    TEAM_TASKS: `Team member wise plan & output reports based on the tasks in their account`,
    TEST_CASES: `Specific scenarios and conditions defined within Locomo under which the software is tested to ensure it meets requirements and functions correctly.`,
    THEME: `Documentation of your software’s color & font styles`,
    UI_COMPONENTS: `Individual parts of the user interface, such as buttons, forms, and menus, identified and managed within Locomo to make up the screens.`,
    BUGS: `All the bugs reported in the ${Labels.Feature_lower} during its QA stage`,
    // DATA_SERVICES: `Data services are automated actions that are executed in response to specific events or conditions within a software system`,
  };
};
