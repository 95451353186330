import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AppResourceForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;

  const {onSubmit} = useFormSubmit({
    uri: '/appresources',
    eventSourceId: 'appResourceAdded',
    ...props,
  });

  return (
    <Form
      api={`/appresources/${row?._id}`}
      fields={{
        parent: {display_label: 1},
        label: 1,
        type: 1,
        id: 1,
        showSingleView: 1,
        index: 1,
      }}
      onSubmit={onSubmit}
      header={{title: 'Add App Resource'}}
      layoutFields={[
        {
          label: 'Parent Resource',
          type: 'autoComplete',
          field: 'parent',
          api: '/appresources',
          suggestionField: 'display_label',
          valueField: 'display_label',
        },
        {
          label: 'Id',
          type: 'text',
          field: 'id',
        },
        {
          label: 'Label',
          type: 'text',
          field: 'label',
        },
        {
          label: 'Type',
          type: 'autoComplete',
          options: ['Module', 'Menu', 'Screen', 'DataEntity', 'DataView'],
          field: 'type',
        },
        {
          label: 'Show Single View',
          type: 'autoComplete',
          options: [{label: 'True', value: true}],
          field: 'showSingleView',
          suggestionField: 'label',
          valueField: 'label',
          keyField: 'value',
          visible: ({values}) => values?.type === 'Menu',
        },
        {
          label: 'Index',
          type: 'text',
          field: 'index',
        },
      ]}
      {...props}
    />
  );
};

export const EditAppResourceForm = props => {
  return (
    <AppResourceForm
      header={{title: 'Edit App Resource'}}
      mode="edit"
      {...props}
    />
  );
};
