import AccountTable from '../../common/views/AccountTable';
import {AccountwiseBalanceTab} from '../../vendor/view/AccountwiseBalanceTable';
import {BalanceSheetTabs} from '../../vendor/view/BalanceSheetTable';
import {
  CustomerBalanceTab,
  VendorAdvanceTab,
  VendorBalanceTab,
} from '../../vendor/view/EntityBalanceTab';
import {PandLTabs} from '../../vendor/view/PandLReportTable';
import {BankBalanceTab} from '../../vendor/view/ReconcilationTables';
import {TrailBalanceTab} from '../../vendor/view/TrailBalanceTable';
import {VoucherLedgerTab} from '../../vendor/view/VoucherLedgerTable';
import {VoucherTrailReport} from '../../vendor/view/VourcherTrailReport';
import VoucherTab from './VoucherTab';

export const ScreenMapping = [
  {
    id: 'voucher',
    view: props => {
      return <VoucherTab {...props} />;
    },
  },
  {
    id: 'reconciled',
    view: props => {
      return <BankBalanceTab {...props} />;
    },
  },
  {
    id: 'vouchertrailreport',
    view: props => {
      return <VoucherTrailReport {...props} />;
    },
  },
  {
    id: 'vendoradvance',
    view: props => {
      return <VendorAdvanceTab {...props} />;
    },
  },
  {
    id: 'customerbalance',
    view: props => {
      return <CustomerBalanceTab {...props} />;
    },
  },
  {
    id: 'accountwisebalance',
    view: props => {
      return <AccountwiseBalanceTab {...props} />;
    },
  },
  {
    id: 'pl',
    view: props => {
      return <PandLTabs {...props} />;
    },
  },
  {
    id: 'balancesheet',
    view: props => {
      return <BalanceSheetTabs {...props} />;
    },
  },
  {
    id: 'trial',
    view: props => {
      return <TrailBalanceTab {...props} />;
    },
  },
  {
    id: 'ledger',
    view: props => {
      return <VoucherLedgerTab {...props} />;
    },
  },
  {
    id: 'account',
    label: 'Account',
    view: <AccountTable />,
  },
  {
    id: 'vendorbalance',
    view: props => {
      return <VendorBalanceTab {...props} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'vouchers',
//     views: [
//       {
//         id: 'voucher',
//         label: 'Vouchers',
//         view: props => {
//           return <VoucherTab {...props} />;
//         },
//       },
//       {
//         id: 'reconciled',
//         label: 'Reconciled',
//         view: props => {
//           return <BankBalanceTab {...props} />;
//         },
//       },
//       {
//         id: 'vouchertrailreport',
//         label: 'Voucher Trial Report',
//         view: props => {
//           return <VoucherTrailReport {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'balances',
//     views: [
//       {
//         id: 'vendoradvance',
//         label: 'Vendor Advance',
//         view: props => {
//           return <VendorAdvanceTab {...props} />;
//         },
//       },
//       {
//         id: 'vendorbalance',
//         label: 'Vendor Balance',
//         view: props => {
//           return <VendorBalanceTab {...props} />;
//         },
//       },
//       {
//         id: 'customerbalance',
//         label: 'Customer Balance',
//         view: props => {
//           return <CustomerBalanceTab {...props} />;
//         },
//       },
//       {
//         id: 'accountwisebalance',
//         label: 'Account Wise Balance',
//         view: props => {
//           return <AccountwiseBalanceTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'reports',
//     views: [
//       {
//         id: 'pl',
//         label: 'P&L',
//         view: props => {
//           return <PandLTabs {...props} />;
//         },
//       },
//       {
//         id: 'balancesheet',
//         label: 'Balance Sheet',
//         view: props => {
//           return <BalanceSheetTabs {...props} />;
//         },
//       },
//       {
//         id: 'trial',
//         label: 'Trial Balance',
//         view: props => {
//           return <TrailBalanceTab {...props} />;
//         },
//       },
//       {
//         id: 'ledger',
//         label: 'Ledger',
//         view: props => {
//           return <VoucherLedgerTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'accounts',
//     views: [
//       {
//         id: 'account',
//         label: 'Account',
//         view: <AccountTable />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'vouchers',
//     label: 'Vouchers',
//   },
//   {
//     id: 'balances',
//     label: 'Balance',
//   },
//   {
//     id: 'reports',
//     label: 'Reports',
//   },
//   {
//     id: 'accounts',
//     label: 'Accounts',
//   },
// ];
