import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const InternalAllocationForm = props => {
  const params = props?.route?.params;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'performance-report',
    ...props,
  });
  return (
    <Form
      onSubmit={onSubmit}
      header="Internal allocation"
      submitAction="Save"
      defaultValues={{
        project_id: {_id: row?._id, project: row?.project},
        is_actual_allocation: true,
      }}
      layoutFields={[
        {
          label: 'Project',
          field: 'project_id',
          type: 'autoComplete',
          api: `/projects`,
          filter: {
            projecttype: 'i',
            project_status: 'a',
          },
          suggestionField: 'project',
          valueField: 'project',
          readOnly: true,
          size: 6,
        },
        {
          label: 'Employee',
          field: 'employee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 6,
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'Start Date',
          size: 6,
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'End Date',
          size: 6,
        },
        {
          type: 'number',
          field: 'hours',
          label: 'Hours',
        },
      ]}
    />
  );
};
