import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';

const KeyPerformanceIndicatorTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyperformanceindicators',
  });

  return (
    <Table
      eventSourceId={'keyperformanceindicators'}
      api={`/keyperformanceindicators`}
      renderHeader={() => (
        <TableHeader
          title="Key Performance Indicator"
          actions={[
            <AddButton
              title="Add"
              view="add-key-perfermance-indicator"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        kra_id: {name: 1},
        name: 1,
        description: 1,
        api: 1,
        entity: {label: 1},
        role: 1,
        department: {name: 1},
      }}
      columns={[
        {
          field: 'kra_id.name',
          header: 'KRA',
          responsive: 'sm',
        },
        {
          field: 'name',
          header: 'KPI',
          responsive: 'sm',
        },
        {
          field: 'department.name',
          header: 'Department',
          responsive: 'sm',
        },
        {
          field: 'entity.label',
          header: 'Entity',
          width: 150,
        },
        {
          field: 'role',
          header: 'Role',
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-performance-indicator', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/keyperformanceindicators/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export default KeyPerformanceIndicatorTable;
