import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import {Image, Text} from '@unthinkable/react-core-components';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useAppStateContext} from '../../../providers/AppState';

const RenderWonStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.won_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.won_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderLostStatus = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.lost_count) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {row?.lost_count}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const RenderSuccess = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  console.log(row);
  const colors = useTheme('colors');
  if (row?.success_percentage && row?.success_percentage == 100) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: '#39e75f',
            align: 'center',
          }}>
          {row?.success_percentage}
        </Text>
      </>
    );
  }
  if (row?.success_percentage && row?.success_percentage >= 50) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.WARNING_HIGH,
            align: 'center',
          }}>
          {(row?.success_percentage).toFixed(2)}
        </Text>
      </>
    );
  }
  if (row?.success_percentage && row?.success_percentage < 50) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: 'red',
            align: 'center',
          }}>
          {(row?.success_percentage).toFixed(2)}
        </Text>
      </>
    );
  } else {
    return 0;
  }
};

const WON_COUNT = {
  header: 'Won',
  render: RenderWonStatus,
  width: 150,
};

const LOST_COUNT = {
  header: 'Lost',
  render: RenderLostStatus,
  width: 150,
};

const SUCCESS = {
  header: 'Success %',
  render: RenderSuccess,
  width: 150,
};

export const RFRResourceWiseSuccessList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/resource-wise-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        // {
        //   header: 'Interview Scheduled',
        //   field: 'interview_scheduled',
        //   type: 'number',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         invalid_interview: {$exists: false},
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...WON_COUNT,
          align: 'center',
          field: 'won_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'resource-wise-closed-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Selected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                  invalid_interview: {$exists: false},
                },
              },
            );
          },
        },
        {
          ...LOST_COUNT,
          align: 'center',
          field: 'lost_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'resource-wise-closed-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Rejected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                  invalid_interview: {$exists: false},
                },
              },
            );
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(
              'resource-wise-closed-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Drop',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                  invalid_interview: {$exists: false},
                },
              },
            );
          },
        },

        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   align: 'center',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },

        SUCCESS,
      ]}
    />
  );
};

export const RFRDeliveryOwnerWiseSuccessList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/delivery-owner-wise-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Delivery owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        // {
        //   header: 'Interview Scheduled',
        //   field: 'interview_scheduled',
        //   type: 'number',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         invalid_interview: {$exists: false},
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...WON_COUNT,
          align: 'center',
          field: 'won_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Selected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },
        {
          ...LOST_COUNT,
          align: 'center',
          field: 'lost_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Rejected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Drop',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },

        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   align: 'center',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },

        SUCCESS,
      ]}
    />
  );
};

export const RFRDeliveryOwnerWiseSuccessListForDo = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/delivery-owner-wise-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      filter={{
        delivery_owner: employeeId?._id,
      }}
      columns={[
        {
          header: 'Delivery owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        // {
        //   header: 'Interview Scheduled',
        //   field: 'interview_scheduled',
        //   type: 'number',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         invalid_interview: {$exists: false},
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...WON_COUNT,
          align: 'center',
          field: 'won_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Selected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },
        {
          ...LOST_COUNT,
          align: 'center',
          field: 'lost_count',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Rejected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Drop',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                },
              },
            );
          },
        },

        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   align: 'center',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('resource-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },

        SUCCESS,
      ]}
    />
  );
};

export const RFRResourceWiseActiveSuccessList = props => {
  let {params = {}, navigation, searchValue} = props;
  return (
    <Table
      params={{...params}}
      api={'/rfr/resource-wise-active-interviews'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      limit={1000}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Total',
          align: 'center',
          aggregate: true,
          field: 'profile_shared_count',
          count_field: 'profile_shared_count',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                // resource: row?._id,
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Screening',
          align: 'center',
          aggregate: true,
          field: 'screening_count',
          count_field: 'screening_count',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: false},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Interview Scheduled',
          align: 'center',
          aggregate: true,
          field: 'interview_conducted_count',
          count_field: 'interview_conducted_count',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $gte: new Date(),
                },
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Result Due',
          align: 'center',
          aggregate: true,
          field: 'result_due_count',
          count_field: 'result_due_count',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $lt: new Date(),
                },
                opportunity_status: 'Active',
              },
            });
          },
        },
      ]}
    />
  );
};

export const RFRResourceWiseSuccessScheduledList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/resource-wise-comparitive-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          header: 'Interview Scheduled',
          field: 'interview_scheduled',
          aggregate: true,
          type: 'number',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          header: 'Interview Conducted',
          field: 'interview_conducted',
          aggregate: true,
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
                outcome: {$exists: true},
              },
            });
          },
        },
        {
          ...WON_COUNT,
          aggregate: true,
          field: 'won_count',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Selected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          ...LOST_COUNT,
          align: 'center',
          aggregate: true,
          field: 'lost_count',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Rejected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Drop',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          header: 'Pending',
          field: 'pending_count',
          aggregate: true,
          align: 'center',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: null,
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        SUCCESS,
      ]}
    />
  );
};

export const RFRMentorWiseSuccessList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/mentor-wise-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        // {
        //   header: 'Interview Scheduled',
        //   field: 'interview_scheduled',
        //   type: 'number',
        //   width: 150,
        //   onPress: ({row}) => {
        //     navigation.navigate('mentor-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         invalid_interview: {$exists: false},
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },
        {
          ...WON_COUNT,
          field: 'won_count',
          aggregate: true,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(
              'mentor-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Selected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  invalid_interview: {$exists: false},
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                },
              },
            );
          },
        },
        {
          ...LOST_COUNT,
          field: 'lost_count',
          aggregate: true,
          align: 'center',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate(
              'mentor-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Rejected',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  invalid_interview: {$exists: false},
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                },
              },
            );
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate(
              'mentor-wise-success-closed-opportunity-table',
              {
                row,
                addOnFilter: {
                  outcome: 'Drop',
                  outcome_date: {
                    $gte: period.from,
                    $lte: period.to,
                  },
                  invalid_interview: {$exists: false},
                  process: {$ne: 'Direct'},
                  mentor: {$exists: true, $ne: null},
                  delivery_owner: {$exists: true, $ne: null},
                },
              },
            );
          },
        },

        // {
        //   header: 'Pending',
        //   field: 'pending_count',
        //   type: 'number',
        //   onPress: ({row}) => {
        //     navigation.navigate('mentor-wise-success-opportunity-table', {
        //       row,
        //       addOnFilter: {
        //         outcome: null,
        //         interview_date: {
        //           $gte: period.from,
        //           $lte: period.to,
        //         },
        //       },
        //     });
        //   },
        // },

        {...SUCCESS, align: 'center'},
      ]}
    />
  );
};

export const RFRMentorWiseActiveSuccessList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/mentor-wise-success-interview'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Total',
          align: 'center',
          aggregate: true,
          field: 'profile_shared_count',
          count_field: 'profile_shared_count',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                // mentor: row?._id,
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Screening',
          align: 'center',
          aggregate: true,
          field: 'screening_count',
          count_field: 'screening_count',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: false},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Interview Scheduled',
          align: 'center',
          aggregate: true,
          field: 'interview_conducted_count',
          count_field: 'interview_conducted_count',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $gte: new Date(),
                },
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Result Due',
          align: 'center',
          aggregate: true,
          field: 'result_due_count',
          count_field: 'result_due_count',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                interview_date: {$exists: true},
                interview_date: {
                  $lt: new Date(),
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const RFRMentorWisecomparitiveSuccessList = props => {
  let {params = {}, navigation, filterParams, searchValue} = props;
  const {period = {}} = filterParams || {};
  return (
    <Table
      params={{...params, ...filterParams}}
      api={'/rfr/mentor-wise-comparitive-success'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      searchFields="name"
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          header: 'Interview Scheduled',
          field: 'interview_scheduled',
          aggregate: true,
          type: 'number',
          width: 150,
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          header: 'Interview Conducted',
          field: 'interview_conducted',
          aggregate: true,
          type: 'number',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('resource-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                invalid_interview: {$exists: false},
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
                outcome: {$exists: true},
              },
            });
          },
        },
        {
          ...WON_COUNT,
          aggregate: true,
          field: 'won_count',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Selected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          ...LOST_COUNT,
          align: 'center',
          aggregate: true,
          field: 'lost_count',
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Rejected',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },
        {
          header: 'Drop',
          field: 'drop_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: 'Drop',
                outcome_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {
          header: 'Pending',
          field: 'pending_count',
          aggregate: true,
          type: 'number',
          onPress: ({row}) => {
            navigation.navigate('mentor-wise-success-opportunity-table', {
              row,
              addOnFilter: {
                outcome: null,
                interview_date: {
                  $gte: period.from,
                  $lte: period.to,
                },
              },
            });
          },
        },

        {...SUCCESS, align: 'center'},
      ]}
    />
  );
};

export const RFRDeliveryOwnerWiseActiveSuccessList = props => {
  let {params = {}, navigation, searchValue} = props;
  return (
    <Table
      params={{...params}}
      api={'/rfr/delivery-owner-wise-active-interviews'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      limit={1000}
      searchFields="name"
      columns={[
        {
          header: 'Delivery Owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Total',
          align: 'center',
          aggregate: true,
          field: 'profile_shared_count',
          count_field: 'profile_shared_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  // delivery_owner: row?._id,
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
        {
          render: CountCellRender,
          header: 'Screening',
          align: 'center',
          aggregate: true,
          field: 'screening_count',
          count_field: 'screening_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: false},
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
        {
          render: CountCellRender,
          header: 'Interview Scheduled',
          align: 'center',
          aggregate: true,
          field: 'interview_conducted_count',
          count_field: 'interview_conducted_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: true},
                  interview_date: {
                    $gte: new Date(),
                  },
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
        {
          render: CountCellRender,
          header: 'Result Due',
          align: 'center',
          aggregate: true,
          field: 'result_due_count',
          count_field: 'result_due_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: true},
                  interview_date: {
                    $lt: new Date(),
                  },
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
      ]}
    />
  );
};

export const RFRDeliveryOwnerWiseActiveSuccessListForDO = props => {
  let {params = {}, navigation, searchValue} = props;
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  return (
    <Table
      params={{...params}}
      api={'/rfr/delivery-owner-wise-active-interviews'}
      eventSourceId={'allocatedResource'}
      search={searchValue}
      limit={1000}
      searchFields="name"
      filter={{
        delivery_owner: employeeId?._id,
      }}
      columns={[
        {
          header: 'Delivery Owner',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.email}
              />
            );
          },
        },
        {
          // ...WON_COUNT,
          render: CountCellRender,
          header: 'Profile Shared',
          align: 'center',
          aggregate: true,
          field: 'profile_shared_count',
          count_field: 'profile_shared_count',
          onPress: ({row}) => {
            navigation.navigate('active-candidate-resource-table', {
              row,
              filter: {
                delivery_owner: row?._id,
                status: 'Active',
                $or: [{outcome: null}, {outcome: {$exists: false}}],
                invalid_interview: null,
                process: {$ne: 'Direct'},
                opportunity_status: 'Active',
              },
            });
          },
        },
        {
          render: CountCellRender,
          header: 'Screening',
          align: 'center',
          aggregate: true,
          field: 'screening_count',
          count_field: 'screening_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: false},
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
        {
          render: CountCellRender,
          header: 'Interview Scheduled',
          align: 'center',
          aggregate: true,
          field: 'interview_conducted_count',
          count_field: 'interview_conducted_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: true},
                  interview_date: {
                    $gte: new Date(),
                  },
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
        {
          render: CountCellRender,
          header: 'Result Due',
          align: 'center',
          aggregate: true,
          field: 'result_due_count',
          count_field: 'result_due_count',
          onPress: ({row}) => {
            navigation.navigate(
              'delivery_owner-wise-success-opportunity-table',
              {
                row,
                addOnFilter: {
                  status: 'Active',
                  $or: [{outcome: null}, {outcome: {$exists: false}}],
                  invalid_interview: null,
                  process: {$ne: 'Direct'},
                  interview_date: {$exists: true},
                  interview_date: {
                    $lt: new Date(),
                  },
                  opportunity_status: 'Active',
                },
              },
            );
          },
        },
      ]}
    />
  );
};
