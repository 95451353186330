import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  ClosedAssignedTicketsTable,
  OpenAssignedTicketsTable,
  TemporarilyClosedAssignedTicketsTable,
} from './AssignedTicketsView';

const TICKET_STATUS = {
  IN_PROGRESS: 'In Progress',
  TEMPORARILY_CLOSED: 'Temporarily Closed',
  CLOSED: 'Closed',
};

export const AssignedTicketTabs = props => {
  const {
    route: {params = {}},
    user: {employee = {}} = {},
  } = props;

  const filterProps = useFilter();

  const {
    filterValues: {filter: addOnFilter = {}, params: filterParams},
    onChangeFilter,
    searchValue,
  } = filterProps;

  const extraFilter = {};

  extraFilter.raised_on_date = {
    $gte: 'params?.raised_on_date?.from',
    $lte: 'params?.raised_on_date?.to',
  };

  extraFilter.temporarily_closed_on = {
    $gte: 'params?.temporarily_closed_on?.from',
    $lte: 'params?.temporarily_closed_on?.to',
  };

  extraFilter.closed_on = {
    $gte: 'params?.closed_on?.from',
    $lte: 'params?.closed_on?.to',
  };

  const tabs = [
    {
      label: 'Assigned',
      view: (
        <OpenAssignedTicketsTable
          filterParams={filterParams}
          addOnFilter={{...addOnFilter, ...extraFilter}}
          sort={{raised_on_date: -1}}
        />
      ),
      api: '/tickets',
      filter: {
        agent: employee?._id,
        status: {
          $nin: ['Temporarily Closed', 'Closed'],
        },
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      eventSourceId: 'tickets',
      addOnFilter: {
        ...addOnFilter,
        ...extraFilter,
      },
      params: {
        ...filterParams,
      },
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Closure Status',
            //   options: ['Closed', 'Temporarily Closed'],
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   field: 'status',
            // },
          ]}
        />,
      ],
    },
    {
      label: 'Overdue',
      view: (
        <OpenAssignedTicketsTable
          filterParams={filterParams}
          addOnFilter={{
            ...addOnFilter,
            ...extraFilter,
            expected_close: {$lte: Date.now()},
          }}
          sort={{expected_close: 1}}
        />
      ),
      api: `/tickets`,
      filter: {
        status: {
          $nin: [TICKET_STATUS.CLOSED, TICKET_STATUS.TEMPORARILY_CLOSED],
        },
        agent: employee?._id,
      },
      eventSourceId: ['tickets'],
      addOnFilter: {
        ...addOnFilter,
        ...extraFilter,
        expected_close: {$lte: Date.now()},
      },
      params: {
        ...filterParams,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Closure Status',
            //   options: ['Closed', 'Temporarily Closed'],
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   field: 'status',
            // },
            {
              type: 'date',
              label: 'Temporarily Closed On',
              field: 'temporarily_closed_on',
              asParam: true,
            },
          ]}
        />,
      ],
    },
    {
      label: 'Under Observation',
      view: (
        <TemporarilyClosedAssignedTicketsTable
          filterParams={filterParams}
          addOnFilter={{...addOnFilter, ...extraFilter}}
          sort={{temporarily_closed_on: -1}}
        />
      ),
      api: `/tickets`,
      filter: {agent: employee?._id, status: 'Temporarily Closed'},
      eventSourceId: ['tickets'],
      addOnFilter: {
        ...addOnFilter,
        ...extraFilter,
      },
      params: {
        ...filterParams,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Closure Status',
            //   options: ['Closed', 'Temporarily Closed'],
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   field: 'status',
            // },
            {
              type: 'date',
              label: 'Temporarily Closed On',
              field: 'temporarily_closed_on',
              asParam: true,
            },
          ]}
        />,
      ],
    },
    {
      label: 'Closed',
      view: (
        <ClosedAssignedTicketsTable
          filterParams={filterParams}
          addOnFilter={{...addOnFilter, ...extraFilter}}
          sort={{closed_on: -1}}
        />
      ),
      api: `/tickets`,
      filter: {agent: employee?._id, status: 'Closed'},
      addOnFilter: {
        ...addOnFilter,
        ...extraFilter,
      },
      params: {
        ...filterParams,
      },
      search: searchValue,
      searchFields: ['subject', 'description', 'ticket_number'],
      eventSourceId: ['tickets'],
      actions: [
        <GroupFilter
          {...filterProps}
          filters={[
            {
              type: 'autoComplete',
              label: 'Raised By',
              field: 'raised_by',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
            },
            {
              type: 'date',
              label: 'Raised On',
              field: 'raised_on_date',
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'First Response Time',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
              field: 'is_first_response_delayed',
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Closure Status',
            //   options: ['Closed', 'Temporarily Closed'],
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   field: 'status',
            // },
            {
              type: 'autoComplete',
              label: 'Closure Time',
              field: 'is_delayed',
              options: [
                {label: 'Delayed', value: true},
                {
                  label: 'In Time',
                  value: {
                    $in: [null, false],
                  },
                },
              ],
              suggestionField: 'label',
              keyField: 'value',
            },
            {
              type: 'date',
              label: 'Closed On',
              field: 'closed_on',
              asParam: true,
            },
          ]}
        />,
      ],
    },
  ];

  return (
    <TabView
      tabs={tabs}
      {...params}
      {...props}
      search={searchValue}
      searchFields={['subject', 'description', 'ticket_number']}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
      ]}
    />
  );
};
