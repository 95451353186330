import {
  Image,
  Styled,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';

export const Container = Styled(View).withConfig({
  shouldForwardProp: prop => !['direction'].includes(prop),
})`
  flex:1;
  overflow:hidden;
  ${props => props.styles?.container}
  ${({direction}) => (direction ? {flexDirection: direction} : void 0)}
`;

export const MenuBarContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['isOpen', 'hidden'].includes(prop),
})`
  height:100%;
  transition: width 0.3s ease;
  ${props => props.styles?.menuBarContainer}
  ${props => {
    const {menuBarWidth, collapsedMenuBarWidth} = props.styles || {};
    if (menuBarWidth && collapsedMenuBarWidth) {
      return {
        width: props.isOpen ? menuBarWidth : collapsedMenuBarWidth,
      };
    }
  }}
  ${({hidden}) => (hidden ? {display: 'none'} : void 0)}
`;

export const CollapseView = Styled(View).withConfig({
  shouldForwardProp: prop => !['isCollapsed'].includes(prop),
})`
  opacity: ${props => (props.isCollapsed ? 1 : 0)};
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #FFFFFF;
  ${({isCollapsed}) => (!isCollapsed ? {display: 'none'} : {})}
  ${props => props.styles?.collapseView}
`;

export const ToggleButton = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop => !['hidden'].includes(prop),
})`
  position: absolute;
  top: 35px;
  right: -12px;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${props => props.styles?.toggleButton}
  ${props => (props?.hidden ? {display: 'none'} : void 0)}
`;

export const ToggleButtonIcon = Styled(Image)
  .attrs(props => ({
    source: props.styles?.toggleIcon,
  }))
  .withConfig({
    shouldForwardProp: prop => !['isOpen'].includes(prop),
  })`
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
  transform: rotate(${props => (props.isOpen ? 180 : 0)}deg);
  ${props => props.styles?.toggleIconStyle}
`;

export const ViewContainer = Styled(View)`
 flex:1;
 overflow:hidden;
${props => props.styles?.viewContainer}
`;
