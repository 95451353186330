import React from 'react';
import {
  Text,
  View,
} from '@unthinkable/react-core-components';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';
import { useTheme } from '@react-navigation/native';
import { AddButton } from '../../../components/button/AddButton';
import moment from 'moment';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';

const approvePackage = ({ invoke }) => {
  const { user = {} } = useAppStateContext();
  return {
    title: 'Approve',
    onPress: props => {
      const { row } = props;
      invoke({
        uri: `/employeesalarycomponents/${row?._id}`,
        props: {
          mark_salary: false,
          approved_by : user._id
        },
      });
    },
    confirm: {
      title: 'Approve Package',
      message: 'Are you sure you want to Approve?',
    },
  }

};

const rejectPackage = ({ invoke }) => {
  const { user = {} } = useAppStateContext();
  return {
    title: 'Reject',
    onPress: props => {
      const { row } = props;
      invoke({
        uri: `/employeesalarycomponents/${row?._id}`,
        props: {
          mark_salary: true,
          rejected_by : user._id
        },
      });
    },
    confirm: {
      title: 'Reject Package',
      message: 'Are you sure you want to Reject?',
    },
  }
};


export const EmployeePackageDetailsTable = props => {
  const { route: { params = {} } = {}, navigation } = props;
  const { row , formHr } = params;
  const color = useTheme('colors');
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['EmployeePackageDetails'],
  });

  return (
    <Table
      eventSourceId={['EmployeePackageDetails']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Package Details"
            actions={[
              <AddButton
                title="Add Package"
                view="add-package-details"
                params={params}
              />
            ]}
          />
        );
      }}
      api={`/employeesalarycomponents/employee/${row?._id}`}
      onRowPress={props => {
        const { row } = props;
        navigation.navigate(`edit-package-details`, {
          row,
          formHr
        });
      }}
      columns={[
        {
          header : "Package Name",
          type : 'text',
          field : 'name'
        },
        {
          header: 'Duration',
          type: 'date',
          render: ({ row, styles }) => {
            let { rowText } = styles;
            return (
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ ...rowText, marginRight: 3, color: '#737373' }}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{ ...rowText, marginLeft: 3, color: '#737373' }}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 300,
        },
        {
          header: 'CTC(Per Month)',
          field: 'ctc_amount',
          type: 'colorTag',
          colorField: color.ACCENT6_MEDIUM,
        },
        {
          header: 'CTC(Per Year)',
          field: 'ctc_amount_per_year',
          type: 'colorTag',
          colorField: color.ACCENT5_MEDIUM,
        },
        {
          header: 'Approver',
          field: 'approved_by',
          type: "userAvatarChip"
        },
        {
          header: 'Creater',
          field: 'created_by',
          type: "userAvatarChip"
        }
      ]}
      moreActions={[
        approvePackage({invoke}),
        rejectPackage({invoke}),
        {
          title: 'Upload Package',
          onPress: ({row}) => {
            navigation.navigate('upload-package', {
              _id : row._id,
              ...params,
            });
          },
        },
        {
          title: 'Change Package Review Date',
          visible : formHr ? true : false,
          onPress: ({row}) => {
            navigation.navigate('change-package-review-date', {
              _id : row._id,
              ...params,
            });
          },
        },
        {
          title: 'Rename Package',
          visible : formHr ? true : false,
          onPress: ({row}) => {
            navigation.navigate('rename-package', {
              _id : row._id,
              ...params,
            });
          },
        },
        {
          title: 'Update CTC Amount',
          visible : formHr ? true : false,
          onPress: ({row}) => {
            navigation.navigate('update-ctc-amount', {
              _id : row._id,
              ...params,
            });
          },
        },
        {
          title: 'Add Incentive',
          visible : formHr ? true : false,
          onPress: ({row}) => {
            navigation.navigate('add-incentive', {
              component : row,
              ...params,
            });
          },
        },
        {
          title: 'Duplicate',
          onPress: ({row}) => {
            navigation.navigate('duplicate-package', {
              component : row,
              ...params,
            });
          },
        },
        {
          title: 'Activity History',
          visible : formHr ? false : true,
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'approved_by', select: {name: 1}},
                {
                  path: 'rejected_by',
                  select: {name: 1},
                },
                {
                  path: 'created_by',
                  select: {name: 1},
                },
              ],
              api: `/employeesalarycomponents/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};


export const EmployeePackageDetailsForAppraisalTable = props => {
  const { route: { params = {} } = {}, navigation } = props;
  const { employee } = params;
  const color = useTheme('colors');
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['EmployeePackageDetails'],
  });

  return (
    <Table
      eventSourceId={['EmployeePackageDetails']}
      renderHeader={() => {
        return (
          <TableHeader
            title="Package Details"
          />
        );
      }}
      api={`/employeesalarycomponents/apprasial/${employee}`}
      onRowPress={props => {
        const { row } = props;
        navigation.navigate(`edit-package-details`, {
          row,
          noneditable : true
        });
      }}
      columns={[
        {
          header: 'Duration',
          type: 'date',
          render: ({ row, styles }) => {
            let { rowText } = styles;
            return (
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ ...rowText, marginRight: 3, color: '#737373' }}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{ ...rowText, marginLeft: 3, color: '#737373' }}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 300,
        },
        {
          header: 'CTC(Per Month)',
          field: 'ctc_amount',
          type: 'colorTag',
          colorField: color.ACCENT6_MEDIUM,
        },
        {
          header: 'CTC(Per Year)',
          field: 'ctc_amount_per_year',
          type: 'colorTag',
          colorField: color.ACCENT5_MEDIUM,
        },
        {
          header: 'Approver',
          field: 'approved_by',
          type: "userAvatarChip"
        },
        {
          header: 'Creater',
          field: 'created_by',
          type: "userAvatarChip"
        }
      ]}
    />
  );
};
