import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
export const WebPageCampaignFrom = props => {
  const {
    route: {params},
    mode,
    navigation,
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/webcampaigns',
    eventSourceId: 'WebPageCampaigns',
    ...props,
  });
  const {department, product, row, target_audience, afterSubmit, searchValue} =
    params;
  return (
    <Form
      onSubmit={onSubmit}
      header={'Add Web Page Campaign'}
      submitAction={'Save'}
      api={`/webcampaigns/${row?._id}`}
      fields={{
        name: 1,
        date: 1,
        url: 1,
        inmail: {
          inmail_subject: 1,
        },
        profile_owner: {
          name: 1,
        },
        assigned_to: {
          name: 1,
        },
        communication_type: 1,
        marketing_theme: {
          name: 1,
        },
        channel: {name: 1},
        employee_team: {name: 1},
        organization: {name: 1},
      }}
      afterSubmit={afterSubmit}
      defaultValues={{
        product: product?._id,
        date: getZeroTimeDate(new Date()),
        communication_type: 'Automatic',
        status: 'active',
        name: searchValue,
      }}
      layoutFields={[
        {
          label: 'Web Page',
          field: 'name',
          type: 'text',
          size: 'medium',
        },
        {
          label: 'URL',
          field: 'url',
          type: 'text',
          size: 'medium',
        },
        {
          label: 'Owner',
          field: 'assigned_to',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          secondarySuggestionField: 'official_email_id',
          valueField: 'name',
          size: 'medium',
        },
        {
          label: 'Channel',
          field: 'channel',
          type: 'autoComplete',
          api: `/channels`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          filter: {
            _id: {
              $nin: ['66d2ec16f897151a53daf08b'],
            },
          },
          required: true,
        },
        {
          label: 'Employee Team',
          field: 'employee_team',
          type: 'autoComplete',
          api: `/employeeteams`,
          filter: {
            _id: {
              $in: ['654c6ebd64126a8c199e9537', '655b1c352722272f13e98953'],
            },
          },
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        {
          label: 'Organization',
          field: 'organization',
          type: 'autoComplete',
          api: `/organizations`,
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          required: true,
        },
        // {
        //   label: 'Marketing Theme',
        //   field: 'marketing_theme',
        //   type: 'autoComplete',
        //   api: '/marketingThemes',
        //   suggestionField: 'theme_value_proposition',
        //   valueField: 'theme_value_proposition',
        //   size: 1,
        //   visible: () => mode !== 'edit',
        // },
        // {
        //   label: 'Linkedin Profile',
        //   field: 'profile_owner',
        //   type: 'autoComplete',
        //   api: '/employeeSuggestions',
        //   suggestionField: 'name',
        //   valueField: 'name',
        //   secondarySuggestionField: 'official_email_id',
        // },
        // {
        //   fields: [
        //     {
        //       type: 'radioGroup',
        //       field: 'communication_type',
        //       label: 'Communication Type',
        //       options: ['Automatic', 'Manual'],
        //       dependentFields: ['loaded_prospects', 'loaded_messages'],
        //     },
        //   ],
        // },
        // {
        //   visible: ({values}) => values?.communication_type == 'Manual',
        //   field: 'loaded_prospects',
        //   render: props => {
        //     const {value} = props;
        //     const {setFieldValue} = useFormContext();
        //     const colors = useTheme('colors');
        //     return (
        //       <TouchableOpacity
        //         onPress={() => {
        //           navigation.navigate('load-prospects', {
        //             setFieldValue,
        //             selectedIds: value,
        //             filter: {
        //               industry: target_audience?.industry,
        //             },
        //           });
        //         }}>
        //         <Text
        //           style={{
        //             color: colors['ACCENT1_HIGH'],
        //             paddingTop: 4,
        //             paddingBottom: 4,
        //             textDecoration: 'underline',
        //           }}>
        //           {`Loaded Prospects(${value?.length || 0})`}
        //         </Text>
        //       </TouchableOpacity>
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const EditWebCampaign = props => {
  return (
    <WebPageCampaignFrom
      mode="edit"
      header={'Web Campaign Detail'}
      {...props}
    />
  );
};
