import React from 'react';
import {Row, Image, View} from '@unthinkable/react-core-components';
import {useComponentTheme} from '@unthinkable/react-theme';
import {resolveExp} from '@unthinkable/react-utils';
import {MultiAvatarChip, MultiTag} from '../../../components/chip/MultiChip';

const PlusMultipleTextRender = props => {
  const {row, columnField, valueField, readOnly, maxTagWidth} = props;

  let viewsArr = resolveExp(row, columnField) || [];

  let values = [];
  viewsArr.map(value => {
    values.push(resolveExp(value, valueField));
  });

  let {rowStyle, addPlus, iconStyle, iconContainerStyle} = useComponentTheme(
    'MultipleTextRenderStyle',
  );

  return (
    <Row style={rowStyle}>
      {viewsArr.length ? (
        columnField === 'owner_id' ? (
          <MultiAvatarChip values={values} maxWidth={maxTagWidth || 140} />
        ) : (
          <MultiTag values={values} maxWidth={maxTagWidth || 200} />
        )
      ) : readOnly ? null : (
        <View style={iconContainerStyle}>
          <Image source={addPlus} style={iconStyle} />
        </View>
      )}
    </Row>
  );
};

export default PlusMultipleTextRender;
