import {useInvoke} from '../../../controllers/useInvoke';
import {
  PROJECT_BUG_TYPE_ID,
  PROJECT_TASK_TYPE_ID,
} from '../../common/constants/SourceConstants';
import {useAppStateContext} from '../../../providers/AppState';
import {CommentMoreAction} from '../../../app-components/renders/CommentRender';

export const useTaskActions = ({
  navigation,
  params,
  fromMyView,
  taskTableSource,
}) => {
  const {user} = useAppStateContext();
  const invoke = useInvoke({method: 'put', eventSourceId: 'Task'});
  const deleteInvoke = useInvoke({method: 'delete', eventSourceId: 'Task'});

  const markDisqualified = {
    title: 'Mark Disqualified',
    confirm: {
      title: 'Mark Disqualified',
      message: 'Are you sure you want to mark the task as disqualified?',
    },
    onPress: ({row}) => {
      navigation.navigate('task-disqualification', {row});
    },
    visible: ({row}) => {
      return (
        row.source?._id != PROJECT_BUG_TYPE_ID &&
        row.status === 'completed' &&
        !row.disqualified &&
        (row.feature_id ? row.feature_id.type === 'feature' : true)
      );
    },
  };

  const markQualified = {
    title: 'Mark Qualified',
    confirm: {
      title: 'Mark Qualified',
      message: 'Are you sure you want to mark the task as qualified?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          disqualified: false,
        },
      });
    },
    visible: ({row}) => {
      return (
        row.source?._id != PROJECT_BUG_TYPE_ID &&
        row.status === 'completed' &&
        row.disqualified === true
      );
    },
  };

  const deleteTask = {
    title: 'Delete',
    variant: 'error',
    confirm: {
      title: 'Delete',
      message: 'Are you sure you want to Delete this task?',
    },
    onPress: ({row}) => {
      deleteInvoke({
        uri: `/projecttasks/${row?._id}`,
        props: {},
      });
    },
    visible: ({row}) => {
      return row.status !== 'completed';
    },
  };

  const updateAssignedTo = {
    title: 'Update Assignee',
    onPress: ({row}) => {
      navigation.navigate('update-assignee', {
        row,
        task_id: row?._id,
        project: row?.project_id,
      });
    },
    visible: !!fromMyView,
  };

  const archive = {
    title: 'Mark Archive',
    onPress: ({row}) => {
      navigation.navigate('archive-task', {
        task: row,
      });
    },
    visible: ({row}) => {
      return (
        !row?.master_task_assignment?.archive_disabled &&
        (row?.status === 'active' || row?.status === 'backlog')
      );
    },
  };

  const unsetEstHours = {
    title: 'Unset Est. Hours',
    onPress: ({row}) => {
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          est_hours: null,
        },
        message: 'Unset successfully',
      });
    },
    visible: ({row}) => {
      return row?.est_hours && row?.sub_task_count > 0;
    },
  };

  const unarchive = {
    title: 'Mark Active',
    confirm: {
      title: 'Mark Active',
      message: 'Do you want to unarchive this task?',
    },
    onPress: ({row}) => {
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          status: 'active',
          archived_on: null,
          archive_reason: null,
        },
      });
    },
    visible: ({row}) => {
      return row?.status === 'archived';
    },
  };

  const markActive = {
    title: 'Mark Active',
    onPress: ({row}) => {
      navigation.navigate('mark-task-active', {
        row,
        fromMyView,
      });
    },
    visible: ({row}) => row?.status === 'backlog',
  };

  const moveToBacklog = {
    title: 'Move to Backlog',
    confirm: {
      title: 'Move to Backlog',
      message: 'Do you want to move this task to backlog?',
    },
    onPress: ({row}) => {
      invoke({
        uri: `/projectTasks/${row?._id}`,
        props: {
          status: 'backlog',
        },
      });
    },
    visible: ({row}) => row?.status === 'active',
  };

  const updateCompletedOnDate = {
    title: 'Update Completed on',
    onPress: props => {
      const {row} = props;
      navigation.navigate('update-complete-date', {
        row,
      });
    },
    visible: ({row}) => {
      return row.status === 'completed';
    },
  };

  const activityLogs = {
    title: 'Acvitity Logs',
    onPress: ({row}) => {
      navigation.navigate(`activity-logs`, {
        _id: row?._id,
        api: `/tasks/${row?._id}/activities`,
        excludeFields: ['parent_objects'],
        title: row?.name ? `Task : ${row?.name}` : '',
      });
    },
  };

  const alignWork = {
    title: 'Align Work',
    onPress: ({row}) => {
      navigation.navigate(`update-task-output`, {
        ...params,
        row,
      });
    },
    visible: ({row}) => row?.status === 'active' || row?.status === 'completed',
  };

  const markComplete = {
    title: 'Mark Complete',
    onPress: ({row}) => {
      navigation.navigate('mark-task-complete', {
        ...params,
        row,
      });
    },
  };

  const markIncomplete = {
    title: 'Mark Incomplete',
    confirm: {
      title: 'Mark Incomplete',
      message: 'Are you sure you want to mark task Incomplete?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          status: 'active',
          completed_on: null,
          review_pending: null,
        },
      });
    },
    visible: ({row}) => {
      return row.status === 'completed';
    },
  };

  const markStrategic = {
    title: 'Mark Strategic',
    confirm: {
      title: 'Mark Strategic',
      message: 'Are you sure you want to mark task strategic?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          is_strategic: true,
        },
      });
    },
    visible: ({row}) => {
      return (
        row.status !== 'completed' &&
        !row.is_strategic &&
        row?.status !== 'archived'
      );
    },
  };

  const markDelegated = {
    title: 'Delegate Task',
    confirm: {
      title: 'Delegate Task',
      message: 'Are you sure you want to delegate this task?',
    },
    onPress: props => {
      const {row} = props;
      invoke({
        uri: `/delegateTask/${row?._id}`,
      });
    },
    visible: ({row}) => {
      return (
        !row.delegators || row.delegators.length == 0 || !row.delegators.some(item => item?._id === user?._id)
      );
    },
  };

  const showOutput = {
    title: 'Show Output',
    onPress: ({row}) => {
      navigation.navigate('mark-task-complete', {
        ...params,
        row,
        readOnly: true,
      });
    },
    visible: ({row}) => {
      return row.status === 'completed';
    },
  };

  const review = {
    title: 'Review',
    onPress: ({row, readOnly}) => {
      !readOnly &&
        navigation.navigate('add-review', {
          ...params,
          row,
          readOnly,
        });
    },
  };

  const comments = CommentMoreAction({
    navigation,
    params: ({row}) => ({
      project: row?.project_id || params?.project,
      comment_source: {_id: PROJECT_TASK_TYPE_ID, label: 'Task'},
      comment_source_id: {_id: row._id, name: row.name},
    }),
  });

  const updateTaskType = {
    title: 'Update Task Type',
    onPress: ({row}) => {
      navigation.navigate('update-task-type-form', {
        row,
      });
    },
    visible: ({row}) => {
      return row.status !== 'completed';
    },
  };

  const plan = {
    title: 'Plan Task',
    onPress: ({row}) => {
      navigation.navigate('add-task-plan-form', {
        row,
      });
    },
    visible: ({row}) => {
      return (
        row.status !== 'completed' &&
        row.status !== 'archived' &&
        !row?.plan_date
      );
    },
  };

  const updatePlan = {
    title: 'Update Plan',
    onPress: ({row}) => {
      navigation.navigate('update-task-plan-form', {
        row,
      });
    },
    visible: ({row}) => {
      return (
        row.status !== 'completed' &&
        row.status !== 'archived' &&
        row?.plan_date
      );
    },
  };
  const markReviewArchived = {
    title: 'Archive Review',
    confirm: {
      title: 'Archive Review',
      message:
        'Are you sure you want to archive the review for the selected tasks?',
    },
    onPress: ({row, selectedIds}) => {
      const updates = {
        is_review_archived: true,
        review_pending: null,
      };
      if (selectedIds) {
        invoke({
          uri: `/projecttasks/batchUpdate`,
          props: {
            ids: selectedIds,
            updates,
          },
        });
      } else {
        invoke({
          uri: `/projecttasks/${row?._id}`,
          props: updates,
        });
      }
    },

    visible: () => taskTableSource === 'Pending',
  };

  const markReviewUnarchived = {
    title: 'Unarchive Review',
    confirm: {
      title: 'Unarchive Review',
      message:
        'Are you sure you want to unarchive the review for the selected tasks?',
    },
    onPress: ({row, selectedIds}) => {
      const updates = {
        review_pending: true,
        is_review_archived: null,
      };
      if (selectedIds) {
        invoke({
          uri: `/projecttasks/batchUpdate`,
          props: {
            ids: selectedIds,
            updates,
          },
        });
      } else {
        invoke({
          uri: `/projecttasks/${row?._id}`,
          props: updates,
        });
      }
    },
    visible: () => taskTableSource === 'Archived',
  };

  const markFavorite = {
    title: ({row}) => {
      let favoriteBy = row?.favorite_by || [];
      let isFavorite = favoriteBy.includes(user?._id);
      return isFavorite ? 'Remove from Favorite' : 'Add to Favorite';
    },
    visible: !user?.externalUser,
    onPress: ({row}) => {
      let favoriteBy = row?.favorite_by || [];
      let isFavorite = favoriteBy.includes(user?._id);
      let updatedFavoriteBy = isFavorite
        ? favoriteBy.filter(id => id !== user?._id)
        : [...favoriteBy, user?._id];
      invoke({
        uri: `/projecttasks/${row?._id}`,
        props: {
          favorite_by: updatedFavoriteBy,
        },
      });
    },
  };

  return {
    markDisqualified,
    markQualified,
    deleteTask,
    updateAssignedTo,
    archive,
    unarchive,
    moveToBacklog,
    updateCompletedOnDate,
    activityLogs,
    alignWork,
    markComplete,
    markIncomplete,
    showOutput,
    review,
    markActive,
    comments,
    updateTaskType,
    plan,
    updatePlan,
    markStrategic,
    markFavorite,
    markReviewArchived,
    markReviewUnarchived,
    unsetEstHours,
    markDelegated,
  };
};

export const taskFilters = {
  project: ({user}) => ({
    type: 'autoComplete',
    label: 'Project',
    placeholder: 'Select project',
    field: 'project_id',
    filter: {user_organization: user?.org},
    api: `/projects`,
    suggestionField: 'project',
    valueField: 'project',
  }),
  custom: {
    type: 'boolean',
    label: 'Custom',
    field: 'is_ad_hoc',
  },
  strategic: {
    type: 'boolean',
    label: 'Strategic',
    field: 'is_strategic',
  },
  assigned_to: {
    type: 'autoComplete',
    api: '/usersuggestions',
    field: 'assigned_to',
    label: 'Assignee',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
    placeholder: 'Select Assignee',
  },
  due_date: {
    type: 'date',
    field: 'due_date',
    label: 'Due On',
  },
  source: {
    type: 'autoComplete',
    api: '/types',
    field: 'source',
    label: 'Output Type',
    valueField: 'label',
    suggestionField: 'label',
    placeholder: 'Select Output',
  },
  is_open: {
    type: 'autoComplete',
    options: ['Yes', 'No'],
    field: 'is_open',
    label: 'Unassigned',
    placeholder: 'Unassigned',
    asParam: true,
  },
  reviewer: {
    type: 'autoComplete',
    api: '/usersuggestions',
    field: 'reviewer',
    label: 'Reviewer',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
    placeholder: 'Select Reviewer',
  },
  completed_on: {
    type: 'date',
    field: 'completed_on',
    label: 'Completed On',
  },
  disqualified: {
    type: 'autoComplete',
    placeholder: 'Select',
    label: 'Disqualified',
    field: 'disqualified',
    keyField: 'value',
    suggestionField: 'label',
    options: [
      {label: 'True', value: true},
      {label: 'False', value: {$in: [null, false]}},
    ],
  },
  disqualified_by: {
    type: 'autoComplete',
    label: 'Disqualified by',
    placeholder: 'Select',
    api: `/usersuggestions`,
    field: 'disqualified_by',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
  },
  favorite_by: {
    type: 'autoComplete',
    label: 'Favorite by',
    placeholder: 'Select',
    api: `/usersuggestions`,
    field: 'favorite_by',
    valueField: 'name',
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
  },
};
