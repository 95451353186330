import { Text } from '@unthinkable/react-core-components';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { Form, MultiStepForm } from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {
    useFetchData
} from '../../../controllers/useFetchData';
import { useInvoke } from '../../../controllers/useInvoke';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const employeeComputations = fetch => ({
  'set permanent address as current address': {
    compute: async value => {
      let totalAmount = 0;
      if (value?.sameAsCurrent) {
        return {
          address_line_1: value?.address_line_1_current,
          address_line_2: value?.address_line_2_current,
          city: value?.city_current,
          state: value?.state_current,
          country: value?.country_current,
          zip: value?.zip_current,
        };
      }
    },
    multi: true,
    dependencies: ['sameAsCurrent'],
  },
});

export const AddEmployeeFormFromParichay = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    mode,
  } = props;
  const {product, row} = params;
  const {employee} = row || {};
  const {post, fetch} = useAppStateContext();
  const {data: parichayReferenceData} = useFetchData({
    api: '/employeeparichays',
    filter: {
      _id: row?._id,
    },
    fields: {
      _id: 1,
      nationality: {_id: 1, name: 1},
      blood_group: {_id: 1, name: 1},
      nominee_relation: {_id: 1, relation: 1},
      secondary_nominee_relation: {_id: 1, relation: 1},
      bank_accounts: {name_id: {name: 1}},
    },
    only: true,
  });

  if (!parichayReferenceData) {
    return null;
  }
  return (
    <MultiStepForm
      api={`/employee-details/${employee?._id ? employee?._id : row?._id}`}
      onSubmit={onSubmit}
      header={'Add Employee'}
      submitAction={'Save'}
      computations={{...employeeComputations(fetch)}}
      defaultValues={{
        employee_status: 'Pending For Approval',
        employee_parichay: row?._id,
        name: row?.full_name,
        dob: row?.dob,
        full_name: row?.full_name,
        gender: row?.gender,
        nationality: parichayReferenceData?.nationality,
        marital_status: row?.marital_status,
        anniversary_date: row?.anniversary_date,
        spouse_name: row?.spouse_name,
        blood_group: parichayReferenceData?.blood_group,
        mobile_number: row?.mobile_number,
        email_id: row?.email_id,
        address_line_1_current: row?.address_line_1_current,
        address_line_2_current: row?.address_line_2_current,
        city_current: row?.city_current,
        state_current: row?.state_current,
        country_current: row?.country_current,
        zip_current: row?.zip_current,
        sameAsCurrent: row?.sameAsCurrent,
        address_line_2: row?.address_line_2,
        address_line_1: row?.address_line_1,
        city: row?.city,
        state: row?.state,
        country: row?.country,
        zip: row?.zip,
        father_name: row?.father_name,
        father_occupation: row?.father_occupation,
        father_date_of_birth: row?.father_date_of_birth,
        mother_name: row?.mother_name,
        mother_occupation: row?.mother_occupation,
        mother_date_of_birth: row?.mother_date_of_birth,
        emergency_contact_persion: row?.emergency_contact_persion,
        contact_address_of_parents: row?.contact_address_of_parents,
        emergency_contact_person_relation:
          row?.emergency_contact_person_relation,
        emergency_contact_persion_number: row?.emergency_contact_persion_number,
        hobbies: row?.hobbies,
        secondary_qualification_institute_name:
          row?.secondary_qualification_institute_name,
        secondary_university_name: row?.secondary_university_name,
        secondary_year_of_passing: row?.secondary_year_of_passing,
        secondary_grade: row?.secondary_grade,
        secondary_education_proofs: row?.secondary_education_proofs,
        sr_secondary_institute_name: row?.sr_secondary_institute_name,
        sr_secondary_university_name: row?.sr_secondary_university_name,
        sr_secondary_year_of_passing: row?.sr_secondary_year_of_passing,
        sr_secondary_grade: row?.sr_secondary_grade,
        sr_secondary_education_proofs: row?.sr_secondary_education_proofs,
        graduation_qualification_name: row?.graduation_qualification_name,
        graduation_course_name: row?.graduation_course_name,
        graduation_institute_name: row?.graduation_institute_name,
        graduation_university_name: row?.graduation_university_name,
        graduation_year_of_passing: row?.graduation_year_of_passing,
        graduation_grade: row?.graduation_grade,
        graduation_proofs: row?.graduation_proofs,
        post_graduation_qualification_name:
          row?.post_graduation_qualification_name,
        employement_proofs: row?.employement_proofs,
        post_graduation_course_name: row?.post_graduation_course_name,
        post_graduation_institute_name: row?.post_graduation_institute_name,
        post_graduation_university_name: row?.post_graduation_university_name,
        post_graduation_year_of_passing: row?.post_graduation_year_of_passing,
        post_graduation_grade: row?.post_graduation_grade,
        post_graduation_proofs: row?.post_graduation_proofs,
        have_past_experience: row?.have_past_experience,
        experience_details: row?.experience_details,
        bank_accounts: parichayReferenceData?.bank_accounts,
        nominee_name: row?.nominee_name,
        nominee_relation: parichayReferenceData?.nominee_relation, ////
        nominee_address: row?.nominee_address, ////
        secondary_nominee_name: row?.secondary_nominee_name, ////
        secondary_nominee_relation:
          parichayReferenceData?.secondary_nominee_relation, ////
        secondary_nominee_address: row?.secondary_nominee_address, ////
        nominee_declaration_form: row?.nominee_declaration_form, ////
        adhar_number: row?.adhar_number, ////
        adhar_copy: row?.adhar_copy, ////
        pan_number: row?.pan_number, ////
        pan_copy: row?.pan_copy, ////
        passport_number: row?.passport_number, ////
        passport_valid_up_to: row?.passport_valid_up_to, ////
        passport_copy: row?.passport_copy, ////
        gratuity_form: row?.gratuity_form, ////
        pf_document: row?.pf_document, ////
        consent_document: row?.consent_document, ////
        offer_letter_copy: row?.offer_letter_copy, ////
        photo: row?.photo, ////
        esi_number: row?.esi_number, ////
        pf_number: row?.pf_number, ////

        //   date: getZeroTimeDate(new Date()),
        //   communication_type: 'Automatic',
      }}
      saveOnNext={false}
      steps={[
        {
          title: 'Employee',
          fields: [
            // {
            //   label: 'Employee Code',
            //   field: 'employee_code',
            //   type: 'text',
            //   size: 4,
            // },
            {
              label: 'Employee Name',
              field: 'name',
              type: 'text',
              size: 4,
              required: true,
            },
            {
              label: 'Joining Date',
              field: 'joining_date',
              type: 'date',
              size: 4,
              required: true,

              // required: true,
            },
            {
              label: 'Employee Status', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'employee_type',
              type: 'autoComplete',
              api: '/employeetypes',
              suggestionField: 'name',
              valueField: 'name',
              // required: true,
              size: 4,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value?.name == 'Permanent') {
                  setFieldValue('notice_period_days', 90);
                }
              },
            },

            //"5422b496c9b2fac76efa5f5c"
            {
              label: 'Probation end date',
              field: 'probation_end_date',
              type: 'date',
              size: 4,
              visible: ({values}) => {
                if (values?.employee_type?._id === '5422b496c9b2fac76efa5f5c') {
                  return true;
                }
                return false;
              },

              required: ({values}) => {
                if (values?.employee_type?._id === '5422b496c9b2fac76efa5f5c') {
                  return true;
                }
                return false;
              },

              // required: true,
            },
            {
              label: 'Deployable Status',
              field: 'deployable_trainable_status',
              type: 'autoComplete',
              options: ['Deployable', 'Trainable'],
              size: 4,
              // required: true,
            },

            {
              label: 'Package structure', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'package_structure',
              type: 'autoComplete',
              api: '/packagestructures',
              suggestionField: 'name',
              valueField: 'name',
              // required: true,
              size: 4,
            },
            {
              label: 'Confirmation Date',
              field: 'confirmationDate',
              type: 'date',
              readOnly: true,
              // required: true,
              size: 4,
              // required: true,
            },
            {
              size: 4,
              field: 'is_btb',
              type: 'checkbox',
              label: 'BTB',
            },
            {
              size: 4,
              field: 'is_apprenticeship',
              type: 'checkbox',
              label: 'Apprentice',
            },
            {
              size: 4,
              field: 'is_task',
              type: 'checkbox',
              label: 'Is Task',
            },
            {
              size: 6,
              field: 'is_agreement_letter_added',
              type: 'checkbox',
              label: 'Agreement',
            },
            {
              label: 'Agreement Copy',
              placeholder: 'Agreement Copy',
              type: 'file',
              visible: ({values}) => values && values.is_agreement_letter_added,
              field: 'agreement_letter',
              options: {bucketName: 'manaze'},
            },
            {
              label: 'Contact',
              fields: [
                {
                  label: 'Work email',
                  field: 'official_email_id',
                  type: 'text',
                  size: 6,
                  required: true,
                },
                // {
                //   label: 'Mobile number',
                //   field: 'mobile_number',
                //   type: 'text',
                //   size: 'medium',
                //   // required: true,
                // },
              ],
            },
            {
              label: 'Position & reporting',
              fields: [
                {
                  size: 4,
                  field: 'is_wilp',
                  type: 'checkbox',
                  label: 'WILP',
                },
                {
                  size: 4,
                  field: 'is_int',
                  type: 'checkbox',
                  label: 'Intern',
                },
                {
                  size: 4,
                  field: 'is_lateral',
                  type: 'checkbox',
                  label: 'Lateral',
                },
                {
                  type: 'autoComplete',
                  field: 'designation',
                  label: 'Designation',
                  api: '/designations',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  field: 'employee_level',
                  label: 'Level',
                  api: '/levels',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  field: 'manualReportingTo',
                  type: 'checkbox',
                  label: 'Manual reporting',
                },
                {
                  type: 'multiAutoComplete',
                  field: 'reporting_to',
                  label: 'Reporting to',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'skill_leader',
                  label: 'Skill manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  // required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'functional_manager',
                  label: 'Functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'new_functional_manager',
                  label: 'New functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  // required: true,

                  size: 6,
                },
              ],
            },
            {
              label: 'Skill',
              fields: [
                {
                  label: 'Primary skills',
                  field: 'resource_language',
                  header: 'Skills',
                  type: 'autoComplete',
                  api: '/skills',
                  suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                  valueField: 'name',
                  // required: true,
                  size: 6,
                },
                {
                  // size: 6,
                  groups: [
                    {
                      defaultHidden: true,
                      label: 'Secondary skill',
                      fields: [
                        {
                          label: 'Secondary skills',
                          field: 'seconday_resource_language',
                          header: 'Skills',
                          type: 'autoComplete',
                          api: '/skills',
                          suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                          valueField: 'name',
                          required: true,
                          size: 3,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: 'Contractual details',
              fields: [
                {
                  label: 'Notice period days',
                  type: 'number',
                  // required: true,
                  field: 'notice_period_days',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Increment cycle',
                  field: 'increment_type',
                  type: 'autoComplete',
                  options: ['Annual', 'Biannual'],
                  required: true,
                  size: 4,
                },
                {
                  label: 'Salary payment mode',
                  field: 'salary_payment_mode',
                  type: 'autoComplete',
                  required: true,
                  options: ['By Cheque', 'Account Transfer'],
                  size: 4,
                },
              ],
            },

            {
              label: 'Organization details',
              fields: [
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  placeholder: 'Product',
                  label: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  placeholder: 'Department',
                  label: 'Department',
                  field: 'department',
                  type: 'autoComplete',
                  api: `/departments`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Work location',
                  field: 'sitting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  label: 'Accounting location',
                  field: 'accounting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'under_bond',
                  type: 'checkbox',
                  label: 'Bond',
                },
                {
                  label: 'Bond duration(Years)',
                  type: 'text',
                  field: 'bond_duration',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                  required: ({values}) => values && values.under_bond,
                },
                {
                  label: 'Bond amount(INR)',
                  type: 'text',
                  field: 'bond_amount',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                  required: ({values}) => values && values.under_bond,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'is_background_verification_required',
                  type: 'checkbox',
                  label: 'Background verification required',
                },
                {
                  label: 'Background verification due',
                  type: 'date',
                  field: 'background_verification_due_date',
                  size: 6,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                  required: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification outcome',
                  type: 'text',
                  field: 'background_verification_outcome',
                  size: 6,
                  readOnly: true,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification report',
                  placeholder: 'Background verification report',
                  type: 'file',
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                  field: 'background_verification_report',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',
                  required: true,
                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                {
                  label: 'Spouse name',
                  field: 'spouse_name',
                  type: 'textArea',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                  required: true,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'State',
                  field: 'state',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                  required: true,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  type: 'text',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  field: 'emergency_contact_persion_number',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Attendance',
          fields: [
            {
              label: 'Working days',
              field: 'working_days',
              required: true,

              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 4,
              options: [
                {value: 5, label: '5'},
                {value: 5.5, label: '5.5'},
                {value: 6, label: '6'},
              ],
              //   size: 'medium',
            },
            {
              label: 'Biometric code',
              type: 'text',
              field: 'biometric_code',
              size: 4,
            },
            {
              label: 'Exceptional Shift',
              type: 'checkbox',
              field: 'is_exceptional_shift',
              size: 4,
            },
            {
              label: 'Shift', //
              fields: [
                {
                  field: 'shift_information',
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Shift',
                          header: 'Shift',
                          field: 'shift_id',
                          type: 'autoComplete',
                          api: '/shifts',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'shift_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Schdule',
              fields: [
                {
                  field: 'schedule_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Schdule',
                          header: 'Schdule',
                          field: 'schedule_id',
                          type: 'autoComplete',
                          api: '/schedules',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Schdule',
                          field: 'schedule_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Leave Policy',
              fields: [
                {
                  field: 'leave_policy_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id',
                          type: 'autoComplete',
                          api: '/leavepolicies',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Holiday Calendar',
              fields: [
                {
                  field: 'holiday_calendar_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id',
                          type: 'autoComplete',
                          api: '/holidaycalendars',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  type: 'text',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  type: 'text',
                  required: true,
                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  required: true,

                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',
                  required: true,

                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',
                  required: true,

                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',
                  required: true,

                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'Has past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      // validate: async fieldValue => {
                      //   const accnoReg = /^[0-9]{9,18}$/;
                      //   if (fieldValue && !accnoReg.test(fieldValue)) {
                      //     return 'Account Number Not Valid';
                      //   }
                      // },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      // validate: async fieldValue => {
                      //   if (fieldValue && fieldValue.length !== 11) {
                      //     return 'ONLY 11 CHARACTERS ALLOWED';
                      //   }
                      // },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',
              required: true,
              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  size: 6,
                  required: true,
                  validate: async fieldValue => {
                    const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                    if (fieldValue && !regexp.test(fieldValue)) {
                      return 'INVALID ADHAR CARD NUMBER';
                    }
                  },
                },
                {
                  placeholder: 'Adhaar card copy',
                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  field: 'applied_for_pan_card',
                  type: 'checkbox',
                  label: 'Applied for pan card',
                },
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  required: ({values}) =>
                    values && !values.applied_for_pan_card,
                  validate: async fieldValue => {
                    let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                    if (fieldValue && !regpan.test(fieldValue)) {
                      return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                    }
                  },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  required: ({values}) =>
                    values && !values.applied_for_pan_card,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  required: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
                {
                  label: 'LWF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'LWF number',
                      type: 'text',
                      field: 'lwf_number',
                    },
                  ],
                },
                {
                  label: 'Pran number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'Pran number',
                      type: 'text',
                      field: 'pran_id',
                    },
                  ],
                },
                {
                  label: 'UAN number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'UAN number',
                      type: 'text',
                      field: 'uan_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: ['EmployeeAssignment', 'selectedCandidate'],
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    mode,
  } = props;
  const {product, row, defaultValues = {}} = params;
  const {employee} = row || {};
  const {post, fetch} = useAppStateContext();
  return (
    <MultiStepForm
      api={`/employee-details/${employee?._id ? employee?._id : row?._id}`}
      onSubmit={onSubmit}
      header={'Add Employee'}
      submitAction={'Save'}
      computations={{...employeeComputations(fetch)}}
      defaultValues={{
        ...defaultValues,
        employee_status: 'Pending For Approval',
      }}
      saveOnNext={false}
      steps={[
        {
          title: 'Employee',
          fields: [
            // {
            //   label: 'Employee Code',
            //   field: 'employee_code',
            //   type: 'text',
            //   size: 4,
            // },
            {
              label: 'Employee Name',
              field: 'name',
              type: 'text',
              size: 4,
              required: true,
            },
            {
              label: 'Joining Date',
              field: 'joining_date',
              type: 'date',
              size: 4,
              required: true,

              // required: true,
            },
            {
              label: 'Employee Status', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'employee_type',
              type: 'autoComplete',
              api: '/employeetypes',
              suggestionField: 'name',
              valueField: 'name',
              // required: true,
              size: 4,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value?.name == 'Permanent') {
                  setFieldValue('notice_period_days', 90);
                }
              },
            },

            //"5422b496c9b2fac76efa5f5c"
            {
              label: 'Probation end date',
              field: 'probation_end_date',
              type: 'date',
              size: 4,
              visible: ({values}) => {
                if (values?.employee_type?._id === '5422b496c9b2fac76efa5f5c') {
                  return true;
                }
                return false;
              },

              required: ({values}) => {
                if (values?.employee_type?._id === '5422b496c9b2fac76efa5f5c') {
                  return true;
                }
                return false;
              },

              // required: true,
            },
            {
              label: 'Deployable Status',
              field: 'deployable_trainable_status',
              type: 'autoComplete',
              options: ['Deployable', 'Trainable'],
              size: 4,
              // required: true,
            },

            {
              label: 'Package structure', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'package_structure',
              type: 'autoComplete',
              api: '/packagestructures',
              suggestionField: 'name',
              valueField: 'name',
              // required: true,
              size: 4,
            },
            {
              label: 'Confirmation Date',
              field: 'confirmationDate',
              type: 'date',
              readOnly: true,
              // required: true,
              size: 4,
              // required: true,
            },
            {
              size: 4,
              field: 'is_btb',
              type: 'checkbox',
              label: 'BTB',
            },
            {
              size: 4,
              field: 'is_apprenticeship',
              type: 'checkbox',
              label: 'Apprentice',
            },
            {
              size: 4,
              field: 'is_task',
              type: 'checkbox',
              label: 'Is Task',
            },
            {
              size: 6,
              field: 'is_agreement_letter_added',
              type: 'checkbox',
              label: 'Agreement',
            },
            {
              label: 'Agreement Copy',
              placeholder: 'Agreement Copy',
              type: 'file',
              visible: ({values}) => values && values.is_agreement_letter_added,
              field: 'agreement_letter',
              options: {bucketName: 'manaze'},
            },
            {
              label: 'Contact',
              fields: [
                {
                  label: 'Work email',
                  field: 'official_email_id',
                  type: 'text',
                  size: 6,
                  required: true,
                },
                // {
                //   label: 'Mobile number',
                //   field: 'mobile_number',
                //   type: 'text',
                //   size: 'medium',
                //   // required: true,
                // },
              ],
            },
            {
              label: 'Position & reporting',
              fields: [
                {
                  size: 4,
                  field: 'is_wilp',
                  type: 'checkbox',
                  label: 'WILP',
                },
                {
                  size: 4,
                  field: 'is_int',
                  type: 'checkbox',
                  label: 'Intern',
                },
                {
                  size: 4,
                  field: 'is_lateral',
                  type: 'checkbox',
                  label: 'Lateral',
                },
                {
                  type: 'autoComplete',
                  field: 'designation',
                  label: 'Designation',
                  api: '/designations',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  type: 'autoComplete',
                  field: 'level',
                  label: 'Level',
                  api: '/levels',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  field: 'manualReportingTo',
                  type: 'checkbox',
                  label: 'Manual reporting',
                },
                {
                  type: 'multiAutoComplete',
                  field: 'reporting_to',
                  label: 'Reporting to',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'skill_leader',
                  label: 'Skill manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  // required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'functional_manager',
                  label: 'Functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  required: true,

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'new_functional_manager',
                  label: 'New functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  // required: true,

                  size: 6,
                },
              ],
            },
            {
              label: 'Skill',
              fields: [
                {
                  label: 'Primary skills',
                  field: 'resource_language',
                  header: 'Skills',
                  type: 'autoComplete',
                  api: '/skills',
                  suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                  valueField: 'name',
                  // required: true,
                  size: 6,
                },
                {
                  // size: 6,
                  groups: [
                    {
                      defaultHidden: true,
                      label: 'Secondary skill',
                      fields: [
                        {
                          label: 'Secondary skills',
                          field: 'seconday_resource_language',
                          header: 'Skills',
                          type: 'autoComplete',
                          api: '/skills',
                          suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                          valueField: 'name',
                          required: true,
                          size: 3,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: 'Contractual details',
              fields: [
                {
                  label: 'Notice period days',
                  type: 'number',
                  // required: true,
                  field: 'notice_period_days',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Increment cycle',
                  field: 'increment_type',
                  type: 'autoComplete',
                  options: ['Annual', 'Biannual'],
                  required: true,
                  size: 4,
                },
                {
                  label: 'Salary payment mode',
                  field: 'salary_payment_mode',
                  type: 'autoComplete',
                  required: true,
                  options: ['By Cheque', 'Account Transfer'],
                  size: 4,
                },
              ],
            },

            {
              label: 'Organization details',
              fields: [
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  placeholder: 'Product',
                  label: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                  required: true,
                },
                {
                  placeholder: 'Department',
                  label: 'Department',
                  field: 'department',
                  type: 'autoComplete',
                  api: `/departments`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Work location',
                  field: 'sitting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Accounting location',
                  field: 'accounting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'under_bond',
                  type: 'checkbox',
                  label: 'Bond',
                },
                {
                  label: 'Bond duration(Years)',
                  type: 'text',
                  field: 'bond_duration',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                  required: ({values}) => values && values.under_bond,
                },
                {
                  label: 'Bond amount(INR)',
                  type: 'text',
                  field: 'bond_amount',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                  required: ({values}) => values && values.under_bond,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'is_background_verification_required',
                  type: 'checkbox',
                  label: 'Background verification required',
                },
                {
                  label: 'Background verification due',
                  type: 'date',
                  field: 'background_verification_due_date',
                  size: 6,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                  required: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification outcome',
                  type: 'text',
                  field: 'background_verification_outcome',
                  size: 6,
                  readOnly: true,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification report',
                  placeholder: 'Background verification report',
                  type: 'file',
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                  field: 'background_verification_report',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',
                  required: true,
                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',
                  required: true,
                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                {
                  label: 'Spouse name',
                  field: 'spouse_name',
                  type: 'textArea',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  required: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },
                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mobile_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        email_id: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip_current: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',
                  required: true,

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                  required: true,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'State',
                  field: 'state',
                  required: true,

                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                  required: true,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        zip: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        father_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                  required: true,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  type: 'text',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        mother_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',
                  required: true,

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  field: 'emergency_contact_persion_number',
                  required: true,

                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        emergency_contact_persion_number: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          validate: async fieldValue => {
                            const {data} = await post({
                              uri: `/employeeParichayValidations`,
                              method: 'post',
                              props: {
                                name: fieldValue,
                              },
                            });
                            if (data) {
                              return data;
                            }
                          },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Attendance',
          fields: [
            {
              label: 'Working days',
              field: 'working_days',
              required: true,

              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 4,
              options: [
                {value: 5, label: '5'},
                {value: 5.5, label: '5.5'},
                {value: 6, label: '6'},
              ],
              //   size: 'medium',
            },
            {
              label: 'Biometric code',
              type: 'text',
              field: 'biometric_code',
              size: 4,
            },
            {
              label: 'Exceptional Shift',
              type: 'checkbox',
              field: 'is_exceptional_shift',
              size: 4,
            },
            {
              label: 'Shift', //
              fields: [
                {
                  field: 'shift_information',
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Shift',
                          header: 'Shift',
                          field: 'shift_id',
                          type: 'autoComplete',
                          api: '/shifts',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'shift_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Schdule',
              fields: [
                {
                  field: 'schedule_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Schdule',
                          header: 'Schdule',
                          field: 'schedule_id',
                          type: 'autoComplete',
                          api: '/schedules',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Schdule',
                          field: 'schedule_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Leave Policy',
              fields: [
                {
                  field: 'leave_policy_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id',
                          type: 'autoComplete',
                          api: '/leavepolicies',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Holiday Calendar',
              fields: [
                {
                  field: 'holiday_calendar_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id',
                          type: 'autoComplete',
                          api: '/holidaycalendars',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id.name',
                          size: 6,
                          required: true,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_qualification_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  size: 3,
                  required: true,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  type: 'text',
                  required: true,
                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  type: 'text',
                  required: true,
                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  required: true,

                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  type: 'text',
                  size: 3,
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  type: 'text',
                  required: true,

                  size: 3,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        sr_secondary_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  type: 'text',
                  required: true,

                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',
                  required: true,

                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',
                  required: true,

                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',
                  required: true,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',
                  required: true,

                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',
                  required: true,

                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_institute_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_university_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations/educations`,
                      method: 'post',
                      props: {
                        post_graduation_year_of_passing: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'Has past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      // validate: async fieldValue => {
                      //   const accnoReg = /^[0-9]{9,18}$/;
                      //   if (fieldValue && !accnoReg.test(fieldValue)) {
                      //     return 'Account Number Not Valid';
                      //   }
                      // },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      // validate: async fieldValue => {
                      //   if (fieldValue && fieldValue.length !== 11) {
                      //     return 'ONLY 11 CHARACTERS ALLOWED';
                      //   }
                      // },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                      required: true,
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',
                  required: true,
                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',
              required: true,
              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  size: 6,
                  required: true,
                  validate: async fieldValue => {
                    const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                    if (fieldValue && !regexp.test(fieldValue)) {
                      return 'INVALID ADHAR CARD NUMBER';
                    }
                  },
                },
                {
                  placeholder: 'Adhaar card copy',
                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                  required: true,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  field: 'applied_for_pan_card',
                  type: 'checkbox',
                  label: 'Applied for pan card',
                },
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  required: ({values}) =>
                    values && !values.applied_for_pan_card,
                  validate: async fieldValue => {
                    let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                    if (fieldValue && !regpan.test(fieldValue)) {
                      return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                    }
                  },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  required: ({values}) =>
                    values && !values.applied_for_pan_card,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',
                  required: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  type: 'file',
                  required: true,
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
                {
                  label: 'LWF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'LWF number',
                      type: 'text',
                      field: 'lwf_number',
                    },
                  ],
                },
                {
                  label: 'Pran number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'Pran number',
                      type: 'text',
                      field: 'pran_id',
                    },
                  ],
                },
                {
                  label: 'UAN number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'UAN number',
                      type: 'text',
                      field: 'uan_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddEmployeeFormEditableNoRequired = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const invoke = useInvoke({
    close: false,
  });
  const {
    route: {params},
    navigation,
    mode,
  } = props;
  const {product, row} = params;
  const {employee} = row || {};
  const {post, fetch} = useAppStateContext();
  return (
    <MultiStepForm
      api={`/employee-details/${employee?._id ? employee?._id : row?._id}`}
      onSubmit={onSubmit}
      header={'Add Employee'}
      submitAction={'Save'}
      computations={{...employeeComputations(fetch)}}
      defaultValues={{
        employee_status: 'Pending For Approval',
      }}
      saveOnNext={false}
      steps={[
        {
          title: 'Employee',
          fields: [
            // {
            //   label: 'Employee Code',
            //   field: 'employee_code',
            //   type: 'text',
            //   size: 4,
            // },
            {
              label: 'Employee Name',
              field: 'name',
              type: 'text',
              size: 4,
            },
            {
              label: 'Joining Date',
              field: 'joining_date',
              type: 'date',
              size: 4,

              //
            },
            {
              label: 'Employee Status', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'employee_type',
              type: 'autoComplete',
              api: '/employeetypes',
              suggestionField: 'name',
              valueField: 'name',
              //
              size: 4,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value?.name == 'Permanent') {
                  setFieldValue('notice_period_days', 90);
                }
              },
            },

            //"5422b496c9b2fac76efa5f5c"
            {
              label: 'Probation end date',
              field: 'probation_end_date',
              type: 'date',
              size: 4,
              visible: ({values}) => {
                if (values?.employee_type?._id === '5422b496c9b2fac76efa5f5c') {
                  return true;
                }
                return false;
              },

              //
            },
            {
              label: 'Deployable Status',
              field: 'deployable_trainable_status',
              type: 'autoComplete',
              options: ['Deployable', 'Trainable'],
              size: 4,
              //
            },

            {
              label: 'Package structure', //whats the difference between this and employee type colum, also provide form fields on its visibility
              field: 'package_structure',
              type: 'autoComplete',
              api: '/packagestructures',
              suggestionField: 'name',
              valueField: 'name',
              //
              size: 4,
            },
            {
              label: 'Confirmation Date',
              field: 'confirmationDate',
              type: 'date',
              readOnly: true,
              //
              size: 4,
              //
            },
            {
              size: 3,
              field: 'is_btb',
              type: 'checkbox',
              label: 'BTB',
            },
            {
              size: 3,
              field: 'is_apprenticeship',
              type: 'checkbox',
              label: 'Apprentice',
            },
            {
              size: 3,
              field: 'is_task',
              type: 'checkbox',
              label: 'Is Task',
            },
            {
              size: 3,
              field: 'address_review_pending',
              type: 'checkbox',
              label: 'Address review',
            },
            {
              size: 4,
              field: 'asset_review_pending',
              type: 'checkbox',
              label: 'Asset Review Required',
            },
            {
              size: 4,
              field: 'detail_review_pending',
              type: 'checkbox',
              label: 'Detail Review Required',
            },
            {
              size: 4,
              field: 'is_agreement_letter_added',
              type: 'checkbox',
              label: 'Agreement',
            },
            {
              label: 'Agreement Copy',
              placeholder: 'Agreement Copy',
              type: 'file',
              visible: ({values}) => values && values.is_agreement_letter_added,
              field: 'agreement_letter',
              options: {bucketName: 'manaze'},
            },
            {
              label: 'Contact',
              fields: [
                {
                  label: 'Work email',
                  field: 'official_email_id',
                  type: 'text',
                  size: 6,
                },
                // {
                //   label: 'Mobile number',
                //   field: 'mobile_number',
                //   type: 'text',
                //   size: 'medium',
                //   //
                // },
              ],
            },
            {
              label: 'Position & reporting',
              fields: [
                {
                  size: 4,
                  field: 'is_wilp',
                  type: 'checkbox',
                  label: 'WILP',
                },
                {
                  size: 4,
                  field: 'is_int',
                  type: 'checkbox',
                  label: 'Intern',
                },
                {
                  size: 4,
                  field: 'is_lateral',
                  type: 'checkbox',
                  label: 'Lateral',
                },
                {
                  type: 'autoComplete',
                  field: 'designation',
                  label: 'Designation',
                  api: '/designations',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'employee_level',
                  label: 'Level',
                  api: '/levels',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  field: 'manualReportingTo',
                  type: 'checkbox',
                  label: 'Manual reporting',
                },
                {
                  type: 'multiAutoComplete',
                  field: 'reporting_to',
                  label: 'Reporting to',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'skill_leader',
                  label: 'Skill manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  //

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'functional_manager',
                  label: 'Functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',

                  size: 6,
                },
                {
                  type: 'autoComplete',
                  field: 'new_functional_manager',
                  label: 'New functional manager',
                  api: '/employeeSuggestions',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  //

                  size: 6,
                },
              ],
            },
            {
              label: 'Skill',
              fields: [
                {
                  label: 'Primary skills',
                  field: 'resource_language',
                  header: 'Skills',
                  type: 'autoComplete',
                  api: '/skills',
                  suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                  valueField: 'name',
                  //
                  size: 6,
                },
                {
                  // size: 6,
                  groups: [
                    {
                      defaultHidden: true,
                      label: 'Secondary skill',
                      fields: [
                        {
                          label: 'Secondary skills',
                          field: 'seconday_resource_language',
                          header: 'Skills',
                          type: 'autoComplete',
                          api: '/skills',
                          suggestionField: 'name', // how to put a filter here so that only skills of the selected practice gets here
                          valueField: 'name',

                          size: 3,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: 'Contractual details',
              fields: [
                {
                  label: 'Notice period days',
                  type: 'number',
                  //
                  field: 'notice_period_days',

                  size: 4,
                },
                {
                  label: 'Increment cycle',
                  field: 'increment_type',
                  type: 'autoComplete',
                  options: ['Annual', 'Biannual'],

                  size: 4,
                },
                {
                  label: 'Salary payment mode',
                  field: 'salary_payment_mode',
                  type: 'autoComplete',

                  options: ['By Cheque', 'Account Transfer'],
                  size: 4,
                },
              ],
            },

            {
              label: 'Organization details',
              fields: [
                {
                  label: 'Organization',
                  field: 'organization',
                  type: 'autoComplete',
                  api: `/organizations`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                },
                {
                  placeholder: 'Product',
                  label: 'Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',

                  size: 4,
                },
                {
                  label: 'Team',
                  field: 'employee_team',
                  type: 'autoComplete',
                  api: `/employeeteams`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 4,
                },
                {
                  placeholder: 'Department',
                  label: 'Department',
                  field: 'department',
                  type: 'autoComplete',
                  api: `/departments`,
                  suggestionField: 'name',
                  valueField: 'name',

                  size: 4,
                },
                {
                  label: 'Work location',
                  field: 'sitting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
                {
                  label: 'Accounting location',
                  field: 'accounting_location',
                  type: 'autoComplete',
                  api: `/offices`,
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 6,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'under_bond',
                  type: 'checkbox',
                  label: 'Bond',
                },
                {
                  label: 'Bond duration(Years)',
                  type: 'text',
                  field: 'bond_duration',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                },
                {
                  label: 'Bond amount(INR)',
                  type: 'text',
                  field: 'bond_amount',
                  size: 6,
                  visible: ({values}) => values && values.under_bond,
                },
              ],
            },
            {
              fields: [
                {
                  field: 'is_background_verification_required',
                  type: 'checkbox',
                  label: 'Background verification required',
                },
                {
                  label: 'Background verification due',
                  type: 'date',
                  field: 'background_verification_due_date',
                  size: 6,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification outcome',
                  type: 'text',
                  field: 'background_verification_outcome',
                  size: 6,
                  readOnly: true,
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                },
                {
                  label: 'Background verification report',
                  placeholder: 'Background verification report',
                  type: 'file',
                  visible: ({values}) =>
                    values && values.is_background_verification_required,
                  field: 'background_verification_report',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Personal',
          fields: [
            {
              label: 'Basic Details',
              fields: [
                {
                  label: 'Full Name',
                  defaultValue: '-',
                  type: 'text',
                  field: 'full_name',
                  size: 3,

                  validate: async fieldValue => {
                    const {data} = await post({
                      uri: `/employeeParichayValidations`,
                      method: 'post',
                      props: {
                        full_name: fieldValue,
                      },
                    });
                    if (data) {
                      return data;
                    }
                  },
                },
                {
                  label: 'Date Of Birth',
                  field: 'dob',
                  type: 'date',

                  size: 3,
                },
                {
                  label: 'Gender',
                  field: 'gender',
                  type: 'autoComplete',

                  options: [
                    'Male',
                    'Female',
                    'Prefer Not to respond',
                    'Others',
                  ],
                  size: 3,
                },

                {
                  label: 'Nationality',
                  field: 'nationality',
                  type: 'autoComplete',
                  api: '/nationalities',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Marital Status',
                  field: 'marital_status',
                  type: 'autoComplete',

                  options: [
                    'Married',
                    'Single',
                    'Separated',
                    'Widowed',
                    'Prefer Not to respond',
                  ],
                  size: 3,
                },
                {
                  label: 'Spouse name',
                  field: 'spouse_name',
                  type: 'textArea',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },

                  size: 3,
                },
                {
                  label: 'Anniversary date',
                  field: 'anniversary_date',
                  type: 'date',
                  visible: ({values}) => {
                    if (values?.marital_status == 'Married') {
                      return true;
                    }
                  },

                  size: 3,
                },
                {
                  label: 'Blood Group',
                  field: 'blood_group',
                  type: 'autoComplete',
                  api: '/bloodgroups',
                  suggestionField: 'name',
                  valueField: 'name',
                  size: 3,
                },
                {
                  label: 'Mobile number ',
                  type: 'text',
                  field: 'mobile_number',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       mobile_number: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Personal Email',
                  field: 'email_id',
                  type: 'text',
                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       email_id: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
              ],
            },
            {
              label: 'Current Address',
              fields: [
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1_current',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2_current',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city_current',
                  type: 'text',

                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state_current',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country_current',
                  type: 'text',

                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip_current',
                  type: 'text',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       zip_current: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
              ],
            },
            {
              label: 'Permanent Address',
              fields: [
                {
                  type: 'checkbox',
                  label: 'Same as current address',
                  field: 'sameAsCurrent',
                },
                {
                  label: 'Address(House no., Locality, Street)',
                  field: 'address_line_1',
                  type: 'textArea',

                  size: 6,
                },
                {
                  label: 'Address(Area/Town- if any)',
                  field: 'address_line_2',
                  type: 'textArea',
                  size: 6,
                },
                {
                  label: 'City',
                  field: 'city',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'State',
                  field: 'state',

                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Country',
                  field: 'country',
                  type: 'text',
                  size: 3,
                },
                {
                  label: 'Zip Code',
                  field: 'zip',
                  type: 'text',
                  size: 3,

                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       zip: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
              ],
            },
            {
              label: `Parents' Details`,
              fields: [
                {
                  label: "Father's name",
                  field: 'father_name',
                  type: 'text',
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       father_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                  size: 4,
                },
                {
                  label: "Father's occupation",
                  type: 'text',
                  field: 'father_occupation',
                  size: 4,
                },
                {
                  label: "Father's date of birth",
                  type: 'date',
                  field: 'father_date_of_birth',
                  size: 4,
                },
                {
                  label: "Mother's name",
                  field: 'mother_name',
                  type: 'text',

                  size: 4,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       mother_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: "Mother's occupation",
                  type: 'text',
                  field: 'mother_occupation',
                  size: 4,
                },
                {
                  label: "Mother's date of birth",
                  type: 'date',
                  field: 'mother_date_of_birth',
                  size: 4,
                },
                {
                  label: 'Contact address of parents',
                  field: 'contact_address_of_parents',
                  type: 'text',
                },
              ],
            },
            {
              label: 'Emergency contact',
              fields: [
                {
                  label: 'Name',
                  type: 'text',
                  field: 'emergency_contact_persion',

                  size: 4,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       emergency_contact_persion: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Relation with emergency person',
                  type: 'text',

                  field: 'emergency_contact_person_relation',
                  size: 4,
                },
                {
                  label: 'Contact number',
                  type: 'text',
                  field: 'emergency_contact_persion_number',

                  size: 4,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations`,
                  //     method: 'post',
                  //     props: {
                  //       emergency_contact_persion_number: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
              ],
            },
            {
              label: 'Hobbies',
              fields: [
                {
                  field: 'hobbies',
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      columns={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          header: 'Name',
                        },
                      ]}
                      fields={[
                        {
                          field: 'name',
                          placeholder: 'Name',
                          type: 'text',
                          label: 'Name',
                          // validate: async fieldValue => {
                          //   const {data} = await post({
                          //     uri: `/employeeParichayValidations`,
                          //     method: 'post',
                          //     props: {
                          //       name: fieldValue,
                          //     },
                          //   });
                          //   if (data) {
                          //     return data;
                          //   }
                          // },
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Attendance',
          fields: [
            {
              label: 'Working days',
              field: 'working_days',

              type: 'autoComplete',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 4,
              options: [
                {value: 5, label: '5'},
                {value: 5.5, label: '5.5'},
                {value: 6, label: '6'},
              ],
              //   size: 'medium',
            },
            {
              label: 'Biometric code',
              type: 'text',
              field: 'biometric_code',
              size: 4,
            },
            {
              label: 'Exceptional Shift',
              type: 'checkbox',
              field: 'is_exceptional_shift',
              size: 4,
            },
            {
              label: 'Shift', //
              fields: [
                {
                  field: 'shift_information',
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Shift',
                          header: 'Shift',
                          field: 'shift_id',
                          type: 'autoComplete',
                          api: '/shifts',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'shift_id.name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Schdule',
              fields: [
                {
                  field: 'schedule_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      inlineAddForm={true}
                      {...props}
                      fields={[
                        {
                          placeholder: 'Schdule',
                          header: 'Schdule',
                          field: 'schedule_id',
                          type: 'autoComplete',
                          api: '/schedules',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          header: 'Schdule',
                          field: 'schedule_id.name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Leave Policy',
              fields: [
                {
                  field: 'leave_policy_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id',
                          type: 'autoComplete',
                          api: '/leavepolicies',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Leave Policy',
                          header: 'Leave Policy',
                          field: 'leave_policy_id.name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              label: 'Holiday Calendar',
              fields: [
                {
                  field: 'holiday_calendar_information', //
                  nested: true,
                  render: props => (
                    <NestedTable
                      {...props}
                      inlineAddForm={true}
                      fields={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id',
                          type: 'autoComplete',
                          api: '/holidaycalendars',
                          suggestionField: 'name',
                          valueField: 'name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                      columns={[
                        {
                          placeholder: 'Holiday Calendar',
                          header: 'Holiday Calendar',
                          field: 'holiday_calendar_id.name',
                          size: 6,
                        },
                        {
                          type: 'date',
                          placeholder: 'From',
                          field: 'from_date',
                          header: 'From',
                        },
                        {
                          type: 'date',
                          placeholder: 'To',
                          field: 'to_date',
                          header: 'To',
                        },
                        {
                          type: 'text',
                          placeholder: 'Remarks',
                          field: 'remarks',
                          header: 'Remarks',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Education',
          fields: [
            {
              label: 'Secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'secondary_qualification_institute_name',
                  type: 'text',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       secondary_qualification_institute_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'University/board name',
                  field: 'secondary_university_name',
                  type: 'text',
                  size: 3,

                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       secondary_university_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },

                {
                  label: 'Year of passing',
                  field: 'secondary_year_of_passing',
                  type: 'text',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       secondary_year_of_passing: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'secondary_grade',
                  type: 'text',

                  size: 3,
                },
                {
                  placeholder: 'Secondary education proof',
                  label: 'Secondary education proof',
                  field: 'secondary_education_proofs',
                  // multiple: true,
                  type: 'file',

                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Senior secondary education',
              fields: [
                {
                  label: 'Institution name',
                  field: 'sr_secondary_institute_name',
                  type: 'text',
                  size: 3,

                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       sr_secondary_institute_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'University/board name',
                  field: 'sr_secondary_university_name',
                  type: 'text',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       sr_secondary_university_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Year of passing',
                  field: 'sr_secondary_year_of_passing',
                  type: 'text',

                  size: 3,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       sr_secondary_year_of_passing: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Grade / Percentage',
                  field: 'sr_secondary_grade',
                  type: 'text',

                  size: 3,
                },
                {
                  placeholder: 'Sr. secondary education proof',
                  label: 'Sr. secondary education proof',
                  field: 'sr_secondary_education_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Graduation',
              fields: [
                {
                  label: 'Graduation qualification',
                  field: 'graduation_qualification_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'graduation_course_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'graduation_institute_name',

                  type: 'text',
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       graduation_institute_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                  size: 4,
                },
                {
                  label: 'Graduation university name',
                  field: 'graduation_university_name',

                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       graduation_university_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       graduation_year_of_passing: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'graduation_grade',

                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Graduation proofs',
                  label: 'Graduation proofs',
                  field: 'graduation_proofs',

                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Post graduation',
              fields: [
                {
                  label: 'Post graduation qualification',
                  field: 'post_graduation_qualification_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Specilisation',
                  field: 'post_graduation_course_name',
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Institution name',
                  field: 'post_graduation_institute_name',
                  type: 'text',
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       post_graduation_institute_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                  size: 4,
                },
                {
                  label: 'Post graduation university name',
                  field: 'post_graduation_university_name',
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       post_graduation_university_name: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                  type: 'text',
                  size: 4,
                },
                {
                  label: 'Year of passing',
                  field: 'post_graduation_year_of_passing',
                  type: 'text',
                  size: 4,
                  // validate: async fieldValue => {
                  //   const {data} = await post({
                  //     uri: `/employeeParichayValidations/educations`,
                  //     method: 'post',
                  //     props: {
                  //       post_graduation_year_of_passing: fieldValue,
                  //     },
                  //   });
                  //   if (data) {
                  //     return data;
                  //   }
                  // },
                },
                {
                  label: 'Grade / Percentage%',
                  field: 'post_graduation_grade',
                  type: 'text',
                  size: 4,
                },
                {
                  placeholder: 'Post graduation proofs',
                  label: 'Post graduation proofs',
                  field: 'post_graduation_proofs',
                  // multiple: true,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
        {
          title: 'Employement History',
          fields: [
            {
              label: 'Has past experience',
              type: 'checkbox',
              field: 'have_past_experience',
            },
            {
              field: 'experience_details',
              visible: ({values}) => values && values.have_past_experience,
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  columns={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'Designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                      formatOptions: {
                        format: 'DD MMM YY',
                      },
                    },
                    {
                      placeholder: 'Employement proof',
                      label: 'Employement proof',
                      field: 'employement_proofs',
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                  fields={[
                    {
                      type: 'text',
                      placeholder: 'Organization',
                      field: 'organization_name',
                      header: 'Organization',
                    },
                    {
                      type: 'text',
                      placeholder: 'Designation',
                      field: 'designation',
                      header: 'designation',
                    },
                    {
                      type: 'date',
                      placeholder: 'Joining Date',
                      field: 'from_date',
                      header: 'Joining Date',
                    },
                    {
                      type: 'date',
                      placeholder: 'Relieving Date',
                      field: 'to_date',
                      header: 'Relieving Date',
                    },
                    {
                      placeholder: 'Employement proof',
                      // label: 'Employement proof',
                      field: 'employement_proofs',
                      header: 'Employement proof', ////resume icon
                      // multiple: true,
                      type: 'file',
                      options: {bucketName: 'manaze'},
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Bank details',
          fields: [
            {
              field: 'bank_accounts',
              nested: true,
              render: props => (
                <NestedTable
                  {...props}
                  inlineAddForm={true}
                  fields={[
                    {
                      label: 'Bank name',
                      field: 'name_id',
                      type: 'autoComplete',
                      api: `/banktypes`,
                      suggestionField: 'name',
                      valueField: 'name',
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                      // validate: async fieldValue => {
                      //   const accnoReg = /^[0-9]{9,18}$/;
                      //   if (fieldValue && !accnoReg.test(fieldValue)) {
                      //     return 'Account Number Not Valid';
                      //   }
                      // },
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                      // validate: async fieldValue => {
                      //   if (fieldValue && fieldValue.length !== 11) {
                      //     return 'ONLY 11 CHARACTERS ALLOWED';
                      //   }
                      // },
                    },
                  ]}
                  columns={[
                    {
                      label: 'Bank name',
                      field: 'name_id.name',
                      header: 'Bank name',
                    },
                    {
                      type: 'text',
                      label: 'Account no.',
                      field: 'account_number',
                      header: 'Account no.',
                    },
                    {
                      type: 'text',
                      placeholder: "Account holder's name",
                      field: 'name_in_bank',
                      header: "Account holder's name",
                    },
                    {
                      type: 'text',
                      placeholder: 'Branch name',
                      field: 'branch',
                      header: 'Branch name',
                    },
                    {
                      type: 'text',
                      placeholder: 'Ifsc code',
                      field: 'ifsc_code',
                      header: 'Ifsc code',
                    },
                  ]}
                />
              ),
            },
          ],
        },
        {
          title: 'Nominee',
          fields: [
            {
              label: 'Primary Nominee',
              fields: [
                {
                  label: 'Primary Nominee',
                  field: 'nominee_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',

                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              label: 'Secondary Nominee',
              fields: [
                {
                  label: 'Secondary Nominee',
                  field: 'secondary_nominee_name',
                  type: 'text',

                  size: 4,
                },
                {
                  label: 'Relation',
                  field: 'secondary_nominee_relation',
                  type: 'autoComplete',
                  api: `/nomineerelations`,
                  suggestionField: 'relation',
                  valueField: 'relation',

                  size: 4,
                },
                {
                  label: 'Address',
                  field: 'secondary_nominee_address',
                  type: 'textArea',
                  size: 4,
                },
              ],
            },
            {
              placeholder: 'Nominee declaration form',
              field: 'nominee_declaration_form',
              type: 'file',

              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          title: 'Statutory & supporting docs',
          fields: [
            {
              label: 'Adhaar details',
              size: 6,
              fields: [
                {
                  label: 'Adhaar number',
                  type: 'text',
                  field: 'adhar_number',
                  size: 6,

                  // validate: async fieldValue => {
                  //   const regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                  //   if (fieldValue && !regexp.test(fieldValue)) {
                  //     return 'INVALID ADHAR CARD NUMBER';
                  //   }
                  // },
                },
                {
                  placeholder: 'Adhaar card copy',
                  field: 'adhar_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'PAN details',
              size: 6,
              fields: [
                {
                  field: 'applied_for_pan_card',
                  type: 'checkbox',
                  label: 'Applied for pan card',
                },
                {
                  label: 'PAN number',
                  type: 'text',
                  field: 'pan_number',
                  size: 6,
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  // validate: async fieldValue => {
                  //   let regpan = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                  //   if (fieldValue && !regpan.test(fieldValue)) {
                  //     return 'First 5 Character "Alphabet(Upper Case)" next 4 Character "Numeric" last Character "Alphabet(Upper Case)"';
                  //   }
                  // },
                },
                {
                  placeholder: 'PAN card copy',
                  field: 'pan_copy',
                  visible: ({values}) => values && !values.applied_for_pan_card,
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              label: 'Passport details',
              fields: [
                {
                  label: 'Passport No.',
                  type: 'text',
                  field: 'passport_number',
                  size: 4,
                },
                {
                  label: 'Passport Valid Upto',
                  type: 'date',
                  field: 'passport_valid_up_to',
                  size: 4,
                },
                {
                  placeholder: 'Passport copy',
                  field: 'passport_copy',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Declaration form',
              fields: [
                {
                  placeholder: 'Gratuity',
                  field: 'gratuity_form',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Form - 11 PF declaration document',
                  field: 'pf_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
                {
                  placeholder: 'Employee Consent',
                  field: 'consent_document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 4,
                },
              ],
            },
            {
              label: 'Supporting documents',
              fields: [
                {
                  placeholder: 'Offer letter copy',
                  field: 'offer_letter_copy',

                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  placeholder: 'Passport size photo',
                  field: 'photo',
                  type: 'file',

                  options: {bucketName: 'manaze'},
                  size: 6,
                },
              ],
            },
            {
              groups: [
                {
                  label: 'ESI number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'ESI number',
                      type: 'text',
                      field: 'esi_number',
                    },
                  ],
                },
                {
                  label: 'PF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'PF number',
                      type: 'text',
                      field: 'pf_number',
                    },
                  ],
                },
                {
                  label: 'LWF number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'LWF number',
                      type: 'text',
                      field: 'lwf_number',
                    },
                  ],
                },
                {
                  label: 'Pran number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'Pran number',
                      type: 'text',
                      field: 'pran_id',
                    },
                  ],
                },
                {
                  label: 'UAN number',
                  defaultHidden: true,
                  fields: [
                    {
                      label: 'UAN number',
                      type: 'text',
                      field: 'uan_number',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
export const EditEmployeeForm = props => {
  return <AddEmployeeForm mode="edit" header={'Employee Detail'} {...props} />;
};

export const EmployeeDetailForm = props => {
  return (
    <AddEmployeeForm
      mode="edit"
      readOnly
      header={'Employee Detail'}
      {...props}
    />
  );
};

export const EmployeeDetailFormForHr = props => {
  return (
    <AddEmployeeFormEditableNoRequired
      mode="edit"
      isDualMode
      // readOnly
      header={'Employee Detail'}
      {...props}
    />
  );
};

export const AddEmployeeFormHOC = props => {
  return <AddEmployeeFormFromParichay header={'New Employee'} {...props} />;
};

export const AddEmployeeWithPackageFormHOC = props => {
  return <AddEmployeeForm header={'New Employee'} {...props} />;
};

export const HoldUnholdSalaryForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'EmployeeAssignment',
    throwError: true,
  });

  return (
    <Form
      {...props}
      defaultValues={{
        hold_salary: row?.hold_salary,
      }}
      onSubmit={data => {
        invoke({
          uri: '/onsalaryholdunhold',
          props: {data: {employeeId, ...data}},
          throwError: true,
        });
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'hold_salary',
          required: true,
          options: [
            {value: true, label: 'Hold'},
            {value: false, label: 'Un-Hold'},
          ],
        },
      ]}
    />
  );
};

export const HoldUnholdEmployeeSalary = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <HoldUnholdSalaryForm
      header={{
        title: 'Hold/Unhold Salary',
      }}
      {...props}
    />
  );
};

export const MarkEmployeeLegal = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'EmployeeAssignment',
    throwError: true,
  });

  return (
    <Form
      {...props}
      onSubmit={data => {
        invoke({
          uri: '/onemployeemarklegal',
          props: {data: {employeeId, ...data}},
        });
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'date',
          field: 'legal_marked_on',
          header: 'Legal Date',
          label: 'Legal Date',
          required: true,
        },
        {
          label: 'Legal Status',
          placeholder: 'Legal Status',
          field: 'legal_status',
          type: 'autoComplete',
          options: [
            'Unautorised absence',
            ' SA Breach',
            'BGV Discrepancy',
            'Discplinary issues',
            'Other',
          ],
        },
      ]}
    />
  );
};

export const markEmployeeLegalForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkEmployeeLegal
      header={{
        title: 'Mark Legal',
      }}
      {...props}
    />
  );
};

export const MarkEmployeeRecoveryPending = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  const defaultValues = {
    _id: row?._id,
    training_cost_recovery_pending: true,
  };
  return (
    <Form
      {...props}
      onSubmit={onSubmit}
      defaultValues={{...defaultValues}}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'date',
          field: 'training_cost_recovery_pending_date',
          header: 'Recovery Cost Date',
          label: 'Recovery Cost Date',
          required: true,
        },
      ]}
    />
  );
};

export const markEmployeeRecoveryPendingForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkEmployeeRecoveryPending
      header={{
        title: 'Mark Recovery Pending',
      }}
      {...props}
    />
  );
};

export const SettleFnfForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      {...props}
      defaultValues={{
        _id: employeeId,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'fnf_status',
          required: true,
          options: [
            {value: true, label: 'Settled'},
            {value: false, label: 'UnSettled'},
          ],
        },
      ]}
    />
  );
};

export const SettleFnfEmployeeSalary = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <SettleFnfForm
      header={{
        title: 'Settle',
      }}
      {...props}
    />
  );
};

export const DownloadPromotionLetter = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  const {user, download} = useAppStateContext();

  return (
    <Form
      {...props}
      onSubmit={data => {
        download({
          uri: `/promotionletterDownload`,
          props: {
            employeeId,
            ...data,
          },
        });
      }}
      submitAction={'Download'}
      layoutFields={[
        {
          label: 'On Letter Head',
          placeholder: 'On Letter Head',
          field: 'on_letter_head',
          type: 'checkbox',
        },
      ]}
    />
  );
};

export const DownloadPromotionLetterForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <DownloadPromotionLetter
      header={{
        title: 'Download Letter',
      }}
      {...props}
    />
  );
};

export const DownloadRelievingLetter = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  const {user, download} = useAppStateContext();

  return (
    <Form
      {...props}
      onSubmit={data => {
        download({
          uri: `/downloadrelievingletter`,
          props: {
            employeeId,
            ...data,
          },
        });
      }}
      submitAction={'Download'}
      layoutFields={[
        {
          label: 'On Letter Head',
          placeholder: 'On Letter Head',
          field: 'on_letter_head',
          type: 'checkbox',
        },
      ]}
    />
  );
};

export const DownloadRelievingLetterForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <DownloadRelievingLetter
      header={{
        title: 'Download Letter',
      }}
      {...props}
    />
  );
};

export const UploadLetters = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });
  return (
    <Form
      api={`/employees/${row?._id}`}
      onSubmit={onSubmit}
      mode="edit"
      submitAction="Save"
      fields={{
        is_appointment_letter_added: 1,
        appointment_letter: 1,
        is_promotion_letter_added: 1,
        promotion_letter: 1,
        is_relieving_letter_added: 1,
        relieving_letter: 1,
        is_agreement_letter_added: 1,
        agreement_letter: 1,
        is_increment_letter_added: 1,
        increment_letter_latest_from_date: 1,
        increment_letter_latest_to_date: 1,
        increment_letter_latest: 1,
      }}
      layoutFields={[
        {
          groups: [
            {
              label: 'Appointment Letter',
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_appointment_letter_added',
                  label: 'Add Appointment Letter',
                },
                {
                  type: 'file',
                  field: 'appointment_letter',
                  label: 'Upload Appointment Letter',
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
            {
              label: 'Promotion Letter',
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_promotion_letter_added',
                  label: 'Add Promotion Letter',
                },
                {
                  type: 'file',
                  field: 'promotion_letter',
                  label: 'Upload Promotion Letter',
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
            {
              label: 'Relieving Letter',
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_relieving_letter_added',
                  label: 'Add Relieving Letter',
                },
                {
                  type: 'file',
                  field: 'relieving_letter',
                  label: 'Upload Relieving Letter',
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
            {
              label: 'Agreement Letter',
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_agreement_letter_added',
                  label: 'Add Agreement Letter',
                },
                {
                  type: 'file',
                  field: 'agreement_letter',
                  label: 'Upload Agreement Letter',
                  options: {bucketName: 'manaze'},
                  required: true,
                },
              ],
            },
            {
              label: 'Increment Letter',
              fields: [
                {
                  type: 'checkbox',
                  field: 'is_increment_letter_added',
                  label: 'Add Increment Letter',
                },
                {
                  type: 'date',
                  field: 'increment_letter_latest_from_date',
                  placeholder: 'From Date',
                  label: 'From Date',
                  required: true,
                },
                {
                  type: 'date',
                  field: 'increment_letter_latest_to_date',
                  placeholder: 'To Date',
                  label: 'To Date',
                  required: true,
                },
                {
                  type: 'file',
                  label: 'Increment Letter',
                  placeholder: 'Drag and drop Increment Letter here',
                  field: 'increment_letter_latest',
                  required: true,
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UploadLettersForm = props => {
  return (
    <UploadLetters
      header={{
        title: 'Upload Letter',
      }}
      {...props}
    />
  );
};

export const DownloadAppointmentLetter = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let employeeId = row?._id;
  const {user, download} = useAppStateContext();

  return (
    <Form
      {...props}
      onSubmit={data => {
        download({
          uri: `/downloadappointementletterlateral`,
          props: {
            employeeId,
            ...data,
          },
        });
      }}
      submitAction={'Download'}
      layoutFields={[
        {
          label: 'On Letter Head',
          placeholder: 'On Letter Head',
          field: 'on_letter_head',
          type: 'checkbox',
        },
      ]}
    />
  );
};

export const DownloadAppointmentLetterForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <DownloadAppointmentLetter
      header={{
        title: 'Download Letter',
      }}
      {...props}
    />
  );
};

export const BackgroundVerificationForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <BackgroundVerification
      header={{
        title: 'Background verification details',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.employee?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const BackgroundVerification = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employees',
    eventSourceId: 'EmployeeAssignment',
    ...props,
  });

  return (
    <Form
      {...props}
      defaultValues={{
        _id: row?._id,
        ...row,
        background_verification_completed: true,
        background_verification_completed_date: getZeroTimeDate(new Date()),
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          fields: [
            {
              type: 'file',
              options: {bucketName: 'manaze'},
              label: 'Background verification documents',
              placeholder: 'Drag and drop the docs here',
              field: 'background_verification_attachment',
            },
            {
              type: 'radioGroup',
              field: 'background_verification_outcome',
              options: [
                {value: 'Green', label: 'Green'},
                {value: 'Amber', label: 'Amber'},
                {value: 'Red', label: 'Red'},
              ],
              label: 'Outcome',
            },
            {
              type: 'file',
              options: {bucketName: 'manaze'},
              label: 'Background verification report',
              placeholder: 'Drag and drop the docs here',
              field: 'background_verification_report',
            },
          ],
        },
      ]}
    />
  );
};
