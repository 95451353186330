import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const AssetRepairHistoryForm = props => {
  const params = props?.route?.params;
  console.log('params>>>>>>', params);
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'AssetRegister',
    uri: '/assetrepairhistories',
    ...props,
  });

  const {row} = params;

  return (
    <Form
      {...props}
      defaultValues={{
        asset: row?._id,
      }}
      onSubmit={onSubmit}
      header={'Add Repair History'}
      submitAction={'Add'}
      layoutFields={[
        {
          type: 'date',
          field: 'repair_date',
          label: 'Repair date',
          required: true,
        },
        {
          type: 'text',
          field: 'description',
          label: 'Description',
          required: true,
        },
        {
          label: 'Vendor',
          field: 'vendor',
          type: 'autoComplete',
          api: '/vendors',
          suggestionField: 'name',
          valueField: 'name',
          size: 6,
          // required: true,
          required: true,
        },

        {
          placeholder: 'Invoice',
          field: 'invoice',
          type: 'file',
          required: true,
          options: {bucketName: 'manaze'},
          required: true,
        },
      ]}
    />
  );
};
