import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {MultiTag} from '../../../components/chip/MultiChip';

const PropsNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={({values}) => {
        const {name} = values;
        return {
          title: name ? 'Add Props' : 'Add Props',
          secondaryTitle: name,
        };
      }}
      editFormHeader={({values}) => {
        const {name} = values;
        return {
          title: 'Edit Props',
          secondaryTitle: name,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      inlineAddForm
      addInBottom
      inlineFields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
          size: 6,
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
      ]}
      fields={[
        {
          label: 'Name',
          type: 'text',
          field: 'name',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Type',
          type: 'multiText',
          field: 'type',
          size: 6,
        },
        {
          label: 'Control',
          type: 'text',
          field: 'control',
          size: 6,
        },
        {
          label: 'Options',
          type: 'multiText',
          field: 'options',
        },
        {
          label: 'Default Value',
          type: 'text',
          field: 'default_value',
        },
        {
          label: 'Category',
          type: 'text',
          field: 'category',
        },
      ]}
      columns={[
        {
          header: 'Name',
          type: 'text',
          field: 'name',
        },
        {
          header: 'Type',
          field: 'type',
          width: 120,
          render: ({row}) => {
            return <MultiTag values={row.type} />;
          },
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

const CodeSampleNestedTable = props => {
  return (
    <NestedTable
      {...props}
      selection={{
        deleteAction: true,
      }}
      header={() => {
        return {
          title: 'Add Code Sample',
        };
      }}
      editFormHeader={({values}) => {
        const {title} = values;
        return {
          title: 'Edit Code Sample',
          secondaryTitle: title,
        };
      }}
      modalProps={{
        size: 'medium',
        height: '80%',
      }}
      fields={[
        {
          label: 'Title',
          type: 'text',
          field: 'title',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'description',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          field: 'args',
          label: 'Args',
          placeholder: 'Enter arguments here...',
          type: 'textArea',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          field: 'parameters',
          label: 'Parameters',
          placeholder: 'Enter parameters here...',
          type: 'textArea',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
        {
          label: 'Code Sample',
          field: 'sample_code',
          placeholder: 'Write your Component code here...',
          type: 'code',
          height: 500,
        },
      ]}
      columns={[
        {
          header: 'Title',
          type: 'text',
          field: 'title',
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

const UIComponentForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/uicomponents',
    eventSourceId: 'UIComponents',
    ...props,
  });

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        {
          label: 'Component',
          type: 'text',
          field: 'component',
          required: true,
        },
        {
          label: 'Description',
          type: 'textArea',
          field: 'desc',
          inputProps: {
            minRows: 3,
            maxRows: 6,
          },
        },
      ],
    },
    props: {
      label: 'Props',
      layoutFields: [
        {
          nested: true,
          field: 'props',
          render: props => <PropsNestedTable {...props} />,
        },
      ],
    },
    code_sample: {
      label: 'Sample Code',
      layoutFields: [
        {
          nested: true,
          field: 'sample_codes',
          render: props => <CodeSampleNestedTable {...props} />,
        },
      ],
    },
  };

  return (
    <Form
      type={'tab'}
      submitAction="Save"
      tabs={tabs}
      onSubmit={onSubmit}
      {...props}
    />
  );
};

export const AddUIComponentForm = props => {
  return <UIComponentForm header="Add Component" {...props} />;
};

export const UIComponentDetailForm = props => {
  const {
    route: {params},
  } = props;

  const {row} = params;

  return (
    <UIComponentForm
      // selectedTab={'designs'}
      mode="edit"
      isDualMode
      header={{title: 'Component Detail', secondaryTitle: row?.component}}
      data={row}
      {...props}
    />
  );
};
