import React from 'react';
import {
  View,
  Platform,
  Styled,
  TextInput,
} from '@unthinkable/react-core-components';

const InputCell = Styled(TextInput)`${({styles}) => {
  return {
    ...(Platform.OS === 'android'
      ? {alignSelf: 'center'}
      : {textAlign: 'center'}),
    ...styles,
  };
}}`;

export const DurationInputContainer = Styled(View)`
  border-width: 1px;
  border-color: #EEEEED;
  border-radius: 8px;
  background-color: #fff;
  flex: 1; 
  overflow: hidden;
  ${({styles}) => styles?.container}
  ${props => (props?.error ? props?.styles?.error : {})}
  `;

class TextInputComponent extends React.Component {
  render() {
    let {value, styles, ...restInputProps} = this.props;
    if (typeof value === 'number') {
      value = String(value);
    }
    return (
      <InputCell
        {...restInputProps}
        value={value}
        keyboardType={'numeric'}
        styles={styles}
      />
    );
  }
}

export const DurationInputCell = Styled(TextInputComponent)`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
  font-family: Inter-Regular;
  line-height: 20px;
  color: #19191A;
  ${({styles}) => styles?.input}
  `;
