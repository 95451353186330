import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import {Col, Text, View} from '@unthinkable/react-core-components';

import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';
import Images from '../../../images';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {
  AISuggestionRender,
  AISuggestionsButton,
} from '../cellrenders/AISuggestionRenders';
import {AITable} from '../../../components/table/AITable';
import {CountColorChip} from '../../../components/chip/ColorChip';
import {Labels} from '../constants/Label';
import {TableHeader} from '../../../components/table/Headers';
import {SearchFilter} from '../../../components/filter/Filters';
import {BorderLessButton} from '../../../components/button/AddButton';
import {useFetchData} from '../../../controllers/useFetchData';
import {useAppStateContext} from '../../../providers/AppState';

const {Owner} = Images;

export const ModuleInlineForm = ({params}) => {
  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Module',
    uri: '/projectmodules',
  });

  const {project, status} = params || {};

  const defaultValues = {status: status, project_id: project?._id};
  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Module Name',
          field: 'module',
          type: 'text',
          required: true,
        },
        {
          label: 'Identifier',
          field: 'identifier',
          type: 'text',
          required: true,
        },
        {
          label: 'Select Owner',
          field: 'owner_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/members`,
          suggestionField: 'name',
          secondarySuggestionField: 'email',
          colorField: 'color',
          avatar: true,
          valueField: 'name',
          width: 250,
        },
      ]}
    />
  );
};

export const ModulesActiveTable = props => {
  const {
    route: {params},
  } = props;
  const navigation = useNavigation();
  const {project} = params || {};
  const {colors, fonts} = useTheme();
  const deleteModuleinvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'Module',
  });

  const {download} = useAppStateContext();

  const updateModuleInvoke = useInvoke({
    method: 'put',
    eventSourceId: 'Module',
  });

  const {navigateTo} = useLeftMenuContext() || {};

  const {searchValue, onChangeFilter} = useFilter({});

  const {data} = useFetchData({
    api: `/projects/${project?._id}/modules/archived`,
    eventSourceId: 'Module',
    count: true,
  });

  return (
    <AITable
      eventSourceId={['Module', 'Feature', 'ProjectAI']}
      api={`/projects/${project?._id}/modules/active`}
      search={searchValue}
      searchFields={['module']}
      renderNoDataProps={{helpId: 'pmt_module_list'}}
      renderHeader={() => (
        <>
          <TableHeader
            title="Active modules"
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <BorderLessButton
                text={`Archived (${data?.count || 0})`}
                onPress={() => {
                  navigation.navigate('archived-module-screen', {
                    ...params,
                  });
                }}
              />,
              project?.ai_enabled ? (
                <AISuggestionsButton
                  {...props}
                  entityName="projectmodule"
                  filter={{
                    project_id: project._id,
                    status: {$ne: 'archived'},
                  }}
                  initialValues={{
                    project_id: project._id,
                    status: 'active',
                  }}
                  eventSourceId="ProjectAI"
                  params={{
                    parent_entity: 'Project',
                    parent_entity_id: project._id,
                    industry: project.industry?.name,
                    projectName: project.project,
                    projectDescription: project.desc,
                  }}
                />
              ) : (
                void 0
              ),
            ]}
          />
          <ModuleInlineForm params={{...params, status: 'active'}} />
        </>
      )}
      secondaryApiProps={{
        api: `/projectmodules/ai`,
        params: {
          parent_entity: 'Project',
          parent_entity_id: project?._id,
        },
        eventSourceId: 'ProjectAI',
      }}
      onRowPress={props => {
        const {row} = props;
        if (row?.aiGenerated) {
          navigation.navigate(`module-edit`, {
            ...params,
            module: row,
          });
        } else if (navigateTo) {
          navigateTo(`module-detail-new`, {
            module: row,
            project: project,
            project_id: project?._id,
            module_id: row._id,
            breadcrumb: {
              dropdown: {
                field: 'module',
              },
            },
          });
        } else {
          navigation.navigate(`module-detail`, {
            module: row,
            project: project,
            project_id: project?._id,
            module_id: row._id,
          });
        }
      }}
      columns={[
        {
          header: 'Module Name',
          render: _props => {
            const {row} = _props;
            let {dependencies, scope} = row;
            dependencies = dependencies
              ?.map(item => item?.module || item)
              .join(', ');

            if (scope) {
              scope = scope?.split('-').filter(Boolean);
            }
            return (
              <Col gap={8}>
                <AISuggestionRender
                  {..._props}
                  params={params}
                  primaryField="module"
                  secondaryField="desc"
                  entityName="ProjectModule"
                  eventSourceId="ProjectAI"
                />
                {scope || dependencies ? (
                  <View
                    style={{
                      paddingTop: 6,
                      paddingRight: 16,
                      paddingBottom: 8,
                      paddingLeft: 16,
                      backgroundColor: colors?.SURFACE3,
                      borderRadius: 12,
                      gap: 8,
                    }}>
                    {scope ? (
                      <View>
                        <Text
                          style={{
                            ...fonts.CAPTION_SMALL,
                            color: colors.NEUTRAL_LOW,
                          }}>
                          Scope:
                        </Text>
                        <Text
                          as="span"
                          style={{
                            ...fonts.CAPTION_SMALL,
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {scope}
                        </Text>
                      </View>
                    ) : (
                      void 0
                    )}
                    {dependencies ? (
                      <View>
                        <Text
                          style={{
                            ...fonts.CAPTION_SMALL,
                            color: colors.NEUTRAL_LOW,
                          }}>
                          Referred Modules:
                        </Text>
                        <Text
                          as="span"
                          style={{
                            ...fonts.CAPTION_SMALL,
                            color: colors.NEUTRAL_MEDIUM,
                          }}>
                          {dependencies}
                        </Text>
                      </View>
                    ) : (
                      void 0
                    )}
                  </View>
                ) : (
                  void 0
                )}
              </Col>
            );
          },
          colspan: ({row}) => (row.aiGenerated ? 4 : void 0),
        },
        {
          type: 'colorTag',
          header: 'Identifier',
          field: 'identifier',
          width: 150,
          colorField: 'color',
        },
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: {icon: Owner, label: 'Owner'},
          width: 160,
        },
      ]}
      moreActions={() => [
        {
          title: 'Detail',
          onPress: ({row}) => {
            navigation.navigate(`module-edit`, {
              ...params,
              module: row,
            });
          },
        },
        {
          title: `Export ${Labels.Features}`,
          onPress: ({row}) => {
            download({
              uri: `/generateExcel`,
              props: {
                filter: {
                  module_id: row._id,
                  status: {$ne: 'archived'},
                },
                sort: {
                  status: 1,
                  index: 1,
                },
                excelParams: {
                  api: '/features/export-data',
                  filename: row.module
                    ? `${row.module.toLowerCase().split().join(_)}_features`
                    : 'features_export',
                  fields: {
                    feature: 1,
                    status: 1,
                    sub_status: 1,
                    type: 1,
                    // releasedOn: 1,
                    code: 1,
                  },
                  columns: [
                    {header: 'Type', key: 'type', width: 15},
                    {header: 'Feature', key: 'feature', width: 60},
                    {header: 'Feature Code', key: 'code', width: 20},
                    {header: 'Status', key: 'status', width: 15},
                    {header: 'Stage', key: 'sub_status', width: 15},
                    // {
                    //   header: 'Release Date',
                    //   key: 'releasedOn',
                    //   type: 'Date',
                    //   format: 'DD MMM',
                    //   width: 15,
                    // },
                  ],
                },
              },
            });
          },
        },
        {
          title: 'Mark Archived',
          confirm: {
            title: 'Archive Module',
            message: `Archiving this module will mark all of its ${Labels.Features} as archived. Are you sure want to proceed?`,
          },
          onPress: ({row}) => {
            updateModuleInvoke({
              uri: `/projectmodules/${row?._id}`,
              props: {
                status: 'archived',
              },
            });
          },
        },
        {
          title: 'Merge Module',
          onPress: props => {
            const {row} = props;
            navigation.navigate(`merge-detail`, {
              ...params,
              source: {_id: row?._id},
              title: 'Merge Module',
              secondaryTitle: row?.module,
              targetField: {
                api: `/projectmodules`,
                suggestionField: 'module',
                valueField: 'module',
                filter: {
                  project_id: project?._id,
                  _id: {$nin: [row?._id]},
                  status: {$ne: 'archived'},
                },
              },
              uri: '/modules/merge',
              eventSourceId: 'Module',
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: ({row}) => {
            navigation.navigate(`activity-logs`, {
              _id: row._id,
              populate: [
                {path: 'project_id', select: {project: 1}},
                {path: 'owner_id', select: {name: 1}},
                {path: 'dependencies', select: {module: 1}},
              ],
              api: `/projectmodules/${row?._id}/activities`,
              displayFields: {
                module: {
                  label: 'module',
                },
                desc: {
                  label: 'desc',
                },
                status: {
                  label: 'status',
                },
                owner_id: {
                  label: 'owner',
                  field: 'owner_id.name',
                },
                project_id: {
                  label: 'project',
                  field: 'project_id.name',
                },
                dependencies: {
                  label: 'Referred Modules',
                  field: 'dependencies.module',
                },
              },
              title: row?.module ? `Module : ${row?.module}` : '',
            });
          },
        },
        // {
        //   title: 'Delete',
        //   variant: 'error',
        //   confirm: {
        //     title: 'Delete',
        //     message: 'Are you sure you want to delete this module?',
        //   },
        //   onPress: ({row}) => {
        //     deleteModuleinvoke({
        //       uri: `/modules/${row?._id}/delete`,
        //       props: {},
        //     });
        //   },
        // },
      ]}
    />
  );
};
