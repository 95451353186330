import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import NestedTable from '../../../components/form/NestedTable';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Text, View} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable, Table} from '../../../components/table/Table';
import {Footer} from '@unthinkable/react-screen-components';
import {Button} from '@unthinkable/react-button';

const MilestoneForm = props => {
  const {
    route: {params = {}},
    header = 'Add MileStones',
  } = props;
  const {department, product, row} = params;
  const {post} = useAppStateContext();

  const invoke = useInvoke({
    eventSourceId: 'projectordersmaps',
    throwError: true,
  });

  return (
    <Form
      api={`/orders/${row?.order?._id}`}
      onSubmit={async data => {
        await invoke({
          uri: '/orders/createMilestones',
          props: {
            ...data,
            project_plan_status: 'Approval Pending',
            project: row?.project?.[0] ? row?.project?.[0] : row?.project,
            order_project_map: row?._id,
          },
        });
      }}
      // onSubmit={async data => {
      //   await post({
      //     uri: '/orders/createMilestones',
      //     props: {
      //       ...data,
      //       project_plan_status: 'Approval Pending',
      //       project: row?.project,
      //       order_project_map: row?._id,
      //     },
      //     eventSourceId: 'projectordersmaps',
      //   });
      // }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'milestone_detail',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              defaultValues={({values}) => {
                return {
                  start_date: values?.order_date,
                  end_date: values?.order_end_date,
                  est_man_hour: values?.est_order_hrs,
                  amount: values?.amount,
                  base_amount: values?.base_amount,
                  exchange_rate: values?.exchange_rate,
                  delivery_rate: values?.delivery_rate,
                  project: row?.project,
                };
              }}
              fields={[
                // {
                //   field: 'order_milestone_no',
                //   placeholder: 'M. No.',
                //   header: 'M. No.',
                //   type: 'text',
                // },
                {
                  field: 'description',
                  placeholder: 'Description',
                  header: 'Description',
                  type: 'textArea',
                  size: 2,
                },
                {
                  field: 'start_date',
                  placeholder: 'Start Date',
                  header: 'Start Date',
                  label: 'Start Date',
                  type: 'date',
                },
                {
                  field: 'end_date',
                  placeholder: 'End Date',
                  header: 'End Date',
                  label: 'End Date',
                  type: 'date',
                },
                {
                  field: 'est_man_hour',
                  header: 'Hrs',
                  label: 'Est. Hrs',
                  placeholder: 'Est. Hrs',
                  type: 'number',
                  onChangeValue: (value, _, {values, setFieldValue}) => {
                    let amount = 0;
                    let base_amount = 0;
                    let delivery_rate = values?.delivery_rate || 0;
                    let exchange_rate = values?.exchange_rate || 0;

                    if (value && delivery_rate)
                      amount = Number(value) * Number(delivery_rate);

                    base_amount = amount;

                    if (delivery_rate) base_amount *= exchange_rate;

                    setFieldValue('amount', amount);
                    setFieldValue('base_amount', base_amount);
                  },
                },
              ]}
              columns={[
                {
                  header: 'M.No',
                  field: 'order_milestone_no',
                  type: 'text',
                  width: 150,
                },
                {
                  header: 'Description',
                  field: 'description',
                  type: 'text',
                },
                {
                  field: 'start_date',
                  header: 'Start Date',
                  type: 'date',
                },
                {
                  field: 'end_date',
                  header: 'End Date',
                  type: 'date',
                },
                {
                  field: 'est_man_hour',
                  header: 'Est.',
                  type: 'number',
                },
              ]}
            />
          ),
        },
        // {
        //   label: 'Milestone Details',
        //   fields: [
        //     {
        //       field: 'milestone_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           defaultValues={({values}) => {
        //             return {
        //               start_date: values?.order_date,
        //               end_date: values?.order_end_date,
        //               est_man_hour: values?.est_order_hrs,
        //               amount: values?.amount,
        //               base_amount: values?.base_amount,
        //               exchange_rate: values?.exchange_rate,
        //               delivery_rate: values?.delivery_rate,
        //             };
        //           }}
        //           columns={[
        //             {
        //               field: 'order_milestone_no',
        //               header: 'Milestone No.',
        //               width: 200,
        //             },
        //             {
        //               field: 'start_date',
        //               header: 'Start Date',
        //               type: 'date',
        //               width: 200,
        //             },
        //             {
        //               field: 'end_date',
        //               header: 'End Date',
        //               type: 'date',
        //               // width: 200,
        //             },
        //             {
        //               field: 'est_man_hour',
        //               header: 'Hrs',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'amount',
        //               header: 'Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'base_amount',
        //               header: 'Base Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'description',
        //               header: 'Description',
        //               width: 200,
        //             },
        //             // {
        //             //   field: 'status',
        //             //   header: 'Status',
        //             //   align: 'center',
        //             //   width: 200,
        //             // },
        //           ]}
        //           fields={[
        //             {
        //               type: 'date',
        //               field: 'start_date',
        //               label: 'Start Date',
        //               placeholder: 'From Date',
        //               size: 4,
        //             },
        //             {
        //               type: 'date',
        //               field: 'end_date',
        //               label: 'End Date',
        //               placeholder: 'To Date',
        //               size: 4,
        //             },
        //             {
        //               type: 'number',
        //               field: 'est_man_hour',
        //               placeholder: 'Hrs',
        //               label: 'Hrs',
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = values?.delivery_rate || 0;
        //                 let exchange_rate = values?.exchange_rate || 0;

        //                 if (value && delivery_rate)
        //                   amount = Number(value) * Number(delivery_rate);

        //                 base_amount = amount;

        //                 if (delivery_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },
        //             {
        //               type: 'number',
        //               field: 'delivery_rate',
        //               label: 'Delivery Rate',
        //               placeholder: 'Delivery Rate',
        //               readOnly: true,
        //               size: 4,
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = value || 0;
        //                 let exchange_rate = values?.exchange_rate || 0;
        //                 let est_man_hour = values?.est_man_hour || 0;

        //                 if (est_man_hour && delivery_rate)
        //                   amount = Number(est_man_hour) * Number(delivery_rate);

        //                 base_amount = amount;

        //                 if (exchange_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },
        //             {
        //               type: 'number',
        //               field: 'exchange_rate',
        //               label: 'Exchange Rate',
        //               placeholder: 'Exchange Rate',
        //               readOnly: true,
        //               size: 4,
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = values?.delivery_rate || 0;
        //                 let exchange_rate = value || 0;
        //                 let est_man_hour = values?.est_man_hour || 0;

        //                 if (est_man_hour && delivery_rate)
        //                   amount = Number(est_man_hour) * Number(delivery_rate);

        //                 base_amount = amount;
        //                 if (exchange_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },

        //             {
        //               type: 'number',
        //               field: 'amount',
        //               placeholder: 'Amount',
        //               label: 'Amount',
        //               readOnly: true,
        //               size: 6,
        //             },
        //             {
        //               type: 'number',
        //               field: 'base_amount',
        //               placeholder: 'Base Amount',
        //               label: 'Base Amount',
        //               readOnly: true,
        //               size: 6,
        //             },
        //             {
        //               field: 'description',
        //               label: 'Description',
        //               placeholder: 'Description',
        //               type: 'textArea',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

const ApproveForm = props => {
  const {
    route: {params = {}},
    header = 'Add MileStones',
  } = props;
  const {department, product, row} = params;
  const {post} = useAppStateContext();
  return (
    <Form
      api={`/orders/${row?.order?._id}`}
      onSubmit={async data => {
        await post({
          uri: '/orders/createMilestones',
          props: {
            ...data,
            project_plan_status: 'Approval Pending',
            project: row?.project,
            order_project_map: row?._id,
          },
        });
      }}
      header={header}
      submitAction={'Save'}
      layoutFields={[
        {
          field: 'milestone_detail',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              inlineAddForm
              defaultValues={({values}) => {
                return {
                  // start_date: values?.order_date,
                  // end_date: values?.order_end_date,
                  // est_man_hour: values?.est_order_hrs,
                  amount: values?.amount,
                  base_amount: values?.base_amount,
                  exchange_rate: values?.exchange_rate,
                  delivery_rate: values?.delivery_rate,
                  project: row?.project,
                };
              }}
              fields={[
                // {
                //   field: 'order_milestone_no',
                //   placeholder: 'M. No.',
                //   header: 'M. No.',
                //   type: 'text',
                // },
                {
                  field: 'description',
                  placeholder: 'Description',
                  header: 'Description',
                  type: 'textArea',
                  size: 2,
                },
                {
                  field: 'start_date',
                  placeholder: 'Start Date',
                  header: 'Start Date',
                  label: 'Start Date',
                  type: 'date',
                },
                {
                  field: 'end_date',
                  placeholder: 'End Date',
                  header: 'End Date',
                  label: 'End Date',
                  type: 'date',
                },
                {
                  field: 'est_man_hour',
                  header: 'Hrs',
                  label: 'Est. Hrs',
                  placeholder: 'Est. Hrs',
                  type: 'number',
                  onChangeValue: (value, _, {values, setFieldValue}) => {
                    let amount = 0;
                    let base_amount = 0;
                    let delivery_rate = values?.delivery_rate || 0;
                    let exchange_rate = values?.exchange_rate || 0;

                    if (value && delivery_rate)
                      amount = Number(value) * Number(delivery_rate);

                    base_amount = amount;

                    if (delivery_rate) base_amount *= exchange_rate;

                    setFieldValue('amount', amount);
                    setFieldValue('base_amount', base_amount);
                  },
                },
              ]}
              columns={[
                {
                  header: 'M.No',
                  field: 'order_milestone_no',
                  type: 'text',
                  width: 100,
                },
                {
                  header: 'Description',
                  field: 'description',
                  type: 'text',
                },
                {
                  field: 'start_date',
                  header: 'Start Date',
                  type: 'date',
                },
                {
                  field: 'end_date',
                  header: 'End Date',
                  type: 'date',
                },
                {
                  field: 'est_man_hour',
                  header: 'Est.',
                  type: 'number',
                },
              ]}
            />
          ),
        },
        // {
        //   label: 'Milestone Details',
        //   fields: [
        //     {
        //       field: 'milestone_detail',
        //       nested: true,
        //       addInBottom: true,
        //       render: props => (
        //         <NestedTable
        //           {...props}
        //           defaultValues={({values}) => {
        //             return {
        //               start_date: values?.order_date,
        //               end_date: values?.order_end_date,
        //               est_man_hour: values?.est_order_hrs,
        //               amount: values?.amount,
        //               base_amount: values?.base_amount,
        //               exchange_rate: values?.exchange_rate,
        //               delivery_rate: values?.delivery_rate,
        //             };
        //           }}
        //           columns={[
        //             {
        //               field: 'order_milestone_no',
        //               header: 'Milestone No.',
        //               width: 200,
        //             },
        //             {
        //               field: 'start_date',
        //               header: 'Start Date',
        //               type: 'date',
        //               width: 200,
        //             },
        //             {
        //               field: 'end_date',
        //               header: 'End Date',
        //               type: 'date',
        //               // width: 200,
        //             },
        //             {
        //               field: 'est_man_hour',
        //               header: 'Hrs',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'amount',
        //               header: 'Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'base_amount',
        //               header: 'Base Amount',
        //               type: 'number',
        //               width: 200,
        //             },
        //             {
        //               field: 'description',
        //               header: 'Description',
        //               width: 200,
        //             },
        //             // {
        //             //   field: 'status',
        //             //   header: 'Status',
        //             //   align: 'center',
        //             //   width: 200,
        //             // },
        //           ]}
        //           fields={[
        //             {
        //               type: 'date',
        //               field: 'start_date',
        //               label: 'Start Date',
        //               placeholder: 'From Date',
        //               size: 4,
        //             },
        //             {
        //               type: 'date',
        //               field: 'end_date',
        //               label: 'End Date',
        //               placeholder: 'To Date',
        //               size: 4,
        //             },
        //             {
        //               type: 'number',
        //               field: 'est_man_hour',
        //               placeholder: 'Hrs',
        //               label: 'Hrs',
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = values?.delivery_rate || 0;
        //                 let exchange_rate = values?.exchange_rate || 0;

        //                 if (value && delivery_rate)
        //                   amount = Number(value) * Number(delivery_rate);

        //                 base_amount = amount;

        //                 if (delivery_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },
        //             {
        //               type: 'number',
        //               field: 'delivery_rate',
        //               label: 'Delivery Rate',
        //               placeholder: 'Delivery Rate',
        //               readOnly: true,
        //               size: 4,
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = value || 0;
        //                 let exchange_rate = values?.exchange_rate || 0;
        //                 let est_man_hour = values?.est_man_hour || 0;

        //                 if (est_man_hour && delivery_rate)
        //                   amount = Number(est_man_hour) * Number(delivery_rate);

        //                 base_amount = amount;

        //                 if (exchange_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },
        //             {
        //               type: 'number',
        //               field: 'exchange_rate',
        //               label: 'Exchange Rate',
        //               placeholder: 'Exchange Rate',
        //               readOnly: true,
        //               size: 4,
        //               onChangeValue: (value, _, {values, setFieldValue}) => {
        //                 let amount = 0;
        //                 let base_amount = 0;
        //                 let delivery_rate = values?.delivery_rate || 0;
        //                 let exchange_rate = value || 0;
        //                 let est_man_hour = values?.est_man_hour || 0;

        //                 if (est_man_hour && delivery_rate)
        //                   amount = Number(est_man_hour) * Number(delivery_rate);

        //                 base_amount = amount;
        //                 if (exchange_rate) base_amount *= exchange_rate;

        //                 setFieldValue('amount', amount);
        //                 setFieldValue('base_amount', base_amount);
        //               },
        //             },

        //             {
        //               type: 'number',
        //               field: 'amount',
        //               placeholder: 'Amount',
        //               label: 'Amount',
        //               readOnly: true,
        //               size: 6,
        //             },
        //             {
        //               type: 'number',
        //               field: 'base_amount',
        //               placeholder: 'Base Amount',
        //               label: 'Base Amount',
        //               readOnly: true,
        //               size: 6,
        //             },
        //             {
        //               field: 'description',
        //               label: 'Description',
        //               placeholder: 'Description',
        //               type: 'textArea',
        //             },
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

export const AddMilestone = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let defaultValues = {};
  return (
    <MilestoneForm
      header="Create Milestone"
      defaultValues={defaultValues}
      {...props}
    />
  );
};

export const EditMilestone = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MilestoneForm
      mode="edit"
      header={{
        title: 'Project plan',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <RowWithSeparator gap={4} style={{alignItems: 'center'}}>
              <Text
                numberOfLines={1}
                style={{
                  color: colors.NEUTRAL_MEDIUM,
                  ...CAPTION_LARGE,
                }}>
                {row?.order?.order_number}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  color: colors.NEUTRAL_MEDIUM,
                  ...CAPTION_LARGE,
                }}>
                {`${moment(row.order?.order_date).format('DD MMM, YY')}`}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  color: colors.NEUTRAL_MEDIUM,
                  ...CAPTION_LARGE,
                }}>
                {`${moment(row.order?.order_end_date).format('DD MMM, YY')}`}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  color: colors.NEUTRAL_MEDIUM,
                  ...CAPTION_LARGE,
                }}>
                {row?.order?.est_order_hrs}
              </Text>
            </RowWithSeparator>
          );
        },
      }}
      fields={{
        order_end_date: 1,
        order_date: 1,
        est_order_hrs: 1,
        amount: 1,
        base_amount: 1,
        exchange_rate: 1,
        delivery_rate: 1,
        project: 1,
        milestone_detail: {
          start_date: 1,
          end_date: 1,
          delivery_rate: 1,
          exchange_rate: 1,
          est_man_hour: 1,
          amount: 1,
          base_amount: 1,
          status: 1,
          order_milestone_no: 1,
          description: 1,
          order_milestone_no: 1,
        },
      }}
      {...props}
    />
  );
};

export const ApproveMilestone = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {row} = params;

  const invoke = useInvoke({
    method: 'post',
    eventSourceId: ['performance-report'],
  });
  return (
    <Table
      api={'/ordermilestones'}
      fields={{
        start_date: 1,
        end_date: 1,
        delivery_rate: 1,
        exchange_rate: 1,
        est_man_hour: 1,
        amount: 1,
        base_amount: 1,
        status: 1,
        order_milestone_no: 1,
        description: 1,
        order_milestone_no: 1,
        order: {_id: 1, order_end_date: 1, order_date: 1, est_order_hrs: 1},
      }}
      filter={{
        order: row?.order?._id,
      }}
      renderHeader={() => {
        return <TableHeader title="Milestones" />;
      }}
      renderFooter={({data}) => {
        return (
          <TableHeader
            actions={[
              <Button
                text="Reject"
                onPress={async () => {
                  await invoke({
                    uri: '/orders/createMilestones',
                    props: {
                      project_plan_status: 'Rejected',
                      order_end_date: row?.order?.order_end_date,
                      order_date: row?.order?.order_date,
                      est_order_hrs: row?.order?.est_order_hrs,
                      _id: row?.order?._id,
                      project: row?.project,
                      order_project_map: row?._id,
                      milestone_detail: data,
                    },
                  });
                  navigation.pop(2);
                }}
              />,
              <Button
                text="Approved"
                onPress={async () => {
                  await invoke({
                    uri: '/orders/createMilestones',
                    props: {
                      project_plan_status: 'Approved',
                      order_end_date: row?.order?.order_end_date,
                      order_date: row?.order?.order_date,
                      est_order_hrs: row?.order?.est_order_hrs,
                      _id: row?.order?._id,
                      project: row?.project,
                      order_project_map: row?._id,
                      milestone_detail: data,
                    },
                  });
                  navigation.goBack();
                }}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'M.No.',
          field: 'order_milestone_no',
          type: 'text',
          width: 100,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
        },
        {
          header: 'Start Date',
          field: 'start_date',
          type: 'date',
          formatOptions: {format: 'DD MMM,YY'},
          width: 80,
          align: 'center',
        },
        {
          header: 'End Date',
          field: 'end_date',
          formatOptions: {format: 'DD MMM,YY'},
          type: 'date',
          width: 80,
          align: 'center',
        },
        {
          header: 'Est. Hrs.',
          field: 'est_man_hour',
          type: 'duration',
          width: 80,
          suffix: 'Hrs',
        },
      ]}
    />
  );
};

const AdminMilestoneForm = props => {
  const {
    route: {params = {}},
    header = 'Add MileStones',
  } = props;
  const {department, product, row} = params;
  const {extraParameter = {}} = row;
  let invoke = useInvoke({
    method: 'post',
    eventSourceId: 'fixed',
    throwError: true,
  });

  return (
    <Form
      api={`/orderMileStonesDetails/${row?._id}`}
      onSubmit={async data => {
        await invoke({
          uri: '/orders/createMilestones',
          props: {
            ...data,
            admin_approval: true,
            project: row?.project?.[0]?._id,
            product: row?.product,
            ...extraParameter,
          },
        });
      }}
      header={header}
      submitAction={'Save'}
      mode="edit"
      defaultValues={{
        admin_approval: true,
        order_date: row?.order_date,
        order_end_date: row?.order_end_date,
        est_order_hrs: row?.est_order_hrs,
        // order_project_map: row?._id,
      }}
      layoutFields={[
        {
          label: 'Order Details',
          fields: [
            {
              label: '',
              fields: [
                {
                  type: 'date',
                  field: 'order_date',
                  label: 'Start Date',
                  placeholder: 'Start Date',
                  size: 6,
                },
                {
                  type: 'date',
                  field: 'order_end_date',
                  label: 'End Date',
                  placeholder: 'End Date',
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'est_order_hrs',
                  label: 'Est Man Hour',
                  placeholder: 'Est Man Hour',
                  size: 12,
                  onChangeValue: (value, _, {values, setFieldValue}) => {
                    let {
                      exchange_rate,
                      est_order_hrs,
                      delivery_rate,
                      amount,
                      base_amount,
                    } = values || {};

                    amount = amount ?? 0;
                    base_amount = base_amount ?? 0;

                    if (value && delivery_rate)
                      amount = Number(value) * Number(delivery_rate);

                    base_amount = amount;
                    if (delivery_rate) base_amount *= exchange_rate;

                    setFieldValue('amount', amount);
                    setFieldValue('base_amount', base_amount);
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Milestone Details',
          fields: [
            {
              field: 'milestone_detail',
              nested: true,
              addInBottom: true,
              render: props => (
                <NestedTable
                  {...props}
                  defaultValues={({values}) => {
                    return {
                      start_date: values?.order_date,
                      end_date: values?.order_end_date,
                      est_man_hour: values?.est_order_hrs,
                      amount: values?.amount,
                      base_amount: values?.base_amount,
                      exchange_rate: values?.exchange_rate,
                      delivery_rate: values?.delivery_rate,
                    };
                  }}
                  columns={[
                    {
                      field: 'order_milestone_no',
                      header: 'Milestone No.',
                      width: 140,
                    },
                    {
                      field: 'description',
                      header: 'Description',
                      minWidth: 200,
                    },
                    {
                      field: 'start_date',
                      header: 'Start Date',
                      formatOptions: {format: 'DD MMM YY'},
                      type: 'date',
                      width: 100,
                    },
                    {
                      field: 'end_date',
                      header: 'End Date',
                      type: 'date',
                      formatOptions: {format: 'DD MMM YY'},
                      width: 100,
                    },
                    {
                      field: 'est_man_hour',
                      header: 'Hrs',
                      type: 'text',
                      width: 60,
                      // colspan: ({row}) => (!row?.end_date ? 2 : void 0),
                    },
                    // {
                    //   field: 'amount',
                    //   header: 'Amount',
                    //   type: 'currency',
                    //   width: 120,
                    // },
                    // {
                    //   field: 'base_amount',
                    //   header: 'Base Amount',
                    //   type: 'currency',
                    //   width: 120,
                    // },
                    // {
                    //   field: 'status',
                    //   header: 'Status',
                    //   align: 'center',
                    //   width: 200,
                    // },
                  ]}
                  fields={[
                    {
                      type: 'date',
                      field: 'start_date',
                      label: 'Start Date',
                      placeholder: 'From Date',
                      size: 4,
                    },
                    {
                      type: 'date',
                      field: 'end_date',
                      label: 'End Date',
                      placeholder: 'To Date',
                      size: 4,
                    },
                    {
                      type: 'number',
                      field: 'est_man_hour',
                      placeholder: 'Hrs',
                      label: 'Hrs',
                      onChangeValue: (value, _, {values, setFieldValue}) => {
                        let amount = 0;
                        let base_amount = 0;
                        let delivery_rate = values?.delivery_rate || 0;
                        let exchange_rate = values?.exchange_rate || 0;

                        if (value && delivery_rate)
                          amount = Number(value) * Number(delivery_rate);

                        base_amount = amount;

                        if (delivery_rate) base_amount *= exchange_rate;

                        setFieldValue('amount', amount);
                        setFieldValue('base_amount', base_amount);
                      },
                    },
                    // {
                    //   type: 'number',
                    //   field: 'delivery_rate',
                    //   label: 'Delivery Rate',
                    //   placeholder: 'Delivery Rate',
                    //   readOnly: true,
                    //   size: 4,
                    //   onChangeValue: (value, _, {values, setFieldValue}) => {
                    //     let amount = 0;
                    //     let base_amount = 0;
                    //     let delivery_rate = value || 0;
                    //     let exchange_rate = values?.exchange_rate || 0;
                    //     let est_man_hour = values?.est_man_hour || 0;

                    //     if (est_man_hour && delivery_rate)
                    //       amount = Number(est_man_hour) * Number(delivery_rate);

                    //     base_amount = amount;

                    //     if (exchange_rate) base_amount *= exchange_rate;

                    //     setFieldValue('amount', amount);
                    //     setFieldValue('base_amount', base_amount);
                    //   },
                    // },
                    // {
                    //   type: 'number',
                    //   field: 'exchange_rate',
                    //   label: 'Exchange Rate',
                    //   placeholder: 'Exchange Rate',
                    //   readOnly: true,
                    //   size: 4,
                    //   onChangeValue: (value, _, {values, setFieldValue}) => {
                    //     let amount = 0;
                    //     let base_amount = 0;
                    //     let delivery_rate = values?.delivery_rate || 0;
                    //     let exchange_rate = value || 0;
                    //     let est_man_hour = values?.est_man_hour || 0;

                    //     if (est_man_hour && delivery_rate)
                    //       amount = Number(est_man_hour) * Number(delivery_rate);

                    //     base_amount = amount;
                    //     if (exchange_rate) base_amount *= exchange_rate;

                    //     setFieldValue('amount', amount);
                    //     setFieldValue('base_amount', base_amount);
                    //   },
                    // },
                    // {
                    //   type: 'number',
                    //   field: 'amount',
                    //   placeholder: 'Amount',
                    //   label: 'Amount',
                    //   readOnly: true,
                    //   size: 6,
                    // },
                    // {
                    //   type: 'number',
                    //   field: 'base_amount',
                    //   placeholder: 'Base Amount',
                    //   label: 'Base Amount',
                    //   readOnly: true,
                    //   size: 6,
                    // },
                    {
                      field: 'description',
                      label: 'Description',
                      placeholder: 'Description',
                      type: 'textArea',
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddAdminMilestone = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let defaultValues = {};
  return (
    <AdminMilestoneForm
      header="Create Milestone"
      defaultValues={defaultValues}
      {...props}
    />
  );
};
