import {Col, Text} from '@unthinkable/react-core-components';
import {getCurrentMonth} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';

export const numDifferentiation = value => {
  if (value >= 10000000) {
    value = (value / 10000000).toFixed(2) + ' Cr';
  } else if (value >= 100000) {
    value = (value / 100000).toFixed(2) + ' L';
  } else if (value >= 10000) {
    value = (value / 10000).toFixed(2) + ' K';
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(2) + ' K';
  } else if (value > 0) {
    value = value.toFixed(0);
  } else if (value < 0) {
    if (Math.abs(value) >= 10000000) {
      value = (value / 10000000).toFixed(2) + ' Cr';
    } else if (Math.abs(value) >= 100000) {
      value = (value / 100000).toFixed(2) + ' L';
    } else if (Math.abs(value) >= 10000) {
      value = (value / 10000).toFixed(2) + ' K';
    } else if (Math.abs(value) >= 1000) {
      value = (value / 1000).toFixed(2) + ' K';
    } else if (Math.abs(value) > 0) {
      value = value.toFixed(0);
    }
    // value = value.toFixed(0);
  }
  return value;
};

const RenderCustomer = props => {
  const {row} = props;

  if (row?.name) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.name}
        variant="secondary"
        items={[
          {
            value: row?.employee_count?.number,
            backgroundColor: row?.employee_count?.color,
            numberOfLines: 1,
          },
          {
            value: row?.industry?.name,
            backgroundColor: row?.industry?.name?.color,
            numberOfLines: 1,
          },
          {
            value: row?.country?.name,
            backgroundColor: row?.country?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const RenderOpenSince = props => {
  const {
    row,
    styles: {rowText},
  } = props;

  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.first_order_date) {
    return (
      <>
        <Col>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              // color: colors.ERROR_HIGH,
            }}>
            {moment(row.first_order_date).format('DD MMM, YY')}
          </Text>
          <Text
            style={{
              ...CAPTION_LARGE,
              color: colors.NEUTRAL_LOW,
            }}>
            Tenure: {row.open_since} d
          </Text>
        </Col>
      </>
    );
  } else {
    return void 0;
  }
};

const LEAD_VALUE = {
  header: 'Score',
  field: 'lead_score',
  toFixed: 2,
  align: 'right',
};

const RenderInvoice = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');

  if (row?.totalRevenue) {
    const value = numDifferentiation(Number(row?.totalRevenue));
    return (
      <>
        <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
          {value}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderPreviousInvoice = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.previoustotalRevenue) {
    const value = numDifferentiation(Number(row?.previoustotalRevenue));
    const differenceValue = numDifferentiation(
      Number(row.differenceOfReveneue),
    );

    return (
      <>
        <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
          {value}
        </Text>
        {/* <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_LOW,
          }}>
          Diff: {differenceValue}
        </Text> */}
      </>
    );
  } else {
    return void 0;
  }
};

const RenderDifference = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.differenceOfReveneue) {
    const differenceValue = numDifferentiation(
      Number(row.differenceOfReveneue),
    );

    return (
      <>
        <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
          {differenceValue}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

const RenderReceipts = props => {
  const {row, navigation} = props;
  const icons = useTheme('icons');
  const fonts = useTheme('fonts');
  const colors = useTheme('colors');
  if (row?.totalReceipts) {
    const value = numDifferentiation(Number(row?.totalReceipts));
    return (
      <>
        <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY2}}>
          {value}
        </Text>
      </>
    );
  } else {
    return void 0;
  }
};

export const CustomerRevenueTable = props => {
  let {
    navigation,
    route: {params},
    filterParams,
    filter,
    searchValue,
  } = props;
  filterParams = {...params, ...filterParams};
  return (
    <Table
      params={{...filterParams}}
      variant={'bordered'}
      addOnFilter={{...filter}}
      limit={5000}
      search={searchValue}
      api={'/customer-revenue-report'}
      columns={[
        {
          header: 'Customer',
          minWidth: 240,
          render: ({row}) => {
            return <RenderCustomer row={row} navigation={navigation} />;
          },
        },
        {...LEAD_VALUE, width: 80},
        {
          header: 'AM',
          field: 'owner',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Since',
          render: RenderOpenSince,
          align: 'center',
          width: 120, //changed from 120 to 140 becuase open changed tenure
        },
        {
          // type: 'currency',
          type: 'indianCurrency',
          aggregate: true,
          header: 'Revenue(Current Period)',
          field: 'totalRevenue',
          width: 200,
          render: RenderInvoice,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('customer-wise-revenue-invoice', {
              _id: row?.revenueIds,
              // period: period,
            });
          },
        },
        {
          // type: 'currency',
          aggregate: true,
          type: 'indianCurrency',
          header: 'Revenue(Pre. Period)',
          field: 'previoustotalRevenue',
          width: 160,
          render: RenderPreviousInvoice,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('customer-wise-revenue-invoice', {
              _id: row?.previousrevenueIds,
              // period: period,
            });
          },
        },

        {
          header: 'Revenue Diff.',
          render: RenderDifference,
          type: 'indianCurrency',
          width: 120,
          type: 'number',
        },
        {
          aggregate: true,
          header: 'Receipts',
          type: 'indianCurrency',
          field: 'totalReceipts',
          width: 80,
          render: RenderReceipts,
          align: 'right',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
          onPress: ({row}) => {
            navigation.navigate('customer-wise-receipt-list', {
              _id: row?.receiptIds,
            });
          },
        },
      ]}
    />
  );
};

export const CustomerRevenueTabs = props => {
  let {route: {params} = {}, extraFilter = {}, fromAm = false} = props;
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      ...extraFilter,
    },
  });
  const icons = useTheme('icons');
  const {params: filterParams, filter} = filterValues;
  const {period} = filterParams;
  const {download} = useAppStateContext();
  const tabs = {
    am_report: {
      label: 'Customer Wise Revenue',
      view: (
        <CustomerRevenueTable
          filterParams={filterParams}
          filter={{...filter, period}}
          searchValue={searchValue}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <PeriodRangeFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'created_on',
              asParam: true,
              label: 'Customer Since',
            },
            {
              type: 'autoComplete',
              label: 'Channel',
              api: '/types/suggestionsForLead',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'campaign_type',
            },
            {
              label: 'Source',
              placeholder: 'Select',
              field: 'campaign_id',
              type: 'autoComplete',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({filter: {campaign_type} = {}}) => {
                return {
                  source: campaign_type,
                };
              },
            },
            !fromAm && {
              type: 'multiAutoComplete',
              field: 'owner',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'AM',
            },
            {
              type: 'autoComplete',
              field: 'csm',
              api: '/employeeSuggestions',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              label: 'CSM',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'multiAutoComplete',
              field: 'product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              label: 'Products',
              filter: {
                _id: {
                  $in: [
                    '53a42e38a22d3a020035f672',
                    '53a42e37a22d3a020035f657',
                    '6086800e5a533c52a4ee31fc',
                    '5df8d73c81564874ecb3c9ab',
                  ],
                },
              },
            },
            // {
            //   type: 'autoComplete',
            //   label: 'Customer',
            //   api: '/customers',
            //   placeholder: 'Select',
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   field: 'customer',
            // },
          ]}
        />,
        // <IconButton
        //   icon={icons.DownloadBlue}
        //   onPress={() => {
        //     download({
        //       uri: '/exportTandMProjectGrossMarginReport',
        //       props: {
        //         uri: '/projects/teamcostreport',
        //         parameters: {
        //           params: {...filterParams, isCustomerGrossMargin: true},
        //           addOnFilter: {...filter, ...params?.customerFilter},
        //         },
        //       },
        //     });
        //   }}
        // />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
