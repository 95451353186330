import React from 'react';
import {OutlineButtonStyle} from './theme';
import {useStyles} from '@unthinkable/react-theme';
import {Button as ButtonComponent} from '@unthinkable/react-button';

export const OutlineButton = props => {
  const style = useStyles(OutlineButtonStyle);

  return <ButtonComponent styles={style} {...props} />;
};
