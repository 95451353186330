import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {Durations} from './components/Durations';
import {Button} from '../../../components/button/Button';
import {useAppStateContext} from '../../../providers/AppState';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Image, Row, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {randomColor} from '../../../utils/util';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import moment from 'moment';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project',
    },
    {
      type: 'autoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Delivery Status',
      placeholder: 'Select',
      options: ['Active', 'WIP Added', 'Delivered'],
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'orginization',
    },
  ];
};

const orderAndDeliveriesFilters = ({
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'Active',
  params,
  period,
}) => {
  const {download} = useAppStateContext();

  return [
    <PeriodRangeFilter
      onChangeFilter={onChangeFilter}
      filterValues={filterValues}
      skipQuarter
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    tab === 'Delivered' && (
      <Button
        text="Download"
        onPress={() => {
          download({
            uri: `/pmDeliveredOrdersAndDelivers/export`,
            props: {
              params: {...params, period},
            },
          });
        }}
      />
    ),
  ];
};

const CUSTOMER = {
  header: 'Customer',
  field: 'customer.name',
  width: 180,
};

const renderProject = ({icons}) => {
  return {
    header: 'Project',
    minWidth: 260,
    render: ({row}) => {
      let icon = '';
      if (row?.project?.tool === 'Locomo') {
        icon = icons?.LocomoTool;
      } else if (row?.project?.tool === 'Jira') {
        icon = icons?.JiraIcon;
      } else {
        icon = icons?.OtherTool;
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image source={icon} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project?.project}
            items={[
              {
                value: row?.project?.code,
              },
              {
                value: `${
                  row?.start_date
                    ? moment(row?.start_date).format('DD MMM YY')
                    : ''
                } - ${
                  row?.due_date ? moment(row?.due_date).format('DD MMM YY') : ''
                }`,
              },
            ]}
          />
        </Row>
      );
    },
  };
};

const DURATION = {
  header: 'Duration',
  align: 'center',
  width: 180,
  render: ({row, styles: {rowText = {}} = {}}) => {
    return Durations({
      styles: rowText,
      fromDate: row?.start_date,
      toDate: row?.due_date,
    });
  },
};

const BILLING_MODEL = {
  header: 'Billing Model',
  field: 'order.billing_type',
  type: 'colorTag',
  colorField: randomColor,
  align: 'center',
};

const CSM = {
  header: 'CSM/PM',
  width: 180,
  render: ({row}) => {
    return <UserAvatarChip value={row?.project?.owner_id} />;
  },
};

const ORDERANDALLOCATED = {
  header: 'Order/Allocated',
  width: 120,
  align: 'right',
  aggregate: true,
  field: 'count',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.count || 0}/${
        row?.resource_deployable || 0
      }`}</Text>
    );
  },
};

const LEAVESINMM = {
  header: 'Leaves (MM)',
  align: 'right',
  aggregate: true,
  field: 'total_leave_hours_mm',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.total_leave_hours_mm || 0} MM`}</Text>
    );
  },
};

const PROJECTDELIVERY = {
  header: 'Proj. delivery',
  align: 'right',
  aggregate: true,
  width: 120,
  field: 'projection_delivery',
  render: ({row, styles: {rowText = {}} = {}}) => {
    return (
      <Text style={{...rowText}}>{`${row?.projection_delivery || 0} MM`}</Text>
    );
  },
};

const ACTUALPROJECTDELIVERY = {
  header: 'Actual delivery',
  align: 'right',
  aggregate: true,
  field: 'actual',
  width: 140,
  render: ({row, styles: {rowText = {}} = {}}) => {
    return <Text style={{...rowText}}>{`${row?.actual || 0} MM`}</Text>;
  },
};

const ActiveOrdersAndDeliveriesForTAndM = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      api={`/pmOrdersAndDeliveries/tm`}
      search={searchValue}
      addOnFilter={{
        ...addOnFilter,
      }}
      params={{...params, period}}
      limit={1000}
      eventSourceId={[
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ]}
      columns={[
        renderProject({icons}),
        CUSTOMER,
        BILLING_MODEL,
        CSM,
        ORDERANDALLOCATED,
        LEAVESINMM,
        PROJECTDELIVERY,
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('projectresourceworking-active-order', {
                    addOnFilter: {
                      _id: {$in: row?._children?.map(({_id}) => _id)},
                      approx_billed_hours: void 0,
                      order_type: 'r',
                    },
                    project_type: 'tm',
                    period,
                    row,
                  });
                }}
                style={{...rowText, color: '#007AFF'}}>
                Mark Delivered
              </Text>
            );
          },
          width: 160,
        },
      ]}
    />
  );
};

const DeliveredOrdersAndDeliveriesForTAndM = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    period = {},
  } = props;
  const icons = useTheme('icons');
  return (
    <Table
      api={`/pmOrdersAndDeliveries/tm`}
      search={searchValue}
      addOnFilter={{...addOnFilter}}
      params={{...params, period}}
      limit={1000}
      eventSourceId="OrdersAndDeliveriesT&M"
      columns={[
        renderProject({icons}),
        CUSTOMER,
        BILLING_MODEL,
        CSM,
        ORDERANDALLOCATED,
        LEAVESINMM,
        PROJECTDELIVERY,
        ACTUALPROJECTDELIVERY,
      ]}
      moreActions={[
        {
          title: 'Delivery Progress',
          onPress: ({row}) => {
            navigation.navigate('tandm-orders-and-delivered', {
              addOnFilter: {
                _id: {$in: row?._children?.map(({_id}) => _id)},
                order_type: 'r',
              },
              row,
              period,
            });
          },
        },
      ]}
    />
  );
};

export const OrdersAndDeliveriesForTAndMTab = props => {
  const {route: {params} = {}} = props;
  const {tandMOutputFilterFormGrowthAndBilling = {}} = params;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    active: {
      apiKey: 'active',
      label: 'Verification Due',
      view: (
        <ActiveOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$nin: ['Delivered', 'Removed']},
            ...tandMOutputFilterFormGrowthAndBilling,
          }}
          period={period}
        />
      ),
      eventSourceId: 'OrdersAndDeliveriesT&M',
      addOnFilter: {
        ...filter,
        approval_status: {$nin: ['Delivered', 'Removed']},
        ...tandMOutputFilterFormGrowthAndBilling,
      },
      limit: 1000,
      api: '/pmOrdersAndDeliveries/tm',
      params: {...params, period},
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <DeliveredOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$in: ['Delivered']},
            ...tandMOutputFilterFormGrowthAndBilling,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        approval_status: {$in: ['Delivered']},
        ...tandMOutputFilterFormGrowthAndBilling,
      },
      params: {...params, period},
      api: '/pmOrdersAndDeliveries/tm',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForTAndMTabForPM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    active: {
      apiKey: 'active',
      label: 'Verification Due',
      view: (
        <ActiveOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$nin: ['Delivered', 'Removed']},
            delivery_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: 'OrdersAndDeliveriesT&M',
      addOnFilter: {
        ...filter,
        approval_status: {
          $nin: ['Delivered', 'Removed'],
        },
        delivery_owner_id: employee?._id,
      },
      limit: 1000,
      api: '/pmOrdersAndDeliveries/tm',
      params: {...params, period},
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },

    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <DeliveredOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$in: ['Delivered']},
            delivery_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        approval_status: {$in: ['Delivered']},
        delivery_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDeliveries/tm',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForTAndMTabForAM = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    active: {
      apiKey: 'active',
      label: 'Verification Due',
      view: (
        <ActiveOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$nin: ['Delivered', 'Removed']},
            account_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: 'OrdersAndDeliveriesT&M',
      addOnFilter: {
        ...filter,
        approval_status: {
          $nin: ['Delivered', 'Removed'],
        },
        account_owner_id: employee?._id,
      },
      limit: 1000,
      api: '/pmOrdersAndDeliveries/tm',
      params: {...params, period},
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Active',
        params,
        period,
      }),
    },
    delivered: {
      apiKey: 'active',
      label: 'Verified',
      view: (
        <DeliveredOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$in: ['Delivered']},
            account_owner_id: employee?._id,
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        approval_status: {$in: ['Delivered']},
        account_owner_id: employee?._id,
      },
      params: {...params, period},
      api: '/pmOrdersAndDeliveries/tm',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const OrdersAndDeliveriesForStaffAgumentation = props => {
  const {route: {params} = {}, user} = props;
  const {employee = {}} = user || {};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    delivered: {
      apiKey: 'active',
      label: 'Staff Augmentation Delivered',
      view: (
        <DeliveredOrdersAndDeliveriesForTAndM
          {...props}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            approval_status: {$in: ['Delivered']},
            'project.new_project_type': 'staffaugmentation',
          }}
          period={period}
        />
      ),
      eventSourceId: [
        'OrdersAndDeliveriesT&M',
        'ActiveOrderOfOrdersAndDeliveries',
      ],
      addOnFilter: {
        ...filter,
        approval_status: {$in: ['Delivered']},
        'project.new_project_type': 'staffaugmentation',
      },
      params: {...params, period},
      api: '/pmOrdersAndDeliveries/tm',
      actions: orderAndDeliveriesFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'Delivered',
        params,
        period,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
