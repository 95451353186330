import React, {useState} from 'react';
import NestedTable from '../../../../components/form/NestedTable';
import {Form} from '../../../../components/form/Form';
import {HEADER_CONTENT_TYPES} from '../ApiConfigurationForm';
import {
  PROJECT_CONTROLLER_TYPE_ID,
  PROJECT_MODEL_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {useAssetFormActions} from './AssetUtility';
import {SubmitButton} from '@unthinkable/react-form';
import {Button} from '../../../../components/button/Button';
import {useInvoke} from '../../../../controllers/useInvoke';
import {Col, Row, Text} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {Asterisk} from '@unthinkable/react-input';

const DetailColumn = ({data}) => {
  const {fonts, colors} = useTheme();
  data = data?.filter(doc => doc.desc !== undefined);
  return (
    <Col gap={8}>
      {data?.map(({inline, required, title, ...item}) =>
        inline ? (
          <Row gap={4} style={{alignItems: 'center'}}>
            <Text
              style={{color: colors.NEUTRAL_MEDIUM, ...fonts.CAPTION_LARGE}}>
              {title} :
            </Text>

            {item.type === 'code' ? (
              <pre>
                <code
                  title={item?.desc}
                  style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                  {item?.desc}
                </code>
              </pre>
            ) : (
              <Text
                title={item?.desc}
                style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                {item?.desc}
              </Text>
            )}
          </Row>
        ) : (
          <Col>
            <Text
              style={{color: colors.NEUTRAL_MEDIUM, ...fonts.CAPTION_LARGE}}>
              {title} :
            </Text>

            {item.type === 'code' ? (
              <pre>
                <code
                  title={item?.desc}
                  style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                  {item?.desc}
                </code>
              </pre>
            ) : (
              <Text
                title={item?.desc}
                style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
                {item?.desc}
              </Text>
            )}
          </Col>
        ),
      )}
    </Col>
  );
};

const ResponseRender = ({row}) => {
  const {resp_type, response, status_desc} = row;

  return (
    <DetailColumn
      data={[
        {title: 'Content Type', desc: resp_type, inline: true},
        {title: 'Description', desc: status_desc},
        {title: 'Example Response', desc: response, type: 'code'},
      ]}
    />
  );
};

const RequestRender = ({row}) => {
  const {request_body_format, request_body} = row;

  return (
    <DetailColumn
      data={[
        {title: 'Body format', desc: request_body_format},
        {title: 'Body', desc: request_body, type: 'code'},
      ]}
    />
  );
};

const ParameterRender = ({row}) => {
  const {type, parameter_type, parameters_desc} = row;

  return (
    <DetailColumn
      data={[
        {title: 'In', desc: parameter_type, inline: true},
        {title: 'Format', desc: type, inline: true},
        {title: 'Description', desc: parameters_desc},
      ]}
    />
  );
};

const NameRender = ({row}) => {
  const {name, required} = row;
  const {fonts, colors} = useTheme();

  return (
    <Text style={{color: colors.NEUTRAL_HIGH, ...fonts.BODY3}}>
      {name}
      {required == 'true' && <Asterisk>{'*'}</Asterisk>}
    </Text>
  );
};

const apiEndpointFields = ({params, navigation}) => {
  const {project} = params;
  const {row} = params;
  const {released_version_id} = row || {};

  const remarksField = released_version_id?._id
    ? 'released_version_id.remarks'
    : 'remarks';

  return {
    name: {
      label: 'Name',
      type: 'text',
      field: 'controller',
      required: true,
    },
    path: {
      label: 'Path',
      type: 'text',
      field: 'uri',
      required: true,
      helperText: 'Note: the parameters should be  like : /user/{userId}',
      onChangeValue: (value, _, {setFieldValue, values}) => {
        const parameters = value.split('/').reduce(
          (acc, doc) => {
            if (doc[0] === '{' && doc[doc.length - 1] === '}') {
              acc.push({
                name: doc.slice(1, doc.length - 1),
                parameter_type: 'path',
                type: 'text',
                required: true,
              });
            }
            return acc;
          },
          values.parameters?.reduce((acc, doc) => {
            if (doc.parameter_type !== 'path') {
              acc.push(doc);
            }
            return acc;
          }, []) || [],
        );
        setFieldValue('parameters', parameters);
      },
      size: 9,
    },
    method: {
      label: 'Method',
      type: 'autoComplete',
      field: 'method',
      options: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
      required: true,
      size: 3,
    },
    version: {
      field: 'api_version',
      label: 'Version',
      type: 'text',
      size: 3,
    },
    reference_entities: {
      label: 'Reference Models',
      field: 'reference_entities',
      type: 'multiAutoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: props => {
        const {searchValue, onChange} = props;
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange && onChange(data),
        });
      },
    },
    dataEntity: ({readOnly} = {}) => ({
      label: 'Model',
      field: 'model_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/assets/${PROJECT_MODEL_TYPE_ID}/suggestions`,
      suggestionField: 'model',
      valueField: 'model',
      searchFields: ['model'],
      onCreate: props => {
        const {searchValue, onChange} = props;
        navigation.navigate(`add-model`, {
          ...params,
          searchValue,
          afterSubmit: ({data}) => onChange && onChange(data),
        });
      },
      size: 6,
      readOnly,
    }),
    module: ({readOnly} = {}) => ({
      label: 'Module',
      type: 'autoComplete',
      api: '/projectmodules',
      filter: {project_id: project?._id},
      placeholder: 'Select',
      suggestionField: 'module',
      valueField: 'module',
      field: 'module_id',
      size: 6,
      readOnly,
    }),
    owner: {
      label: 'Owner',
      field: 'owner_id',
      type: 'autoComplete',
      api: `/projects/${project?._id}/members`,
      suggestionField: 'name',
      secondarySuggestionField: 'email',
      colorField: 'color',
      avatar: true,
      valueField: 'name',
      size: 6,
    },
    security: {
      label: 'Authentication',
      field: 'security_type',
      type: 'autoComplete',
      options: ['BasicAuth', 'BearerAuth', 'ApiKeyAuth'],
      size: 6,
    },
    description: {
      label: 'Description',
      type: 'aiTextArea',
      field: 'desc',
      inputProps: {
        minRows: 10,
        maxRows: 20,
      },
    },
    remarks: {
      label: 'Remarks',
      field: remarksField,
      type: 'richText',
      placeholder: 'Write remarks here...',
      minHeight: 200,
    },
    folder_path: {
      field: 'folder_path_id',
      label: 'Folder Path',
      type: 'autoComplete',
      api: `/repositoryFolderPaths`,
      suggestionField: 'path',
      secondarySuggestionField: 'name',
      valueField: 'path',
      filter: {
        type: 'api',
        project_id: project?._id,
      },
      onCreate: ({searchValue, onChange}) => {
        navigation.navigate(`add-repository-folder-path`, {
          ...params,
          repositoryType: 'Backend',
          defaultValues: {
            type: 'api',
            project_id: project?._id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      helperText: 'Select path on which file is placed',
    },
    file_name: {
      type: 'autoComplete',
      field: 'file_name',
      label: 'File Name',
      api: `/folderFiles`,
      suggestionField: 'name',
      valueField: 'name',
      filter: ({values}) => {
        return {
          folder_path_id: values?.folder_path_id,
        };
      },
      onCreate: ({searchValue, onChange, form: {values}}) => {
        navigation.navigate(`add-file-name`, {
          ...params,
          defaultValues: {
            folder_path_id: values?.folder_path_id,
            name: searchValue,
          },
          afterSubmit: ({data}) => onChange?.(data),
        });
      },
      size: 6,
      visible: ({values}) => values?.folder_path_id,
    },
  };
};

export const ApiEndpointForm = props => {
  const {
    navigation,
    route: {params},
    mode,
  } = props;

  const {row, project_id, module_id} = params;

  const fields = apiEndpointFields({params, navigation});

  const tabs = {
    basic_info: {
      label: 'Basic Info',
      layoutFields: [
        {
          ...fields.name,
          size: 9,
        },
        fields.method,
        fields.path,
        fields.version,
        fields.dataEntity({readOnly: mode}),
        fields.module({readOnly: mode}),
        fields.folder_path,
        fields.file_name,
        {
          collapsedFields: [
            fields.security,
            fields.owner,
            fields.reference_entities,
            fields.description,
          ],
        },
      ],
    },
    request: {
      label: 'Request',
      layoutFields: [
        {
          label: 'Request Body',
          field: 'requests',
          nested: true,
          visible: ({values}) =>
            ['POST', 'PUT', 'PATCH'].includes(values?.method),
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                header={'Add Request'}
                editFormHeader={readOnly ? 'Request Detail' : 'Edit Request'}
                columnVerticalAlignment={'top'}
                modalProps={{
                  size: 'medium',
                }}
                fields={[
                  {
                    label: 'Content Type',
                    field: 'request_body_type',
                    type: 'autoComplete',
                    options: Object.keys(HEADER_CONTENT_TYPES),
                    onChangeValue: (value, _, {setFieldValue}) => {
                      setFieldValue(
                        'request_body_format',
                        HEADER_CONTENT_TYPES[value],
                      );
                      if (value === 'application/json') {
                        setFieldValue('request_body', `{\n}`);
                      } else {
                        setFieldValue('request_body');
                      }
                    },
                    size: 6,
                  },
                  {
                    field: 'request_body_format',
                    label: 'Body format',
                    type: 'text',
                    readOnly: true,
                    size: 6,
                  },
                  {
                    field: 'request_body',
                    label: 'Body',
                    type: 'textArea',
                    inputProps: {
                      minRows: 14,
                      maxRows: 17,
                    },
                  },
                ]}
                columns={[
                  {
                    header: 'Content Type',
                    field: 'request_body_type',
                    type: 'text',
                    width: 150,
                  },
                  {
                    header: 'Detail',
                    render: RequestRender,
                  },
                ]}
              />
            );
          },
        },
        {
          field: 'parameters',
          nested: true,
          label: 'Parameters',
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                header={'Add Parameter'}
                editFormHeader={
                  readOnly ? 'Parameter Detail' : 'Edit Parameter'
                }
                modalProps={{
                  size: 'medium',
                }}
                columnVerticalAlignment={'top'}
                fields={[
                  {
                    label: 'Name',
                    type: 'text',
                    field: 'name',
                    required: true,
                    size: 6,
                  },
                  {
                    label: 'In',
                    field: 'parameter_type',
                    type: 'autoComplete',
                    options: ['path', 'query', 'header', 'cookie', 'body'],
                    required: true,
                    onChangeValue: (value, _, {setFieldValue}) => {
                      if (value === 'path') {
                        setFieldValue('type', 'text');
                        setFieldValue('required', true);
                      } else {
                        setFieldValue('type');
                        setFieldValue('required', false);
                      }
                    },
                    size: 6,
                  },
                  {
                    field: 'type',
                    label: 'Format',
                    type: 'autoComplete',
                    options: ['text', 'object'],
                    size: 6,
                  },
                  {
                    field: 'required',
                    type: 'switch',
                    label: 'Required',
                    size: 6,
                  },
                  {
                    field: 'parameters_desc',
                    label: 'Description',
                    type: 'textArea',
                    inputProps: {
                      minRows: 2,
                      maxRows: 4,
                    },
                  },
                ]}
                columns={[
                  {
                    label: 'Name',
                    render: NameRender,
                    width: 150,
                  },
                  {
                    header: 'Detail',
                    render: ParameterRender,
                  },
                ]}
              />
            );
          },
        },
      ],
    },
    response: {
      label: 'Responses',
      layoutFields: [
        {
          field: 'responses',
          nested: true,
          render: props => {
            const {
              form: {readOnly},
            } = props;
            return (
              <NestedTable
                {...props}
                editFormHeader={readOnly ? 'Response Detail' : 'Edit Response'}
                header={'Add Response'}
                columnVerticalAlignment={'top'}
                fields={[
                  {
                    label: 'Code',
                    header: 'Code',
                    type: 'text',
                    field: 'status_code',
                    required: true,
                    size: 6,
                    onChangeValue: (value, _, {setFieldValue}) => {
                      setFieldValue('resp_type');
                    },
                  },
                  {
                    visible: ({values}) => {
                      const {status_code} = values;
                      return status_code?.length;
                    },
                    label: 'Content Type',
                    field: 'resp_type',
                    type: 'autoComplete',
                    options: ({values, _parentValues: {responses}}) => {
                      const headerNotToShow = responses?.reduce(
                        (acc, {status_code, resp_type}) => {
                          if (status_code === values?.status_code) {
                            acc[resp_type] = 1;
                          }
                          return acc;
                        },
                        {},
                      );

                      return Object.keys(HEADER_CONTENT_TYPES)?.filter(
                        key => !headerNotToShow?.[key],
                      );
                    },
                    onChangeValue: (value, _, {setFieldValue}) => {
                      if (value === 'application/json') {
                        setFieldValue('response', `{\n}`);
                      } else {
                        setFieldValue('response');
                      }
                    },
                    size: 6,
                  },
                  {
                    label: 'Description',
                    header: 'Description',
                    field: 'status_desc',
                    type: 'text',
                  },

                  {
                    label: 'Response',
                    field: 'response',
                    type: 'textArea',
                    inputProps: {
                      minRows: 12,
                      maxRows: 15,
                    },
                  },
                ]}
                columns={[
                  {
                    header: 'Code',
                    type: 'text',
                    field: 'status_code',
                    width: 60,
                  },
                  {
                    header: 'Detail',
                    render: ResponseRender,
                  },
                ]}
              />
            );
          },
        },
      ],
    },
    remarks: {
      visible: !!mode,
      label: 'Remarks',
      layoutFields: [fields.remarks],
    },
    // code: {
    //   visible: () => row?.folder_path_id && row?.file_name,
    //   label: 'Code',
    //   layoutFields: [
    //     {
    //       field: 'api_code',
    //       placeholder: 'Write your api code here...',
    //       type: 'aiCode',
    //       height: 500,
    //       params: ({model_id, _id: controller_id}) => {
    //         return {
    //           model_id: model_id?._id,
    //           project_id,
    //           module_id,
    //           controller_id,
    //         };
    //       },
    //       entityName: 'projectController',
    //     },
    //   ],
    // },
  };

  return <Form submitAction="Save" tabs={tabs} type="tab" {...props} />;
};

export const AddApiEndpointForm = props => {
  const {
    route: {params},
  } = props;

  const {
    project,
    module,
    feature,
    model,
    searchValue,
    afterSubmit,
    dataEntityFilter,
  } = params;

  const {createAsset, requiredValidation} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
  });

  const initialValues = {
    status: 'active',
    controller: searchValue,
    request_body_type: 'application/json',
    resp_type: 'application/json',
    request_body_format: HEADER_CONTENT_TYPES['application/json'],
    request_body: `{\n}`,
    response: `{\n}`,
    project_id: project?._id,
    module_id: module,
    model_id: model || dataEntityFilter?.model_id,
    feature_id: feature?._id,
    source: PROJECT_CONTROLLER_TYPE_ID,
  };

  return (
    <ApiEndpointForm
      data={initialValues}
      header="Add API"
      onSubmit={createAsset}
      beforeSubmit={requiredValidation}
      afterSubmit={afterSubmit}
      defaultValues={initialValues}
      {...props}
    />
  );
};

export const ApiEndpointDetailForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {row, feature, readOnly} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
    feature_id: feature?._id,
    row,
  });

  return (
    <ApiEndpointForm
      key={row?._id}
      isDualMode
      readOnly={readOnly || row?.aiGenerated}
      mode="edit"
      header={{title: 'Api Detail', secondaryTitle: row?.controller}}
      onSubmit={updateHistory}
      submitAction="Save"
      data={row}
      beforeSubmit={assetBeforeSubmit({data: row})}
      {...props}
    />
  );
};

export const APIEndpointCodeEditorForm = props => {
  const {
    route: {params},
  } = props;

  const [loading, setLoading] = useState(false);
  const {row, project_id, module_id, readOnly, feature} = params;

  const {updateHistory, assetBeforeSubmit} = useAssetFormActions({
    source: PROJECT_CONTROLLER_TYPE_ID,
    feature_id: feature?._id,
    row,
  });
  const postInvoke = useInvoke({
    method: 'post',
  });
  return (
    <Form
      data={row}
      mode="edit"
      header={{title: 'Api Code', secondaryTitle: row?.controller}}
      skipDefaultSave
      footer={({styles, handleSubmit, values, onClose}) => {
        return {
          actions: [
            <SubmitButton label="Save" styles={styles.secondaryButton} />,
            <Button
              loading={loading}
              text="Save and Commit"
              onPress={async () => {
                setLoading(true);
                const {message} = await postInvoke({
                  uri: '/commitGitFile',
                  props: {
                    ...params,
                    fileContent: values?.api_code,
                    folder_path_id: values?.folder_path_id?._id,
                    file_name: values?.file_name?.name,
                    feature_id: values?.feature_id?._id,
                  },
                });
                if (message === 'Success') {
                  handleSubmit?.();
                  onClose?.();
                  setLoading(false);
                }
              }}
            />,
          ],
        };
      }}
      readOnly={readOnly || row?.aiGenerated}
      onSubmit={updateHistory}
      beforeSubmit={assetBeforeSubmit({data: row})}
      layoutFields={[
        {
          field: 'api_code',
          placeholder: 'Write your api code here...',
          type: 'aiCode',
          height: 280,
          is_committable: false,
          params: ({model_id, _id: controller_id}) => {
            return {
              model_id: model_id?._id,
              project_id,
              module_id,
              controller_id,
            };
          },
          entityName: 'projectController',
        },
      ]}
      {...props}
    />
  );
};
