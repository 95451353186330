import {Table} from '../../../components/table/Table';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {TabView} from '../../../components/tab/TabView';
import {
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {Chip} from '../../../components/chip/Chip';
import {leadControllers} from '../controllers/leadControllers';
import React from 'react';
import {
  AutoCompleteFilter,
  GroupFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {UploadButton} from '../buttons/Upload';
import {RenderLinkedInUrl, RenderName} from './CustomRendersCollection';
import {ErrorActionsTable} from './ActionsTable';
import {Button} from '../../../components/button/Button';

const ContactRender = props => (
  <CompositeFieldsRender
    {...props}
    fields={[
      {field: 'website', type: 'text'},
      {
        field: 'contact_email',
        type: 'text',
      },
    ]}
  />
);

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');
  const last_communication_date =
    row?.['potential_customer']?.['last_communication_date'];
  return (
    <Chip
      {...rest}
      color={INFORMATION_LOW}
      textColor={INFORMATION_HIGH}
      value={
        (last_communication_date &&
          moment(last_communication_date).format('DD MMM, YYYY')) ||
        '-'
      }
      suffix={<Image source={CommunicationBlue} style={{paddingLeft: 4}} />}
      borderRadius={6}
    />
  );
};

const NextTaskRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'potential_customer.next_task', type: 'text'},
        {
          field: 'potential_customer.next_task_date',
          type: 'date',
        },
      ]}
    />
  );
};

const DesignationRender = props => {
  const {row} = props;
  const {Message} = useTheme('icons');
  if (!row?.contact_id?.designation) {
    return null;
  }
  return (
    <Row>
      <Image
        source={Message}
        style={{paddingRight: 10}}
        width={20}
        height={20}
      />
      <Text
        style={{
          color: '#007AFF',
          fontSize: '14px',
          fontFamily: 'Inter-Regular',
          lineHeight: '20px',
          numberOfLines: 1,
        }}>
        {row?.contact_id?.designation}
      </Text>
    </Row>
  );
};

const NAME = {
  field: 'potential_customer.name',
  header: 'Name',
};

const DESIGNATION = {
  field: 'contact_id.designation',
  header: 'Designation',
};

const LINKEDIN = {
  type: 'link',
  field: 'contact_linkedIn_url',
  header: 'LinkedIn URL',
  linkText: 'LinkedIn Id',
};

const CREATED_ON = {
  type: 'date',
  field: 'added_on',
  header: 'Created On',
};

const REQUEST_SEND_DATE = {
  type: 'date',
  field: row => row?.campaign_connected_at?.connection_request_date,
  header: 'Requested On',
};

const EXPECTED_CONNECTION_DATE = {
  type: 'date',
  header: 'Outreach Duration Ends',
  field: 'campaign_connected_at.expected_connection_date',
};

const LAST_COMMUNICATION = communications => ({
  header: 'Communication',
  render: CommunicationRender,
  onPress: communications,
});

const messageCount = ({params, navigation, status}) => ({
  render: CountCellRender,
  width: 100,
  header: 'Actions',
  align: 'right',

  // Akshat Garg - 22/05/24
  count_field:
    typeof status === 'object' &&
    status.$in.find(item => item === 'nurturing' || item === 'connected')
      ? 'inmail_message_sent_count'
      : 'inmail_message_count',
  released_count_field: 'inmail_message_sent_count',
  responsive: 'sm',
  // onPlusPress: props => {
  //   const {row} = props;
  //   navigation.navigate(`outreach-campaign-contact-messages`, {
  //     campaign_contact_id: {_id: row._id, name: row.name},
  //     contact_id: {_id: row.contact_id},
  //     campaign_id: params.campaign_id,
  //   });
  // },
  onPress: props => {
    const {row} = props;
    navigation.navigate(`outreach-campaign-contact-messages`, {
      campaign_id: params.campaign_id,
      contact_id: {_id: row.contact_id},
      campaign_contact_id: {_id: row._id, name: row.contact_id.name},
    });
  },
});

export const OutreachCampaignContactsUnverified = props => {
  let {
    navigation,
    route: {params},

    searchValue,
  } = props;
  const {campaign_id} = params;

  let campaignContactsfilter = {
    api: `/outreachcampaigns/${campaign_id._id}/contacts`,
    filter: {website_summary_status: {$in: ['pending', 'unverified']}},
    params,
  };
  const {
    viewProspect,
    populateBusinessSummary,
    updateBusinessSummary,
    editCampaignContactProspect,
    updatePotentialCustomerBusinessSummaryBatch,
    deleteOutreachCampaignContacts,
    dropOutreachCampaignContacts,
    verifyBusinessSummary,
  } = leadControllers(props);

  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Populate Summary',
            onPress: updatePotentialCustomerBusinessSummaryBatch,
          },
          {
            title: 'Verify Summary',
            onPress: verifyBusinessSummary,
          },
        ],
      }}
      eventSourceId={[
        'communicationAdded',
        'reloadImportHistoryLogs',
        'populateBusiness',
        'droppedContact',
        'updateStatus',
        'potentialcustomerupdated',
        'summaryVerified',
        'markVerified',
      ]}
      search={searchValue}
      searchFields={[
        'contact_linkedIn_url',
        'contact_email',
        'website',
        'name',
      ]}
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 150,
        },
        {
          header: 'Designation',
          field: 'contact_id.designation',
          type: 'text',
          width: 150,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 150,
        },

        {
          header: 'Summary',
          field: 'potential_customer.website_summary',
          type: 'textArea',
        },
      ]}
      moreActions={[
        {
          title: 'Edit Contact',
          onPress: ({row}) => {
            navigation.navigate('edit-potential-customer', row);
          },
        },
        {
          title: 'View prospect',
          onPress: editCampaignContactProspect,
        },

        {
          title: 'Populate Summary',
          onPress: populateBusinessSummary,
        },
        {
          title: 'Update Summary',
          onPress: updateBusinessSummary,
        },

        {
          title: 'Update Status',
          onPress: ({row}) => {
            navigation.navigate('update-status', row);
          },
        },
        {
          title: 'Prospect',
          onPress: viewProspect,
        },
        {
          title: 'Delete',
          onPress: deleteOutreachCampaignContacts,
        },
        {
          title: 'Drop',
          onPress: dropOutreachCampaignContacts,
          visible: () => {
            return campaignContactsfilter?.filter?.status != 'dropped';
          },
        },
      ]}
      {...props}
    />
  );
};

export const OutreachCampaignContactsList = props => {
  let {
    navigation,
    route: {params},
    campaignContactsfilter,
    searchValue,
  } = props;

  let {potential_customer, campaign_id} = params || {};
  if (potential_customer?._id && campaign_id?._id) {
    campaignContactsfilter = {
      api: `/outreachcampaigns/${campaign_id._id}/contacts`,
      filter: {potential_customer},
      params,
    };
  }

  const {
    makeLeadFromCampaignContact,
    makeUqlFromCampaignContact,
    viewProspect,
    populateBusinessSummary,
    updateBusinessSummary,
    editCampaignContactProspect,
    updatePotentialCustomerBusinessSummaryBatch,
    deleteOutreachCampaignContacts,
    dropOutreachCampaignContacts,
    verifyBusinessSummary,
    setStatusToBeGenerated,
    populateContactMessages,
    viewCommunications,
    populateWebSiteBusinessSummary,
    fetchContactConnectionList,
    viewOutreachCampaignContactConnection,
  } = leadControllers(props);

  const status = campaignContactsfilter?.filter?.status;
  const campaign_name = campaignContactsfilter?.params?.campaign_id?.name;

  return (
    <Table
      selection={{
        actions: [
          {
            title: 'To be generated',
            onPress: setStatusToBeGenerated,
          },
          {
            title: 'Populate Summary',
            onPress: updatePotentialCustomerBusinessSummaryBatch,
          },
          {
            title: 'Verify Summary',
            onPress: verifyBusinessSummary,
          },
        ],
      }}
      eventSourceId={[
        'communicationAdded',
        'reloadImportHistoryLogs',
        'populateBusiness',
        'droppedContact',
        'updateStatus',
        'potentialcustomerupdated',
        'indexUpdated',
        'test website',
        'summaryVerified',
        'markVerified',
        'potentialcustomeradded',
        'updateStatus',
      ]}
      search={searchValue}
      searchFields={[
        'contact_linkedIn_url',
        'contact_email',
        'website',
        'name',
      ]}
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 150,
        },
        {
          header: 'Designation',
          field: 'contact_id.designation',
          type: 'text',
          width: 150,
        },
        // {
        //   header: 'Degree of Connection',
        //   field: 'degree_of_connection',
        //   type: 'text',
        //   width: 150,
        // },
        // Akshat Garg - 22/05/24 - Adding two more columns
        {
          header: 'Company size',
          field: 'potential_customer.employee_count.number',
          type: 'text',
          width: 150,
        },
        {
          header: 'Country',
          field: 'potential_customer.country.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 150,
        },

        // {
        //   header: 'Summary',
        //   field: 'potential_customer.website_summary',
        //   type: 'textArea',
        //   // width: 250,
        //   visible: () => {
        //     const status = campaignContactsfilter?.filter?.status;
        //     return (
        //       status === 'new' ||
        //       status === 'connection_request_sent' ||
        //       status === 'connected'
        //     );
        //   },
        // },

        {
          header: 'Reason',
          field: 'reason',
          type: 'text',
          width: 250,
          visible: () => {
            return campaignContactsfilter?.filter?.status === 'dropped';
          },
        },
        {
          header: 'First Reply',
          field: 'first_reply',
          type: 'text',
          visible: () => {
            return campaignContactsfilter?.filter?.status === 'reply_received';
          },
        },
        {
          header: 'First Reply On',
          field: 'first_reply_received_on',
          type: 'date',
          width: 150,
          visible: () => {
            return campaignContactsfilter?.filter?.status === 'reply_received';
          },
        },
        messageCount({navigation, params, status}),
        // Akshat Garg - 22/05/24 - Adding next action type and next action on in nurturing type
        {
          header: 'Next Action',
          field: 'next_campaign_action.action',
          type: 'text',
          align: 'right',
          width: 150,
          onPress: props => {
            const {row} = props;
            navigation.navigate('unverified-campaign-action-form', {
              ...row?.next_campaign_action,
              campaign_name,
              data: [
                {
                  ...row.next_campaign_action,
                  contact_id: row?.contact_id,
                  potential_customer: row?.potential_customer,
                  nextCampaignActiondetail: true,
                },
              ],
            });
          },
          visible: () => {
            const status = campaignContactsfilter?.filter?.status;
            return (
              typeof status === 'object' &&
              status.$in.find(
                item => item === 'nurturing' || item === 'connected',
              )
            );
          },
        },
        {
          header: 'Planned Date',
          field: 'next_campaign_action.scheduledOn',
          type: 'date',
          align: 'right',
          width: 150,
          formatOptions: {
            format: 'DD MMM',
          },
          visible: () => {
            const status = campaignContactsfilter?.filter?.status;
            return (
              typeof status === 'object' &&
              status.$in.find(
                item => item === 'nurturing' || item === 'connected',
              )
            );
          },
        },
        {
          header: 'Scheduled Date',
          field: 'next_campaign_action.actualScheduledOn',
          type: 'date',
          align: 'right',
          width: 150,
          formatOptions: {
            format: 'DD MMM',
          },
          visible: () => {
            const status = campaignContactsfilter?.filter?.status;
            return (
              typeof status === 'object' &&
              status.$in.find(
                item => item === 'nurturing' || item === 'connected',
              )
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit Contact',
          onPress: ({row}) => {
            navigation.navigate('edit-potential-customer', row);
          },
        },
        {
          title: 'View prospect',
          onPress: editCampaignContactProspect,
        },
        {
          title: 'Populate messages',
          onPress: populateContactMessages,
        },
        {
          title: 'View communication',
          onPress: viewCommunications,
        },
        {
          title: 'Populate Summary',
          onPress: populateBusinessSummary,
        },
        {
          title: 'Test WebSite',
          onPress: populateWebSiteBusinessSummary,
        },
        {
          title: 'Update Summary',
          onPress: updateBusinessSummary,
        },

        {
          title: 'Move to QL',
          onPress: makeLeadFromCampaignContact,
        },
        {
          title: 'Move to UQL',
          onPress: makeUqlFromCampaignContact,
        },
        {
          title: 'Prospect',
          onPress: viewProspect,
        },
        {
          title: 'Delete',
          onPress: deleteOutreachCampaignContacts,
        },
        {
          title: 'Drop',
          onPress: dropOutreachCampaignContacts,
          visible: () => {
            return campaignContactsfilter?.filter?.status != 'dropped';
          },
        },
        {
          title: 'Update Status',
          onPress: ({row}) => {
            navigation.navigate('update-status', row);
          },
        },
        {
          title: 'fetch Connections',
          onPress: fetchContactConnectionList,
        },
        {
          title: 'Show Connection List',
          onPress: viewOutreachCampaignContactConnection,
        },
      ]}
      {...props}
    />
  );
};

const OutreachCampaignWithdrawTable = props => {
  let {campaignContactsfilter} = props;

  return (
    <Table
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 150,
        },
        {
          header: 'Designation',
          field: 'contact_id.designation',
          type: 'text',
          width: 150,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 150,
        },
        {
          header: 'Withdrawal On',
          field: 'con_withdrawal_on',
          type: 'date',
          width: 250,
        },
      ]}
      {...props}
    />
  );
};

const OutreachCampaignErrorTable = props => {
  let {campaignContactsfilter} = props;
  const {viewLinkedinProfile} = leadControllers(props);

  return (
    <Table
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Contact',
          render: RenderLinkedInUrl,
          width: 150,
        },
        {
          header: 'Designation',
          field: 'contact_id.designation',
          type: 'text',
          width: 150,
        },
        {
          header: 'Website',
          render: RenderName,
          width: 150,
        },
        {
          header: 'Error',
          field: 'error',
          type: 'text',
        },
        {
          header: 'Error On',
          field: 'error_on',
          type: 'date',
        },
      ]}
      moreActions={[
        {
          title: 'View Profile',
          onPress: viewLinkedinProfile,
        },
      ]}
      {...props}
    />
  );
};

const OutreachTargetAudienceContactsList = props => {
  const {
    route: {params},
    campaignContactsfilter,
    searchValue,
  } = props;
  const {fromInmailCampaign, fromEmailCampaign} = params;
  const {viewProspect, deleteOutreachTargetAudienceContacts} =
    leadControllers(props);
  return (
    <Table
      eventSourceId={[
        'communicationAdded',
        'reloadImportHistoryLogs',
        'markVerified',
        'droppedContact',
      ]}
      search={searchValue}
      searchFields={['contact_linkedIn_url', 'contact_email', 'website']}
      {...campaignContactsfilter}
      columns={[
        {
          header: 'Name',
          render: RenderName,
          width: 250,
        },
        {
          header: 'Designation',
          render: DesignationRender,
          width: 250,
        },
      ]}
      moreActions={[
        {
          title: 'Prospect',
          onPress: viewProspect,
        },
        {
          title: 'Delete',
          onPress: deleteOutreachTargetAudienceContacts,
        },
      ]}
      {...props}
    />
  );
};

export const OutreachCampaignContactTabs = props => {
  let {route: {params} = {}, navigation} = props;
  let {filter = {}, row, campaign_id} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter(
    {},
  );

  let {filter: filters = {}, params: filterParams = {}} = filterValues || {};

  filter = {...filter, ...filters};

  params = {...params, ...filterParams};

  if (searchValue) {
    params = {
      ...params,
      search: searchValue,
      searchFields: [
        'contact_linkedIn_url',
        'contact_email',
        'website',
        'name',
      ],
    };
  }

  let campaignContactsfilter = {
    api: `/outreachcampaigns/${campaign_id._id}/contacts`,
    filter: filter,
    params,
  };

  let addOnFilter = {website_summary_status: 'verified'};

  if (campaign_id?.campaign_method === 'Manual') {
    addOnFilter = {};
  }

  const tabs = {
    new: {
      label: `Not started`,
      view: (
        <OutreachCampaignContactsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: 'new',
              // website_summary_status: 'verified',
              ...addOnFilter,
              error: null,
            },
            sort: {potential_customer: 1},
          }}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.params,
        status: 'new',
        // website_summary_status: 'verified',
        ...addOnFilter,
        error: null,
      },
      eventSourceId: ['markVerified', 'droppedContact'],
    },
    connection_request_sent: {
      label: `Connection sent`,
      view: (
        <OutreachCampaignContactsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: 'connection_request_sent',
              error: null,
            },
          }}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        status: 'connection_request_sent',
        error: null,
      },
    },
    connected: {
      label: `Nurturing`,
      view: (
        <OutreachCampaignContactsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: {$in: ['connected', 'nurturing']},
              error: null,
            },
          }}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        status: {$in: ['connected', 'nurturing']},
        error: null,
      },
      eventSourceId: ['updateStatus'],
    },
    reply_received: {
      label: `Reply received`,
      view: (
        <OutreachCampaignContactsList
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: 'reply_received',
              error: null,
            },
          }}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        status: 'reply_received',
        error: null,
      },
      eventSourceId: ['updateStatus'],
    },
    error: {
      label: `Error`,
      view: <ErrorActionsTable searchValue={searchValue} />,
      api: '/campaignactions',
      filter: {campaign_id: params?.campaign_id?._id, status: 'error'},
      eventSourceId: [
        'indexUpdated',
        'removeError',
        'markVerified',
        'droppedContact',
      ],
    },
    withdraw: {
      label: `Withdraw`,
      view: (
        <OutreachCampaignWithdrawTable
          campaignContactsfilter={{
            ...campaignContactsfilter,
            filter: {
              ...campaignContactsfilter.filter,
              status: 'withdrawal',
            },
            sort: {con_withdrawal_on: -1},
          }}
        />
      ),
      ...campaignContactsfilter,
      filter: {
        ...campaignContactsfilter.filter,
        status: 'withdrawal',
      },
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Summary',
              options: [
                {label: 'Pending', value: 'pending'},
                {label: 'To be Generated', value: 'to_be_generated'},
                {label: 'Unverified', value: 'unverified'},
                {label: 'Verified', value: 'verified'},
                {label: 'Error', value: 'error'},
              ],
              placeholder: 'Select',
              valueField: 'label',
              keyField: 'value',
              suggestionField: 'label',

              field: 'website_summary_status',
              filter: {campaign_id: campaign_id._id},
              asParam: true,
            },
            {
              type: 'autoComplete',
              label: 'status',
              options: [
                {label: 'locked', value: 'locked'},
                {label: 'rejected', value: 'rejected'},
                {label: 'free', value: 'free'},
              ],
              placeholder: 'Select',
              valueField: 'label',
              keyField: 'value',
              label: 'status',
              suggestionField: 'label',

              field: 'status',
              filter: {campaign_id: campaign_id._id},
              asParam: true,
            },
            {
              type: 'date',
              field: 'next_action_date',
              asParam: true,
              label: 'Date',
            },
          ]}
        />,
        // <UploadButton props={props} navigation={navigation} />,
        // <AutoCompleteFilter
        //   field="potential_customer"
        //   api="/potentialCustomers"
        //   searchField="name"
        //   suggestionField="name"
        //   placeholder="Select Potential Customer"
        //   valueField="name"
        //   onChangeFilter={onChangeFilter}
        //   filterValues={filterValues}
        // />,
        // <MoreActions
        //   actions={[
        //     {
        //       title: 'Import Inmail Prospects',
        //       onPress: () => {
        //         navigation.navigate('import-list', {
        //           ...props?.route?.params,
        //           source: 'linkedin seed contacts',
        //           model: 'PotentialCustomer',
        //         });
        //       },
        //     },
        //   ]}
        // />,
        <Button
          text={'Manage Prospects'}
          gap={10}
          onPress={() => {
            navigation.navigate('campaign-prospects-tab', {
              ...props?.route?.params,
            });
          }}
        />,
      ]}
    />
  );
};

export const OutreachTargetAudienceContactTabs = props => {
  const {route: {params} = {}, navigation} = props;
  let {filter = {}, row, target_audience} = params;
  const {onChangeFilter, searchValue, applyFilter, filterValues} = useFilter();

  let {filter: filters = {}} = filterValues || {};
  filter = {...filter, ...filters};
  const campaignContactsfilter = {
    api: `/targetaudiences/${target_audience._id}/contacts`,
    filter: filter,
    params,
  };

  const tabs = {
    active: {
      label: `Contacts`,
      view: (
        <OutreachTargetAudienceContactsList
          campaignContactsfilter={campaignContactsfilter}
          searchValue={searchValue}
        />
      ),
      ...campaignContactsfilter,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <AutoCompleteFilter
          field="potential_customer"
          api="/potentialCustomers"
          searchField="name"
          suggestionField="name"
          placeholder="Select Potential Customer"
          valueField="name"
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />,
        <MoreActions
          actions={[
            {
              title: 'Import TargetAudience Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'target audience linkedin seed contacts',
                  model: 'PotentialCustomer',
                });
              },
            },
          ]}
        />,
      ],
    },
  };

  return <TabView tabs={tabs} params={params} {...props} />;
};
