import React from 'react';
import {LeftMenuView} from '../../../components/menu/LeftMenuView';
import {RecurringTaskAssignmentTabs} from '../../task/views/RecurringTaskAssignmentTabs';
import {ReviewTasksTabs} from '../../task/views/ReviewTasksTabs';
import {TeamTaskOutputTabs} from './TeamTaskOutputTabs';
import {BacklogOrganizationTaskTable} from '../../task/views/BacklogOrganizationTaskTable';
import {ArchivedOrganizationTaskTable} from '../../task/views/ArchivedOrganizationTaskTable';
import {OrganizationActiveTasksGroupByPlan} from '../../task/views/OrganizationActiveTasksGroupByPlan';

export const TeamTaskMenus = props => {
  const {route: {params} = {}} = props;
  const {employee_team, userId} = params;

  const menus = [
    {
      label: 'Backlog',
      view: <BacklogOrganizationTaskTable />,
      api: '/organizationtasks',
      params: {teamId: employee_team?._id, userId},
      eventSourceId: 'Task',
      filter: {
        status: 'backlog',
        type: {
          $in: ['Task', 'Continuous'],
        },
        project_id: null,
      },
    },
    {
      label: 'Active',
      view: <OrganizationActiveTasksGroupByPlan />,
      api: '/organizationtasks',
      params: {teamId: employee_team?._id, userId},
      eventSourceId: 'Task',
      filter: {
        status: 'active',
        type: {
          $in: ['Task', 'Continuous'],
        },
        project_id: null,
      },
    },
    // {
    //   label: 'Strategic',
    //   view: <StrategicTaskTabs />,
    //   api: '/organizationtasks',
    //   params: {teamId: employee_team?._id, userId},
    //   eventSourceId: 'Task',
    //   filter: {
    //     status: {$in: ['active', 'backlog']},
    //     is_strategic: true,
    //     type: {
    //       $in: ['Task', 'Continuous'],
    //     },
    //     project_id: null,
    //   },
    // },
    {
      label: 'Review Pending',
      view: <ReviewTasksTabs />,
    } ,
    {
      label: 'Completed',
      view: <TeamTaskOutputTabs />,
    },
    {
      label: 'Archived',
      view: <ArchivedOrganizationTaskTable />,
      api: '/organizationtasks',
      params: {teamId: employee_team?._id, userId},
      filter: {
        status: 'archived',
        type: {
          $in: ['Task', 'Continuous'],
        },
        project_id: null,
      },
      eventSourceId: ['Task'],
    },
    {
      label: 'Recurring',
      view: <RecurringTaskAssignmentTabs />,
      api: '/mastertaskassignments',
      eventSourceId: 'MasterTaskAssignment',
      filter: {
        status: 'Active',
        parent_model_relation: 'Lifetime',
        employee_team: employee_team?._id,
        parent_task_assignment: null,
      },
    } ,
  ];

  return <LeftMenuView {...props} params={params} menus={menus} />;
};
