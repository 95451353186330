import React from 'react';
import {InlineForm} from '../../../../components/form/Form';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {PROJECT_CONTROLLER_TYPE_ID} from '../../../common/constants/SourceConstants';
import {SplitScreen} from '../../../../components/SplitScreen';
import {ApiEndpointDetailForm} from './ApiEndPointForm';
import {
  AssetAddButton,
  ExportExcelAction,
  FeatureAssetHeaderRow,
  assetMappings,
  featureAssetColumns,
  useAssetActions,
} from './AssetUtility';
import {ApiEndPointsTable, apiEndpointExportParams} from './ApiEndPointsScreen';
import {TabView} from '../../../../components/tab';
import {useFilter} from '../../../../controllers/useFilter';
import {TableHeader} from '../../../../components/table/Headers';
import {SearchFilter} from '../../../../components/filter/Filters';
import {AISuggestionsButton} from '../../cellrenders/AISuggestionRenders';
import {Table} from '../../../../components/table/Table';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {GroupBy} from '../../../../components/table/GroupBy';

const ApiEndpointInlineForm = props => {
  const {
    route: {params},
    navigation,
    sourceIds,
  } = props;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Controller',
    uri: '/projectAssetVersions',
    ...props,
  });

  const {project, feature, module} = params || {};

  const defaultValues = {
    source: PROJECT_CONTROLLER_TYPE_ID,
    project_id: project?._id,
    feature_id: feature?._id,
    module_id: module?._id,
    is_changed: false,
  };

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Name',
          field: 'source_id',
          type: 'autoComplete',
          api: `/projects/${project?._id}/assets/${PROJECT_CONTROLLER_TYPE_ID}/suggestions`,
          suggestionField: 'controller',
          valueField: 'controller',
          searchFields: ['controller'],
          required: true,
          filter: {_id: {$nin: sourceIds}},
          onCreate: ({searchValue}) => {
            navigation.navigate(`add-controller`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};
const uri = {
  type: 'text',
  field: 'uri',
  header: 'Path',
  width: 250,
};

const model = {
  type: 'colorTag',
  field: 'model_id.model',
  colorField: 'model_id.color',
  header: 'Model',
  width: 150,
};

const method = {
  type: 'colorTag',
  field: 'method',
  header: 'Method',
  width: 80,
  align: 'center',
};

const EVENT_SOURCE_ID = [
  'Feature',
  'Task',
  'Controller',
  'DataService',
  'MapUserstory',
];

export const FeaturesApiEndpointTable = props => {
  const {
    navigation,
    route: {params},
    isRowActive,
    selectedId,
  } = props;

  const {feature} = params;

  const tableProps = {
    api: `/features/${feature?._id}/assets/${PROJECT_CONTROLLER_TYPE_ID}/asset-data`,
  };

  const {
    assetDetail,
    tasks,
    removeFromFeature,
    versions,
    changeModule,
    changeDataEntity,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_CONTROLLER_TYPE_ID,
    feature_id: feature?._id,
    eventSourceId: EVENT_SOURCE_ID,
  });

  const assetColumns = featureAssetColumns({
    navigation,
    params,
    api: '/projectControllers',
    source: PROJECT_CONTROLLER_TYPE_ID,
  });

  return (
    <Table
      isRowActive={isRowActive}
      renderHeader={headerProps => (
        <FeatureAssetHeaderRow
          feature={feature}
          assetId={PROJECT_CONTROLLER_TYPE_ID}
          assetInlineForm={<ApiEndpointInlineForm {...props} />}
          eventSourceId={EVENT_SOURCE_ID}
          exportParams={apiEndpointExportParams({
            ...tableProps,
            fromFeature: true,
          })}
          {...headerProps}
        />
      )}
      eventSourceId={EVENT_SOURCE_ID}
      selection={{
        actions: [updateModuleMultipleAssets, updateDataEntityMultipleAssets],
      }}
      columns={[
        {
          type: 'text',
          field: 'controller',
          header: 'Name',
        },
        uri,
        method,
        model,
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.api_services_count,
        !selectedId && assetColumns.tasks({onPress: tasks}),
        !selectedId && assetColumns.est_hrs,
        !selectedId && assetColumns.assignees,
        !selectedId && assetColumns.code_editor,
      ]}
      onRowPress={assetDetail}
      moreActions={[
        versions,
        changeModule,
        changeDataEntity,
        {
          title: 'Generate Code',
          visible: ({row}) => row?.folder_path_id && row?.file_name,
          onPress: ({row}) => {
            navigation.navigate('api-endpoint-code-editor', {
              ...params,
              row,
              readOnly: feature.status === 'released',
            });
          },
        },
        removeFromFeature,
      ]}
      {...tableProps}
    />
  );
};

const FeatureApiEndpointScreen = props => {
  return (
    <SplitScreen splitScreen={<ApiEndpointDetailForm {...props} />}>
      <FeaturesApiEndpointTable {...props} />
    </SplitScreen>
  );
};

export const AllFeatureApiEndpointScreen = props => {
  const {
    route: {params},
    api,
    groupBy,
  } = props;

  const {
    project,
    feature,
    module,
    dataEntityFilter,
    fromDataEntity,
    api: apiParams,
    dataEntity,
  } = params;

  const entityId = dataEntity?._id;

  const filterProps = useFilter({});
  const {searchValue, filterValues, onChangeFilter} = filterProps;

  const sourceField = assetMappings[PROJECT_CONTROLLER_TYPE_ID].sourceField;

  const filter = {...dataEntityFilter};

  if (!fromDataEntity) {
    filter.module_id = module?._id;
  }

  const tableProps = {
    api:
      apiParams ||
      api ||
      `/projects/${project?._id}/features/${feature?._id}/asset/${PROJECT_CONTROLLER_TYPE_ID}/all-data`,
    addOnFilter: {
      project_id: project?._id,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: sourceField,
    filter,
    params: {
      show_in_development: !fromDataEntity && !feature?._id,
    },
    limit: groupBy ? 10000 : 50,
  };

  const renderHeader = ({selectedIds}) => {
    return (
      <TableHeader
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          !module?._id && <GroupBy {...groupBy} />,
          !fromDataEntity && (
            <ExportExcelAction
              selectedIds={selectedIds}
              params={apiEndpointExportParams({
                ...tableProps,
                fromFeature: true,
              })}
            />
          ),
          <AssetAddButton
            title={'API'}
            view={'add-controller'}
            params={{...params, dataEntityFilter}}
          />,
          entityId && module && feature && (
            <AISuggestionsButton
              {...props}
              entityName="projectController"
              filter={{
                entity_id: entityId,
                module_id: module._id,
              }}
              params={{
                parent_entity: 'Module',
                parent_entity_id: module._id,
                project_id: project._id,
                module_id: module._id,
                entity_category: 'EntityWiseApi',
                entity_category_prompts: [
                  {
                    category: 'Detail',
                    prompt_name: 'NewDetailSuggestion',
                  },
                ],
                model_id: entityId,
              }}
              initialValues={{
                status: 'active',
                project_id: project._id,
                module_id: module._id,
                source: PROJECT_CONTROLLER_TYPE_ID,
                feature_id: feature?._id,
                model_id: entityId,
              }}
              eventSourceId={['DataEntityAi', 'ApiEndpointAi', 'Model']}
            />
          ),
        ]}
      />
    );
  };

  return (
    <SplitScreen splitScreen={<ApiEndpointDetailForm {...props} />}>
      <ApiEndPointsTable
        {...props}
        renderHeader={renderHeader}
        groupBy={groupBy}
        {...tableProps}
        fromFeature
        fromAllView
      />
    </SplitScreen>
  );
};

export const FeatureApiEndpointTab = props => {
  const {
    route: {params},
  } = props;

  const {project, feature, module} = params;
  return (
    <TabView
      {...props}
      tabs={{
        active: {
          label: 'In Development',
          view: <FeatureApiEndpointScreen />,
        },
        all: {
          label: 'All',
          view: <AllFeatureApiEndpointScreen />,
          actions: feature?._id
            ? [
                project?.ai_enabled && module && feature && (
                  <AISuggestionsButton
                    {...props}
                    entityName="projectController"
                    filter={{
                      module_id: module._id,
                    }}
                    params={{
                      parent_entity: 'Module',
                      parent_entity_id: module._id,
                      project_id: project._id,
                      module_id: module._id,
                      label: 'APIs',
                      entity_category_prompts: [
                        {
                          category: 'Detail',
                          prompt_name: 'NewDetailSuggestion',
                          label: 'APIs Detail',
                        },
                      ],
                    }}
                    initialValues={{
                      status: 'active',
                      project_id: project._id,
                      module_id: module._id,
                      source: PROJECT_CONTROLLER_TYPE_ID,
                      feature_id: feature?._id,
                    }}
                    eventSourceId="ApiEndpointAi"
                  />
                ),
              ]
            : [],
        },
      }}
    />
  );
};
