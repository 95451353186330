import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';

export const ProjectEnvironmentForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectEnvironments',
    eventSourceId: 'Environments',
    ...props,
  });

  let {
    mode,
    navigation,
    route: {params},
  } = props;

  const {project, deployment_environment, afterSubmit, searchValue} =
    params || {};

  const defaultValues = {
    project_id: project?._id,
    environment: searchValue,
  };

  return (
    <Form
      api={`/projectEnvironments/${deployment_environment?._id}`}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      fields={{
        environment: 1,
        label: 1,
        source_environment: {label: 1},
        target_environment: {label: 1},
        branch: 1,
        is_feature_source: 1,
        is_dynamic_branch: 1,
        is_self_testing: 1,
        feature_branch_merge_to: {label: 1},
      }}
      beforeSubmit={({data}) => {
        if (!data.is_feature_source && data.feature_branch_merge_to) {
          return {
            data: {
              ...data,
              feature_branch_merge_to: [],
            },
          };
        } else {
          return {
            data,
          };
        }
      }}
      layoutFields={[
        [
          {
            type: 'text',
            field: 'label',
            label: 'Environment',
            required: true,
            validate: values => {
              if (values.includes(' ')) {
                return "Environment can't have spaces.";
              }
            },
            onChangeValue: (value, e, {setFieldValue}) => {
              setFieldValue('environment', value.toLowerCase());
            },
          },
          {
            type: 'text',
            field: 'branch',
            label: 'Branch Name',
            required: true,
            readOnly: mode === 'edit',
          },
          {
            label: 'Source',
            field: 'source_environment',
            type: 'autoComplete',
            api: `/projectEnvironments`,
            suggestionField: 'label',
            valueField: 'label',
            filter: ({values}) => {
              return {
                project_id: project?._id,
                environment: {$ne: values.environment},
              };
            },
            visible: ({values}) => values.environment !== 'production',
            required: true,
          },
          {
            label: 'Merge To',
            field: 'target_environment',
            type: 'multiAutoComplete',
            api: `/projectEnvironments`,
            suggestionField: 'label',
            valueField: 'label',
            filter: ({values}) => {
              return {
                project_id: project?._id,
                environment: {$ne: values.environment},
              };
            },
            visible: ({values}) => values.environment !== 'production',
          },
        ],
        {
          label: 'Feature Source Branch',
          type: 'checkbox',
          field: 'is_feature_source',
        },
        {
          label: 'Feature Merge To',
          type: 'multiAutoComplete',
          field: 'feature_branch_merge_to',
          api: `/projectEnvironments`,
          filter: {project_id: project?._id},
          suggestionField: 'label',
          valueField: 'label',
          fields: {
            label: 1,
          },
          visible: ({values}) => values.is_feature_source,
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const ProjectEnvironmentAddForm = props => {
  return <ProjectEnvironmentForm header="Add Environment" {...props} />;
};

export const ProjectEnvironmentUpdateForm = props => {
  return (
    <ProjectEnvironmentForm
      mode="edit"
      header="Update Environment"
      {...props}
    />
  );
};

export const UploadEnvFile = props => {
  const {route: {params = {}} = {}} = props;
  const {environment, repository} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/repositoryEnvironments/${environment?._id}/secretEnvs`,
    eventSourceId: 'Environments',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{docker_file: environment.docker_file}}
      header={{
        title: 'Add Docker and Environment',
        secondaryTitle: `${environment?.environment_id?.label} (${repository?.repository})`,
      }}
      layoutFields={[
        {
          label: 'Env file',
          type: 'file',
          field: 'envFile',
          inline: true,
          placeholder: 'Upload env file',
        },
        {
          label: 'Docker file',
          type: 'file',
          field: 'docker_file',
          placeholder: 'Upload your Docker file',
        },
      ]}
    />
  );
};

export const MapDomain = props => {
  const {route: {params = {}} = {}} = props;
  const {environment, repository} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/repositoryEnvironments/${environment?._id}/mapDomain`,
    eventSourceId: 'Environments',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header={{
        title: 'Map your domain',
        secondaryTitle: `${environment?.environment_id?.label} (${repository?.repository})`,
      }}
      layoutFields={[
        {
          label: 'Domain',
          type: 'text',
          field: 'domain',
          required: true,
        },
      ]}
    />
  );
};

export const JenkinsSetupForm = props => {
  const {route: {params = {}} = {}} = props;
  const {project_id, repository_id, repository_type, environment, repository} =
    params;

  let {onSubmit} = useFormSubmit({
    uri: `/repositoryEnvironments/${environment?._id}/jenkinsSetup`,
    eventSourceId: 'Environments',
  });

  const defaultValues = {
    project_id,
    repository_id,
    port:
      environment?.port || (repository_type === 'Frontend' ? '2000' : '4010'),
    docker_repo_name: 'rhjadeja004',
    create_new_server: repository_type === 'Backend' ? true : false,
    cloud_infra_id: environment.cloud_infra_id,
    create_new_server: environment.create_new_server,
    passPercentage: 80,
  };

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      header={{
        title: 'Create Jenkins Pipeline',
        secondaryTitle: `${environment?.environment_id?.label} (${repository?.repository})`,
      }}
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Cloud',
          type: 'autoComplete',
          field: 'cloud_infra_id',
          api: '/cloudInfrastructures',
          filter: {project_id},
          suggestionField: 'cloud',
          valueField: 'cloud',
          secondarySuggestionField: 'cloud_service',
          required: true,
        },
        {
          label: 'Testcase pass percentage',
          type: 'number',
          field: 'passPercentage',
          required: true,
          validate: value => {
            if (value < 0 || value > 100) {
              return 'Pass percentage must be between 0-100';
            }
          },
        },
        {
          label: 'Port',
          field: 'port',
          type: 'number',
          required: true,
          size: 4,
        },
        {
          label: 'Create new server',
          type: 'checkbox',
          field: 'create_new_server',
        },
      ]}
    />
  );
};

export const SyncJenkinPipeline = props => {
  const {navigation, route: {params = {}} = {}} = props;
  const {project_id, environment, repository} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/repositoryEnvironments`,
    eventSourceId: 'Environments',
    method: 'put',
  });

  return (
    <Form
      header={{
        title: 'Edit Jenkins',
        secondaryTitle: `${environment?.environment_id?.label} (${repository?.repository})`,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      mode={'edit'}
      defaultValues={{_id: environment?._id}}
      api={`/repositoryEnvironments/${environment?._id}`}
      fields={{
        deployment_platform_id: {url: 1, platform: 1},
        jenkins_deployment_url: 1,
        pipeline: 1,
        child_pipeline_response: 1,
      }}
      layoutFields={[
        {
          label: 'Platform',
          type: 'autoComplete',
          api: '/deploymentPlatforms',
          filter: {project_id},
          field: 'deployment_platform_id',
          suggestionField: 'platform',
          valueField: 'platform',
          secondarySuggestionField: 'url',
          required: true,
          onCreate: ({form: {values}, searchValue, onChange}) => {
            const {type, vcs} = values;
            navigation.navigate(`deployment-platform-add-form`, {
              ...params,
              type,
              vcs,
              is_deployable: false,
              searchValue,
              afterSubmit: ({data}) => onChange?.(data),
              is_dependent_repository: true,
            });
          },
        },
        {
          label: 'Job/Pipeline Name',
          type: 'text',
          field: 'pipeline',
          required: true,
        },
        {
          label: 'Deployment URL',
          type: 'text',
          field: 'jenkins_deployment_url',
        },
        {
          label: 'Deployment Status Webhook',
          type: 'text',
          field: 'child_pipeline_response',
          readOnly: true,
        },
      ]}
    />
  );
};

const regions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
];

export const InfrastructureSetup = props => {
  const {route: {params = {}} = {}} = props;
  const {project_id, environment, project} = params;

  let {onSubmit} = useFormSubmit({
    uri: `/setupInfrastructure`,
    eventSourceId: 'Environments',
  });

  return (
    <Form
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        project: project.project,
        project_code: project.code,
        repo_env_id: environment?._id,
        repoEnvironment: environment,
      }}
      header={{
        title: 'Setup Infrastructure',
        secondaryTitle: environment?.environment_id?.label,
      }}
      layoutFields={[
        {
          label: 'Port',
          type: 'text',
          field: 'port',
          required: true,
        },
        {
          field: 'region',
          type: 'autoComplete',
          label: 'Region',
          options: regions,
          required: true,
          size: 6,
          helperText: `Choose the AWS region where your resources will be located`,
        },
        {
          label: 'AWS access key',
          type: 'text',
          field: 'aws_access_key',
          required: true,
        },
        {
          label: 'AWS secret key',
          type: 'text',
          field: 'aws_secret_key',
          required: true,
        },
      ]}
    />
  );
};
