import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useInvoke} from '../../../controllers/useInvoke';
import { Linking, Text, TouchableOpacity } from '@unthinkable/react-core-components';
import { useTheme } from '@react-navigation/native';


const RenderName = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const {colors} = useTheme('colors');
  const color = row?.url ? {color: colors['primary']} : void 0;
  return (
    <TouchableOpacity
      onPress={() => {
        row?.url &&
          Linking.openURL(
            row?.url.startsWith('https') ? row?.url : `https://${row?.url}`,
            '_blank',
          );
      }}>
      <Text
        title={row?.name}
        style={{
          ...rowText,
          ...color,
        }}>
        {row?.name}
      </Text>
    </TouchableOpacity>
  );
};
const editTrainingMaterial = ({navigation, params}) => ({
  title: 'Edit',
  onPress: props => {
    const {row} = props;
    let {skill_topic, skill_metrics} = row || {};
    navigation.navigate('edit-training-material', {
      skill_topic,
      skill_metrics,
      training_material: {
        _id: row?._id,
      },
    });
  },
});
const deleteTrainingMaterial = ({invoke}) => ({
  title: 'Delete',
  variant: 'error',
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/trainingmaterials/${row?._id}`,
      props: {
        status: 'Inactive',
      },
    });
  },
  confirm: {
    title: 'Mark Delete',
    message: 'Are you sure you want to Delete?',
  },
});

const TrainingMaterialListWithInlineForm = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {skill_metrics} = params;
  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter(
    {},
  );
  let {filter = {}} = filterValues || {};
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['StudyMaterials'],
  });
  const inlineForm = props => {
    const {
      navigation,
      route: {params},
    } = props;

    let {onSubmit} = useFormSubmit({
      eventSourceId: 'StudyMaterials',
      uri: '/trainingMaterials',
      ...props,
    });
    let defaultValues = {
      skill_metrics: skill_metrics?._id,
      status: 'Active',
    };
    return (
      <InlineForm
        onSubmit={onSubmit}
        submitAction="Save"
        defaultValues={defaultValues}
        layoutFields={[
          {
            label: 'Material Name',
            field: 'name',
            type: 'text',
            required: true,
          },
          {
            label: 'Material Link',
            field: 'url',
            type: 'text',
            required: true,
          },
          {
            label: 'Topic',
            placeholder: 'Topic',
            field: 'skill_topic',
            type: 'autoComplete',
            api: '/skilltopics',
            suggestionField: 'name',
            valueField: 'name',
            filter: {
              skill_metrics: skill_metrics?._id,
            },
            onCreate: ({searchValue, onChange}) => {
              navigation.navigate(`add-skill-topic`, {
                ...params,
                searchValue,
                afterSubmit: ({data}) => onChange && onChange(data),
              });
            },
          },
          {
            label: 'Category',
            placeholder: 'Category',
            field: 'material_type',
            type: 'autoComplete',
            api: `/trainingmaterialtypes`,
            suggestionField: 'name',
            valueField: 'name',
            width: 200,
            onCreate: ({searchValue, onChange}) => {
              navigation.navigate(`add-training-material-type`, {
                ...params,
                searchValue,
                afterSubmit: ({data}) => onChange && onChange(data),
              });
            },
          },
        ]}
      />
    );
  };
  return (
    <>
      <TableHeader
        title="Study Material"
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              {
                type: 'autoComplete',
                label: 'Topic',
                api: '/skilltopics',
                suggestionField: 'name',
                valueField: 'name',
                field: 'skill_topic',
              },
              {
                type: 'autoComplete',
                label: 'Category',
                api: '/trainingmaterialtypes',
                suggestionField: 'name',
                valueField: 'name',
                field: 'material_type',
              },
            ]}
          />,
        ]}
      />
      {inlineForm(props)}
      <Table
        filter={{
          skill_metrics,
          status: 'Active',
          ...filter,
        }}
        search={searchValue}
        searchFields="name"
        eventSourceId={['StudyMaterials']}
        api="/trainingMaterials"
        fields={{
          url:1,
          name: 1,
          skill_topic: {
            name: 1,
            color:1
          },
          material_type: {
            name: 1,
            color: 1,
          },
          skill_metrics: {
            _id: 1,
            name: 1,
          },
        }}
        columns={[
          {
            header: 'Material',
            render:RenderName
          },
          {
            field: 'skill_topic.name',
            colorField:'skill_topic.color',
            header: 'Topic',
            type: 'colorTag',
            width:150,

          },
          {
            field: 'material_type.name',
            header: 'Category',
            type: 'colorTag',
            colorField: 'material_type.color',
            width:150,

          },
          {
            type: 'rating',
            align: 'center',
            // field: 'performance',
            header: 'Avg. Rating',
            width:120,
          },
        ]}
        moreActions={() => [
          editTrainingMaterial({navigation, params}),
          deleteTrainingMaterial({invoke}),
        ]}
        {...props}
      />
    </>
  );
};

export default TrainingMaterialListWithInlineForm;
