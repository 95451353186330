import React from 'react';
import { Table } from '../../../components/table/Table';
import images from '../../../images';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

const { Owner } = images;

// Akshat Garg - 28/08/24 - threads meeting notes table
export const ThreadMeetingNotesTable = props => {
    let {
        navigation,
        route: { params },
    } = props;

    const { thread = {} } = params;
    const threadFilter = thread?._id ? { thread: thread._id } : {};

    return (
        <Table
            eventSourceId={['threadMeetingNotesAdded']}
            api={`/threadmeetingnotes`}
            fields={{
                participants: {
                    name: 1,
                    color: 1,
                },
                title: 1,
                description: 1,
                createdAt: 1
            }}
            sort={{ createdAt: -1 }}
            filter={{ ...threadFilter }}
            renderHeader={() => (
                <TableHeader
                    title="Meeting Notes"
                    actions={[
                        <AddButton
                            title="Meeting Notes"
                            view="add-thread-meeting-notes"
                            params={props?.route?.params}
                        />,
                    ]}
                />
            )}
            columns={[
                {
                    header: 'Title',
                    field: 'title',
                    type: 'text',
                    width: 200,
                },
                {
                    header: 'Description',
                    field: 'description',
                    type: 'textArea',
                },
                {
                    header: 'Created On',
                    field: 'createdAt',
                    width: 150,
                    align: 'center',
                    type: 'date',
                    formatOptions: {
                        format: 'DD MMM YY',
                    },
                },
                {
                    header: { icon: Owner },
                    field: 'participants',
                    type: 'userAvatarGroup',
                    align: 'right',
                    width: 170,
                },
            ]}
            moreActions={[
                {
                    title: 'Edit',
                    onPress: ({ row }) => {
                        navigation.navigate('edit-thread-meeting-notes', { row });
                    },
                },
            ]}
        />
    );
};
