import React from 'react';
import {AddButton, HeaderButton} from '../components/button/AddButton';
import {
  AddRequestTalentForEmployee,
  AddRequestTalentForm,
  RequestTalent,
} from '../modules/common/views/AddRequestTalentForm';
import ChangeAllocationForm from '../modules/common/views/ChangeAllocationForm';
import {EmployeeAssignmentTable} from '../modules/common/views/EmployeeAssignmentTable';
import {
  AddGeographicForm,
  UpdateGeographicForm,
} from '../modules/common/views/GeographicForm';
import {
  MyResources,
  OrganizationResources,
} from '../modules/common/views/MyResources';
import {
  AddOrganizationCategoryForm,
  UpdateOrganizationCategoryForm,
} from '../modules/common/views/OrganizationCategoryForm';
import {
  AddMarketingConcepts,
  EditMarketingConceptForm,
} from '../modules/communication/views/AddMarketingConcepts';
import {
  AddPlatform,
  EditPlatformForm,
} from '../modules/communication/views/AddPlatform';
import {EditGenericInitiativeForm} from '../modules/department/views/AddGenericInitiativesForm';
import {ApiForm} from '../modules/department/views/ApiForm';
import {EditKpiForm, KpiForm} from '../modules/department/views/KpiForm';
import {RevenueTable} from '../modules/department/views/RevenueGroupTable';
import {SelectKpis} from '../modules/department/views/SelectKpis';
import {TargetForm} from '../modules/department/views/TargetForm';
import {VendorInvoiceDetailsTable} from '../modules/department/views/VendorInvoiceDetailsTable';
import {
  AddOutreachCampaign,
  EditOutreachCampaignForm,
} from '../modules/leadGeneration/outreachcampaign-views/AddOutreachCampaign';
import {AllCampaignContactList} from '../modules/leadGeneration/views/CampaignContactsList';
import {
  EditRecurringTaskForm,
  RecurringTaskForm,
} from '../modules/task/views/RecurringTaskForm';
import {
  AddAdHocTeamTaskForm,
  EditAdHocTaskForm,
  EditProjectTaskForm,
  EditStrategicTaskForm,
  EditTaskForm,
  MarkTaskActiveForm,
} from '../modules/task/views/TaskForm';
import {TaskTabs} from '../modules/task/views/TaskTabs';
import {UpdateTaskOutputForm} from '../modules/task/views/UpdateTaskOutputForm';

import {
  AdCreativeForm,
  EditAdCreativeForm,
} from '../modules/leadGeneration/views/AdCreativeForm';
import {
  AddCommunicationForm,
  AddScheduleTaskForm,
} from '../modules/leadGeneration/views/AddCommunicationForm';
import {
  AddCustomPlatformOpportunityForm,
  AssignPreSalesForm,
  EditCustomPlatformOpportunityForm,
  MarkEnggOpportunityCloseForm,
  MarkOpportunityCloseForm,
  UpdateSubStage,
} from '../modules/leadGeneration/views/AddCustomPlatformOpportunityForm';
import {
  AddInmailCampaign,
  EditInmailCampaign,
} from '../modules/leadGeneration/views/AddInmailCampaign';
import {AddMarketingCreativeForm} from '../modules/leadGeneration/views/AddMarketingCreativeForm';
import {AddOpportunityForm} from '../modules/leadGeneration/views/AddOpportunityForm';
import {
  AddDescription,
  AddPoc,
} from '../modules/leadGeneration/views/AddPocForm';
import {AddPotentialCustomerForm} from '../modules/leadGeneration/views/AddPotentialCustomerForm';
import {
  AddStaffAugmentationOpportunityForm,
  DetailStaffAugmentationOpportunityForm,
  EditStaffAugmentationOpportunityForm,
  MarkContractNegotiation,
  UpdateProposalHours,
} from '../modules/leadGeneration/views/AddStaffAugmentationOpportunityForm';
import {
  AmFeedbackFromNonedit,
  AssignInterviewResource,
  MarkInterviewResourceOutcomeForm,
  MarkOutcomeDrop,
  OutcomeDateCorrectionForm,
  PHFeedbackFrom,
  PHFeedbackFromDual,
  ScheduleResourceInterviewForm,
  UpdateResourceResumeForm,
} from '../modules/leadGeneration/views/AssignInterviewResourceForm';
import {
  AssignBAOnUnAssignedRFQ,
  AssignOwnerOnUnassignedRFQ,
  AssignPreSalesOnUnAssignedRFQ,
  AssignSolutionConsultants,
  AssignTechPerson,
  AssignTechPersonOnActive,
  UpdateOwner,
  UpdatePracticeOnActiveRFQ,
  UpdatePreSales,
  UpdateSolutionConsultant,
} from '../modules/leadGeneration/views/AssignTechPerson';
import {BlogForm, EditBlogForm} from '../modules/leadGeneration/views/BlogForm';
import {
  BuyerPersonaForm,
  EditBuyerPersonaForm,
} from '../modules/leadGeneration/views/BuyerPersonaForm';
import {
  ContactListForCampaign,
  ContactListForCampaignForOutreachBatch,
} from '../modules/leadGeneration/views/CampaignContactsList';
import {
  CampaignLeadListTab,
  CampaignLeadListTabForOutreachBatch,
} from '../modules/leadGeneration/views/CampaignLeadList';
import {
  CampaignProspectForMessageSendTab,
  CampaignProspectForPositiveTab,
  CampaignProspectListTab,
  CampaignProspectListTabForOutreachBatch,
  MessageSendTabForOutreachBatch,
  MessageSentForCampaignMessageTab,
  PositiveForCampaignMessageTab,
  PositiveTabForOutreachBatch,
} from '../modules/leadGeneration/views/CampaignProspectList';
import {
  CarouselAdForm,
  EditCarouselAdForm,
} from '../modules/leadGeneration/views/CarouselAdForm';
import {
  CaseStudyForm,
  EditCaseStudyForm,
} from '../modules/leadGeneration/views/CaseStudyForm';
import {
  CommunicationListTable,
  CommunicationListTableCustomerOverview,
} from '../modules/leadGeneration/views/CommunicationListTable';
import {CreateRFQForm} from '../modules/leadGeneration/views/CreateRFQForm';
import {CreateRFRForm} from '../modules/leadGeneration/views/CreateRFRForm';
import {DataMiningForm} from '../modules/leadGeneration/views/DataMiningForm';

import {NavHeader} from '../components/nav/NavHeader';
import {
  AddActiveElementsForm,
  UpdateActiveElementsForm,
} from '../modules/common/views/ActiveElementsForm';
import {AllocateOnInputs} from '../modules/common/views/AllocateOnInputs';
import CompanySetup from '../modules/common/views/CompanySetup';
import {
  AddCurrencyForm,
  UpdateCurrencyForm,
} from '../modules/common/views/CurrencyForm';
import {
  AddEmployeeAccessForm,
  UpdateEmployeeAccessForm,
} from '../modules/common/views/EmployeeAccessForm';
import {
  AddEmployeeAssignmentForm,
  AddEmployeeSkill,
  UpdateEmployeeAssignmentForm,
} from '../modules/common/views/EmployeeAssignmentForm';
import {
  AddEmployeeSizeForm,
  EditEmployeeSizeForm,
} from '../modules/common/views/EmployeeSizeForm';
import {
  AddFinancialYearForm,
  UpdateFinancialYearForm,
} from '../modules/common/views/FinancialYearForm';
import {
  AddIndustryForm,
  EditIndustryForm,
} from '../modules/common/views/IndustryForm';
import {
  AddOfficeForm,
  UpdateOfficeForm,
} from '../modules/common/views/OfficeForm';
import {
  AddOrganizationForm,
  UpdateOrganizationForm,
} from '../modules/common/views/OrganizationForm';
import {
  AddProductForm,
  UpdateProductForm,
} from '../modules/common/views/ProductForm';
import {
  AddProjectMemberForm,
  UpdateProjectMemberForm,
} from '../modules/common/views/ProjectEmployeeForm';
import {
  AddProjectForm,
  AddUnbilledProjectForm,
} from '../modules/common/views/ProjectForm';
import TalentManagement from '../modules/common/views/TalentManagement';
import {TeamTableTabView} from '../modules/common/views/TeamTableTabView';
import {AddTypesForm, UpdateTypesForm} from '../modules/common/views/TypesForm';
import CreativeMenu from '../modules/communication/views/CreativeMenu';
import {MarketingConceptTable} from '../modules/communication/views/MarketingConceptTable';
import CustomerDetailDashboard from '../modules/customer/views/CustomerDetailDashboard';
import {
  AddCustomerForm,
  AddCustomerFormForCustomer,
  AddCustomerFormForOpportunity,
  EditCustomerForm,
  MakeCustomerForm,
} from '../modules/customer/views/CustomerForm';
import {
  CustomerAllRFQList,
  CustomerAllRFRList,
  CustomerProposalList,
  CustomerRFRList,
  CustomerRPSList,
} from '../modules/customer/views/CustomerOpportunityTable';
import {
  AddStrategicInitiativesForm,
  EditStrategicInitiativeForm,
} from '../modules/department/views/AddStrategicInitiativesForm';
import {AllTargetsTabView} from '../modules/department/views/AllTargetsTabView';
import {CompletedStrategicInitiativesTable} from '../modules/department/views/CompletedStrategicInitiativesTable';
import {CustomerRevenueTable} from '../modules/department/views/CustomerRevenueTable';
import {
  AddDepartmentForm,
  UpdateDepartmentForm,
} from '../modules/department/views/DepartmentForm';
import {
  AddDepartmentInputForm,
  UpdateDepartmentInputForm,
} from '../modules/department/views/DepartmentInputForm';
import {
  AddDepartmentOutputForm,
  UpdateDepartmentOutputForm,
} from '../modules/department/views/DepartmentOutputForm';
import {DepartmentDashboard} from '../modules/department/views/DepartmentsDashboard';
import AllDepartments from '../modules/department/views/DepartmentsOutput';
import {JoinStrategicInitiative} from '../modules/department/views/JoinStrategicInitiative';
import {MarkGenericInitiativeCompleteForm} from '../modules/department/views/MarkGenericInitiativeCompleteForm';
import {MarkStrategicInitiativeCompleteForm} from '../modules/department/views/MarkStrategicInitiativeCompleteForm';
import ProductNavigator from '../modules/department/views/ProductNavigator';
import AllProducts from '../modules/department/views/ProductOutput';
import {SalaryTable} from '../modules/department/views/SalaryTable';
import {ShowStrategicInvitees} from '../modules/department/views/ShowStrategicInitiative';
import StrategicInitiativesTable from '../modules/department/views/StrategicInitiativesTable';
import UserDashboard from '../modules/department/views/UserDashboard';
import {
  DetailDripInmailFormNew,
  DripInmailFormNew,
  EditDripInmailFormNew,
} from '../modules/leadGeneration/outreachcampaign-views/DripInmailFormNew';
import {
  AddMessageForm,
  EditMessageForm,
  MessageFormDetail,
} from '../modules/leadGeneration/views/AddMessageForm';
import {
  AddOutreachContent,
  EditOutreach,
} from '../modules/leadGeneration/views/AddOutreachContent';
import {BlogTabView} from '../modules/leadGeneration/views/BlogTabView';
import {BuyerPersonaTabView} from '../modules/leadGeneration/views/BuyerPersonaTabView';
import {CarouselAdTabView} from '../modules/leadGeneration/views/CarouselAdTabView';
import {CaseStudyTabView} from '../modules/leadGeneration/views/CaseStudyTabView';
import {CommunicationTabView} from '../modules/leadGeneration/views/CommuncationsTabView';
import {CreativeAdTabView} from '../modules/leadGeneration/views/CreativeAdTabView';
import {
  CustomDigitalPlatformOppTabView,
  OpportunityTabView,
  ProposalOppTabView,
} from '../modules/leadGeneration/views/CustomPlatformOpportunityTabView';
import {
  AllOpportunityTable,
  CustomPlatformOpportunityActiveTable,
} from '../modules/leadGeneration/views/CustomPlatformOpportunityTable';
import {CustomerOppTabView} from '../modules/leadGeneration/views/CustomerTabView';
import {
  DataMiningHeader,
  DataMiningTable,
} from '../modules/leadGeneration/views/DataMiningTable';
import {
  AddDripEmailForm,
  DetailDripEmailForm,
  EditDripEmailForm,
} from '../modules/leadGeneration/views/DripEmailForm';
import {DripEmailTabView} from '../modules/leadGeneration/views/DripEmailTabView';
import {DripEmailActiveTable} from '../modules/leadGeneration/views/DripEmailTable';
import {
  DetailDripInmailForm,
  DripInmailForm,
  EditDripInmailForm,
} from '../modules/leadGeneration/views/DripInmailForm';
import {DripInmailTabView} from '../modules/leadGeneration/views/DripInmailTabView';
import {DripInmailActiveTable} from '../modules/leadGeneration/views/DripInmailTable';
import {
  EditEmailCampaignForm,
  EmailCampaignForm,
} from '../modules/leadGeneration/views/EmailCampaignForm';
import {
  EmailCampaignHeader,
  EmailCampaignTable,
} from '../modules/leadGeneration/views/EmailCampaignTable';
import {EmailMessageLists} from '../modules/leadGeneration/views/EmailMessageLists';
import {FetchPaidAdAnalyticsForm} from '../modules/leadGeneration/views/FetchPaidAdAnalyticsForm';
import {
  EditImageForm,
  ImageForm,
} from '../modules/leadGeneration/views/ImageForm';
import {ImageTabView} from '../modules/leadGeneration/views/ImageTabView';
import {ImportHistoryLogDetail} from '../modules/leadGeneration/views/ImportHistoryLogDetails';
import {ImportHistoryLogList} from '../modules/leadGeneration/views/ImportHistoryLogList';
import {ImportLeads} from '../modules/leadGeneration/views/ImportLead';
import {
  InmailCampaignHeader,
  InmailCampaignTable,
} from '../modules/leadGeneration/views/InmailCampaignTable';
import {
  CustomerAcquisitionContractNegotiationTab,
  CustomerGrowthContractNegotiationTab,
} from '../modules/leadGeneration/views/InpputDepartmentContractNegotiationTable';
import {
  CustomerAcquisationInterviewTab,
  InputDepartmentInterviewTabView,
  NewResourceAdditionTabView,
} from '../modules/leadGeneration/views/InputDepartmentInterviewTable';
import {
  CustomerAcquisationProposalTab,
  InputDepartmentProposalTabView,
  ProposalWonTabView,
} from '../modules/leadGeneration/views/InputDepartmentProposalTable';
import {SalesMeetingTab} from '../modules/leadGeneration/views/InputDepartmentSalesMeetingTable';
import {
  CustomerAcquisationPOCTab,
  CustomerGrowthPOCTab,
} from '../modules/leadGeneration/views/InputProofOfConceptTable';
import {InterviewResourcesTable} from '../modules/leadGeneration/views/InterviewResourcesTable';
import {InterviewTabView} from '../modules/leadGeneration/views/InterviewTabView';
import {
  CustomerOverviewRevenueList,
  CustomerWiseReceiptList,
  CustomerWiseRevenueInvoiceList,
  DirectExpenseInvoiceList,
  ExpenseInvoiceList,
  OverduePendingAmtCustomerList,
  RevenueInvoiceList,
} from '../modules/leadGeneration/views/InvoiceLists';
import {
  AddLeadForm,
  AddLeadFormQLActive,
  AddLeadFormUQLActive,
  CreateDuplicateLeadForm,
  EditLeadForm,
} from '../modules/leadGeneration/views/LeadForm';
import {
  AllLeadTableTab,
  LeadTableTabView,
} from '../modules/leadGeneration/views/LeadTableTabView';
import {MakeLeadForm} from '../modules/leadGeneration/views/MakeLeadForm';
import {MakeUqlForm} from '../modules/leadGeneration/views/MakeUqlForm';
import {
  CandidateDescriptionPopUp,
  LeadDescriptionPopUp,
  MarkLeadLostForm,
} from '../modules/leadGeneration/views/MarkLeadLostForm';
import {
  AddMarketingTheme,
  EditMarketingTheme,
} from '../modules/leadGeneration/views/MarketingThemeForm';
import {
  MarketingThemeTabs,
  MarketingThemeTabsForNurturing,
} from '../modules/leadGeneration/views/MarketingThemeTabs';
import {MessageTable} from '../modules/leadGeneration/views/MessageTable';
import {OpportunityDropRfq} from '../modules/leadGeneration/views/OpportunityDropRfq';
import {OpportunityMarkLost} from '../modules/leadGeneration/views/OpportunityMarkLost';
import {
  OpportunityMarkOwn,
  OpportunityMarkOwnRFQFetaure,
} from '../modules/leadGeneration/views/OpportunityMarkOwn';
import {
  OpportunitySubmitProposal,
  OpportunitySubmitProposalFeatureForm,
  OpportunitySubmitQuote,
  OpportunitySubmitQuoteFeatureForm,
  ShowOpportunityProposalRFQFeature,
  ShowOpportunityQuotationRFQFeature,
  ShowOpportunitySubmitProposal,
  ShowOpportunitySubmitProposalRestimation,
} from '../modules/leadGeneration/views/OpportunitySubmitProposal';
import {
  EditOtherCampaign,
  OtherCampaignForm,
} from '../modules/leadGeneration/views/OtherCampaignForm';
import {OutReachBatchForm} from '../modules/leadGeneration/views/OutReachBatchForm';
import {
  OutreachBatchTabView,
  OutreachBatchTabViewBeta,
} from '../modules/leadGeneration/views/OutreachBatchTabView';
import {OutreachBatchHeader} from '../modules/leadGeneration/views/OutreachBatchTable';
import {
  EditPaidAdsForm,
  PaidAdForm,
} from '../modules/leadGeneration/views/PaidAdForm';
import {
  PaidAdHeader,
  PaidAdsTable,
} from '../modules/leadGeneration/views/PaidAdsTable';
import {POCOppTabView} from '../modules/leadGeneration/views/PocTabView';
import {
  AddProposalSubmittedForm,
  EditProposalSubmittedForm,
} from '../modules/leadGeneration/views/ProposalSubmittedForm';
import {ProposalSubmittedTableTabView} from '../modules/leadGeneration/views/ProposalSubmittedTableTabView';
import {ProposalTabView} from '../modules/leadGeneration/views/ProposalTabs';
import {
  AddProspectForm,
  EditProspectForm,
  UpdatePotentialCustomerForm,
} from '../modules/leadGeneration/views/ProspectForm';
import {ProspectListForMessageTab} from '../modules/leadGeneration/views/ProspectListForMessage';
import {
  AllProspectTableTab,
  ProspectContactedTab,
  ProspectsTableTabView,
} from '../modules/leadGeneration/views/ProspectTableTabView';
import {RPSCloseForm} from '../modules/leadGeneration/views/RPSCloseForm';
import {AddRPSForm, EditRPSForm} from '../modules/leadGeneration/views/RPSForm';
import {ActiveRPSTable} from '../modules/leadGeneration/views/RPSTable';
import {RPSTableTabView} from '../modules/leadGeneration/views/RPSTableTabView';
import {
  EditSingleScreenMessageForm,
  SingleScreenMessageForm,
} from '../modules/leadGeneration/views/SingleScreenMessageForm';
import {SingleScreenMessageTabView} from '../modules/leadGeneration/views/SingleScreenMessageTabView';
import {
  InterviewStaffAugOppTabView,
  StaffAugmentationOppTabView,
} from '../modules/leadGeneration/views/StaffAugmentationOppTabView';
import {
  ActiveCandidateTabView,
  ActiveCandidateTabViewDeliveryOwner,
  DropCandidateTabView,
  LostCandidateTabView,
  StaffAugmentationResourceTable,
  WonCandidateTabView,
} from '../modules/leadGeneration/views/StaffAugmentationResourceTable';
import {
  ActiveStaffAugmentationOpportunityTable,
  DeliveryOwnerWiseSuccessClosedOpportunityTable,
  DeliveryOwnerWiseSuccessOpportunityTable,
  MentorWiseSuccessClosedOpportunityTable,
  MentorWiseSuccessOpportunityTable,
  PracticeWiseClosedOpportunityTable,
  PracticeWiseOpportunityTable,
  ResourceWiseSuccessOpportunityTable,
  ResourceWiseSuccessOpportunityTableClosed,
} from '../modules/leadGeneration/views/StaffAugmentationTable';
import {
  AddTargetAudience,
  EditTargetAudience,
} from '../modules/leadGeneration/views/TargetAudienceForm';
import {TargetAudienceTable} from '../modules/leadGeneration/views/TargetAudienceTable';
import {UpdateCampaignForm} from '../modules/leadGeneration/views/UpdateCampaignForm';
import {
  UpdateLeadStageForm,
  UpdateUqlDateFrom,
} from '../modules/leadGeneration/views/UpdateLeadStageForm';
import {
  AddLeadConsultants,
  MarkLeadLostCumulativeForm,
  UpdateLeadStatusForm,
} from '../modules/leadGeneration/views/UpdateLeadStatusForm';
import {
  UpdateOwnerForm,
  UpdateOwnerFormForFreeProspect,
  UpdateOwnerRowForm,
} from '../modules/leadGeneration/views/UpdateOwnerForm';
import {
  UpdateCombinedSubStage,
  UpdatePOCStatus,
  UpdateProposalStatus,
  UpdateProposalStatusPreSales,
  UpdateWinningChanceForm,
} from '../modules/leadGeneration/views/UpdateProposalStatus';
import {
  AddValueProposition,
  EditValueProposition,
} from '../modules/leadGeneration/views/ValuePropositionForm';
import {ValuePropositionList} from '../modules/leadGeneration/views/ValuePropositionTable';
import {
  EditVideoForm,
  VideoForm,
} from '../modules/leadGeneration/views/VideoForm';
import {VideoTabView} from '../modules/leadGeneration/views/VideoTabView';
import {
  EditWebCampaign,
  WebPageCampaignFrom,
} from '../modules/leadGeneration/views/WebPageCampaignFrom';
import {
  EditWebPageForm,
  WebPageCommunicationForm,
} from '../modules/leadGeneration/views/WebPageFrom';
import {WebPageTabView} from '../modules/leadGeneration/views/WebPageTabView';
import {
  WebPageCampaignHeader,
  WebPageTable,
} from '../modules/leadGeneration/views/WebPageTable';
import {AssignEstHours} from '../modules/task/views/AssignEstHours';
import {AssignTask} from '../modules/task/views/AssignTask';
import {MarkTaskCompleteForm} from '../modules/task/views/MarkTaskCompleteForm';
import {AddTaskFieldForm} from '../modules/task/views/TaskFieldForm';
// import InvoiceTable from '../modules/order/views/InvoiceTable';
import {OrderTabDepartmentOutputView} from '../modules/order/views/OrderTable';
import {UpdateReplanForm} from '../modules/pmt/views/ReplanForm';
import {UpdateCompleteDateForm} from '../modules/task/views/UpdateCompleteDateForm';

import {isMobile} from '@unthinkable/react-utils';
import {
  AddAccessGroup,
  UpdateAccessGroup,
} from '../modules/common/views/AccessGroupForm';
import {
  CreateAccountForm,
  EditAccountForm,
} from '../modules/common/views/AccountForm';
import {EditCountryForm} from '../modules/common/views/CountryTable';
import {
  AddFreezeRangeForm,
  EditFreezeRangeForm,
} from '../modules/common/views/FreezeRangeForm';
import OrganizationSetupMenu from '../modules/common/views/OrganizationSetupMenu';
import SetupMenu from '../modules/common/views/SetupMenu';
import {
  AddUserAccess,
  UpdateUserAccess,
} from '../modules/common/views/UserAccessForm';
import {CustomerMenu} from '../modules/customer/views/CustomerMenu';
import {AddCustomObjectType} from '../modules/department/views/AddCustomObjectType';
import {
  DailyAttendanceForShiftAllowanceTab,
  DailyAttendanceTab,
  TeamEmployeeAttendanceTab,
} from '../modules/department/views/EmployeeAttendanceTab';
import {
  AddLeaveRequestForm,
  UpdateLeaveRequestForm,
} from '../modules/department/views/LeaveRequestForm';
import OrganizationNavigator from '../modules/department/views/OrganizationNavigator';
import {
  TeamAttendanceRejectRequestForm,
  UpdateTeamAttendanceForm,
} from '../modules/department/views/TeamAttendanceForm';
import {UpdateTeamLeaveRequestForm} from '../modules/department/views/TeamRequestForm';
import {
  AddTimesheetForm,
  AddTimesheetRequestForm,
  AddTimesheetRequestFormRowAction,
  EditLogTimesheetProjectForm,
  EditTimesheetForm,
  LogTimesheetNonProjectForm,
  LogTimesheetNonProjectFormDetails,
  LogTimesheetProjectForm,
  LogTimesheetProjectFormDetails,
} from '../modules/department/views/TimesheetForm';
import {
  AddVendorForm,
  EditVendorForm,
} from '../modules/department/views/VendorTableViewForm';
import {
  BonusDetailForm,
  CompensationStructureDetailForm,
  IncentiveDetailForm,
  InvoiceDetailForm,
  SalaryDetailForm,
} from '../modules/department/views/employeeSalaryForm';
import {
  AddEmployeeTeam,
  EditEmployeeTeam,
} from '../modules/employee/views/AddEmployeeTeam';
import {AddSkillToEmployeeForm} from '../modules/employee/views/AddSkillToEmployeeForm';
import {EmployeeList} from '../modules/employee/views/EmployeeList';
import {
  ScheduledReportMenu,
  SuccessReportMenu,
} from '../modules/employee/views/EmployeeMenu';
import {EmployeeTeamOrganizationEfficiency} from '../modules/employee/views/EmployeeTeamOrganizationEfficiency';
import {UpdateEmployeeTeam} from '../modules/employee/views/UpdateEmployeeTeam';
import {
  AddBackLinkForm,
  AddKeywordForm,
  AddSocialMediaPosts,
  AddSocialMediaWebpage,
  EditBackLinkForm,
  EditKeywordForm,
  EditSocialMediaPosts,
  EditSocialMediaWebpage,
} from '../modules/leadGeneration/components/SocialMediaMasterForm';
import {SocialMediaMasterNavigator} from '../modules/leadGeneration/components/SocialMediaMasterNavigator';
import {CampaignMessageTable} from '../modules/leadGeneration/outreachcampaign-views/CampaignMessageTable';
import {
  OutreachCampaignContactTabs,
  OutreachCampaignContactsList,
  OutreachCampaignContactsUnverified,
  OutreachTargetAudienceContactTabs,
} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignContactsList';
import {OutreachCampaignHeader} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignsTab';
import {
  AddEmployeeFormHOC,
  AddEmployeeWithPackageFormHOC,
  BackgroundVerificationForm,
  DownloadAppointmentLetterForm,
  DownloadPromotionLetterForm,
  DownloadRelievingLetterForm,
  EditEmployeeForm,
  EmployeeDetailForm,
  EmployeeDetailFormForHr,
  HoldUnholdEmployeeSalary,
  SettleFnfEmployeeSalary,
  UploadLettersForm,
  markEmployeeLegalForm,
  markEmployeeRecoveryPendingForm,
} from '../modules/leadGeneration/views/AddEmployeeForm';
import {
  AddCronHistoryForm,
  AddHolidayCalendarForm,
  AddLeavePolicyForm,
  AddLeaveTypeForm,
  AddPunchHistoryForm,
  AddScheduleForm,
  AddShiftsForm,
  EditCronHistoryForm,
  EditHolidayCalendarForm,
  EditLeavePolicyForm,
  EditLeaveTypeForm,
  EditPunchHistoryForm,
  EditScheduleForm,
  EditShiftsForm,
} from '../modules/leadGeneration/views/AttendanceTableForms';
import ChannelWiseCampaignTable from '../modules/leadGeneration/views/ChannelWiseCampaignTable';
import ChannelWiseStaffAugmentationTab from '../modules/leadGeneration/views/ChannelWiseStaffAugmentationTab';
import ChannelwiseCustomPlatformTab from '../modules/leadGeneration/views/ChannelwiseCustomPlatformTab';
import {ChannelWiseCustomerTab} from '../modules/leadGeneration/views/ChannelwiseCustomerTab';
import {DialogueBoxForm} from '../modules/leadGeneration/views/DialogueBoxForm';
import {EmployeeTabs} from '../modules/leadGeneration/views/EmployeeTabs';
import {EditInterview} from '../modules/leadGeneration/views/InterviewForm';
import {PointOfContactTable} from '../modules/leadGeneration/views/LeadTable';
import {LoadEmailMessages} from '../modules/leadGeneration/views/LoadEmailMessages';
import {LoadInmailMessages} from '../modules/leadGeneration/views/LoadInmailMessagesTable';
import {LoadProspectsTable} from '../modules/leadGeneration/views/LoadProspectList';
import {MessageCategoryType} from '../modules/leadGeneration/views/MessageCategoryType';
import {MoveToOpportunityForm} from '../modules/leadGeneration/views/MoveToOpportunityForm';
import {InreachCampaignTabs} from '../modules/leadGeneration/views/OtherCampaignTabView';
import {PaidCampaignTabView} from '../modules/leadGeneration/views/PaidAdsCampaignTabView';
import {PaidAdCampaignHeader} from '../modules/leadGeneration/views/PaidAdsCampaignTable';
import {PerformanceMenu} from '../modules/leadGeneration/views/PerformanceMenu';
import {PreSalesTabView} from '../modules/leadGeneration/views/PreSalesTabView';
import {TargetAudienceTabs} from '../modules/leadGeneration/views/TargetAudienceTabs';
import {
  AddAttendanceForm,
  AddSelfAttendanceForm,
  AddSelfLeaveRequestForm,
  UpdateAttendanceForm,
  UpdateAttendanceFormHR,
  UpdateEWDForm,
  UpdateMultipleAttendanceForm,
} from '../modules/leadGeneration/views/attendance-views/AttendanceForm';
import DetailResourceAllocatedList, {
  AllAllocationResourceList,
  AllocationResourceForBillingStatusReportList,
  DetailResourceAllocatedDepartmentList,
  TrainingResourceAllocatedList,
} from '../modules/order/views/DetailResourceAllocatedList';
import {
  AddEmployeeRequestForm,
  ProjectwiseEmployeeRequestForFixedOders,
} from '../modules/order/views/EmployeeRequestForFixedOders';
import FixedOrderAssignedResourcesTable from '../modules/order/views/FixedOrderAssignedResourcesTable';
import {AddFixedOrderAssignment} from '../modules/order/views/FixedOrderAssinmentForm';
import FixedOrderEmployeeRequestTab from '../modules/order/views/FixedOrderEmployeeRequestTab';
import {InvoiceMenu, InvoiceMenuAm} from '../modules/order/views/InvoiceMenu';
import {
  FixOrderResourceAssignmentForRM,
  MilestoneOrderResourceAssignmentForm,
} from '../modules/order/views/MilestoneAllocatedResource';
import {
  AddAdminMilestone,
  AddMilestone,
  ApproveMilestone,
  EditMilestone,
} from '../modules/order/views/MilestoneForm';
import MilestonesTab from '../modules/order/views/MilestonesTab';
import {
  AddFixedOrderform,
  AddSupportOrderform,
  AddTMOrderform,
  DetailFixedOrderForm,
  DetailTMOrderform,
  EditFixedOrderForm,
  EditSupportOrderForm,
  EditTMOrderForm,
} from '../modules/order/views/OrderForm';
import {
  OrderResourceAssignmentForm,
  ProjectOrderResourceAssignmentForm,
} from '../modules/order/views/OrderResourceAssignmentForm';
import {OrderResourceRequiremnetTable} from '../modules/order/views/OrderResourceRequiremnetTable';
import {OrderTableTabView} from '../modules/order/views/OrderTableTabView';
import {
  MarkDeliveryUnadjust,
  PotentialAdjust,
  UpdateProjectResourceWorkingForm,
} from '../modules/order/views/ProjectResourceWorkingDataForm';
import {
  ManMonthCustomerOverviewList,
  ProjectResourceWorkingDataTable,
} from '../modules/order/views/ProjectResourceWorkingDataTable';
import {
  EditProjectResourceWorkingForm,
  ProjectResourceWorkingForm,
} from '../modules/order/views/ProjectResourceWorkingForm';
import {
  AdjustInvoiceForm,
  CreateRevenueInvoice,
  DownloadInvoicePDF,
  EditableRevenueInvoice,
  MarkIsServiceInvoiceForm,
  NonEditableRevenueInvoice,
  SettleInvoice,
  UpdateInvoiceSubmissionDateForm,
  UpdateSoftexDetailsForm,
} from '../modules/order/views/RevenueInvoiceForm';
import {
  EditRevenueReceipt,
  ReceiptForm,
  RevenueReceiptDetail,
} from '../modules/order/views/RevenueReceiptForm';
import SkillRequirementTable from '../modules/order/views/SkillRequirementTable';
import {
  CreateVoucherForm,
  EditVoucherForm,
  OpenEditVoucherForm,
  OpenVoucherForm,
} from '../modules/order/views/VoucherForm';
import AssignShadowForm from '../modules/order/views/employee-allocation/AssignShadowForm';
import ChangeAllocatedResourceForm from '../modules/order/views/employee-allocation/ChangeAllocatedResourceForm';
import StopAllocation from '../modules/order/views/employee-allocation/StopAllocation';
import {OrganizationDashboard} from '../modules/organization/views/OrganizationDashboard';
import {
  AddMySkillsForm,
  EditMySkillsForm,
} from '../modules/pmt/views/MySkillsForm';
import {MySkillsTable} from '../modules/pmt/views/MySkillsTable';
import {NewChat} from '../modules/pmt/views/NewChat';
import {UpdateReleasedOnForm} from '../modules/pmt/views/ReleasedOnForm';
import {
  AddSkillTopic,
  EditSkillTopic,
} from '../modules/skills/views/AddSkillTopic';
import {AddTrainingMaterialTypes} from '../modules/skills/views/AddTrainingMaterialTypes';
import {AddAssessement} from '../modules/skills/views/AssessmentForm';
import {AssignResourceOnSkill} from '../modules/skills/views/AssignResourceOnSkill';
import {AssignTraineeOnBatch} from '../modules/skills/views/AssignTraineOnBatch';
import EmployeeSkillMetricsTable from '../modules/skills/views/EmployeeSkillMetricsTable';
import {EmployeeSkillTable} from '../modules/skills/views/EmployeeSkillOverview';
import {AddLearningPath} from '../modules/skills/views/LearningPathForm';
import {AddSelfCheckList} from '../modules/skills/views/SelfCheckListForm';
import {
  SkillAndTrainingNavigatorHR,
  SkillAndTrainingNavigatorSkillLeader,
} from '../modules/skills/views/SkillAndTrainingNavigator';
import {
  AddSkillMetricsForm,
  AddSkillMetricsNew,
  AddSkillToolForm,
  EditSkillMetricsForm,
  EditSkillMetricsNew,
  SkillMetricsDetiails,
} from '../modules/skills/views/SkillMetricsForm';
import {SkillTopicsTable} from '../modules/skills/views/SkillTopicsTable';
import {AddToolForm, EditToolForm} from '../modules/skills/views/ToolForm';
import ToolTable from '../modules/skills/views/ToolTable';
import {
  AddTopicDuration,
  AddTopicWiseFeedbackCycle,
  AddTopicWiseTrainer,
  AddTrainingBatchForm,
  EditTrainingBatch,
} from '../modules/skills/views/TrainingBatchForms';
import {
  AddTrainingMaterial,
  EditTrainingMaterial,
} from '../modules/skills/views/TrainingMaterialForm';
import {TrainingMaterialTable} from '../modules/skills/views/TrainingMaterialList';
import {
  AddTrainingProgram,
  DuplicateTrainingProgram,
  EditTrainingProgram,
  PlanSkillTraining,
} from '../modules/skills/views/TrainingProgramForms';
import {
  EmployeeOverdueTasksTab,
  EmployeeTasksTab,
} from '../modules/task/views/EmployeeTasksTab';
import {AssignmentChecklist} from '../modules/task/views/MasterTaskAssignmentChecklist';
import {
  AddMasterTask,
  EditMasterTask,
  UpdateMasterTaskChecklist,
} from '../modules/task/views/MasterTaskForm';
import {
  MasterTaskTable,
  MasterTaskTabs,
} from '../modules/task/views/MasterTaskTable';
import {
  TeamTasksTable,
  TeamTaskAllTable,
  OverDueTasks,
} from '../modules/task/views/TasksTable';
import EmailPermission from '../modules/user/EmailPermission';
import {
  AddVendorInvoiceForm,
  AddVendorInvoiceReimbursmentForm,
  EditVendorInvoiceForm,
  SettleVendorInvoice,
  UpdateNewVendorStatus,
  UpdateVerifiedVendorStatus,
  markVendorBlacklist,
} from '../modules/vendor/view/VendorInvoiceForm';

import {
  ActionsTable,
  ErrorActionsTable,
} from '../modules/leadGeneration/outreachcampaign-views/ActionsTable';
import {
  DropOutreachCampaignContactsForm,
  OutreachCampaignProspectDropForm,
} from '../modules/leadGeneration/outreachcampaign-views/DropOutreachCampaignContactsForm';
import {
  EmailProfileAddForm,
  EmailProfileEditForm,
} from '../modules/leadGeneration/outreachcampaign-views/EmailProfileForm';
import {
  EditEmailProviderAddForm,
  EmailProviderAddForm,
} from '../modules/leadGeneration/outreachcampaign-views/EmailProviderForm';
import {
  LinkedinProfileFormEdit,
  LinkedinProfileFormNew,
} from '../modules/leadGeneration/outreachcampaign-views/LinkedinProfileForm';

import {OutreachCampaignContactMessageFormEdit} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignContactMessageForm';
import {OutreachCampaignContactMessageTable} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignContactMessageTable';
import {
  OutreachCampaignMessageFormEdit,
  OutreachCampaignMessageFormNew,
} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignMessageForm';
import {OutreachCampaignMessageTable} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignMessageTable';
import {
  AddNewValueProposition,
  EditNewValuePropositionForm,
} from '../modules/leadGeneration/outreachcampaign-views/OutreachValuePropositionForm';
import {ValuePropositionListNew} from '../modules/leadGeneration/outreachcampaign-views/OutreachValuePropositionTable';
import {ScheduledActionForm} from '../modules/leadGeneration/outreachcampaign-views/ScheduledActionForms';
import {DripInmailTable} from '../modules/leadGeneration/outreachcampaign-views/ValuePropositionDripInmailTable';

import {
  AccessResource,
  UpdateAccessResourceForm,
} from '../modules/common/views/AccessResourceForm';
import {
  AddAccessRole,
  UpdateAccessRole,
} from '../modules/common/views/AccessRoleForm';
import {
  AddReimbursementCategory,
  EditReimbursementCategory,
} from '../modules/common/views/ReimbursementCategoryForm';
import {
  AddUserSetupForm,
  UpdateUserSetupForm,
} from '../modules/common/views/UserSetupForm';
import {AddLandlordDetailsForm} from '../modules/department/views/AddLandlordDetailsForm';
import {
  EmployeeIncentiveSalaryTab,
  EmployeeKPIIncentiveSalaryDetailTab,
} from '../modules/department/views/EmployeeSalaryTab';
import {MonthlyWorkloadOverviewTable} from '../modules/department/views/MonthlyWorkloadOverview';
import {SalaryMenu} from '../modules/department/views/SalaryMenu';
import {TaxReopenForm} from '../modules/department/views/TaxReopenForm';
import {
  TimesheetsProgressTable,
  TimesheetsTable,
  TimesheetsTandMProgressTable,
} from '../modules/department/views/TimesheetTable';
import {UpdateCalculationTypeForm} from '../modules/department/views/UpdateCalculationType';
import {
  UpdateIncomeTaxValue,
  UpdateTDSMonthAndYear,
  UpdateTaxCalculationDate,
} from '../modules/department/views/UpdateIncomeTaxValue';
import {Form16Tabs} from '../modules/department/views/form16Tabs';
import AddOtherResourceInWorkload from '../modules/employee/views/AddOtherResourceInWorkload';
import WorkloadAssignReplacementForm from '../modules/employee/views/AddWorkloadReplacement';
import {AssignAsReplacement} from '../modules/employee/views/AssignAsReplacement';
import {EmployeeWorkloadTabs} from '../modules/employee/views/EmployeeWorkloadTabs';
import {
  ProposedCompleteDateForm,
  ProposedDeploymentDateForm,
} from '../modules/employee/views/ProposedDeploymentDateForm';
import StopAllocationInWorkLoad from '../modules/employee/views/StopAllocationInWorkLoad';
import {
  UpdateCV,
  UpdateResourceSkill,
} from '../modules/employee/views/UpdateCV';
import WorkloadAssignShadowForm from '../modules/employee/views/WorkloadAssignShadow';
import {CampaignActionUnverifiedForm} from '../modules/leadGeneration/outreachcampaign-views/CampaignActionUnverifiedForm';
import {CampaignContactStatusForm} from '../modules/leadGeneration/outreachcampaign-views/CampaignContactStatusForm';
import {CampaignProspectsTab} from '../modules/leadGeneration/outreachcampaign-views/CampaignProspectsTab';
import {
  EditEmailSignatureAddForm,
  EmailSignatureAddForm,
} from '../modules/leadGeneration/outreachcampaign-views/EmailSignatureForm';
import {OutreachCampaignSelectNewCampaign} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignSelectNewCampaign';
import {OutreachCampaignTableGrouped} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignTableGrouped';
import {OutreachInmailCampaignTabs} from '../modules/leadGeneration/outreachcampaign-views/OutreachInmailCampaignTabs';
import {EditPotentialCustomerContactForm} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomerContactForm';
import {PotentialCustomerFormUpdateBusinessSummary} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomerFormUpdateBusinessSummary';
import {PotentialCustomerLockRejectForm} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomerLockRejectForm';
import {
  PotentialCustomerLocksRequestHeader,
  PotentialCustomerLocksRequestTable,
} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomerLocksRequestTable';
import {SettingsOutreachCampaignForm} from '../modules/leadGeneration/outreachcampaign-views/SettingsOutreachCampaign';
import {AddCampaignContact} from '../modules/leadGeneration/views/CampaignContactForm';
import EmailProfileList from '../modules/leadGeneration/views/EmailProfileList';
import EmailProviderList from '../modules/leadGeneration/views/EmailProviderList';
import EmailSignatureList from '../modules/leadGeneration/views/EmailSignatureList';
import LinkedInProfileList from '../modules/leadGeneration/views/LinkedInProfileList';
import {
  PreSalesMenu,
  PreSalesMenuForSolConsultant,
} from '../modules/leadGeneration/views/PreSalesMenu';
import {SendEmailTestForm} from '../modules/leadGeneration/views/SendEmailTestForm';
import {UpdateInsideSales} from '../modules/leadGeneration/views/UpdateInsideSalesForm';
import {UploadForm16DocumentForm} from '../modules/leadGeneration/views/UploadForm16Form';
import {WebsiteSummaryTestForm} from '../modules/leadGeneration/views/WebsiteSummaryTestForm';
import {
  AttendanceTab,
  AttendanceTabForVerifiedOrder,
} from '../modules/leadGeneration/views/attendance-views/AttendanceTab';
import {AssignTraineeForm} from '../modules/order/views/AssignTraineeForm';
import {
  FixedOrderAssignProjectForm,
  MilestoneAssignProjectForm,
} from '../modules/order/views/FixedOrderAssignProjectForm';
import OrderResourceRequestTable from '../modules/order/views/OrderResourceRequestTable';
import UpdateAllocation from '../modules/order/views/employee-allocation/UpdateAllocation';
import {EmployeeEfficiencyDetail} from '../modules/pmt/views/EmployeeEfficiencyDetail';
import {EmployeeEfficiencyIssueDetail} from '../modules/pmt/views/EmployeeEfficiencyIssueDetail';
import {ProjectsTable} from '../modules/pmt/views/ProjectsTable';
import {UpdateDueDateForm} from '../modules/pmt/views/UpdateDueDateForm';
import {
  ChangeCandidateOfferDate,
  ChangeInterviewDateOfCandidateForm,
  ChangeInterviewTimeOfCandidateForm,
  ChangeInterviewerOfCandidateForm,
  ChangeRecruiterOfCandidateForm,
  DetailReasonOfDrop,
  DetailReasonOfMakingNurturable,
  DetailReasonOfRejection,
  DetailSummaryOfAllRoundsOfCandidate,
  DropCandidateForm,
  HoldCandidateForm,
  MarkAccepted,
  MarkJoined,
  MarkNurturable,
  MoveToAnotherJobOpening,
  MovingCandidateToFirstRoundForm,
  MovingCandidateToHrRoundForm,
  MovingCandidateToNextRoundForm,
  RejectCandidateForm,
} from '../modules/recruitment/views/CandidateActionForm';
import {
  AddCandidate,
  EditCandidate,
} from '../modules/recruitment/views/CandidateForm';
import {JobOpeningCandidateTab} from '../modules/recruitment/views/CandidateTab';
import {
  AddJobOwner,
  CreateJobOpening,
  EditJobOpening,
  NonEditableJobOpening,
} from '../modules/recruitment/views/CreateJobOpening';
import {HrRecruitmentMenu} from '../modules/recruitment/views/HrRecruitmentMenu';
import {
  AddMrf,
  ApproveWithSalaryBudgetManPowerRequisition,
  EditableMrf,
  NonEditableMrf,
} from '../modules/recruitment/views/ManPowerRequisitionForm';
import {
  AddReimbursementRequest,
  EditReimbursementRequest,
  EditableReopenReimbursementRequest,
  NonEditableReimbursementRequest,
  RejectReimbursementForm,
  RemarkReimbursement,
} from '../modules/reimbursement/views/ReimbursementForm';
import {AssignMentorWiseTraineeOnBatch} from '../modules/skills/views/AssignMentorWiseTraineeOnBatch';
import {
  FeedbackForm,
  FeedbackFormPIP,
  MyFeedbackDetails,
} from '../modules/skills/views/EditFeedback';
import {EmployeeTrainingList} from '../modules/skills/views/EmployeeTrainingList';
import {
  EmployeeTrainingTabs,
  MyMenteesForWorkspace,
  MyMenteesTabs,
} from '../modules/skills/views/EmployeeTrainingTabs';
import EmployeeTrainingThroughBatchTable from '../modules/skills/views/EmployeeTrainingThroughBatchTable';
import {ExtendTrainingForm} from '../modules/skills/views/ExtendTraining';
import ExtendedDateDetails from '../modules/skills/views/ExtendedDateDetails';
import {
  PIPTrainingFeedback,
  TrainingFeedback,
} from '../modules/skills/views/TrainingFeedbackTable';
import TrainingMaterialListWithInlineForm from '../modules/skills/views/TrainingMaterialListWithInlineForm';
import {AdHocTaskTabs} from '../modules/task/views/AdHocTaskTabs';
import {AddTaskTable} from '../modules/task/views/AddTaskTable';
import {ArchiveTaskForm} from '../modules/task/views/ArchiveTaskForm';
import {TeamTaskTabs} from '../modules/task/views/TeamTaskTabs';
import {
  AddNewTicketForm,
  EditNewTicketForm,
  TicketDetailForm,
} from '../modules/tickets/views/AddNewTicketForm';
import {
  ChangeAgentForm,
  ChangeCategoryForm,
  ChangeDepartmentForm,
  RenderTicketChatHeader,
  SetDependencyForm,
  TemporarilyClosedForm,
} from '../modules/tickets/views/DialogBoxForms';
import {TicketHistoryScreen} from '../modules/tickets/views/TicketHistoryScreen';
import {
  TicketHeadTabs,
  TicketMenus,
} from '../modules/tickets/views/TicketsMenus';

import {DataEntityViewEditForm} from '../modules/common/views/DataEntityViewForm';
import {DataEntityViews} from '../modules/common/views/DataEntityViews';
import {
  SelfLevelFixedDetailTable,
  SelfLevelTMDetailTable,
} from '../modules/department/views/EmployeeSalaryTable';
import {WithdrawExitManagementRequestForm} from '../modules/department/views/ExitManagementForm';
import {AddWFHRequestForm} from '../modules/department/views/WfhRequestForm';
import {AnchorResourceActionForm} from '../modules/employee/views/AnchorResourceActionForm';
import {AssignLearningResource} from '../modules/employee/views/AssignLearningResource';
import {AssignResourceOnTeam} from '../modules/employee/views/AssignResourceOnTeam';
import {AssignTraining} from '../modules/employee/views/AssignTraining';
import {MarkNotAvailable} from '../modules/employee/views/MarkNotAvailable';
import {SwappingFrom} from '../modules/employee/views/SwappingFrom';
import {UpdateAnchorResourceStatus} from '../modules/employee/views/UpdateAnchorResourceStatus';
import {
  ExtendPIP,
  FinishPIP,
  PIPNextActionForm,
  PotentialPIP,
  StartPIP,
  UpdatePIPMentor,
} from '../modules/employee/views/employeePIPForms';
import {CampaignProspectsMarkVerifyForm} from '../modules/leadGeneration/outreachcampaign-views/CampaignProspectsMarkVerifyForm';
import {CommunicationTable} from '../modules/leadGeneration/outreachcampaign-views/CommunicationTable';
import {OutreachCampaignEmailReplyFormEdit} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignEmailReplyFormEdit';
import {OutreachCampaignNavigator} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignNavigator';
import {ImportHistoryLogsActivityTable} from '../modules/leadGeneration/views/ImportHistoryLogsActivity';
import {ChangePassword} from '../modules/login/views/ChangePassword';
import {AllNotifications} from '../modules/notification/views/AllNotifications';
import FixOrderResourcesAssignForRmList from '../modules/order/views/FixOrderResourcesAssignForRmList';
import {RecieptTab} from '../modules/order/views/RecieptTab';
import VoucherTab from '../modules/order/views/VoucherTab';
import {StopNonProjectAllocation} from '../modules/order/views/employee-allocation/StopNonProjectAllocation';
import {MyChatsList} from '../modules/pmt/views/MyChatsList';
import {OfferJobForm} from '../modules/recruitment/views/OfferJobForm';
import {COORferredReimbursement} from '../modules/reimbursement/views/ReimbursementTabs';
import {CompleteEmployeeTraining} from '../modules/skills/views/CompleteEmployeeTraining';
import {
  ProjectTaskAssignmentReviewerForm,
  ProjectTaskAssignmentTeamForm,
} from '../modules/task/views/ProjectTaskAssignmentForm';
import {UpdateProjectTaskTypeForm} from '../modules/task/views/UpdateProjectTaskTypeForm';
import {
  EditFistResponseTimeForm,
  EditOverallResolutionTime,
  EditWorkingCycleForm,
} from '../modules/tickets/views/EmployeeTicketsForms';
import {EditTicketCategory} from '../modules/tickets/views/TicketCategoryForms';
import {EditTicketOwnerForm} from '../modules/tickets/views/TicketOwnerForms';
import {TicketSetupScreen} from '../modules/tickets/views/TicketSetupScreen';
import {EditTicketSubCategory} from '../modules/tickets/views/TicketSubCategoryForms';

import {
  CustomerDeliveryList,
  CustomerFinishedHoursList,
} from '../modules/common/views/CustomerDeliveryList';
import {
  AddLeaveAllowed,
  EditLeaveAllowed,
} from '../modules/common/views/LeaveAllowedForm';
import {
  AddMyOrderTarget,
  UpdateMyOrderTarget,
} from '../modules/common/views/MyOrderTarget';
import {MyOrdersTargetTable} from '../modules/common/views/MyOrdersTargetTable';
import {
  AddCustomerFormForCustomerNew,
  CreateCustomerForm,
  CustomerDescriptionPopUp,
  DuplicateCustomer,
  DuplicateCustomerPopUp,
  EditCustomerFormNew,
  LostCustomer,
  UpdateCustomerCSM,
} from '../modules/customer/views/CustomerFormNew';
import {
  CustomerContactTable,
  CustomerContactTableWithInlineForm,
} from '../modules/customer/views/CustomerOverview';
import {TATFormDetails} from '../modules/employee/views/RfrTATForm';
import {AnalyticsTable} from '../modules/leadGeneration/outreachcampaign-views/AnalyticsTable';
import {CampaignActionsAnalytics} from '../modules/leadGeneration/outreachcampaign-views/CampaignActionsAnalytics';
import {CampaignContactConnectionList} from '../modules/leadGeneration/outreachcampaign-views/CampaignContactConnectionList';
import {
  FinetuneAddDocument,
  FinetuneEditDocument,
} from '../modules/leadGeneration/outreachcampaign-views/FinetuneAddDocument';
import {FinetuneAddFollowupMessage} from '../modules/leadGeneration/outreachcampaign-views/FinetuneAddFollowupMessage';
import {FinetuneAddMessage} from '../modules/leadGeneration/outreachcampaign-views/FinetuneAddMessage';
import {FinetuneDocumentList} from '../modules/leadGeneration/outreachcampaign-views/FinetuneDocumentList';
import {FinetuneDuplicateForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneDuplicateForm';
import {FinetuneJobForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneJobForm';
import {FinetuneJobList} from '../modules/leadGeneration/outreachcampaign-views/FinetuneJobList';
import {FinetuneJobModelForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneJobModelForm';
import {FinetuneJobSelectForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneJobSelectForm';
import {FinetuneTrainingSampleList} from '../modules/leadGeneration/outreachcampaign-views/FinetuneTrainingSampleList';
import {FinetuneUsecaseSelectForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneUsecaseSelectForm';
import {
  FinetuneUsecasesForm,
  FinetuneUsecasesFormEdit,
} from '../modules/leadGeneration/outreachcampaign-views/FinetuneUsecasesForm';
import {FinetuneUsecasesList} from '../modules/leadGeneration/outreachcampaign-views/FinetuneUsecasesList';
import {OutreachCampaignTableEmailProfileGrouped} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignTableEmailProfileGrouped';
import {PositiveResponseForm} from '../modules/leadGeneration/outreachcampaign-views/PositiveResponseForm';
import {PotentialCustomersHubspotActivitiyTable} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomersHubspotActivitiyTable';
import {PotentialCustomersHubspotLeadForm} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomersHubspotLeadForm';
import {PotentialCustomersHubspotLeadsTable} from '../modules/leadGeneration/outreachcampaign-views/PotentialCustomersHubspotLeadsTable';
import {SetActualScheduleOnForm} from '../modules/leadGeneration/outreachcampaign-views/SetActualScheduleOnForm';
import {ViewAssistantResponse} from '../modules/leadGeneration/outreachcampaign-views/ViewAssistantResponse';
import EmailStatsList from '../modules/leadGeneration/views/EmailStatsList';
import EmailUnsubscribeList from '../modules/leadGeneration/views/EmailUnsubscribeList';
import TokenUsageList from '../modules/leadGeneration/views/TokenUsageList';
import {ApiLogsDetailsForm} from '../modules/logs/views/ApiLogsDetailsForm';
import {
  AddProjectionFromOpportunity,
  OpportunityProjectionFormForTnM,
  ProjectedBillingForm,
} from '../modules/opportunityProjection/views/opportunityProjectionForm';
import {OpportunityProjectionListTeam} from '../modules/opportunityProjection/views/opportunityProjectionLists';
import {ActiveOrderMarkDeliveredForm} from '../modules/order/views/ActiveOrderMarkDeliveredForm';
import {
  ActiveOrderAllocations,
  ActiveOrderAllocationsFixed,
  ActiveOrderOfOrdersAndDeliveries,
  ActiveOrderOfOrdersAndDeliveriesForFixed,
  TandMOrdersAndDelivered,
} from '../modules/order/views/ActiveOrderOfOrdersAndDeliveries';
import {CurrentBussinessCustomerList} from '../modules/order/views/CurrentBussinessCustomerList';
import {CurrentBussinessResourceDeployableList} from '../modules/order/views/CurrentBussinessResourceDeployableList';
import {OrdersAndDeliveriesMenu} from '../modules/order/views/OrdersAndDeliveriesMenu';
import {ProjectionMenu} from '../modules/order/views/ProjectionMenu';
import {RevenueInvoiceTab} from '../modules/order/views/RevenueInvoiceTab';
import {VerifiedOrderOfOrdersAndDeliveries} from '../modules/order/views/VerifiedOrderOfOrdersAndDeliveries';
import {TaskDisqualificationForm} from '../modules/pmt/views/TaskDisqualificationForm';
import {
  AddReferralToOtherOpening,
  ReferralCandidateCommentForm,
  ReferralCandidateForm,
} from '../modules/recruitment/views/AdReferralCandidate';
import {AddLOIForm} from '../modules/recruitment/views/AddLOIDetails';
import {AttritionMenu} from '../modules/recruitment/views/AttritionMenu';
import {
  PracticeWiseResignedTable,
  ReasonWiseResignedTable,
  experienceWiseNonTechTable,
  experienceWiseTechTable,
  totalVoluntaryNonTechTable,
  totalVoluntaryTechTable,
  voluntaryWiseNonTechTable,
  voluntaryWiseTechTable,
} from '../modules/recruitment/views/AttritionTable';
import {CheckPackageBreakdown} from '../modules/recruitment/views/CheckPackageBreakDown';
import {ReferralTable} from '../modules/recruitment/views/ReferralTable';
import {AddFeedbackCriteria} from '../modules/skills/views/AddFeedbackCriteria';
import {AllTraineesOverview} from '../modules/skills/views/AllTraineesTable';
import {AllTrainersOverview} from '../modules/skills/views/AllTrainersList';
import {DayWiseAttendance} from '../modules/skills/views/DayWiseAttendance';
import {InactiveTrainerList} from '../modules/skills/views/InactiveTrainerList';
import {MyFeedbacksTable} from '../modules/skills/views/MyFeedbacks';
import {SelfFeedbacksMenu} from '../modules/skills/views/SelfFeedbackMenu';
import {TraineeFeedbackForTrainerView} from '../modules/skills/views/TraineeFeedbackForTrainer';
import {
  EditTraineeToTrainerFeedbackForm,
  TraineeToTrainerFeedbackDetailRM,
} from '../modules/skills/views/TraineeToTrainerFeedbackForm';
import {
  TraineeToTrainerFeedbacks,
  TraineeToTrainerFeedbacksRM,
} from '../modules/skills/views/TraineeToTrainerFeedbacks';
import {
  TraineeToTrainerFeedbackDetail,
  TraineeToTrainerFeedbackForm,
} from '../modules/skills/views/TraineeToTrainingFeedbackForm';
import {TraineeToTrainingFeedbackTable} from '../modules/skills/views/TraineeToTrainingFeedbackTable';
import {
  EditTrainingAttendance,
  MyAttendanceTable,
  TrainingAttendanceTable,
} from '../modules/skills/views/TrainingAttendanceTable';
import {InactiveTrainerCourses} from '../modules/skills/views/TrainingBatchTable';
import {OrganizationDisqualifiedTasksTable} from '../modules/task/views/DisqualifiedTasksTable';

import {ActionSummaryForm} from '../modules/leadGeneration/outreachcampaign-views/ActionSummaryForm';

import {CustomerProjectTeam} from '../modules/customer/views/CustomerProjectTable';
import {organizationProjectNavigator} from '../modules/employee/views/ProjectsNavigator';
import {
  ActiveEngineeringOpportunitiesListForAnalytics,
  ClosedEngineeringOpportunitiesListForAnalytics,
} from '../modules/engineeringOpportunities/views/EngineeringOpportunitiesActiveList';
import {EngineeringOpportunitiesTabView} from '../modules/engineeringOpportunities/views/EngineeringOpportunitiesTabs';
import {ErrorLogList} from '../modules/leadGeneration/outreachcampaign-views/ErrorLogList';
import {
  UniqueSellingPointAddForm,
  UniqueSellingPointEditForm,
} from '../modules/leadGeneration/outreachcampaign-views/UniqueSellingPointAddForm';
import {UniqueSellingPointList} from '../modules/leadGeneration/views/UniqueSellingPointList';
import {DeliveryTab} from '../modules/order/views/DeliveryTab';
import {ProjectReleasedFeaturesTable} from '../modules/pmt/views/FeaturesTable';
import {ImportTraineeForm} from '../modules/skills/views/ImportTraineeForm';
import {MarkTrainerInactiveForm} from '../modules/skills/views/MarkTrainerInactiveForm';

import {
  AddEscalationReasonForm,
  EditEscalationReasonForm,
} from '../modules/common/views/EscalationReasonForm';
import {
  AddEscalationForm,
  EditEscalationForm,
  resolveEscalation,
} from '../modules/common/views/ProjectEscalationForm';
import {ProjectEscalationTabs} from '../modules/common/views/ProjectEscalationTabs';
import {CustomPlatformMergedTabs} from '../modules/customPlatformNewView/view/customPlatformMergedTab';
import {
  CustomPlatformActiveMergedListForAnalytics,
  CustomPlatformFeatureClosedForAnalytics,
} from '../modules/customPlatformNewView/view/customPlatfromMergedList';
import {
  MarkOpportunityPositonDrop,
  MarkOpportunityPositonLost,
} from '../modules/engineeringOpportunities/views/EnigneeringOpportunitiesForms';
import {FinetuneUseSampleForm} from '../modules/leadGeneration/outreachcampaign-views/FinetuneUseSampleForm';
import {PerosnalizationErrorsList} from '../modules/leadGeneration/views/PerosnalizationErrorsList';

// import { AddBankForm, UpdateBankForm } from '../modules/common/views/BanksForm';
import MasterSetupNavigator from '../modules/common/views/MasterSetupNavigator';
import SetupNavigator from '../modules/common/views/SetupNavigator';
import CustomerNavigator from '../modules/customer/views/CustomerNavigator';
import EmployeeNavigator from '../modules/employee/views/EmployeeNavigator';
import {ManagingTable} from '../modules/employee/views/ManagementCostReportTable';
import {
  CampaignGlobalSettingForm,
  EditCampaignGlobalSettingForm,
} from '../modules/leadGeneration/outreachcampaign-views/CampaignGlobalSettingForm';
import {PotenitalCustomerBusinessSummaryForm} from '../modules/leadGeneration/outreachcampaign-views/PotenitalCustomerBusinessSummaryForm';
import {CampaignGlobalSettingList} from '../modules/leadGeneration/views/CampaignGlobalSettingList';
import CampaignNavigator from '../modules/leadGeneration/views/CampaignNavigator';
import OpportunityNavigator from '../modules/leadGeneration/views/OpportunityNavigator';
import {GmailHistoryLogsTable} from '../modules/logs/views/GmailHistoryLogsTable';
import {OpportunityAnalyticsTable} from '../modules/opportunityAnalytics/views/OpportunityAnalyticsDashboard';
import {
  DeliveryProgressTable,
  EditDeliveryProgress,
} from '../modules/order/views/DeliveryProgressTable';
import ProjectResourceWorkingDataForOrders from '../modules/order/views/ProjectResourceWorkingDataForOrders';
import {TeamTaskMenus} from '../modules/order/views/TeamTaskMenus';
import {
  AddEventTaskAssignmentForm,
  EditEventTaskAssignmentForm,
} from '../modules/task/views/EventTaskAssignmentForm';
import {
  AddRecurringTaskAssignmentForm,
  EditRecurringTaskAssignmentForm,
} from '../modules/task/views/RecurringTaskAssignmentForm';
import {UpdateEmployeeTeamInAssignmentForm} from '../modules/task/views/UpdateEmployeeTeamInAssignmentForm';
// import { UpdateBankForm } from '../modules/common/views/BanksForm';
import {ExpenseMenu} from '../modules/Expense/ExpenseMenu';
import {AssetAssigneeForm} from '../modules/Expense/view/AssetAssigneeForm';
import {
  AssetAssignmentHistoryTable,
  AssetRepairHistoryTable,
} from '../modules/Expense/view/AssetAssignmentHistoryTable';
import {
  AddSubAssetForm,
  AssetRegisterForm,
  DetailAssetRegisterForm,
  NewAssetRegisterFormForIt,
  NewAssetRegisterFormForPi,
} from '../modules/Expense/view/AssetRegisterForm';
import {
  AddBudgetRequest,
  EditBudgetRequest,
  RejectBudgetReason,
} from '../modules/Expense/view/BudgetRequestForm';
import {
  AddExpenseRequest,
  EditExpenseRequest,
  RejectExpenseReason,
} from '../modules/Expense/view/ExpenseRequestForm';
import {ITAssetMenu} from '../modules/Expense/view/ITAssetMenu';
import {MarkUnusableForm} from '../modules/Expense/view/MarkUnusableForm';
import {
  AddPurchaseOrder,
  EditPurchaseOrder,
} from '../modules/Expense/view/PurchaseOrderForm';
import {
  AddQuotationRequest,
  EditQuotationRequest,
  QuotaitonApproveForm,
  RejectQuotationReason,
} from '../modules/Expense/view/QuotationRequestForm';
import {TaggedAssetTable} from '../modules/Expense/view/TaggedAssetTable';
import {UpdateAssetPhotoForm} from '../modules/Expense/view/UpdateAssetPhotoForm';
import {UpdateStatusForm} from '../modules/Expense/view/UpdateStatusForm';
import {VerificationAssetStatus} from '../modules/Expense/view/VerificationAssetStatus';
import {
  AddExpenseApproverLimit,
  EditExpenseApproverLimit,
} from '../modules/common/views/ExpenseApproverLimitForm';
import {MyOrdersTable} from '../modules/common/views/MyOrdersTable';
import {
  AddProjectClientForm,
  UpdateProjectClientForm,
} from '../modules/common/views/ProjectClientForm';
import CustomerNavigatorAm from '../modules/customer/views/CustomerNavigatorAm';
import {ChurnCustomerReportTabView} from '../modules/customer/views/CustomerTableTabView';
import {
  ActiveCustomerTable,
  CustomerForAmReport,
  CustomerTeamSizeList,
} from '../modules/customer/views/CustomersTable';
import {
  AddCurrencyExchangeRateForm,
  EditCurrencyExchangeRateForm,
} from '../modules/customer/views/currencyForm';
import {
  AddDesignationForm,
  EditDesignationForm,
} from '../modules/department/views/Designationform';
import {VendorMenu} from '../modules/department/views/VendorTabView';
import {EditMonthlyAttendance} from '../modules/employee/views/AttendanceSalaryForm';
import {BenchNavigator} from '../modules/employee/views/BenchNavigator';
import {BillingStatusReportTab} from '../modules/employee/views/BillingStatusReportTab';
import {
  AddBloodGroup,
  EditBloodGroup,
} from '../modules/employee/views/BloodGroupForm';
import {AddDeliveryOwnerForm} from '../modules/employee/views/DeliveryOwnerForm';
import {EmployeeDeliveryOwnerTable} from '../modules/employee/views/EmployeeDeliveryOwnerTable';
import {
  AddEmployeeLevel,
  ChangeEmployeeDepartment,
  EditEmployeeLevel,
  PromoteEmployee,
} from '../modules/employee/views/EmployeeLevelForm';
import EmployeeNewNavigator from '../modules/employee/views/EmployeeNewNavigator';
import {
  AddEmployeeSalaryDetail,
  AddYearlyIncentiveForm,
  ChangePackageReview,
  DuplicateEmployeeSalaryDetail,
  EditEmployeeSalaryDetail,
  RenamePackage,
  UpdateCTCAmountForm,
  UploadPackage,
} from '../modules/employee/views/EmployeePackageDetailForm';
import {
  EmployeePackageDetailsForAppraisalTable,
  EmployeePackageDetailsTable,
} from '../modules/employee/views/EmployeePackageDetailsTable';
import {
  AddEmployeeParichayDetailForm,
  EmployeeParichayDocumentForm,
  EmployeeParichayForm,
  EmployeeParichayFormForHr,
  EmployeeParichayUpdateDetailForm,
} from '../modules/employee/views/EmployeeParichayForm';
import {EmployeeReportNavigator} from '../modules/employee/views/EmployeeReportsNavigator';
import {EmployeeSalaryHrMenu} from '../modules/employee/views/EmployeeSalaryHrMenu';
import {EmployeeSetupMenu} from '../modules/employee/views/EmployeeSetupMenu';
import {EmployeeSetupNavigator} from '../modules/employee/views/EmployeeSetupNavigator';
import {EmployeeTeamLeadTable} from '../modules/employee/views/EmployeeTeamLeadTable';
import {
  AddEmployeeType,
  EditEmployeeType,
} from '../modules/employee/views/EmployeeTypeForm';
import {AddEmployeeUserForm} from '../modules/employee/views/EmployeeUserForm';
import {
  EngEfficiencyReport,
  EngGrossMarginReport,
} from '../modules/employee/views/EngGrossMarginReport';
import {EnggAllResourceTabs} from '../modules/employee/views/EnggAllResourceTabs';
import {
  EnggAllResourceBilledAndUnBilledTabs,
  EnggAllResourceTabsForProjectTeam,
} from '../modules/employee/views/EnggAllResourceTabsForProjectTeam';
import {EnggAllResourceTabsMonthWiseForProjectTeam} from '../modules/employee/views/EnggAllResourceTabsMonthWiseForProjectTeam';
import {EngineeringNavigator} from '../modules/employee/views/EngineeringNavigator';
import {EngineeringProjectWiseNavigator} from '../modules/employee/views/EngineeringProjectWiseNavigator';
import {GenerateAttendanceBaseSalary} from '../modules/employee/views/GenerateSalaryForm';
import {ImportEmployeeDeliveryAndTeamLead} from '../modules/employee/views/ImportEmployeeDeliveryAndTeamLead';
import {ManagerSkillForm} from '../modules/employee/views/ManagerDashboard';
import {
  AddManualVariable,
  EditManualVariable,
} from '../modules/employee/views/ManualVariableForm';
import {
  AddNomineeRelation,
  EditNomineeRelation,
} from '../modules/employee/views/NomineeRelationForm';
import {organizationProjectNavigatorAM} from '../modules/employee/views/ProjectNavigatorAm';
import {organizationProjectNavigatorPM} from '../modules/employee/views/ProjectNavigatorPm';
import {ProjectUnbilledNavigatorAm} from '../modules/employee/views/ProjectUnbilledNavigatorAm';
import {ProjectUnbilledNavigatorPm} from '../modules/employee/views/ProjectUnbilledNavigatorPm ';
import {ProjectsUnbilledNavigator} from '../modules/employee/views/ProjectsUnbilledNavigator';
import {PunchDataForm} from '../modules/employee/views/PunchDataForm';
import {
  AddReimbursementHead,
  EditReimbursementHead,
} from '../modules/employee/views/ReimbursementHeadForm';
import {
  AddOwnerForm,
  ReplacementForm,
  UpdateReplacementEmployee,
  updateStatusForm,
} from '../modules/employee/views/ReplacementForm';
import ResignationNavigator from '../modules/employee/views/ResignatioMenu';
import {
  AddSalaryComponent,
  EditSalaryComponent,
} from '../modules/employee/views/SalaryComponentForm';
import {
  AddEsiCycleForm,
  AddLeaveCreditForm,
  AddSalaryCycleForm,
  EditEsiCycleForm,
  EditLeaveCreditForm,
  EditSalaryCycleForm,
} from '../modules/employee/views/SalarySetupForms';
import SkillLeaderDashboard from '../modules/employee/views/SkillLeaderDashboard';
import {
  IncomeTaxCalculate,
  TaxDeclarationForms,
} from '../modules/employee/views/TaxDeclarationNestedForms';
import {AddTechnicalLeadForm} from '../modules/employee/views/TechnicalLeadForm';
import {
  AllResourceOnBench,
  AllResourceOnBenchAnlaytics,
  AvailableResource,
} from '../modules/employee/views/UnavailableList';
import {UpdateAnchorResourceSwappingForm} from '../modules/employee/views/UpdateAnchorResourceSwappingForm';
import {
  EditResourcePractice,
  EditResourceSkill,
} from '../modules/employee/views/employeeskill';
import {TaxDeclarationCard} from '../modules/employee/views/taxDeclarationCard';
import {UploadButton} from '../modules/leadGeneration/buttons/Upload';
import {ActionAnalyticsTab} from '../modules/leadGeneration/outreachcampaign-views/ActionAnalyticsTab';
import {
  AddCampaignForm,
  EditCampaignForm,
} from '../modules/leadGeneration/outreachcampaign-views/AddCampaignForm';
import {
  AddCampaignMessageForm,
  AddCampaignMessageFormEdit,
} from '../modules/leadGeneration/outreachcampaign-views/AddCampaignMessageForm';
import {
  AddMappingForm,
  EditMappingForm,
} from '../modules/leadGeneration/outreachcampaign-views/AddMappingForm';
import {
  AddUserSegmentForm,
  EditUserSegmentForm,
} from '../modules/leadGeneration/outreachcampaign-views/AddUserSegmentForm';
import {CampaignMessagesTab} from '../modules/leadGeneration/outreachcampaign-views/CampaignMessagesTab';
import {DefineSegmentCriteria} from '../modules/leadGeneration/outreachcampaign-views/DefineSegmentCriteria';
import {EmailSyncCommunicationTable} from '../modules/leadGeneration/outreachcampaign-views/EmailSyncCommunicationTable';
import {FetchDataForm} from '../modules/leadGeneration/outreachcampaign-views/FetchDataForm';
import {MoveToBatchForm} from '../modules/leadGeneration/outreachcampaign-views/MoveToBatchForm';
import {OutreachCampaignUSPForm} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignUSPForm';
import {OutreachCampaignUSPTable} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignUSPTable';
import {OutreachCampaignVerifiedTab} from '../modules/leadGeneration/outreachcampaign-views/OutreachCampaignVerifiedTab';
import {CountryList} from '../modules/leadGeneration/views/CountryList';
import {ImportHistoryLogPreviewForm} from '../modules/leadGeneration/views/ImportHistoryLogPreviewForm';
import {ImportMappingList} from '../modules/leadGeneration/views/ImportMappingList';
import {
  CountrySelectorForm,
  IndustrySelectorForm,
} from '../modules/leadGeneration/views/IndustryCountrySelectionForm';
import {IndustryList} from '../modules/leadGeneration/views/IndustryList';
import LeadNavigator from '../modules/leadGeneration/views/LeadMenu';
import {PotentialCustomerTabForLeadToQl} from '../modules/leadGeneration/views/PotentialCustomerTabForLeadToQl';
import {
  StaffaugmentationOppMenu,
  StaffaugmentationOppMenuDeliveryOwners,
} from '../modules/leadGeneration/views/StaffaugmentationOppMenu';
import {UserSegmentContactList} from '../modules/leadGeneration/views/UserSegmentContactList';
import {UserSegmentList} from '../modules/leadGeneration/views/UserSegmentList';
import {UserSegmentProspectList} from '../modules/leadGeneration/views/UserSegmentProspectList';
import {EmailSyncCommunicationView} from '../modules/logs/views/EmailSyncCommunicationView';
import {AddWorkInProgressForm} from '../modules/order/views/AddWorkInProgressForm';
import {
  ClosePartialEngagementForm,
  ClosePartialEngagementTable,
} from '../modules/order/views/ClosePartialEngagementForm';
import {GrowthAndBillingTab} from '../modules/order/views/CurrentBusinessTAndM';
import {CustomerReportsMenu} from '../modules/order/views/CustomerReportsMenu';
import {
  CreateDraftInvoice,
  EditableDraftInvoice,
  NonEditableDraftInvoice,
} from '../modules/order/views/DraftInvoiceForm';
import {ProjectWiseEmployeeRequestTable} from '../modules/order/views/EmployeeRequestTable';
import {MarkOrderClosedForm} from '../modules/order/views/MarkOrderClosedForm';
import {OrderExtendedForm} from '../modules/order/views/OrderExtendedForm';
import {
  FixedOrderMapForm,
  TAndMOrderMapForm,
} from '../modules/order/views/OrderMapForm';
import {OrdersAndDeliveriesForFixedTab} from '../modules/order/views/OrdersAndDeliveriesForFixed';
import {OrdersAndDeliveriesForTAndMTab} from '../modules/order/views/OrdersAndDeliveriesForTAndM';
import {ProjectAllocateResourceTab} from '../modules/order/views/ProjectAllocateResource';
import {ProjectwiseResourceRequestTable} from '../modules/order/views/ProjectwiseResourceRequestTable';
import {
  AgeingReportReceipt,
  RecieptAllTable,
} from '../modules/order/views/RecieptTable';
import {
  AgeingReportInvoice,
  RevenueInvoiceAllTable,
} from '../modules/order/views/RevenueInvoiceTable';
import {UpdateDeliveryOwnerForm} from '../modules/order/views/UpdateDeliveryOwnerForm';
import {UpdateProjectResourceWorking} from '../modules/order/views/UpdateProjectResourceWorking';
import VoucherLineItemTab from '../modules/order/views/VoucherLineItemTable';
import {VoucherTable} from '../modules/order/views/VoucherTable';
import {UpdateLevelsOwner} from '../modules/order/views/employee-allocation/UpdateLevelsOwner';
import {OrderMenu, OrderMenuAM} from '../modules/order/views/orderMenu';
import {MyCommentsList} from '../modules/pmt/views/MyCommentsList';
import {projectEmployees} from '../modules/pmt/views/PerformanceReportTable';
import {ProjectGrossMarginTable} from '../modules/pmt/views/ProjectGrossMarginTable';
import {ProjectOrderMappings} from '../modules/pmt/views/ProjectOrderMappingsTable';
import {AddComponentForm} from '../modules/pmt/views/assets/ComponentForm';
import {
  ProjectEfficiencyTable,
  TeamEfficiencyTable,
} from '../modules/task/views/EfficiencyTable';
import {EventTaskAssignmentTabs} from '../modules/task/views/EventTaskAssignmentTabs';
import {MyOrganizationTaskMenus} from '../modules/task/views/MyOrganizationTaskMenus';
import {MyProjectTaskMenus} from '../modules/task/views/MyProjectTaskMenus';
import {RecurringTaskAssignmentTabs} from '../modules/task/views/RecurringTaskAssignmentTabs';
import {SubTaskTable} from '../modules/task/views/SubTaskTable';
import {
  AddTaskPlanForm,
  UpdateTaskPlanForm,
} from '../modules/task/views/TaskPlanForm';
import {AddDebitNoteForm} from '../modules/vendor/view/DebitNoteForm';
import FinanceNavigator from '../modules/vendor/view/FinanceNavigator';
import {
  AddVendorPaymentFrom,
  EditVendorPaymentForm,
} from '../modules/vendor/view/PaymentForm';
import {
  BankReconcilationTable,
  NotBankReconcilationTable,
  UpdateReconcilationDetailForm,
  bankreconcilation,
} from '../modules/vendor/view/ReconcilationTables';
import {
  PurchaseCategoryOverview,
  PurchaseCategoryOverviewForSalary,
  VendorInvoiceTable,
} from '../modules/vendor/view/VendorInvoiceTable';
import VendorNavigator from '../modules/vendor/view/VendorNavigator';
import {VendorPaymentTable} from '../modules/vendor/view/VendorPaymentTables';
import {VendorReportsMenu} from '../modules/vendor/view/VendorReportsMenu';
import {VoucherTrailTab} from '../modules/vendor/view/VourcherTrailReport';
import {AddDepartment} from '../modules/vendor/view/departmentForm';
// import {StartNoduesEmployee} from '../modules/employee/views/NoduesForms';
import {ProfileDetail} from '@unthinkable/react-login';
import {LeftMenuNewView} from '../components/menu/LeftMenuNewView';
import {AssetAggrementForm} from '../modules/Expense/view/AssetAggrementForm';
import {TaggedWithAssetForm} from '../modules/Expense/view/TaggedWithAssetForm';
import {AddClientProjectMemberForm} from '../modules/common/views/ClientProjectMemberForm';
import {
  AddEntityVariableForm,
  UpdateEntityVariableForm,
} from '../modules/common/views/EntityVariableForm';
import {RequestFeedbackForm} from '../modules/common/views/RequestFeedbackForm';
import {
  AddCategoryForm,
  EditCategoryForm,
} from '../modules/common/views/categoryForm';
import {
  EmployeeAppraisalMenu,
  SelfAppraisalMenu,
} from '../modules/department/views/AppraisalHRMenu';
import {
  AddAppraisalAttachment,
  AddEmployeeToApprasial,
  AddRemark,
  ChangeCloseDate,
  ChangePeopleManager,
  CloseAppraisalManually,
  DownloadAnnualAppraisalLetterForm,
  DownloadBiAnnualAppraisalLetterForm,
  EmployeeAppraisalAccepted,
  HRAppraisalDetail,
  HRUploadLetter,
  ManagerAppraisalDetail,
  PHAppraisalDetail,
  PHFinalAppraisalDetail,
  ProcessAppraisalManually,
  ResendAppraisalLetter,
  SendAppraisalLetter,
  closeAppraisalRequest,
  EditIncementDetail,
  SetExceptionalShiftForm,
} from '../modules/department/views/EmployeeAppraisalForm';
import {EmployeePreviousAppraisalTable} from '../modules/department/views/EmployeeAppraisalTable';
import {
  ChangeReportingTo,
  EmployeeProjectFeedback,
  EmployeeSelfProjectFeedback,
  MarkFeedbackNotRelevant,
} from '../modules/department/views/ProjectFeedbackForm';
import {
  EmployeeRMFeedback,
  EmployeeSelfRMFeedback,
} from '../modules/department/views/RMFeedbackForm';
import {ProjectFeedbackTab} from '../modules/department/views/TeamProjectFeedbackTabs';
import {
  AddPackageStructure,
  EditPackageStructure,
} from '../modules/employee/views/AddPackageStructure';
import {HRAppraisalPackageDetailsTable} from '../modules/employee/views/ArtificialPackageDetailTable';
import {BackgroundVerificationRepotTabs} from '../modules/employee/views/BackgroundVerificationTabs';
import {
  ConfirmationHrMenu,
  ConfirmationManagerMenu,
} from '../modules/employee/views/ConfirmationMenu';
import {
  DepartmentPerformaceDetailsTable,
  DepartmentPerformaceDetailsTableForSalary,
} from '../modules/employee/views/DepartmentPerformaceDetailsTable';
import {
  MarkInactive,
  UpdateDesirableStatus,
  UpdateExittype,
  UpdateRelievingDate,
  UpdateResignReason,
} from '../modules/employee/views/EmployeeExitForm';
import {EmployeeFeedbackMenu} from '../modules/employee/views/EmployeeProjectFeedbackMenu';
import {EmployeeVariableProjectTable} from '../modules/employee/views/EmployeeVariableTable';
import {ExpenseAgeingDetails} from '../modules/employee/views/ExpenseAgeing';
import {
  AddPreparePackageDetail,
  EditPreparePackageDetail,
} from '../modules/employee/views/HRPreparePackageForm';
import {
  AddLeaveRegister,
  EditLeaveRegister,
} from '../modules/employee/views/LeaveRegisterForm';
import {
  ClientFeedbackProjectOverview,
  NoClientFeedbackProjects,
} from '../modules/employee/views/NewProjectTable';
import {
  AccountNoduesEmployee,
  AddFnfFile,
  AdminNoduesEmployee,
  EmployeeNoduesEmployee,
  FnfRecoveryPendingPaymentDetails,
  HRNoduesEmployee,
  ITNetworkNoduesEmployee,
  PaidFnf,
  PaidFnfOverdue,
  PendingPaymentFnf,
  ShareFnf,
  StartNoduesEmployee,
  TeamNoduesEmployee,
  UpdateRemarkForFnf,
  markProcessedForm,
} from '../modules/employee/views/NoduesForms';
import {
  AcceptInternshipAction,
  AcceptProbationAction,
  ExtendInternshipAction,
  ExtendProbationAction,
  RejectInternshipAction,
  RejectProbationAction,
} from '../modules/employee/views/ProbationForms';
import {
  ManagersRemarkOnProductivity,
  MarkProductivitySurveyClosed,
  ProductivitySurveyForm,
} from '../modules/employee/views/ProductivityServeyForm';
import {
  BulkForm16LogDetail,
  UploadBulkForm16,
} from '../modules/employee/views/UploadBulkForm16Form';
import {UpdatePassword} from '../modules/leadGeneration/outreachcampaign-views/UpdatePassword';
import {AddMAForm} from '../modules/order/views/AddMAForm';
import {
  AllAllocatedResourceTab,
  AllocatedResourceTab,
} from '../modules/order/views/AllocatedResourceTab';
import {
  AcceptShiftAllowanceForm,
  GenerateShiftAllowanceFormForRM,
  ProcessedShiftAllowanceForm,
  ProcessedShiftAllowanceMultipleForm,
  RejectShiftAllowanceForm,
  UpdateActualShiftAllowanceForm,
} from '../modules/order/views/ShiftAllowanceRMActionForm';
import {
  AppModuleInputForm,
  EditAppModuleInputForm,
} from '../modules/organization/views/AppModuleInputForm';
import ModuleMenu from '../modules/organization/views/ModuleMenu';
import {
  EditModuleMenuForm,
  ModuleMenuForm,
} from '../modules/organization/views/ModuleMenuForm';
import ModuleMenuRights from '../modules/organization/views/ModuleMenuRights';
import {
  EditModuleMenuRightsForm,
  ModuleMenuRightsForm,
} from '../modules/organization/views/ModuleMenuRightsForm';
import {
  EditUserEntityRoleForm,
  UserEntityRoleForm,
} from '../modules/organization/views/UserEntityRoleForm';
import DepartmentKPITable from '../modules/organizationTask/views/DepartmentKPITable';
import DrillByRoleTable from '../modules/organizationTask/views/DrillByRoleTable';
import DrillByskillTable from '../modules/organizationTask/views/DrillByskillTable';
import {
  EditKPIInputForm,
  KPIInputForm,
} from '../modules/organizationTask/views/KPIInputForm';
import {
  EditKPIPermissionInputForm,
  KPIPermissionInputForm,
} from '../modules/organizationTask/views/KPIPermissionInputForm';
import {
  EditKPITargetInputForm,
  KPITargetInputForm,
} from '../modules/organizationTask/views/KPITargetInputForm';
import {
  EditKRAInputForm,
  KRAInputForm,
} from '../modules/organizationTask/views/KRAInputForm';
import {
  EditKpiApiForm,
  KpiApiForm,
} from '../modules/organizationTask/views/KpiApiInputForm';
import {ClientFeedbackTabs} from '../modules/pmt/views/ClientFeedbackTabs';
import {PendingProjectApprovalsTable} from '../modules/pmt/views/PendingProjectApprovals';
import {
  EditProjectFeedbackForm,
  ProjectFeedbackDetail,
} from '../modules/pmt/views/ProjectFeedbackForms';
import {ProjectMarkHoldForm} from '../modules/pmt/views/ProjectMarkHoldForm';
import {CandidateFeedback} from '../modules/recruitment/views/CandidateFeedbackForm';
import {MyCandidateFeedbackTabs} from '../modules/recruitment/views/MyCandidateFeedbacks';
import {ScheduleInterview} from '../modules/recruitment/views/ScheduleInterview';
import {AddTrainingPurpose} from '../modules/skills/views/TrainingPurposeForm';
import {
  AppResourceForm,
  EditAppResourceForm,
} from '../modules/userManagement/views/AppResourceForm';
import {
  AppRoleForm,
  EditAppRolesForm,
} from '../modules/userManagement/views/AppRoleForm';
import {EditRoleForm, RoleForm} from '../modules/userManagement/views/RoleForm';
import {
  EditRolePermissionForm,
  RolePermissionForm,
} from '../modules/userManagement/views/RolePermissionForm';
import {
  EditUserRoleForm,
  UserRoleForm,
} from '../modules/userManagement/views/UserRoleForm';
import {EditBankForm} from '../modules/vendor/view/BankForm';
import {
  CreateFundTransferForm,
  EditFundTransferForm,
} from '../modules/vendor/view/FundTransferForm';
import {CheckStatementOfAccount} from '../modules/vendor/view/StatementOfAccount';
import {
  BankTransactionFundTable,
  BankTransactionTable,
  BankTxnForm,
} from '../modules/vendor/view/TransactionTables';
import {
  UploadVendorForm16,
  UploadVendorSelfForm16,
  VendorBulkDetailScreen,
} from '../modules/vendor/view/VendorForm16Forms';
// import {AddTrainingPurpose} from '../modules/skills/views/TrainingPurposeForm';
import {
  EditRolePermissionFormDataEntity,
  RolePermissionFormDataEntity,
} from '../modules/userManagement/views/RolePermissionFormDataEntity';
import RolePermissionTable from '../modules/userManagement/views/RolePermissionTable';
import {AboutMeTab} from '../modules/common/views/AboutMeTabs';
import {AddLedgerDownload} from '../modules/vendor/view/LedgerDownloadForm';
import {UserSegmentActiveProspectList} from '../modules/leadGeneration/views/UserSegmentActiveProspectList';
import EmployeesBenchTables from '../modules/organizationTask/views/EmployeesBenchTables';
import {ProjectFeedbackReportOverview} from '../modules/pmt/views/ProjectFeedbacks';
import {BranchCheckout} from '../modules/pmt/actions/BranchCheckout';
import ProjectTablekpi from '../modules/organizationTask/views/ProjectTablekpi';
import {
  AddCreditNoteAm,
  EditCreditNoteAm,
  MarkBilledCreditNote,
  RejectCreditNoteReason,
} from '../modules/order/views/CreditNoteForm';
import {HelpTemplateVideoPlayer} from '../components/helpguide/HelpTemplateVideoPlayer';
import {
  EditHelpScreenInputForm,
  HelpScreenInputForm,
} from '../modules/pmt/views/HelpScreenInputForm';
import {
  EditHelpItemInputForm,
  HelpItemInputForm,
} from '../modules/pmt/views/HelpItemInputForm';
import HelpItemTable from '../modules/pmt/views/HelpItemTable';
import {
  AddUIComponentForm,
  UIComponentDetailForm,
} from '../modules/common/views/UIComponentForm';
import {UpdateDepartmentOnVoucherForm} from '../modules/vendor/view/VoucherLedgerTable';
import {SelectedArchiveTaskForm} from '../modules/task/views/SelectedArchiveTaskForm';
import {IconButton} from '../components/button/IconButton';
import {useTheme} from '@unthinkable/react-theme';
import {Tooltip} from '../components/modal/Tooltip';
import {useAppStateContext} from '../providers/AppState';
import LeadKpiTable from '../modules/organizationTask/views/LeadKpiTable';
import {ModulesActiveTable} from '../modules/pmt/views/ModulesActiveTable';
import {ReportIssueForm} from '../modules/pmt/views/ReportIssueForm';
import {Labels} from '../modules/pmt/constants/Label';
import UserKpisTable from '../modules/organizationTask/views/UserKpisTable';
import {
  EditCollaboratorForm,
  EditThreadForm,
  ThreadForm,
} from '../modules/task/views/AddThreadForm';
import {
  EditThreadGoalForm,
  ThreadGoalForm,
} from '../modules/task/views/AddThreadGoalForm';
import {
  ThreadTaskMenus,
  ThreadTasksTabs,
} from '../modules/task/views/ThreadTasksTabs';
import {
  AddSalaryTemplateDetail,
  DuplicateSalaryTemplate,
  SalaryTemplateDetailForm,
  SalaryTemplateEditDetailForm,
} from '../modules/employee/views/AddSalaryTemplateForm';
import {EmployeeSalaryTemplateTable} from '../modules/employee/views/SalaryTemplateTable';
import {AddDataServiceLibraryForm} from '../modules/pmt/views/assets/DataServiceLibraryForm';
import {AddStyledComponentForm} from '../modules/pmt/views/assets/StyledComponentForm';
import {
  EditThreadMeetingNotesForm,
  ThreadMeetingNotesForm,
} from '../modules/task/views/AddThreadMeetingNotesForm';
import {
  ChannelInputForm,
  EditChannelInputForm,
} from '../modules/leadGeneration/views/ChannelsInputForm';
import {UpdateChannelForm} from '../modules/leadGeneration/views/UpdateChannelForm';
// import {UpdateEmployeeLeavePolicy} from '../modules/leadGeneration/views/AddEmployeeShiftForms';
import {
  UpdateEmployeeHolidayCalendar,
  UpdateEmployeeLeavePolicy,
  UpdateEmployeeScheduleInformation,
  UpdateEmployeeShiftInformation,
} from '../modules/leadGeneration/views/AddEmployeeShiftForms';
import {
  ProjectFeatureTable,
  ProjectWiseFeatureTable,
} from '../modules/employee/views/ProjectFeatureTable';
import {ThreadTabs} from '../modules/task/views/MyThreadTable';
import {ArchivedOrganizationTaskTable} from '../modules/task/views/ArchivedOrganizationTaskTable';
import {AssetRepairHistoryForm} from '../modules/Expense/view/AssetRepairHistoryForm';
import {ProjectAllEfficiencyTab} from '../modules/pmt/views/ProjectEfficiencyTab';
import {OpportunityTimelineReport} from '../modules/leadGeneration/views/OpportunityTimelineReport';
import {AllAttendanceTable} from '../modules/leadGeneration/views/attendance-views/AllAttendanceTable';
import {LibraryTypes} from '../modules/pmt/constants/LibraryConstants';
import {DepartmentWiseEmployeeMenu} from '../modules/department/views/DepartmentWiseEmployeeMenus';
import {DepartmentRoleTable} from '../modules/department/views/DepartmentRolesTable';
import {AssignDepartmentRoleOnEmployee} from '../modules/department/views/DepartmentRoleOnEmployee';
import {EditDepartmentRole} from '../modules/department/views/DepartmentRoleForms';
import {InternalAllocationForm} from '../modules/common/views/InternalAllocationForm';
import {EditDepartmentTag} from '../modules/department/views/DepartmentTagForms';
import {DepartmentTagTable} from '../modules/department/views/DepartmentTagTable';
import {ProjectWiseAllocationTab} from '../modules/order/views/ProjectWiseAllocationsTab';
import {UnbilledTagTab} from '../modules/employee/views/EnggNonBilledTab';
import {SendVariableMail} from '../modules/employee/views/KpiVariableForm';
import {EditCsatSalaryManual} from '../modules/employee/views/CsatSalaryManualForm';
import {TeamOfficeTimeTrackingReport} from '../modules/leadGeneration/views/EmployeeOfficeTrackingReport';
import {BilledProjectStatusWiseTabs} from '../modules/pmt/views/PerformanceTab';
import {
  BilledProjectLeftMenu,
  UnBilledProjectLeftMenu,
} from '../modules/employee/views/ProjectTypeWiseTable';

export const stackScreens = [
  {
    name: 'view-module-menu',
    component: ModuleMenu,
    options: {
      header: props => (
        <NavHeader
          title={`Module Menus (${props?.route?.params?.row?.label})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'view-role-permission',
    component: RolePermissionTable,
    options: {
      header: props => (
        <NavHeader
          title={`Role Permission (${props?.route?.params?.row?.display_label})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'view-module-menu-rights',
    component: ModuleMenuRights,
    options: {
      header: props => <NavHeader title={`Module Menu Rights`} {...props} />,
    },
  },
  {
    name: 'all-rfqs',
    component: AllOpportunityTable,
    options: {
      header: props => (
        <NavHeader
          title={props?.route?.params?.label || 'Opportunities'}
          {...props}
        />
      ),
    },
  },
  {
    name: 'growth-and-billing',
    component: GrowthAndBillingTab,
    options: {
      header: props => <NavHeader {...props} title={`Growth & Billing`} />,
    },
  },
  {
    name: 'project-pending-approvals',
    component: PendingProjectApprovalsTable,
    options: {
      header: props => <NavHeader {...props} title={`Pending Approvals`} />,
    },
  },
  {
    name: 'all-departments',
    component: AllDepartments,
    options: {
      header: props => <NavHeader title="Products" {...props} />,
    },
  },
  {
    name: 'all-products',
    component: AllProducts,
    options: {
      header: props => <NavHeader title="Products" {...props} />,
    },
  },
  {
    name: 'add-employee-parichay-form-screen',
    component: AddEmployeeParichayDetailForm,
    options: {
      header: props => <NavHeader title="Employee Parichay" {...props} />,
    },
    // options: {size: 'extraLarge'},
  },
  {
    name: 'leads',
    component: LeadTableTabView,
    options: {
      header: props => {
        let title =
          props?.route?.params?.product?.name ||
          props?.route?.params?.organization?.name;
        return (
          <NavHeader
            {...props}
            title={title ? `Leads (${title})` : 'My Leads'}
            // actions={[
            //   <AddButton
            //     title="Lead"
            //     view="add-lead"
            //     params={props?.route?.params}
            //   />,
            //   <AddButton
            //     title="Import Leads"
            //     view="import-list"
            //     params={{
            //       ...props?.route?.params,
            //       source: 'leads',
            //       model: 'PotentialCustomer',
            //     }}
            //   />,
            // ]}
          />
        );
      },
    },
  },
  {
    name: 'proposal submitted',
    component: ProposalSubmittedTableTabView,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title="Proposal Submitted"
          actions={[
            <AddButton
              title="Proposal Submitted"
              view="add-proposal-submitted"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'tasks',
    component: TaskTabs,
    options: {
      header: props => <NavHeader {...props} title="Tasks" />,
    },
  },
  {
    name: 'teams',
    component: TeamTableTabView,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title="Teams"
          actions={[
            <AddButton
              title="Request Talent"
              view="add-request-talent"
              params={props?.route?.params}
            />,
            <HeaderButton
              title="My Resources"
              view="my-resource"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'inmail-campaign-list',
    component: InmailCampaignTable,
    options: {
      header: InmailCampaignHeader,
    },
  },
  {
    name: 'email-campaign-list',
    component: EmailCampaignTable,
    options: {
      header: EmailCampaignHeader,
    },
  },
  {
    name: 'data-mining-list',
    component: DataMiningTable,
    options: {
      header: DataMiningHeader,
    },
  },
  {
    name: 'paid-ads-list',
    component: PaidAdsTable,
    options: {
      header: PaidAdHeader,
    },
  },
  {
    name: 'web-page-list',
    component: WebPageTable,
    options: {
      header: WebPageCampaignHeader,
    },
  },
  {
    name: 'other-campaign-list',
    component: InreachCampaignTabs,
    options: {
      header: props => <NavHeader {...props} title="Inreach Campaign" />,
    },
  },
  {
    name: 'all-leads',
    component: AllLeadTableTab,
    options: {
      header: props => <NavHeader {...props} title="All leads" />,
    },
  },
  {
    name: 'all-prospects',
    component: AllProspectTableTab,
    options: {
      header: props => <NavHeader {...props} title="All prospects" />,
    },
  },
  // Akshat Garg - 21/03/24 - Creating a new route for single prospects tab.
  {
    name: 'prospect-contacted-tab',
    component: ProspectContactedTab,
    options: {
      header: props => <NavHeader {...props} title="All prospects" />,
    },
  },
  {
    name: 'prospects',
    component: ProspectsTableTabView,
    options: {
      header: props => {
        let title =
          props?.route?.params?.product?.name ||
          props?.route?.params?.organization?.name;
        return (
          <NavHeader
            {...props}
            title={title ? `Prospects (${title})` : 'My Prospects'}
            // actions={[
            //   <AddButton
            //     title="Prospect"
            //     view="add-prospect"
            //     params={props?.route?.params}
            //   />,
            //   <AddButton
            //     title="Import Prospects"
            //     view="import-list"
            //     params={{
            //       ...props?.route?.params,
            //       source: 'prospects',
            //       model: 'PotentialCustomer',
            //     }}
            //   />,
            // ]}
          />
        );
      },
    },
  },
  {
    name: 'rps-tabs',
    component: RPSTableTabView,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`RPS (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          // actions={[
          //   <AddButton
          //     title="RPS"
          //     view="add-rps"
          //     params={props?.route?.params}
          //   />,
          // ]}
        />
      ),
    },
  },
  {
    name: 'web-page-communication-list',
    component: WebPageTabView,
    options: {
      header: props => (
        <NavHeader
          title="Web Page"
          {...props}
          actions={
            <AddButton
              title="Web Page"
              view="add-webpage"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'staff-augmentation-opportunity-list',
    component: StaffAugmentationOppTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Staff Augmentation Opportunity (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'engineer-opportunities-dashboard',
    component: EngineeringOpportunitiesTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Staff Augmentation Opportunity (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name ||
            ''
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'opportunity-projection-sales-owner-list',
    component: OpportunityProjectionListTeam,
    options: {
      header: props => (
        <NavHeader
          title={`Opportunity Projections (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'customer-presales-list',
    component: CustomerOppTabView,
    options: {
      header: props => <NavHeader title={'Customers'} {...props} />,
    },
  },
  {
    name: 'employee-tab-view',
    component: EmployeeTabs,
    options: {
      header: props => <NavHeader title={'Employees'} {...props} />,
    },
  },
  {
    name: 'opportunity-tab-view',
    component: OpportunityTabView,
    options: {
      header: props => <NavHeader title="Opportunity" {...props} />,
    },
  },
  {
    name: 'interview-opportunity-list',
    component: InterviewStaffAugOppTabView,
    options: {
      header: props => (
        <NavHeader
          title="Staff Augentation Opportunity"
          {...props}
          actions={
            <AddButton
              title="Opportunity"
              view="add-staffaugmentationopportunity"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'communication-list',
    component: CommunicationTabView,
    options: {
      header: props => <NavHeader title="Communications" {...props} />,
    },
  },
  {
    name: 'custome-platform-opportunity-list',
    component: CustomDigitalPlatformOppTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Custom Platform Opportunity (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'custom-platform-merged-view-tabs',
    component: CustomPlatformMergedTabs,
    options: {
      header: props => <NavHeader {...props} title="Custom Platform Opp" />,
    },
  },
  {
    name: 'opportunity-analytics-dashboard',
    component: OpportunityAnalyticsTable,
    options: {
      header: props => (
        <NavHeader
          title={`Opportunity Analaytics(${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'custome-platform-opportunity-list-workspace',
    component: CustomDigitalPlatformOppTabView,
    options: {
      header: props => (
        <NavHeader title={`Custom Platform Opportunity`} {...props} />
      ),
    },
  },
  {
    name: 'show-actions',
    component: ActionsTable,
    options: {
      header: props => {
        const title = props?.route?.params?.title
          ? props?.route?.params?.title
          : props?.route?.params?.showUnverified
          ? `Unverified ${props?.route?.params?.row?.action || 'actions'}`
          : props?.route?.params?.status === 'inprocess'
          ? 'InProcess actions'
          : props?.route?.params?.sent_action
          ? `Completed ${props?.route?.params?.row?.action || 'actions'}`
          : `Verified ${props?.route?.params?.row?.action || 'actions'}`;

        const moreActions = props?.route?.params?.for_training
          ? [
              <HeaderButton
                title="Fetch Data"
                view="fetchData-form"
                params={props?.route?.params}
              />,
            ]
          : [];
        return <NavHeader title={title} actions={moreActions} {...props} />;
      },
    },
  },
  {
    name: 'show-communications',
    component: CommunicationTable,
    options: {
      header: props => {
        const name = props?.route?.params?.name;
        const title = props?.route?.params?.title;
        const headerTitle = name
          ? `Email Replies (${name})`
          : title
          ? `Replies (${title})`
          : 'Communication';

        return <NavHeader title={headerTitle} {...props} />;
      },
    },
  },
  // Akshat Garg - 11/03/24 - route for navigating to communication table on row press of communication view
  {
    name: 'email-sync-communication-table',
    component: EmailSyncCommunicationTable,
    options: {
      header: props => {
        return <NavHeader title={'Communications'} {...props} />;
      },
    },
  },
  {
    name: 'show-analytics-actions',
    component: CampaignActionsAnalytics,
    options: {
      header: props => {
        const name = props?.route?.params?.addOnFilter?.campaign_name;
        const title = props?.route?.params?.addOnFilter?.title;
        const headerTitle = name
          ? `Actions (${title ? `${name} - ${title}` : name})`
          : 'Actions';
        return <NavHeader title={headerTitle} {...props} />;
      },
    },
  },
  {
    name: 'show-outreach-analytics',
    component: AnalyticsTable,
    options: {
      header: props => {
        return <NavHeader title={'Outreach Analytics'} {...props} />;
      },
    },
  },
  {
    name: 'import-history-logs-activity',
    component: ImportHistoryLogsActivityTable,
    options: {
      header: props => {
        return <NavHeader title="View Activity Logs" {...props} />;
      },
    },
  },
  {
    name: 'outreach-campaign-grouped',
    component: OutreachCampaignTableGrouped,
    options: {
      header: props => {
        const title = props?.route?.params?.showUnverified
          ? 'Unverified actions'
          : props?.route?.params?.sent_action
          ? 'Completed actions'
          : 'Verified actions';
        return <NavHeader title={title} {...props} />;
      },
    },
  },

  {
    name: 'outreach-campaign-grouped-analytics',
    component: ActionAnalyticsTab,
    options: {
      header: props => {
        return <NavHeader title={'Actions'} {...props} />;
      },
    },
  },
  {
    name: 'outreach-campaign-email-profile-grouped',
    component: OutreachCampaignTableEmailProfileGrouped,
    options: {
      header: props => {
        const title =
          props?.route?.params?.status === 'error'
            ? 'Error count'
            : props?.route?.params?.status === 'inprocess'
            ? 'InProcess count'
            : props?.route?.params?.sent_action
            ? 'Completed count'
            : 'Scheduled  count';
        return <NavHeader title={title} {...props} />;
      },
    },
  },
  {
    name: 'outreachcampaign-contacts-unverified',
    component: OutreachCampaignContactsUnverified,
    options: {
      header: props => {
        const title = `${props.route.params.campaign_id.name} (Unverified contacts)`;
        return <NavHeader title={title} {...props} />;
      },
    },
  },
  {
    name: 'pre-sales-tab-view',
    component: PreSalesTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Pre Sales (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'quote-submitted-tabs',
    component: ProposalTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Proposal (${props?.route?.params?.product?.name})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'all-targets-tab-list',
    component: AllTargetsTabView,
    options: {
      header: props => <NavHeader title="All Targets" {...props} />,
    },
  },
  {
    name: 'poc-tab-view',
    component: POCOppTabView,
    options: {
      header: props => (
        <NavHeader
          title={`POC (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
          // actions={
          //   <AddButton
          //     title=" POC"
          //     view="add-poc"
          //     params={props?.route?.params}
          //   />
          // }
        />
      ),
    },
  },
  {
    name: 'all-proposal-opportunity-list',
    component: ProposalOppTabView,
    options: {
      header: props => (
        <NavHeader
          title="Custome Platform Opportunity"
          {...props}
          actions={
            <AddButton
              title="Opportunity"
              view="add-customplatformopportunity"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'single-screen-message-list',
    component: SingleScreenMessageTabView,
    options: {
      header: props => (
        <NavHeader
          title="Single Screen Message"
          {...props}
          actions={
            <AddButton
              title="Single Screen Message"
              view="add-singlescreenmessage"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'image-list',
    component: ImageTabView,
    options: {
      header: props => (
        <NavHeader
          title="Image"
          {...props}
          actions={
            <AddButton
              title="Image"
              view="add-image"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'drip-inmail-list',
    component: DripInmailTabView,
    options: {
      header: props => (
        <NavHeader
          title="Drip Inmail"
          {...props}
          actions={
            <AddButton
              title="Drip Inmail"
              view="add-dripinmailcontent"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'drip-inmail-list-for-message-theme',
    component: DripInmailActiveTable,
    options: {
      header: props => (
        <NavHeader
          title="Message"
          {...props}
          actions={
            <AddButton
              title="Message"
              view="add-dripinmailcontent"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'value-proposition-drip-inmail-list',
    component: DripInmailTable,
    options: {
      header: props => (
        <NavHeader
          title="Message"
          {...props}
          actions={
            <AddButton
              title="Message"
              view="add-dripinmailcontent-new"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },

  {
    name: 'carousel-ad-list',
    component: CarouselAdTabView,
    options: {
      header: props => (
        <NavHeader
          title="Carousel Ad"
          {...props}
          actions={
            <AddButton
              title="Carousel Ad"
              view="add-carouselad"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'blog-list',
    component: BlogTabView,
    options: {
      header: props => (
        <NavHeader
          title="Blog"
          {...props}
          actions={
            <AddButton
              title="Blog"
              view="add-blog"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'case-study-list',
    component: CaseStudyTabView,
    options: {
      header: props => (
        <NavHeader
          title="Case Study"
          {...props}
          actions={
            <AddButton
              title="Case Study"
              view="add-casestudy"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'buyer-persona-list',
    component: BuyerPersonaTabView,
    options: {
      header: props => (
        <NavHeader
          title="Buyer Persona"
          {...props}
          actions={
            <AddButton
              title="Buyer Persona"
              view="add-buyerpersona"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'drip-email-list',
    component: DripEmailTabView,
    options: {
      header: props => (
        <NavHeader
          title="Email"
          {...props}
          actions={
            <AddButton
              title=" Drip Email"
              view="add-dripemailcontent"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'drip-email-list-for-message-theme',
    component: DripEmailActiveTable,
    options: {
      header: props => (
        <NavHeader
          title="Message"
          {...props}
          actions={
            <AddButton
              title="Message"
              view="add-dripemailcontent"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'ad-creative-list',
    component: CreativeAdTabView,
    options: {
      header: props => (
        <NavHeader
          title="Ad Creative"
          {...props}
          actions={
            <AddButton
              title=" Ad Creative"
              view="add-adcreatives"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'video-list',
    component: VideoTabView,
    options: {
      header: props => (
        <NavHeader
          title="Video"
          {...props}
          actions={
            <AddButton
              title="Video"
              view="add-video"
              params={props?.route?.params}
            />
          }
        />
      ),
    },
  },
  {
    name: 'order',
    component: OrderTableTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Order (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'orders',
    component: OrderTableTabView,
    options: {
      header: props => <NavHeader title={`Orders`} {...props} />,
    },
  },
  {
    name: 'invoices',
    component: RevenueInvoiceTab,
    options: {
      header: props => <NavHeader title={`My Invoices`} {...props} />,
    },
  },
  {
    name: 'department-outputs-orders',
    component: OrderTabDepartmentOutputView,
    options: {
      header: props => <NavHeader title="Order" {...props} />,
    },
  },
  // {
  //   name: 'invoice',
  //   component: ReceInvoiceTable,
  //   options: {
  //     header: props => (
  //       <NavHeader
  //         title={`Invoice (${
  //           props?.route?.params?.product?.name ||
  //           props?.route?.params?.organization?.name
  //         })`}
  //         {...props}
  //       />
  //     ),
  //   },
  // },
  {
    name: 'input-department-interview',
    component: InputDepartmentInterviewTabView,
    options: {
      header: props => <NavHeader title="Interview" {...props} />,
    },
  },
  {
    name: 'input-department-proposal',
    component: InputDepartmentProposalTabView,
    options: {
      header: props => <NavHeader title="Proposal" {...props} />,
    },
  },
  {
    name: 'new-resource-addition',
    component: NewResourceAdditionTabView,
    options: {
      header: props => <NavHeader title="New Resource Addition" {...props} />,
    },
  },
  {
    name: 'proposal-won',
    component: ProposalWonTabView,
    options: {
      header: props => <NavHeader title="Proposal Won" {...props} />,
    },
  },
  {
    name: 'customer-growth-contract-negotiations',
    component: CustomerGrowthContractNegotiationTab,
    options: {
      header: props => <NavHeader title="Contract Negotiation" {...props} />,
    },
  },
  {
    name: 'customer-acquisition-contract-negotiations',
    component: CustomerAcquisitionContractNegotiationTab,
    options: {
      header: props => <NavHeader title="Contract Negotiation" {...props} />,
    },
  },
  {
    name: 'customer-acquisition-poc',
    component: CustomerAcquisationPOCTab,
    options: {
      header: props => <NavHeader title="Proof Of Concept" {...props} />,
    },
  },
  {
    name: 'customer-growth-poc',
    component: CustomerGrowthPOCTab,
    options: {
      header: props => <NavHeader title="Proof Of Concept" {...props} />,
    },
  },
  {
    name: 'strategic-initiatives',
    component: StrategicInitiativesTable,
    options: {
      header: props => <NavHeader title="Strategic Initiatives" {...props} />,
    },
  },
  {
    name: 'customer-acquisation-proposal',
    component: CustomerAcquisationProposalTab,
    options: {
      header: props => <NavHeader title="Proposal" {...props} />,
    },
  },
  {
    name: 'customer-acquisation-interview',
    component: CustomerAcquisationInterviewTab,
    options: {
      header: props => <NavHeader title="Interview" {...props} />,
    },
  },
  {
    name: 'customer-acquisation-sales-meeting',
    component: SalesMeetingTab,
    options: {
      header: props => <NavHeader title="Sales Meeting" {...props} />,
    },
  },
  {
    name: 'customers-table',
    component: CustomerRevenueTable,
    options: {
      header: props => <NavHeader title="Customers" {...props} />,
    },
  },
  {
    name: 'vendor-invoice-direct-expense-table',
    component: VendorInvoiceTable,
    options: {
      header: props => <NavHeader title="Vendors" {...props} />,
    },
  },
  {
    name: 'show-direct-expense-list',
    component: DirectExpenseInvoiceList,
    options: {
      header: props => <NavHeader title="Vendors" {...props} />,
    },
  },
  {
    name: 'show-expense-list',
    component: ExpenseInvoiceList,
    options: {
      header: props => <NavHeader title="Salary" {...props} />,
    },
  },
  {
    name: 'salary-list',
    component: SalaryTable,
    options: {
      header: props => <NavHeader title="Salary" {...props} />,
    },
  },
  // {
  //   name: 'customer-proposal-list',
  //   component: CustomerProposalList,
  //   options: {
  //     header: props => <NavHeader title="Opportunity" {...props} />,
  //   },
  // },
  {
    name: 'customer-rfr-list',
    component: CustomerRFRList,
    options: {
      header: props => <NavHeader title="RFR" {...props} />,
    },
  },
  {
    name: 'customer-rps-list',
    component: CustomerRPSList,
    options: {
      header: props => <NavHeader title="RPS" {...props} />,
    },
  },
  {
    name: 'marketing-themes',
    component: MarketingThemeTabs,
    options: {
      header: props => (
        <NavHeader
          title={`Outreach Themes (${
            props?.route?.params?.target_audience?.name
              ? props?.route?.params?.target_audience?.name
              : props?.route?.params?.product?.name
          })`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'marketing-concepts-list',
    component: MarketingConceptTable,
  },
  {
    name: 'outreach-batch',
    component: OutreachBatchTabView,
    options: {
      header: props => <OutreachBatchHeader {...props} />,
    },
  },
  {
    name: 'outreach-campaigns',
    component: OutreachInmailCampaignTabs,
    options: {
      header: props => <OutreachCampaignHeader {...props} />,
    },
  },
  {
    name: 'unlock-requests',
    component: PotentialCustomerLocksRequestTable,
    options: {
      header: props => <PotentialCustomerLocksRequestHeader {...props} />,
    },
  },
  {
    name: 'paid-campaign-list',
    component: PaidCampaignTabView,
    options: {
      header: props => <PaidAdCampaignHeader {...props} />,
    },
  },
  {
    name: 'outreach-batch-beta',
    component: OutreachBatchTabViewBeta,
    options: {
      header: props => <OutreachBatchHeader {...props} />,
    },
  },
  {
    name: 'interview-list',
    component: InterviewTabView,
    options: {
      header: props => (
        <NavHeader
          title={`Interview (${props?.route?.params?.product?.name})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'target-audience-list',
    component: TargetAudienceTable,
    options: {
      header: props => (
        <NavHeader
          title={`Target Audience (${props?.route?.params?.product?.name})`}
          {...props}
          // actions={[
          //   <ObjectWiseResources
          //     {...props}
          //     params={{...props?.route?.params}}
          //     object={{
          //       _id: '64ddbdfcc04e3fa90747b2f2',
          //       name: 'BuyerPersona',
          //     }}
          //   />,
          //   <ObjectWiseTasks
          //     {...props}
          //     params={{...props?.route?.params}}
          //     object={{
          //       _id: '64ddbdfcc04e3fa90747b2f2',
          //       name: 'BuyerPersona',
          //     }}
          //   />,
          //   <AddButton
          //     title="Target Audience"
          //     view="add-target-audience"
          //     params={props?.route?.params}
          //   />,
          // ]}
        />
      ),
    },
  },
  {
    name: 'show-lead-list',
    component: CampaignLeadListTab,
    options: {
      header: props => (
        <NavHeader title={props.route.params?.row?.name} {...props} />
      ),
    },
  },
  {
    name: 'email-message-list',
    component: EmailMessageLists,
    options: {
      header: props => (
        <NavHeader
          title={`Series (${props.route.params?.row?.name})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'inmail-message-list',
    component: EmailMessageLists,
    options: {
      header: props => (
        <NavHeader
          title={`Series (${props.route.params?.row?.name})`}
          {...props}
        />
      ),
    },
  },
  {
    name: 'show-value-propositions',
    component: ValuePropositionList,
    options: {
      header: props => (
        <NavHeader
          title={`Value Propositions (${props?.route?.params?.row?.name})`}
          {...props}
          actions={[
            <AddButton
              title="Value Propositions"
              params={props?.route?.params}
              view="add-value-proposition"
              {...props}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'new-value-propositions-list',
    component: ValuePropositionListNew,
    options: {
      header: props => (
        <NavHeader
          title={`Value Propositions (${props?.route?.params?.row?.name})`}
          {...props}
          actions={[
            <AddButton
              title="New Value Propositions "
              params={props?.route?.params}
              view="add-new-value-proposition"
              {...props}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'show-nuturing',
    component: MarketingThemeTabsForNurturing,
    options: {
      header: props => (
        <NavHeader
          title={`Nurturing (${props?.route?.params?.target_audience?.name})`}
          {...props}
          actions={[
            <AddButton
              {...props}
              title="Add Theme"
              view="add-marketing-theme"
              params={{
                ...props?.route?.params,
                campaign_type: '64d0fd55ab78dfead48c144a',
                theme_type: 'nurturing',
              }}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'organization-dashboard',
    component: OrganizationDashboard,
    options: {
      header: props => <NavHeader title={'Organizations'} {...props} />,
    },
  },
  {
    name: 'employee-skill-list',
    component: EmployeeList,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;
        return (
          <NavHeader
            title={`${params?.label} Resources`}
            {...props}
            actions={[
              <AddButton
                {...props}
                title={'Resource'}
                view="add-employee-skill"
                params={params}
              />,
            ]}
          />
        );
      },
    },
  },
  {
    name: 'order-resource-requirement',
    component: OrderResourceRequiremnetTable,
    options: {
      header: props => <NavHeader title={'Due Allocations'} {...props} />,
    },
  },
  {
    name: 'target-audience-tabs',
    component: TargetAudienceTabs,
    options: {
      header: props => <NavHeader title={'Target Audience'} {...props} />,
    },
  },
  {
    name: 'project-resource-working-list',
    component: ProjectResourceWorkingDataTable,
    options: {
      header: props => (
        <NavHeader
          title={'Project Resource Working'}
          {...props}
          actions={[
            <AddButton
              {...props}
              title={'Add Project Resource Working'}
              view="add-project-resource-working"
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'master-task-list',
    component: MasterTaskTable,
    options: {
      header: props => <NavHeader title={'Master Tasks'} {...props} />,
    },
  },
  {
    name: 'master-task-tabs',
    component: MasterTaskTabs,
    options: {
      header: props => <NavHeader title={'Master Tasks'} {...props} />,
    },
  },
  {
    name: 'channel-wise-customer',
    component: ChannelWiseCustomerTab,
    options: {
      header: props => <NavHeader title={'Customer'} {...props} />,
    },
  },
  {
    name: 'channel-wise-rfr',
    component: ChannelWiseStaffAugmentationTab,
    options: {
      header: props => (
        <NavHeader title={'Staff Augmentation Reality'} {...props} />
      ),
    },
  },
  {
    name: 'channel-wise-rfq',
    component: ChannelwiseCustomPlatformTab,
    options: {
      header: props => (
        <NavHeader title={'Custom Platform Opportunity'} {...props} />
      ),
    },
  },
  {
    name: 'channel-wise-campaign',
    component: ChannelWiseCampaignTable,
    options: {
      header: props => <NavHeader title={'Campaign'} {...props} />,
    },
  },
  {
    name: 'daily-attendance-tab',
    component: DailyAttendanceTab,
    options: {
      header: props => <NavHeader title={'Attendance'} {...props} />,
    },
  },
  {
    name: 'team-employee-attendance-tab',
    component: TeamEmployeeAttendanceTab,
    options: {
      header: props => (
        <NavHeader
          title={`${props?.route?.params?.row?.employee?.name} Attendance`}
          {...props}
        />
      ),
    },
  },

  {
    name: 'skill-requirement-table',
    component: SkillRequirementTable,
    options: {
      header: props => <NavHeader title={'Skill Requirement'} {...props} />,
    },
  },
  {
    name: 'order-resource-request-table',
    component: OrderResourceRequestTable,
    options: {
      header: props => <NavHeader title={'Resource Request'} {...props} />,
    },
  },
  {
    name: 'project-order-resource-request-table',
    component: ProjectwiseResourceRequestTable,
    options: {
      header: props => <NavHeader title={'Due Allocation'} {...props} />,
    },
  },
  {
    name: 'event-task-assignment-tabs',
    component: EventTaskAssignmentTabs,
    componentProps: {addOnFilter: {status: 'Completed'}},
    options: {
      header: props => <NavHeader title={'Task Assignments '} {...props} />,
    },
  },
  {
    name: 'show-prospect-count-list',
    component: ContactListForCampaign,
    options: {
      header: props => (
        <NavHeader {...props} title={`${props?.route?.params?.row?.name}`} />
      ),
    },
  },
  {
    name: 'outreach-campaign-contacts',
    component: OutreachCampaignContactTabs,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.campaign_id?.name}(Outreach campaign)`}
        />
      ),
    },
  },
  // Rajnish Trikaldarshi 23/12/2023 - Make a Route to navigate to campaign Prospect tab where we verify and drop the potential customer
  {
    name: 'campaign-prospects-tab',
    component: CampaignProspectsTab,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Manage Prospects(${props?.route?.params?.campaign_id?.name})`}
        />
      ),
    },
  },

  {
    name: 'outreach-campaign-verified',
    component: OutreachCampaignVerifiedTab,
    options: {
      header: props => {
        const title = 'Unverified actions';
        return <NavHeader title={title} {...props} />;
      },
    },
  },

  {
    name: 'outreach-campaign-contacts-accepted',
    component: props => {
      let {route: {params} = {}} = props || {};

      let {campaign_id} = params || {};
      campaign_id = campaign_id._id || campaign_id;
      return (
        <OutreachCampaignContactsList
          {...props}
          campaignContactsfilter={{
            api: `/outreachcampaigns/${campaign_id}/contacts`,
            filter: {
              ...params.filter,
              status: {$in: ['connected', 'nurturing']},
              error: null,
            },
            params,
          }}
        />
      );
    },
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.campaign_id?.name}(Outreach campaign)`}
        />
      ),
    },
  },
  {
    name: 'outreach-targetaudience-contacts',
    component: OutreachTargetAudienceContactTabs,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.target_audience?.name}(Target Audience)`}
        />
      ),
    },
  },
  {
    name: 'outreach-campaign-messages',
    component: OutreachCampaignMessageTable,
    options: {
      header: props => (
        <NavHeader
          title={`${props?.route?.params?.campaign_id?.name}(Messages)`}
          {...props}
          actions={[
            <AddButton
              {...props}
              title={'Load Message'}
              view="add-outreach-campaign-message-form"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'campaign-messages',
    component: CampaignMessagesTab,
    options: {
      header: props => (
        <NavHeader
          title={`${props?.route?.params?.campaign_id?.name}(Messages)`}
          {...props}
          actions={[
            <AddButton
              {...props}
              title={'Load Message'}
              view="add-campaign-message-form"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'outreach-campaign-usps',
    component: OutreachCampaignUSPTable,
    options: {
      header: props => (
        <NavHeader
          title={`${props?.route?.params?.campaign_id?.name}(USPs)`}
          {...props}
          actions={[
            <AddButton
              {...props}
              title={'Add USP'}
              view="add-outreach-usp-form"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'outreach-campaign-contact-messages',
    component: OutreachCampaignContactMessageTable,
    options: {
      header: props => (
        <NavHeader
          title={`${props?.route?.params?.campaign_contact_id?.name}(Messages)`}
          {...props}
          actions={[
            <AddButton
              {...props}
              title={'Load Messages'}
              onPress={() => {}}
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },

  {
    name: 'show-prospect-list',
    component: CampaignProspectListTab,
    options: {
      header: props => (
        <NavHeader {...props} title={`${props?.route?.params?.row?.name}`} />
      ),
      ...(isMobile && {height: '70%'}),
    },
  },
  {
    name: 'show-prospect-list-for-messagesend-click',
    component: CampaignProspectForMessageSendTab,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.row?.name || 'Message Sent'}`}
        />
      ),
      ...(isMobile && {height: '70%'}),
    },
  },
  {
    name: 'show-prospect-list-for-positive-click',
    component: CampaignProspectForPositiveTab,
    options: {
      header: props => (
        <NavHeader {...props} title={`${props?.route?.params?.row?.name}`} />
      ),
    },
  },
  {
    name: 'show-milestones',
    component: MilestonesTab,
    options: {
      header: props => <NavHeader {...props} title={`Milestone`} />,
    },
  },
  {
    name: 'employee-skills-overview',
    component: EmployeeSkillTable,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={props?.routes?.params?.label || '' + 'Employees'}
        />
      ),
    },
  },
  {
    name: 'training-material-list',
    component: TrainingMaterialTable,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={props?.routes?.params?.label || '' + 'Training Material'}
        />
      ),
    },
  },
  {
    name: 'team-employee-efficiency',
    component: TeamEfficiencyTable,
    options: {
      header: props => <NavHeader {...props} title={`Team Output`} />,
    },
  },
  {
    name: 'employee-team-workload',
    component: EmployeeWorkloadTabs,
    options: {
      header: props => (
        <NavHeader {...props} title={`Employee Team Workload`} />
      ),
    },
  },
  {
    name: 'employee-team-project',
    component: ProjectsTable,
    options: {
      header: props => <NavHeader {...props} title={`Projects`} />,
    },
  },
  {
    name: 'coo-referred-reimbursements',
    component: COORferredReimbursement,
    options: {
      header: props => (
        <NavHeader {...props} title={`Referred Reimbursements`} />
      ),
    },
  },
  {
    name: 'fixed-employee-request-tab',
    component: FixedOrderEmployeeRequestTab,
    options: {
      header: props => <NavHeader {...props} title={`Employee Request`} />,
    },
  },
  {
    name: 'employee-team-organization-efficiency',
    component: EmployeeTeamOrganizationEfficiency,
    options: {
      header: props => (
        <NavHeader {...props} title={`Organization Efficiency`} />
      ),
    },
  },
  {
    name: 'project-allocations-tab',
    component: DetailResourceAllocatedList,
    options: {
      header: props => <NavHeader {...props} title={`Resource Allocation`} />,
    },
  },
  {
    name: 'employee-training-list',
    component: EmployeeTrainingList,
    options: {
      header: props => <NavHeader {...props} title={`Mentees`} />,
    },
  },
  {
    name: 'employee-training-tabs',
    component: EmployeeTrainingTabs,
    options: {
      header: props => <NavHeader {...props} title={`Mentees`} />,
    },
  },
  {
    name: 'adhoc-task-tabs',
    component: AdHocTaskTabs,
    options: {
      header: props => <NavHeader {...props} title="Custom Tasks" />,
    },
  },
  {
    name: 'training-feedbacks',
    component: TrainingFeedback,
    options: {
      header: props => <NavHeader {...props} title="Training Feedbacks" />,
    },
  },
  {
    name: 'pip-training-feedbacks',
    component: PIPTrainingFeedback,
    options: {
      header: props => <NavHeader {...props} title="PIP Feedbacks" />,
    },
  },
  {
    name: 'monthly-workload-overview',
    component: MonthlyWorkloadOverviewTable,
    options: {
      header: props => <NavHeader {...props} title={`Monthly Workload`} />,
    },
  },
  {
    name: 'training-material-inline-list',
    component: TrainingMaterialListWithInlineForm,
    options: {
      header: props => {
        const {
          route: {params = {}},
        } = props;
        return <NavHeader {...props} title={`${params?.label} (Skill)`} />;
      },
    },
  },
  {
    name: 'linkedin-profile-list',
    component: LinkedInProfileList,
    options: {
      header: props => <NavHeader {...props} title={`LinkedInProfile List`} />,
    },
  },
  {
    name: 'email-profile-list',
    component: EmailProfileList,
    options: {
      header: props => <NavHeader {...props} title={`EmailProfile List`} />,
    },
  },
  // Make Route for navigate to unique selling point list - Parveen 13-02-2024
  {
    name: 'unique-selling-point-list',
    component: UniqueSellingPointList,
    options: {
      header: props => (
        <NavHeader {...props} title={`Unique Selling Point List`} />
      ),
    },
  },

  // Make Route for navigate to gmail history logs table - Rajnish Trikaldarshi 23-02-2024
  {
    name: 'gmail-history-logs',
    component: GmailHistoryLogsTable,
    options: {
      header: props => <NavHeader {...props} title={`Gmail History Logs`} />,
    },
  },
  // Akshat Garg - 11/03/24 - route for navigating to view communications
  {
    name: 'email-sync-communication-view',
    component: EmailSyncCommunicationView,
    options: {
      header: props => <NavHeader {...props} title={`Communications`} />,
    },
  },
  // Make route for navigate to campaign global setting - Akshat Garg 23/02/24
  {
    name: 'campaign-global-setting',
    component: CampaignGlobalSettingList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Campaign Global Setting`}
          actions={[
            <AddButton title="Add" view="add-campaign-global-setting" />,
          ]}
        />
      ),
    },
  },
  {
    name: 'segment-list',
    component: UserSegmentList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Batches`}
          actions={[
            <AddButton title="Add Batch" view="add-segment" />,
            <UploadButton
              props={{
                ...props,
                source: 'segmentation',
                model: 'UserSegmentContact',
              }}
              navigation={props?.navigation}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'user-segment-contact-list',
    component: UserSegmentContactList,
    options: {
      header: props => (
        <NavHeader {...props} title={`User Segments Contacts`} />
      ),
    },
  },
  {
    name: 'user-segment-prospect-list',
    component: UserSegmentProspectList,
    options: {
      header: props => <NavHeader {...props} title={`Prospects`} />,
    },
  },
  {
    name: 'user-segment-active-prospect-list',
    component: UserSegmentActiveProspectList,
    options: {
      header: props => <NavHeader {...props} title={`Prospects`} />,
    },
  },

  {
    name: 'mapping-list',
    component: ImportMappingList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Mappings`}
          actions={[<AddButton title="Add Mapping" view="add-mapping" />]}
        />
      ),
    },
  },

  {
    name: 'industry-list',
    component: IndustryList,
    options: {
      header: props => <NavHeader {...props} title={`Industry`} />,
    },
  },
  {
    name: 'country-list',
    component: CountryList,
    options: {
      header: props => <NavHeader {...props} title={`Country`} />,
    },
  },
  {
    name: 'perosnalization-issues',
    component: PerosnalizationErrorsList,
    options: {
      header: props => (
        <NavHeader {...props} title={`Personalization Errors List`} />
      ),
    },
  },
  {
    name: 'email-unsubscribe-list',
    component: EmailUnsubscribeList,
    options: {
      header: props => <NavHeader {...props} title={`EmailUnsubscribe List`} />,
    },
  },
  {
    name: 'token-usage-list',
    component: TokenUsageList,
    options: {
      header: props => <NavHeader {...props} title={`Token Usage List`} />,
    },
  },
  {
    name: 'email-provider-list',
    component: EmailProviderList,
    options: {
      header: props => <NavHeader {...props} title={`EmailProvider List`} />,
    },
  },
  {
    name: 'email-signature-list',
    component: EmailSignatureList,
    options: {
      header: props => <NavHeader {...props} title={`EmailSignature List`} />,
    },
  },
  {
    name: 'hubspot-contact-list',
    component: PotentialCustomersHubspotLeadsTable,
    options: {
      header: props => <NavHeader {...props} title={`Hubspot Contacts`} />,
    },
  },
  {
    name: 'page-vists-hubspots',
    component: PotentialCustomersHubspotActivitiyTable,
    options: {
      header: props => {
        let {route: {params: {name = ''} = {}} = {}} = props;
        return <NavHeader {...props} title={`Page Visits (${name})`} />;
      },
    },
  },
  // Make Route for navigate to campaign contact connection list - Rajnish Trikaldarshi 13-01-2024
  {
    name: 'contact-connection-list',
    component: CampaignContactConnectionList,
    options: {
      header: props => (
        <NavHeader {...props} title={`Campaign Contact Connection List`} />
      ),
    },
  },
  {
    name: 'fine-tune-use-case-list',
    component: FinetuneUsecasesList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Finetune UseCases`}
          actions={[
            <AddButton
              title="Add"
              view="fine-tune-use-case-form"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'fine-tune-training-sample-list',
    component: FinetuneTrainingSampleList,
    options: {
      header: props => (
        <NavHeader {...props} title={`Finetune Training Sample`} />
      ),
    },
  },
  {
    name: 'error-logs',
    component: ErrorLogList,
    options: {
      header: props => <NavHeader {...props} title={`Error Logs`} />,
    },
  },

  {
    name: 'fine-tune-job-list',
    component: FinetuneJobList,
    options: {
      header: props => <NavHeader {...props} title={`Finetune Jobs`} />,
    },
  },
  {
    name: 'bulk-form-16',
    component: Form16Tabs,
    options: {
      header: props => <NavHeader {...props} title={`Tax Declaration`} />,
    },
  },
  {
    name: 'payroll-attendance',
    component: AttendanceTab,
    options: {
      header: props => <NavHeader {...props} title={`Attendance`} />,
    },
  },
  {
    name: 'team-task-tabs',
    component: TeamTaskTabs,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.employee_team?.name || ''} Tasks`}
        />
      ),
    },
  },
  {
    name: 'jobopening-candidate-tabs',
    component: JobOpeningCandidateTab,
    options: {
      header: props => <NavHeader {...props} title={`Candidate`} />,
    },
  },
  {
    name: 'ticket-head-tabs',
    component: TicketHeadTabs,
    options: {
      header: props => <NavHeader {...props} title={`Tickets`} />,
    },
  },
  {
    name: 'ticket-setup',
    component: TicketSetupScreen,
    options: {
      header: props => <NavHeader {...props} title={`Setup`} />,
    },
  },
  {
    name: 'all-campaign-contact',
    component: AllCampaignContactList,
    options: {
      header: props => <NavHeader {...props} title={`All Campaign Contacts`} />,
    },
  },

  // Rajnish Trikaldarshi 28-12-2023 - Make Route to open campaign contacts through campaignProspects Tab.
  {
    name: 'all-campaign-contact_from_prospects',
    component: OutreachCampaignContactsList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`All Campaign Contacts (${
            props?.route?.params?.potential_customer?.name || ''
          })`}
        />
      ),
    },
  },
  {
    name: 'vouchers',
    component: VoucherTab,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'voucherslist',
    component: VoucherTable,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'hr-show-appraisal-package-details',
    component: HRAppraisalPackageDetailsTable,
    options: {
      header: props => <NavHeader {...props} title="Package Details" />,
    },
  },
  {
    name: 'ph-show-package-details',
    component: EmployeePackageDetailsForAppraisalTable,
    options: {
      header: props => <NavHeader {...props} title="Package Details" />,
    },
  },
  {
    name: 'show-employee-previous-appraisal',
    component: EmployeePreviousAppraisalTable,
    options: {
      header: props => <NavHeader {...props} title="Previous Appraisal" />,
    },
  },
  {
    name: 'show-employee-project-feedback',
    component: ProjectFeedbackTab,
    options: {
      header: props => <NavHeader {...props} title="Previous Appraisal" />,
    },
  },
  {
    name: 'invoice-payment',
    component: VendorPaymentTable,
    options: {
      header: props => <NavHeader title="Payments" {...props} />,
    },
  },
  {
    name: 'voucherstraillist',
    component: VoucherTrailTab,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'voucherlineitems',
    component: VoucherLineItemTab,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'Chat',
    component: MyChatsList,
    options: {
      header: props => <NavHeader {...props} title="Chats" />,
    },
  },
  {
    name: 'receipts',
    component: RecieptTab,
    options: {
      header: props => <NavHeader {...props} title="My Receipts" />,
    },
  },
  {
    name: 'deliveries',
    component: DeliveryTab,
    options: {
      header: props => <NavHeader {...props} title="My Deliveries" />,
    },
  },
  {
    name: 'referred-candidate',
    component: ReferralTable,
    options: {
      header: props => <NavHeader {...props} title="Referrals" />,
    },
  },
  // Make oute to navigate to campaign contact's connection list - Rajnish Trikaldarshi 12-01-2024
  {
    name: 'show-campaign-connection-list',
    component: CampaignContactConnectionList,
    options: {
      header: props => (
        <NavHeader title="Campaign Connection List" {...props} />
      ),
    },
  },

  {
    name: 'show-outreach-campaign-error',
    component: ErrorActionsTable,
    options: {
      header: props => <NavHeader title="Error" {...props} />,
    },
  },
  {
    name: 'fine-tune-document-list',
    component: FinetuneDocumentList,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Finetune Document`}
          actions={[
            <AddButton
              title="Add"
              view="fine-tune-add-document"
              params={props?.route?.params}
            />,
          ]}
        />
      ),
    },
  },
  {
    name: 'fine-tune-add-document',
    component: FinetuneAddDocument,
    options: {
      header: props => <NavHeader {...props} title={`Finetune Add Document`} />,
    },
  },
  {
    name: 'edit-fine-tune-document',
    component: FinetuneEditDocument,
    options: {
      header: props => (
        <NavHeader {...props} title={`Finetune Edit Document`} />
      ),
    },
  },
  {
    name: 'bankreconcilelist',
    component: BankReconcilationTable,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'banknotreconcilelist',
    component: NotBankReconcilationTable,
    options: {
      header: props => <NavHeader {...props} title="Vouchers" />,
    },
  },
  {
    name: 'my-candidate-feedbacks',
    component: MyCandidateFeedbackTabs,
    options: {
      header: props => <NavHeader {...props} title="Candidate Feedbacks" />,
    },
  },
  {
    name: 'client-feedback-tabs',
    component: ClientFeedbackTabs,
    options: {
      header: props => <NavHeader {...props} title="Client Feedbacks" />,
    },
  },
  {
    name: 'view-department_kpis',
    component: DepartmentKPITable,
    options: {
      header: props => (
        <NavHeader
          showBack
          {...props}
          title={`Department KPIs(${props?.route?.params?.row?.name})`}
        />
      ),
    },
  },

  {
    name: 'view-user-kpis',
    component: UserKpisTable,
    options: {
      header: props => (
        <NavHeader
          showBack
          {...props}
          title={`User KPIs(${props?.route?.params?.row?.name})`}
        />
      ),
    },
  },
  {
    name: 'view-drill-by-skills',
    component: DrillByskillTable,
    options: {
      header: props => {
        return (
          <NavHeader
            showBack
            {...props}
            title={`View By Skill(${props?.route.params.row.kpi_id.name})`}
          />
        );
      },
    },
  },
  {
    name: 'view-drill-by-roles',
    component: DrillByRoleTable,
    options: {
      header: props => (
        <NavHeader
          showBack
          {...props}
          title={`View By Role(${props?.route?.params?.row?.kpi_id?.name})`}
        />
      ),
    },
  },
  {
    name: 'help-screen-item',
    component: HelpItemTable,
    options: {
      header: props => (
        <NavHeader
          showBack
          {...props}
          title={`Help Screen Item (${props?.route?.params?.row?.help_text_id})`}
        />
      ),
    },
  },
  {
    name: 'client-feedback-project-overview',
    component: ClientFeedbackProjectOverview,
    options: {
      header: props => <NavHeader {...props} title="Projects" />,
    },
  },
  {
    name: 'project-feedback-report-overview',
    component: ProjectFeedbackReportOverview,
    options: {
      header: props => <NavHeader {...props} title="Client Feedbacks" />,
    },
  },
  {
    component: NoClientFeedbackProjects,
    name: 'no-client-feedback-projects',
    options: {
      header: props => <NavHeader {...props} title="Projects" />,
    },
  },
  {
    component: DepartmentPerformaceDetailsTable,
    name: 'department-performance-details',
    options: {
      header: props => (
        <NavHeader {...props} title="Department Expense Analysis" />
      ),
    },
  },
  {
    component: DepartmentPerformaceDetailsTableForSalary,
    name: 'department-performance-details-salary',
    options: {
      header: props => (
        <NavHeader {...props} title="Department Expense Analysis" />
      ),
    },
  },
  {
    component: PurchaseCategoryOverview,
    name: 'purchase-category-overview',
    options: {
      header: props => <NavHeader {...props} title="Purchase Category" />,
    },
  },
  {
    component: PurchaseCategoryOverviewForSalary,
    name: 'purchase-category-overview-salary',
    options: {
      header: props => <NavHeader {...props} title="Purchase Category" />,
    },
  },
  {
    name: 'team-thread-tabs',
    component: ThreadTabs,
    options: {
      header: props => {
        return (
          <NavHeader
            {...props}
            title={`${
              props?.route?.params?.employee_team?.name ||
              props?.route?.params?.thread?.name
            } Threads`}
          />
        );
      },
    },
  },
  // {
  //   name: 'project-billed-screen',
  //   component: BilledProjectStatusWiseTabs,
  //   options: {
  //     header: props => (
  //       <NavHeader
  //         back
  //         navigation={props?.navigation}
  //         route={{
  //           ...props.route,
  //           params: {
  //             department: props?.route?.params.department,
  //             tag: props?.route?.params?.tag,
  //           },
  //         }}
  //         title={`${props?.route?.params?.navTitle}`}
  //         secondaryTitle={props?.route?.params?.title}
  //       />
  //     ),
  //   },
  // },
  {
    name: 'departmentbilledemployees',
    component: AllocatedResourceTab,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.department,
              tag: props?.route?.params?.tag,
            },
          }}
          title={`${props?.route?.params?.navTitle}`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
  {
    name: 'departmentunbilledemployees',
    component: UnbilledTagTab,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.department,
              tag: props?.route?.params?.tag,
            },
          }}
          title={`${props?.route?.params?.navTitle}`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
];

export const leftMenuScreens = [
  {
    name: 'dashboard',
    component: UserDashboard,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'profile-detail',
    component: ProfileDetail,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'app-module',
    path: ':appModule_id/:itemId/:screenId/:tabId?',
    component: LeftMenuNewView,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'project-detail-new',
    path: '/:appModule_id/projects/:parentTabApiKey/:project_id/:itemId/:screenId/:tabId?',
    requiredFields: ['project'],
    pathParams: async ({fetch, route}) => {
      const {data} = await fetch({
        uri: '/project-route/detail',
        props: {
          addOnFilter: {
            _id: route?.params?.project_id,
          },
        },
      });
      return {
        project: data[0],
      };
    },
    component: LeftMenuNewView,
    options: {
      header: props => {
        return <NavHeader {...props} />;
      },
    },
  },
  {
    name: 'module-detail-new',
    path: '/:appModule_id/modules/:parentTabApiKey/:module_id/:itemId/:screenId/:tabId?',
    requiredFields: ['module'],
    pathParams: async ({fetch, newParams}) => {
      const {data} = await fetch({
        uri: '/project-route/detail',
        props: {
          addOnFilter: {
            _id: newParams?.module?.project_id,
          },
        },
      });
      return {
        project: data[0],
        parentItems: ['projects', 'modules'],
      };
    },
    component: LeftMenuNewView,
    options: {
      header: props => {
        return <NavHeader {...props} />;
      },
    },
  },
  {
    name: 'library-detail',
    path: '/:appModule_id/projectlibraries/:projectlibrary_id/:itemId/:screenId/:tabId?',
    requiredFields: ['projectlibrary'],
    pathParams: async ({fetch, newParams}) => {
      const {type, project_id} = newParams?.projectlibrary || {};
      const {data} = await fetch({
        uri: '/project-route/detail',
        props: {
          addOnFilter: {
            _id: project_id,
          },
        },
      });

      let parentMap = {
        [LibraryTypes.BOILERPLATE]: ['projects', 'boilerplateLibrary'],
        [LibraryTypes.COMPONENT]: ['projects', 'uiComponentLibrary'],
        [LibraryTypes.STYLED_COMPONENT]: ['projects', 'styledComponentLibrary'],
        [LibraryTypes.DATA_SERVICE]: ['projects', 'dbServicesLibrary'],
        [LibraryTypes.THEME]: ['projects', 'themeLibrary'],
      };

      return {
        project: data[0],
        parentItems: parentMap[type],
      };
    },
    component: LeftMenuNewView,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'feature-detail-screen-new',
    path: '/:appModule_id/features/:parentTabApiKey/:feature_id/:itemId/:screenId/:tabId?',
    requiredFields: ['feature'],
    pathParams: async ({fetch, route, newParams}) => {
      let featureData = newParams?.feature;

      if (!featureData) {
        const {data} = await fetch({
          uri: '/feature-route/detail',
          props: {
            addOnFilter: {
              _id: route?.params?.feature_id,
            },
          },
        });
        featureData = data[0];
      }

      const {data: projectData} = await fetch({
        uri: '/project-route/detail',
        props: {
          addOnFilter: {
            _id: featureData?.project_id,
          },
        },
      });
      return {
        feature: featureData,
        project: projectData[0],
        project_id: featureData?.project_id?._id,
        featureType: featureData?.type,
        feature_id: featureData?._id,
        module: featureData?.module_id,
        module_id: featureData?.module_id?._id,
        milestone: featureData?.milestone_id,
        milestone_id: featureData?.milestone_id?._id,
        sub_status: featureData?.sub_status,
        parentItems:
          route?.params?.appModule_id === 'task'
            ? ['taskprojects']
            : ['projects', Labels.Feature_lower],
        breadcrumb: {
          title: featureData?.feature,
        },
      };
    },
    component: LeftMenuNewView,
    options: {
      header: props => {
        return (
          <NavHeader actions={[<BranchCheckout {...props} />]} {...props} />
        );
      },
    },
  },
  {
    name: 'milestone-menu-new',
    path: '/:appModule_id/milestones/:parentTabApiKey/:milestone_id/:itemId/:screenId/:tabId?',
    requiredFields: ['milestone'],
    pathParams: async ({fetch, newParams}) => {
      const {data} = await fetch({
        uri: '/project-route/detail',
        props: {
          addOnFilter: {
            _id: newParams?.milestone?.project_id,
          },
        },
      });
      return {
        project: data[0],
        project_id: newParams?.milestone?.project_id,
        milestone_id: newParams?.milestone?._id,
        parentItems: ['projects', 'milestone'],
        breadcrumb: {
          title: newParams?.milestone?.milestone,
        },
      };
    },
    component: LeftMenuNewView,
    options: {
      header: props => {
        return <NavHeader {...props} />;
      },
    },
  },
  {
    name: 'project-team-active-tasks-new',
    // path: '/:appModule_id/modules/:parentTabApiKey/:module_id/:itemId/:screenId/:tabId?',
    component: LeftMenuNewView,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'employee-efficiency-detail-new',
    component: LeftMenuNewView,
    options: {
      header: props => <NavHeader {...props} />,
    },
  },
  {
    name: 'customer-details',
    component: CustomerDetailDashboard,
    options: {
      header: props => {
        return (
          <NavHeader
            // title={`Customer Details (props?.route.params?.row?.name}`}
            title={`Customer Details (${props?.route?.params?.row?.name})`}
            {...props}
          />
        );
      },
    },
  },
  {
    name: 'department-dashboard',
    component: DepartmentDashboard,
    options: {
      header: props => (
        <NavHeader title={props.route?.params?.department?.name} {...props} />
      ),
    },
  },
  {
    name: 'all-departments-dashboard',
    component: ProductNavigator,
    options: {
      header: props => (
        <NavHeader title={props?.route?.params?.product?.name} {...props} />
      ),
    },
  },
  {
    name: 'organization-navigator',
    component: OrganizationNavigator,
    options: {
      header: props => (
        <NavHeader
          title={props?.route?.params?.organization?.name}
          {...props}
        />
      ),
    },
  },
  {
    name: 'company-setup',
    component: CompanySetup,
    options: {
      header: props => <NavHeader title="Technical Setup" {...props} />,
    },
  },
  {
    name: 'organization-setup',
    component: OrganizationSetupMenu,
    options: {
      header: props => <NavHeader title="Organization Setup" {...props} />,
    },
  },
  {
    name: 'employee-setup-navigator',
    component: EmployeeSetupNavigator,
    options: {
      header: props => <NavHeader title="Employee Setup" {...props} />,
    },
  },
  {
    name: 'employee-report-navigator',
    component: EmployeeReportNavigator,
    options: {
      header: props => <NavHeader title="Employee Reports" {...props} />,
    },
  },
  {
    name: 'setup',
    component: SetupMenu,
    options: {
      header: props => <NavHeader title="Admin Setup" {...props} />,
    },
  },
  {
    name: 'request-talent',
    component: TalentManagement,
    options: {
      header: props => <NavHeader title={`Talent Management`} {...props} />,
    },
  },
  {
    name: 'creative',
    component: CreativeMenu,
    options: {
      header: props => <NavHeader title={`Creative`} {...props} />,
    },
  },
  {
    name: 'customers',
    component: CustomerMenu,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Customers (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
        />
      ),
    },
  },
  {
    name: 'customer-dashboard',
    component: CustomerNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Customers`} />,
    },
  },
  {
    name: 'customer-dashboard-am',
    component: CustomerNavigatorAm,
    options: {
      header: props => <NavHeader {...props} title={`Customers`} />,
    },
  },
  {
    name: 'vendor-dashboard',
    component: VendorNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Vendor`} />,
    },
  },
  {
    name: 'finance-dashboard',
    component: FinanceNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Finance`} />,
    },
  },
  {
    name: 'performance',
    component: PerformanceMenu,
    options: {
      header: props => <NavHeader {...props} title={`Performance`} />,
    },
  },
  {
    name: 'employee-menu',
    component: EmployeeNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Employees`} />,
    },
  },
  {
    name: 'employee-new-menu',
    component: EmployeeNewNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Employees`} />,
    },
  },
  {
    name: 'opportunity-menu',
    component: OpportunityNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Opportunities`} />,
    },
  },
  {
    name: 'customer-group-menu',
    component: CustomerNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Customers`} />,
    },
  },
  {
    name: 'campaigns-menu',
    component: CampaignNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Campaigns`} />,
    },
  },

  {
    name: 'admin-setup-navigator',
    component: SetupNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Admin Setup`} />,
    },
  },
  {
    name: 'technical-setup-navigator',
    component: MasterSetupNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Technical Setup`} />,
    },
  },
  {
    name: 'organization-projects-menu',
    component: organizationProjectNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Projects`} />,
    },
  },
  {
    name: 'projects-unbilled-menu',
    component: ProjectsUnbilledNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Projects`} />,
    },
  },
  {
    name: 'my-project-task-menu',
    component: MyProjectTaskMenus,
    options: {
      header: props => <NavHeader {...props} title={`Project Tasks`} />,
    },
  },
  {
    name: 'my-organization-task-menu',
    component: MyOrganizationTaskMenus,
    options: {
      header: props => <NavHeader {...props} title={`Custom Tasks`} />,
    },
  },
  {
    name: 'invoice-menu',
    component: InvoiceMenu,
    options: {
      header: props => <NavHeader {...props} title={`Invoice`} />,
    },
  },

  {
    name: 'invoice-menu-am',
    component: InvoiceMenuAm,
    options: {
      header: props => <NavHeader {...props} title={`Invoice`} />,
    },
  },

  {
    name: 'skills-and-training-hr',
    component: SkillAndTrainingNavigatorHR,
    options: {
      header: props => <NavHeader {...props} title={`Skill & Courses Admin`} />,
    },
  },
  {
    name: 'skills-and-training-skill-leader',
    component: SkillAndTrainingNavigatorSkillLeader,
    options: {
      header: props => (
        <NavHeader {...props} title={`Skills & Training(Skill Leader)`} />
      ),
    },
  },
  {
    name: 'outreach-campaign-menu',
    component: OutreachCampaignNavigator,
    options: {
      header: props => <OutreachCampaignHeader {...props} />,
    },
  },
  {
    name: 'social-media-master',
    component: SocialMediaMasterNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Master`} />,
    },
  },
  {
    name: 'pre-sales-menu',
    component: PreSalesMenu,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Pre Sales (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
        />
      ),
    },
  },
  {
    name: 'pre-sales-menu-for-consultant',
    component: PreSalesMenuForSolConsultant,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`Solution Consultant (${
            props?.route?.params?.product?.name ||
            props?.route?.params?.organization?.name
          })`}
        />
      ),
    },
  },
  {
    name: 'pre-sales-menu-for-consultant-workspace',
    component: PreSalesMenuForSolConsultant,
    options: {
      header: props => <NavHeader {...props} title={`Solution Consultant`} />,
    },
  },
  {
    name: 'project-menu-for-am',
    component: organizationProjectNavigatorAM,
    options: {
      header: props => <NavHeader {...props} title={`Projects(AM)`} />,
    },
  },
  {
    name: 'project-unbilled-menu-for-am',
    component: ProjectUnbilledNavigatorAm,
    options: {
      header: props => <NavHeader {...props} title={`Projects(AM)`} />,
    },
  },
  {
    name: 'project-menu-for-pm',
    component: organizationProjectNavigatorPM,
    options: {
      header: props => <NavHeader {...props} title={`Projects(PM)`} />,
    },
  },
  {
    name: 'project-unbilled-menu-for-pm',
    component: ProjectUnbilledNavigatorPm,
    options: {
      header: props => <NavHeader {...props} title={`Projects(PM)`} />,
    },
  },
  {
    name: 'salary-menu',
    component: SalaryMenu,
    options: {
      header: props => <NavHeader {...props} title={`My Salary`} />,
    },
  },
  {
    name: 'self-trainer-for-workspace',
    component: MyMenteesForWorkspace,
    options: {
      header: props => <NavHeader {...props} title={`My Trainees`} />,
    },
  },
  {
    name: 'self-feedbacks-personal-info',
    component: SelfFeedbacksMenu,
    options: {
      header: props => <NavHeader {...props} title={`My Feedbacks`} />,
    },
  },
  {
    name: 'about-me-tabs',
    component: AboutMeTab,
    options: {
      header: props => <NavHeader {...props} title={`My Feedbacks`} />,
    },
  },
  {
    name: 'employee-efficiency-detail',
    component: EmployeeEfficiencyDetail,
    options: {
      header: props => {
        const {route: {params: {row} = {}} = {}} = props;
        const firstName = row?.name || row?.assigned_to?.name;
        const efficiencyPercentage = row?.efficiencyPercentage || 0;
        let title = `${
          firstName.toString().split(' ')[0]
        }'s Efficiency - ${efficiencyPercentage?.toFixed()}%`;
        return (
          <NavHeader {...props} title={title} secondaryTitle={row?.span} />
        );
      },
    },
  },
  {
    name: 'employee-efficiency-issue-detail',
    component: EmployeeEfficiencyIssueDetail,
    options: {
      header: props => {
        const {route: {params: {row} = {}} = {}} = props;
        return (
          <NavHeader
            {...props}
            title={'Issues, bugs & disqualified tasks'}
            secondaryTitle={row?.assigned_to?.name}
          />
        );
      },
    },
  },
  {
    name: 'employee-overdue-detail',
    component: EmployeeOverdueTasksTab,
    options: {
      header: props => {
        const {route: {params: {row} = {}} = {}} = props;
        const firstName = row?.name || row?.assigned_to?.name;
        let title = `${firstName.toString().split(' ')[0]}'s Overdue Tasks`;

        return (
          <NavHeader {...props} title={title} secondaryTitle={row?.span} />
        );
      },
    },
  },
  {
    name: 'hr-recruitment-menu',
    component: HrRecruitmentMenu,
    options: {
      header: props => <NavHeader {...props} title={`Hr Recruitment`} />,
    },
  },
  {
    name: 'confirmation-menu',
    component: ConfirmationHrMenu,
    options: {
      header: props => (
        <NavHeader {...props} title={`Employee confirmations`} />
      ),
    },
  },
  {
    name: 'confirmation-team-menu',
    component: ConfirmationManagerMenu,
    options: {
      header: props => <NavHeader {...props} title={`Team confirmations`} />,
    },
  },
  {
    name: 'attrition-menu',
    component: AttritionMenu,
    options: {
      header: props => <NavHeader {...props} title={`Attrition`} />,
    },
  },
  {
    name: 'success-reports-menu',
    component: SuccessReportMenu,
    options: {
      header: props => <NavHeader {...props} title={`Success Reports`} />,
    },
  },
  {
    name: 'staffaugmentation-menu',
    component: StaffaugmentationOppMenu,
    options: {
      header: props => (
        <NavHeader {...props} title={`Staffaugmentation Opportunity`} />
      ),
    },
  },
  {
    name: 'staffaugmentation-menu-delivery-owners',
    component: StaffaugmentationOppMenuDeliveryOwners,
    options: {
      header: props => (
        <NavHeader {...props} title={`Staffaugmentation Opportunity`} />
      ),
    },
  },
  {
    name: 'scheduled-success-reports-menu',
    component: ScheduledReportMenu,
    options: {
      header: props => <NavHeader {...props} title={`Scheduled  Reports`} />,
    },
  },
  {
    name: 'resignation-menu',
    // component: ResignationMenu,
    component: ResignationNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Resignations`} />,
    },
  },
  {
    name: 'ticket-menus',
    component: TicketMenus,
    options: {
      header: props => <NavHeader {...props} title={`Tickets`} />,
    },
  },
  {
    name: 'projections-menu',
    component: ProjectionMenu,
    options: {
      header: props => <NavHeader {...props} title={`Projections`} />,
    },
  },
  {
    name: 'customer-reports-menu',
    component: CustomerReportsMenu,
    options: {
      header: props => <NavHeader {...props} title={`Reports`} />,
    },
  },
  {
    name: 'vendor-reports-menu',
    component: VendorReportsMenu,
    options: {
      header: props => <NavHeader {...props} title={`Reports`} />,
    },
  },
  {
    name: 'vendor-bifurcated-menu',
    component: VendorMenu,
    options: {
      header: props => <NavHeader {...props} title={`Vendors`} />,
    },
  },
  {
    name: 'orders-and-deliveries-menu',
    component: OrdersAndDeliveriesMenu,
    options: {
      header: props => (
        <NavHeader {...props} title={`My Orders And Deliveries`} />
      ),
    },
  },
  {
    name: 'team-tasks-menus',
    component: TeamTaskMenus,
    options: {
      header: props => {
        return (
          <NavHeader
            {...props}
            title={`${
              props?.route?.params?.employee_team?.name ||
              props?.route?.params?.thread?.name
            } Tasks`}
          />
        );
      },
    },
  },
  {
    name: 'thread-tasks-menus',
    component: ThreadTaskMenus,
    options: {
      header: props => {
        return (
          <NavHeader
            {...props}
            title={`${
              props?.route?.params?.employee_team?.name ||
              props?.route?.params?.thread?.name
            } Tasks`}
          />
        );
      },
    },
  },
  {
    name: 'engineering-menu',
    component: EngineeringNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Engineering`} />,
    },
  },
  {
    name: 'engineering-project-wise-menu',
    component: EngineeringProjectWiseNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Project Wise`} />,
    },
  },
  {
    name: 'bench-menu',
    component: BenchNavigator,
    options: {
      header: props => <NavHeader {...props} title={`Bench`} />,
    },
  },
  {
    name: 'order-menu',
    component: OrderMenu,
    options: {
      header: props => <NavHeader {...props} title={`Orders`} />,
    },
  },
  {
    name: 'order-menu-am',
    component: OrderMenuAM,
    options: {
      header: props => <NavHeader {...props} title={`Orders`} />,
    },
  },
  {
    name: 'expense-menu',
    component: ExpenseMenu,
    options: {
      header: props => <NavHeader {...props} title="Expenses" />,
    },
  },
  {
    name: 'leads-menu',
    component: LeadNavigator,
    options: {
      header: props => {
        let title =
          props?.route?.params?.product?.name ||
          props?.route?.params?.organization?.name;
        return (
          <NavHeader
            {...props}
            title={title ? `Leads (${title})` : 'My Leads'}
            // actions={[
            //   <AddButton
            //     title="Lead"
            //     view="add-lead"
            //     params={props?.route?.params}
            //   />,
            //   <AddButton
            //     title="Import Leads"
            //     view="import-list"
            //     params={{
            //       ...props?.route?.params,
            //       source: 'leads',
            //       model: 'PotentialCustomer',
            //     }}
            //   />,
            // ]}
          />
        );
      },
    },
  },
  {
    name: 'it-asset-menu',
    component: ITAssetMenu,
    options: {
      header: props => <NavHeader {...props} title={`IT Assets`} />,
    },
  },
  {
    name: 'employee-setup',
    component: EmployeeSetupMenu,
    options: {
      header: props => <NavHeader {...props} title={`Employee Setup`} />,
    },
  },
  {
    name: 'employee-project-feedback-hr',
    component: EmployeeFeedbackMenu,
    options: {
      header: props => <NavHeader {...props} title={`Employee Feedback`} />,
    },
  },
  {
    name: 'employee-appraisal-hr',
    component: EmployeeAppraisalMenu,
    options: {
      header: props => <NavHeader {...props} title={`Employee Appraisal`} />,
    },
  },
  {
    name: 'self-appraisal',
    component: SelfAppraisalMenu,
    options: {
      header: props => <NavHeader {...props} title={`Employee Appraisal`} />,
    },
  },
  {
    name: 'employee-salary-hr',
    component: EmployeeSalaryHrMenu,
    options: {
      header: props => <NavHeader {...props} title={`Employee Salary`} />,
    },
  },
  {
    name: 'department-wise-employee-menus',
    component: DepartmentWiseEmployeeMenu,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.department,
            },
          }}
          title={`Department Employees`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
  {
    name: 'project-billed-screen',
    component: BilledProjectLeftMenu,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.project_billing_status,
            },
          }}
          title={`Project`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
  {
    name: 'project-unbilled-screen',
    component: UnBilledProjectLeftMenu,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.project_billing_status,
            },
          }}
          title={`Project`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
  {
    name: 'project-billing-status-wise-menus',
    component: DepartmentWiseEmployeeMenu,
    options: {
      header: props => (
        <NavHeader
          back
          navigation={props?.navigation}
          route={{
            ...props.route,
            params: {
              department: props?.route?.params.project_billing_status,
            },
          }}
          title={`Projects`}
          secondaryTitle={props?.route?.params?.title}
        />
      ),
    },
  },
];

export const modalScreens = [
  {name: 'kpi-selection-list', component: SelectKpis},
  {
    name: 'employee-workload',
    component: EnggAllResourceTabs,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'employee-workload-for-project',
    component: EnggAllResourceTabsForProjectTeam,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'employee-workload-for-billed-and-unbilled',
    component: EnggAllResourceBilledAndUnBilledTabs,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'employee-monthwise-workload-for-project-team',
    component: EnggAllResourceTabsMonthWiseForProjectTeam,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'org-disqualified-tasks',
    component: OrganizationDisqualifiedTasksTable,
    options: {
      size: 'max',
      header: {
        title: 'Disqualified Tasks',
        secondaryTitle: props => {
          return props?.route?.params?.row?.assigned_to?.name;
        },
      },
    },
  },
  {
    name: 'help-guide-text',
    component: HelpTemplateVideoPlayer,
    options: {
      size: 'auto',
      header: {
        title: props => props?.route?.params?.videoContent?.detail_title,
      },
    },
  },
  // Add Routes when we navigate to only video content template player. - Rajnish 02-09-2024
  {
    name: 'help-guide-video-player-no-data',
    component: HelpTemplateVideoPlayer,
    options: {
      size: 'large',
      header: {
        title: props => props?.route?.params?.videoContent?.detail_title,
      },
    },
  },
  {
    name: 'help-guide-video-player',
    component: HelpTemplateVideoPlayer,
    options: {
      size: 'max',
      header: {
        title: props => props?.route?.params?.videoContent?.detail_title,
      },
    },
  },
  {
    name: 'show-lead-list-for-outreach-batch',
    component: CampaignLeadListTabForOutreachBatch,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'import-list',
    component: ImportHistoryLogList,
    options: {size: 'extraLarge', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'customer-proposal-list',
    component: CustomerProposalList,
    options: {size: 'max'},
  },
  {
    name: 'customer-all-rfr-list',
    component: CustomerAllRFRList,
    options: {size: 'max'},
  },
  {
    name: 'customer-all-rfq-list',
    component: CustomerAllRFQList,
    options: {size: 'max'},
  },

  // {
  //   name: 'show-prospect-list',
  //   component: CampaignProspectListTab,
  //   options: {
  //     header: {
  //       title: props => `${props?.route?.params?.row?.name}`,
  //     },
  //     size: 'large',
  //     ...(isMobile && {height: '70%'}),
  //   },
  // },
  {
    name: 'request-send-for-outreach-batch',
    component: CampaignProspectListTabForOutreachBatch,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'show-prospect-list-for-message-send',
    component: MessageSentForCampaignMessageTab,
    options: {
      header: {
        title: props => `${props?.route?.params?.row?.name}`,
      },
      size: 'large',
      ...(isMobile && {height: '70%'}),
    },
  },
  {
    name: 'show-prospect-list-for-message-send-positive-tab',
    component: PositiveForCampaignMessageTab,
    options: {
      header: {
        title: props => `${props?.route?.params?.row?.subject}`,
      },
    },
  },
  // {
  //   name: 'show-prospect-count-list',
  //   component: ContactListForCampaign,
  //   options: {
  //     header: {
  //       title: props => `${props?.route?.params?.row?.name}`,
  //     },
  //     size: 'large',
  //     ...(isMobile && {height: '70%'}),
  //   },
  // },
  {
    name: 'show-prospect-count-list-for-outreach-batch',
    component: ContactListForCampaignForOutreachBatch,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'show-revenue-list',
    component: RevenueInvoiceList,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'make-custom-platform-opportunity',
    component: CustomPlatformOpportunityActiveTable,
    componentProps: {
      filter: {
        potential_customer: 'params?.leadId',
      },
    },
    options: {
      header: {
        title: 'Custom Platform Opportunities',
        actions: props => [
          <AddButton
            title="Opportunity"
            view="add-customplatformopportunity-proposal"
            params={props?.route?.params}
          />,
        ],
      },
    },
  },
  {
    name: 'make-staff-augmentation-opportunity',
    component: ActiveStaffAugmentationOpportunityTable,
    componentProps: {
      filter: {
        potential_customer: 'params?.leadId',
      },
    },
    options: {
      header: {
        title: 'Staff Augmentation Opportunities',
        actions: props => [
          <AddButton
            title="Opportunity"
            view="add-staffaugmentationopportunity-proposal"
            params={props?.route?.params}
          />,
        ],
      },
    },
  },
  {
    name: 'make-rps',
    component: ActiveRPSTable,
    componentProps: {
      filter: {
        potential_customer: 'params?.leadId',
      },
    },
    options: {
      header: {
        title: 'RPS',
        actions: props => [
          <AddButton
            title="RPS"
            view="add-rps-proposal"
            params={props?.route?.params}
          />,
        ],
      },
    },
  },
  {
    name: 'show-prospect-list-for-message',
    component: ProspectListForMessageTab,
    options: {
      header: {
        title: props => `${props?.route?.params?.row?.subject}`,
      },
      size: 'larfe',
    },
  },
  {
    name: 'show-communication',
    component: CommunicationListTable,
  },
  {
    name: 'show-communication-customer-overview',
    component: CommunicationListTableCustomerOverview,
  },
  {
    name: 'show-interview-resources',
    component: InterviewResourcesTable,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'current-team-size-customer',
    component: CustomerTeamSizeList,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'show-contacts',
    component: PointOfContactTable,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'view-assitant-response',
    component: ViewAssistantResponse,
    options: {
      size: 'large',
    },
  },
  {
    name: 'opportunity-show-submit-proposal',
    component: ShowOpportunitySubmitProposal,
    options: {size: 'medium'},
  },
  {
    name: 'opportunity-show-submit-proposal-restimation',
    component: ShowOpportunitySubmitProposalRestimation,
    options: {size: 'large'},
  },
  {
    name: 'opportunity-quotation-rfq-feature',
    component: ShowOpportunityQuotationRFQFeature,
    options: {size: 'large'},
  },
  {
    name: 'opportunity-proposal-rfq-feature',
    component: ShowOpportunityProposalRFQFeature,
    options: {size: 'large'},
  },
  {
    name: 'opportunity-timeline-report',
    component: OpportunityTimelineReport,
    options: {size: 'max'},
  },
  {
    name: 'my-resource',
    component: MyResources,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'employee-allocation',
    component: EmployeeAssignmentTable,
    options: {
      header: {
        title: 'Allocations',
      },
    },
  },
  {
    name: 'organization-resource',
    component: OrganizationResources,
    options: {size: 'small'},
  },
  {
    name: 'staff-augmentation-resource',
    component: StaffAugmentationResourceTable,
    options: {size: 'extraLarge', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'active-candidate-resource-table',
    component: ActiveCandidateTabView,
    options: {
      size: 'max',
      header: {
        title: 'Active Candidates',
        secondaryTitle: props => {
          return (
            props?.route?.params?.row?.potential_customer?.name ||
            props?.route?.params?.row?.customer_id?.name
          );
        },
      },
    },
  },
  {
    name: 'active-candidate-resource-table-deliveryowner',
    component: ActiveCandidateTabViewDeliveryOwner,
    options: {
      size: 'max',
      header: {
        title: 'Active Candidates',
        secondaryTitle: props => {
          return (
            props?.route?.params?.row?.potential_customer?.name ||
            props?.route?.params?.row?.customer_id?.name
          );
        },
      },
    },
  },
  {
    name: 'lost-candidate-resource-table',
    component: LostCandidateTabView,
    options: {
      size: 'extraLarge',
      header: {
        title: 'Lost Candidates',
        secondaryTitle: props => {
          return (
            props?.route?.params?.row?.potential_customer?.name ||
            props?.route?.params?.row?.customer_id?.name
          );
        },
      },
    },
  },
  {
    name: 'drop-candidate-resource-table',
    component: DropCandidateTabView,
    options: {
      size: 'extraLarge',
      header: {
        title: 'Drop Candidates',
        secondaryTitle: props => {
          return (
            props?.route?.params?.row?.potential_customer?.name ||
            props?.route?.params?.row?.customer_id?.name
          );
        },
      },
    },
  },
  {
    name: 'won-candidate-resource-table',
    component: WonCandidateTabView,
    options: {
      size: 'extraLarge',
      header: {
        title: 'Won Candidates',
        secondaryTitle: props => {
          return (
            props?.route?.params?.row?.potential_customer?.name ||
            props?.route?.params?.row?.customer_id?.name
          );
        },
      },
    },
  },
  {
    name: 'customer-invoices',
    component: RevenueTable,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'delivery-invoices',
    component: RevenueInvoiceAllTable,
    options: {size: 'extraLarge', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'customer-ageing-invoices',
    component: RevenueInvoiceAllTable,
    options: {size: 'max'},
  },
  {
    name: 'vendor-invoices',
    component: VendorInvoiceDetailsTable,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'show-invitees-on-strategic-initiative',
    component: ShowStrategicInvitees,
    options: {size: 'medium', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'messagesend-for-outreach',
    component: MessageSendTabForOutreachBatch,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'positive-for-outreach',
    component: PositiveTabForOutreachBatch,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'completed-strategic-initiatives-list',
    component: CompletedStrategicInitiativesTable,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'theme-messages',
    component: MessageTable,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'campaign-messages-table',
    component: CampaignMessageTable,
    options: {size: 'large', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'new-chat',
    component: NewChat,
    options: {
      position: 'screenRight',
      width: '50%',
      header: {
        title: ({
          route: {
            params: {row, title},
          },
        }) => {
          return title;
        },
        secondaryTitle: ({
          route: {
            params: {source_name},
          },
        }) => {
          return source_name;
        },
      },
      backgroundColor: '#FDFDFD',
    },
  },
  {
    name: 'ticket-history',
    component: TicketHistoryScreen,
    options: {
      position: 'screenRight',
      width: '70%',
      header: {
        title: props => {
          return <RenderTicketChatHeader {...props} />;
        },
      },
      backgroundColor: '#FDFDFD',
    },
  },
  {
    name: 'new-chat-with-chatId',
    component: NewChat,
    options: {
      position: 'screenRight',
      width: '40%',
      header: {
        title: ({
          route: {
            params: {row, title},
          },
        }) => {
          return title;
        },
        secondaryTitle: ({
          route: {
            params: {source_name},
          },
        }) => {
          return source_name;
        },
      },
      backgroundColor: '#FDFDFD',
    },
  },
  // {
  //   name: 'show-value-propositions',
  //   component: ValuePropositionList,
  //   options: {
  //     size: 'large',
  //   },
  // },
  // {
  //   name: 'show-nuturing',
  //   component: MarketingThemeTabsForNurturing,
  //   options: {
  //     size: 'large',
  //   },
  // },
  {
    name: 'load-prospects',
    component: LoadProspectsTable,
    options: {
      size: 'large',
      ...(isMobile && {height: '70%'}),
    },
  },
  {
    name: 'tool-list',
    component: ToolTable,
  },
  {
    name: 'tasks-table',
    component: AddTaskTable,
    options: {
      size: 'large',
      ...(isMobile && {height: '70%'}),
      header: {
        title: ({route}) => {
          const {
            params: {label},
          } = route;
          return `${label} Tasks`;
        },
      },
    },
  },
  {
    name: 'load-inmail-messages',
    component: LoadInmailMessages,
  },
  {
    name: 'load-email-messages',
    component: LoadEmailMessages,
  },
  {
    name: 'employee-assignment-list',
    component: EmployeeAssignmentTable,
    options: {
      size: 'large',
      header: {
        title: ({route}) => {
          const {
            params: {label},
          } = route;
          return `${label} Employee Allocations`;
        },
        actions: props => [
          <AddButton
            title="Add Employee Allocation"
            view="add-employee-assignment"
            params={props.route.params}
          />,
        ],
      },
    },
  },
  {
    name: 'read-permission-email',
    component: EmailPermission,
    options: {size: 'small'},
  },
  {
    name: 'view-timesheets',
    component: TimesheetsTable,
    options: {size: 'max'},
  },
  {
    name: 'employee-skill-metrics',
    component: EmployeeSkillMetricsTable,
    options: {size: 'large'},
  },
  {name: 'employees-list', component: EmployeeList, options: {size: 'large'}},
  {
    name: 'resource-allocations-list',
    component: DetailResourceAllocatedList,
    options: {size: 'max'},
  },
  {
    name: 'resource-allocations-list-department',
    component: DetailResourceAllocatedDepartmentList,
    options: {size: 'max'},
  },
  {
    name: 'all-resource-allocations-list',
    component: AllAllocationResourceList,
    options: {size: 'max'},
  },
  {
    name: 'resource-allocations-list-for-billing-status',
    component: AllocationResourceForBillingStatusReportList,
    options: {size: 'max'},
  },
  {
    name: 'training-resource-allocations-list',
    component: TrainingResourceAllocatedList,
    options: {size: 'max'},
  },
  {
    name: 'employee-tasks',
    component: EmployeeTasksTab,
    options: {
      size: 'max',
      header: {
        title: ({
          route: {
            params: {row},
          },
        }) => {
          const firstName = row?.name || row?.assigned_to?.name;
          if (firstName) {
            return `${firstName.toString().split(' ')[0]}'s tasks`;
          }
        },
      },
    },
  },
  {
    name: 'employee-team',
    component: TeamTasksTable,
    options: {
      size: 'max',
      header: {
        title: props => {
          const firstName = props?.route?.params?.row?.name;
          if (firstName) {
            return `${firstName.toString().split(' ')[0]}'s Team`;
          }
        },
      },
    },
  },
  {
    name: 'employee-team-tasks',
    component: TeamTaskAllTable,
    options: {
      size: 'max',
      header: {
        title: props => {
          const firstName = props?.route?.params?.row?.name;
          if (firstName) {
            return `${firstName.toString().split(' ')[0]}'s Team`;
          }
        },
      },
    },
  },
  {
    name: 'employee-skills-table',
    component: MySkillsTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'assign-trainee-on-batch',
    component: AssignTraineeOnBatch,
    options: {
      size: 'max',
      header: {
        title: 'Add Trainees',
      },
    },
  },
  {
    name: 'fixed-order-assigned-resources',
    component: FixedOrderAssignedResourcesTable,
    options: {
      size: 'max',
      header: {
        title: 'Resources',
      },
    },
  },
  {
    name: 'fixed-order-assigned-resources-for-rm',
    component: FixOrderResourcesAssignForRmList,
    options: {
      size: 'max',
      header: {
        title: 'Resources',
      },
    },
  },
  // Rajnish Trikaldarshi 23/12/2023 - Make Route to open form to markVerify and drop the potential customer
  {
    name: 'edit-campaign-prospect',
    component: CampaignProspectsMarkVerifyForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'skill-topic-list',
    component: SkillTopicsTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'employee-training-with-batch',
    component: EmployeeTrainingThroughBatchTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'extended_date_details',
    component: ExtendedDateDetails,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'ic-incentive-salary-detail',
    component: EmployeeIncentiveSalaryTab,
    options: {
      size: 'large',
    },
  },
  {
    name: 'ic-incentive-salary-detail-tab',
    component: EmployeeKPIIncentiveSalaryDetailTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'view-project',
    component: SelfLevelFixedDetailTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'view-deliveries',
    component: SelfLevelTMDetailTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'data-entity-views',
    component: DataEntityViews,
    options: {
      size: 'large',
    },
  },
  {
    name: 'practice-wise-opportunity-table',
    component: PracticeWiseOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'practice-wise-closed-opportunity-table',
    component: PracticeWiseClosedOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'overdue-pending-amt-customer-list',
    component: OverduePendingAmtCustomerList,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'customer-wise-project-team',
    component: CustomerProjectTeam,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-wise-team-efficiency',
    component: ProjectEfficiencyTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-wise-feature-efficiency',
    component: ProjectReleasedFeaturesTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'contact-detail-customer-overview',
    component: CustomerContactTableWithInlineForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'client-poc-detail-customer-overview',
    component: CustomerContactTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'man-months-customer-overview',
    component: ManMonthCustomerOverviewList,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'revenue-amout-customer-overview',
    component: CustomerOverviewRevenueList,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'customer-wise-revenue-invoice',
    component: CustomerWiseRevenueInvoiceList,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'customer-wise-receipt-list',
    component: CustomerWiseReceiptList,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'resource-wise-success-opportunity-table',
    component: ResourceWiseSuccessOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'resource-wise-closed-success-opportunity-table',
    component: ResourceWiseSuccessOpportunityTableClosed,
    options: {
      size: 'max',
    },
  },
  {
    name: 'delivery_owner-wise-success-opportunity-table',
    component: DeliveryOwnerWiseSuccessOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'delivery_owner-wise-success-closed-opportunity-table',
    component: DeliveryOwnerWiseSuccessClosedOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'mentor-wise-success-opportunity-table',
    component: MentorWiseSuccessOpportunityTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'mentor-wise-success-closed-opportunity-table',
    component: MentorWiseSuccessClosedOpportunityTable,
    options: {
      size: 'max',
    },
  },

  // Rajnish Trikaldarshi 28-12-2023 - Update the Route to open Model unVerifiedCampaignActionForm
  {
    name: 'unverified-campaign-action-form',
    component: CampaignActionUnverifiedForm,
    options: {
      size: 'max',
    },
  },

  // Route to open Model action summary form - Parveen 05-02-2024
  {
    name: 'action-summary-form',
    component: ActionSummaryForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'comments',
    component: MyCommentsList,
    options: isMobile
      ? {position: 'fullScreen'}
      : {
          width: '60%',
          height: '90%',
        },
  },
  {
    name: 'notification-route',
    component: AllNotifications,
    options: {
      position: 'screenRight',
      width: 500,
      variant: 'unStyled',
    },
  },
  {
    name: 'total-voluntary-tech-table',
    component: totalVoluntaryTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'total-involuntary-nontech-table',
    component: totalVoluntaryNonTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'voluntary-wise-tech-attrition',
    component: voluntaryWiseTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'voluntary-wise-non-tech-attrition',
    component: voluntaryWiseNonTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'reason-wise-resigned-attrition',
    component: ReasonWiseResignedTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'experience-wise-tech-attrition',
    component: experienceWiseTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'experience-wise-nontech-attrition',
    component: experienceWiseNonTechTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'practice-wise-resigned-attrition',
    component: PracticeWiseResignedTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'order-target-list',
    component: MyOrdersTargetTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'customer-delivery-list',
    component: CustomerDeliveryList,
    options: {
      size: 'large',
    },
  },
  {
    name: 'customer-finished-hours-list',
    component: CustomerFinishedHoursList,
    options: {
      size: 'large',
    },
  },
  {
    name: 'current-business-customer-list',
    component: CurrentBussinessCustomerList,
    options: {
      size: 'large',
    },
  },
  {
    name: 'current-business-resource-deployable-list',
    component: CurrentBussinessResourceDeployableList,
    options: {
      size: 'large',
    },
  },
  {
    name: 'email-profile-stats-list',
    component: EmailStatsList,
    options: {size: 'medium'},
  },
  {
    name: 'all-trainers-overview',
    component: AllTrainersOverview,
    options: {
      size: 'max',
    },
  },
  {
    name: 'active-order-allocations',
    component: ActiveOrderAllocations,
    options: {
      size: 'max',
    },
  },
  {
    name: 'active-order-allocations-fixed',
    component: ActiveOrderAllocationsFixed,
    options: {
      size: 'max',
    },
  },
  {
    name: 'all-trainees-overview',
    component: AllTraineesOverview,
    options: {
      size: 'max',
    },
  },
  {
    name: 'view-training-batch-attendance',
    component: DayWiseAttendance,
    options: {
      size: 'large',
    },
  },
  {
    name: 'mark-training-batch-attendance',
    component: TrainingAttendanceTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'my-training-attendance',
    component: MyAttendanceTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'my-feedbacks',
    component: MyFeedbacksTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'trainee-to-trainer-feedbacks',
    component: TraineeToTrainerFeedbacks,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'duplicate-customer-parent',
    component: DuplicateCustomerPopUp,
    options: {
      size: 'small',
      header: {
        title: 'Duplicate Customer',
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;
          const {row} = params;
          const {name} = row || {};
          return name;
        },
      },
    },
  },
  {
    name: 'customer-description-pop-up',
    component: CustomerDescriptionPopUp,
    options: {
      size: 'medium',
      header: {
        title: 'Description',
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;
          const {data} = params;
          const {name} = data || {};
          return name;
        },
      },
    },
  },
  {
    name: 'lead-lost-description-pop-up',
    component: LeadDescriptionPopUp,
    options: {
      size: 'medium',
      header: {
        title: 'Lost description',
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;
          const {data} = params;
          const {name} = data || {};
          return name;
        },
      },
    },
  },
  {
    name: 'candidate-lost-description-pop-up',
    component: CandidateDescriptionPopUp,
    options: {
      size: 'medium',
      header: {
        title: 'Reject reason',
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;
          const {data} = params;
          const {name} = data || {};
          return name;
        },
      },
    },
  },
  {
    component: MyMenteesTabs,
    name: 'my-mentees-tabs',
    options: {
      size: 'large',
      header: {
        title: 'My Trainees',
      },
    },
  },
  {
    component: TraineeFeedbackForTrainerView,
    name: 'trainee-feedback-for-trainer',
    options: {
      size: 'large',
    },
  },
  {
    component: InactiveTrainerCourses,
    name: 'inactive-trainer-courses',
    options: {
      size: 'large',
    },
  },
  {
    component: InactiveTrainerList,
    name: 'inactive-trainer-list',
    options: {
      size: 'large',
    },
  },
  {
    component: TraineeToTrainingFeedbackTable,
    name: 'trainee-to-training-feedback-table',
    options: {
      size: 'medium',
    },
  },
  {
    component: TraineeToTrainerFeedbacksRM,
    name: 'trainee-to-trainer-feedbacks-rm',
    options: {
      size: 'medium',
    },
  },
  {
    component: ProjectEscalationTabs,
    name: 'escalation-tab',
    options: {
      size: 'large',
    },
  },
  {
    component: ManagingTable,
    name: 'managing-table',
    options: {
      size: 'large',
    },
  },
  {
    name: 'opportunity-analytics-active-engineering',
    component: ActiveEngineeringOpportunitiesListForAnalytics,
    options: {size: 'max'},
  },
  {
    name: 'employee-analytics-ubilled-view',
    component: AllResourceOnBenchAnlaytics,
    options: {size: 'max'},
  },
  {
    name: 'opportunity-analytics-active-feature',
    component: CustomPlatformActiveMergedListForAnalytics,
    options: {size: 'max'},
  },
  {
    name: 'opportunity-analytics-close-feature',
    component: CustomPlatformFeatureClosedForAnalytics,
    options: {size: 'max'},
  },
  {
    name: 'opportunity-analytics-closed-engineering',
    component: ClosedEngineeringOpportunitiesListForAnalytics,
    options: {size: 'max'},
  },
  {
    name: 'sub-task-assignment-table',
    component: RecurringTaskAssignmentTabs,
    options: {
      size: 'max',
      header: {
        title: 'Sub Task Assignments',
        secondaryTitle: props =>
          props?.route?.params?.parent_task_assignment?.task,
      },
    },
  },
  {
    name: 'sub-task-table',
    component: SubTaskTable,
    options: {
      size: 'max',
      header: {
        title: 'Sub Tasks',
        secondaryTitle: props => props?.route?.params?.parent_task?.name,
      },
    },
  },
  {
    name: 'eng_gross_margin_report',
    component: EngGrossMarginReport,
    options: {size: 'max'},
  },
  {
    name: 'eng_efficiency_report',
    component: EngEfficiencyReport,
    options: {size: 'max'},
  },
  {
    name: 'project-employee',
    component: projectEmployees,
    options: {size: 'medium'},
  },
  {
    name: 'project-gross-margin-report',
    component: ProjectGrossMarginTable,
    options: {size: 'max'},
  },
  {
    name: 'customer-navigation',
    component: ActiveCustomerTable,
    options: {size: 'max'},
  },
  {
    name: 'project-order-mapping-list',
    component: ProjectOrderMappings,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-wise-fixed-employee-request-tab',
    component: ProjectWiseEmployeeRequestTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-allocate-resource',
    component: ProjectAllocateResourceTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'potential-customer-for-lead-to-ql',
    component: PotentialCustomerTabForLeadToQl,
    options: {
      size: 'max',
    },
  },
  {
    name: 'bvg-report-list',
    component: BackgroundVerificationRepotTabs,
    options: {
      size: 'max',
    },
  },
  {
    name: 'churn-customer-drill-list',
    component: ChurnCustomerReportTabView,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'customer-am-report',
    component: CustomerForAmReport,
    options: {
      size: 'max',
    },
  },
  {
    name: 'attendance-verified-order',
    component: AttendanceTabForVerifiedOrder,
    options: {size: 'large'},
  },
  {
    name: 'delivery-progress',
    component: DeliveryProgressTable,
    options: {size: 'large'},
  },
  {
    name: 'revenue-invoice-table',
    component: RevenueInvoiceAllTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-resource-working-data-for-orders',
    component: ProjectResourceWorkingDataForOrders,
    options: {size: 'max'},
  },
  {
    name: 'skill-leader-dashboard',
    component: SkillLeaderDashboard,
    options: {size: 'max'},
  },
  {
    name: 'close-partial-engagement',
    component: ClosePartialEngagementTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'custom-tasks',
    component: TaskTabs,
    options: {
      size: 'max',
      header: {
        title: 'Tasks',
        secondaryTitle: props => props?.route?.params?.secondaryTitle,
      },
    },
  },
  {
    name: 'skill-wise-total-employee',
    component: AllResourceOnBench,
    options: {
      size: 'max',
    },
  },
  {
    name: 'skill-wise-available-employee',
    component: AvailableResource,
    options: {
      size: 'max',
    },
  },
  {
    name: 'asset-assignment-history',
    component: AssetAssignmentHistoryTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'asset-repair-history',
    component: AssetRepairHistoryTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'tagged-asset',
    component: TaggedAssetTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'open-receipts',
    component: RecieptAllTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'employee-package-details',
    component: EmployeePackageDetailsTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'employee-salary-template',
    component: EmployeeSalaryTemplateTable,
    options: {size: 'max'},
  },
  {
    name: 'employee-attendance',
    component: AllAttendanceTable,
    options: {size: 'max'},
  },
  {
    name: 'project-timesheet-progress',
    component: TimesheetsProgressTable,
    options: {size: 'large'},
  },
  {
    name: 'projectresourceworking-active-order',
    component: ActiveOrderOfOrdersAndDeliveries,
    options: {
      size: 'max',
    },
  },
  {
    name: 'tandm-orders-and-delivered',
    component: TandMOrdersAndDelivered,
    options: {
      size: 'max',
    },
  },
  {
    name: 'projectresourceworking-active-order-for-fixed',
    component: ActiveOrderOfOrdersAndDeliveriesForFixed,
    options: {
      size: 'max',
    },
  },
  {
    name: 'projectresourceworking-verified-order',
    component: VerifiedOrderOfOrdersAndDeliveries,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-fixed-order-projection',
    component: MyOrdersTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'approve-milestone',
    component: ApproveMilestone,
    options: {size: 'large'},
  },
  {
    name: 'employee-team-lead-list',
    component: EmployeeTeamLeadTable,
    options: {size: 'large'},
  },
  {
    name: 'employee-delivery-owner-list',
    component: EmployeeDeliveryOwnerTable,
    options: {size: 'large'},
  },
  {
    name: 'fixed-output-from-growth-and-billing',
    component: OrdersAndDeliveriesForFixedTab,
    options: {size: 'max'},
  },
  {
    name: 't-and-m-growth-and-billing',
    component: OrdersAndDeliveriesForTAndMTab,
    options: {size: 'max'},
  },
  {
    name: 'timesheet-progress-tandm',
    component: TimesheetsTandMProgressTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'tax-declarations-card',
    component: TaxDeclarationCard,
    options: {
      size: 'max',
      header: {title: 'Tax Declarations'},
    },
  },
  {
    name: 'employeewise-billing-status-report',
    component: BillingStatusReportTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'all-allocated-resource-list',
    component: AllAllocatedResourceTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'allocated-resource-list',
    component: AllocatedResourceTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'employee-variable-project',
    component: EmployeeVariableProjectTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'employee-bench-table',
    component: EmployeesBenchTables,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-table-kpi',
    component: ProjectTablekpi,
    options: {
      size: 'max',
    },
  },
  {
    name: 'lead-kpi-table',
    component: LeadKpiTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'bank-transaction',
    component: BankTransactionTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'bank-transaction-fund',
    component: BankTransactionFundTable,
    options: {
      size: 'max',
    },
  },

  {
    name: 'ageing-report-invoice',
    component: AgeingReportInvoice,
    options: {
      size: 'max',
    },
  },
  {
    name: 'ageing-report-receipt',
    component: AgeingReportReceipt,
    options: {
      size: 'large',
    },
  },
  {
    name: 'expense-ageing-details',
    component: ExpenseAgeingDetails,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-modules',
    component: ModulesActiveTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'daily-attendance-shift-allowance-tab',
    component: DailyAttendanceForShiftAllowanceTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-feature-table',
    component: ProjectFeatureTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'project-wise-internal-billing',
    component: ProjectWiseFeatureTable,
    options: {
      size: 'max',
    },
  },
  {
    name: 'archived-tasks',
    component: ArchivedOrganizationTaskTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'overdue-tasks',
    component: OverDueTasks,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-all-efficiency',
    component: ProjectAllEfficiencyTab,
    options: {
      size: 'max',
    },
  },
  {
    name: 'department-roles',
    component: DepartmentRoleTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'department-tags',
    component: DepartmentTagTable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'office-time-tracking-report',
    component: TeamOfficeTimeTrackingReport,
    options: {
      size: 'max',
    },
  },
];

export const formModalScreens = [
  {
    name: 'dialogue-box-for-date',
    component: DialogueBoxForm,
    options: {size: 'small'},
  },
  {name: 'add-employee-access', component: AddEmployeeAccessForm},
  {name: 'add-apis', component: ApiForm},
  {name: 'add-kpi', component: KpiForm},
  {name: 'edit-kpis', component: EditKpiForm},
  {name: 'add-target', component: TargetForm},
  {
    name: 'add-ad-hoc-team-task',
    component: AddAdHocTeamTaskForm,
  },
  {
    name: 'ad-hoc-task-edit',
    component: EditAdHocTaskForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'strategic-task-edit',
    component: EditStrategicTaskForm,
    options: {
      size: 'large',
    },
  },
  {name: 'add-department-input', component: AddDepartmentInputForm},
  {name: 'add-department-output', component: AddDepartmentOutputForm},
  {name: 'employee-access-detail', component: UpdateEmployeeAccessForm},
  {name: 'department-input-detail', component: UpdateDepartmentInputForm},
  {name: 'add-department', component: AddDepartmentForm},
  {name: 'department-output-detail', component: UpdateDepartmentOutputForm},
  {name: 'product-detail', component: UpdateProductForm},
  {name: 'add-product', component: AddProductForm},
  {name: 'add-office', component: AddOfficeForm},
  {name: 'office-detail', component: UpdateOfficeForm},
  {
    name: 'organization-detail',
    component: UpdateOrganizationForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-organization',
    component: AddOrganizationForm,
    options: {
      size: 'max',
    },
  },
  {name: 'add-financial-year', component: AddFinancialYearForm},
  // {name: 'add-bank', component: AddBankForm},
  {
    name: 'add-currency',
    component: AddCurrencyForm,
    options: {size: 'small'},
  },
  {name: 'department-detail', component: UpdateDepartmentForm},
  {
    name: 'add-inmailcampaign',
    component: AddInmailCampaign,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'edit-inmailcampaign',
    component: EditInmailCampaign,
    options: {size: 'max'},
  },
  {
    name: 'add-outreachcampaign',
    component: AddOutreachCampaign,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'add-campaign-form',
    component: AddCampaignForm,
    options: {size: 'max', ...(isMobile && {height: '70%'})},
  },
  {
    name: 'add-outreach-campaign-message-form',
    component: OutreachCampaignMessageFormNew,
    options: {size: 'large'},
  },
  {
    name: 'add-campaign-message-form',
    component: AddCampaignMessageForm,
    options: {size: 'large'},
  },
  {
    name: 'add-outreach-usp-form',
    component: OutreachCampaignUSPForm,
    options: {size: 'small'},
  },

  {
    name: 'add-projected-billing-mm',
    component: ProjectedBillingForm,
    options: {size: 'auto'},
  },
  {
    name: 'add-linkedin-profile',
    component: LinkedinProfileFormNew,
    options: {size: 'medium'},
  },
  {
    name: 'edit-delivery-progress',
    component: EditDeliveryProgress,
    options: {size: 'auto'},
  },
  {
    name: 'update-status',
    component: CampaignContactStatusForm,
    options: {size: 'small'},
  },
  {
    name: 'reject-form',
    component: PotentialCustomerLockRejectForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-linkedin-profile',
    component: LinkedinProfileFormEdit,
    options: {size: 'medium'},
  },
  {
    name: 'update-linkedin-password',
    component: UpdatePassword,
    options: {size: 'small'},
  },

  {
    name: 'email-profile-add-form',
    component: EmailProfileAddForm,
    options: {size: 'small'},
  },
  {
    name: 'email-profile-edit-form',
    component: EmailProfileEditForm,
    options: {size: 'small'},
  },
  {
    name: 'email-provider-add-form',
    component: EmailProviderAddForm,
    options: {size: 'small'},
  },
  {
    name: 'email-signature-add-form',
    component: EmailSignatureAddForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-email-signature-add-form',
    component: EditEmailSignatureAddForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-email-provider-add-form',
    component: EditEmailProviderAddForm,
    options: {size: 'small'},
  },
  // Make Route for navigate to unique selling add form - Parveen 13-02-2024
  {
    name: 'unique-selling-point-add-form',
    component: UniqueSellingPointAddForm,
    options: {size: 'small'},
  },
  // Make Route for navigate to unique selling point edit form - Parveen 13-02-2024
  {
    name: 'unique-selling-point-edit-form',
    component: UniqueSellingPointEditForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-outreach-campaign-message-form',
    component: OutreachCampaignMessageFormEdit,
    options: {size: 'large'},
  },
  {
    name: 'edit-campaign-message-form',
    component: AddCampaignMessageFormEdit,
    options: {size: 'large'},
  },

  // Add route to view email reply form - Parveen - 23-12-2023
  {
    name: 'edit-outreach-campaign-email-reply-form',
    component: OutreachCampaignEmailReplyFormEdit,
    options: {size: 'large'},
  },
  {
    name: 'edit-outreach-campaign-contact-message-form',
    component: OutreachCampaignContactMessageFormEdit,
    options: {size: 'medium'},
  },
  {
    name: 'edit-outreach-campaign',
    component: EditOutreachCampaignForm,
    options: {size: 'max'},
  },
  {
    name: 'edit-campaign-form',
    component: EditCampaignForm,
    options: {size: 'max'},
  },

  {
    name: 'settings-outreach-campaign',
    component: SettingsOutreachCampaignForm,
    options: {size: 'medium'},
  },
  {
    name: 'employee-form',
    component: EditEmployeeForm,
    options: {size: 'max'},
  },
  {
    name: 'employee-details-form',
    component: EmployeeDetailForm,
    options: {size: 'max'},
  },
  {
    name: 'add-employee-form',
    component: AddEmployeeWithPackageFormHOC,
    options: {size: 'max'},
  },
  {
    name: 'add-employee-with-package-form',
    component: AddEmployeeFormHOC,
    options: {size: 'max'},
  },
  {
    name: 'employee-detail-form',
    component: EmployeeDetailForm,
    options: {size: 'max'},
  },
  {
    name: 'add-salary-template',
    component: AddSalaryTemplateDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-salary-template',
    component: SalaryTemplateEditDetailForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'salary-template-detail',
    component: SalaryTemplateDetailForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'duplicate-salary-template-form',
    component: DuplicateSalaryTemplate,
    options: {
      size: 'max',
    },
  },
  {
    name: 'employee-detail-form-editable',
    component: EmployeeDetailFormForHr,
    options: {size: 'max'},
  },
  {
    name: 'add-emailcampaign',
    component: EmailCampaignForm,
    options: {size: 'max'},
  },
  {
    name: 'edit-emailcampaign',
    component: EditEmailCampaignForm,
    options: {size: 'max'},
  },
  {name: 'add-datamining', component: DataMiningForm},
  {name: 'add-paidadcampaign', component: PaidAdForm},
  {name: 'add-webpage', component: WebPageCommunicationForm},
  {
    name: 'add-othercampaign',
    component: OtherCampaignForm,
    options: {size: 'max'},
  },
  {
    name: 'edit-othercampaign',
    component: EditOtherCampaign,
    options: {size: 'max'},
  },
  {
    name: 'edit-active-task',
    component: EditTaskForm,
    options: {
      size: 'large',
    },
  },
  {name: 'update-task-output', component: UpdateTaskOutputForm, size: 'auto'},
  {
    name: 'mark-task-complete',
    component: MarkTaskCompleteForm,
  },
  {name: 'add-dripemailcontent', component: AddDripEmailForm},
  {name: 'add-potentialcustomer', component: AddPotentialCustomerForm},
  {name: 'add-buyerpersona', component: BuyerPersonaForm},
  {name: 'add-casestudy', component: CaseStudyForm},
  {name: 'add-blog', component: BlogForm},
  {name: 'add-carouselad', component: CarouselAdForm},
  {name: 'add-dripinmailcontent', component: DripInmailForm},
  {name: 'add-dripinmailcontent-new', component: DripInmailFormNew},
  {name: 'add-image', component: ImageForm},
  {name: 'add-singlescreenmessage', component: SingleScreenMessageForm},
  {name: 'add-video', component: VideoForm},
  {name: 'add-active-elements', component: AddActiveElementsForm},
  {name: 'active-elements-detail', component: UpdateActiveElementsForm},
  {
    name: 'add-access-group',
    component: AddAccessGroup,
    options: {size: 'small'},
  },
  {
    name: 'add-access-resource',
    component: AccessResource,
    options: {size: 'small'},
  },
  {name: 'update-access-group', component: UpdateAccessGroup},
  {name: 'update-access-resource', component: UpdateAccessResourceForm},
  {name: 'add-user-access', component: AddUserAccess},
  {name: 'add-access-role', component: AddAccessRole},
  {name: 'update-access-role', component: UpdateAccessRole},
  {name: 'update-user-access', component: UpdateUserAccess},
  {name: 'update-user-setup', component: UpdateUserSetupForm},
  {name: 'add-user-setup', component: AddUserSetupForm},

  {name: 'add-types', component: AddTypesForm},
  {
    name: 'add-entity-variable',
    component: AddEntityVariableForm,
    options: {size: 'large'},
  },
  {name: 'add-employee-assignment', component: AddEmployeeAssignmentForm},
  {name: 'add-employee-skill', component: AddEmployeeSkill},
  {
    name: 'employee-assignment-detail',
    component: UpdateEmployeeAssignmentForm,
  },
  {name: 'types-detail', component: UpdateTypesForm},
  {
    name: 'entity-variable-detail',
    component: UpdateEntityVariableForm,
    options: {size: 'large'},
  },
  {
    name: 'currency-detail',
    component: UpdateCurrencyForm,
    options: {size: 'small'},
  },
  {name: 'financial-year-detail', component: UpdateFinancialYearForm},
  // {name: 'bank-detail', component: UpdateBankForm},
  {name: 'add-webcampaign', component: WebPageCampaignFrom},
  {name: 'add-adcreatives', component: AdCreativeForm},
  {name: 'add-marketingcreative', component: AddMarketingCreativeForm},
  {
    name: 'add-staffaugmentationopportunity',
    component: AddStaffAugmentationOpportunityForm,
  },
  {
    name: 'edit-staffaugmentationopportunity',
    component: EditStaffAugmentationOpportunityForm,
  },
  {
    name: 'edit-staffaugmentationopportunity-deliveryowner',
    component: DetailStaffAugmentationOpportunityForm,
  },
  {
    name: 'add-staffaugmentationopportunity-proposal',
    component: AddStaffAugmentationOpportunityForm,
  },
  {
    name: 'add-customplatformopportunity',
    component: AddCustomPlatformOpportunityForm,
  },
  {
    name: 'add-employee-user-form',
    component: AddEmployeeUserForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-employee-parichay-form',
    component: EmployeeParichayForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-employee-parichay-form-for-hr',
    component: EmployeeParichayFormForHr,
    options: {size: 'extraLarge'},
  },
  {
    name: 'edit-employee-parichay-form',
    component: EmployeeParichayUpdateDetailForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'edit-employee-parichay-documents',
    component: EmployeeParichayDocumentForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-opportunityprojection-t&m',
    component: OpportunityProjectionFormForTnM,
  },
  {
    name: 'add-all-campaign-contacts',
    component: AddCampaignContact,
  },
  {
    name: 'edit-customplatformopportunity',
    component: EditCustomPlatformOpportunityForm,
  },
  {
    name: 'presales-opportunity-details',
    component: EditCustomPlatformOpportunityForm,
  },
  {
    name: 'add-customplatformopportunity-proposal',
    component: AddCustomPlatformOpportunityForm,
  },
  {
    name: 'fetchData-form',
    component: FetchDataForm,
    options: {size: 'small'},
  },
  {name: 'add-poc', component: AddPoc},
  {name: 'add-opportunity', component: AddOpportunityForm},
  {
    name: 'import-history-log-detail',
    component: ImportHistoryLogDetail,
    options: {size: 'medium'},
  },
  {
    name: 'import-bulk-form16-detail',
    component: BulkForm16LogDetail,
    options: {size: 'large'},
  },
  {
    name: 'import-form',
    component: ImportLeads,
    options: {size: 'small'},
  },
  {
    name: 'import-preview-form',
    component: ImportHistoryLogPreviewForm,
    options: {size: 'large'},
  },
  {name: 'mark-lead-lost', component: MarkLeadLostForm},
  {name: 'mark-lead-lost-cumulative', component: MarkLeadLostCumulativeForm},
  // {name: 'update-lead-stage', component: MarkLeadLostCumulativeForm},

  {
    name: 'add-lead-consultants',
    component: AddLeadConsultants,
    options: {size: 'small'},
  },

  {
    name: 'use-sample-document',
    component: FinetuneUseSampleForm,
    options: {size: 'small'},
  },

  {
    name: 'update-lead-stage',
    component: UpdateLeadStageForm,
    options: {size: 'small'},
  },
  {
    name: 'update-uql-date',
    component: UpdateUqlDateFrom,
    options: {size: 'small'},
  },
  {
    name: 'mark-employee-pip',
    component: StartPIP,
    options: {size: 'auto'},
  },
  {
    name: 'mark-potential-pip',
    component: PotentialPIP,
    options: {size: 'auto'},
  },
  {
    name: 'accept-probation-confirmation',
    component: AcceptProbationAction,
    options: {size: 'small'},
  },
  {
    name: 'accept-internship-confirmation',
    component: AcceptInternshipAction,
    options: {size: 'small'},
  },
  {
    name: 'reject-probation-confirmation',
    component: RejectProbationAction,
    options: {size: 'small'},
  },
  {
    name: 'reject-internship-confirmation',
    component: RejectInternshipAction,
    options: {size: 'small'},
  },
  {
    name: 'extend-probation-confirmation',
    component: ExtendProbationAction,
    options: {size: 'small'},
  },
  {
    name: 'extend-internship-confirmation',
    component: ExtendInternshipAction,
    options: {size: 'small'},
  },
  {
    name: 'promote-employee',
    component: PromoteEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'change-employee-department',
    component: ChangeEmployeeDepartment,
    options: {size: 'medium'},
  },
  {
    name: 'start-nodues-form',
    component: StartNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'close-background-verification-form',
    component: BackgroundVerificationForm,
    options: {size: 'medium'},
  },
  {
    name: 'bank-transaction-update-form',
    component: BankTxnForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-people-manager',
    component: ChangePeopleManager,
    options: {size: 'small'},
  },
  {
    name: 'upload-package',
    component: UploadPackage,
    options: {size: 'small'},
  },
  {
    name: 'change-package-review-date',
    component: ChangePackageReview,
    options: {size: 'small'},
  },
  {
    name: 'rename-package',
    component: RenamePackage,
    options: {size: 'small'},
  },
  {
    name: 'update-ctc-amount',
    component: UpdateCTCAmountForm,
    options: {size: 'small'},
  },
  {
    name: 'add-incentive',
    component: AddYearlyIncentiveForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'duplicate-package',
    component: DuplicateEmployeeSalaryDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'update-reporting-manager',
    component: ChangeReportingTo,
    options: {size: 'small'},
  },
  {
    name: 'mark-not-relevant',
    component: MarkFeedbackNotRelevant,
    options: {size: 'small'},
  },
  {
    name: 'process-appraisal-manually',
    component: ProcessAppraisalManually,
    options: {size: 'small'},
  },
  {
    name: 'close-appraisal-manually',
    component: CloseAppraisalManually,
    options: {size: 'small'},
  },
  {
    name: 'close-appraisal-request',
    component: closeAppraisalRequest,
    options: {size: 'small'},
  },
  {
    name: 'resend-appraisal-letter',
    component: ResendAppraisalLetter,
    options: {size: 'small'},
  },
  {
    name: 'add-remark',
    component: AddRemark,
    options: {size: 'small'},
  },
  {
    name: 'add-attachment',
    component: AddAppraisalAttachment,
    options: {size: 'small'},
  },
  {
    name: 'update-close-date',
    component: ChangeCloseDate,
    options: {size: 'small'},
  },
  {
    name: 'edit-increment-details',
    component: EditIncementDetail,
    options: {size: 'small'},
  },
  {
    name: 'edit-appraisal-package-details',
    component: EditPreparePackageDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'hr-prepare-appraisal-package-details',
    component: AddPreparePackageDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'upload-letters',
    component: UploadLettersForm,
    options: {size: 'medium'},
  },
  {
    name: 'mark-employee-legal',
    component: markEmployeeLegalForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-employee-recovery-pending',
    component: markEmployeeRecoveryPendingForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-inactive',
    component: MarkInactive,
    options: {size: 'medium'},
  },
  {
    name: 'hold-unhold-salary',
    component: HoldUnholdEmployeeSalary,
    options: {size: 'small'},
  },
  {
    name: 'settle-fnf',
    component: SettleFnfEmployeeSalary,
    options: {size: 'small'},
  },
  {
    name: 'team-no-dues-form',
    component: TeamNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'employee-no-dues-form',
    component: EmployeeNoduesEmployee,
    options: {size: 'small'},
  },
  {
    name: 'accounts-no-dues-form',
    component: AccountNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'hr-no-dues-form',
    component: HRNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'admin-no-dues-form',
    component: AdminNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'it-no-dues-form',
    component: ITNetworkNoduesEmployee,
    options: {size: 'medium'},
  },
  {
    name: 'update-fnf-remark',
    component: UpdateRemarkForFnf,
    options: {size: 'small'},
  },
  {
    name: 'update-relieving-date',
    component: UpdateRelievingDate,
    options: {size: 'small'},
  },
  {
    name: 'update-exceptional-shift',
    component: SetExceptionalShiftForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-exit-type',
    component: UpdateExittype,
    options: {size: 'small'},
  },
  {
    name: 'update-desirable-type',
    component: UpdateDesirableStatus,
    options: {size: 'small'},
  },
  {
    name: 'update-resign-reason',
    component: UpdateResignReason,
    options: {size: 'small'},
  },
  {
    name: 'share-fnf',
    component: ShareFnf,
    options: {size: 'small'},
  },
  {
    name: 'payment-pending-fnf',
    component: PendingPaymentFnf,
    options: {size: 'small'},
  },
  {
    name: 'add-fnf-file',
    component: AddFnfFile,
    options: {size: 'small'},
  },
  {
    name: 'paid-fnf',
    component: PaidFnf,
    options: {size: 'small'},
  },
  {
    name: 'paid-fnf-overdue',
    component: PaidFnfOverdue,
    options: {size: 'small'},
  },
  {
    name: 'upload-fnf-recovery-details',
    component: FnfRecoveryPendingPaymentDetails,
    options: {size: 'medium'},
  },
  {
    name: 'view-fnf-recovery-details',
    component: FnfRecoveryPendingPaymentDetails,
    options: {size: 'medium'},
  },
  {
    name: 'unmark-employee-pip',
    component: FinishPIP,
    options: {size: 'auto'},
  },
  {
    name: 'extend-employee-pip',
    component: ExtendPIP,
    options: {size: 'auto'},
  },
  {
    name: 'update-pip-metor',
    component: UpdatePIPMentor,
    options: {size: 'small'},
  },
  {
    name: 'create-duplicate-lead',
    component: CreateDuplicateLeadForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'update-lead-status',
    component: UpdateLeadStatusForm,
    options: {size: 'small'},
  },
  {
    name: 'update-proposal-status',
    component: UpdateProposalStatus,
    options: {size: 'small'},
  },
  {
    name: 'update-proposal-status-pre-sales',
    component: UpdateProposalStatusPreSales,
    options: {size: 'small'},
  },
  {
    name: 'update-poc-status',
    component: UpdatePOCStatus,
    options: {size: 'small'},
  },
  {
    name: 'promotion-letter-download',
    component: DownloadPromotionLetterForm,
    options: {size: 'small'},
  },
  {
    name: 'relieving-letter-download',
    component: DownloadRelievingLetterForm,
    options: {size: 'small'},
  },
  {
    name: 'annual-letter-download',
    component: DownloadAnnualAppraisalLetterForm,
    options: {size: 'small'},
  },
  {
    name: 'biannual-letter-download',
    component: DownloadBiAnnualAppraisalLetterForm,
    options: {size: 'small'},
  },
  {
    name: 'appointment-letter-download',
    component: DownloadAppointmentLetterForm,
    options: {size: 'small'},
  },
  {
    name: 'update-winning-chance',
    component: UpdateWinningChanceForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-interview-outcome',
    component: MarkInterviewResourceOutcomeForm,
    options: {size: 'medium'},
  },
  {
    name: 'outcome-date-correction',
    component: OutcomeDateCorrectionForm,
    options: {size: 'auto'},
  },
  {
    name: 'schedule-resource-interview',
    component: ScheduleResourceInterviewForm,
    options: {size: 'auto'},
  },
  {
    name: 'update-interview-resource-resume',
    component: UpdateResourceResumeForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-resource-outcome-drop',
    component: MarkOutcomeDrop,
    options: {size: 'auto'},
  },
  {
    name: 'mark-opportunity-close',
    component: MarkOpportunityCloseForm,
    options: {size: 'auto'},
  },
  {
    name: 'mark-engineering-opp-close',
    component: MarkEnggOpportunityCloseForm,
    options: {size: 'auto'},
  },
  {
    name: 'mark-contract-negotiations',
    component: MarkContractNegotiation,
    options: {size: 'small'},
  },
  {
    name: 'mark-opportunity-position-lost',
    component: MarkOpportunityPositonLost,
    options: {size: 'small'},
  },
  {
    name: 'mark-opportunity-position-drop',
    component: MarkOpportunityPositonDrop,
    options: {size: 'small'},
  },
  {
    name: 'add-communication',
    component: AddCommunicationForm,
    options: {size: 'medium'},
  },
  {
    name: 'schedule-action',
    component: ScheduledActionForm,
    options: {size: 'small'},
  },
  {name: 'make-lead', component: MakeLeadForm, options: {size: 'auto'}},
  {name: 'make-uql', component: MakeUqlForm, options: {size: 'large'}},
  {
    name: 'edit-hubspot-contact',
    component: PotentialCustomersHubspotLeadForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'update-owners',
    component: UpdateOwnerForm,
    options: {size: 'small'},
  },
  {
    name: 'update-owners-for-free',
    component: UpdateOwnerFormForFreeProspect,
    options: {size: 'small'},
  },
  {
    name: 'update-owners-row-action',
    component: UpdateOwnerRowForm,
    options: {size: 'small'},
  },
  {
    name: 'assign-pre-sales',
    component: AssignPreSalesForm,
    options: {size: 'auto'},
  },
  {
    name: 'add-lead',
    component: AddLeadForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-lead-uql-active',
    component: AddLeadFormUQLActive,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-QL',
    component: AddLeadFormQLActive,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-proposal-submitted',
    component: AddProposalSubmittedForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'fine-tune-use-case-form',
    component: FinetuneUsecasesForm,
    options: {size: 'small'},
  },
  {
    name: 'add-campaign-global-setting',
    component: CampaignGlobalSettingForm,
    options: {size: 'small'},
  },
  {
    name: 'add-segment',
    component: AddUserSegmentForm,
    options: {size: 'small'},
  },
  {
    name: 'define-filter',
    component: DefineSegmentCriteria,
    options: {size: 'medium'},
  },
  {
    name: 'add-mapping',
    component: AddMappingForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-campaign-global-setting',
    component: EditCampaignGlobalSettingForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-segment',
    component: EditUserSegmentForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-mapping',
    component: EditMappingForm,
    options: {size: 'medium'},
  },

  {
    name: 'edit-fine-tune-use-case',
    component: FinetuneUsecasesFormEdit,
    options: {size: 'small'},
  },
  {
    name: 'use-as-sample-data',
    component: FinetuneUsecaseSelectForm,
    options: {size: 'small'},
  },
  {
    name: 'test-fine-tune-model',
    component: FinetuneJobSelectForm,
    options: {size: 'medium'},
  },
  {
    name: 'set-actual-schedule-on',
    component: SetActualScheduleOnForm,
    options: {size: 'small'},
  },
  {
    name: 'update-channel',
    component: UpdateChannelForm,
    options: {size: 'small'},
  },
  {
    name: 'set-batch',
    component: MoveToBatchForm,
    options: {size: 'small'},
  },
  {
    name: 'fine-tune-message',
    component: FinetuneAddMessage,
    options: {size: 'large'},
  },
  {
    name: 'fine-tune-summary',
    component: PotenitalCustomerBusinessSummaryForm,
    options: {size: 'large'},
  },
  {
    name: 'fine-tune-follow-up-message',
    component: FinetuneAddFollowupMessage,
    options: {size: 'large'},
  },
  {
    name: 'edit-fine-tune-job',
    component: FinetuneJobForm,
    options: {size: 'small'},
  },

  {
    name: 'select-job-model',
    component: FinetuneJobModelForm,
    options: {size: 'small'},
  },
  {
    name: 'select-duplicate-usecase',
    component: FinetuneDuplicateForm,
    options: {size: 'small'},
  },
  {
    name: 'add-customer',
    component: AddCustomerForm,
    options: {size: 'large'},
  },
  {
    name: 'mark-customer-lost',
    component: LostCustomer,
    options: {size: 'auto'},
  },
  {
    name: 'make-duplicate-customer',
    component: DuplicateCustomer,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-customer-new',
    component: AddCustomerFormForCustomer,
    options: {size: 'max'},
  },
  {
    name: 'add-customer-for-opportunity',
    component: AddCustomerFormForOpportunity,
    options: {size: 'max'},
  },
  {
    name: 'add-customer-form-new',
    component: AddCustomerFormForCustomerNew,
    options: {size: 'max'},
  },
  {
    name: 'create-customer-form',
    component: CreateCustomerForm,
    options: {size: 'max'},
  },
  {
    name: 'add-customer-form',
    component: AddCustomerFormForCustomer,
    options: {size: 'large'},
  },
  {
    name: 'lead-detail',
    component: EditLeadForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'opportunity-projection-details',
    component: OpportunityProjectionFormForTnM,
    options: {size: 'large'},
  },
  {
    name: 'rfr-tat-details',
    component: TATFormDetails,
    options: {size: 'extraLarge'},
  },
  {
    name: 'timesheet-project-detail',
    component: LogTimesheetProjectFormDetails,
    options: {size: 'large'},
  },
  {
    name: 'timesheet-non-project-detail',
    component: LogTimesheetNonProjectFormDetails,
    options: {size: 'large'},
  },
  {
    name: 'web-campaign-detail',
    component: EditWebCampaign,
    options: {size: 'large'},
  },
  {
    name: 'proposal-submitted-detail',
    component: EditProposalSubmittedForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'customer-detail',
    component: EditCustomerForm,
    options: {size: 'large'},
  },
  {
    name: 'customer-detail-new',
    component: EditCustomerFormNew,
    options: {size: 'extraLarge'},
  },
  {
    name: 'update-customer-csm',
    component: UpdateCustomerCSM,
  },
  {
    name: 'make-customer',
    component: MakeCustomerForm,
    options: {size: 'extraLarge'},
  },
  {name: 'rps-detail', component: EditRPSForm, options: {size: 'large'}},
  {
    name: 'mark-rps-close',
    component: RPSCloseForm,
    options: {size: 'small'},
  },
  {name: 'create-rfq', component: CreateRFQForm, options: {size: 'large'}},
  {name: 'create-rfr', component: CreateRFRForm, options: {size: 'large'}},
  {
    name: 'add-prospect',
    component: AddProspectForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'prospect-detail',
    component: EditProspectForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'update-potential-customer',
    component: UpdatePotentialCustomerForm,
    options: {size: 'large'},
  },
  {
    name: 'update-campaign',
    component: UpdateCampaignForm,
    options: {size: 'small'},
  },
  {
    name: 'update-business-summary',
    component: PotentialCustomerFormUpdateBusinessSummary,
    options: {size: 'small'},
  },
  {
    name: 'update-new-vendor-status',
    component: UpdateNewVendorStatus,
    options: {size: 'small'},
  },
  {
    name: 'mark-vendor-blacklist',
    component: markVendorBlacklist,
    options: {size: 'small'},
  },
  {
    name: 'update-verified-vendor-status',
    component: UpdateVerifiedVendorStatus,
    options: {size: 'small'},
  },
  {name: 'add-rps', component: AddRPSForm},
  {name: 'add-rps-proposal', component: AddRPSForm},
  {
    name: 'update-inside-sales',
    component: UpdateInsideSales,
    options: {size: 'small'},
  },
  {
    name: 'assign-tech-person',
    component: AssignTechPerson,
    options: {size: 'small'},
  },
  {
    name: 'assign-solution-consultants-on-unassigned-rfq',
    component: AssignSolutionConsultants,
    options: {size: 'small'},
  },
  {
    name: 'update-combined-substage',
    component: UpdateCombinedSubStage,
    options: {size: 'small'},
  },
  {
    name: 'closed-feature-opportunity',
    component: OpportunityMarkOwnRFQFetaure,
    options: {size: 'small'},
  },
  {
    name: 'assign-tech-person-on-active',
    component: AssignTechPersonOnActive,
    options: {size: 'small'},
  },
  {
    name: 'assign-pre-sales-on-active',
    component: AssignPreSalesOnUnAssignedRFQ,
    options: {size: 'small'},
  },
  {
    name: 'assign-buisness-analyst-on-opportunity',
    component: AssignBAOnUnAssignedRFQ,
    options: {size: 'small'},
  },
  {
    name: 'assign-owner-on-unassigned-rfq',
    component: AssignOwnerOnUnassignedRFQ,
    options: {size: 'small'},
  },
  {
    name: 'update-practice-on-rfq',
    component: UpdatePracticeOnActiveRFQ,
    options: {size: 'small'},
  },
  {
    name: 'update-solution-consultant',
    component: UpdateSolutionConsultant,
    options: {size: 'small'},
  },
  {
    name: 'update-pre-sales',
    component: UpdatePreSales,
    options: {size: 'small'},
  },
  {
    name: 'update-owner-for-rfq',
    component: UpdateOwner,
    options: {size: 'small'},
  },
  {
    name: 'update-sub-stage',
    component: UpdateSubStage,
    options: {size: 'small'},
  },
  {
    name: 'assign-interview-resource',
    component: AssignInterviewResource,
    options: {size: 'meduim'},
  },
  {
    name: 'asset-repair-history-form',
    component: AssetRepairHistoryForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-generic-initiative',
    component: EditGenericInitiativeForm,
  },
  {
    name: 'add-strategic-initiative',
    component: AddStrategicInitiativesForm,
  },
  {
    name: 'edit-strategic-initiative',
    component: EditStrategicInitiativeForm,
  },
  {
    name: 'complete-strategic-initiative',
    component: MarkStrategicInitiativeCompleteForm,
  },
  {
    name: 'complete-generic-initiative',
    component: MarkGenericInitiativeCompleteForm,
  },
  {
    name: 'add-marketing-concept',
    component: AddMarketingConcepts,
    options: {size: 'small'},
  },
  {
    name: 'add-manager-skills',
    component: ManagerSkillForm,
    options: {size: 'small'},
  },
  {name: 'add-platform', component: AddPlatform, options: {size: 'small'}},
  {
    name: 'opportunity-mark-won',
    component: OpportunityMarkOwn,
    options: {size: 'small'},
  },
  {
    name: 'nodues-mark-processed',
    component: markProcessedForm,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-mark-lost',
    component: OpportunityMarkLost,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-drop-rfq',
    component: OpportunityDropRfq,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-submit-proposal',
    component: OpportunitySubmitProposal,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-submit-quote',
    component: OpportunitySubmitQuote,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-submit-quote-rfq-feature',
    component: OpportunitySubmitQuoteFeatureForm,
    options: {size: 'small'},
  },
  {
    name: 'opportunity-submit-proposal-rfq-feature',
    component: OpportunitySubmitProposalFeatureForm,
    options: {size: 'small'},
  },
  {name: 'add-project', component: AddProjectForm},
  {name: 'add-unbilled-project', component: AddUnbilledProjectForm},
  {
    name: 'add-component',
    component: AddComponentForm,
    options: {size: 'large'},
  },
  {
    name: 'add-Styled-component',
    component: AddStyledComponentForm,
    options: {size: 'large'},
  },
  {
    name: 'add-data-service-library',
    component: AddDataServiceLibraryForm,
    options: {size: 'large'},
  },
  {name: 'add-project-member', component: AddProjectMemberForm},
  {name: 'add-client-project-member', component: AddClientProjectMemberForm},

  {name: 'project-member-detail', component: UpdateProjectMemberForm},
  {name: 'add-project-client-member', component: AddProjectClientForm},
  {name: 'project-client-member-detail', component: UpdateProjectClientForm},
  {
    name: 'add-employee-size',
    component: AddEmployeeSizeForm,
    options: {size: 'small'},
  },
  {
    name: 'add-escalation-reason',
    component: AddEscalationReasonForm,
    options: {size: 'small'},
  },
  {name: 'edit-escalation-reason', component: EditEscalationReasonForm},
  {
    name: 'employee-size-detail',
    component: EditEmployeeSizeForm,
    options: {size: 'small'},
  },
  {
    name: 'project-feedback-detail',
    component: EmployeeProjectFeedback,
    options: {size: 'large'},
  },
  {
    name: 'rm-feedback-detail',
    component: EmployeeRMFeedback,
    options: {size: 'large'},
  },
  {
    name: 'self-project-feedback-detail',
    component: EmployeeSelfProjectFeedback,
    options: {size: 'large'},
  },
  {
    name: 'self-rm-feedback-detail',
    component: EmployeeSelfRMFeedback,
    options: {size: 'large'},
  },
  {
    name: 'hr-appraisal-detail',
    component: HRAppraisalDetail,
    options: {size: 'extraLarge'},
  },
  {
    name: 'ph-appraisal-detail',
    component: PHAppraisalDetail,
    options: {size: 'extraLarge'},
  },
  {
    name: 'ph-final-appraisal-detail',
    component: PHFinalAppraisalDetail,
    options: {size: 'extraLarge'},
  },
  {
    name: 'manager-appraisal-detail',
    component: ManagerAppraisalDetail,
    options: {size: 'extraLarge'},
  },
  {
    name: 'send-appraisal-letter',
    component: SendAppraisalLetter,
    options: {size: 'small'},
  },
  {
    name: 'upload-appraisal-letter',
    component: HRUploadLetter,
    options: {size: 'small'},
  },
  {
    name: 'appraisal-offer-acceptance-form',
    component: EmployeeAppraisalAccepted,
    options: {size: 'medium'},
  },
  {
    name: 'add-industry',
    component: AddIndustryForm,
    options: {size: 'small'},
  },
  {
    name: 'add-freeze-range',
    component: AddFreezeRangeForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-freeze-range',
    component: EditFreezeRangeForm,
    options: {size: 'small'},
  },
  {
    name: 'industry-detail',
    component: EditIndustryForm,
    options: {size: 'small'},
  },
  {
    name: 'country-detail',
    component: EditCountryForm,
    options: {size: 'small'},
  },
  {
    name: 'add-request-talent',
    component: AddRequestTalentForm,
  },
  {
    name: 'add-request-talent-employee',
    component: AddRequestTalentForEmployee,
    options: {size: 'small'},
  },

  {
    name: 'change-allocation',
    component: ChangeAllocationForm,
  },
  {
    name: 'request-talent-assignment',
    component: RequestTalent,
    options: {size: 'small'},
  },
  {
    name: 'add-geographic-category',
    component: AddGeographicForm,
    options: {size: 'small'},
  },
  {
    name: 'update-geographic-category',
    component: UpdateGeographicForm,
    options: {size: 'small'},
  },
  {
    name: 'add-organization-category',
    component: AddOrganizationCategoryForm,
    options: {size: 'small'},
  },
  {
    name: 'update-organization-category',
    component: UpdateOrganizationCategoryForm,
    options: {size: 'small'},
  },
  {
    name: 'task-edit',
    component: EditProjectTaskForm,
    options: {size: 'large', height: '90%'},
  },
  {
    name: 'add-recurring-task',
    component: RecurringTaskForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-recurring-task',
    component: EditRecurringTaskForm,
    options: {size: 'medium'},
  },
  {name: 'task-field', component: AddTaskFieldForm, options: {size: 'small'}},
  {name: 'replan-form', component: UpdateReplanForm, options: {size: 'small'}},
  {
    name: 'releasedon-form',
    component: UpdateReleasedOnForm,
    options: {size: 'small'},
  },
  {
    name: 'due-date-form',
    component: UpdateDueDateForm,
    options: {size: 'small'},
  },
  {
    name: 'task-disqualification',
    component: TaskDisqualificationForm,
    options: {size: 'small'},
  },
  {
    name: 'update-complete-date',
    component: UpdateCompleteDateForm,
    options: {size: 'small'},
  },
  {
    name: 'assign-est-hours',
    component: AssignEstHours,
    options: {size: 'medium'},
  },
  {
    name: 'update-task-type-form',
    component: UpdateProjectTaskTypeForm,
    options: {size: 'small'},
  },
  {
    name: 'assign-task',
    component: AssignTask,
    options: {size: 'small'},
  },
  {
    name: 'join-strategic-initiative',
    component: JoinStrategicInitiative,
    options: {size: 'medium'},
  },
  {name: 'edit-paid-ad', component: EditPaidAdsForm},
  {
    name: 'fetch-paidad-analytics',
    component: FetchPaidAdAnalyticsForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-marketing-concepts',
    component: EditMarketingConceptForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-ad-creatives',
    component: EditAdCreativeForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-interview',
    component: EditInterview,
    // options: {size: 'small'},
  },
  {
    name: 'edit-carousel-ad',
    component: EditCarouselAdForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-image-ad',
    component: EditImageForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-video-ad',
    component: EditVideoForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-drip-email',
    component: EditDripEmailForm,
  },

  {
    name: 'detail-drip-email',
    component: DetailDripEmailForm,
  },
  {
    name: 'edit-drip-inmail',
    component: EditDripInmailForm,
  },
  {
    name: 'edit-drip-inmail-new',
    component: EditDripInmailFormNew,
  },
  {
    name: 'detail-drip-inmail',
    component: DetailDripInmailForm,
  },
  {
    name: 'detail-drip-inmail-new',
    component: DetailDripInmailFormNew,
  },
  {name: 'edit-blog', component: EditBlogForm, options: {size: 'small'}},
  {
    name: 'edit-buyer-persona',
    component: EditBuyerPersonaForm,
  },
  {
    name: 'edit-case-study',
    component: EditCaseStudyForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-platform',
    component: EditPlatformForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-single-screen-message',
    component: EditSingleScreenMessageForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-web-page',
    component: EditWebPageForm,
    options: {size: 'small'},
  },
  {
    name: 'allocate-on-inputs',
    component: AllocateOnInputs,
    options: {size: 'medium'},
  },
  {name: 'add-schedule-task', component: AddScheduleTaskForm},
  {
    name: 'add-outreachBatch',
    component: OutReachBatchForm,
    options: {size: 'small'},
  },
  {
    name: 'add-outreach-content',
    component: AddOutreachContent,
  },
  {
    name: 'edit-outreach-content',
    component: EditOutreach,
  },
  {
    name: 'message-detail',
    component: MessageFormDetail,
    options: {size: 'large'},
  },
  {
    name: 'edit-message',
    component: EditMessageForm,
    options: {size: 'large'},
  },

  {
    name: 'add-message',
    component: AddMessageForm,
    options: {size: 'large'},
  },
  {
    name: 'add-target-audience',
    component: AddTargetAudience,
    options: {size: 'large'},
  },
  {
    name: 'add-user-entity-role',
    component: UserEntityRoleForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-app-module',
    component: AppModuleInputForm,
    options: {size: 'small'},
  },
  {
    name: 'add-module-menu',
    component: ModuleMenuForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-module-menu-rights',
    component: ModuleMenuRightsForm,
    options: {size: 'medium'},
  },

  {
    name: 'add-key-responsiblity-area',
    component: KRAInputForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-key-responsiblity-area',
    component: EditKRAInputForm,
    options: {size: 'small'},
  },
  {
    name: 'add-kpi-api-table',
    component: KpiApiForm,
    options: {size: 'small'},
  },
  {
    name: 'add-channel-table',
    component: ChannelInputForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-channel-table',
    component: EditChannelInputForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-kpi-api-table',
    component: EditKpiApiForm,
    options: {size: 'small'},
  },

  {
    name: 'add-key-perfermance-indicator',
    component: KPIInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-key-performance-indicator',
    component: EditKPIInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-home-screen',
    component: HelpScreenInputForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-home-screen',
    component: EditHelpScreenInputForm,
    options: {size: 'large'},
  },
  {
    name: 'add-home-item',
    component: HelpItemInputForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-home-item',
    component: EditHelpItemInputForm,
    options: {size: 'large'},
  },
  {
    name: 'add-key-perfermance-indicator-target',
    component: KPITargetInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-key-perfermance-indicator-target',
    component: EditKPITargetInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-roles',
    component: RoleForm,
    options: {size: 'small'},
  },
  {
    name: 'add-threads',
    component: ThreadForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-app-role',
    component: AppRoleForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-app-role',
    component: EditAppRolesForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-kpi-permissions',
    component: KPIPermissionInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-kpi-permissions',
    component: EditKPIPermissionInputForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-user-role',
    component: UserRoleForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-user-role',
    component: EditUserRoleForm,
    options: {size: 'small'},
  },

  {
    name: 'add-app-resource',
    component: AppResourceForm,
    options: {size: 'medium'},
  },

  {
    name: 'edit-app-resource',
    component: EditAppResourceForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-thread',
    component: EditThreadForm,
    options: {size: 'small'},
  },
  {
    name: 'add-thread-meeting-notes',
    component: ThreadMeetingNotesForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-thread-meeting-notes',
    component: EditThreadMeetingNotesForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-collaborator',
    component: EditCollaboratorForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-thread-goal-issue',
    component: EditThreadGoalForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-role-permissions-data-entity',
    component: RolePermissionFormDataEntity,
    options: {size: 'medium'},
  },
  {
    name: 'add-role-permissions',
    component: RolePermissionForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-role-permissions',
    component: EditRolePermissionForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-role-permissions-data-entity',
    component: EditRolePermissionFormDataEntity,
    options: {size: 'medium'},
  },
  {
    name: 'edit-target-audience',
    component: EditTargetAudience,
  },
  {
    name: 'edit-user-entity-role',
    component: EditUserEntityRoleForm,
  },
  {
    name: 'edit-app-module',
    component: EditAppModuleInputForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-module-menu',
    component: EditModuleMenuForm,
  },
  {
    name: 'edit-module-menu-rights',
    component: EditModuleMenuRightsForm,
  },
  {
    name: 'edit-roles',
    component: EditRoleForm,
  },
  {
    name: 'add-value-proposition',
    component: AddValueProposition,
  },
  {
    name: 'add-new-value-proposition',
    component: AddNewValueProposition,
  },
  {
    name: 'edit-new-value-proposition',
    component: EditNewValuePropositionForm,
  },
  {
    name: 'edit-value-proposition',
    component: EditValueProposition,
  },
  {
    name: 'add-marketing-theme',
    component: AddMarketingTheme,
  },
  {
    name: 'edit-marketing-theme',
    component: EditMarketingTheme,
  },
  {
    name: 'add-tm-order',
    component: AddTMOrderform,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-support-order-form',
    component: AddSupportOrderform,
    options: {
      size: 'large',
    },
  },
  {
    name: 'edit-support-order-form',
    component: EditSupportOrderForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-tm-order',
    component: EditTMOrderForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'detail-tm-order',
    component: DetailTMOrderform,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-fixed-order',
    component: AddFixedOrderform,
    options: {
      size: 'large',
    },
  },
  {
    name: 'edit-fixed-order',
    component: EditFixedOrderForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'detail-fixed-order',
    component: DetailFixedOrderForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-poc-description',
    component: AddDescription,
    options: {
      size: 'large',
    },
  },
  {
    name: 'move-to-opportunity',
    component: MoveToOpportunityForm,
  },
  {
    name: 'add-custom-object-type',
    component: AddCustomObjectType,
  },
  {
    name: 'add-skills-metrics',
    component: AddSkillMetricsForm,
  },
  {
    name: 'edit-skill-metrics',
    component: EditSkillMetricsForm,
  },
  {
    name: 'detail-skill-metrics',
    component: SkillMetricsDetiails,
  },
  {
    name: 'add-tools',
    component: AddToolForm,
  },
  {
    name: 'edit-tools',
    component: EditToolForm,
  },
  {
    name: 'add-learning-path',
    component: AddLearningPath,
  },
  {
    name: 'add-assessement',
    component: AddAssessement,
  },
  {
    name: 'add-self-checklist',
    component: AddSelfCheckList,
  },
  {
    name: 'add-message-category-type',
    component: MessageCategoryType,
  },
  {name: 'add-attendance', component: AddAttendanceForm},
  {name: 'self-add-attendance', component: AddSelfAttendanceForm},
  {
    name: 'reject-team-request',
    component: TeamAttendanceRejectRequestForm,
    options: {size: 'small'},
  },
  {name: 'upload-form-16', component: UploadForm16DocumentForm},
  {
    name: 'update-attendance',
    component: UpdateAttendanceForm,
    options: {size: 'small'},
  },
  {
    name: 'update-attendance-HR',
    component: UpdateAttendanceFormHR,
    options: {size: 'small'},
  },
  {
    name: 'update-multiple-attendance',
    component: UpdateMultipleAttendanceForm,
    options: {size: 'small'},
  },
  // Attendance Setup Forms
  {
    name: 'add-schedule-form',
    component: AddScheduleForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-schedule-form',
    component: EditScheduleForm,
    options: {size: 'small'},
  },
  {
    name: 'add-shifts-form',
    component: AddShiftsForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-shifts-form',
    component: EditShiftsForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-leave-type-form',
    component: AddLeaveTypeForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-leave-type-form',
    component: EditLeaveTypeForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-leave-policy-form',
    component: AddLeavePolicyForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-leave-policy-form',
    component: EditLeavePolicyForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-holiday-calendar-form',
    component: AddHolidayCalendarForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-holiday-calendar-form',
    component: EditHolidayCalendarForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-cron-history-form',
    component: AddCronHistoryForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-cron-history-form',
    component: EditCronHistoryForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-punch-history-form',
    component: AddPunchHistoryForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-punch-history-form',
    component: EditPunchHistoryForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-master-task',
    component: AddMasterTask,
  },
  {
    name: 'edit-master-task',
    component: EditMasterTask,
  },
  {
    name: 'order-resource-assignment',
    component: OrderResourceAssignmentForm,
    options: {size: 'medium'},
  },
  {
    name: 'milestone-allocated-resource',
    component: MilestoneOrderResourceAssignmentForm,
    options: {size: 'medium'},
  },
  {
    name: 'fix-order-resource-assignment-for-rm',
    component: FixOrderResourceAssignmentForRM,
    options: {size: 'medium'},
  },
  {
    name: 'add-project-resource-working',
    component: ProjectResourceWorkingForm,
  },
  {
    name: 'edit-project-resource-working',
    component: EditProjectResourceWorkingForm,
  },
  {
    name: 'assign-shadow-form',
    component: AssignShadowForm,
    options: {size: 'medium'},
  },
  {
    name: 'stop-allocation-form',
    component: StopAllocation,
    options: {size: 'medium'},
  },
  {
    name: 'update-levels-owners',
    component: UpdateLevelsOwner,
    options: {size: 'medium'},
  },
  {
    name: 'stop-non-project-allocation-form',
    component: StopNonProjectAllocation,
    options: {size: 'small'},
  },
  {
    name: 'assign-resource-on-team',
    component: AssignResourceOnTeam,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-allocation-form',
    component: ChangeAllocatedResourceForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-leave-request',
    component: AddLeaveRequestForm,
    options: {size: 'small'},
  },
  {
    name: 'add-wfh-request',
    component: AddWFHRequestForm,
    options: {size: 'small'},
  },
  {
    name: 'add-self-leave-request',
    component: AddSelfLeaveRequestForm,
    options: {size: 'small'},
  },
  {name: 'update-leave-request', component: UpdateLeaveRequestForm},
  {name: 'update-team-leave-request', component: UpdateTeamLeaveRequestForm},
  {name: 'update-team-attendance-request', component: UpdateTeamAttendanceForm},
  {
    name: 'add-timesheet',
    component: AddTimesheetForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-timesheet',
    component: EditTimesheetForm,
    options: {size: 'small'},
  },
  {
    name: 'add-timesheet-request',
    component: AddTimesheetRequestForm,
    options: {size: 'medium'},
  },
  {
    name: 'log-non-project-timesheet',
    component: LogTimesheetNonProjectForm,
    options: {size: 'medium'},
  },
  {
    name: 'log-project-timesheet',
    component: LogTimesheetProjectForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-timesheet-progress',
    component: EditLogTimesheetProjectForm,
    options: {size: 'medium'},
  },
  {
    name: 'timesheet-request-form-row-action',
    component: AddTimesheetRequestFormRowAction,
    options: {size: 'small'},
  },
  {
    name: 'update-master-task-checklist',
    component: UpdateMasterTaskChecklist,
  },
  {
    name: 'assignment-checklist-form',
    component: AssignmentChecklist,
  },
  {
    name: 'add-skill-to-employee',
    component: AddSkillToEmployeeForm,
    options: {size: 'small'},
  },
  {
    name: 'editable-revenue-invoice',
    component: EditableRevenueInvoice,
    options: {
      size: 'max',
    },
  },
  {
    name: 'editable-draft-invoice',
    component: EditableDraftInvoice,
    options: {
      size: 'max',
    },
  },
  {
    name: 'create-revenue-invoice',
    component: CreateRevenueInvoice,
    options: {
      size: 'max',
    },
  },
  {
    name: 'create-draft-invoice',
    component: CreateDraftInvoice,
    options: {
      size: 'large',
    },
  },
  {
    name: 'non-editable-revenue-invoice',
    component: NonEditableRevenueInvoice,
    options: {
      size: 'max',
    },
  },
  {
    name: 'non-editable-draft-invoice',
    component: NonEditableDraftInvoice,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-revenue-receipt',
    component: ReceiptForm,
    options: {size: 'large'},
  },
  {
    name: 'update-revenue-receipt',
    component: EditRevenueReceipt,
    options: {size: 'max'},
  },
  {
    name: 'details-revenue-receipt',
    component: RevenueReceiptDetail,
    options: {size: 'max'},
  },
  {
    name: 'vendor-view-detail',
    component: EditVendorForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-vendor-form',
    component: AddVendorForm,
    options: {size: 'max'},
  },
  {
    name: 'add-vendor-invoice',
    component: AddVendorInvoiceForm,
    options: {size: 'max'},
  },
  {
    name: 'add-fund-transfer',
    component: CreateFundTransferForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-fund-transfer',
    component: EditFundTransferForm,
    options: {size: 'large'},
  },
  {
    name: 'add-vendor-invoice-reimbursment',
    component: AddVendorInvoiceReimbursmentForm,
    options: {size: 'max'},
  },
  {
    name: 'add-payment-form',
    component: AddVendorPaymentFrom,
    options: {size: 'large'},
  },
  {
    name: 'adjust-invoice',
    component: AdjustInvoiceForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-is-services',
    component: MarkIsServiceInvoiceForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-vendor-invoice-details',
    component: EditVendorInvoiceForm,
    options: {size: 'max'},
  },
  {
    name: 'edit-vendor-payment-details',
    component: EditVendorPaymentForm,
    options: {size: 'large'},
  },
  {
    name: 'create-account',
    component: CreateAccountForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-account',
    component: EditAccountForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-voucher',
    component: CreateVoucherForm,
    options: {size: 'max'},
  },
  {
    name: 'add-package-structure',
    component: AddPackageStructure,
    options: {size: 'max'},
  },
  {
    name: 'edit-package-structure',
    component: EditPackageStructure,
    options: {size: 'max'},
  },
  {
    name: 'edit-voucher',
    component: EditVoucherForm,
    options: {size: 'max'},
  },
  {
    name: 'open-voucher',
    component: OpenVoucherForm,
    options: {size: 'max'},
  },
  {
    name: 'editable-open-voucher',
    component: OpenEditVoucherForm,
    options: {size: 'max'},
  },
  {
    name: 'download-invoice',
    component: DownloadInvoicePDF,
    options: {size: 'small'},
  },
  {
    name: 'update-invoice-ubmission-date',
    component: UpdateInvoiceSubmissionDateForm,
    options: {size: 'small'},
  },
  {
    name: 'update-softex-details',
    component: UpdateSoftexDetailsForm,
    options: {size: 'small'},
  },
  {
    name: 'update-reconcile-details',
    component: UpdateReconcilationDetailForm,
    options: {size: 'small'},
  },
  {
    name: 'settle-invoice',
    component: SettleInvoice,
    options: {size: 'small'},
  },
  {
    name: 'settle-vendor-invoice',
    component: SettleVendorInvoice,
    options: {size: 'small'},
  },
  {
    name: 'create-milestone',
    component: AddMilestone,
    options: {size: 'max'},
  },
  {
    name: 'edit-milestone',
    component: EditMilestone,
    options: {size: 'extraLarge'},
  },
  {
    name: 'create-employee-request',
    component: AddEmployeeRequestForm,
    options: {size: 'medium'},
  },
  {
    name: 'create-fixed-order-assignment',
    component: AddFixedOrderAssignment,
    options: {size: 'medium'},
  },
  {
    name: 'assign-resource-on-skill',
    component: AssignResourceOnSkill,
    options: {size: 'medium'},
  },
  {
    name: 'add-skill-topic',
    component: AddSkillTopic,
    options: {size: 'small'},
  },
  {
    name: 'edit-skill-topic',
    component: EditSkillTopic,
    options: {size: 'small'},
  },
  {
    name: 'add-training-program',
    component: AddTrainingProgram,
    options: {size: 'medium'},
  },
  {
    name: 'edit-training-program',
    component: EditTrainingProgram,
    options: {size: 'medium'},
  },
  {
    name: 'add-training-batch',
    component: AddTrainingBatchForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-training-batch',
    component: EditTrainingBatch,
    options: {size: 'large'},
  },
  {
    name: 'add-my-skills',
    component: AddMySkillsForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-my-skills',
    component: EditMySkillsForm,
    options: {size: 'small'},
  },
  {
    name: 'add-training-material',
    component: AddTrainingMaterial,
  },
  {
    name: 'edit-training-material',
    component: EditTrainingMaterial,
  },
  {
    name: 'add-training-material-type',
    component: AddTrainingMaterialTypes,
    options: {size: 'small'},
  },
  {
    name: 'add-employee-team',
    component: AddEmployeeTeam,
    options: {size: 'small'},
  },
  {
    name: 'edit-employee-team',
    component: EditEmployeeTeam,
    options: {size: 'small'},
  },
  {
    name: 'update-employee-teams',
    component: UpdateEmployeeTeam,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-social-media-post',
    component: AddSocialMediaPosts,
  },
  {
    name: 'edit-social-media-post',
    component: EditSocialMediaPosts,
  },
  {
    name: 'add-keyword',
    component: AddKeywordForm,
  },
  {
    name: 'edit-keyword',
    component: EditKeywordForm,
  },
  {
    name: 'add-social-media-webpage',
    component: AddSocialMediaWebpage,
  },
  {
    name: 'edit-social-media-webpage',
    component: EditSocialMediaWebpage,
  },
  {
    name: 'add-back-link',
    component: AddBackLinkForm,
  },
  {
    name: 'edit-back-link',
    component: EditBackLinkForm,
  },
  {
    name: 'assign-mentor-wise-trainee-on-batch',
    component: AssignMentorWiseTraineeOnBatch,
    options: {
      size: 'max',
      header: {
        title: 'Add Trainees',
      },
    },
  },
  {
    name: 'project-resource-working-data-from',
    component: UpdateProjectResourceWorkingForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'drop-outreachcampaign-contacts',
    component: DropOutreachCampaignContactsForm,
    options: {size: 'small'},
  },
  {
    name: 'plan-training-program',
    component: PlanSkillTraining,
  },
  {
    name: 'edit-potential-customer',
    component: EditPotentialCustomerContactForm,
    options: {size: 'small'},
  },
  {
    name: 'mark-as-positive-response',
    component: PositiveResponseForm,
    options: {size: 'small'},
  },
  // Rajnish Trikaldarshi 23/12/2023 - Make Route to open form to write a reason to drop the potential customer.
  {
    name: 'drop-campaign-prospect',
    component: OutreachCampaignProspectDropForm,
    options: {size: 'small'},
  },
  {
    name: 'extend-training',
    component: ExtendTrainingForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-training-feedback',
    component: FeedbackForm,
  },
  {
    name: 'pip-training-feedback-form',
    component: FeedbackFormPIP,
  },
  {
    name: 'workload-assign-shadow-form',
    component: WorkloadAssignShadowForm,
    options: {size: 'medium'},
  },
  {
    name: 'workload-assign-replacement-form',
    component: WorkloadAssignReplacementForm,
    options: {size: 'medium'},
  },
  {
    name: 'workload-add-other-resourceform',
    component: AddOtherResourceInWorkload,
    options: {size: 'medium'},
  },
  {
    name: 'workload-stop-allocation',
    component: StopAllocationInWorkLoad,
    options: {size: 'medium'},
  },
  {
    name: 'assign-trainee-form',
    component: AssignTraineeForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-skill-metrics-new',
    component: AddSkillMetricsNew,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-skill-metrics-new',
    component: EditSkillMetricsNew,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-skill-tool',
    component: AddSkillToolForm,
  },
  {
    name: 'create-new-ticket',
    component: AddNewTicketForm,
  },
  {
    name: 'edit-ticket-details',
    component: EditNewTicketForm,
  },
  {
    name: 'temporarily-closed-reason',
    component: TemporarilyClosedForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-agent-form',
    component: ChangeAgentForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-department-form',
    component: ChangeDepartmentForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'set-dependency-form',
    component: SetDependencyForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-calculation-type',
    component: UpdateCalculationTypeForm,
    options: {
      size: 'small',
    },
  },
  {name: 'ticket-detail', component: TicketDetailForm},
  {
    name: 'change-category-form',
    component: ChangeCategoryForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'send-email-test-form',
    component: SendEmailTestForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'website-summary-test-form',
    component: WebsiteSummaryTestForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-reimbursement-category',
    component: EditReimbursementCategory,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-reimbursement-category',
    component: AddReimbursementCategory,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-reimbursement-request',
    component: AddReimbursementRequest,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-reimbursement-request',
    component: EditReimbursementRequest,
    options: {
      size: 'max',
    },
  },
  {
    name: 'non-editable-reimbursement-request',
    component: NonEditableReimbursementRequest,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-reopen-reimbursement-request',
    component: EditableReopenReimbursementRequest,
    options: {
      size: 'large',
    },
  },
  {
    name: 'update-income-tax-value',
    component: UpdateIncomeTaxValue,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-landlord-details',
    component: AddLandlordDetailsForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'reopen-tax',
    component: TaxReopenForm,
  },
  {
    name: 'update-tds-month-and-year',
    component: UpdateTDSMonthAndYear,
  },
  {
    name: 'update-tax-calculation-date',
    component: UpdateTaxCalculationDate,
  },
  {
    name: 'add-remark-reimbursement',
    component: RemarkReimbursement,
    options: {
      size: 'small',
    },
  },
  {
    name: 'ewd-form',
    component: UpdateEWDForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'reject-reimbursement',
    component: RejectReimbursementForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-mrf',
    component: AddMrf,
    options: {
      size: 'large',
    },
  },
  {
    name: 'non-editable-mrf',
    component: NonEditableMrf,
    options: {
      size: 'large',
    },
  },
  {
    name: 'editable-mrf',
    component: EditableMrf,
    options: {
      size: 'large',
    },
  },
  {
    name: 'approve-with-salary-man-power-requisition',
    component: ApproveWithSalaryBudgetManPowerRequisition,
    options: {
      size: 'small',
    },
  },
  {
    name: 'inmail-campaign-select-new-campaign',
    component: OutreachCampaignSelectNewCampaign,
    options: {
      size: 'small',
    },
  },
  {
    name: 'create-job-opening',
    component: CreateJobOpening,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'editable-job-opening',
    component: EditJobOpening,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'non-editable-job-opening',
    component: NonEditableJobOpening,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'update-allocation-form',
    component: UpdateAllocation,
    options: {size: 'medium'},
  },
  {
    name: 'add-candidate',
    component: AddCandidate,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-candidate',
    component: EditCandidate,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'drop-candidate',
    component: DropCandidateForm,
    options: {size: 'medium'},
  },
  {
    name: 'change-recruiter-of-candidate',
    component: ChangeRecruiterOfCandidateForm,
    options: {size: 'small'},
  },
  {
    name: 'moving-candidate-to-first-round',
    component: MovingCandidateToFirstRoundForm,
    options: {size: 'medium'},
  },
  {
    name: 'change-interview-date-of-candidate',
    component: ChangeInterviewDateOfCandidateForm,
    options: {size: 'small'},
  },
  {
    name: 'change-interviewer-of-candidate',
    component: ChangeInterviewerOfCandidateForm,
    options: {size: 'small'},
  },
  {
    name: 'change-interview-time-of-candidate',
    component: ChangeInterviewTimeOfCandidateForm,
    options: {size: 'small'},
  },
  {
    name: 'hold-candidate',
    component: HoldCandidateForm,
    options: {size: 'small'},
  },
  {
    name: 'reject-candidate',
    component: RejectCandidateForm,
    options: {size: 'medium'},
  },
  {
    name: 'assign-project',
    component: FixedOrderAssignProjectForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'invoice-detail-salary',
    component: InvoiceDetailForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'compensation-structure-detail-salary',
    component: CompensationStructureDetailForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'incentive-component-detail',
    component: IncentiveDetailForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'bonus-component-detail',
    component: BonusDetailForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'fixed-component-detail',
    component: SalaryDetailForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'milestone-assign-project',
    component: MilestoneAssignProjectForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'moving-candidate-to-next-round',
    component: MovingCandidateToNextRoundForm,
    options: {size: 'medium'},
  },
  {
    name: 'moving-candidate-to-hr-round',
    component: MovingCandidateToHrRoundForm,
    options: {size: 'medium'},
  },
  {
    name: 'detail_summary_of_all_rounds',
    component: DetailSummaryOfAllRoundsOfCandidate,
    options: {size: 'large'},
  },
  {
    name: 'update-cv',
    component: UpdateCV,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-resource-skill',
    component: UpdateResourceSkill,
    options: {
      size: 'small',
    },
  },
  {
    name: 'assign-as-replacement',
    component: AssignAsReplacement,
    options: {
      size: 'large',
    },
  },
  {
    name: 'assign-employee-as-replacement',
    component: AssignAsReplacement,
    options: {
      size: 'large',
    },
  },
  {
    name: 'update-employee-as-replacement',
    component: UpdateReplacementEmployee,
    options: {
      size: 'large',
    },
  },
  {
    name: 'proposed-deployment-date',
    component: ProposedDeploymentDateForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'proposed-complete-deployment-date',
    component: ProposedCompleteDateForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-swapped-with',
    component: UpdateAnchorResourceSwappingForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'archive-task',
    component: ArchiveTaskForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'selected-archive-task',
    component: SelectedArchiveTaskForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'assign-training',
    component: AssignTraining,
    options: {
      size: 'large',
    },
  },
  {
    name: 'mark-not-available',
    component: MarkNotAvailable,
    options: {
      size: 'small',
    },
  },
  {
    name: 'data-entity-view-edit',
    component: DataEntityViewEditForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-ticket-owner',
    component: EditTicketOwnerForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'active-order-mark-delivered',
    component: ActiveOrderMarkDeliveredForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-anchor-resource-status',
    component: UpdateAnchorResourceStatus,
    options: {
      size: 'small',
    },
  },
  {
    name: 'anchor-resource-actions',
    component: AnchorResourceActionForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'assignment-team-form',
    component: ProjectTaskAssignmentTeamForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'assignment-reviewer-form',
    component: ProjectTaskAssignmentReviewerForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-password',
    component: ChangePassword,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-employee-team-overall-resolution-time',
    component: EditOverallResolutionTime,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-employee-team-response-time',
    component: EditFistResponseTimeForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-working-cycle',
    component: EditWorkingCycleForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'complete-employee-training',
    component: CompleteEmployeeTraining,
    options: {
      size: 'small',
    },
  },
  {
    name: 'identify-swapping',
    component: SwappingFrom,
    options: {
      size: 'large',
    },
  },
  {
    name: 'assign-as-learning',
    component: AssignLearningResource,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-leave-allowed',
    component: AddLeaveAllowed,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-leave-allowed',
    component: EditLeaveAllowed,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-ticket-category',
    component: EditTicketCategory,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-ticket-sub-category',
    component: EditTicketSubCategory,
    options: {
      size: 'small',
    },
  },
  {
    name: 'withdraw-exit-management-request',
    component: WithdrawExitManagementRequestForm,
  },
  {
    name: 'offer-job',
    component: OfferJobForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'mark-candidate-accepted',
    component: MarkAccepted,
    options: {
      size: 'small',
    },
  },
  {
    name: 'mark-candidate-joined',
    component: MarkJoined,
    options: {
      size: 'small',
    },
  },
  {
    name: 'mark-candidate-nuraturable',
    component: MarkNurturable,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-candidate-offer-date',
    component: ChangeCandidateOfferDate,
    options: {
      size: 'small',
    },
  },
  {
    name: 'api-logs-detail',
    component: ApiLogsDetailsForm,
    options: {size: 'large'},
  },
  {
    component: AddLOIForm,
    name: 'add-loi',
    options: {
      size: 'medium',
    },
  },
  {
    component: DetailReasonOfRejection,
    name: 'detail-reason-of-rejection',
    options: {
      size: 'medium',
    },
  },
  {
    component: DetailReasonOfMakingNurturable,
    name: 'detail-reason-of-nurturable',
    options: {
      size: 'small',
    },
  },
  {
    component: MoveToAnotherJobOpening,
    name: 'move-to-another-job-opening',
    options: {
      size: 'medium',
    },
  },
  {
    component: DetailReasonOfDrop,
    name: 'detail-reason-of-drop',
    options: {
      size: 'medium',
    },
  },
  {
    name: 'check-package-breakdown',
    component: CheckPackageBreakdown,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-referral-candidate',
    component: ReferralCandidateForm,
    options: {
      size: 'medium',
    },
  },
  {
    component: ReferralCandidateCommentForm,
    name: 'add-referral-candidate-comment',
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-referral-candidate-to-job-opening',
    component: AddReferralToOtherOpening,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-topic-wise-trainer',
    component: AddTopicWiseTrainer,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-feedback-criteria',
    component: AddFeedbackCriteria,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-my-order-target',
    component: AddMyOrderTarget,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-order-target',
    component: UpdateMyOrderTarget,
    options: {
      size: 'small',
    },
  },
  {
    name: 'duplicate-training-program',
    component: DuplicateTrainingProgram,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-topic-duration',
    component: AddTopicDuration,
    options: {
      size: 'small',
    },
  },
  {
    name: 'my-feedback-details',
    component: MyFeedbackDetails,
    options: {
      size: 'medium',
    },
  },
  {
    component: EditTraineeToTrainerFeedbackForm,
    name: 'edit-trainee-to-trainer-feedback-form',
    options: {
      size: 'large',
    },
  },
  {
    component: TraineeToTrainerFeedbackForm,
    name: 'trainee-to-training-feedback',
    options: {
      size: 'medium',
    },
  },
  {
    component: EditTrainingAttendance,
    name: 'edit-training-attendance',
    options: {
      size: 'small',
    },
  },
  {
    component: MarkTrainerInactiveForm,
    name: 'mark-trainer-inactive',
    options: {
      size: 'small',
    },
  },
  {
    component: AddTopicWiseFeedbackCycle,
    name: 'add-topic-wise-feedback-cycle',
    options: {
      size: 'small',
    },
  },
  {
    component: TraineeToTrainerFeedbackDetail,
    name: 'trainee-to-trainer-feedback-detail',
    options: {
      size: 'medium',
    },
  },
  {
    component: TraineeToTrainerFeedbackDetailRM,
    name: 'trainee-to-trainer-feedback-detail-rm',
    options: {
      size: 'medium',
    },
  },
  {
    component: ImportTraineeForm,
    name: 'import-trainees',
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-projection-on-opportunity',
    component: AddProjectionFromOpportunity,
    options: {
      size: 'large',
    },
  },
  {
    name: 'update-proposal-hrs',
    component: UpdateProposalHours,
    options: {size: 'small'},
  },
  {
    name: 'add-escalation',
    component: AddEscalationForm,
    options: {size: 'small'},
  },
  {
    name: 'update-escalation',
    component: EditEscalationForm,
    options: {size: 'small'},
  },
  {
    name: 'resolve-escalation',
    component: resolveEscalation,
    options: {size: 'small'},
  },
  {
    name: 'add-recurring-task-assignment',
    component: AddRecurringTaskAssignmentForm,
    options: {size: 'large'},
  },
  {
    name: 'add-thread-goal',
    component: ThreadGoalForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-employee-team-master-task-assignment',
    component: EditRecurringTaskAssignmentForm,
    options: {size: 'large'},
  },
  {
    name: 'update-assignment-employee-team',
    component: UpdateEmployeeTeamInAssignmentForm,
    options: {size: 'auto'},
  },
  {
    name: 'add-event-task-assignment',
    component: AddEventTaskAssignmentForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-event-task-assignment',
    component: EditEventTaskAssignmentForm,
    options: {size: 'large'},
  },
  {
    name: 'replacement-form',
    component: ReplacementForm,
    options: {size: 'large'},
  },
  {
    name: 'add-owner',
    component: AddOwnerForm,
    options: {size: 'small'},
  },
  {
    name: 'update-replacement-status',
    component: updateStatusForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'select-industry-to-merge',
    component: IndustrySelectorForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'select-country-to-merge',
    component: CountrySelectorForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'pip-next-action-form',
    component: PIPNextActionForm,
  },
  {
    name: 'edit-resource-skill',
    component: EditResourceSkill,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-resource-practice',
    component: EditResourcePractice,
    options: {
      size: 'small',
    },
  },
  {
    name: 'ph-feedback-form',
    component: PHFeedbackFrom,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'ph-feedback-form-dual',
    component: PHFeedbackFromDual,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'am-feedback-non-editable',
    component: AmFeedbackFromNonedit,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'tm-order-map',
    component: TAndMOrderMapForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'fixed-order-map',
    component: FixedOrderMapForm,
    options: {
      width: 800,
    },
  },
  {
    name: 'update-project-mapped',
    component: FixedOrderMapForm,
    options: {
      width: 800,
    },
  },
  {
    name: 'project-order-resource-assignment',
    component: ProjectOrderResourceAssignmentForm,
    options: {width: 800},
  },
  {
    name: 'project-wise-employee-request-for-fixed',
    component: ProjectwiseEmployeeRequestForFixedOders,
    options: {
      size: 'max',
    },
  },
  {
    name: 'update-project-resource-working',
    component: UpdateProjectResourceWorking,
  },
  {
    name: 'add-budget-request',
    component: AddBudgetRequest,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-budget-request',
    component: EditBudgetRequest,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-expense-request',
    component: AddExpenseRequest,
    options: {
      size: 'large',
    },
  },
  {
    name: 'edit-expense-request',
    component: EditExpenseRequest,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-expense-budget',
    component: AddExpenseApproverLimit,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-expense-budget',
    component: EditExpenseApproverLimit,
    options: {
      size: 'small',
    },
  },
  {
    name: 'reject-budget-request',
    component: RejectBudgetReason,
    options: {
      size: 'small',
    },
  },
  {
    name: 'reject-expense-request',
    component: RejectExpenseReason,
    options: {
      size: 'small',
    },
  },
  {
    name: 'reject-quotation-request',
    component: RejectQuotationReason,
    options: {
      size: 'small',
    },
  },
  {
    name: 'order-mark-closed',
    component: MarkOrderClosedForm,
    options: {
      size: 'auto',
    },
  },
  {
    name: 'order-partial-mark-closed-form',
    component: ClosePartialEngagementForm,
    options: {width: 800},
  },
  {
    name: 'order-extend-form',
    component: OrderExtendedForm,
    options: {
      size: 'auto',
    },
  },
  {
    name: 'add-debit-note',
    component: AddDebitNoteForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-work-in-progress',
    component: AddWorkInProgressForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-task-plan-form',
    component: AddTaskPlanForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-task-plan-form',
    component: UpdateTaskPlanForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'mark-task-active',
    component: MarkTaskActiveForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-quotation-request',
    component: AddQuotationRequest,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-quotation-request',
    component: EditQuotationRequest,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-purchase-order',
    component: AddPurchaseOrder,
    options: {
      size: 'large',
    },
  },
  {
    name: 'asset-register-form',
    component: AssetRegisterForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'details-asset-register-form',
    component: DetailAssetRegisterForm,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-sub-asset-form',
    component: AddSubAssetForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'new-asset-register-form',
    component: NewAssetRegisterFormForIt,
    options: {
      size: 'max',
    },
  },
  {
    name: 'new-asset-register-form-for-pi',
    component: NewAssetRegisterFormForPi,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-purchase-order',
    component: EditPurchaseOrder,
    options: {
      size: 'large',
    },
  },
  {
    name: 'adjust-potential',
    component: PotentialAdjust,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'mark-unadjust',
    component: MarkDeliveryUnadjust,
    options: {
      size: 'auto',
    },
  },
  {
    name: 'asset-assignee-form',
    component: AssetAssigneeForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'mark-unusable-form',
    component: MarkUnusableForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'import-form-16',
    component: UploadBulkForm16,
    options: {
      size: 'large',
    },
  },
  {
    name: 'update-asset-status-form',
    component: UpdateStatusForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'upload-asset-photo',
    component: UpdateAssetPhotoForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'approve-quotaion-form',
    component: QuotaitonApproveForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'verification-asset-status',
    component: VerificationAssetStatus,
    options: {
      size: 'small',
    },
  },
  {
    name: 'tagged-with-asset',
    component: TaggedWithAssetForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'punch-data-screen',
    component: PunchDataForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-package-details',
    component: AddEmployeeSalaryDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'edit-package-details',
    component: EditEmployeeSalaryDetail,
    options: {
      size: 'max',
    },
  },
  {
    name: 'generate-attendance-base-salary',
    component: GenerateAttendanceBaseSalary,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-to-appraisal',
    component: AddEmployeeToApprasial,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-employee-leave-policy',
    component: UpdateEmployeeLeavePolicy,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'update-employee-holiday-calendar',
    component: UpdateEmployeeHolidayCalendar,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'update-employee-schedule-information',
    component: UpdateEmployeeScheduleInformation,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'update-employee-shift-information',
    component: UpdateEmployeeShiftInformation,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-leave-credit',
    component: AddLeaveCreditForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-leave-credit',
    component: EditLeaveCreditForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-esi-cycle',
    component: AddEsiCycleForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-esi-cycle',
    component: EditEsiCycleForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-salary-cycle',
    component: AddSalaryCycleForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-salary-cycle',
    component: EditSalaryCycleForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-designation',
    component: AddDesignationForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'designation-detail',
    component: EditDesignationForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-manual-variable',
    component: AddManualVariable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-manual-variable',
    component: EditManualVariable,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-reimbursement-head',
    component: AddReimbursementHead,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-reimbursement-head',
    component: EditReimbursementHead,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-salary-component',
    component: AddSalaryComponent,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-salary-component',
    component: EditSalaryComponent,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-currency-exchange-rate',
    component: AddCurrencyExchangeRateForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-currency-exchange_rate',
    component: EditCurrencyExchangeRateForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'update-department',
    component: UpdateDepartmentOnVoucherForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-employee-level',
    component: AddEmployeeLevel,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-employee-level',
    component: EditEmployeeLevel,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-nominee-relation',
    component: AddNomineeRelation,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-blood-group',
    component: AddBloodGroup,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-employee-type',
    component: AddEmployeeType,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-nominee-relation',
    component: EditNomineeRelation,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-blood-group',
    component: EditBloodGroup,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-employee-type',
    component: EditEmployeeType,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'create-admin-milestone',
    component: AddAdminMilestone,
    options: {
      size: 'max',
    },
  },
  {
    name: 'add-team-leader',
    component: AddTechnicalLeadForm,
    options: {size: 'auto'},
  },
  {
    name: 'add-delivery-owner',
    component: AddDeliveryOwnerForm,
    options: {size: 'auto'},
  },
  {
    name: 'import-employee-deliveryowner-and-teamlead',
    component: ImportEmployeeDeliveryAndTeamLead,
    options: {size: 'auto'},
  },
  {
    name: 'edit-monthly-attendances',
    component: EditMonthlyAttendance,
    options: {size: 'large'},
  },
  {
    name: 'udpate-delivery-owner',
    component: UpdateDeliveryOwnerForm,
    options: {size: 'auto'},
  },
  {
    name: 'update-bank-reconcilation-detail',
    component: bankreconcilation,
    options: {size: 'auto'},
  },
  {
    name: 'tax-declaration-forms',
    component: TaxDeclarationForms,
    options: {
      size: 'large',
    },
  },
  {
    name: 'change-department',
    component: AddDepartment,
    options: {
      size: 'small',
    },
  },
  {
    name: 'project-hold',
    component: ProjectMarkHoldForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-leave-register',
    component: AddLeaveRegister,
    options: {
      size: 'large',
    },
  },
  {
    name: 'edit-leave-register',
    component: EditLeaveRegister,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-job-owner',
    component: AddJobOwner,
  },
  {
    name: 'add-ma',
    component: AddMAForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'schedule-interview',
    component: ScheduleInterview,
  },
  {
    name: 'accept-ma',
    component: AcceptShiftAllowanceForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'candidate-feedback',
    component: CandidateFeedback,
  },
  {
    name: 'reject-ma',
    component: RejectShiftAllowanceForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-actual-ma',
    component: UpdateActualShiftAllowanceForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'processed-ma',
    component: ProcessedShiftAllowanceForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'multi-processed-ma',
    component: ProcessedShiftAllowanceMultipleForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'request-feedback',
    component: RequestFeedbackForm,
  },
  {
    name: 'update-asset-aggrement',
    component: AssetAggrementForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-category',
    component: AddCategoryForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-category',
    component: EditCategoryForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-banks',
    component: EditBankForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'generate-shift-allowance',
    component: GenerateShiftAllowanceFormForRM,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-client-feedback',
    component: EditProjectFeedbackForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'client-feedback-detail',
    component: ProjectFeedbackDetail,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'download-statement-of-account',
    component: CheckStatementOfAccount,
    options: {
      size: 'small',
    },
  },
  {
    name: 'managers-remark-on-productivity',
    component: ManagersRemarkOnProductivity,
    options: {
      size: 'small',
    },
  },
  {
    name: 'upload-vendor-form',
    component: UploadVendorForm16,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'upload-vendor-form-16',
    component: UploadVendorSelfForm16,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'detail-screen-view-form-16',
    component: VendorBulkDetailScreen,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-training-purpose',
    component: AddTrainingPurpose,
    options: {
      size: 'small',
    },
  },
  {
    name: 'productivity-survey',
    component: ProductivitySurveyForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'download-ledger',
    component: AddLedgerDownload,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-credit-note-am',
    component: AddCreditNoteAm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-credit-note-am',
    component: EditCreditNoteAm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'reject-credit-note-reason',
    component: RejectCreditNoteReason,
    options: {
      size: 'small',
    },
  },
  {
    name: 'mark-billed-credit-note',
    component: MarkBilledCreditNote,
    options: {
      size: 'small',
    },
  },
  {
    component: MarkProductivitySurveyClosed,
    name: 'close-productivity-survey',
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-ui-component',
    component: AddUIComponentForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'ui-component-detail',
    component: UIComponentDetailForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'report-issue',
    component: ReportIssueForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'Income-tax-calculate',
    component: IncomeTaxCalculate,
    options: {
      size: 'small',
    },
  },
  {
    name: 'assign-department-role-on-employee',
    component: AssignDepartmentRoleOnEmployee,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-department-role',
    component: EditDepartmentRole,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-department-tag',
    component: EditDepartmentTag,
    options: {
      size: 'small',
    },
  },
  {
    name: 'internal-allocation',
    component: InternalAllocationForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'send-variable-mail',
    component: SendVariableMail,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-csat-salary-manual',
    component: EditCsatSalaryManual,
    options: {
      size: 'medium',
    },
  },
];
