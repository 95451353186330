import {AddButton} from '../../../components/button/AddButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {
  DraftInvoiceAllTable,
  DraftInvoiceAllTableAm,
} from './DraftInvoiceTable';

const filters = ({tab}) => {
  return [
    {
      type: 'autoComplete',
      label: 'Customer',
      api: '/customers',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'customer',
    },
    {
      type: 'date',
      label: 'Invoice Date',
      field: 'invoice_date',
    },
    {
      type: 'autoComplete',
      label: 'Relation',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'account_owner_id',
    },
    ...(tab == 'All'
      ? [
          {
            type: 'autoComplete',
            label: 'Invoice type',
            placeholder: 'Select',
            options: [
              {label: 'Actual', value: 'i'},
              {label: 'Advance', value: 'a'},
              {label: 'Credit', value: 'c'},
              {label: 'Debit', value: 'd'},
            ],
            keyField: 'value',
            suggestionField: 'label',
            valueField: 'label',
            field: 'invoice_type',
          },
        ]
      : []),
    {
      type: 'autoComplete',
      label: 'Organization',
      api: '/organizations',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'organization',
    },
    {
      type: 'autoComplete',
      label: 'Product',
      api: '/products',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'product',
    },
    {
      type: 'autoComplete',
      label: 'Locations',
      api: '/offices',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'location',
    },
    {
      type: 'autoComplete',
      label: 'Status',
      placeholder: 'Select',
      options: ['New', 'Fully Invoiced', 'Partially Invoiced'],
      field: 'status',
    },
    {
      type: 'autoComplete',
      label: 'Type',
      placeholder: 'Select',
      options: [
        {label: 'Fixed', value: 'ot'},
        {label: 'T&M', value: 'r'},
      ],
      keyField: 'value',
      suggestionField: 'label',
      valueField: 'label',
      field: 'pricing_type',
    },
    {
      type: 'autoComplete',
      label: 'Invoice Category',
      placeholder: 'Select',
      options: ['Export Invoice', 'Tax Invoice', 'Bill of Supply'],
      field: 'invoice_category',
    },
  ];
};

const commonFilters = ({
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'All',
  params = {},
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search Invoice Number"
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
    <AddButton
      title="Add Draft Invoice"
      view="create-draft-invoice"
      params={params}
    />,
  ];
};

const commonFiltersprocessed = ({
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  filters,
  tab = 'All',
  params = {},
}) => {
  return [
    <SearchFilter
      value={searchValue}
      onChangeFilter={onChangeFilter}
      placeholder="Search Invoice Number"
    />,
    <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={filters({tab})}
    />,
  ];
};

export const DraftInvoiceTab = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    revenue_invoice_pending: {
      label: 'Pending',
      view: (
        <DraftInvoiceAllTable
          {...props}
          tab={'Pending'}
          searchValue={searchValue}
          addOnFilter={{...filter, status: 'pending'}}
        />
      ),
      eventSourceId: 'draft-invoice',
      api: `/draftInvoice/all`,
      addOnFilter: {...filter, status: 'pending'},
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
      }),
    },
    revenue_invoice_processed: {
      label: 'Processed',
      view: (
        <DraftInvoiceAllTable
          {...props}
          tab={'Processed'}
          searchValue={searchValue}
          addOnFilter={{...filter, status: 'processed'}}
        />
      ),
      eventSourceId: 'draft-invoice',
      api: `/draftInvoice/all`,
      addOnFilter: {...filter, status: 'processed'},
      actions: commonFiltersprocessed({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const DraftInvoiceTabAM = props => {
  const {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const tabs = {
    revenue_invoice_pending: {
      label: 'Pending',
      view: (
        <DraftInvoiceAllTableAm
          {...props}
          tab={'Pending'}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'pending',
            $or: [
              {account_owner_id: employeeId},
              {
                account_owner_id: {
                  $in: employeeTeam,
                },
              },
            ],
          }}
        />
      ),
      eventSourceId: 'draft-invoice',
      api: `/draftInvoice/all`,
      addOnFilter: {
        ...filter,
        status: 'pending',
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
      actions: commonFilters({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
      }),
    },
    revenue_invoice_processed: {
      label: 'Processed',
      view: (
        <DraftInvoiceAllTable
          {...props}
          tab={'Processed'}
          searchValue={searchValue}
          addOnFilter={{
            ...filter,
            status: 'processed',
            $or: [
              {account_owner_id: employeeId},
              {
                account_owner_id: {
                  $in: employeeTeam,
                },
              },
            ],
          }}
        />
      ),
      eventSourceId: 'draft-invoice',
      api: `/draftInvoice/all`,
      addOnFilter: {
        ...filter,
        status: 'processed',
        $or: [
          {account_owner_id: employeeId},
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      },
      actions: commonFiltersprocessed({
        searchValue,
        onChangeFilter,
        filterValues,
        applyFilter,
        filters,
        tab: 'All',
        params,
      }),
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
