import React from 'react';
import {TabView} from '../../../components/tab/TabView';

import {
  GroupFilter,
  PeriodRangeMonthWiseBreakupFilter,
} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';

import {Row} from '@unthinkable/react-core-components';

import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {
  AllLostOpportunitiesCombinedList,
  AllNewOpportunitiesCombinedList,
  AllOpportunitiesCombinedList,
} from './AllOpportunitiesList';

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const AllOpportunitiesTab = props => {
  let params = props?.route?.params;
  const {applyFilter, onChangeFilter, searchValue, filterValues} = useFilter({
    params: {
      period: getCurrentFY(),
    },
  });

  let {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.new = {
    label: 'New',
    // api: '/opportunities/all-active-opportunities',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <AllNewOpportunitiesCombinedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Outcome',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            field: 'outcome_date',
            asParam: true,
            label: 'Outcome date',
          },

          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'multiAutoComplete',
            label: 'Business Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },

          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        {/* <ObjectWiseTasks
        {...props}
        params={params}
        object={{
          _id: '64e06ebb94f415bdfd0a4aed',
        }}
      /> */}
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />
      </Row>,
    ],
  };

  tabs.unassigned = {
    label: 'Active',
    api: '/opportunities/all-active-opportunities',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <AllOpportunitiesCombinedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },
          {
            type: 'date',
            field: 'creation_date',
            asParam: true,
            label: 'Created On',
          },
          {
            type: 'autoComplete',
            label: 'Outcome',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'date',
            field: 'outcome_date',
            asParam: true,
            label: 'Outcome date',
          },

          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
            searchFields: ['name', 'official_email_id', 'employee_code'],
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Business Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },

          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
          {
            type: 'date',
            field: 'next_task_date',
            asParam: true,
            label: 'Next Task date',
          },
        ]}
      />,
      // <Row
      //   gap={8}
      //   style={{alignItems: 'center', justifyContent: 'space-between'}}>
      //   <ObjectWiseTasks
      //     {...props}
      //     params={params}
      //     object={{
      //       _id: '64e06ebb94f415bdfd0a4aed',
      //     }}
      //   />
      // </Row>,
    ],
  };
  tabs.active = {
    label: 'Closed',
    // api: '/opportunities/all-closed-opportunities',
    params: params,
    addOnFilter: filter,
    eventSourceId: 'CustomPlatformOpportunity',
    view: (
      <AllLostOpportunitiesCombinedList
        {...props}
        search={searchValue}
        addOnFilter={filter}
        filterParams={filterParams}
        params={params}
      />
    ),
    actions: [
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Type',
            suggestionField: 'label',
            valueField: 'label',
            keyField: 'value',
            options: [
              {
                label: 'Customer',
                value: 'Customer',
              },
              {
                label: 'Prospect',
                value: 'PotentailCustomer',
              },
            ],
            placeholder: 'Select',
            field: 'rfr_type',
          },
          {
            type: 'autoComplete',
            label: 'Customer',
            api: '/customers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'customer_id',
          },
          {
            type: 'autoComplete',
            label: 'Potential Customer',
            api: '/potentialcustomers',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'potential_customer',
          },

          {
            type: 'autoComplete',
            label: 'Outcome',
            options: [
              'New',
              'Active',
              'Won',
              'Lost',
              {label: 'Drop', value: 'invalidRfq'},
            ],
            placeholder: 'Select',
            field: 'status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            label: 'Dropped RFQ',
            options: [{label: 'True', value: 'true'}],
            placeholder: 'Select',
            field: 'is_drop',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },

          {
            type: 'autoComplete',
            label: 'Industry',
            api: '/industries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'industry',
          },
          {
            type: 'autoComplete',
            label: 'Country',
            api: '/countries',
            placeholder: 'Select',
            suggestionField: 'name',
            field: 'country',
          },
          {
            type: 'autoComplete',
            label: 'Employee Count',
            api: '/employeesizes',
            placeholder: 'Select',
            suggestionField: 'number',
            field: 'employee_count',
          },
          {
            type: 'autoComplete',
            label: 'Channel',
            api: '/types/suggestionsForLead',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'campaign_type',
          },
          {
            type: 'autoComplete',
            label: 'Sales',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'owner',
          },
          {
            type: 'autoComplete',
            label: 'Business Consultant', //pre sales
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'rfq_owner',
          },
          {
            type: 'multiAutoComplete',
            label: 'Tech. Consultant', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'tech_person',
          },
          {
            type: 'multiAutoComplete',
            label: 'Business Analyst', //sol
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'buisness_analyst',
          },
          {
            type: 'multiAutoComplete',
            label: 'Sol.Consultant', //primary
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            secondarySuggestionField: 'official_email_id',
            field: 'primary_owner',
          },
          {
            type: 'autoComplete',
            label: 'Quote Status',
            options: ['Submitted', 'Pending', 'On Hold'],
            placeholder: 'Select',
            field: 'quote_status',
          },
          // {
          //   type: 'date',
          //   field: 'quote_status_date',
          //   asParam: true,
          //   label: 'Quote Date',
          // },
          {
            type: 'autoComplete',
            label: 'Skill',
            api: '/skills',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'skill',
          },
        ]}
      />,
      <Row
        gap={8}
        style={{alignItems: 'center', justifyContent: 'space-between'}}>
        {/* <ObjectWiseTasks
          {...props}
          params={params}
          object={{
            _id: '64e06ebb94f415bdfd0a4aed',
          }}
        /> */}
        <PeriodRangeMonthWiseBreakupFilter
          onChangeFilter={onChangeFilter}
          filterValues={filterValues}
        />
      </Row>,
    ],
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      //   actions={[
      //     <GroupFilter
      //       filterValues={filterValues}
      //       applyFilter={applyFilter}
      //       filters={[
      //         {
      //           type: 'autoComplete',
      //           label: 'Type',
      //           suggestionField: 'label',
      //           valueField: 'label',
      //           keyField: 'value',
      //           options: [
      //             {
      //               label: 'Customer',
      //               value: 'Customer',
      //             },
      //             {
      //               label: 'Prospect',
      //               value: 'PotentailCustomer',
      //             },
      //           ],
      //           placeholder: 'Select',
      //           field: 'rfr_type',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Customer',
      //           api: '/customers',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           field: 'customer_id',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Potential Customer',
      //           api: '/potentialcustomers',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           field: 'potential_customer',
      //         },
      //         {
      //           type: 'date',
      //           field: 'creation_date',
      //           asParam: true,
      //           label: 'Created On',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Outcome',
      //           options: [
      //             'New',
      //             'Active',
      //             'Won',
      //             'Lost',
      //             {label: 'Drop', value: 'invalidRfq'},
      //           ],
      //           placeholder: 'Select',
      //           field: 'status',
      //           suggestionField: 'label',
      //           keyField: 'value',
      //           valueField: 'label',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Dropped RFQ',
      //           options: [{label: 'True', value: 'true'}],
      //           placeholder: 'Select',
      //           field: 'is_drop',
      //           suggestionField: 'label',
      //           keyField: 'value',
      //           valueField: 'label',
      //         },
      //         {
      //           type: 'date',
      //           field: 'outcome_date',
      //           asParam: true,
      //           label: 'Outcome date',
      //         },

      //         {
      //           type: 'autoComplete',
      //           label: 'Sales',
      //           api: '/employeeSuggestions',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           secondarySuggestionField: 'official_email_id',
      //           field: 'owner',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Pre Sales',
      //           api: '/employeeSuggestions',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           secondarySuggestionField: 'official_email_id',
      //           field: 'rfq_owner',
      //         },
      //         {
      //           type: 'multiAutoComplete',
      //           label: 'Sol. Consultant',
      //           api: '/employeeSuggestions',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           secondarySuggestionField: 'official_email_id',
      //           field: 'tech_person',
      //         },
      //         {
      //           type: 'multiAutoComplete',
      //           label: 'Pri. Sol.Consultant',
      //           api: '/employeeSuggestions',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           secondarySuggestionField: 'official_email_id',
      //           field: 'primary_owner',
      //         },
      //         {
      //           type: 'autoComplete',
      //           label: 'Quote Status',
      //           options: ['Submitted', 'Pending', 'On Hold'],
      //           placeholder: 'Select',
      //           field: 'quote_status',
      //         },
      //         // {
      //         //   type: 'date',
      //         //   field: 'quote_status_date',
      //         //   asParam: true,
      //         //   label: 'Quote Date',
      //         // },
      //         {
      //           type: 'autoComplete',
      //           label: 'Skill',
      //           api: '/skills',
      //           placeholder: 'Select',
      //           suggestionField: 'name',
      //           valueField: 'name',
      //           field: 'skill',
      //         },
      //         {
      //           type: 'date',
      //           field: 'next_task_date',
      //           asParam: true,
      //           label: 'Next Task date',
      //         },
      //       ]}
      //     />,
      //     <Row
      //       gap={8}
      //       style={{alignItems: 'center', justifyContent: 'space-between'}}>
      //       <ObjectWiseTasks
      //         {...props}
      //         params={params}
      //         object={{
      //           _id: '64e06ebb94f415bdfd0a4aed',
      //         }}
      //       />
      //       <PeriodRangeMonthWiseBreakupFilter
      //         onChangeFilter={onChangeFilter}
      //         filterValues={filterValues}
      //       />
      //       ,
      //       <AddButton
      //         title=" Custom Platform Opportunity"
      //         view="add-customplatformopportunity"
      //         params={props?.route?.params}
      //       />
      //     </Row>,
      //   ]}
    />
  );
};
