import React from 'react';
import {Image, Row, Text, View} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {TextRenderer} from '../../../components/form/Renderers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {chatScreen} from './AssignedTicketsView';
import {Rating} from '@unthinkable/react-star-rating';
import {useAppStateContext} from '../../../providers/AppState';
import {getNumberOfLineStyle} from '@unthinkable/react-core-components/src/style-utility';

const RatingRenderer = ({row, ...rest}) => {
  return <Rating {...rest} value={row['feedback']} readOnly />;
};
const RenderAssignAgent = ({row, styles}) => {
  const {Owner} = useTheme('icons');
  const Colors = useTheme('colors');
  let Component;
  if (row.agent) {
    Component = <UserAvatar value={row.agent} />;
  } else {
    Component = <Image source={Owner} style={{height: 24, width: 24}} />;
  }
  return (
    <View>
      <View
        style={{
          alignItems: 'center',
        }}>
        {Component}
      </View>
      <Text
        title={`${row?.employee_team?.name}`}
        style={{
          ...styles.text,
          ...getNumberOfLineStyle({numberOfLines: 1}),
          color: Colors.NEUTRAL_LOW,
        }}>
        {row?.employee_team?.name}
      </Text>
    </View>
  );
};

const RenderDate = ({value}) => {
  return (
    <View style={{gap: 8, flexDirection: 'column'}}>
      <TextRenderer value={moment(value).format('DD MMM YY')} />
      <GroupContentItem value={moment(value).format('hh:mm A')} />
    </View>
  );
};

const RenderResponseDate = ({row}) => {
  const {last_agent_response_on, last_employee_response_on} = row;
  return (
    <View style={{gap: 8, flexDirection: 'column'}}>
      {last_employee_response_on && (
        <GroupContentItem
          value={moment(last_employee_response_on).format('DD MMM hh:mm A')}
        />
      )}
      {last_agent_response_on && (
        <GroupContentItem
          value={moment(last_agent_response_on).format('DD MMM hh:mm A')}
        />
      )}
    </View>
  );
};

const RenderRaisedBy = ({value}) => {
  return (
    <Row style={{alignItems: 'center', gap: 8}}>
      <UserAvatar value={value} size={28} />
      <View style={{gap: 4, flexDirection: 'column'}}>
        <TextRenderer value={value?.name} />
        <GroupContentItem value={value.employee_code} />{' '}
      </View>
    </Row>
  );
};

const RenderTime = ({value}) => {
  return (
    <GroupContentItem
      value={
        (Number.parseInt(value / 60)
          ? Number.parseInt(value / 60) + ' hrs '
          : '') +
        (value % 60) +
        ' mins'
      }
    />
  );
};

const RenderName = ({row}) => {
  return (
    <View style={{gap: 8}}>
      <TextRenderer value={row.subject} style={{numberOfLines: 1}} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={`#${row?.ticket_number}`} />
        <GroupContentItem value={row?.category?.name} />
      </RowWithSeparator>
    </View>
  );
};

const RenderRaisedOn = ({row}) => {
  const {ERROR_HIGH, SUCCESS_HIGH, WARNING_HIGH, ACCENT5_HIGH} =
    useTheme('colors');
  const PRIORITY_WISE_COLOR = {
    Urgent: ERROR_HIGH,
    High: WARNING_HIGH,
    Medium: ACCENT5_HIGH,
    Low: SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        flexDirection: 'columns',
        gap: 8,
        borderLeftColor: PRIORITY_WISE_COLOR[row.priority],
        borderLeftWidth: 2,
        padding: 12,
        margin: -12,
      }}>
      <TextRenderer value={moment(row.raised_on_date).format('DD MMM YY')} />
      <GroupContentItem value={moment(row.raised_on_date).format('hh:mm a')} />
    </View>
  );
};

const RenderClosedStatus = ({row}) => {
  const {is_delayed, delay_time = 0, early_time = 0} = row;
  const IsDelay = is_delayed ? 'Delayed' : 'On Time';
  let DelayText =
    'By ' +
    (Number.parseInt(delay_time / 60)
      ? Number.parseInt(delay_time / 60) + ' hrs '
      : '') +
    (delay_time % 60) +
    ' mins';
  if (isNaN(delay_time)) {
    DelayText = 'By ' + delay_time;
  }
  const EarlyText =
    (Number.parseInt(early_time / 60)
      ? Number.parseInt(early_time / 60) + ' hrs '
      : '') +
    (early_time % 60) +
    ' mins' +
    ' early';
  const {SUCCESS_HIGH, SUCCESS_LOW, ERROR_HIGH, ERROR_LOW} = useTheme('colors');
  const backgroundColorsMap = {
    Delayed: ERROR_LOW,
    'On Time': SUCCESS_LOW,
  };
  const textColorsMap = {
    Delayed: ERROR_HIGH,
    'On Time': SUCCESS_HIGH,
  };
  return (
    <View
      style={{
        backgroundColor: backgroundColorsMap[IsDelay],
        padding: 12,
        margin: -12,
        gap: 8,
      }}>
      <TextRenderer
        value={IsDelay}
        style={{
          color: textColorsMap[IsDelay],
        }}
      />
      <GroupContentItem value={is_delayed ? DelayText : EarlyText} />
    </View>
  );
};

export const OrgTicketList = props => {
  const {
    route: {params},
    navigation,
    isClosed,
    isDelayed,
    employee_team,
    isUnassigned,
    isTempClosed,
    sort,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'tickets',
  });
  const markClose = ({row}) => {
    invoke({
      uri: '/tickets/' + row?._id,
      props: {
        status: 'Closed',
      },
    });
  };
  const {download} = useAppStateContext();

  return (
    <Table
      sort={sort}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: ({selectedIds}) => {
              download({
                uri: '/tickets/download',
                props: {
                  filter: {
                    _id: {
                      $in: selectedIds,
                    },
                  },
                },
              });
            },
          },
        ],
      }}
      api="/tickets"
      eventSourceId={['tickets']}
      fields={{
        subject: 1,
        description: 1,
        agent: {name: 1, color: 1},
        raised_on_date: 1,
        expected_close: 1,
        status: 1,
        category: {
          name: 1,
        },
        sub_category: {
          name: 1,
        },
        priority: 1,
        employee_team: {
          name: 1,
        },
        ticket_number: 1,
        delay_time: 1,
        early_time: 1,
        is_delayed: 1,
        closed_on: 1,
        raised_by: {
          name: 1,
          color: 1,
          employee_code: 1,
          official_email_id: 1,
        },
        agent_working_time: 1,
        feedback: 1,
        dependency_status: 1,
        attachment: 1,
        agent_dependency_time: 1,
        last_agent_response_on: 1,
        last_employee_response_on: 1,
        temporarily_closed_on: 1,
      }}
      filter={{
        employee_team: employee_team?._id,
      }}
      onRowPress={({row}) => {
        row.agent
          ? chatScreen({row, navigation, params})
          : navigation.navigate('change-agent-form', {
              ...params,
              ticketDetails: row,
            });
      }}
      columns={[
        {header: 'Raised On', render: RenderRaisedOn, width: 100},
        {
          header: 'Description',
          render: RenderName,
          minWidth: 250,
        },
        {
          render: RenderRaisedBy,
          header: 'Employee',
          field: 'raised_by',
          width: 180,
        },
        {
          render: RenderAssignAgent,
          header: 'Assigned To',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('change-agent-form', {
              ...params,
              ticketDetails: row,
            });
          },
          visible: !isClosed,
        },
        {
          render: RenderAssignAgent,
          header: 'Closed By',
          width: 180,
          onPress: ({row}) => {
            navigation.navigate('change-agent-form', {
              ...params,
              ticketDetails: row,
            });
          },
          visible: !!isClosed,
        },
        {
          render: RenderDate,
          field: 'temporarily_closed_on',
          header: 'Under Observation Since',
          width: 200,
          visible: !!isTempClosed,
        },
        {
          render: RenderDate,
          field: 'expected_close',
          header: 'Target Closure',
          width: 150,
          visible: !isClosed && !isUnassigned && !isTempClosed,
        },
        {
          type: 'colorTag',
          field: 'dependency_status',
          header: 'Dependency Status',
          visible: !isClosed && !isUnassigned && !isTempClosed,
          width: 180,
        },
        {
          render: RenderResponseDate,
          header: 'Last Response',
          width: 150,
          visible: !isClosed && !isUnassigned && !isTempClosed,
        },
        {
          render: RenderDate,
          field: 'closed_on',
          header: 'Closed On',
          width: 140,
          visible: !!isClosed,
        },
        {
          render: RenderClosedStatus,
          header: 'Resolution Time',
          visible: !!isClosed,
          width: 180,
        },
        // {
        //   render: RenderTime,
        //   header: 'Dependency Time',
        //   field: 'agent_dependency_time',
        //   visible: !!isClosed,
        //   width: 180,
        // },
        {
          render: RenderTime,
          field: 'delay_time',
          header: 'Delayed By',
          variant: 'secondary',
          width: 150,
          visible: !!isDelayed,
        },
        {
          render: RatingRenderer,
          header: 'Feedback',
          width: 120,
          visible: !!isClosed,
        },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('ticket-detail', {
              ticketId: row._id,
              ticket: row,
            });
          },
        },
        {
          title: 'Change Category',
          visible: !isClosed && !isDelayed,
          onPress: ({row}) => {
            navigation.navigate('change-category-form', {
              ticketDetails: row,
            });
          },
        },
        {
          title: 'Change Team',
          visible: !isClosed && !isDelayed,
          onPress: ({row}) => {
            navigation.navigate('change-department-form', {
              ticketDetails: row,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
