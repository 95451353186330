import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from '../../../components/button/Button';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const RenderRequestFeedback = ({navigation}, {values}) => {
  const onPress = () => {
    navigation.navigate('request-feedback', {
      project: values,
    });
  };
  return <Button text="REQUEST FEEDBACK" onPress={onPress} />;
};

const ProjectComputations = fetch => ({
  'set value from customer': {
    compute: async value => {
      if (value?.customer?._id) {
        const {data: customerData} = await fetch({
          uri: '/customers',
          props: {
            filter: {
              _id: value?.customer?._id,
            },
            fields: {
              _id: 1,
              industry: {_id: 1, name: 1},
              owner: {_id: 1, name: 1},
            },
            only: true,
          },
        });
        return {
          industry: customerData.industry,
          account_owner: customerData.owner,
        };
      }
    },
    multi: true,
    dependencies: ['customer'],
  },
});

export const ProjectForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  let {route = {}, fromDetail = false, mode, navigation} = props || {};
  let {params = {}} = route || {};
  let {
    department,
    product,
    project,
    new_project_type,
    formAdmin = false,
  } = params || {};
  const {
    user: {employee: employeeId, email: userEmail},
    fetch,
  } = useAppStateContext();
  const [requiredField, setRequiredField] = useState(false);
  return (
    <Form
      api={`/projects/${project?._id}`}
      computations={{...ProjectComputations(fetch)}}
      fields={{
        project: 1,
        code: 1,
        project_group: 1,
        desc: 1,
        department: {name: 1},
        product: {name: 1},
        projecttype: 1,
        project_sub_category: 1,
        owner_id: {name: 1},
        csm: {_id: 1, name: 1},
        account_owner: {_id: 1, name: 1},
        customer: {name: 1},
        primary_practice: {name: 1},
        project_category: 1,
        observer: {name: 1},
        project_group: 1,
        project_start_date: 1,
        project_end_date: 1,
        desc: 1,
        new_project_type: 1,
        tool: 1,
        msa: 1,
        proposal_doc: 1,
        estimation_sheet: 1,
        other_project: 1,
        observer: {name: 1},
        client: {
          name: 1,
        },
        clientAccess: 1,
        is_project_assign: 1,
        feedbackSchedule: 1,
        nextFeedbackSchedule: 1,
        is_support_project: 1,
        industry: {
          name: 1,
        },
        feedback_mode: 1,
        parent: {project: 1},
        disable_release_efficiency: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        status: 'backlog',
        owner_id: employeeId,
        department,
        product,
        is_project_assign: false,
        created_on: getZeroTimeDate(new Date()),
        new_project_type,
      }}
      layoutFields={[
        {
          label: 'About Project',
          fields: [
            {
              type: 'text',
              field: 'project',
              label: 'Project',
              required: true,
              size: 6,
            },
            {
              label: 'For',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Type',
              field: 'new_project_type',
              type: 'autoComplete',
              size: 4,
              options: [
                {value: 'staffaugmentation', label: 'Staff Augmentation'},
                {value: 'managedteam', label: 'Managed Team'},
                {value: 'sdlc', label: 'SDLC'},
                {value: 'sdlcplus', label: 'SDLC+'},
              ],
              readOnly: formAdmin,
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (value === 'sdlcplus') {
                  setRequiredField(true);
                } else {
                  setRequiredField(false);
                  setFieldValue('feedbackSchedule', 'Monthly');
                  setFieldValue(
                    'nextFeedbackSchedule',
                    moment(new Date()).endOf('month'),
                  );
                }
              },
            },
            {
              label: 'Tool',
              field: 'tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
              required: true,
              size: 4,
            },
            {
              label: 'Primary Practice',
              field: 'primary_practice',
              type: 'autoComplete',
              api: `/practices`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 4,
            },

            // {
            //   label: 'Sub Category',
            //   field: 'project_sub_category',
            //   type: 'autoComplete',
            //   size: 3,
            //   options: [
            //     'Client Managed',
            //     'Daffodil Managed',
            //     'Hybrid Managed',
            //     'Daffodil Managed - Support',
            //   ],
            //   required: true,
            //   visible: ({values}) =>
            //     values &&
            //     (values.projecttype === 'f' || values.projecttype === 'tm'),
            // },
            {
              label: 'Start Date',
              field: 'project_start_date',
              type: 'date',
              required: true,
              size: 6,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              field: 'feedbackSchedule',
              editable: false,
              type: 'text',
              label: 'Feedback schedule',
              size: 6,
              visible: ({values}) =>
                values &&
                (values.new_project_type === 'staffaugmentation' ||
                  values.new_project_type === 'managedteam' ||
                  values.new_project_type === 'sdlc'),
            },
            {
              field: 'nextFeedbackSchedule',
              editable: false,
              type: 'date',
              label: 'Next feedback schedule',
              size: 6,
              visible: ({values}) =>
                values &&
                (values.new_project_type === 'staffaugmentation' ||
                  values.new_project_type === 'managedteam' ||
                  values.new_project_type === 'sdlc'),
            },
            {
              field: 'is_support_project',
              type: 'checkbox',
              label: 'Support Project',
              size: 6,
            },
            {
              type: 'checkbox',
              field: 'disable_release_efficiency',
              label: 'Hide Efficiency',
              size: 6,
              visible: () => {
                return userEmail === 'amit.singh@daffodildb.com';
              },
            },
            {
              field: 'projecttype',
              type: 'autoComplete',
              label: 'Project Type',
              options: [
                {value: 'tm', label: 'T&M'},
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 6,
              visible: () => fromDetail,
            },
            {
              collapsedFields: [
                {
                  label: 'End Date',
                  field: 'project_end_date',
                  size: 6,
                  required: true,
                  type: 'date',
                },
                {
                  label: 'Industry',
                  field: 'industry',
                  type: 'autoComplete',
                  api: `/industries`,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 6,
                },
                {
                  type: 'aiTextArea',
                  field: 'desc',
                  required: true,
                  label: 'Description',
                  inputProps: {
                    minRows: 3,
                    maxRows: 6,
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Parent Project',
          visible: () => {
            return (
              userEmail === 'amit.singh@daffodildb.com' ||
              userEmail === 'manoj.kaushik@daffodilsw.com'
            );
          },
          fields: [
            {
              label: 'Name',
              field: 'parent',
              type: 'autoComplete',
              api: `/projects`,
              suggestionField: 'project',
              valueField: 'project',
            },
          ],
        },
        {
          label: 'Project ownership & access',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'PM',
                  field: 'owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Observer',
                  field: 'observer',
                  type: 'multiAutoComplete',
                  api: `/users`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                },
                {
                  label: 'AM',
                  field: 'account_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Customer POC',
          fields: [
            {
              label: 'Name',
              field: 'client',
              type: 'multiAutoComplete',
              api: `/users`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              valueField: 'name',
              filter: {
                userType: {$in: ['client', 'Client']},
              },
            },
          ],
        },
        {
          label: 'Documents',
          visible: ({values}) => values?.new_project_type === 'sdlcplus',
          fields: [
            {
              placeholder: 'Proposal',
              label: 'Proposal',
              field: 'proposal_doc',
              multiple: true,
              type: 'file',
              // required: requiredField,
              size: 6,
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'MSA',
              label: 'MSA',
              field: 'msa',
              multiple: true,
              type: 'file',
              // required: requiredField,
              size: 6,
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'Estimation Sheet  ',
              label: 'Estimation Sheet  ',
              field: 'estimation_sheet',
              multiple: true,
              type: 'file',
              // required: requiredField,
              size: 6,
              options: {bucketName: 'manaze'},
            },
            {
              placeholder: 'Others',
              label: 'Others',
              field: 'other_project',
              multiple: true,
              type: 'file',
              // required: requiredField,
              size: 6,
              options: {bucketName: 'manaze'},
            },
          ],
        },
        // {
        //   groups: [
        //     {
        //       label:
        //     }
        //   ]
        // }

        // [
        //   {
        //     label: 'Client Access',
        //     field: 'clientAccess',
        //     type: 'checkbox',
        //     size: 'small',
        //   },
        // ],
        {
          label: 'Feedback Mode',
          fields: [
            {
              field: 'feedback_mode',
              type: 'radioGroup',
              options: ['Manual', 'Automatic'],
            },
            {
              render: RenderRequestFeedback,
              visible: mode === 'edit' && fromDetail,
              navigation,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UnbilledProjectForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  let {user = {}} = useAppStateContext();
  let {employee} = user || {};
  let {route = {}} = props || {};
  let {params = {}} = route || {};
  let {department, product, project} = params || {};
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  return (
    <Form
      api={`/projects/${project?._id}`}
      fields={{
        project: 1,
        code: 1,
        project_group: 1,
        desc: 1,
        department: {name: 1},
        product: {name: 1},
        projecttype: 1,
        project_sub_category: 1,
        owner_id: {name: 1},
        csm: {_id: 1, name: 1},
        account_owner: {_id: 1, name: 1},
        customer: {name: 1},
        primary_practice: {name: 1},
        project_category: 1,
        observer: {name: 1},
        project_group: 1,
        project_start_date: 1,
        project_end_date: 1,
        desc: 1,
        tool: 1,
        observer: {name: 1},
        is_support_project: 1,
        client: {
          name: 1,
        },
        clientAccess: 1,
        is_self_module: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        status: 'backlog',
        owner_id: employeeId,
        // observer: user._id,
        department,
        product,
        // project_group: 'Testing',
        projecttype: 'i',
        created_on: getZeroTimeDate(new Date()),
        // project_sub_category: 'Daffodil Managed',
      }}
      layoutFields={[
        {
          label: 'About Project',
          fields: [
            {
              type: 'text',
              field: 'project',
              label: 'Project Name',
              required: true,
              size: 6,
            },
            // {
            //   type: 'text',
            //   field: 'project_group',
            //   label: 'Project Group',
            //   readOnly: true,
            //   size: 3,
            // },
            {
              label: 'For',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Tool',
              field: 'tool',
              type: 'autoComplete',
              options: ['Locomo', 'Other'],
              required: true,
              size: 'small',
            },
            {
              label: 'Primary Practice',
              field: 'primary_practice',
              type: 'autoComplete',
              api: `/practices`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 'small',
            },
            {
              label: 'Category',
              field: 'project_category',
              type: 'autoComplete',
              options: ['POC', 'Social', 'Internal'],
              size: 'small',
              required: true,
            },

            // onChangeValue: (value, e, {setFieldValue}) => {
            //   if (value == 'i' || value == 'f') {
            //     setFieldValue('project_sub_category', 'Daffodil Managed');
            //   } else {
            //     setFieldValue('project_sub_category', null);
            //   }
            // },

            {
              label: 'Start Date',
              field: 'project_start_date',
              type: 'date',
              size: 'small',
              required: true,
              size: 6,
            },
            {
              label: 'Product',
              field: 'product',
              type: 'autoComplete',
              api: `/products`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              field: 'is_support_project',
              type: 'checkbox',
              label: 'Support Project',
              size: 6,
            },
            {
              field: 'is_self_module',
              type: 'checkbox',
              label: 'Self Module',
              size: 6,
            },
            {
              collapsedFields: [
                {
                  label: 'End Date',
                  field: 'project_end_date',
                  size: 3,
                  type: 'date',
                  required: true,
                },
                {
                  size: 3,
                  type: 'textArea',
                  field: 'desc',
                  label: 'Description',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Project ownership & access',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'PM',
                  field: 'owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Observer',
                  field: 'observer',
                  type: 'multiAutoComplete',
                  api: `/users`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                },
                {
                  label: 'AM',
                  field: 'account_owner',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Feedback POC',
          fields: [
            {
              label: 'Name',
              field: 'client',
              type: 'multiAutoComplete',
              api: `/users`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              valueField: 'name',
              filter: {
                userType: {$in: ['client', 'Client']},
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectForm = props => {
  return (
    <ProjectForm
      header={{
        title: 'Add Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      successMessage="Project Created."
      {...props}
    />
  );
};

export const AddUnbilledProjectForm = props => {
  return (
    <UnbilledProjectForm
      header={{
        title: 'Add Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const EditBilledProjectForm = props => {
  return (
    <ProjectForm
      mode="edit"
      header={{
        title: 'Edit Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const EditUnbilledProjectForm = props => {
  return (
    <UnbilledProjectForm
      mode="edit"
      header={{
        title: 'Edit Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const ProjectDetailsForm = props => {
  return (
    <ProjectForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail Project',
      }}
      fromDetail={true}
      {...props}
    />
  );
};

export const ProjectDescriptionForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  let {route = {}} = props || {};
  let {params = {}} = route || {};
  let {project} = params || {};
  return (
    <Form
      mode="edit"
      header={{
        title: 'Project Description',
        secondaryTitle: project?.project,
      }}
      api={`/projects/${project?._id}`}
      fields={{
        desc: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'aiTextArea',
          field: 'desc',
          required: true,
          label: 'Description',
          inputProps: {
            minRows: 6,
            maxRows: 8,
          },
        },
      ]}
      {...props}
    />
  );
};
