import React from 'react';
import {Table} from '../../../components/table/Table';
import {Text} from '@unthinkable/react-core-components';
import {useInvoke} from '../../../controllers/useInvoke';

const deleteOrderResourceRequest = ({invoke}) => ({
  title: 'Reject Request',
  confirm: {
    title: 'Reject Request',
    message: 'Do you want to reject this request?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/orderresourcerequests/${row?._id}`,
    });
  },
});

export const ProjectwiseResourceRequestTable = props => {
  const {route: {params = {}} = {}, navigation} = props || {};
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'orderresourcerequests',
  });
  const {data = {}} = params;
  const {skill_required, order, fromOrderWiseAllocations, project} = data;
  let filter = {};
  if (fromOrderWiseAllocations) {
    filter = {
      skill_required,
      order,
    };
  } else {
    filter = {
      skill_required,
      order,
      status: 'pending',
      project,
    };
  }

  return (
    <Table
      api={'/orderwise/orderresourcerequests'}
      params={params}
      eventSourceId={[
        'skillRequirement',
        'employeeAssigned',
        'orderresourcerequests',
      ]}
      addOnFilter={filter}
      columns={[
        {header: 'Req No.', field: 'order_resource_request_no', type: 'text'},
        {
          header: 'Skill ',
          field: 'tool.name',
          type: 'colorTag',
          color: '#F5E0FF',
        },
        {header: 'Order', field: 'order.order_number', type: 'text'},
        {
          header: 'Start ',
          field: 'from_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'End ',
          field: 'to_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Hrs/Day',
          field: 'hours',
          type: 'number',
        },
        {
          header: 'Action',
          align: 'center',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                onPress={() => {
                  navigation.navigate('project-allocate-resource', {
                    row,
                    allocateResourceFilter: {},
                  });
                }}
                style={{...rowText, color: '#007AFF'}}>
                Allocate
              </Text>
            );
          },
        },
      ]}
      moreActions={[deleteOrderResourceRequest({invoke, params})]}
    />
  );
};
