import React, {forwardRef, memo} from 'react';
import {Wrap} from '@unthinkable/react-core-components';
import AvatarChip from './AvatarChip';
import {RemainingValueText} from './Styles';

export const MultiAvatarChip = forwardRef(
  (
    {styles, children, maxChips = 1, values, maxWidth, avatarChip, gap = 4},
    ref,
  ) => {
    if (values) {
      if (!Array.isArray(values)) {
        values = [values];
      }
    } else if (children) {
      values = React.Children.toArray(children);
    }
    values = values ? values.filter(value => !!value) : values;
    if (!values || !values.length) {
      return null;
    }
    let remainingValues = void 0;
    if (maxChips && maxChips < values?.length) {
      remainingValues = values.slice(maxChips);
      values = values.slice(0, maxChips);
    }

    let chips = values.map((_value, index) => {
      const chipProps = {
        ...avatarChip,
        key: index,
      };
      if (maxWidth) {
        chipProps.maxWidth = maxWidth;
      }
      if (React.isValidElement(_value)) {
        return React.cloneElement(_value, {
          ...chipProps,
          ..._value.props,
        });
      } else {
        _value = typeof _value === 'string' ? {value: _value} : _value;
        return <AvatarChip styles={styles} {...chipProps} {..._value} />;
      }
    });

    return (
      <Wrap style={{alignItems: 'center'}} gap={gap} ref={ref}>
        {chips}
        {remainingValues?.length ? (
          <RemainingValueText
            styles={styles}>{`+ ${remainingValues.length}`}</RemainingValueText>
        ) : (
          void 0
        )}
      </Wrap>
    );
  },
);

export default memo(MultiAvatarChip);
