import React from 'react';
import {MultiDataTable} from './MultiDataTable';

export const AITable = ({moreActions: propMoreActions, ...props}) => {
  let moreActions = propMoreActions;
  if (moreActions) {
    moreActions = actionProps => {
      const {row} = actionProps;
      if (row?.aiGenerated) {
        return [];
      } else if (typeof propMoreActions === 'function') {
        return propMoreActions(actionProps);
      } else {
        return propMoreActions;
      }
    };
  }

  return <MultiDataTable {...props} moreActions={moreActions} />;
};
