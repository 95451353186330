export const ChipStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      paddingTop: 1,
      paddingRight: 8,
      paddingBottom: 1,
      paddingLeft: 8,
      backgroundColor: colors.SURFACE2,
    },
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};
export const TagStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      borderRadius: 4,
      paddingTop: 1,
      paddingRight: 6,
      paddingBottom: 1,
      paddingLeft: 6,
      backgroundColor: colors.SURFACE2,
    },
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
  };
};

export const ErrorChipStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    textColor: colors.ERROR_HIGH,
    color: colors.ERROR_LOW,
  };
};

export const AvatarChipStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    avatar: {
      text: {
        ...fonts.FOOTNOTE,
        color: '#FCFCFC',
      },
    },
  };
};

export const ErrorTagStyle = ({theme: {fonts, colors, icons}}) => {
  return {
    container: {
      backgroundColor: colors.ERROR_HIGH,
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 4,
      paddingRight: 4,
      borderRadius: 6,
    },
    text: {
      ...fonts.CAPTION_SMALL,
      color: colors.BACKGROUND,
    },
  };
};
